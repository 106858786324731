<template>
  <v-container fluid v-if="formHotel">
    <v-row class="ma-0">
      <v-col cols="auto">
        <search-local-hotel
            v-model="formHotel.local"
            :outlined="true" label="Local"></search-local-hotel>
            <v-checkbox label="Flexibilidade" v-model="formHotel.flexibilidade"></v-checkbox>
      </v-col>
      <v-col cols="auto">
        <form-date-picker-multiple
            v-model="formHotel.checkin"
            outlined label="Check-in"></form-date-picker-multiple>
      </v-col>
      <v-col cols="auto">
        <form-date-picker-multiple
            v-model="formHotel.checkout"
            outlined label="Check-out"></form-date-picker-multiple>
      </v-col>
      <v-col align-self="center" cols="auto">
        <v-menu
            v-model="menu"
            :close-on-content-click="false"
            :nudge-width="200"
            offset-x
        >
          <template v-slot:activator="{ on, attrs }">
            <v-textarea
                outlined
                v-bind="attrs"
                v-on="on"
                readonly
                no-resize
                rows="3"
                dense
                height="auto"
                :value="getPaxQuartosLabel"
            >
              <template v-slot:append>
                <v-btn
                    small
                    icon
                    color="primary"
                    v-bind="attrs"
                    v-on="on">
                  <v-icon>
                    mdi-dots-vertical
                  </v-icon>
                </v-btn>
              </template>
            </v-textarea>
          </template>

          <v-card>

            <v-divider></v-divider>

            <v-list>
              <v-list-item>
                <v-list-item-action>
                  <v-text-field
                      dense
                      label="Adultos"
                      type="number"
                      v-model="formHotel.adultos"
                  >
                  </v-text-field>
                </v-list-item-action>
                <v-list-item-title>18 ou mais</v-list-item-title>
              </v-list-item>
              <v-list-item>
                <v-list-item-action>
                  <v-text-field
                      dense
                      type="number"
                      label="Crianças"
                      v-model="formHotel.criancas">
                  </v-text-field>
                </v-list-item-action>
                <v-list-item-title>2-17 anos</v-list-item-title>
              </v-list-item>
              <v-list-item>
                <v-list-item-action>
                  <v-text-field
                      dense
                      type="number"
                      label="Bebês"
                      v-model="formHotel.bebes">
                  </v-text-field>
                </v-list-item-action>
                <v-list-item-title>0-2 anos</v-list-item-title>
              </v-list-item>
              <v-list-item>
                <v-list-item-action>
                  <v-text-field
                      dense
                      label="Quartos"
                      v-model="formHotel.quartos">
                  </v-text-field>
                </v-list-item-action>
                <v-list-item-title>Quartos</v-list-item-title>

              </v-list-item>
            </v-list>

            <v-card-actions>
              <v-spacer></v-spacer>

              <v-btn
                  color="primary"
                  text
                  @click="menu = false"
              >
                Fechar
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-menu>
      </v-col>
    </v-row>
  </v-container>
</template>
<script>

import SearchAeroporto from "@/components/widgets/SearchAeroporto";
import FormDatePicker from "@/components/widgets/FormDatePicker";
import SearchLocalHotel from "@/components/widgets/SearchLocalHotel";
import FormDatePickerMultiple from "@/components/widgets/FormDatePickerMultiple";
import { validateDates } from "@/helpers/dates";


export default {
  components: { FormDatePickerMultiple, SearchLocalHotel, FormDatePicker, SearchAeroporto},
  props: {
    value: {
      type: Object
    }
  },
  data() {
    return {
      type: null,
      menu: false,
      formHotel: null
    }
  },
  watch: {
    formHotel: {
      deep: true,
      handler: function (val) {
        this.$emit('input', this.formHotel);

        const { checkin, checkout } = this.formHotel;
        if (validateDates(checkin[0], checkout[0])) {
          this.formHotel.checkout = [];
        }
      }
    }
  },
  created() {
    this.formHotel = this.value ? this.value : {
      adultos: 1,
      quartos: 1,
      criancas: 0,
      bebes: 0
    };
  },
  computed: {
    getPaxQuartosLabel() {
      let arr_str = []
      if (this.formHotel.adultos > 0) {
        arr_str.push(`${this.formHotel.adultos} Adulto${this.pluralize(this.formHotel.adultos)}`);
      }
      if (this.formHotel.criancas > 0) {
        arr_str.push(`${this.formHotel.criancas} Criança${this.pluralize(this.formHotel.criancas)}`)
      }

      let quartos_str = `${this.formHotel.quartos} Quarto${this.pluralize(this.formHotel.quartos)}`
      arr_str.push(quartos_str)
      return arr_str.join('\n')
    }
  },
  methods: {
    pluralize(val) {
      return this.$options.filters.pluralize(val);
    }
  }

}
</script>
