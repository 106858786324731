import { get } from '../modules';

export default {

    getSubcotacoes() {
        return get(`/api/subcotacoes/`)
    },

    getValidacoesSubcotacao(subcotacaoID) {
        return get(`/api/subcotacoes/${subcotacaoID}/validacoes/`)
    },

}