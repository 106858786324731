<template>
  <v-card>
    <v-card-title>Provedores associados</v-card-title>
    <v-card-text>
      <v-container
        v-for="(provedor, index) in provedores"
        :key="index"
        class="my-2"
      >
        <v-row>#{{ index + 1 }}</v-row>
        <v-row>ID: {{ provedor.id }}</v-row>
        <v-row>Nome: {{ provedor.nome }}</v-row>
        <v-row>Email: {{ provedor.email }}</v-row>
        <v-row>Telefone: {{ provedor.telefone }}</v-row>
        <v-row
          >Tipo servico:
          {{ provedor.tipo_servico | formatTipoServico }}</v-row
        >
        <v-row><v-divider></v-divider></v-row>
      </v-container>
    </v-card-text>
    <v-card-actions>
      <v-btn color="blue darken-1" text @click="close"> Fechar </v-btn>
    </v-card-actions>
  </v-card>
</template>
<script>
export default {
  props: {
    provedores: {
      type: Array,
      default: () => [],
    },
  },

  methods: {
    close() {
      this.$emit("close");
    },
  },
};
</script>
