<template>
  <tr>
    <td>{{ item.id }}</td>
    <td>
      {{ item.data_hora_cotacao | dataHoraBR }}
    </td>
    <td>{{ cotacaoTipoServico(item) }}</td>
    <td>{{ item.descricao }}</td>
    <td>
      {{ validateCustoTotal | dinheiroReal }}
      <abbr v-if="this.fromContasPagar == true"
        title="Custo total recalculado a partir de alteração em contas a pagar."
      >
        ⓘ
      </abbr>
    </td>
    <td>
      {{
        ((item.ajuste_ativado &&
          converteValorFinanceiroBRL(item.valor_ajuste)) ||
          0) | dinheiroReal
      }}
    </td>
    <td>{{ cotacaoValorTotal(item) | dinheiroReal }}</td>
    
    <td>{{ item.cotado_por.nome }} {{ item.cotado_por.sobrenome }}</td>
    <td>{{ tipo }}</td>
    <td>
      <v-icon color="primary" class="mr-2" @click="showFornecedores">
        mdi-magnify
      </v-icon>
    </td>
    <td>
      <div class="d-flex">
        <v-btn
          x-small
          class="mr-2"
          :disabled="!canGoToCarrinho"
          @click="dialog = true"
          icon
        >
          <v-icon small :color="!canGoToCarrinho ? 'grey' : 'green'">
            mdi-cart
          </v-icon>
        </v-btn>
        <v-btn x-small class="" @click="viewCotacaoItem(item)" icon>
            <v-icon color="primary">mdi-eye </v-icon>
        </v-btn>
      </div>
    </td>
    <template>
      <v-row justify="center">
        <v-dialog v-model="dialog" persistent max-width="290">
          <v-card>
            <v-card-title class="text-h5"> Confirmação </v-card-title>
            <v-card-text
              >Realmente deseja enviar essa cotação para o
              carrinho?</v-card-text
            >
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="secondary" text @click="dialog = false">
                Não
              </v-btn>
              <v-btn color="green darken-1" text @click="confirmCarrinho()">
                Sim, envie
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
        <fornecedores-popup
          v-model="dialogFornecedores"
          :loading="loadingFornecedores"
          :fornecedores="fornecedores"
        />
      </v-row>
    </template>
    <template>
      <v-dialog v-model="dialogEditCotacao" v-if="dialogEditCotacao">
        <cotacao-edit-item
          :orcamento="item.orcamento"
          :item="item"
          v-model="edittingItem"
          :allowSave="allowSave"
          @close="closeEditItem"
          :isVisualizacao="isVisualizacao"
        ></cotacao-edit-item>
      </v-dialog>
    </template>
  </tr>
</template>
<script>
import { valorTotalServico } from "@/helpers/utils";
import { getListFornecedores } from "@/helpers/fornecedores";
import { converteValorFinanceiroBRL } from "@/helpers/financeiro";
import { hoursBetween } from "@/helpers/dates";

import api from "@/api/api";
import CotacaoMixin from "@/mixins/CotacaoMixin";

import FornecedoresPopup from "@/components/Fornecedores/FornecedoresPopup";
import CotacaoEditItem from "@/components/cotacao/CotacaoEditItem";

export default {
  components: {
    FornecedoresPopup,
    CotacaoEditItem
  },
  props: {
    item: { // item == cotação
      type: Object,
      default: () => {},
    },
    headers: {
      type: Array,
      default: () => [],
    },
    selected: {
      type: Boolean,
      default: () => true,
    },
  },
  mixins: [CotacaoMixin],
  data() {
    return {
      formAjuste: "",
      loadingAjuste: false,
      venda_id: this.$route.params.id,
      dialog: false,
      fornecedores: [],
      dialogFornecedores: false,
      loadingFornecedores: false,
      dialogEditCotacao: false,
      edittingItem: {},
      allowSave: true,
      isVisualizacao: false,
      fromContasPagar: false,
    };
  },
  computed: {
    tipo() {
      if (this.item.cotacao_voo) {
        const uniqueTipos = [
          ...new Set(
            this.item.cotacao_voo.subcotacoes.map(
              (subcotacao) => subcotacao.tipo_cotacao
            )
          ),
        ];
        return uniqueTipos.join(", ");
      }
      return "";
    },
    canGoToCarrinho() {
      if(!this.item) return false
      const now = new Date()
      const creation = new Date(this.item.data_hora_cotacao)
      return this.item.status === 'orcamento' && hoursBetween(creation, now) < 6
    },
    validateCustoTotal() {
      let custo_total = 0;
      if(this.item.custo_total) {
        this.fromContasPagar = true;
        custo_total = this.item.custo_total;
      } else {
        custo_total = this.cotacaoCustoTotal(this.item)
      }
      return custo_total;
    }
  },

  watch: {
    selected: {
      handler: function (val) {
        this.isSelected = this.selected;
      },
    },
  },

  created() {
    this.formAjuste = this.item && this.item.ajustes;
  },

  methods: {
    converteValorFinanceiroBRL(valorFinanceiro) {
      return converteValorFinanceiroBRL(valorFinanceiro);
    },

    enviarCarrinhoItem() {
      const { item, venda_id } = this;
      api.addCarrinho(venda_id, item).then((response) => {
        this.$store.dispatch("venda/fetchCarrinho", true);
        this.$root.vtoast.show({
            message: 'Cotação enviada para o carrinho!',
            color: 'success',
            icon: 'mdi-check',
        })
      });
      this.item.status = ""
    },

    confirmCarrinho() {
      this.dialog = false;
      this.enviarCarrinhoItem();
    },

    updateCotacaoAjuste(item) {
      this.loadingAjuste = true;
      this.loadingAjuste = false;
      this.edittingCotacao = false;
      this.item.ajustes = this.formAjuste;
    },

    showFornecedores() {
      this.dialogFornecedores = true;
      this.loadingFornecedores = true;
      this.fornecedores = getListFornecedores(this.item);
      this.loadingFornecedores = false;
    },
    viewCotacaoItem(cotacao) {
      this.allowSave = false;
      this.edittingItem = cotacao;
      this.isVisualizacao = true;
      this.dialogEditCotacao = true;
    },
    closeEditItem() {
      this.dialogEditCotacao = false;
      this.edittingItem = null;
    },
  },
};
</script>
<style scoped>
.desc {
  white-space: pre-wrap;
}
</style>
