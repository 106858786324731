<template>
  <v-container>
    <v-row>
      <v-text-field
        readonly
        :label="label || 'Contato'"
        :value="cliente | nomeSobrenome"
        :disabled="disabled"
        :error-messages="errors"
        placeholder="Nenhum contato selecionado"
        @click:clear="select(null)"
        @click:prepend-inner="showSearch = true"
        @click="showSearch = true"
        outlined
        prepend-inner-icon="mdi-magnify"
        clearable
      />
      <v-dialog
        v-model="showSearch"
        :persistent="true"
        max-width="1200"
        :disabled="disabled"
      >
        <SelectContatos
          @close="showSearch = false"
          @input="select"
          @refresh="loadContatos"
          :numPages="numPages"
          :contatos="contatos"
          :filters="filters"
          :isButtonDisabled="isButtonDisabled"
        />
      </v-dialog>
    </v-row>
  </v-container>
</template>
<script>
import SelectContatos from "@/components/widgets/SelectContatos";
import api from "@/api/api";

export default {
  components: { SelectContatos },
  props: {
    defaultContatos: {
      type: Array,
      default: null,
    },
    value: [Object, Number],
    emptyLabel: String,
    label: {
      type: String,
      default: "Contato",
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    filters: {
      type: Array,
      default: () => [],
    },
    isButtonDisabled: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      cliente: null,
      showSearch: false,
      page: 1,
      allContatos: [],
      numPages: 1,
    };
  },
  watch: {
    value: {
      immediate: true,
      deep: true,
      handler: function (val) {
        this.cliente = val;
      },
    },
  },

  computed: {
    errors() {
      if (this.emptyLabel && this.cliente == null) {
        return [this.emptyLabel];
      }
      return [];
    },

    contatos() {
      return this.allContatos;
    },
  },

  created() {
    if (!this.value) return;
    this.cliente = this.value;
  },

  async mounted() {
    this.loadContatos();
  },

  methods: {
    select(item) {
      this.cliente = item;
      this.$emit("input", item);
    },

    loadContatos(
      { page, filters } = { page: this.page, filters: this.filters }
    ) {
      api
        .getContatos(page, filters)
        .then((response) => {
          this.allContatos = response.data.contatos;
          this.numPages = response.data.pages;
        })
        .catch((err) => {
          this.$root.vtoast.show({ message: err.message, type: "error" });
        });
    },
  },
};
</script>
