<template >
    <div class="page">
        <div v-for="(cotacao, idxCotacao) in cotacoes" class="cotacao-container">
            <Header/>
            <h3 class="cotacao">Cotação #{{idxCotacao + 1}} (id: {{cotacao.id}})</h3>
            <div class="hotel-info">
                <div class="column">
                    <p>Nome do Hotel</p>
                    <p>Local Hotel</p>
                    <p>Checkin</p>
                    <p>Checkout</p>
                    <p v-if="cotacao.cotacao_hotel.diarias">Diárias</p>
                    <p v-if="cotacao.cotacao_hotel.diarias_min">Mínimo de diárias</p>
                    <p v-if="cotacao.cotacao_hotel.diarias_max">Máximo de diárias</p>
                    <p>Quartos</p>
                    <p v-if="checkAdultos(cotacao)">Adultos</p>
                    <p v-if="checkCrianca(cotacao)">Crianças</p>
                    <p>Café da manhã incluso</p>
                </div>
                <div class="column right">
                    <p>{{cotacao.cotacao_hotel.nome_hotel}}.</p>
                    <p>{{cotacao.cotacao_hotel.local_hotel.nome}}.</p>
                    <p>{{convert(cotacao.cotacao_hotel.checkin_inicio, cotacao.cotacao_hotel.checkin_fim)}}</p>
                    <p>{{convert(cotacao.cotacao_hotel.checkout_inicio, cotacao.cotacao_hotel.checkout_fim)}}</p>
                    <p v-if="cotacao.cotacao_hotel.diarias">{{cotacao.cotacao_hotel.diarias}}</p>
                    <p v-if="cotacao.cotacao_hotel.diarias_min">{{cotacao.cotacao_hotel.diarias_min}}</p>
                    <p v-if="cotacao.cotacao_hotel.diarias_max">{{cotacao.cotacao_hotel.diarias_max}}</p>
                    <p>{{cotacao.cotacao_hotel.quartos}}</p>
                    <p v-if="checkAdultos(cotacao)">{{cotacao.cotacao_hotel.adultos}}</p>
                    <p v-if="checkCrianca(cotacao)">{{cotacao.cotacao_hotel.criancas}}</p>
                    <p>{{cotacao.cotacao_hotel.cafe ? 'Sim' : 'Não' }}</p>
                </div>
            </div>
            <div class="precos">
                <p>{{format(total(cotacao))}}</p>
                <p>Diária: {{format(diaria(cotacao))}}</p>
            </div>
            <div class="html2pdf__page-break"></div>
        </div>
        <resumo-orcamento :cotacoes="cotacoes" />
    </div>
</template>
<script>
import Header from '../Template/Header.vue'
import CotacaoMixin from "@/mixins/CotacaoMixin";
import ResumoOrcamento from "./ResumoOrcamento.vue";

export default {
    components: {
        Header,
        ResumoOrcamento
    },
    props: {
        cotacoes: {
            type: Array,
            default: () => [],
            required: true
        }
    },
    mixins: [CotacaoMixin],
    methods: {
        convert(dateIn, dateOut){
            const formatedDateIn = new Date(dateIn).toLocaleDateString('pt-BR')
            const formatedDateOut = new Date(dateOut).toLocaleDateString('pt-BR')
            if(dateOut) return [formatedDateIn, formatedDateOut].join(" - ")
            return formatedDateIn
        },
        format(num){
            if(!num) return "R$ 0,00"
            if(num.valor) num = this.converteValorFinanceiroBRL(num).toFixed(2)
            return this.$options.filters['dinheiroReal'](num)
        },
        total(cotacao){
            return this.cotacaoValorTotal(cotacao)
        },
        checkAdultos(cotacao){
            return cotacao.cotacao_hotel.adultos > 0
        },
        checkCrianca(cotacao){
            return cotacao.cotacao_hotel.criancas > 0
        },
        diaria(cotacao){
            return this.converteValorFinanceiroBRL(cotacao.cotacao_hotel.custo_diaria) + 
                this.cotacaoValorAjuste(cotacao)/cotacao.cotacao_hotel.diarias
        },
    }
}
</script>
<style >
.precos {
    background-color: #819486;
    border-radius: 15px;
    color: white;
    margin: 3rem 1rem 0 1rem ;
    padding: 1rem;
} 
.precos > p:nth-child(1){
    font-size: 1.5rem;
    margin-left: 1rem;
    margin-bottom: 2rem;
}
.hotel-info{
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: space-between;
}
.cotacao-container > h3 {
    margin-left: 0;
    color: white;
    background-color: #819486;
    border-radius: 15px;
    padding: 1.5rem;
    text-align: center;
}
.column{
    padding: 0;
    margin: 1rem;
}
.right{
    text-align: right
}
.cotacao{
    padding-left: 4rem;
    margin: 0.5rem 0 0.5rem 0
}
</style>