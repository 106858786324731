<template>
  <div v-if="exportPdf">
    <v-card>
      <v-card-title>
        <span class='text-h5'>Exportando PDF da venda.</span>
      </v-card-title>
      <div  class="box">
        <div ref="print"> 
          <Pdf 
            v-if="vendas.length"
            :servicos="servicos"
            :vendas="vendas"
            :valorTotal="valorTotal"
            :valorAbertoCotacoes="valorAbertoCotacoes"
            :valorAbertoVendas="valorAbertoVendas"
            :beneficiarios="beneficiarios"
          ></Pdf>
          <div v-else>
            Por favor selecione pelo menos uma venda.
          </div>
        </div> 
      </div>
      <v-card-actions class="ma-0">
        <v-spacer></v-spacer>
        <v-btn text @click="cancel">Cancelar</v-btn>
        <v-btn color="primary" @click="save">Salvar</v-btn>
      </v-card-actions>
    </v-card>
  </div>
  <div v-else>
    Loading...
  </div>
</template>
<script>
// STILL NEEDS TO UPDATE THE REFERED DIV TO EXPORT DESIRED INFOS 
import Pdf from './VendaPDF/Pdf'
import html2pdf from 'html2pdf.js'

export default {
  components: {
    Pdf
  },
  props: {
    exportPdfData: {
      type: Object,
      default: () => {
      },
      required: true
    },
    
    servicos: {
      type: Array,
      default: () => []
    },
    vendas: {
      type: Array,
      default: () => []
    },
    valorTotal: {
      type: Object,
      default: () => {}
    },
    valorAbertoCotacoes: {
      type: Object,
      default: () => {}
    },
    valorAbertoVendas: {
      type: Object,
      default: () => {}
    },
    beneficiarios: {
      type: Array,
      default: () => []
    }
  },

  data() {
    return {
      loading: false,
      exportPdf: null,
    }
  },
  created() {
    this.initialize();
  },

  methods: {
    initialize() {
        this.exportPdf = this.exportPdfData;
        this.loading = false;
    },
    cancel() {
        this.exportPdf = false;
        this.$emit('close');
    },
    // TODO passar isso pra pdf.js
    async savePDF (element) {
    html2pdf().set({
        pagebreak: { mode: ['avoid-all', 'css', 'legacy'], after: '.page' }
      });
      html2pdf(element, {
        useCORS: true,
        allowTaint: true,
              filename: 'document.pdf',
              html2canvas: { scale: 1.1 },
              jsPDF: { unit: 'pt', format: 'a4', orientation: 'portrait' }
      })
    },
    async save() {
        const element = this.$refs.print;
        await this.savePDF(element)
    }
  }
}
</script>
<style>
  .box {
      margin: 2rem;
      padding: 2rem;
  }
</style>
