<template>
    <v-container fluid v-if="loading">
        Loading...
    </v-container>
    <v-container fluid v-else>
        <venda-cotacoes-list :cotacoes="cotacoes" :carrinho="carrinho" />
    </v-container>
</template>


<script>
import {mapState} from "vuex";

import VendaCotacoesList from "@/components/venda/VendaCotacaoList";

export default {
    components: { VendaCotacoesList },

    data() {
        return {
            loading: false,
        }
    },

    async mounted() {
        this.loading = true
        await this.$store.dispatch("venda/fetchCotacoes")
        this.loading = false
    },

    computed: {
        ...mapState({
            orcamentos: state => state.venda.orcamentos,
            venda: state => state.venda.venda,
            cotacoes: state => state.venda.cotacoes,
            carrinho: state => state.venda.carrinho
        })
    },

    methods: {

    }
}
</script>
