import api from "@/api/api";

const contatoStore = {
    namespaced: true,
    state: {
        contato: null,
        contatos: [],
        contatosNumPages: 0,
        loading: false,
    },

    getters: {
        contato: state => state.contato,
        contatos: state => state.contatos
    },

    mutations: {
        SET_LOADING(state, value) {
            state.loading = value;
        },
        SET_CONTATO(state, contato) {
            state.contato = contato
        },
        SET_CONTATOS(state, contatos) {
            state.contatos = contatos
        },
        SET_VALUE(state, { key, value }) {
            state[key] = value;
        },
    },

    actions: {
        async fetchContato({ commit }, id) {
            commit("SET_CONTATO", null)
            commit("SET_LOADING", true);
            let error = false;

            const res = await api.getContato(id).then(res => res).catch(err => {
                error = true;
                return err;
            });

            res["error"] = error;
            commit("SET_LOADING", false);

            if (!res.error) {
                let contato = res.data
                commit("SET_CONTATO", contato)
            }

            return res;
        },

        async fetchContatos({commit, state}) {
            commit("SET_VALUE", {key: "loading", value: true});
            
            return await api.getContatos(0).then(res => {
                commit("SET_VALUE", {key: "loading", value: false});
                commit("SET_VALUE", {key: "contatos", value: res.data});
                return res;
            }).catch(err => {
                commit("SET_VALUE", {key: "loading", value: false});
                throw err;
            })
        },

        async fetchRelacoesEmpresa({ commit }, empresa_id) {
            commit("SET_LOADING", true);

            const res = await api.getEmpresaRelacoes(empresa_id)
                .then(res => res)
                .catch(err => {
                    error = true;
                    return err;
                });

            res["error"] = error;
            commit("SET_LOADING", false);

            if (!res.error) {
                let contatos = res.data
                commit("SET_CONTATOS", contatos)
            }

            return res;
        },

        // pagination type: object = {page: int, filters: Array}
        async fetchContatosPaginated({commit, state}, pagination) {
            commit("SET_LOADING", true);

            const page = pagination.page || 1
            const filters = pagination.filters || []
            
            return await api.getContatos(page, filters).then(res => {
                commit("SET_CONTATOS", res.data.contatos);
                commit("SET_VALUE", {key: "contatosNumPages", value: res.data.pages});
                return res;
            }).catch(err => {
                throw err;
            }).finally(() => {
                commit("SET_LOADING", false);
            })
        },
    },
}

export default contatoStore
