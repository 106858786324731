<template>
  <v-card rounded="10" raised elevation="10" class="my-10">
    <v-row>
      <v-col>
        <v-data-table :headers="headers" :items="emissoes" sort-by="id" sort-desc :loading="loadingTable">
          <template v-slot:item.id="{ item, value }">
            <v-link-text-field :to="{ name: `emissao`, params: { id: item.id } }" text small>
              {{ item.id }}
            </v-link-text-field>

          </template>
          <template v-slot:item.consultor="{ item, value }">
            {{ value | nomeSobrenome }}
          </template>

          <template v-slot:item.status="{ value }">
            <StatusEmissao :status="value" />
          </template>

          <template v-slot:item.servico_status="{ item }">
            {{ item.servico.status | formatStatusServico }}
          </template>
          <template v-slot:item.venda="{ item }">
            <v-link-text-field :to="{ name: `venda-info`, params: { id: item.venda.id } }" text small>
              Ven: {{ (item.venda ? item.venda.id : 0) | formatVendaId }}
            </v-link-text-field>
          </template>
          <template v-slot:item.cotacao="{ item }">
            <v-link-text-field :to="{ name: `orcamento`, params: { id: item.servico.cotacao.orcamento.id } }" text small>
              Cot: {{ item.servico.cotacao.id }}
            </v-link-text-field>

          </template>
          <template v-slot:item.orcamento="{ item }">
            <v-link-text-field :to="{ name: `orcamento`, params: { id: item.servico.cotacao.orcamento.id } }" text small>
              Orç: {{ item.servico.cotacao.orcamento.id }}
            </v-link-text-field>
          </template>
          <template v-slot:item.servico_id="{ item }">
            Serv: {{ item.servico.id }}
          </template>

          <template v-slot:item.cliente="{ item }">
            {{ item?.venda?.cliente | nomeSobrenome }}
            {{ item?.venda?.cliente_empresa?.nome }}
          </template>

          <template v-slot:item.actions="{ item }">
            <v-btn icon color="transparent" :to="{ name: 'emissao', params: { id: item.id } }">
              <v-icon small color="primary" class="mr-2" @click="item">mdi-open-in-new
              </v-icon>
            </v-btn>
          </template>

          <template v-slot:item.data_hora_emissao="{ item }">
            <div v-if="!item.data_hora_emissao">---</div>
            <div v-else>
              {{ item.data_hora_emissao | dataHoraBR }}
            </div>
          </template>

          <template v-slot:item.created_at="{ item }">
            <div v-if="!item.created_at">---</div>
            <div v-else>
              {{ item.created_at | dataHoraBR }}
            </div>
          </template>

          <template v-slot:item.servico="{ item }">
            <span v-if="item.subcotacao_voo">
              {{
                item.subcotacao_voo?.viagem?.trechos[0]?.pernas[0]?.data_hora_partida | convertDataBR
              }}
            </span>
          </template>

          <template v-slot:item.tipo="{ item }">
            <div v-if="item.tipo === 'emissao_voo_pagante'">
              <v-icon small color="green"> mdi-cash </v-icon>
              Pagante
            </div>
            <div v-if="item.tipo === 'emissao_voo_milha'">
              <v-icon small color="yellow darken-2"> mdi-airplane </v-icon>
              Milha
            </div>
            <div v-if="item.tipo === 'emissao_voo_wallet'">
              <v-icon small color="yellow darken-2"> mdi-wallet</v-icon>
              Wallet
            </div>
            <div v-if="item.tipo === 'emissao_hotel'">
              <v-icon small color="yellow darken-2"> mdi-home</v-icon>
              Hotel
            </div>
            <div v-if="item.tipo === 'emissao_carro'">
              <v-icon small color="yellow darken-2"> mdi-car</v-icon>
              Carro
            </div>
            <div v-if="item.tipo === 'emissao_outro'">
              <v-icon small color="yellow darken-2"> mdi-help-circle</v-icon>
              Outro
            </div>
          </template>

          <template v-slot:no-data> Nenhuma emissão encontrada </template>
        </v-data-table>
      </v-col>
    </v-row>
  </v-card>
</template>

<script>
import api from "@/api/api";
import VLinkTextField from "@/components/widgets/VLinkTextField.vue";
import StatusEmissao from "@/components/widgets/StatusEmissao.vue";

export default {
  components: { VLinkTextField, StatusEmissao },
  data() {
    return {
      emissoes: [],
      loadingTable: false,
      headers: [
        {
          text: "ID",
          align: "start",
          sortable: true,
          value: "id",
        },
        {
          text: "Venda",
          value: "venda",
        },
        {
          text: "Orçamento",
          value: "orcamento",
        },
        {
          text: "Cotacao",
          value: "cotacao",
        },
        {
          text: "Serviço",
          value: "servico_id",
        },
        {
          text: "Cliente",
          value: "cliente",
        },
        {
          text: "Status",
          value: "status",
        },
        {
          text: "Status Servico",
          value: "servico_status",
        },
        {
          text: "Data de Conclusão",
          value: "data_hora_emissao",
        },
        {
          text: "Data de Criação",
          value: "created_at",
        },
        {
          text: "Tipo",
          value: "tipo",
        },
        {
          text: "Partida",
          value: "servico",
        },
      ],
    };
  },
  mounted() {
    this.initialize();
  },
  created() {
  },

  methods: {
    initialize() {
      this.loading = true
      const cliente_id = this.$route.params.id;
      api.getContatoEmissoesBeneficiario(cliente_id).then((response) => {
        this.emissoes = response.data;
      }).catch(e => {
        this.$root.vtoast.show({
          message: 'Erro ao buscar emissões',
          color: 'error',
          icon: 'mdi-close',
        })
      }).finally(() => {
        this.loadingTable = false
      })
    },


  },
  computed: {

  },
};
</script>
