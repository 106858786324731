<template>
  <div v-if="devMode && !hide">
    <v-row>
      <v-col>
        {{ label }}
      </v-col>
      <v-spacer></v-spacer>
      <v-col cols="1" v-if="hideButton === false">
        <v-btn @click="hide = true">Hide</v-btn>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <json-viewer :expand-depth="3" copyable :value="json" v-if="json">
        </json-viewer>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import JsonViewer from "vue-json-viewer";

export default {
  components: { JsonViewer },
  props: {
    label: {
      type: String,
      required: false,
      default: "",
    },
    json: {
      type: [Object, Array],
      required: false,
    },
    devMode: {
      type: Boolean,
      required: false,
      default: false,
    },
    hideButton: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  data() {
    return {
      hide: false,
    };
  },
};
</script>
