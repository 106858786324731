<template>
  <v-autocomplete
    dense
    :label="label"
    :items="cidades"
    v-model="selected"
    item-text="text"
    item-value="id"
    :loading="loading"
    :outlined="outlined"
    @input="$emit('input', selected)"
  >
  </v-autocomplete>
</template>

<script>
import {formatCidades} from  '@/helpers/cidades'

export default {
  props: {
    label: String,
    value: [String, Object],
    outlined: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      selected: this.value,
      loading: false,
    };
  },
  computed: {
    cidades() {
      const cidades = this.$store.state.global.cidades;
      return formatCidades(cidades);
    },
  },
  async mounted() {
    this.loading = true;
    const res = await this.$store
      .dispatch("global/fetchCidades")
      .catch((err) => {
        this.$root.vtoast.show({
          message: "Erro ao carregar cidades",
          color: "error",
        });
        this.$root.vtoast.show({ message: err.message, color: "error" });
      });
    this.loading = false;
  },
};
</script>
