import api from "@/api/api";
import {formatApiError} from "@/helpers/apiErrors"

export default {

    data() {
        return {
            loadingSalvarEmissao: false, loadingUpdateBeneficiario: false
        }
    }, 
    
    methods: {

        async salvarEmissao(emissao) {
            this.loadingSalvarEmissao = true;
            const emissaoId = emissao.id;
            const res = await api.updateEmissao(emissaoId, emissao).then(res => {
                return res.data;
          }).catch(
            err => {
                console.error(err)
                window.alert(formatApiError(err))
                this.loadingSalvarEmissao = false;
                 this.$root.vtoast.show({message: "Erro ao salvar emissão.", color: "error", icon:'mdi-close'});
            }
        )
            this.loadingSalvarEmissao = false;
            return res;
        }, 

        async updateBenefeciario(beneficiario) {
            this.loadingUpdateBeneficiario = true;
            const id = beneficiario && beneficiario.id;
            const res = await api.updateBeneficiario(id, beneficiario).then(res => {
                this.$root.vtoast.show({message: "Beneficiário atualizado com sucesso!", color: 'success'})
                return {
                    error: false
                };
            }).catch(err => {
                this.loadingUpdateBeneficiario = false;
                this.$root.vtoast.show({message: "Erro ao atualizar beneficiário.", color: "error"});
                console.error(err);
                return {
                    error: true
                }
            })
            this.loadingUpdateBeneficiario = false;
            return res;
        }, 

        
        
    }
}