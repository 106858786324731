export default [
    {
        key: "fila_de_emissao",
        value: "Fila de emissão",
        text: "white",
        background: "orange",
    },
    {
        key: "cancelada",
        value: "Cancelada",
        text: "white",
        background: "red",
    },
    {
        key: "finalizada",
        value: "Finalizada",
        text: "black", 
        background: "green",
    },
    {
        key: "rac",
        value: "RAC",
        text: "white", 
        background: "purple",
    },
]