<template>
  <v-data-table :headers="headers" :items="tableItems" class="elevation-5">
    <template v-slot:top>
      <v-toolbar flat>
        <v-toolbar-title>Preço de Custo de Milhas</v-toolbar-title>
        <v-divider class="mx-4" inset vertical></v-divider>
        <v-spacer></v-spacer>
        <milha-propria-new-item
          :item="editedItem"
          :newItem="editedIndex === -1"
          v-model="dialog"
          :formatProgramaFidelidade="formatProgramaFidelidade"
          :formatCiaAerea="formatCiaAerea"
          @save="save"
          @delete="close"
        />
        <v-dialog v-model="dialogDelete" max-width="500px">
          <v-card>
            <v-card-title class="text-h5"
              >Tem certeza que deseja arquivar este item?</v-card-title
            >
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="blue darken-1" text @click="closeDelete"
                >Cancelar</v-btn
              >
              <v-btn
                color="blue darken-1"
                text
                @click="deleteItemConfirm(editedItem.id)"
                >OK</v-btn
              >
              <v-spacer></v-spacer>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-toolbar>
    </template>
    <template v-slot:item.programa="{ item }">
      {{ formatProgramaFidelidade(item.programa) }}
    </template>
    <template v-slot:item.actions="{ item }">
      <v-icon small class="mr-2" @click="editItem(item)"> mdi-pencil </v-icon>
    </template>
    <template v-slot:no-data>
      <v-btn color="primary" @click="initialize"> Reset </v-btn>
    </template>
  </v-data-table>
</template>
<script>
import MilhaPropriaNewItem from "./MilhaPropriaNewItem";
import api from "@/api/api.js";
import { PRECOS_RANGE } from "@/constants/milhas";

export default {
  components: { MilhaPropriaNewItem },
  props: {
    formatCiaAerea: {
      type: Function,
      default: () => {},
    },

    formatProgramaFidelidade: {
      type: Function,
      default: () => {},
    },
  },
  data: () => ({
    dialog: false,
    dialogDelete: false,
    precosRange: PRECOS_RANGE,
    headers: [
      {
        text: "Programa",
        align: "start",
        sortable: false,
        value: "programa",
      },
      { text: "Custo Próprio", value: "custo_proprio" },
      { text: "Custo Terceiros", value: "custo_terceiros" },
      { text: "Ações", value: "actions", sortable: false },
    ],
    items: [],
    editedIndex: -1,
    editedItem: {
      id: 0,
      programas: [0],
      precos: PRECOS_RANGE.map((e) => 0),
      piso: 0,
    },
    defaultItem: {
      id: 0,
      programas: [0],
      precos: PRECOS_RANGE.map((e) => 0),
      piso: 0,
    },
  }),

  computed: {
    programas() {
      return this.$store.state.global.programas;
    },
    tableItems() {
      return this.items.filter(item => item.tipo==='custo')
    },
  },

  watch: {
    dialog(val) {
      val || this.close();
    },
    dialogDelete(val) {
      val || this.closeDelete();
    },
  },

  created() {
    this.initialize();
  },

  methods: {
    initialize() {
      api.getTabelaMilhas().then((response) => {
        this.items = response.data;
      });
    },

    validateData() {
      if (
        this.editedItem.precos.some((preco) => parseFloat(preco) <= 0)
      ) {
        this.$root.vtoast.show({
          message: "Valores não podem ser negativos",
          icon: "mdi-close",
          color: "error",
        });
        return false;
      }
      return true;
    },

    editItem(item) {
      this.editedIndex = this.items.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.dialog = true;
    },

    deleteItemConfirm(id) {
      api.deleteTabelaMilhas(id).then((response) => {
        this.closeDelete();
      });
    },

    close() {
      this.dialog = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },

    closeDelete() {
      this.dialogDelete = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },

    async save() {
      if (!this.validateData()) return;
      // confirmação do usuário
      if (
        !confirm(
          "Tem certeza que deseja alterar o valor das milhas desse programa? Essas alterações serão salvas e mantidas em instancias futuras."
        )
      )
        return;
      // chamada api
      await api
        .putTabelaMilhas(this.editedItem.id, this.editedItem)
        .then((response) => {
          this.items = this.items.map((item) =>
            response.data.id === item.id
              ? {
                  ...response.data,
                }
              : item
          );
          this.$root.vtoast.show({
            message: "Valor alterado com sucesso!",
            icon: "mdi-check",
            color: "success",
          });
        });
      this.close();
    },
  },
};
</script>
