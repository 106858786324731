<template>
  <v-menu
    ref="menu"
    v-model="menu"
    :close-on-content-click="false"
    :nudge-right="40"
    transition="scale-transition"
    offset-y
    min-width="auto"
  >
    <template v-slot:activator="{ on, attrs }">
      <v-text-field
        :disabled="disabled"
        :outlined="outlined"
        :dense="dense"
        :label="label"
        :prepend-inner-icon="icon"
        v-model="formattedDates"
        v-bind="attrs"
        v-on="on"
        readonly
        :name="name"
        :append-icon="appendIcon"
        @click:append="() => $emit('toggleShowHoraMinuto')"
        :clearable="clearable"
        @click:clear="()=> $emit('clear')"
      />
    </template>
    <v-date-picker v-model="date" />
  </v-menu>
</template>
<script>
import DateSkyler from '@/helpers/Date/date';
export default {
  props: {
    value: {
      type: [Date, Object, String],
      required: false,
      default: function () { return new Date() }
    },
    label: String,
    outlined: {
      type: Boolean,
      default: false,
    },
    name: {
      type: String,
      default: "",
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    icon: {
      type: String,
      default: "mdi-calendar",
    },
    dense: {
      type: Boolean,
      default: true,
    },
    clearable: {
      type: Boolean,
      default: true,
    },
    appendIcon: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      date: this.value,
      menu: false,
    };
  },
  computed: {
    formattedDates: {
      get() {
        return this.$options.filters.convertDataBR(this.date);
      },
      set(value) {
        return value
      }
    },
  },
  watch: {
    date: {
      immediate: true,
      handler: function (datesFromDatePicker) {
        if (!datesFromDatePicker) return;
        this.$emit("input", DateSkyler.parseISOtoUTCDate(this.date));
      }
    },
  },
  mounted() {
    if (!this.date) this.date = "";
    else this.date = DateSkyler.dateToString(this.date)
  },
};
</script>
