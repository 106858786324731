<template>
  <v-container fluid class="ma-0">
    <v-row>
      <v-col>
        <v-row>
          <div class="text-subtitle-1 mx-4 font-weight-bold">Cotação</div>
        </v-row>
        <v-row>
          <v-container fluid class ma-2>
            <!--     Nenhum orçamento passado como opção     -->
            <v-row v-if="!orcamento"> Nenhum orçamento vinculado </v-row>

            <!--        Hotel   -->
            <v-row v-else-if="orcamento.tipo_servico === 'orcamento_hotel'">
              <cotacao-edit-form-secao-cotacao-hotel
                :orcamento="orcamento"
                v-model="formCotacao.cotacao_hotel"
              />
            </v-row>
            <!--        Carro   -->
            <v-row v-else-if="orcamento.tipo_servico === 'orcamento_carro'">
              <cotacao-edit-form-secao-cotacao-carro
                :orcamento="orcamento"
                v-model="formCotacao.cotacao_carro"
              />
            </v-row>

            <!--        Outro   -->
            <v-row v-else-if="orcamento.tipo_servico === 'orcamento_outro'">
              <cotacao-edit-form-secao-cotacao-outro
                :orcamento="orcamento"
                v-model="formCotacao.cotacao_outro"
              />
            </v-row>

            <!--        Voo   -->
            <v-row v-else-if="orcamento.tipo_servico === 'orcamento_voo'">
              <cotacao-edit-form-secao-cotacao-voo
                :orcamento="orcamento"
                :value="valueCotacaoVoo"
                v-model="formCotacao.cotacao_voo"
              />
            </v-row>

            <!--            Nenhuma das opções acima -->

            <v-row v-else>
              <v-alert color="error">
                Problema técnico: Orçamento não possui 'tipo_servico'
              </v-alert>
            </v-row>
          </v-container>
        </v-row>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import CotacaoEditFormSecaoCotacaoHotel from "./CotacaoEditFormSecaoCotacaoHotel";
import CotacaoEditFormSecaoCotacaoCarro from "./CotacaoEditFormSecaoCotacaoCarro";
import CotacaoEditFormSecaoCotacaoOutro from "./CotacaoEditFormSecaoCotacaoOutro";
import CotacaoEditFormSecaoCotacaoVoo from "./CotacaoEditFormSecaoCotacaoVoo";

export default {
  components: {
    CotacaoEditFormSecaoCotacaoVoo,
    CotacaoEditFormSecaoCotacaoOutro,
    CotacaoEditFormSecaoCotacaoCarro,
    CotacaoEditFormSecaoCotacaoHotel,
  },
  props: {
    orcamento: {
      type: Object,
      default: null,
    },
    value: {
      type: Object,
      default: null,
    },
    item: { // item == cotação
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
        formCotacao: this.value ? { ...this.value } : {},
        valueCotacaoVoo: this.value?.cotacao_voo? this.value.cotacao_voo : {}, 
        // formCotacao: this.value,
    };
  },
  mounted() {
    this.$nextTick(async () => {
      const filters = []

      if(this.value.cotacao_voo) filters.push(["tipo", "aereo"])
      if(this.value.cotacao_hotel) filters.push(["tipo", "hospedagem"])
      if(this.value.cotacao_carro) filters.push(["tipo", "locadora_de_carro"])
      if(this.value.cotacao_outro) filters.push(["tipo", "outro"])

      await this.$store.dispatch("global/fetchFornecedores", filters).catch((err) => {
        this.$root.vtoast.show({ message: err.message, type: "error" });
      });
    })
  },
  watch: {
    formCotacao: {
      deep: true,
      handler: function (val) {
        this.$emit("input", val);
      },
    },
  },
};
</script>
