import { get, post }  from '../modules'

export default {
    login(username, password) {
        return post("/api/login/", {username, password});
    },

    login_otp(otp) {
        return post("/api/otp_verification/", {otp});
    },

    logout() {
        return post("/api/logout/");
    },

    whoami() {
        return get("/api/whoami/");
    },

    getUsuarios() {
        return get("/api/users/web_ativos/");
        // * mudança de rota excluindo os usuários inativos e os mobile
    }
}
