<template>
  <v-card>
    <v-card-title
      >Descrição Serviços -
      {{ cotacaoTipoServico(servico.cotacao) }}</v-card-title
    >
    <v-card-text>
      <v-container>
        <v-row>
          <v-col>
            <v-row>
              <v-col class="font-weight-bold"> Valor Inicial: </v-col>
              <v-col>
                {{ cotacaoCustoTotal(servico.cotacao) | dinheiroReal }}
              </v-col>
            </v-row>
            <v-row>
              <v-col class="font-weight-bold"> Valor Ajuste: </v-col>
              <v-col>
                {{ cotacaoValorAjuste(servico.cotacao) | dinheiroReal }}
              </v-col>
            </v-row>
            <v-row>
              <v-col class="font-weight-bold"> Valor Pós-Ajuste: </v-col>
              <v-col>
                {{ cotacaoValorTotal(servico.cotacao) | dinheiroReal }}
              </v-col>
            </v-row>
            <v-row>
              <v-col class="font-weight-bold"> Valor 12x: </v-col>
              <v-col>
                {{ cotacaoValorTotalJuros(servico.cotacao) | dinheiroReal }}
              </v-col>
            </v-row>
            <v-row>
              <v-col class="font-weight-bold"> Desconto: </v-col>
              <v-col>
                {{
                  converteValorFinanceiroBRL(servico.desconto) | dinheiroReal
                }}
              </v-col>
            </v-row>
            <v-row>
              <v-col class="font-weight-bold"> Receitas: </v-col>
              <v-col>
                {{ servicoValorTotal(servico) | dinheiroReal }}
              </v-col>
              <!-- <v-col
                v-if="
                  servicoValorTotal(servico) <
                  cotacaoCustoTotal(servico.cotacao)
                "
                :style="{ color: 'red' }"
              >
                Menor
              </v-col> -->
            </v-row>
          </v-col>
        </v-row>
        <v-divider class="mb-2" />
        <v-col>
          <v-row class="font-weight-bold ma-0"> Custos: </v-row>
          <v-row class="ml-2 pb-0">
            <v-col>
              <v-row v-if="servico?.cotacao?.cotacao_voo">
                <v-col>
                  <v-row
                    v-for="subcotacao in servico?.cotacao?.cotacao_voo
                      ?.subcotacoes"
                    :key="subcotacao.id"
                  >
                    <v-col>
                      <v-row class="font-weight-bold">
                        Subcotação #{{ subcotacao.id }} -
                        {{ subcotacao.tipo_cotacao }}</v-row
                      >

                      <v-row
                        v-if="subcotacao.tipo_cotacao === 'pagante'"
                        v-for="[key, value] in Object.entries(
                          custosServicoVooSubcotacaoPrecificadores(subcotacao)
                        )"
                        :key="key"
                      >
                        <v-col
                          align-self="auto"
                          class="font-weight-bold"
                          v-if="key !== 'Custo assento conforto'"
                          >{{ key }}</v-col
                        >
                        <v-col
                          align-self="auto"
                          v-if="key !== 'Custo assento conforto'"
                          >{{ value | dinheiroReal }}</v-col
                        >

                        <v-col v-if="emissao == 'finalizada'">
                          <v-field-value
                            v-if="key == 'Taxa de embarque'"
                            label="Responsável"
                            >{{
                              getCustoTaxaEmbarqueResponsavel()
                            }}</v-field-value
                          >
                          <v-field-value
                            v-if="key == 'Taxa de bagagem'"
                            label="Responsável"
                          >
                            {{
                              getCustoTaxaBagagemResponsavel()
                            }}</v-field-value
                          >
                          <v-field-value
                            v-if="key == 'Taxas extras'"
                            label="Responsável"
                          >
                            {{ getCustoTaxaExtraResponsavel() }}
                          </v-field-value>
                          <v-field-value
                            v-if="key == 'RAV via fornecedor'"
                            label="Responsável"
                          >
                            {{ getRavResponsavel() }}
                          </v-field-value>

                          <v-field-value
                            v-if="key == 'Custo bilhete'"
                            label="Responsável"
                          >
                            {{ getCustoBilheteResponsavel() }}
                          </v-field-value>
                        </v-col>
                      </v-row>
                      <v-row
                        class="mb-2"
                        v-if="
                          subcotacao.tipo_cotacao === 'milha' &&
                          servico.status !== 'emissao'
                        "
                      >
                        Custos para milhas só estão disponíveis para serviços em
                        emissão
                      </v-row>
                      <v-row
                        v-if="
                          subcotacao.tipo_cotacao === 'milha' &&
                          servico.status === 'emissao'
                        "
                      >
                        <v-col>
                          <v-row>
                            <v-col>
                              <v-row>
                                <v-col class="font-weight-bold">
                                  Taxa de embarque</v-col
                                >
                                <v-col>{{
                                  converteValorFinanceiroBRL(
                                    subcotacao.custo_taxa_embarque
                                  ) | dinheiroReal
                                }}</v-col>

                                <v-col v-if="emissao == 'finalizada'"
                                  ><v-field-value label="Responsável">{{
                                    getCustoTaxaEmbarqueResponsavel()
                                  }}</v-field-value></v-col
                                >
                              </v-row>
                              <v-row>
                                <v-col class="font-weight-bold">
                                  Taxa de bagagem</v-col
                                >
                                <v-col>{{
                                  converteValorFinanceiroBRL(
                                    subcotacao.custo_taxa_bagagem
                                  ) | dinheiroReal
                                }}</v-col>
                                <v-col v-if="emissao == 'finalizada'"
                                  ><v-field-value label="Responsável">{{
                                    getCustoTaxaBagagemResponsavel()
                                  }}</v-field-value></v-col
                                >
                              </v-row>
                              <v-row>
                                <v-col class="font-weight-bold">
                                  Taxas extras</v-col
                                >
                                <v-col>{{
                                  converteValorFinanceiroBRL(
                                    subcotacao.custos_taxas_extras
                                  ) | dinheiroReal
                                }}</v-col>
                                <v-col v-if="emissao == 'finalizada'"
                                  ><v-field-value label="Responsável">{{
                                    getCustoTaxaExtraResponsavel()
                                  }}</v-field-value></v-col
                                >
                              </v-row>
                              <v-row>
                                <v-col class="font-weight-bold">
                                  RAV via fornecedor</v-col
                                >
                                <v-col>{{
                                  converteValorFinanceiroBRL(subcotacao.rav)
                                    | dinheiroReal
                                }}</v-col>
                                <v-col v-if="emissao == 'finalizada'"
                                  ><v-field-value label="Responsável">{{
                                    getRavResponsavel()
                                  }}</v-field-value></v-col
                                >
                              </v-row>

                              <v-row>
                                <v-col class="font-weight-bold">
                                  Custo total(sem as milhas)</v-col
                                >
                                <v-col>{{
                                  custosServicoVooSubcotacaoPrecificadores(
                                    subcotacao
                                  )["Custo total"] | dinheiroReal
                                }}</v-col>
                              </v-row>
                              <v-divider class="mb-2" />
                            </v-col>
                          </v-row>
                          <v-row
                            v-for="milhaValidada in cotacoesValidadas(
                              subcotacao
                            )"
                            :key="milhaValidada.id"
                          >
                            <v-col>
                              <v-row>
                                <v-col class="font-weight-bold">
                                  Milhas validadas #{{
                                    milhaValidada.id
                                  }}</v-col
                                >
                              </v-row>
                              <v-row>
                                <v-col class="font-weight-bold">
                                  Valor compra</v-col
                                >
                                <v-col>{{
                                  converteValorFinanceiroBRL(
                                    milhaValidada.valor_compra
                                  ) | dinheiroReal
                                }}</v-col>
                              </v-row>
                              <v-row>
                                <v-col class="font-weight-bold">
                                  Quantidade milhas</v-col
                                >
                                <v-col>{{ milhaValidada.valor }}</v-col>
                              </v-row>
                              <v-row>
                                <v-col class="font-weight-bold">
                                  Custo total</v-col
                                >
                                <v-col>{{
                                  ((milhaValidada.valor *
                                    converteValorFinanceiroBRL(
                                      milhaValidada.valor_compra
                                    )) /
                                    1000)
                                    | dinheiroReal
                                }}</v-col>
                              </v-row>
                              <v-divider class="mb-2" />
                            </v-col>
                          </v-row>
                        </v-col>
                      </v-row>

                      <v-row
                        class="mb-2"
                        v-if="
                          subcotacao.tipo_cotacao === 'wallet' &&
                          servico.status !== 'emissao'
                        "
                      >
                        Custos para wallet só estão disponíveis para serviços em
                        emissão
                      </v-row>
                      <v-row
                        class="pb-0"
                        v-if="
                          subcotacao.tipo_cotacao === 'wallet' &&
                          servico.status === 'emissao'
                        "
                      >
                        <v-col>
                          <v-row>
                            Deságio {{ desagioWallet(subcotacao) }}%
                          </v-row>
                          <v-row
                            v-for="[key, value] in Object.entries(
                              custosServicoVooSubcotacaoPrecificadores(
                                subcotacao
                              )
                            )"
                            :key="key"
                          >
                            <v-col
                              class="font-weight-bold"
                              v-if="key !== 'Custo assento conforto'"
                              >{{ key }}</v-col
                            >
                            <v-col v-if="key !== 'Custo assento conforto'">{{
                              value | dinheiroReal
                            }}</v-col>
                            <v-col v-if="emissao == 'finalizada'">
                              <v-field-value
                                v-if="key == 'Taxa de embarque'"
                                label="Responsável"
                                >{{
                                  getCustoTaxaEmbarqueResponsavel()
                                }}</v-field-value
                              >

                              <v-field-value
                                v-if="key == 'Taxa de bagagem'"
                                label="Responsável"
                              >
                                {{
                                  getCustoTaxaBagagemResponsavel()
                                }}</v-field-value
                              >
                              <v-field-value
                                v-if="key == 'Taxas extras'"
                                label="Responsável"
                              >
                                {{ getCustoTaxaExtraResponsavel() }}
                              </v-field-value>
                              <v-field-value
                                v-if="key == 'RAV via fornecedor'"
                                label="Responsável"
                              >
                                {{ getRavResponsavel() }}
                              </v-field-value>

                              <v-field-value
                                v-if="key == 'Custo bilhete'"
                                label="Responsável"
                              >
                                {{ getCustoBilheteResponsavel() }}
                              </v-field-value>
                            </v-col>
                          </v-row>
                          <v-row>
                            <v-col class="font-weight-bold"
                              >Custo após deságio</v-col
                            >
                            <v-col>{{
                              custoWallet(subcotacao) | dinheiroReal
                            }}</v-col>
                          </v-row>
                        </v-col>
                      </v-row>
                      <v-divider class="mb-2"></v-divider>
                    </v-col>
                  </v-row>

                  <v-row class="font-weight-bold ml-2 mb-2">
                    Cotação #{{ servico.cotacao.id }}</v-row
                  >
                  <v-field-value label="Custo Total">
                    {{ custoServico(servico) | dinheiroReal }}</v-field-value
                  >
                </v-col>
              </v-row>
              <v-row
                class="pa-0"
                v-if="!servico?.cotacao?.cotacao_voo"
                v-for="[key, value] in Object.entries(
                  custoServicoPrecificadoresSemVoo(servico)[0]
                )"
                :key="key"
              >
                <v-col class="font-weight-bold pb-0">{{ key }}</v-col>
                <v-col class="pb-0">{{ value | dinheiroReal }}</v-col>
              </v-row>
            </v-col>
          </v-row>
        </v-col>
        <v-divider class="mt-6 mb-2"></v-divider>
        <v-row>
          <v-col class="font-weight-bold">
            Valor Base(Receitas - Custos totais):
          </v-col>
          <v-col>
            {{
              (servicoValorTotal(servico) - custoServico(servico))
                | dinheiroReal
            }}
          </v-col>
        </v-row>
      </v-container>
    </v-card-text>
    <v-card-actions>
      <v-btn color="blue darken-1" text @click="close"> Fechar </v-btn>
    </v-card-actions>
  </v-card>
</template>
<script>
import CotacaoMixin from "@/mixins/CotacaoMixin";
import { converteValorFinanceiroBRL } from "@/helpers/financeiro";
import VFieldValue from "@/components/widgets/VFieldValue.vue";
import api from "@/api";

export default {
  components: {
    VFieldValue,
  },
  mixins: [CotacaoMixin],

  props: {
    servico: {
      type: Object,
      default: () => {},
    },
    milhasValidadas: {
      type: Array,
      default: () => [],
    },
    desagioProprio: {
      type: Number,
      default: 0,
    },
  },
  data: () => ({
    pagamentoEmissoesConcluidas: [],
    emissao: "",
  }),
  methods: {
    close() {
      this.$emit("close");
    },
    cotacoesValidadas(subcotacao) {
      if (!this.milhasValidadas) return [];
      return this.milhasValidadas.filter(
        (milhaValidada) => milhaValidada.subcotacao === subcotacao.id
      );
    },
    custoWallet(subcotacao) {
      return (
        this.custosServicoVooSubcotacaoPrecificadores(subcotacao)[
          "Custo total"
        ] *
        (1 - this.desagioWallet(subcotacao) / 100)
      );
    },
    custoMilha(subcotacao) {
      return (
        this.milhasValidadas
          .filter((milhaValidada) => milhaValidada.subcotacao === subcotacao.id)
          .reduce(
            (sum, item) =>
              (sum +=
                (item.valor * converteValorFinanceiroBRL(item.valor_compra)) /
                1000),
            0
          ) +
        this.custosServicoVooSubcotacaoPrecificadores(subcotacao)["Custo total"]
      );
    },
    custoPagante(subcotacao) {
      return this.custosServicoVooSubcotacaoPrecificadores(subcotacao)[
        "Custo total"
      ];
    },
    custoSubcotacao(subcotacao) {
      if (subcotacao.tipo_cotacao === "pagante")
        return this.custoPagante(subcotacao);
      if (subcotacao.tipo_cotacao === "wallet")
        return this.custoWallet(subcotacao);
      if (subcotacao.tipo_cotacao === "milha")
        return this.custoMilha(subcotacao);
    },
    custoServicoVoo(servico) {
      return servico.cotacao.cotacao_voo.subcotacoes.reduce(
        (sum, subcotacao) => (sum += this.custoSubcotacao(subcotacao)),
        0
      );
    },
    custoServicoNaoVoo(servico) {
      return this.custoServicoPrecificadoresSemVoo(servico).reduce(
        (sum, item) => item["Custo total"] + sum,
        0
      );
    },
    custoServico(servico) {
      if (servico.cotacao.cotacao_voo) return this.custoServicoVoo(servico);
      return this.custoServicoNaoVoo(servico);
    },

    desagioWallet(subcotacao) {
      if (subcotacao?.tipo_cotacao !== "wallet") return 0;
      const cotacoesValidadas = this.cotacoesValidadas(subcotacao);
      if (!cotacoesValidadas || !cotacoesValidadas.length) return 0;
      let desagio =
        cotacoesValidadas.reduce(
          (sum, cotacao) =>
            (sum += cotacao.conta_fidelidade
              ? this.desagioProprio
              : cotacao.desagio_compra
              ? Number(cotacao.desagio_compra)
              : 0),
          0
        ) / cotacoesValidadas.length;

      return desagio;
    },

    getCustoBilheteResponsavel() {
      let responsavelCustoBilhete = "";
      this.pagamentoEmissoesConcluidas.forEach((item) => {
        if (item.campo_formador === "custo_bilhete") {
          responsavelCustoBilhete = item.responsavel;
        }
      });

      return responsavelCustoBilhete;
    },
    getRavResponsavel() {
      let responsavelRav = "";
      this.pagamentoEmissoesConcluidas.forEach((item) => {
        if (item.campo_formador === "rav") {
          responsavelRav = item.responsavel;
        }
      });

      return responsavelRav;
    },
    getCustoTaxaEmbarqueResponsavel() {
      let responsavelCustoTaxaEmbarque = "";
      this.pagamentoEmissoesConcluidas.forEach((item) => {
        if (item.campo_formador === "custo_taxa_embarque") {
          responsavelCustoTaxaEmbarque = item.responsavel;
        }
      });
      return responsavelCustoTaxaEmbarque;
    },
    getCustoTaxaBagagemResponsavel() {
      let responsavelCustoTaxaBagagem = "";
      this.pagamentoEmissoesConcluidas.forEach((item) => {
        if (item.campo_formador === "custo_taxa_bagagem") {
          responsavelCustoTaxaBagagem = item.responsavel;
        }
      });
      return responsavelCustoTaxaBagagem;
    },
    getCustoTaxaExtraResponsavel() {
      let responsavelCustoTaxaExtra = "";
      this.pagamentoEmissoesConcluidas.forEach((item) => {
        if (item.campo_formador === "custo_taxas_extras") {
          responsavelCustoTaxaExtra = item.responsavel;
        }
      });

      return responsavelCustoTaxaExtra;
    },
  },

  computed: {
    emissaoID() {
      return this.servico.venda.emissoes[0].id;
    },
  },

  mounted() {
    api
      .getPagamentoEmissao(this.emissaoID)
      .then((res) => {
        const data = res.data;
        return (this.pagamentoEmissoesConcluidas = data);
      })
      .catch((err) => {
        console.error(err);
        this.$root.vtoast.show({
          message: "Erro ao recuperar pagamentos desta emissão.",
          color: "error",
          icon: "mdi-close",
        });
      });

    api
      .getEmissao(this.emissaoID)
      .then((data) => {
        this.emissao = data.data.status;
      })
      .catch((e) => {
        this.$root.vtoast.show({
          message: "Erro ao carregar emissão",
          type: "error",
        });
        this.$root.vtoast.show({ message: e.msg, type: "error" });
      });
  },
};
</script>
