<template>

  <v-card elevation="4" rounded>
    <v-tabs v-model="activeTab">
      <v-tabs-slider color="primary"></v-tabs-slider>
      <v-tab>
        Venda
      </v-tab>
      <v-tab>
        Orçamento
      </v-tab>
    </v-tabs>
    <v-divider></v-divider>
    <v-tabs-items v-model="activeTab">
      <v-tab-item>
        <orcamento-tab-venda :venda="orcamento.venda" ></orcamento-tab-venda>
      </v-tab-item>
      <v-tab-item>
        <orcamento-tab-info :orcamento="orcamento">
          <template v-slot:actions>
            <slot name="actions"/>
          </template>
        </orcamento-tab-info>
      </v-tab-item>
    </v-tabs-items>
  </v-card>
</template>
<script>
import OrcamentoTabInfo from "@/components/Orcamento/TabInformacoes/OrcamentoTabInfo";
import OrcamentoTabVenda from "@/components/Orcamento/TabInformacoes/OrcamentoTabVenda";

export default {
  components: {OrcamentoTabVenda, OrcamentoTabInfo},
  props: {
    orcamento: {
      type: Object,
      default: () => {
      }
    }
  },
  data() {
    return {
      activeTab: 1,
    }
  }
}
</script>
