<template>
  <v-container fluid v-if="orcamento || cotacao_outro">
    <v-row v-if="orcamento_outro || cotacao_outro">
      <v-col>
        <v-field-value label="Nome do Serviço">
          {{ orcamento_outro?.nome_servico || cotacao_outro?.nome_servico}}
        </v-field-value>
      </v-col>
      <v-col>
        <v-field-value label="Descrição do Serviço">
          {{ orcamento_outro?.descricao_servico || cotacao_outro?.nome_servico}}
        </v-field-value>
      </v-col>
      <v-col>
        <v-field-value label="Local">
          {{ orcamento_outro?.local || cotacao_outro?.local | localCidadeResumida }}
        </v-field-value>

      </v-col>
      <v-col>
        <v-field-value label="Flexibilidade" v-if="orcamento_outro">
          {{ orcamento_outro?.flexibilidade? "Sim":"Não" }}
        </v-field-value>

      </v-col>
      <v-col>
        <v-row>
          <v-col>
            <v-field-value label="Data">
              {{ orcamento_outro?.data_inicio_inicio || cotacao_outro?.data_inicio | convertDataBR }}
              -
              {{ orcamento_outro?.data_inicio_fim || cotacao_outro?.data_fim | convertDataBR }}
            </v-field-value>
          </v-col>
        </v-row>
      </v-col>

      <v-col v-if="orcamento_outro?.anotacoes">
        <v-field-value label="Anotações" >
          {{ orcamento_outro?.anotacoes }}
        </v-field-value>
      </v-col>
    </v-row>
  </v-container>
  <v-container fluid v-else>
    <v-alert color="error">
      Erro ao exibir orçamento vinculado. Por favor, consulte a equipe técnica.
    </v-alert>
  </v-container>
</template>

<script>
import VFieldValue from "@/components/widgets/VFieldValue";

export default {
  components: {VFieldValue},
  props: {
    orcamento: {
      type: Object,
      default: null
    },
    item:{ // item == cotação 
      type: Object,
      default: () => {},
    }
  },
  computed: {
    orcamento_outro() {
      return this.orcamento && this.orcamento.orcamento_outro;
    },
    cotacao_outro(){
      return this.item && this.item.cotacao_outro;
    }
  }
}
</script>
