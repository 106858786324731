<template>
  <v-dialog v-model="dialog" persistent max-width="500px">
    <template v-slot:activator="{ on, attrs }">
      <div v-on="on">
        <v-btn
          color="success"
          dark
          small
          class="ma-4"
          v-bind="attrs"
          :disabled="disabled"
        >
          <v-icon>mdi-currency-usd</v-icon>
          Faturar
        </v-btn>
      </div>
    </template>
    <v-card>
      <v-card-title class="text-h5">
        <v-container>Faturar</v-container>
      </v-card-title>
      <v-card-text>
        <v-container> Deseja realmente faturar o carrinho? </v-container>
      </v-card-text>

      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="blue darken-1" text @click="close"> Cancelar </v-btn>
        <v-btn :loading="loading" color="primary" @click="getBeneficiarios">
          Sim
        </v-btn>
      </v-card-actions>
    </v-card>

    <v-dialog v-model="beneficiariosDialog">
      <v-card>
        <v-card-title>Insira os beneficiários associados</v-card-title>
        <v-card-text>
          <v-container v-for="(cotacao, index) in carrinho" :key="index">
            Serviço #{{ index + 1 }} -- {{ cotacao | descricaoCotacao }}
            <beneficiarios
              v-model="cotacao.beneficiarios"
              :cotacao="cotacao"
              :venda="venda"
              :qtdPax="qtdPax(cotacao)"
              :isButtonDisabled="true"
            />
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="blue darken-1"
            text
            @click="beneficiariosDialog = false"
          >
            Cancelar
          </v-btn>
          <v-btn :loading="loading" color="primary" @click="gotoPagamento">
            Salvar
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <venda-carrinho-validar-pagamento-dialog
      @save="save"
      :pagamentoDialog="pagamentoDialog"
      :cotacoes="carrinho"
      @closePagamento="closePagamento"
    />
  </v-dialog>
</template>

<script>
import Beneficiarios from "@/components/Beneficiarios/Beneficiarios";
import VendaCarrinhoValidarPagamentoDialog from "@/components/venda/carrinho/VendaCarrinhoValidarPagamentoDialog";
import { mapState } from "vuex";
import { numPessoas, numPessoasDetalhado } from "@/helpers/subcotacao";

export default {
  components: {
    Beneficiarios,
    VendaCarrinhoValidarPagamentoDialog,
  },
  props: {
    disabled: {
      type: Boolean,
    },
    carrinho: {
      type: Array,
      default: () => {},
    },
  },
  data() {
    return {
      loading: false,
      dialog: false,
      beneficiariosDialog: false,
      pagamentoDialog: false,
    };
  },

  computed: {
    ...mapState({
      venda: (state) => state.venda.venda,
    }),
  },

  methods: {
    //Retorna a quantidade de beneficiários para adição dos novos ícones
    qtdPax(cotacao) {
      // cotacao.cotacao_voo.subcotacoes[0]
      if (cotacao.cotacao_voo) {
        return numPessoasDetalhado(cotacao.cotacao_voo.subcotacoes[0]);
      }
      if (cotacao.cotacao_hotel) {
        return {
          total: cotacao.cotacao_hotel.adultos + cotacao.cotacao_hotel.criancas,
          adultos: cotacao.cotacao_hotel.adultos,
          criancas: cotacao.cotacao_hotel.criancas,
        };
      }
      return {
        total: 1,
      };
    },

    show() {
      this.dialog = true;
    },
    close() {
      this.dialog = false;
    },
    getBeneficiarios() {
      this.beneficiariosDialog = true;
      this.close();
    },
    validate() {
      if (
        this.carrinho.some(
          (carr) => carr.beneficiarios.length != this.qtdPax(carr).total
        )
      ) {
        this.$root.vtoast.show({
          message:
            "Por favor insira todos os beneficiários associados para todas as cotações.",
          color: "error",
          icon: "mdi-close",
        });
        return false;
      }
      return true;
    },
    gotoPagamento() {
      if (!this.validate()) return;
      this.pagamentoDialog = true;
    },
    closePagamento() {
      this.pagamentoDialog = false;
    },

    checkPagamento(informacoesPagamento) {
      if (
        !informacoesPagamento ||
        Object.values(informacoesPagamento).some(
          (pagamento) => pagamento.tiposPagamento === ""
        )
      ) {
        this.$root.vtoast.show({
          message: "Por favor insira o pagamento de todas as cotações",
          color: "error",
          icon: "mdi-close",
        });
        return false;
      }
      return true;
    },
    save(informacoesPagamento, responsaveisFinanceiros, juntarPagamentos) {
      if (!this.checkPagamento(informacoesPagamento)) return;
      this.loading = true;
      this.pagamentoDialog = false;
      this.$emit(
        "confirm",
        this.carrinho,
        informacoesPagamento,
        responsaveisFinanceiros,
        juntarPagamentos
      );
      setTimeout(() => {
        this.loading = false;
        this.close();
      }, 500);
    },
  },
};
</script>
