<template>
  <v-container class="w-auto" max-width="100%">
    <v-card class="px-4 mx-0">
      <v-card-title class="text-h5"> Serviços relacionados </v-card-title>
      <v-card-text>
        <v-row v-for="item in servicosRelacionados" :key="item.id">
          <v-col>
            <v-field-value label="Serviço">
              <v-link-text-field
                :to="{
                  name: `venda-servicos`,
                  params: { id: item?.venda?.id },
                }"
                text
                small
              >
                #{{ item?.id }}
              </v-link-text-field>
            </v-field-value>
          </v-col>
          <v-col>
            <v-field-value label="Venda">
              <v-link-text-field
                :to="{ name: `venda-info`, params: { id: item?.venda?.id } }"
                text
                small
              >
                #{{ item?.venda?.id }}
              </v-link-text-field>
            </v-field-value>
          </v-col>
          <v-col
            ><v-field-value label="Adicionado por">
              {{ item.servico_vinculado_por | nomeSobrenome }}
            </v-field-value></v-col
          >
          <v-col>
            <v-field-value label="Adicionado em">
              {{ item.servico_vinculado_em | convertDataBR }}
            </v-field-value>
          </v-col>
        </v-row>

        <v-divider></v-divider>
        <v-row>
          <v-select
            :items="servicosCliente"
            label="Adicionar serviço"
            outlined
            v-model="value.servico"
            item-value="id"
            :item-text="(servico) => `Serviço #${servico.id}`"
          />
        </v-row>
      </v-card-text>

      <v-card-actions>
        <v-btn text @click="closeDialog">
          <v-icon left>mdi-arrow-left</v-icon>
          Voltar
        </v-btn>
        <v-spacer />
        <v-btn text @click="addServico()"> Adicionar servico </v-btn>
      </v-card-actions>
    </v-card>
  </v-container>
</template>

<script>
import VFieldValue from "@/components/widgets/VFieldValue";
import VLinkTextField from "@/components/widgets/VLinkTextField";
export default {
  components: { VLinkTextField, VFieldValue },
  data() {
    return {
      loading: false,
      value: {},
    };
  },
  props: {
    dialog: {
      type: Boolean,
      default: false,
    },
    servicosRelacionados: {
      type: Array,
      default: () => [],
    },
    servicosCliente: {
      type: Array,
      default: () => [],
    },
  },
  methods: {
    closeDialog() {
      this.$emit("closeDialog");
    },
    addServico() {
      this.loading = true;
      this.$emit("adicionarServico", this.value);
      this.value.servico = "";
    },
  },
};
</script>
