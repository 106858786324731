<template>
  <v-container fluid v-if="orcamento || cotacao_carro">
    <v-row v-if="orcamento_carro || cotacao_carro">
      <v-col>
        <v-field-value label="Local Retirada">
          {{ orcamento_carro?.local_retirada || cotacao_carro?.local_retirada | localCidadeResumida }}
        </v-field-value>
        <v-field-value label="Local Devolução">
          {{ orcamento_carro?.local_devolucao || cotacao_carro?.local_devolucao | localCidadeResumida }}
        </v-field-value>

      </v-col>
      <v-col v-if="orcamento_carro">
        <v-field-value label="Flexibilidade Retirada">
          {{ orcamento_carro?.flexibilidade_retirada? "Sim": "Não" }}
        </v-field-value>
        <v-field-value label="Flexibilicade Devolução">
          {{ orcamento_carro?.flexibilidade_devolucao? "Sim": "Não" }}
        </v-field-value>

      </v-col>
      <v-col>
        <v-row>
          <v-col>
            <v-field-value label="Checkin">
              {{ orcamento_carro?.data_retirada_inicio || cotacao_carro?.data_hora_retirada | convertDataBR }}
              -
              {{ orcamento_carro?.data_retirada_fim || cotacao_carro?.data_hora_retirada | convertDataBR }}
            </v-field-value>

            <v-field-value label="Checkout">
              {{ orcamento_carro?.data_devolucao_inicio || cotacao_carro?.data_hora_devolucao | convertDataBR }}
              -
              {{ orcamento_carro?.data_devolucao_fim || cotacao_carro?.data_hora_devolucao | convertDataBR }}
            </v-field-value>
          </v-col>
        </v-row>
      </v-col>
      <v-col>
        <v-field-value label="Tipo de veículo">
          {{ orcamento_carro?.tipo_veiculo || cotacao_carro?.tipo_carro}}
        </v-field-value>
      </v-col>
      <v-col>
        <v-field-value label="Anotações">
          {{ orcamento_carro?.anotacoes || cotacao_carro?.anotacoes}}
        </v-field-value>
      </v-col>
    </v-row>
  </v-container>
  <v-container fluid v-else>
    <v-alert color="error">
      Erro ao exibir orçamento vinculado. Por favor, consulte a equipe técnica.
    </v-alert>
  </v-container>
</template>

<script>
import VFieldValue from "@/components/widgets/VFieldValue";

export default {
  components: {VFieldValue},
  props: {
    orcamento: {
      type: Object,
      default: null
    },
    item:{ // item == cotação
      type: Object,
      default: () => {},
    }
  },
  computed: {
    orcamento_carro() {
      return this.orcamento && this.orcamento.orcamento_carro;
    },
    cotacao_carro(){
      return this.item  && this.item.cotacao_carro;
    },
  }
}
</script>
