import { get, post, del } from '../modules'

export default {
    getNotificacoes() {
        return get("/api/notificacoes/");
    },
    marcarLido(id) {
        return post(`/api/notificacoes/${id}/lido/`)
    },
    marcarImportante(id) {
        return post(`/api/notificacoes/${id}/importante/`)
    },
    getNotificacoesDetalhado(page=1, filters=[]) {
        let url = "/api/notificacoes/detailed/?page="+page
        filters.forEach( filter => {
            url += `&${filter[0]}=${filter[1]}`
        })
        return get(url);
    },
}