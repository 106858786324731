export const arraySameElements = (arr1, arr2) => {
    return arr1.length === arr2.length && arr1.every(e => new Set(arr2).has(e))
}

export const flattenObj = (obj, parent, res = {}) => {
    if(!obj) return {}
    for (const key of Object.keys(obj)) {
        const propName = parent ? parent + '.' + key : key;
        if (typeof obj[key] === 'object') {
            flattenObj(obj[key], propName, res);
        } else {
            res[propName] = obj[key];
        }
    }
    return res;
}

export const extendObj = (obj, extension) => {
    if(!extension) return
    for (let i in extension){
        obj[i] = extension[i]
    }
}

export const substituteObj = (obj, extension) => {
    if(!obj) return
    for (let i in obj)
        delete obj[i]
    for (let i in extension)
        obj[i] = extension[i]
}