<template>
  <span>
    <v-btn :color="color"  :disabled="disabled" class="mb-2 mr-2" @click.stop="openDialog">
      <v-icon left>{{ icon }}</v-icon>
      {{ type }}
    </v-btn>
    <v-dialog :key="pagarmeKey" v-model="pagarmeDialog" max-width="600">
      <v-card>
        <v-card-title class="text-h5">
          Criar Link de Pagamento Pagar.me ({{ type }})
        </v-card-title>
        <v-card-text>
          <v-form ref="form" lazy-validation>
            <VMoedaField outlined dense label="Valor total dos pagamentos" v-model="valorPagamento" />
          </v-form>
          <v-form ref="form" lazy-validation>
            <v-select label="Parcelas sem juros" :items="[1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12]" v-model="parcelas"
              outlined dense v-if="type === 'cartao'" />
          </v-form>
          <v-form ref="form" lazy-validation>
            <v-select
              label="Servicos"
              :items="excluirServicosCancelados"
              outlined
              clearable
              dense
              multiple
              chips
              item-value="id"
              :item-text="serviceDescription"
              v-model="pagamento.servico"
            />
          </v-form>

          <v-form ref="form" lazy-validation v-if="qtdServicos > 1">
            <div v-for="(servico,index)  in pagamento.servico" :key="index">
                <p> Serviço #{{ servico }}</p>
                <VMoedaField
                :key="index" 
                outline
                dense 
                label="Valor pagamento" 
                v-model="valorIndividualPagamentos[index]" />
            </div>

          </v-form>
        </v-card-text>
        <v-card-actions>    
          <v-btn color="dark" text @click="cancel">
            Cancelar
          </v-btn>
          <v-spacer></v-spacer>

          <v-btn color="green" text @click="checkVariosServicos" :loading="loading">
            Confirmar
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  
    <v-dialog v-model="urlDialog" max-width="600">
      <v-card>
        <v-card-title class="text-h5">
          Pagar.me - Link para Pagamento ({{ type }})
        </v-card-title>

        <v-card-text>
          <CopyTextField label="URL Pagar.me" id="pagarme_link" :value="pagamento.pagarme_link" readonly />
        </v-card-text>

        <v-card-actions>
          <v-btn color="dark" text @click="urlDialog = false"> Fechar </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </span>
</template>

<script>
import VMoedaField from "@/components/widgets/VMoedaField";
import CopyTextField from "@/components/widgets/CopyTextField.vue";
import CotacaoMixin from "@/mixins/CotacaoMixin";
import { converteValorFinanceiroBRL } from "@/helpers/financeiro";
import api from "@/api/api";

export default {
  components: { VMoedaField, CopyTextField },
  mixins:[CotacaoMixin],
  props: {
    type: {
      type: String,
      default: "cartao"
    },
    color: {
      type: String,
      default: "success"
    },
    icon: {
      type: String,
      default: "mdi-link"
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    servicosProp: {
      type: Array,
      default: () => [],
    },
    pagamentosVenda: {
      type: Array,
      default: () => [],
    },
    valorPagamentoRAC: {
      type: Object,
      default:() => {},
    },
  },

  data() {
    return {
      pagarmeDialog: false,
      urlDialog: false,
      rawServicos: [],
      loadingServicos: true,
      valorPagamento: this.valorPagamentoRAC || null,
      valorIndividualPagamentos: [],
      loading: false,
      parcelas: null,
      pagamentos: [],
      pagarmeKey: 0,
      pagamentoDefault: {
        venda: this.$route.params.id,
        servico: null,
        status: "nao_efetuado",
        descricao: "",
        valor_pagamento: {
          moeda: null,
          valor: 0,
        },
        meio_pagamento: this.type === "cartao" ? "pagarme" : this.type === "pix" ? "pix" : "",
        responsavel_financeiro: null,
      },
      pagamento: {
        venda: this.$route.params.id,
        servico: null,
        status: "nao_efetuado",
        descricao: "",
        valor_pagamento: {
          moeda: null,
          valor: 0,
        },
        meio_pagamento: this.type === "cartao" ? "pagarme" : this.type === "pix" ? "pix" : "",
        responsavel_financeiro: null,
      },
    };
  },


  computed: {
    qtdServicos(){
      if(this.pagamento.servico){
        return this.pagamento.servico.length;
      }
      return 0;
    },
    valor_aberto() {
      return Object.fromEntries(
        this.servicosProp.map((servico) => [
          servico.cotacao.id,
          this.servicoValorTotal(servico) -
            this.pagamentosVenda
              .filter((pagamento) => pagamento.servico?.id === servico.id)
              .filter((pagamento) => pagamento.status === "concluido")
              .reduce(
                (sum, pagamento) =>
                  (sum += converteValorFinanceiroBRL(
                    pagamento.valor_pagamento
                  )),
                0
              ),
        ])
      );
    },

    excluirServicosCancelados(){
      return this.servicosProp.filter((servico) => {
        return !servico.cancelado_em && !servico.cancelado_por
      });
    },
    
    servicos() {
      return this.rawServicos
        .filter((servico) => servico.cotacao.orcamento.venda === this.venda)
        .filter((servico) =>
          ["Carrinho", "Validação", "Emissao"].includes(servico.status)
        );
    },

  },

    watch: {
      valorPagamentoRAC: {
        handler(newValue) {
            this.$nextTick(() => {
              this.valorPagamento = newValue;
            })
        },
        immediate: true,
        deep:true,
      },
  },

  methods: {
    valorTotalServico(servico) {
      return this.servicoValorTotal(servico);
    },
    serviceDescription(servico) {
      const id = servico.id;
      if (!id) return "";
      const idCotacao = servico.cotacao.id;
      return `Servico # ${servico.id} - Valor Aberto: ${this.valor_aberto[
        idCotacao
      ]} - Valor Total: ${this.valorTotalServico(servico).toFixed(
        2
      )}`;
    },
    openDialog(){
      this.pagarmeKey += 1;
      this.valorPagamento = this.valorPagamentoRAC || null;
      this.pagarmeDialog = true;
    },
    cancel(){
      this.pagarmeDialog = false
    },

    async checkVariosServicos(){
      if(this.pagamento.servico.length === 1){
        this.pagamento.servico = this.pagamento.servico[0];
        await this.gerarLink();
        return;
      }
      const servicos = this.pagamento.servico; 
      const pagamentosIndividuais = this.valorIndividualPagamentos;
      const cliente =
          this.$store.state.venda.venda.cliente ||
          this.$store.state.venda.venda.cliente_empresa;
      const name = `${this.pagamento.venda} - ${cliente.nome} ${cliente.sobrenome}`;
      for(let i = 0; i < servicos.length; i++){
        let pag = JSON.parse(JSON.stringify(this.pagamentoDefault));
        pag.servico = servicos[i];
        pag.valor_pagamento = pagamentosIndividuais[i];
        const valor = parseInt(pag.valor_pagamento.valor * 100);
        pag.pagarme = {
          amount: valor,
          title: name
        }
        if (this.parcelas) pag.pagarme.max_installments = this.parcelas;
        this.pagamentos.push(pag);
      }
      await this.gerarLink(true);
      return;
    },
    async gerarLink(muitosPagamentos = false) {
      this.loading = true;
      if(!muitosPagamentos){
        this.pagamento.valor_pagamento = this.valorPagamento
        const cliente =
          this.$store.state.venda.venda.cliente ||
          this.$store.state.venda.venda.cliente_empresa;
        const name = `${this.pagamento.venda} - ${cliente.nome} ${cliente.sobrenome}`;
        const valor = parseInt(this.pagamento.valor_pagamento.valor * 100);
        this.pagamento.pagarme = {
          amount: valor,
          title: name
        }
        if (this.parcelas) this.pagamento.pagarme.max_installments = this.parcelas
      }
      let pagamentosFinal = [];
      pagamentosFinal = [this.pagamento];
      if(muitosPagamentos){
        pagamentosFinal = this.pagamentos;
      }
      if(this.pagamento.servico){
        api
        .postCriarPagamentos({ pagamentos: pagamentosFinal })
        .then((res) => {
          this.$root.vtoast.show({
            message: "Pagamento(s) cadastrado(s) com sucesso(s)!",
            icon: "mdi-check",
            color: "success",
          });
          this.loading = false;
          this.pagarmeDialog = false;
          if(res.data.length === 1){
              const data = res.data[0];
              this.pagamento.pagarme_link = data.pagarme_link
              this.$emit("updatePagamentos", data);
              this.urlDialog = true;
          } else{
                for(let i = 0; i < res.data.length;i++){
                  const data = res.data[i];
                  this.pagamentos[i].pagarme_link = data.pagarme_link
                  this.$emit("updatePagamentos", data);
                }
          }
          this.parcelas = null
          this.valorIndividualPagamentos = [];
          this.pagamentos = [];
        })
        .catch((e) => {
          this.$root.vtoast.show({
            message: "Erro ao cadastrar pagamento!",
            icon: "mdi-close",
            color: "error",
          });
          this.loading = false;
          console.error(e);
        })
      } else{
        this.$root.vtoast.show({
          message: "Selecione o serviço associado",
          icon: "mdi-close",
          color: "error",
        });
        this.loading = false;
      }
    },
  },
};
</script>
