<template>
  <v-container v-if="contato">
    <v-form id="extras-form">
      <v-row>
        <v-col cols="12" sm="6">
          <CopyTextField
            label="Instagram"
            id="instagram"
            icon="mdi-instagram"
            :value="contato.extras.instagram"
          />
        </v-col>
        <v-col cols="12" sm="6">
          <CopyTextField
            label="LinkedIn"
            id="linkedin"
            icon="mdi-linkedin"
            :value="contato.extras.linkedin"
          />
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12" sm="6">
          <SearchContato v-model="relacao" label="Relação" />
          <input type="hidden" name="relacao" :value="relacao?.id" />
        </v-col>
        <v-col cols="12" sm="6">
          <CopyTextField
            label="Tipo de Relação"
            icon="mdi-account-multiple"
            id="tipo_relacao"
            :value="contato.extras.tipo_relacao"
          />
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12" sm="6">
          <FormDatePicker
              v-model="data_especial"
              outlined
              :dense="false"
              label="Data Especial"
              name="data_especial"
          />
        </v-col>

        <v-col cols="12" sm="6">
          <CopyTextField
            label="Ocasião"
            id="ocasiao"
            icon="mdi-lead-pencil"
            :value="contato.extras.ocasiao"
          />
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12" sm="12">
          <v-textarea
            label="Observações"
            outlined
            prepend-inner-icon="mdi-magnify"
            name="observacoes"
            :rules="[
              (v) =>
                v?.length < 500 ||
                'Observações devem ter menos de 500 caracteres',
            ]"
            :value="contato.extras.observacoes"
          />
        </v-col>
      </v-row>
    </v-form>
  </v-container>
</template>

<script>
import CopyTextField from "@/components/widgets/CopyTextField.vue";
import SearchContato from "@/components/widgets/SearchContato.vue";
import FormDatePicker from "@/components/widgets/FormDatePicker.vue"
import skylerDate from "@/helpers/Date/date"

export default {
  name: "Extras",

  components: {
    CopyTextField,
    SearchContato,
    FormDatePicker
  },

  data() {
    return {
      relacao: 0,
      data_especial: null
    };
  },

  computed: {
    contato() {
      return this.$store.getters['contato/contato']
    },
  },

  methods: {
    initialize() {
      if(!this.contato) return
      this.relacao = this.contato.extras.relacao;
      if(this.contato.extras.data_especial)
        this.data_especial = skylerDate.parseISOtoUTCDate(this.contato.extras.data_especial);
    }
  },

  created() {
    this.initialize()
  }
};
</script>
