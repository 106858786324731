<template >
    <v-container class="ma-4">
        <v-row>
            <v-col>
                <div class="text-h5 transition-swing">
                    Milhas 
                </div>
            <v-breadcrumbs :items="breadcumb_items"></v-breadcrumbs>
            </v-col>
        </v-row>
        <v-row><v-col>
            <milhas-table
                :formatProgramaFidelidade="formatProgramaFidelidade"
                :formatCiaAerea="formatCiaAerea"
            />
        </v-col></v-row>

        <v-row><v-col>
            <milhas-historico-table
                :limit="4"
                :formatProgramaFidelidade="formatProgramaFidelidade"
                :formatCiaAerea="formatCiaAerea"
            /> 
        </v-col></v-row>
        <v-dialog v-model="fullTableDialog">
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            color="blue"
            dark
            v-bind="attrs"
            v-on="on"
          >
            Todos os dados
          </v-btn>
        </template>
  
        <v-card>
            <milhas-historico-table
                :formatProgramaFidelidade="formatProgramaFidelidade"
                :formatCiaAerea="formatCiaAerea"
            />
            <v-divider></v-divider>
            <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn
                    color="primary"
                    text
                    @click="fullTableDialog = false"
                >
                    Fechar
                </v-btn>
            </v-card-actions>
        </v-card>
      </v-dialog>
    </v-container>
</template>
<script>
import MilhasTable from '@/components/Milhas/MilhasTable.vue'
import MilhasHistoricoTable from '@/components/Milhas/MilhasHistoricoTable.vue'

import {
  formatCiaAerea,
  formatProgramaFidelidade,
} from "@/helpers/fidelidades";

export default {
    components: {MilhasTable, MilhasHistoricoTable},
    data: () => ({
        fullTableDialog: false,
        breadcumb_items: [
        {
            text: 'Home',
            to: {
                name: "home",
            },
            exact: true
        },
        {
            text: 'Milhas',
            to: {
                name: "milhas"
            }
        }
      ],
    }),

    mounted() {
        this.initialize()
    },

    methods: {
        initialize() {
            this.$store.dispatch('global/fetchProgramas').catch(err => {
                this.$root.vtoast.show({message: err.message, type: 'error'});
            })
            this.$store.dispatch("global/fetchCiaAereas").catch(err => {
                this.$root.vtoast.show({message: err.message, type: 'error'});
            })
        },

        formatProgramaFidelidade(val) {
            return formatProgramaFidelidade(val, this.$store.state.global.programas);
        },

        formatCiaAerea(val) {
            return formatCiaAerea(val, this.$store.state.global.ciaAereas);
        },
    }
    
}
</script>
<style >
.link-painel{
    margin: auto 0 auto 0;
}
</style>