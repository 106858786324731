import Vue from "vue"
import MEIOS_DE_PAGAMENTO from '@/constants/meiosPagamento'

const formatMeioPagamento = (key) => {
    if(!key) return ""
    const meio = MEIOS_DE_PAGAMENTO.find(e => e.key === key)
    if(!meio) return ""
    return meio.value
}

Vue.filter("formatMeioPagamento", formatMeioPagamento)