<template>
  <v-container>
    <div>
      <v-card>
        <v-card-title>Pagamento da Emissão </v-card-title>
        <v-card-text>
          <v-container>
            <v-row v-for="(pagamento, index) in pagamentos" :key="index">
              <v-col v-if="camposFormadores">
                <vuetify-money
                  v-if="pagamento.campo_formador === 'milheiro'"
                  dense
                  disabled
                  v-bind:outlined="true"
                  v-bind:label="
                    pagamento.campo_formador | formatCampoFormadorPreco
                  "
                  v-model.number="cotacaoFormadora[pagamento.campo_formador]"
                  v-bind:options="{ precision: 2, locale: 'pt-BR' }"
                ></vuetify-money>
                <v-moeda-field
                  v-else
                  outlined
                  dense
                  disabled
                  :label="pagamento.campo_formador | formatCampoFormadorPreco"
                  v-model="cotacaoFormadora[pagamento.campo_formador]"
                />
              </v-col>
              <v-col v-if="pagamento">
                <v-btn-toggle mandatory dense v-model="pagamento.responsavel">
                  <v-btn disabled>Cliente</v-btn>
                  <v-btn disabled>Skyler</v-btn>
                </v-btn-toggle>
              </v-col>
              <v-col>
                <v-select
                  label="Não Alt./Estornar/Transf. de Crédito"
                  v-model="pagamento.tipo_rac"
                  :items="
                    emissao?.tipo == 'emissao_voo_wallet'
                      ? tiposRacsWallet
                      : tiposRacs
                  "
                  item-value="key"
                  item-text="value"
                  outlined
                  dense
                />
              </v-col>
              <v-col>
                <v-select
                  v-if="pagamento.responsavel === 'skyler'"
                  :items="cards"
                  :item-text="formatCard"
                  item-value="id"
                  v-model="pagamento.cartao"
                  outlined
                  dense
                  disabled
                />
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
      </v-card>
    </div>
  </v-container>
</template>
<script>
import VMoedaField from "@/components/widgets/VMoedaField";
import VFieldValue from "@/components/widgets/VFieldValue";
import camposFormadores from "@/constants/camposFormadoresPreco";
import TIPOS_RACS from "@/constants/tiposRacs";
import TIPOS_RACS_WALLET from "@/constants/tiposRacs_wallet";

export default {
  components: {
    VMoedaField,
    VFieldValue,
  },

  data: () => ({
    camposFormadores: camposFormadores,
    tiposRacs: TIPOS_RACS,
    tiposRacsWallet: TIPOS_RACS_WALLET,
    tipoRac: {},
  }),
  props: {
    emissao: {
      type: Object,
      default: () => {},
    },
    pagamentos: {
      type: Array,
      default: () => [],
    },
    cards: {
      type: Array,
      default: () => [],
    },
  },

  computed: {
    cotacaoRelacionadaID() {
      return this.emissao?.servico?.cotacao?.id;
    },

    cotacaoFormadora() {
      if (!this.emissao) return {};
      if (this.emissao.subcotacao_voo)
        return { ...this.emissao.subcotacao_voo };
      const servico = this.emissao.servico;
      if (!servico) return {};
      const cotacao = servico.cotacao;
      if (!cotacao) return {};

      if (cotacao.cotacao_hotel) return { ...cotacao.cotacao_hotel };
      if (cotacao.cotacao_carro) return { ...cotacao.cotacao_carro };
      if (cotacao.cotacao_outro) return { ...cotacao.cotacao_outro };

      return {};
    },

    pagamentosObj() {
      return this.pagamentos.reduce(
        (cum, cur) => ({ ...cum, [cur.campo_formador]: cur }),
        {}
      );
    },
  },
  methods: {
    campoZerado(campo) {
      if (!campo) return true;
      if (campo.valor) return parseFloat(campo.valor) === 0;
      return campo === 0;
    },
    formatCard(card) {
      if (!card) return "";
      return `${card.nome} - ${card.bandeira} (${card.digs})`;
    },
    formatPagamento(pagamentos) {
      return pagamentos.map((pagamento) => ({
        ...pagamento,
        responsavel:
          pagamento.responsavel === 1
            ? "skyler"
            : pagamento.responsavel === 0
            ? "cliente"
            : null,
      }));
    },

    save() {
      return this.formatPagamento(this.pagamentos);
    },
  },
};
</script>
