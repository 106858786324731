<template >
    <div class= "page">
        <Infos 
        :vendas="vendas"
        :valorTotal="valorTotal"
        :valorAbertoVendas="valorAbertoVendas"
        :servicos="servicos"
        />
        <Servicos 
        :vendas="vendas"
        :servicos="servicos"
        :valorAbertoCotacoes="valorAbertoCotacoes"
        :beneficiarios="beneficiarios"
        />

    </div>
</template>
<script>

import Infos from './Infos.vue'
import Servicos from './Servicos.vue'


export default {
    components: {
        Infos,
        Servicos,
    },
    props: {
        servicos: {
        type: Array,
        default: () => []
        },
        vendas: {
        type: Array,
        default: () => []
        },
        valorTotal: {
        type: Object,
        default: () => {}
        },
        valorAbertoCotacoes: {
        type: Object,
        default: () => {}
        },
        valorAbertoVendas: {
        type: Object,
        default: () => {}
        },
        beneficiarios: {
        type: Array,
        default: () => []
        },
    },
}
</script>
<style >
html { -webkit-print-color-adjust: exact; }
@font-face {
  font-family: TenorSans;
  src: url('@/assets/TenorSans-Regular.ttf');
}
@font-face {
  font-family: Manrope;
  src: url('@/assets/Manrope-Regular.otf');
}

.page {
    margin-bottom: 0rem;
    padding: 3rem;
    padding-top: 0;
    padding-bottom:0;
}


</style>