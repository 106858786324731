<template>
    <v-container class="ma-4" fluid v-if="cotacoes">
        <v-data-table
            :headers="headers"
            :items="cotacoes"
        >
            <template v-slot:item="{item, headers, isSelected, select}">
                <venda-cotacao-list-item
                    :item="item"
                    :headers="headers"
                    :selected="isSelected"
                    @selectItem="select"
                />
            </template>
        </v-data-table>
    </v-container>
</template>

<script>
import VendaCotacaoListItem from "./VendaCotacaoListItem"

export default {
    components: {
        VendaCotacaoListItem
    },
    props: {
        cotacoes: {
            type: Array,
            default: () => []
        },
    },
    data() {
        return {
            dialogCarrinho: false,
            dialogDelete: false,
            dialogEditItem: false,
            edittingItem: null,
            headers: [
                {
                    text: "ID",
                    value: "id"
                },
                {
                    text: "Data da Cotação",
                    value: "data_hora_cotacao"
                },
                {
                    text: "Tipo de serviço",
                    value: "tipo"
                },
                {
                    text: "Descrição rápida",
                    value: "descricao_rapida"
                },
                {
                    text: "Custo total",
                    value: "custo_total"
                },
                {
                    text: "Ajuste",
                    value: "ajustes"
                },
                {
                    text: "Valor à vista",
                    value: "valor_final"
                },
                {
                    text: "Cotado por",
                    value: "cotado_por"
                },
                {
                    text: "Tipo",
                    value: "tipo",
                },
                {
                    text: "Fornecedores",
                    value: "fornecedores"
                },
                {
                    text: "Ações",
                    value: "actions"
                },
                
            ],
        }
    },
    computed: {
    },
    methods: {
        editItem(item) {
            this.edittingItem = item;
            this.dialogEditItem = true;
        },

        enviarCarrinhoSelectedItems() {
            this.dialogCarrinho = true;
        },

        enviarCarrinhoSelectedItemsConfim() {
            this.$emit('enviarCarrinho', this.selectedCotacoes);
        },
    }
}

</script>
