<template>
  <v-flex class="pa-2">
    <span class="text font-weight-bold">{{ label }}: </span>
    <slot></slot>
  </v-flex>
</template>

<script>
export default {
  props:{
    label: {
      type: String,
      default: ""
    }
  },
};
</script>
