import milhas from "@/api/routes/milhas"
import Vue from "vue"

const formatContaFidelidade = contaFidelidade => {
    if(!contaFidelidade) return ''
    const titular = contaFidelidade?.titular
    const programa = contaFidelidade?.programa
    const provedor = contaFidelidade?.provedor

    return `${titular?.nome || ''} ${titular?.sobrenome || ''} - ${programa?.nome || ''} - ${provedor?.nome || ''} `
}

const formatContaMilheiro = milhasValidadas => {
    if(!milhasValidadas) return ''
    const {cpf, provedor_milhas, programa_fidelidade} = milhasValidadas
    return `${cpf || ''} - ${programa_fidelidade?.nome || ''} - ${provedor_milhas?.nome || ''} `
}

Vue.filter('formatContaFidelidade', formatContaFidelidade)
Vue.filter('formatContaMilheiro', formatContaMilheiro)