<template>
  <v-container fluid class="ma-0" v-if="subCotacoesFullList!==null">
    <v-row fluid class="ma-0">
      <v-container fluid>
        <v-row>
          <v-col cols="12">
            <div v-for="(subcotacao, index) in subCotacoesFullList" :key="index">
              <v-row v-if="!subcotacao.removed" class="my-2">
                <v-col style="border: 1px solid cadetblue; border-radius: 25px;">
                  <v-row class="pa-2 text-subtitle-2" v-if="form.subcotacoes.length>1">
                    Bilhete {{ index + 1 }}
                  </v-row>
                  <v-row v-if="valueProp">
                    <cotacao-edit-form-secao-cotacao-voo-sub-cotacao-item :value="valueProp[index].dados" v-model="subcotacao.dados"/>
                  </v-row>
                  <v-row v-else>
                    <cotacao-edit-form-secao-cotacao-voo-sub-cotacao-item v-model="subcotacao.dados"/>
                  </v-row>
                </v-col>
                <v-col cols="auto" align-self="center">
                  <v-btn
                      icon
                      @click="removeTrecho(subcotacao)"
                  >
                    <v-icon>mdi-delete</v-icon>
                  </v-btn>
                </v-col>
              </v-row>
            </div>
          </v-col>
        </v-row>
      </v-container>
      <v-container fluid>
        <v-row>
          <v-col cols="auto">
            <v-btn
                small
                color="primary"
                @click="addSubcotacaoEditForm"
            >
              <v-icon
                  small
                  color="white">
                mdi-plus
              </v-icon>
              Adicionar Bilhete
            </v-btn>
          </v-col>
        </v-row>
      </v-container>
    </v-row>
  </v-container>
</template>
<script>

import CotacaoEditFormSecaoCotacaoVooSubCotacaoItem
  from "./CotacaoEditFormSecaoCotacaoVooSubCotacaoItem";

export default {
  components: {
    CotacaoEditFormSecaoCotacaoVooSubCotacaoItem
  },
  props: {
    value: {
      type: Object,
      required: false,
      default: () => null
    },
    disabled: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  data() {
    return {
      form: null,
      subCotacoesFullList: null,
      valueProp: this.value?.subcotacoes? this.value.subcotacoes : null,
    }
  },
  watch: {
    subCotacoesFullList: {
      immediate: true,
      deep: true,
      handler: function (val) {
        if (this.form) {
          this.form.subcotacoes = this.activeSubcotacoes;
          this.input();
        }
      }
    }
  },
  computed: {
    activeSubcotacoes() {
      return this.subCotacoesFullList.filter(s => !s.removed)
    }
  },
  created() {
    const defaultForm = {
      subcotacoes: []
    }
    this.form = {...defaultForm};
    if (this.value) {
      this.form = {
        ...this.form,
        ...this.value
      }
    }
    this.subCotacoesFullList = this.form.subcotacoes || [];
    if (this.activeSubcotacoes.length === 0) {
      this.addSubcotacaoEditForm();
    }
    this.input()
  },
  methods: {
    addSubcotacaoEditForm() {
      const subcotacao = {
        removed: false,
        dados: {
          viagem: {
            trechos: [],
          },
        }
      }
      const subcot = this.subCotacoesFullList[this.subCotacoesFullList.length - 1]
      const novaSubcotacao = {...subcot}
      novaSubcotacao.removed = false;
      this.subCotacoesFullList.push(novaSubcotacao)
    },
    removeTrecho(subcotacao) {
      const index = this.subCotacoesFullList.indexOf(subcotacao)
      this.subCotacoesFullList[index].removed = true;
      this.subCotacoesFullList.splice(index, 1);
    },
    input() {
      this.$emit('input', this.form);
    }
  }

}
</script>
