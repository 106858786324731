var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-dialog',{attrs:{"persistent":""},model:{value:(_vm.pagamentoDialog),callback:function ($$v) {_vm.pagamentoDialog=$$v},expression:"pagamentoDialog"}},[_c('v-card',{staticClass:"px-8 pb-8 pt-4"},[_vm._l((_vm.cotacoes),function(cotacao){return (_vm.informacoesPagamento)?_c('div',{key:cotacao.id,attrs:{"grid-list-mb":""}},[_vm._v(" Cotacão #"+_vm._s(cotacao.id)+" - "+_vm._s(_vm._f("dinheiroReal")(_vm.cotacaoValorTotalJuros(cotacao)))+" "),_c('v-radio-group',{attrs:{"row":""},model:{value:(_vm.informacoesPagamento[cotacao.id].tiposPagamento),callback:function ($$v) {_vm.$set(_vm.informacoesPagamento[cotacao.id], "tiposPagamento", $$v)},expression:"informacoesPagamento[cotacao.id].tiposPagamento"}},[_c('v-radio',{attrs:{"label":"Pagamento à vista","value":"pagamentoAVista"}}),_c('v-radio',{attrs:{"label":"Pagamento parcelado com entrada","value":"pagamentoParceladoEntrada"}}),_c('v-radio',{attrs:{"label":"Pagamento parcelado","value":"pagamentoParcelado"}}),_c('v-radio',{attrs:{"label":"Cartão do Cliente no Portal do Fornecedor","value":"pagamentoAVistaPortalFornecedor"}})],1),(
          _vm.informacoesPagamento[cotacao.id].tiposPagamento ===
          'pagamentoAVistaPortalFornecedor'
        )?_c('v-row',{staticClass:"d-flex flex-column pb-4"},[_c('h4',[_vm._v("Pagamento via Cartão do Cliente no Portal do Fornecedor")]),_vm._v(" Valor mínimo a ser cobrado: "+_vm._s(_vm._f("dinheiroReal")(_vm.valorMinimoASerCobrado(cotacao)))+" "),_c('v-moeda-field',{attrs:{"outlined":"","label":"Valor a ser cobrado do cliente","disabledEscolhaMoeda":true},on:{"input":function($event){return _vm.preencherDesconto(_vm.informacoesPagamento, cotacao)}},model:{value:(
            _vm.informacoesPagamento[cotacao.id].pagamentoAVistaPortalFornecedor
              .valorTotal
          ),callback:function ($$v) {_vm.$set(_vm.informacoesPagamento[cotacao.id].pagamentoAVistaPortalFornecedor
              , "valorTotal", $$v)},expression:"\n            informacoesPagamento[cotacao.id].pagamentoAVistaPortalFornecedor\n              .valorTotal\n          "}}),_c('h5',[_vm._v(" Desconto Resultante: "+_vm._s(_vm._f("dinheiroReal")(_vm.descontosAtuais[cotacao.id].pagamentoAVistaPortalFornecedor ?.desconto?.valor))+" ")])],1):_vm._e(),(
          _vm.informacoesPagamento[cotacao.id].tiposPagamento ===
          'pagamentoAVista'
        )?_c('v-row',{staticClass:"d-flex flex-column pb-4"},[_c('h4',[_vm._v("Pagamento à vista")]),_vm._v(" Valor mínimo a ser cobrado: "+_vm._s(_vm._f("dinheiroReal")(_vm.valorMinimoASerCobrado(cotacao)))+" "),_c('v-moeda-field',{attrs:{"outlined":"","label":"Valor a ser cobrado do cliente","disabledEscolhaMoeda":true},on:{"input":function($event){return _vm.preencherDesconto(_vm.informacoesPagamento, cotacao)}},model:{value:(
            _vm.informacoesPagamento[cotacao.id].pagamentoAVista.valorTotal
          ),callback:function ($$v) {_vm.$set(_vm.informacoesPagamento[cotacao.id].pagamentoAVista, "valorTotal", $$v)},expression:"\n            informacoesPagamento[cotacao.id].pagamentoAVista.valorTotal\n          "}}),_c('h5',[_vm._v(" Desconto Resultante: "+_vm._s(_vm._f("dinheiroReal")(_vm.descontosAtuais[cotacao.id].pagamentoAVista?.desconto?.valor))+" ")])],1):_vm._e(),(
          _vm.informacoesPagamento[cotacao.id].tiposPagamento ===
          'pagamentoParceladoEntrada'
        )?_c('v-row',{staticClass:"d-flex flex-column pb-4"},[_c('h4',[_vm._v("Pagamento parcelado com entrada")]),_c('v-select',{attrs:{"items":_vm.quantidadeParcelas,"label":"Número de parcelas"},model:{value:(
            _vm.informacoesPagamento[cotacao.id].pagamentoParceladoEntrada
              .numeroParcelas
          ),callback:function ($$v) {_vm.$set(_vm.informacoesPagamento[cotacao.id].pagamentoParceladoEntrada
              , "numeroParcelas", $$v)},expression:"\n            informacoesPagamento[cotacao.id].pagamentoParceladoEntrada\n              .numeroParcelas\n          "}}),_c('v-moeda-field',{attrs:{"outlined":"","label":"Valor da entrada","disabledEscolhaMoeda":true},on:{"input":function($event){return _vm.preencherDesconto(_vm.informacoesPagamento, cotacao)}},model:{value:(
            _vm.informacoesPagamento[cotacao.id].pagamentoParceladoEntrada
              .valorEntrada
          ),callback:function ($$v) {_vm.$set(_vm.informacoesPagamento[cotacao.id].pagamentoParceladoEntrada
              , "valorEntrada", $$v)},expression:"\n            informacoesPagamento[cotacao.id].pagamentoParceladoEntrada\n              .valorEntrada\n          "}}),(
            _vm.informacoesPagamento[cotacao.id].pagamentoParceladoEntrada
              .numeroParcelas &&
            _vm.informacoesPagamento[cotacao.id].pagamentoParceladoEntrada
              .valorEntrada
          )?_c('div',[_vm._v(" (Total - Entrada): "+_vm._s(_vm._f("dinheiroReal")(_vm.returnValorTotalMenosEntrada(cotacao)))+" ---- Valor mínimo a ser cobrado: "+_vm._s(_vm._f("dinheiroReal")(_vm.valorMinimoASerCobrado(cotacao, "pagamentoParceladoEntrada")))+" "),_c('v-moeda-field',{attrs:{"outlined":"","label":"Valor a ser cobrado do cliente","disabledEscolhaMoeda":true},on:{"input":function($event){return _vm.preencherDesconto(_vm.informacoesPagamento, cotacao)}},model:{value:(
              _vm.informacoesPagamento[cotacao.id].pagamentoParceladoEntrada
                .valorTotal
            ),callback:function ($$v) {_vm.$set(_vm.informacoesPagamento[cotacao.id].pagamentoParceladoEntrada
                , "valorTotal", $$v)},expression:"\n              informacoesPagamento[cotacao.id].pagamentoParceladoEntrada\n                .valorTotal\n            "}}),_c('h5',[_vm._v(" Desconto Resultante: "+_vm._s(_vm._f("dinheiroReal")(_vm.descontosAtuais[cotacao.id].pagamentoParceladoEntrada?.desconto ?.valor))+" ")])],1):_vm._e()],1):_vm._e(),(
          _vm.informacoesPagamento[cotacao.id].tiposPagamento ===
          'pagamentoParcelado'
        )?_c('v-row',{staticClass:"d-flex flex-column pb-4"},[_c('h4',[_vm._v("Pagamento parcelado")]),_c('v-select',{attrs:{"items":_vm.quantidadeParcelas,"label":"Número de parcelas"},model:{value:(
            _vm.informacoesPagamento[cotacao.id].pagamentoParcelado.numeroParcelas
          ),callback:function ($$v) {_vm.$set(_vm.informacoesPagamento[cotacao.id].pagamentoParcelado, "numeroParcelas", $$v)},expression:"\n            informacoesPagamento[cotacao.id].pagamentoParcelado.numeroParcelas\n          "}}),(
            _vm.informacoesPagamento[cotacao.id].pagamentoParcelado.numeroParcelas
          )?_c('div',[_vm._v(" Valor mínimo a ser cobrado: "+_vm._s(_vm._f("dinheiroReal")(_vm.valorMinimoASerCobrado(cotacao)))+" "),_c('v-moeda-field',{attrs:{"outlined":"","label":"Valor a ser cobrado do cliente","disabledEscolhaMoeda":true},on:{"input":function($event){return _vm.preencherDesconto(_vm.informacoesPagamento, cotacao)}},model:{value:(
              _vm.informacoesPagamento[cotacao.id].pagamentoParcelado.valorTotal
            ),callback:function ($$v) {_vm.$set(_vm.informacoesPagamento[cotacao.id].pagamentoParcelado, "valorTotal", $$v)},expression:"\n              informacoesPagamento[cotacao.id].pagamentoParcelado.valorTotal\n            "}}),_c('h5',[_vm._v(" Desconto Resultante: "+_vm._s(_vm._f("dinheiroReal")(_vm.descontosAtuais[cotacao.id].pagamentoParcelado?.desconto?.valor))+" ")])],1):_vm._e()],1):_vm._e(),_c('v-divider',{staticClass:"pt-2"})],1):_vm._e()}),_c('v-row',[_c('v-col',[(!_vm.paraEmpresa)?_c('search-contato',{attrs:{"isButtonDisabled":true,"label":"Responsável Financeiro"},model:{value:(_vm.responsaveisFinanceiros.responsavelFinanceiro),callback:function ($$v) {_vm.$set(_vm.responsaveisFinanceiros, "responsavelFinanceiro", $$v)},expression:"responsaveisFinanceiros.responsavelFinanceiro"}}):_vm._e(),(_vm.paraEmpresa)?_c('v-select',{attrs:{"label":"Empresa","items":_vm.empresas,"item-text":"nome","item-value":"id","prepend-inner-icon":"mdi-domain","outlined":""},model:{value:(_vm.responsaveisFinanceiros.responsavelFinanceiroEmpresa),callback:function ($$v) {_vm.$set(_vm.responsaveisFinanceiros, "responsavelFinanceiroEmpresa", $$v)},expression:"responsaveisFinanceiros.responsavelFinanceiroEmpresa"}}):_vm._e()],1),_c('div',{staticClass:"pt-5"},[_c('v-btn',{attrs:{"color":"primary"},on:{"click":function($event){return _vm.clickEmpresa()}}},[_vm._v(" "+_vm._s(_vm.descricaoBotao)+" ")])],1)],1),_c('v-row'),_c('v-row',[_c('v-btn',{attrs:{"color":"blue darken-1","text":""},on:{"click":_vm.closePagamento}},[_vm._v(" Cancelar ")]),_c('v-btn',{on:{"click":_vm.save}},[_vm._v(" Fazer pagamento ")]),_c('v-checkbox',{attrs:{"dense":"","label":"Juntar pagamentos *"},model:{value:(_vm.juntarPagamentos),callback:function ($$v) {_vm.juntarPagamentos=$$v},expression:"juntarPagamentos"}}),_c('p',[_vm._v(" *Obs.: Apenas pagamentos com forma de pagamento idênticas serão juntados (incluindo número de parcelas) ")])],1)],2)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }