<template>
  <v-card>
    <v-card-title>Companias Aéreas</v-card-title>
    <v-card-text>
      <v-container>
        <v-col v-if="ciasAereas.length > 0">
          <v-row v-for="(ciaAerea, idx) in ciasAereas" :key="idx">
            {{ciaAerea.nome}}
          </v-row>
        </v-col>
        <v-col v-else>
          <v-row>
            Nenhuma Cia Aérea especificada para esse bilhete.
          </v-row>
        </v-col>
      </v-container>
    </v-card-text>
    <v-card-actions>
      <v-spacer></v-spacer>
      <v-btn text @click="close">Fechar</v-btn>
    </v-card-actions>
  </v-card>
</template>
<script>
import {ciasAereas} from "@/helpers/subcotacao"

export default {
  
  props: {
    subcotacao: {
      type: Object,
      default: () => {}
    }
  },

  computed: {
    ciasAereas(){
      return ciasAereas(this.subcotacao)
    }
  },

  methods: {
    close(){
      this.$emit('close')
    }
  }

};
</script>
