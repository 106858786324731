<template>
  <v-container fluid v-if="emissao">
    <v-container fluid justify="left">
      <v-row>
        <v-col cols="auto">
          <v-field-value label="Emissão"
            >#{{ emissao.id | formatId }}</v-field-value
          >
        </v-col>
        <v-col cols="auto">
          <v-field-value label="Venda">
            <v-link-text-field
              :to="{ name: 'venda-info', params: { id: emissao.venda.id } }"
            >
              #{{ emissao.venda.id | formatId }}
            </v-link-text-field>
          </v-field-value>
        </v-col>
        <v-col cols="auto">
          <v-field-value label="Orçamento">
            <v-link-text-field
              :to="{ name: 'orcamento', params: { id: orcamentoID } }"
            >
              #{{ orcamentoID | formatId }}
            </v-link-text-field>
          </v-field-value>
        </v-col>
      </v-row>

      <v-row>
        <v-col cols="auto" v-if="emissao.venda.cliente">
          <v-field-value label="Cliente">
            {{
              emissao.venda.cliente &&
              emissao.venda.cliente.nome + " " + emissao.venda.cliente.sobrenome
            }}
            <v-btn
              small
              icon
              color="primary"
              :to="{
                name: 'cliente',
                params: { id: emissao.venda.cliente.id },
              }"
            >
              <v-icon> mdi-open-in-new </v-icon>
            </v-btn>
          </v-field-value>
        </v-col>
        <v-col cols="auto" v-if="emissao.venda.cliente_empresa">
          <v-field-value label="Empresa">
            {{
              emissao.venda.cliente_empresa
                ? `${emissao.venda.cliente_empresa.nome}`
                : ""
            }}
            <v-btn
              small
              icon
              color="primary"
              :to="{
                name: 'empresa',
                params: { id: emissao.venda.cliente_empresa.id },
              }"
            >
              <v-icon> mdi-open-in-new </v-icon>
            </v-btn>
          </v-field-value>
        </v-col>

        <v-col cols="auto">
          <v-field-value label="Consultor">{{
            (emissao.venda ? emissao.venda.consultor : null) | nomeSobrenome
          }}</v-field-value>
        </v-col>
        <v-col cols="auto">
          <v-field-value label="Criada em">{{
            emissao.created_at | dataHoraBR
          }}</v-field-value>
        </v-col>
        <v-col cols="auto" v-if="emissao.data_hora_emissao">
          <v-field-value label="Concluida em">{{
            emissao.data_hora_emissao | dataHoraBR
          }}</v-field-value>
        </v-col>
        <v-col cols="auto" v-if="emissao.data_hora_emissao">
          <v-field-value label="Concluida por">{{
            emissao.concluida_por | nomeSobrenome
          }}</v-field-value>
        </v-col>
      </v-row>

      <v-row v-if="emissao.status === 'cancelada'">
        <v-col cols="auto">
          <v-field-value label="Cancelada em">{{
            emissao.cancelada_em | dataHoraBR
          }}</v-field-value>
        </v-col>
        <v-col cols="auto">
          <v-field-value label="Motivo">{{
            emissao.motivo_cancelamento
          }}</v-field-value>
        </v-col>
        <v-col cols="auto">
          <v-field-value label="Cancelada por">{{
            emissao.cancelada_por | nomeSobrenome
          }}</v-field-value>
        </v-col>
      </v-row>

      <v-row>
        <v-col cols="auto">
          <v-field-value label="Status da Emissão">{{
            emissao.status | formatStatusEmissao
          }}</v-field-value>
        </v-col>
        <v-col cols="auto">
          <v-field-value label="Tipo da Emissão">{{
            emissao.tipo | formatTipoEmissao
          }}</v-field-value>
        </v-col>
        <v-col cols="auto">
          <v-field-value label="Fornecedor">{{
            emissao.fornecedor ? emissao.fornecedor.nome : ""
          }}</v-field-value>
        </v-col>
      </v-row>
    </v-container>
    <div v-if="tipo_servico === 'Hotel'">
      <emissao-detalhe-hotel :emissao="emissao" />
    </div>
    <div v-if="tipo_servico === 'Vôo'">
      <emissao-detalhe-voo-milha
        v-if="emissao.tipo === 'emissao_voo_milha'"
        :emissao="emissao"
      />
      <emissao-detalhe-voo-pagante v-else :emissao="emissao" />
    </div>
    <div v-if="tipo_servico === 'Carro'">
      <emissao-detalhe-carro :emissao="emissao" />
    </div>
    <div v-if="tipo_servico === 'Outro'">
      <emissao-detalhe-outro :emissao="emissao" />
    </div>
    <Chat :vendaID="vendaID"></Chat>
  </v-container>
</template>

<script>
import CotacaoMixin from "@/mixins/CotacaoMixin";
import VFieldValue from "@/components/widgets/VFieldValue";
import VLinkTextField from "@/components/widgets/VLinkTextField";
import Chat from "@/components/widgets/Chat";

import EmissaoDetalheOutro from "./EmissaoDetalheOutro";
import EmissaoDetalheHotel from "./EmissaoDetalheHotel";
import EmissaoDetalheCarro from "./EmissaoDetalheCarro";
import EmissaoDetalheVooMilha from "./EmissaoDetalheVooMilha";
import EmissaoDetalheVooPagante from "./EmissaoDetalheVooPagante";

export default {
  components: {
    VFieldValue,
    VLinkTextField,
    Chat,
    EmissaoDetalheVooPagante,
    EmissaoDetalheVooMilha,
    EmissaoDetalheCarro,
    EmissaoDetalheHotel,
    EmissaoDetalheOutro,
  },
  mixins: [CotacaoMixin],
  props: {
    emissao: {
      type: Object,
      default: null,
    },
  },
  computed: {
    tipo_servico() {
      const cotacao =
        this.emissao && this.emissao.servico && this.emissao.servico.cotacao;
      return this.cotacaoTipoServico(cotacao);
    },
    vendaID() {
      if (!this.emissao) return 0;
      if (!this.emissao.venda) return 0;
      return this.emissao.venda.id;
    },
    orcamentoID() {
      return this.emissao?.servico?.cotacao?.orcamento?.id;
    },
  },
};
</script>
