// export const formatProgramasFidelidade = (programas) => ""

export const formatProgramaFidelidade = (programa, allPrograma) => {
    if (!programa || !allPrograma) return ""
    if(typeof programa === 'object') return programa.nome
    const prog = allPrograma.find( e => e.id === programa)
    if(!prog) return ""
    return prog.nome 
}

export const formatCiaAerea = (ciaAerea, allCiaAereas) => {
    if (!ciaAerea || !allCiaAereas) return ""
    const cia = allCiaAereas.find(e => e.id === ciaAerea)
    if(!cia) return ""
    return `${cia.nome} (${cia.nome_resumido})`
}
