<template>
  <v-container>
    <v-expansion-panels class="mb-6">
      <v-expansion-panel
        :key="beneficiario.id"
        v-for="(beneficiario, index) in beneficiarios"
      >
        <v-expansion-panel-header>
          <v-col cols="auto">
            <v-btn
              icon
              small
              color="primary"
              @click="() => copyContent(beneficiario)"
            >
              <v-icon icon>mdi-content-copy</v-icon>
            </v-btn>
          </v-col>
          <v-col cols="6">
            <p style="font-weight: bold">Nome</p>
            <span>{{ beneficiario.contato | nomeSobrenome }}</span>

            <v-btn
              small
              icon
              color="primary"
              :to="{
                name: 'contato-info',
                params: {
                  id: beneficiario.contato?.id,
                },
              }"
            >
              <v-icon> mdi-open-in-new </v-icon>
            </v-btn>
          </v-col>
          <v-col cols="6">
            <p style="font-weight: bold">CPF</p>
            <span v-if="beneficiario.contato && beneficiario.contato.cpf">
              {{ beneficiario.contato.cpf | formatCpf }}
            </span>
          </v-col>
          <v-col cols="6" v-if="beneficiario.contato">
            <p style="font-weight: bold">Data de nascimento</p>
            <span>{{
              beneficiario.contato.data_nascimento | convertDataBR
            }}</span>
          </v-col>
          <v-col cols="6" v-if="beneficiario.contato.passaport">
            <p style="font-weight: bold">Passaporte</p>
            <span>
              {{ beneficiario.contato.passport.numero }}
            </span>
          </v-col>
          <v-col cols="6" v-if="beneficiario.contato.passaport">
            <p style="font-weight: bold">Emissão passaporte</p>
            <span>
              {{ beneficiario.contato.passport.data_emissao }}
            </span>
          </v-col>
          <v-col cols="6" v-if="beneficiario.contato.passaport">
            <p style="font-weight: bold">Expiração passaporte</p>
            <span>
              {{ beneficiario.contato.passport.data_validade }}
            </span>
          </v-col>
        </v-expansion-panel-header>
        <v-expansion-panel-content>
          <v-row>
            <v-col>
              <v-text-field
                outlined
                dense
                label="E-Ticket"
                v-model="beneficiario.eticket"
              >
                <template v-slot:append-outer>
                  <v-btn
                    icon
                    color="success"
                    :loading="loadingUpdateBeneficiario"
                    @click="updateBeneficiario(beneficiario)"
                    :disabled="emissao.status !== 'fila_de_emissao'"
                  >
                    <v-icon> mdi-check </v-icon>
                  </v-btn>
                </template>
              </v-text-field>
            </v-col>
          </v-row>
          <v-row v-if="emissaoVoo">
            <v-col>
              <v-text-field
                outlined
                dense
                label="Assento"
                v-model="beneficiario.assento"
              >
                <template v-slot:append-outer>
                  <v-btn
                    icon
                    color="success"
                    :loading="loadingUpdateBeneficiario"
                    @click="updateBeneficiario(beneficiario)"
                    :disabled="emissao.status !== 'fila_de_emissao'"
                  >
                    <v-icon> mdi-check </v-icon>
                  </v-btn>
                </template>
              </v-text-field>
            </v-col>
            <v-col>
              <v-text-field
                outlined
                dense
                label="Tipo de assento"
                v-model="beneficiario.tipo_assento"
              >
                <template v-slot:append-outer>
                  <v-btn
                    icon
                    color="success"
                    :loading="loadingUpdateBeneficiario"
                    @click="updateBeneficiario(beneficiario)"
                    :disabled="emissao.status !== 'fila_de_emissao'"
                  >
                    <v-icon> mdi-check </v-icon>
                  </v-btn>
                </template>
              </v-text-field>
            </v-col>
          </v-row>
          <v-row v-if="emissao.tipo === 'emissao_hotel'">
            <v-col>
              <v-text-field
                outlined
                dense
                label="Tipo de quarto"
                v-model="beneficiario.tipo_quarto"
              >
                <template v-slot:append-outer>
                  <v-btn
                    icon
                    color="success"
                    :loading="loadingUpdateBeneficiario"
                    @click="updateBeneficiario(beneficiario)"
                    :disabled="emissao.status !== 'fila_de_emissao'"
                  >
                    <v-icon> mdi-check </v-icon>
                  </v-btn>
                </template>
              </v-text-field>
            </v-col>
          </v-row>
          <v-row>
            <v-spacer />
            <v-col cols="2">
              <v-btn
                @click="removerBeneficiarioFirstClick(beneficiario)"
                icon
                :disabled="emissao.status !== 'fila_de_emissao'"
              >
                <v-icon color="red"> mdi-delete </v-icon>
              </v-btn>
            </v-col>
          </v-row>
        </v-expansion-panel-content>
      </v-expansion-panel>
    </v-expansion-panels>
    <!--    Form para remover contato   -->
    <v-dialog v-model="showDialogRemoverBeneficiario">
      <v-card v-if="showDialogRemoverBeneficiario">
        <v-card-title class="grey" />
        <v-card-text>
          <div>
            Deseja realmente remover o beneficiário
            {{ edittingItem.contato | nomeSobrenome }} ?
          </div>
        </v-card-text>
        <v-card-actions>
          <v-spacer />
          <v-btn
            @click="
              edittingItem = null;
              showDialogRemoverBeneficiario = false;
            "
            >Cancelar
          </v-btn>
          <v-btn
            color="red"
            :loading="loadingSalvarEmissao"
            :disabled="!edittingItem"
            @click="removerBeneficiario"
          >
            Remover
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import EmissaoMixin from "@/mixins/EmissaoMixin";
import VFieldValue from "@/components/widgets/VFieldValue";
import api from "@/api/api";
import { serializeDatePassport } from "@/helpers/dates";
import { copyToClipboard } from "@/helpers/copy";

export default {
  components: { VFieldValue },

  props: {
    emissao: {
      type: Object,
      required: true,
    },
  },

  data() {
    return {
      showDialogNovoBeneficiario: false,
      showDialogRemoverBeneficiario: false,
      form: null,
      edittingItem: null,
      beneficiarios: [],
    };
  },

  mixins: [EmissaoMixin],

  computed: {
    emissaoVoo() {
      return this.emissao.tipo.includes("voo");
    },
  },

  mounted() {
    api
      .getBeneficiariosEmissao(this.emissao.id)
      .then((res) => (this.beneficiarios = res.data))
      .catch((err) =>
        this.$root.vtoast.show({
          message: "Não foi possivel recuperar os beneficiários",
          color: "error",
          icon: "mdi-close",
        }),
      );
    // this.emissao.beneficiarios.forEach(async ({ contato }) => {
    //   await this.appendPassport(contato);
    // });
  },

  methods: {
    updateBeneficiario(beneficiario) {
      api
        .updateBeneficiario(beneficiario.id, beneficiario)
        .then((res) => {
          this.$root.vtoast.show({
            message: "Beneficiário alterado com sucesso",
            color: "success",
            icon: "mdi-check",
          });
        })
        .catch((err) => {
          console.error(err);
          this.$root.vtoast.show({
            message: "Beneficiário não pode ser alterado.",
            color: "error",
            icon: "mdi-close",
          });
        });
    },

    copyContent(beneficiario) {
      if (!beneficiario) return;
      const nome = beneficiario.contato?.nome || "";
      const sobrenome = beneficiario.contato?.sobrenome || "";
      const cpf = beneficiario.contato?.cpf || "";
      const dataNascimento = beneficiario.contato?.data_nascimento || "";
      copyToClipboard(`
        Nome: ${nome} \n
        Sobrenome: ${sobrenome} \n
        CPF: ${cpf} \n
        Data de Nascimento: ${dataNascimento} \n
      `);
      this.$root.vtoast.show({
        message: "Dados do beneficiário copiados para área de transferencia!",
        color: "success",
        icon: "mdi-check",
      });
    },

    async getPassportInfo(id) {
      return await api.getContatoPassaporte(id);
    },

    async appendPassport(contact) {
      const { data } = await this.getPassportInfo(contact.id);
      const [passport] = data;
      const emptyPassport = {
        numero: "-",
        data_emissao: "-",
        data_validade: null,
      };

      if (contact.perfil === "passageiro_internacional")
        contact.passport = { ...passport };
      else contact.passport = { ...emptyPassport };

      contact.data_nascimento = serializeDatePassport(contact.data_nascimento);
      contact.passport.data_validade = serializeDatePassport(
        contact.passport.data_validade
      );
    },
  },
};
</script>
