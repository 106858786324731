<template>
  <v-chip
      label
      :color="corStatus"
      :text-color="corTextoStatus">
    {{ statusText }}
  </v-chip>
</template>

<script>
import STATUS_EMISSAO from "@/constants/statusEmissao"

const cores = STATUS_EMISSAO.reduce(
  (cum, curr) => ({
      ...cum, 
      [curr.key]: {
        ...curr
      }
    }), {}
)

export default {
  props: {
    status: {
      type: String,
      default: ""
    }
  },
  computed: {
    corStatus() {
      const status = this.status
      if (status in cores) {
        return cores[status].background
      }
      return "grey";
    },
    corTextoStatus() {
      const status = this.status
      if (status in cores) {
        return cores[status].text
      }
      return "white";
    },
    statusText(){
      let statusObj = STATUS_EMISSAO.find(s => s.key === this.status)

      // to be compatible with old statuses like "Não inciado"
      if(!statusObj) 
        statusObj = STATUS_EMISSAO.find(s => s.value === this.status)

      if(!statusObj)  return  ""
      return statusObj.value
    }
  }
}
</script>
