<template>
    <div>
        <v-card class="elevation-5">
            <v-container fluid>
                <v-row>
                    <v-col cols="2" style="max-height: 70px;">
                        <v-select
                            clear-icon="mdi-close"
                            clearable
                            outlined
                            v-model="filters.campo_formador"
                            label="Campo formador"
                            :items="campo_formador"
                            item-text="value"
                            item-value="key"
                            dense
                            class="my-5"                      
                        >
                        </v-select>
                    </v-col>
                    <v-col cols="2" style="max-height: 70px;">
                        <v-text-field
                            dense
                            outlined
                            clearable
                            clear-icon="mdi-close"
                            append-icon="mdi-magnify"
                            label="ID da Venda"
                            v-model="filters.id_venda"
                            class="my-5"
                        >
                        </v-text-field>
                    </v-col>
                    <v-col cols="2" style="max-height: 70px;">
                        <v-select
                            clear-icon="mdi-close"
                            clearable
                            outlined
                            v-model="filters.fornecedor"
                            label="Fornecedor"
                            :items="fornecedores"
                            item-text="nome"
                            item-value="id"
                            dense
                            class="my-5"                      
                        >
                        </v-select>
                    </v-col>
                    <v-col cols="2" style="max-height: 70px;">
                        <v-select
                            clear-icon="mdi-close"
                            clearable
                            outlined
                            v-model="filters.card"
                            label="Cartão"
                            :items="cards"
                            :item-text="formatCard"
                            item-value="id"
                            dense
                            class="my-5"
                        >
                        </v-select>
                    </v-col>
                    <v-col cols="2" style="max-height: 70px;">
                        <v-text-field
                            dense
                            outlined
                            clearable
                            clear-icon="mdi-close"
                            append-icon="mdi-magnify"
                            label="Localizador"
                            v-model="filters.localizador"
                            class="my-5"
                        >
                        </v-text-field>
                    </v-col>
                </v-row>
            </v-container>
            <v-container fluid v-if="loadingTable">
                <v-card>
                    <v-card-text class="text-h6 text-center">
                        Aguarde um momento...
                    </v-card-text>
                </v-card>
            </v-container>
            <v-container fluid v-else-if="ordemPagamento && ordemPagamento.length > 0">     
                <v-card
                    v-for="(emissaoOP, index) in OPPerEmissao"
                    :key="index"
                    class="my-6 elevation-5"
                    style="border-radius: 10px;"
                >
                    <v-card-title class="justify-center white--text primary">
                        <v-row>                        
                            <v-col cols="12" class="text-center">
                                <span class="text-uppercase">Emissão: {{ emissaoOP.emissao.OPs[0].emissao.id }}</span>
                                <v-divider vertical color="white" class="mx-2 pa-0" v-if="emissaoOP.emissao.OPs[0].emissao.localizador"/>
                                <span class="text-uppercase" v-if="emissaoOP.emissao.OPs[0].emissao.localizador">LOC: {{ emissaoOP.emissao.OPs[0].emissao.localizador }}</span>
                            </v-col>
                        </v-row>
                    </v-card-title>
                    <v-card-text>
                        <v-data-table
                            :headers="headers"
                            :items="emissaoOP.emissao.OPs"
                            :loading="loading"
                            :hide-default-footer="true"
                            :items-per-page="15"
                        >  
                            <template v-slot:item.servico.venda="{ item }">
                                <v-link-text-field
                                :to="{ name: 'venda-info', params: { id: item.emissao.venda } }"
                                >
                                #{{ item.emissao.venda | formatVendaId }}
                                </v-link-text-field>
                            </template>

                            <template v-slot:item.valor="{ item }">
                                {{ item.valor | dinheiroReal}}
                            </template>

                            <template v-slot:item.emissao.tipo="{ item }">
                                {{ item.emissao.tipo | formatTipoEmissao}}
                            </template>

                            <template v-slot:item.campo_formador="{ item }">
                                {{ item.campo_formador | formatCampoFormadorPreco}}
                            </template>

                            <template v-slot:item.meio_pagamento="{ item }">
                                {{ item.meio_pagamento | formatMeioPagamento}}
                            </template>

                            <template v-slot:item.data_solicitacao="{ item }">
                                {{ item.data_solicitacao | convertDataBR}}
                                às
                                {{ item.data_solicitacao | dataHoraMinuto }}
                            </template>

                            <template v-slot:item.data_alteracao="{ item }">
                                {{ item.data_alteracao | convertDataBR}}
                                <span v-if="item.data_alteracao">às</span>
                                {{ item.data_alteracao | dataHoraMinuto }}
                            </template>

                            <template v-slot:item.fornecedor="{ item }">
                                {{ item.fornecedor }}
                            </template>

                            <template v-slot:item.emissao.status="{ item }">
                                {{ item.emissao.status | formatStatusEmissao}}
                            </template>

                            <template v-slot:item.cartao="{ item }">
                                {{ formatCard(item.cartao) }}
                            </template>

                            <template v-slot:item.data_pagamento="{ item }">
                                {{ item.data_pagamento | convertDataBR}}
                            </template>

                            <template v-slot:no-data> 
                                Nenhuma ordem de pagamento em aberto encontrada. 
                            </template>

                            <template v-slot:item.actions="{ item }">
                                <v-row>
                                    <v-col cols="6">
                                        <v-select
                                            clear-icon="mdi-close"
                                            clearable
                                            label="status"
                                            :items="statusAberto"
                                            item-value="key"
                                            item-text="value"
                                            outlined
                                            dense
                                            v-model="selectedOrdemPagamento[item.id]"
                                            class="mt-5"
                                            :disabled="checkUserPermissao"
                                            @change="checkStatusChange(item.id, selectedOrdemPagamento[item.id])"
                                        >
                                        </v-select>
                                    </v-col>
                                </v-row>
                                <!-- DIALOG PARA ALTERAÇÃO DE STATUS -->
                                <v-dialog v-model="dialogAlterStatus" persistent max-width="500px" :overlay-opacity="0.1" transition="dialog-bottom-transition">
                                    <v-card class="overflow-y-hidden" style="border-radius: 10px;">
                                        <v-card-title class="text-h5 justify-center white--text primary">Deseja mudar o status para aberto?</v-card-title>
                                        <v-divider/>
                                        <v-card-text>
                                            <v-row>
                                                <v-col cols="12">
                                                    <v-card-subtitle>ID fatura e a data de pagamento que haviam sido salvos serão excluídos para essa ordem de pagamento.</v-card-subtitle>
                                                </v-col>
                                            </v-row>
                                        </v-card-text>
                                        <v-divider class="py-0"/>
                                        <v-card-actions>
                                        <v-row>
                                            <v-col cols="6">
                                                <v-btn block large color="error" @click="closeDialogAlterStatus">Cancelar</v-btn>
                                            </v-col>
                                            <v-col cols="6">
                                                <v-btn block large color="success" :loading="loadingAlterStatus" @click="ConfirmChangeStatus">Confirmar</v-btn>
                                            </v-col>
                                        </v-row>
                                        </v-card-actions>
                                    </v-card>
                                </v-dialog>
                            </template>
                        </v-data-table>
                        <span class="px-4 black--text">> Custo total: {{ emissaoOP.emissao.custo_total | dinheiroReal }}</span>
                    </v-card-text>
                </v-card>
            </v-container>
            <v-container fluid v-else>
                <v-card class=" elevation-5">
                    <v-card-title class="justify-center">
                        <span>Nenhuma ordem de pagamento como pago encontrada.</span>
                    </v-card-title>
                </v-card>
            </v-container>
            <Paginator
                    v-model="currentPage"
                    :numPages="numPagesOrdemPagamento"
                    @pageChange="pageChange"
            />
        </v-card>
    </div>
</template>
<script>
import api from "@/api/api";
import Paginator from '@/components/widgets/Paginator.vue';
import statusOrdemPagamento from '@/constants/statusOrdemPagamento';
import MEIOS_DE_PAGAMENTO from "@/constants/meiosPagamento";
import VLinkTextField from "@/components/widgets/VLinkTextField";
import tipoCampoFormadorPreco from "@/constants/tipoCampoFormadorPreco";
import FormDatePicker from "../widgets/FormDatePicker.vue";
import VMoedaField from "@/components/widgets/VMoedaField";
import CotacaoMixin from "@/mixins/CotacaoMixin";
import checkPermissao from "@/helpers/checkUserPermissao.js";

export default {
    components: {Paginator, VLinkTextField, FormDatePicker, VMoedaField},
    mixins:[CotacaoMixin],
    data: () => ({
        headers: [
            { text: "ID", value: "id" },
            { text: "Venda", value: "emissao.venda" },
            { text: "Serviço", value: "emissao.servico" },
            { text: "Valor", value: "valor" },
            { text: "Tipo de serviço", value: "emissao.tipo"},
            { text: "Campo formador", value: "campo_formador" },
            { text: "Meio de pagamento", value: "meio_pagamento" },
            { text: "Data de solicitação", value: "data_solicitacao" },
            { text: "Data de alteração", value: "data_alteracao" },
            { text: "Fornecedor", value: "fornecedor.nome" },
            { text: "Status da emissão", value: "emissao.status" },
            { text: "Cartões", value: "cartao"},
            { text: "ID fatura", value: "id_fatura"},
            { text: "Data do pagamento", value: "data_pagamento"},
            { text: "Ações", value: "actions" },
        ],
        ordemPagamento: [],
        currentPage: 1,
        numPagesOrdemPagamento: null,
        loading: false,
        selectedOrdemPagamento: {},
        OPId: null,
        statusOrdemPagamento,
        MEIOS_DE_PAGAMENTO,
        loadingAlterStatus: false,
        filters:{
            campo_formador:"",
            id_venda:null,
            fornecedor: null,
            card: null,
            localizador: "",
        },
        campo_formador: tipoCampoFormadorPreco,
        dialogAlterStatus: false,
        pagamento:{
            id_fatura: '',
            data_pagamento: new Date,
        },
        OPstatus: null,
        selectedCampoFormador: null,
        servicoId: null,
        cotacao: null,
        actionsOptions: [
            { text: "valor", value: "Valor" },
            { text: "cartao", value: "Cartão" },
        ],
        cards: null,
        fornecedores: null,
        loadingTable: false,
    }),
    async mounted(){
        await this.initialize()
    },

  watch:{
      currentPage:{
          handler(){
              this.pageChange(this.currentPage);
          }
      },
      filters: {
          deep: true,
          handler() {
              this.pageChange(1);
          },
      },
  },

  computed:{
      OPPerEmissao() {
        let groupedByEmissao = {};
            if (this.ordemPagamento) {
                groupedByEmissao = this.ordemPagamento.map((emissao) => {
                    return {emissao};
                })
            }

          return groupedByEmissao;
      },
      labelText(){
         switch(this.selectedCampoFormador){
              case "custo_bilhete":
                  return "Custo bilhete";
              case "custo_taxa_embarque":
                  return "Custo taxa de embarque"  
              case "custo_taxa_bagagem":
                  return "Custo taxa de bagagem";
              case "rav":
                  return "RAV";
              case "quantidade_milhas":
                  return "Quantidade de milhas";
              case "custo_taxas_extras":
                  return "Custo taxas extras";
              case "milheiro":
                  return 'Milheiro';
              case "custo_diaria":
                  return "Custo da diária";
              case "custo_extras":
                  return "Extras"
              case "custo_seguro":
                  return "Seguro";
              case "custo_total":
                  return "Custo total"
              default:
                  return "";
          }
      },
      statusAberto() {
          return this.statusOrdemPagamento.filter(status => status.key === 'aberto');
      },
      user() {
          return this.$store.getters['usuario/loggedUser'];
      },
      checkUserPermissao(){
          const pertencente = this.user.pertencente;
          return checkPermissao(pertencente);
      }
  },

    methods: {
        async initialize() {
            this.loadingTable = true;
            await api.getOrdensPagamentosPagos()
            .then(response => {
                this.ordemPagamento = response.data.ordemPagamento
                this.loadingTable = false;
                this.numPagesOrdemPagamento = response.data.pages
            })
            .catch(err => {
                this.$root.vtoast.show({
                    message: `Erro ao carregar ordens de pagamentos pagos: ${err}`,
                    icon: 'mdi-close',
                    color: 'error'
                })
            })
            await api
                .getCartoes()
                .then((res) => (this.cards = res.data))
                .catch((err) =>
                this.$root.vtoast.show({
                    message: "Erro ao recuperar cartões",
                    color: "error",
                    icon: "mdi-close",
                })
                );
            await this.$store.dispatch("global/fetchFornecedores")
                .then((res) => {this.fornecedores = res.data})
                .catch((err) => {
                    this.$root.vtoast.show({
                        message: "Erro ao recuperar fornecedores",
                        color: "error",
                        icon: "mdi-close",
                    })
                }
                );
        },
        async refreshOrdensPagamentosPagos(page){
            this.loadingTable = true;
            api.getOrdensPagamentosPagos(page).then((response) => {
                this.ordemPagamento = response.data.ordemPagamento
                this.loadingTable = false;
                this.numPagesOrdemPagamento = response.data.pages
            })
            .catch(err => {
                this.$root.vtoast.show({
                    message: `Erro ao carregar ordens de pagamentos pagos: ${err}`,
                    icon: 'mdi-close',
                    color: 'error'
                })
            })
            .finally(() => {
                this.loadingTable = false;
            })
        },
        formatCard(cartao){
            if(!cartao) return "";
            return `${cartao.nome} - ${cartao.bandeira} (${cartao.digs})`;
        },
        pageChange(page = 1) {
            clearTimeout(this.timer);

          let filters = [];

          if(this.filters.campo_formador){
              filters.push(["campo_formador", this.filters.campo_formador]);
          }

          if(this.filters.id_venda){
              filters.push(["id_venda", this.filters.id_venda]);
          }

          if(this.filters.fornecedor){
              filters.push(["fornecedor", this.filters.fornecedor]);
          }

          if(this.filters.card){
              filters.push(["card", this.filters.card]);
          }

          if(this.filters.localizador){
              filters.push(["localizador", this.filters.localizador]);
          }

            this.timer = setTimeout(() => {
                api.getOrdensPagamentosPagos(page, status = 'pago', filters).then((response) => {
                    this.ordemPagamento = response.data.ordemPagamento
                    this.loadingTable = false;
                    this.numPagesOrdemPagamento = response.data.pages
                });
            }, 1000);

            this.loadingTable = true;
        },
        openDialogAlterStatus(id, selectedOrdemPagamento){
            this.dialogAlterStatus = true;
            this.OPId = id;
            this.OPstatus = selectedOrdemPagamento;
        },
        closeDialogAlterStatus(){
            this.dialogAlterStatus = false;
            this.OPId = null;
            this.pagamento.id_fatura = null;
            this.pagamento.data_pagamento = null;
        },
        OPAlterStatus(id, selectedOrdemPagamento) {
            this.loadingAlterStatus = true;
            const data = {
                status: selectedOrdemPagamento,
                id_fatura: this.pagamento.id_fatura,
                data_pagamento: this.pagamento.data_pagamento
            }

          api.postOrdemPagamentoStatus(id, data)
              .then(response => {
                  this.$root.vtoast.show({
                      message: "Alterado status da ordem de pagamento!",
                      color: "success",
                      icon: "mdi-check",
                  })
                  this.loadingAlterStatus = false;
                  this.refreshOrdensPagamentosPagos();
                  this.closeDialogAlterStatus();
              })
              .catch(
                  error => {
                      console.error(error)
                      this.$root.vtoast.show({
                          message: "Erro ao alterar o status da ordem de pagamento!",
                          icon: "mdi-error",
                          color: "error",
                      })
                      this.loadingAlterStatus = false;
                      this.closeDialogAlterStatus();
                  })
      },
      ConfirmChangeStatus(){
          this.OPAlterStatus(this.OPId, this.OPstatus);
      },
      checkStatusChange(id, selectedOrdemPagamento){
          if(selectedOrdemPagamento){
              this.openDialogAlterStatus(id, selectedOrdemPagamento);
          }
      },
  },
}
</script>
<style scoped>
/* fixing v-dialog box-shadow being "weird" */
::v-deep .v-dialog.v-dialog--persistent {
box-shadow: none;
}
</style>
