<template>
  <v-container fluid>
    <v-row class="ma-0">
      <v-card outlined rounded width="100%" elevation="2">
        <v-row class="ma-1">
          <v-col>
            <v-row>
              <v-col>
                <v-field-value label="Tipo de serviço">Hotel</v-field-value>
              </v-col>
            </v-row>
            <v-row class="ma-2">
              <select-hotel v-model="orcamento.orcamento_hotel" @input="input"></select-hotel>
            </v-row>
            <v-text-field class="mx-6" label="Descrição rápida" v-model="orcamento.descricao" :rules="[
              (v) =>
                (v || '').length <= 30 ||
                'Descrições devem ter menos de 30 caracteres',
            ]"></v-text-field>
          </v-col>
          
        </v-row>
        

      </v-card>
    </v-row>
  </v-container>
</template>

<script>

import SelectTrechosVoo from "@/components/Orcamento/Widgets/SelectTrechosVoo";
import VFieldValue from "@/components/widgets/VFieldValue";
import SelectHotel from "@/components/Orcamento/Widgets/SelectHotel";

export default {
  components: {SelectHotel, VFieldValue, SelectTrechosVoo},
  props: {
    value: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      orcamento: null
    }
  },
  created() {
    this.orcamento = this.value ? {...this.value} : {tipo: "orcamento_hotel", orcamento_hotel: {}}
  },
  methods: {
    input() {
      this.$emit('input', this.orcamento)
    }
  }
}

</script>
