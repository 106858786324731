<template >
    <v-card class="my-6 elevation-5" :loading="loading">
        <v-card-title>Notificações</v-card-title>
        <v-card-text>
            <v-data-table :items="notificacoes" :headers="headers" v-if="notificacoes" sort-by="created_at" sort-desc
                :hide-default-footer="true" :items-per-page="15">
                <template v-slot:top>
                    <v-container fluid>
                        <v-row v-if="loading === false">
                            <v-col>
                                <v-row>
                                    <v-col cols="12" md="2">
                                        <v-autocomplete clear-icon="mdi-close" clearable outlined v-model="filter.consultor"
                                            label="Consultor" :item-text="item => `${item.nome} ${item.sobrenome}`"
                                            :item-value="item => item.id" :items="users" dense>
                                        </v-autocomplete>
                                    </v-col>
                                    <v-col cols="12" md="2">
                                        <v-text-field dense outlined clearable clear-icon="mdi-close" append-icon="mdi-magnify"
                                            label="ID da Venda" v-model="search.id_venda" />
                                    </v-col>
                                    <v-col cols="12" md="2">
                                        <v-checkbox label="Apenas importantes" v-model="filter.importante" />
                                    </v-col>
                                    <v-col cols="12" md="2">
                                        <v-checkbox label="Apenas lidos" v-model="filter.lido" />
                                    </v-col>
                                    <v-col cols="12" md="2">
                                        <v-select label="Tipo" id="tipo" v-model="filter.tipo" :items="tipoNotificacao"
                                            item-value="key" item-text="value" outlined clearable />
                                        {{ filter.tipo }}
                                    </v-col>
                                </v-row>
                                <v-row>
                                    <v-col cols="12" md="2">
                                        <v-text-field dense outlined clearable clear-icon="mdi-close" append-icon="mdi-magnify"
                                            label="Fornecedor ID" v-model="search.fornecedor" />
                                    </v-col>
                                    <v-col cols="12" md="2">
                                        <v-text-field dense outlined clearable clear-icon="mdi-close" append-icon="mdi-magnify"
                                            label="Provedor ID" v-model="search.provedor" />
                                    </v-col>
                                </v-row>
                            </v-col>



                        </v-row>
                    </v-container>
                </template>
                <template v-slot:item.venda="{ item }">
                    <v-link-text-field v-if="item.venda" :to="{ name: 'venda-info', params: { id: item?.venda?.id } }" text
                        small>
                        # {{ item?.venda?.id }}
                    </v-link-text-field>
                </template>
                <template v-slot:item.ids="{ item }">
                    {{ item.ids }}
                </template>
                <template v-slot:item.title="{ item }">
                    {{ item.title }}
                </template>
                <template v-slot:item.created_at="{ item }">
                    {{ item.created_at | dataHoraBR }}
                </template>
                <template v-slot:item.context="{ item }">
                    {{ item.context | formatTipoNotificacao }}
                </template>
                <template v-slot:item.cliente="{ item }">
                    {{ item?.venda?.cliente | nomeSobrenome }}
                    {{ item?.venda?.cliente_empresa?.nome }}
                </template>
                <template v-slot:item.consultor="{ item }">
                    {{ item?.venda?.consultor | nomeSobrenome }}
                </template>
                <template v-slot:item.actions="{ item }">
                    <v-btn icon :color=colorImportante(item)>
                        <v-icon small class='mr-2' @click='marcarImportante(item.id)'>mdi-star
                        </v-icon>
                    </v-btn>

                    <v-btn icon :color=colorLido(item) @click="marcarLido(item.id)">
                        <v-icon small class="mr-2">mdi-email-open</v-icon>
                    </v-btn>
                    <v-btn icon :color="colorLog(item)">
                        <v-icon small class='mr-2' @click='verLog(item)'>mdi-file-document
                        </v-icon>
                    </v-btn>
                </template>
            </v-data-table>
            <v-pagination v-model="currentPage" :length="numPages" :total-visible="7"></v-pagination>
        </v-card-text>
        <log-descricao-popup v-model="dialog" :dados="log?.dados" :metaDados="log?.meta" />
    </v-card>
</template>
<script>
import api from "@/api/api"
import cotacaoMixin from "@/mixins/CotacaoMixin"
import VLinkTextField from "@/components/widgets/VLinkTextField";
import { formatApiError } from "@/helpers/apiErrors";
import TIPO_NOTIFICACAO from "@/constants/tipoNotificacao"
import LogDescricaoPopup from "@/components/Logs/LogDescricaoPopup"
export default {

    mixins: [cotacaoMixin],
    components: { VLinkTextField, LogDescricaoPopup },

    props: {
        notificacoes: {
            type: Array,
            default: () => []
        },
        loading: {
            type: Boolean,
            default: true,
        }
    },

    data: () => ({
        tipoNotificacao: TIPO_NOTIFICACAO,
        filter: {
            importante: false,
            lido: false,
            consultor: "",
            id_venda: "",
            provedor: "",
            fornecedor: "",
        },
        timer: null,
        search: {
            id_venda: '',
            provedor: "",
            fornecedor: "",
        },
        headers: [
            { text: "Criado em", value: "created_at" },
            { text: "Título", value: "title" },
            { text: "Consultor", value: "consultor" },
            { text: "Venda", value: "venda" },
            { text: "Tipo", value: "context" },
            { text: "ids", value: "ids" },
            { text: "Cliente", value: "cliente" },
            { text: "Actions", value: "actions" },
        ],
        currentPage: 1,
        numPages: 6,
        dialog: false,
        log: null,
    }),
    computed: {
        loggedUser() {
            return this.$store.getters['usuario/loggedUser'];
        },

        users() {
            return this.$store.getters['usuario/usuarios'];
        },
    },
    watch: {
        currentPage(val) {
            this.changePage()
        },
        'search.id_venda': {
            handler() {
                clearTimeout(this.timer)

                this.timer = setTimeout(() => {
                    this.filter.id_venda = this.search.id_venda
                }, 1000)
            }
        },
        'search.provedor': {
            handler() {
                clearTimeout(this.timer)

                this.timer = setTimeout(() => {
                    this.filter.provedor = this.search.provedor
                }, 1000)
            }
        },
        'search.fornecedor': {
            handler() {
                clearTimeout(this.timer)

                this.timer = setTimeout(() => {
                    this.filter.fornecedor = this.search.fornecedor
                }, 1000)
            }
        },
        filter: {

            deep: true,
            handler(val) {
                this.currentPage = 1
                this.changePage()
            }
        }
    },

    methods: {
        marcarLido(id) {
            api.marcarLido(id).then((res) => {
                this.changePage()
            }).catch(e => {
                this.$root.vtoast.show({
                    message: 'Erro ao fazer a atualizar status',
                    color: 'error',
                    icon: 'mdi-close'
                })

                console.error(e)
            })
        },
        marcarImportante(id) {
            api.marcarImportante(id).then((res) => {
                this.changePage()
            }).catch(e => {
                this.$root.vtoast.show({
                    message: 'Erro ao fazer a atualizar status',
                    color: 'error',
                    icon: 'mdi-close'
                })
                console.error(e)
            })
        },
        verLog(item) {
            api.getLog(item.log).then((res) => {
                this.dialog = true
                this.log = res.data
            }).catch(e => {
                this.$root.vtoast.show({
                    message: 'Erro ao procurar logs',
                    color: 'error',
                    icon: 'mdi-close'
                })
                console.error(e)
            })
        },
        colorLido(item) {
            return item.lido ? "blue" : "gray"
        },
        colorImportante(item) {
            return item.importante ? "yellow" : "gray"
        },
        colorLog(item) {
            return item.log ? "green" : "gray"
        },
        changePage() {
            let filters = []
            if (this.filter.consultor) filters.push(["consultor", this.filter.consultor])
            if (this.filter.lido) filters.push(["lido", this.filter.lido])
            if (this.filter.importante) filters.push(["importante", this.filter.importante])
            if (this.filter.id_venda) filters.push(["id_venda", this.filter.id_venda])
            if (this.filter.tipo) filters.push(["tipo", this.filter.tipo])
            if (this.filter.provedor) filters.push(["provedor", this.filter.provedor])
            if (this.filter.fornecedor) filters.push(["fornecedor", this.filter.fornecedor])
            this.$emit('atualizar', this.currentPage, filters)
        },


    }
}
</script>