  <template>
  <v-container fluid>
    <v-card>
      <v-card-text>
        <v-container fluid>
          <v-row fluid>
            <v-col> # {{ emissao.id }} </v-col>
            <v-col>
              {{ emissao.status | formatStatusEmissao }}
            </v-col>
            <v-col v-if="emissao.emitido_por">
              Emissor: {{ emissao.emitido_por | nomeSobrenome }}
            </v-col>
            <v-col> Cot. {{ emissao.servico.cotacao.id }} </v-col>
            <v-col> Orç. {{ emissao.servico.cotacao.orcamento.id }} </v-col>

            <v-col>
              {{ emissao.tipo | descricaoEmissao }}
            </v-col>
            <v-col>
              <v-btn
                color="primary"
                :to="{ name: 'emissao', params: { id: emissao.id } }"
              >
                <v-icon>mdi-pencil</v-icon>
              </v-btn>
            </v-col>
            <v-col>
              <v-dialog v-model="detalhes" persistent max-width="600px">
                <template v-slot:activator="{ on, attrs }">
                  <v-btn color="primary" dark v-bind="attrs" v-on="on">
                    Detalhes
                  </v-btn>
                </template>
                <v-card>
                  <v-card-title>
                    <span class="text-h5"> Detalhes </span>
                  </v-card-title>
                  <v-card-text>
                    <v-col>
                      <v-row>
                        Criado em:
                        {{ emissao.venda.created_at | convertDataBR }}
                      </v-row>
                      <v-row>
                        Passageiro: {{ emissao.venda.cliente | nomeSobrenome }}
                      </v-row>

                      <v-row
                        v-for="(trecho, index) in emissao.subcotacao_voo?.viagem
                          .trechos"
                        :key="index"
                      >
                        <div
                          v-for="(perna, index) in trecho.pernas"
                          :key="index"
                        >
                          Origem: {{ perna.origem.nome }} - Destino:
                          {{ perna.destino.nome }}
                        </div>
                      </v-row>
                    </v-col>
                  </v-card-text>
                  <v-card-actions>
                    <v-btn color="secondary" @click="detalhes = false">
                      Fechar
                    </v-btn>
                  </v-card-actions>
                </v-card>
              </v-dialog>
            </v-col>
          </v-row>
          <v-row v-if="emissao.status === 'cancelada'">
            <v-col cols="auto">
              <v-field-value label="Cancelada em">{{
                emissao.cancelada_em | convertDataBR
              }}</v-field-value>
            </v-col>
            <v-col cols="auto">
              <v-field-value label="Motivo">{{
                emissao.motivo_cancelamento
              }}</v-field-value>
            </v-col>
          </v-row>
        </v-container>
      </v-card-text>
    </v-card>
  </v-container>
</template>

<script>
import CotacaoMixin from "@/mixins/CotacaoMixin";

import VFieldValue from "@/components/widgets/VFieldValue";

export default {
  mixins: [CotacaoMixin],
  components: { VFieldValue },
  props: {
    emissao: {
      type: Object,
      default: () => {},
    },
  },
  data: () => ({
    detalhes: false,
  }),
  methods: {},
};
</script>
