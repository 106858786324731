import { get, paginate, filter } from '../modules'

export default {
    getLogs(page, filters=[]){
        const paginatedURL = paginate('/api/logs', page)
        const filteredURL = filter(paginatedURL, filters)

        return get(filteredURL);
    },
    getLog(id){
        return get(`/api/logs/${id}/`);
    }
}
