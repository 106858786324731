export const getAllFields = (formId) => {
  const form = document.getElementById(formId);

  if (form === null || form === undefined) {
    return {};
  }
  //verificação adicionada para criação de contato quando os documentos não forem preenchidos
  const fields = Object.values(form).reduce((obj, field) => {
    obj[field.name] = field.value;
    return obj;
  }, {});
  return fields;
};
