<template>
  <v-data-table
    item-key="id"
    :headers="headers"
    :items="relacoes"
    @click:row="rowClick"
  >
    <template v-slot:item.name="{item}" >
      {{item.nome}} {{item.sobrenome}}
    </template>
  </v-data-table>
</template>

<script>
import api from '@/api/api.js';

export default {
    data() {
        return {
            relacoes: [],
            id: this.$route.params.id,
            headers: [
            {
              text: "Nome",
              value: "name"
            },
          ],
        };
    },
    created() {
        this.initialize();
    },
    methods: {
        initialize() {
          api.getEmpresaRelacoes(this.id).then(response => {
              this.relacoes = response.data;
          });
        },

        rowClick(value) {
            this.$router.push({ name: 'cliente', params: { id: value.id } })
        },
    },
}
</script>