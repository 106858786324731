import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import vuetify from './plugins/vuetify'
import VDevValue from "@/components/widgets/VDevValue";
import "./plugins/vuetify-money.js";
import "./filters"

import Maska from 'maska'
import JSONView from "vue-json-component";
import VMask from 'v-mask'

Vue.use(VMask);
Vue.use(Maska)
Vue.use(JSONView)

Vue.config.productionTip = false
Vue.component('v-dev-value', VDevValue);

new Vue({
    router,
    store,
    vuetify,
    render: h => h(App)
}).$mount('#app')
