<template>
  <div>
    <v-container class="text-center">
      <img src="logo-skyler.png" alt="Logo Skyler" width="300vw" max-width="200px">
      <v-divider></v-divider>

      <v-card width="30rem" class="mx-auto mt-9" flat>
        <v-form>
          <v-card-title>
            <v-spacer />
            <h2>Login</h2>
            <v-spacer />
          </v-card-title>

          <v-card-text>
            <v-text-field
                prepend-inner-icon="mdi-account"
                label="Login"
                autocomplete="email"
                v-model="form.username"
                color="black"
                outlined>
            </v-text-field>
            <v-text-field
                prepend-inner-icon="mdi-lock"
                label="Senha"
                v-model="form.password"
                autocomplete="new-password"
                type="password"
                color="black"
                outlined/>
          </v-card-text>
          <v-card-actions>
            <v-spacer />
            <v-btn :loading="loading" block color="secondary" @click="login">
              Acessar
            </v-btn>
          </v-card-actions>
        </v-form>
      </v-card>
    </v-container>
    <Footer/>
  </div>
</template>

<script>
import Footer from '@/components/App/Footer.vue'

export default {
  name: "Login",
  components: {Footer},
  methods: {
    async login() {
      this.loading = true;
      await this.$store.dispatch("usuario/login", this.form)
      .then(async () => {
        await this.$router.push({name: "otp"})
      })
      .catch(err => {
            this.loading = false;
            this.$root.vtoast.show({message: "Erro ao fazer login", color: "error"});
          }
      )
      this.loading = false;
    }
  },
  data() {
    return {
      loading: false,
      form: {
        username: "",
        password: "",
      }
    }
  }
}
</script>
