<template>
  <v-container fluid>
    <v-row>
      <v-col cols="12">
        <v-card outlined width="100%" rounded elevation="2">
          <v-row class="ma-1">
            <v-col cols="12">
              <v-row>
                <v-col>
                  <v-field-value label="Tipo de serviço">Outro </v-field-value>
                </v-col>
              </v-row>
              <v-row class="ma-2">
                <v-col cols="3">
                  <search-local
                    dense
                    outlined
                    label="Local"
                    v-model="orcamento.orcamento_outro.local"
                    @input="input"
                  ></search-local>
                  <v-checkbox
                    label="Flexibilidade"
                    v-model="orcamento.orcamento_outro.flexibilidade"
                  ></v-checkbox>
                </v-col>
                <v-col cols="12" md="3">
                  <v-text-field
                    dense
                    outlined
                    v-model="orcamento.orcamento_outro.nome_servico"
                    @input="input"
                    placeholder="Insira o nome do serviço"
                    label="Nome do serviço"
                  ></v-text-field>
                </v-col>

                <v-col cols="12" md="3">
                  <form-date-picker-multiple
                    v-model="orcamento.orcamento_outro.data_inicio"
                    outlined
                    label="Data"
                  >
                  </form-date-picker-multiple>
                </v-col>
              </v-row>
              <v-row class="ma-2">
                <v-col cols="9">
                  <v-select
                    :items="TiposServicosOutros"
                    label="Tipo de serviço"
                    item-text="value"
                    item-value="key"
                    v-model="orcamento.orcamento_outro.descricao_servico"
                  ></v-select>
                </v-col>
              </v-row>
              <v-row class="ma-2">
                <v-col cols="9">
                  <v-text-field
                    label="Descrição rápida"
                    v-model="orcamento.descricao"
                    :rules="[
                      (v) =>
                        (v || '').length <= 30 ||
                        'Descrições devem ter menos de 30 caracteres',
                    ]"
                  ></v-text-field>
                </v-col>
              </v-row>
            </v-col>
          </v-row>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import SelectTrechosVoo from "@/components/Orcamento/Widgets/SelectTrechosVoo";
import VFieldValue from "@/components/widgets/VFieldValue";
import FormDatePicker from "@/components/widgets/FormDatePicker";
import FormDatePickerMultiple from "@/components/widgets/FormDatePickerMultiple";
import SearchLocal from "@/components/widgets/SearchLocal";
import { TiposServicosOutros as TIPOS_SERVICOS } from "@/constants/fornecedores";

export default {
  components: {
    SearchLocal,
    FormDatePickerMultiple,
    FormDatePicker,
    VFieldValue,
    SelectTrechosVoo,
  },
  props: {
    value: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      orcamento: null,
      TiposServicosOutros: TIPOS_SERVICOS,
    };
  },
  watch: {
    orcamento: {
      deep: true,
      handler: function (val) {
        this.$emit("input", this.orcamento);
      },
    },
  },
  created() {
    this.orcamento = this.value
      ? { ...this.value }
      : { orcamento_outro: "orcamento_outro" };
    if (!this.orcamento.orcamento_outro)
      this.orcamento.orcamento_outro = {
        nome_servico: "",
        data_inicio: [],
        descricao_servico: "",
        tipo_data: "data_unica",
      };
  },
  methods: {
    input() {
      this.$emit("input", this.orcamento);
    },
  },
};
</script>
