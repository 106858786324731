<template>
  <v-container>
    <v-row>
      <v-col>
        <div
            class="text-h6"
        >
          Dashboards
        </div>
        <v-breadcrumbs :items="breadcumb_items"></v-breadcrumbs>
      </v-col>
    </v-row>
    <v-row>
      <v-container class="pa-4">
        <div class="text-h6">Bem vindo, {{ user|nomeSobrenome }}</div>
      </v-container>
    </v-row>
  </v-container>
</template>


<script>
export default {
  data() {
    return {
      breadcumb_items: [
        {
          text: 'Home',
          to: "home",
          disabled: true
        }
      ],
    }
  },
  computed: {
    user() {
      return this.$store.getters['usuario/loggedUser'];
    }
  },
}
</script>
