<template>
  <v-container fluid v-if="trechos" class="ma-0">
    <v-row fluid class="ma-0">
      <v-container fluid>
        <v-container fluid>
          <v-row>
            <v-col>
              <div v-for="(trecho, index) in trechosFullList" :key="index">
                <v-row v-if="!trecho.removed" class="ma-2" style="border: 1px solid black;border-radius: 25px;">
                  <div class="pa-2 text-subtitle-1">
                    Trecho
                  </div>
                  <voo-edit-form-viagem-trecho v-model="trecho.trecho" @input="input">
                    <template v-slot:actions>
                      <v-btn
                          icon
                          color="primary"
                          @click="removeTrecho(trecho)"
                      >
                        <v-icon>mdi-close</v-icon>
                      </v-btn>
                    </template>

                  </voo-edit-form-viagem-trecho>
                </v-row>
              </div>
            </v-col>
          </v-row>

          <v-row>
            <v-col cols="12" class="text-center">
              <v-btn

                  color="primary"
                  @click="addTrecho"
              >
                <v-icon
                    small
                    color="white">
                  mdi-plus
                </v-icon>
                Adicionar trecho
              </v-btn>
            </v-col>
          </v-row>
        </v-container>
      </v-container>
    </v-row>
  </v-container>
</template>
<script>

import VooEditFormViagemTrecho from "@/components/Voo/VooEditFormViagemTrecho";

export default {
  components: {VooEditFormViagemTrecho},
  props: {
    value: {
      type: Object,
      required: false,
      default: () => {
      }
    },
    disabled: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  data() {
    return {
      form: {},
      trechosFullList: [],
      trechoIdaVolta: {},
      defaultTrecho: {},
    }
  },
  computed: {
    trechos() {
      return this.trechosFullList.filter(trecho => !trecho.removed).map(trecho => trecho.trecho);
    },
  },
  created() {
    this.initialize();
  },
  methods: {
    async initialize() {
      // defaultViagemForm:
      this.form = {
        origem: null,
        destino: null,
        cia_aerea: null,
        tipo_trecho: 'ida_volta',
        classe: ""
      };
      await this.$store.dispatch('global/fetchCiaAereas').catch(err => {
        this.$root.vtoast.show({message: err.message, type: 'error'});
      })
      if (!this.value) {
        return;
      }
      this.form = {
        ...this.form,
        ...this.value,
      };

      this.defaultTrecho = this.value?.trechos ? {...this.value.trechos[0]} : {}

      const originalTrechos = this.value.trechos;
      if (originalTrechos) {
        this.trechosFullList = originalTrechos.map((item) => {
          return {
            trecho: {...item},
            removed: false
          };
        });
      }
      if (this.trechosFullList.length === 0) {
        this.addTrecho();
      }
      this.input(this.form)
    },
    addTrecho() {
      const trecho = {
        trecho: {
          ...this.defaultTrecho,
          ordem: this.trechosFullList.length,
        },
        removed: false
      }
      this.trechosFullList.push(trecho);
      this.input(this.form)
    },
    removeTrecho(trecho) {
      let index = this.trechosFullList.indexOf(trecho)
      this.trechosFullList[index].removed = true;
      this.input(this.form);
    },
    
    input(val) {
      // envia a viagem e apenas os trechos não removidos
      const form = {
        ...val,
        trechos: this.trechos,
      };
      this.$emit('input', form);
      

    }
  }

}
</script>
