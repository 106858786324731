<template>
  <v-container fluid>
    <v-row>
      <v-col cols="12">
        <v-tabs v-model="activeTab">
          <v-tabs-slider color="primary"></v-tabs-slider>
          <v-tab
              v-for="tab in tabs"
              :key="tab.id"
              :to="tab.route"
              exact-path>
            <v-icon v-if="tab.icon" class="pr-2">{{tab.icon}}</v-icon>
            {{ tab.name }}
            <v-badge v-if="tab.badge" class="pl-1 pb-1" color="red"></v-badge>
          </v-tab>
        </v-tabs>
        <v-container fluid class="ma-4" v-if="loading">
          Loading...
        </v-container>
        <router-view v-else/>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import VFieldValue from "@/components/widgets/VFieldValue";
import CopyLinkField from "@/components/widgets/CopyLinkField";

export default {
  components: {
    CopyLinkField,
    VFieldValue
  },
  data() {
    return {
      loading: false,
      activeTab: "",
      tabs: [
        {id: '1', name: 'Minhas Vendas', route: {name: 'usuario-vendas'}, icon:"mdi-cart"},
        {id: '2', name: 'Meus orçamentos', route: {name: 'usuario-orcamentos'}, icon:"mdi-file"},
        {id: '3', name: 'Minhas cotações', route: {name: 'usuario-cotacoes'}, icon: "mdi-file-document"},
        {id: '7', name: 'Minhas emissões', route: {name: 'usuario-emissoes'}, icon: "mdi-printer"},
        {id: '8', name: 'Minhsa atividades', route: {name: 'usuario-atividades'}, icon: "mdi-file-document-outline"},
      ]
    }
  },
  async mounted() {
    // this.loading = true;
    // await this.$store.dispatch("usuario/fetchUsuario", 1)
    // this.loading = false;
  }
}
</script>

