<template>
  <div>
  <ProvedorImageManager
    @close="closeImageManager"
    :dialog.sync="imageManager"
    :provedorId="selectedProvedor"
    v-if="selectedProvedor"
  />

  <v-card>
   <v-card-text>
      <v-data-table
        :headers="headers"
        :items="provedores"
        class="elevation-5"
        :loading="loading"
        :hide-default-footer="true"
      >
        <!-- TEMPLATES TABELA-->
        <template v-slot:item.tipo_servico="{ item }">
          {{ item.tipo_servico | formatTipoServico }}
        </template>

        <template v-slot:item.actions="{ item }">
          <v-icon small class="mr-2" @click="editItem(item)" :disabled="checkUserPermissao"> mdi-pencil </v-icon>
          <v-icon small class="mr-2" @click="openImageManager(item)"> mdi-image </v-icon>
        </template>
        <template v-slot:no-data>
          <v-btn color="primary" @click="$emit('refresh')"> Reset </v-btn>
        </template>

        <template v-slot:item.emissoes="{ item }">
          <v-icon color="primary" class="mr-2" @click="showEmissoes(item.id)">
            mdi-magnify
          </v-icon>
        </template>

        <template v-slot:item.created_by="{ item }">
            {{ item.created_by | nomeSobrenome }}
        </template>
          
        <!-- DIALOGOS -->
        <template v-slot:top>
          <v-toolbar flat>
            <v-toolbar-title>Provedores de Serviços</v-toolbar-title>
            <v-divider class="mx-4" inset vertical></v-divider>
            <v-spacer></v-spacer>
            <!-- EDIÇÃO-->
            <provedores-new-item
              v-model="dialog"
              :item="editedItem"
              :newItem="editedIndex === -1"
              @save="save"
              @close="close"
            />
            <!-- DIALOG DELETE -->
            <v-dialog v-model="dialogDelete" max-width="500px">
              <v-card>
                <v-card-title class="text-h5"
                  >Tem certeza que deseja deletar este item?</v-card-title
                >
                <v-card-text>
                  Digite o nome
                  <span class="bold-text"> {{ nomeProvedor }} </span> para
                  confirmar.
                  <v-text-field label="Nome completo" v-model="inputNomeProvedor" />
                </v-card-text>
                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn text @click="closeDelete">Não</v-btn>
                  <v-btn
                    text
                    @click="deleteItemConfirm"
                    :disabled="nomeProvedor !== inputNomeProvedor"
                    >Sim</v-btn
                  >
                </v-card-actions>
              </v-card>
            </v-dialog>
            <v-dialog v-model="dialogEmissoes" max-width="500px">
              <fornecedores-emissoes-item
                :emissoes="provedorEmissoes"
                :loading="loadingEmissoes"
                @close="dialogEmissoes = false"
              ></fornecedores-emissoes-item>
            </v-dialog>
          </v-toolbar>
          <v-col cols="12" md="4">
            <v-text-field
              dense
              outlined
              clearable
              clear-icon="mdi-close"
              append-icon="mdi-magnify"
              label="Pesquisar"
              v-model="search.provedor"
            />
          </v-col>

        </template>
      </v-data-table>
      <Paginator
          v-model="currentPage"
          :numPages="numPages"
          @pageChange="pageChange"
        />
      </v-card-text>
    </v-card>
  </div>
</template>
<script>
import Paginator from '@/components/widgets/Paginator.vue'
import MASKS from "@/constants/masks";
import { TiposServicos as TIPOS_SERVICOS } from "@/constants/fornecedores";
import MEIOS_DE_PAGAMENTO from "@/constants/meiosPagamento";

import ProvedoresNewItem from "./ProvedoresNewItem";
import FornecedoresEmissoesItem from './FornecedoresEmissoesItem';
import VLinkTextField from "@/components/widgets/VLinkTextField";
import ProvedorImageManager from "@/components/Fornecedores/ProvedorImageManager"

import api from "@/api/api";
import checkPermissao from "@/helpers/checkUserPermissao.js";


import { filterEndereco } from "@/helpers/endereco";
import { formatApiError } from "@/helpers/apiErrors";
import { validateCPF, validateCNPJ } from "@/helpers/identificadores";

export default {
  components: {
    ProvedoresNewItem,
    FornecedoresEmissoesItem,
    VLinkTextField,
    Paginator,
    ProvedorImageManager
  },

  props: {
    value: {
      type: Array,
      default: () => [],
    },
    loading: {
      type: Boolean,
      default: true,
    },
    numPages: {
      type: Number,
      default: 1
    },
  },

  data: () => ({
    TiposServicos: TIPOS_SERVICOS,
    MeiosPagamento: MEIOS_DE_PAGAMENTO,
    masks: MASKS,
    provedorEmissoes: [],
    inputNomeProvedor: "",
    selectedProvedor: null,
    loadingEmissoes: false,
    dialogEmissoes: false,
    imageManager: false,
    dialog: false,
    dialogDelete: false,
    headers: [
      {
        text: "ID",
        align: "start",
        sortable: false,
        value: "id",
      },
      { text: "Nome", value: "nome"},
      { text: "Email", value: "email"},
      { text: "Telefone", value: "telefone"},
      { text: "Tipo de Servico", value: "tipo_servico", align: 'center'},
      { text: "Emissões", value: "emissoes", align: 'center'},
      { text: 'Criado por', value: 'created_by', align: 'center'},
      { text: "Actions", value: "actions", sortable: false, align: 'center'},
    ],
    editedIndex: -1,
    editedItem: {
      nome: "",
      email: "",
      telefone: "",
      tipo_servico: "",
      endereco: {},
    },
    defaultItem: {
      nome: "",
      email: "",
      telefone: "",
      tipo_servico: "",
      endereco: {},
    },
    currentPage: 1,
    search: {
      provedor: ''
    },
    filter: {
      provedor: '',
    },
    filters: [],
    timer: undefined
  }),

  computed: {
    provedores() {
      return this.value;
    },
    nomeProvedor() {
      return this.editedItem.nome;
    },
    user() {
      return this.$store.getters['usuario/loggedUser'];
    },
    checkUserPermissao(){
        const pertencente = this.user.pertencente;
        return checkPermissao(pertencente);
    },
  },

  watch: {
    dialog(val) {
      val || this.close();
    },
    dialogDelete(val) {
      val || this.closeDelete();
    },
    "search.provedor": {
      handler() {
        clearTimeout(this.timer);

        this.timer = setTimeout(() => {
          this.filter.provedor = this.search.provedor;
        }, 1000);
      },
    },
    "filter.provedor": {
      handler() {
        this.filters = [["nome", this.filter.provedor]]
        this.pageChange(this.currentPage)
      },
    }
  },

  methods: {
    validate_data() {
      const data = this.editedItem;
      if (this.editedIndex > -1) return true;
      if (!validateCPF(data.cpf) && !validateCNPJ(data.cnpj)) return false;
      return true;
    },

    editItem(item) {
      this.editedIndex = this.provedores.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.dialog = true;
    },

    openImageManager(item) {
      this.selectedProvedor = item.id
      this.imageManager = true
    },

    deleteItem(item) {
      this.editedIndex = this.provedores.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.dialogDelete = true;
    },

    showEmissoes(provedorID){
      this.loadingEmissoes = true
      api.getProvedorEmissoes(provedorID).then(
        res => {
          this.provedorEmissoes = res.data
          this.loadingEmissoes = false
        }
      ).catch(err => 
        this.$root.vtoast.show({
            message: 'Erro ao carregar emissões associadas.' + err,
            color: 'error',
            icon: 'mdi-close'
        })
      )
      this.dialogEmissoes = true
    },

    async deleteItemConfirm() {
      await api
        .deleteProvedor(this.editedItem.id)
        .then((response) => {
          this.provedores.splice(this.editedIndex, 1);
          this.$root.vtoast.show({
            message: "Provedor deletado com sucesso.",
            icon: "mdi-check",
            color: "success",
          });
        })
        .catch((err) => {
          this.$root.vtoast.show({
            message: `Erro ao deletar provedor: ${err}`,
            icon: "mdi-close",
            color: "error",
          });
        });
      this.closeDelete();
      this.$emit("refresh");
    },

    close() {
      this.dialog = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },

    closeDelete() {
      this.dialogDelete = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },

    closeImageManager() {
      this.imageManager = false
      this.selectedProvedor = null
    },

    async post() {
      if (!this.validate_data()) {
        this.$root.vtoast.show({
          message: "Faltam dados ou dados fornecidos são inválidos.",
          color: "error",
          icon: "mdi-close",
        });
        return;
      }
      await api
        .postProvedor(this.editedItem)
        .then((response) => {
          this.provedores.push(response.data);
          this.$root.vtoast.show({
            message: "Provedor criado com sucesso!",
            icon: "mdi-check",
            color: "success",
          });
          this.close();
          this.$emit("refresh");
        })
        .catch((err) => {
          window.alert(formatApiError(err));
        });
    },

    async put() {
      if (!this.validate_data()) {
        this.$root.vtoast.show({
          message: "Faltam dados obrigatórios",
          color: "error",
          icon: "mdi-close",
        });
        return;
      }
      if (this.editedItem.created_by) this.editedItem.created_by = this.editedItem.created_by.id
      await api
        .putProvedor(this.editedItem.id, this.editedItem)
        .then((response) => {
          this.$root.vtoast.show({
            message: "Dados do provedor alterado com sucesso!",
            icon: "mdi-check",
            color: "success",
          });
          this.close();
          this.$emit("refresh");
        })
        .catch((err) => {
          window.alert(formatApiError(err));
        });
    },

    async save() {
      this.editedItem.endereco = filterEndereco(this.editedItem.endereco);
      if (this.editedIndex > -1) {
        await this.put();
      } else {
        await this.post();
      }
    },
    pageChange(page) {
      this.currentPage = page
      this.$emit('refreshProvedores', {page: page, filters: this.filters})
    },
  },
};
</script>
