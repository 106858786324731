<template>
  <v-card>
    <v-card-title>Cotações Associadas</v-card-title>
    <v-card-text>
      <v-skeleton-loader
        class="mx-auto"
        width="max"
        type="article"
        v-if="loading"
      ></v-skeleton-loader>
      <v-container
        v-else
        v-for="(cotacao, index) in cotacoes"
        :key="index"
        class="my-2"
      >
        <v-row>#{{ index + 1 }}</v-row>
        <v-row>ID da cotação: {{ cotacao.id }}</v-row>
        <v-row>
          <cotacao-hotel
            v-if="cotacao.cotacao_hotel && !loading"
            :cotacao="cotacao.cotacao_hotel"
            :orcamentoLinkTo="orcamentoLinkTo(cotacao)"
          ></cotacao-hotel>
        </v-row>
        <v-row>
          <cotacao-carro
            v-if="cotacao.cotacao_carro && !loading"
            :cotacao="cotacao.cotacao_carro"
            :orcamentoLinkTo="orcamentoLinkTo(cotacao)"
          ></cotacao-carro>
        </v-row>
        <v-row>
          <cotacao-outro
            v-if="cotacao.cotacao_outro && !loading"
            :cotacao="cotacao.cotacao_outro"
            :orcamentoLinkTo="orcamentoLinkTo(cotacao)"
          ></cotacao-outro>
        </v-row>
        <v-row><v-divider></v-divider></v-row>
      </v-container>
    </v-card-text>
    <v-card-actions>
      <v-btn color="blue darken-1" text @click="close"> Fechar </v-btn>
    </v-card-actions>
  </v-card>
</template>
<script>
import { orcamentoLinkTo } from "@/helpers/links";

import CotacaoHotel from "./widgets/CotacaoHotel";
import CotacaoCarro from "./widgets/CotacaoCarro";
import CotacaoOutro from "./widgets/CotacaoOutro";

export default {
  components: {
    CotacaoHotel,
    CotacaoCarro,
    CotacaoOutro,
  },

  props: {
    cotacoes: {
      type: Array,
      default: () => [],
    },
    dialog: {
      type: Boolean,
      default: false,
    },
    loading: {
      type: Boolean,
      default: false,
    },
  },

  methods: {
    close() {
      this.$emit("close");
    },
    orcamentoLinkTo(cotacao) {
      return orcamentoLinkTo(cotacao);
    },
  },
};
</script>
