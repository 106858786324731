<template >
    <notificacoes-list @atualizar="atualizar" @initialize="initialize" :notificacoes="notificacoes" :loading="loading"/>
  </template>
  <script>
  import api from "@/api/api"
  import cotacaoMixin from "@/mixins/CotacaoMixin"
  import NotificacoesList from "@/components/Notificacoes/NotificacoesList.vue"
 
  export default {
    components: {NotificacoesList},
    mixins: [cotacaoMixin],
  
  
    data: () => ({
      notificacoes: null,
      loading: true,
    }),
    computed: {
    },
    async mounted() {
      await this.initialize()
    },
    methods: {
      async initialize(){
        this.loading = true
        api.getNotificacoesDetalhado().then(response => {
          this.notificacoes = response.data.notificacoes
        }).catch(err => {
          console.error(err)
          this.$root.vtoast.show({
                      message: 'Notificações não encontradas',
                      color: 'error',
                      icon: 'mdi-close'
                  })
        }).finally(() => this.loading = false)

        this.$store.dispatch('usuario/fetchUsuarios')
      },
      async atualizar(page=1, filters=[]) {
        this.loading = true
        api.getNotificacoesDetalhado(page, filters).then((res) => {
          this.notificacoes = res.data.notificacoes
          this.loading = false;
        })
      }
    }
  }
  </script>