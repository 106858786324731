const defaultVenda = {
    id: "",
    status: '',
    cliente: null,
    meio_contato_tipo: null,
    meio_contato_valor: null,
    origem: {
        name: ""
    },
    destino: {
        name: ""
    },
    data_ida: '',
    data_volta: "",
    indicacao:
        {
            name: ""
        },
    consultor: {
        name: ""
    },
    anotacoes: ""
}

export { defaultVenda }
