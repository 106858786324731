<template>
  <v-container fluid>
    <v-row class="py-4">
      Informações da busca
    </v-row>
    <v-row>

      <v-datetime-text-field mostra-hora-minuto v-model="form" @input="input" :readonly="true"/>
    </v-row>
  </v-container>
</template>

<script>
import VDatetimeTextField from "@/components/widgets/VDatetimeTextField";

export default {
  components: {VDatetimeTextField},
  props: {
    value: {
      type: [Date, String],
      required: false,
      default: () => {
        return new Date();
      }
    }
  },
  data() {
    return {
      form: null
    }
  },
  created() {
    this.form = this.value;

  },
  methods: {
    input(event) {
      this.$emit("input", this.form);
    }
  }
}
</script>
