<template >
   <div>
    <v-card :loading="loading">
       <json-view :data="busca" :styles="{ key: 'yellow' }" />
    </v-card>
   </div>
  </template>
  <script>
  import api from "@/api/api"
  import {formatApiError} from "@/helpers/apiErrors"
  import { JSONView } from "vue-json-component";
  export default {
    components: {JSONView},
    data: () => ({
      busca: null,
      loading: true,

    }),
    computed: {
      buscaId() {
        return this.$route.params.id
      }
    },
    async mounted() {
      await this.initialize()
    },
    methods: {
      async initialize(){
        this.loading = true
        api.getBusca(this.buscaId).then(res => {
          this.busca = JSON.parse(res.data.busca)
          this.loading=false
        })
        .catch(e => {
          window.alert(formatApiError(e))
        })
      },
    }
  }
  </script>
<style>
.json-view-item {
    border-bottom:1px solid rgba(66, 66, 66, 0.13);
}

.value-key {
  padding-left: 20px;
}
</style>