<template>
  <v-container fluid v-if="cotacoes">
    <v-data-table
      :loading="loading"
      item-key="id"
      :headers="headers"
      :items="cotacoes"
      v-model="selectedCotacoes"
      show-select
      class="elevation-1"
    >
      <!-- TEMPLATES TABELA -->
      <template v-slot:item.id="{ item }">
        {{ item.id }}
      </template>
      <template v-slot:item.data_hora_busca="{ item }">
        {{ item.data_hora_cotacao | convertDataBR }} às
        {{ item.data_hora_cotacao | dataHoraMinuto }}
      </template>
      <template v-slot:item.cotado_por="{ item }">
        {{ item.cotado_por |nomeSobrenome }}
      </template>

      <template v-slot:item.descricao_cotacao="{ item }">
        {{ descricaoCotacao(item) }}
      </template>
      <template v-slot:item.descricao="{ item }">
        {{ item.descricao }}
      </template>
      <template v-slot:item.tipo_cotacao="{ item }">
        {{
          item.cotacao_voo
            ? item.cotacao_voo.subcotacoes[0].tipo_cotacao || "Não especificado"
            : ""
        }}
      </template>
      <template v-slot:item.valor="{ item }">
        {{ cotacaoCustoTotal(item) | dinheiroReal }}
      </template>
      <template v-slot:item.valor_ajuste="{ item }">
        {{
          (item.ajuste_ativado ? cotacaoValorAjuste(item) : 0) | dinheiroReal
        }}
      </template>
      <template v-slot:item.valor_final="{ item }">
        {{ cotacaoValorTotal(item) | dinheiroReal }}
      </template>
      <template v-slot:item.actions="{ item }">
        <v-btn x-small class="mr-2" @click="viewCotacaoItem(item)" icon>
          <v-icon color="primary">mdi-eye </v-icon>
        </v-btn>
        <v-btn x-small class="mr-2" v-if="orcamento.status!=='fechado'" @click="copyCotacaoItem(item)" icon>
          <v-icon color="primary">mdi-content-copy </v-icon>
        </v-btn>
        <v-btn x-small class="mr-2" v-if="item.status!=='fatura' && orcamento.status!=='fechado'" @click="deleteCotacaoItem(item)" icon>
          <v-icon small color="red"> mdi-archive </v-icon>
        </v-btn>
      </template>
    </v-data-table>

    <!--    Dialogs  -->

    <!--    Delete dialog -->
    <v-dialog
      v-model="dialogDeleteCotacao"
      v-if="dialogDeleteCotacao"
      max-width="600px"
    >
      <v-card>
        <v-card-title class="text-h5"
          >Tem certeza que deseja arquivar essa cotação?</v-card-title
        >
        <v-card-text>
          <p>id: {{ edittingItem.id }}</p>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn text @click="closeDeleteItem">Cancelar</v-btn>
          <v-btn color="error" @click="deleteItemConfirm">Sim</v-btn>
          <v-spacer></v-spacer>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!--    Edit dialog  -->
    <v-dialog v-model="dialogEditCotacao" v-if="dialogEditCotacao">
      <cotacao-edit-item
        :orcamento="orcamento"
        v-model="edittingItem"
        :allowSave="allowSave"
        @close="closeEditItem"
        @save="cotacaoSaved"
        :isVisualizacao="isVisualizacao"
      ></cotacao-edit-item>
    </v-dialog>
  </v-container>
</template>

<script>
import CotacaoEditItem from "@/components/cotacao/CotacaoEditItem";
import CotacaoMixin from "@/mixins/CotacaoMixin";

export default {
  components: { CotacaoEditItem },
  mixins: [CotacaoMixin],
  props: {
    cotacoes: {
      type: Array,
      default: () => [],
    },
    orcamento: {
      type: Object,
      default: () => {},
    },
    carrinho: {
      type: Array,
      default: () => [],
    },
    loading: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      selectedCotacoes: [],
      dialogDeleteCotacao: false,
      dialogEditCotacao: false,
      allowSave: true,
      edittingItem: {},
      headers: [
        {
          text: "ID",
          value: "id",
        },
        {
          text: "Data da busca",
          value: "data_hora_busca",
        },
        {
          text: "Cotado por",
          value: "cotado_por"
        },
        {
          text: "Descrição",
          value: "descricao_cotacao",
        },
        {
          text: "Descrição Rápida",
          value: "descricao"
        },
        {
          text: "Tipo",
          value: "tipo_cotacao",
        },
        {
          text: "Custo total",
          value: "valor",
        },
        {
          text: "Ajuste",
          value: "valor_ajuste",
        },
        {
          text: "Valor Final",
          value: "valor_final",
        },
        {
          text: "Ações",
          value: "actions",
        },
      ],
      isVisualizacao: false,
    };
  },
  watch: {
    selectedCotacoes(val) {
      this.$emit("setSelectedCotacoes", val);
    },
  },
  methods: { 
    // ! Funções de edição de cotação não usadas
    // editCotacaoItem(cotacao) {
    //   this.edittingItem = cotacao;
    //   this.dialogEditCotacao = true;
    // },
    closeEditItem() {
      this.dialogEditCotacao = false;
      this.edittingItem = null;
    },
    
    deleteCotacaoItem(cotacao) {
      this.edittingItem = cotacao;
      this.dialogDeleteCotacao = true;
    },
    closeDeleteItem() {
      this.dialogDeleteCotacao = false;
      this.edittingItem = null;
    },
    copyCotacaoItem(cotacao) {
      this.allowSave = true;
      const now = new Date()
      this.edittingItem = {
        venda: cotacao.venda?.id,
        orcamento: cotacao.orcamento,
        cotacao_carro: this.removeIds(cotacao.cotacao_carro),
        cotacao_hotel: this.removeIds(cotacao.cotacao_hotel),
        cotacao_outro: this.removeIds(cotacao.cotacao_outro),
        cotacao_voo: this.removeIds(cotacao.cotacao_voo),
        data_busca: now.toISOString().substring(0, 10),
        hora_busca: now.toTimeString().substring(0, 5),
      }
      this.dialogEditCotacao = true;
    },
    removeIds(cotacao){
      if(!cotacao) return null
      const data = {
        ...cotacao,
        id: null,
        cotacao: null
      }
      delete data.id
      delete data.cotacao
      return data
    },
    viewCotacaoItem(cotacao) {
      this.allowSave = false;
      this.edittingItem = cotacao,
      this.isVisualizacao = true;
      this.dialogEditCotacao = true;
    },
    async deleteItemConfirm() {
      const res = await this.deleteCotacao(this.edittingItem);

      if (res && res.success) {
        const oldCotacaoIdx = this.cotacoes.findIndex(
          (item) => item.id === this.edittingItem.id
        );
        if (oldCotacaoIdx !== -1) {
          this.cotacoes.splice(oldCotacaoIdx, 1);
        }
      }
      this.dialogDeleteCotacao = false;
      this.edittingItem = null;
    },

    cotacaoSaved(cotacao) {
      this.dialogEditCotacao = false;
      this.edittingItem = null;
      const oldCotacaoIdx = this.cotacoes.findIndex(
        (item) => item.id === cotacao.id
      );
      if (oldCotacaoIdx !== -1) {
        this.cotacoes.splice(oldCotacaoIdx, 1, cotacao);
      }
      else{
        this.$emit('save', cotacao)
      }
    },
  },
};
</script>
