<template>
  <v-container class="ma-4" fluid> <h1>Deu certo :-)</h1> </v-container>
</template>
 
 <script>
export default {
  name: "success-maxipago",
  data() {
    return {};
  },
};
</script>
 