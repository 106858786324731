<template>
  <v-container fluid>
    <v-row>
      <v-col>
        <table-cotacoes-pendencias
          :items="servicos"
          :loading="loading"
          :contasFidelidade="contasFidelidade"
          @select="select"
          @cancel="cancel"
        />
      </v-col>
    </v-row>
    <v-spacer></v-spacer>
    <v-row>
      <v-col>
        <table-cotacoes-validadas
          :items="cotacoesMilhasValidadas"
          :loading="validadasLoading"
          :contasFidelidade="contasFidelidade"
          :numPages="numPages"
          @pageUpdate = "pageUpdate"
        />
      </v-col>
    </v-row>
    <v-dialog v-model="dialog" scrollable>
      <v-card>
        <sub-cotacao-list
          :servico="selectedServico"
          :contasFidelidade="contasFidelidade"
          :programasFidelidade="programasFidelidade"
          @refresh="refresh"
        ></sub-cotacao-list>
        <v-card-actions>
          <v-btn color="blue darken-1" text @click="dialog = false">
            Fechar
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>
<script>
import OrcamentoMixin from "@/mixins/OrcamentoMixin";

import api from "@/api/api.js";

import SubCotacaoList from "./TableSubCotacoesAutorizacao";
import TableCotacoesValidadas from "./TableCotacoesValidadas";
import TableCotacoesPendencias from "./TableCotacoesPendencias";

export default {
  components: {
    SubCotacaoList,
    TableCotacoesValidadas,
    TableCotacoesPendencias,
  },
  mixins: [OrcamentoMixin],
  props: {
    servicos: {
      type: Array,
      required: false,
      default: () => [],
    },
    loading: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  data() {
    return {
      dialog: false,
      selectedServico: null,
      search: "",
      contasFidelidade: [],
      programasFidelidade: [],
      cotacoesMilhasValidadas: [],
      validadasLoading: false,
      numPages: 1,
      filters: {}
    };
  },

  mounted() {
    this.$store.dispatch("contato/fetchContatos");
    this.$store.dispatch("global/fetchProvedores");
    this.validadasLoading = true;
    this.initialize();
  },

  methods: {
    select(id) {
      this.selectedServico = this.servicos.find(
        (servico) => servico.id === id
      );
      this.dialog = true;
    },

    cancel(id, justificativaCancelamento) {
      api.cancelarServico(id, {motivo_cancelamento: justificativaCancelamento}).then(
        res => {
          this.$root.vtoast.show({
            message: "Servico cancelado com sucesso.",
            icon: "mdi-check",
            color: "success",
          })
          this.$emit('refresh')
        }
      ).catch(
        err => {
          console.error(err)
          this.$root.vtoast.show({
            message: "Servico não pode ser cancelado no momento",
            icon: "mdi-error",
            color: "error",
          })
        }
      )
    },
    refresh() {
      this.$emit('refresh');
      setTimeout(() => {
        this.validadasLoading = true;
        api.getCotacoesMilhasValidadas().then((res) => {
          this.cotacoesMilhasValidadas = res.data['milhas-validadas'];
          this.validadasLoading = false;
          this.numPages = res.data.pages;
        });
      }, 1000);
    },
    pageUpdate(page=1, filters=[]){
      this.validadasLoading = true;
      api.getCotacoesMilhasValidadas(page, filters).then((res) => {
        this.cotacoesMilhasValidadas = res.data['milhas-validadas'];
        this.validadasLoading = false;
        this.numPages = res.data.pages;
      });
    },
    initialize(){
      api
        .getProgramas()
        .then((e) => {
          this.programasFidelidade = e.data;
        })
        .catch((err) =>
          this.$root.vtoast.show({
            message: "Programas de Fidelidade não encontrados",
            color: "error",
          })
        );
      api
        .getFidelidades()
        .then((e) => {
          this.contasFidelidade = e.data;
        })
        .catch((err) =>
          this.$root.vtoast.show({
            message: "Contas de Fidelidade não encontradas",
            color: "error",
          })
        );
        this.pageUpdate(1)
      },
  },
};
</script>
