<template>
    <v-card rounded="10" raised elevation="10">
      <v-row>
        <v-col>
          <v-data-table
            @click:row="rowClick"
            :headers="headers"
            :items="emissoes"
            sort-by="id"
            sort-desc
            :hide-default-footer="true"
            :loading="loadingTable"
          >
            <template v-slot:top>
              <v-container class="ma-4 pr-8" fluid>
                <v-row>
                  <v-col cols="12" md="2">
                    <v-autocomplete
                      clear-icon="mdi-close"
                      clearable
                      outlined
                      v-model="filter.consultor"
                      label="Consultor"
                      :item-text="(item) => `${item.nome} ${item.sobrenome}`"
                      :item-value="(item) => item.id"
                      :items="users"
                      dense
                    >
                    </v-autocomplete>
                  </v-col>
                  <v-col cols="12" md="2">
                    <v-autocomplete
                      clear-icon="mdi-close"
                      clearable
                      outlined
                      v-model="filter.participante"
                      label="Participante"
                      :item-text="(item) => `${item.nome} ${item.sobrenome}`"
                      :item-value="(item) => item.id"
                      :items="users"
                      dense
                    >
                    </v-autocomplete>
                  </v-col>
  
                  <!-- <v-col cols="12" md="2">
                    <v-select
                      clear-icon="mdi-close"
                      clearable
                      outlined
                      v-model="filter.status"
                      label="Status"
                      :items="statusEmissao"
                      item-text="value"
                      item-value="key"
                      dense
                    >
                    </v-select>
                  </v-col> -->
                  <v-col cols="12" md="2">
                    <v-select
                      clear-icon="mdi-close"
                      clearable
                      outlined
                      v-model="filter.tipo"
                      label="Tipo"
                      :items="tipoBilhete"
                      item-text="value"
                      item-value="key"
                      dense
                    >
                    </v-select>
                  </v-col>
                  <v-col cols="12" md="2">
                    <FormDatePickerMultiple
                      outlined
                      label="Data de Criação"
                      v-model="search.data_criacao"
                    />
                  </v-col>
                  <v-col cols="12" md="2">
                    <FormDatePickerMultiple
                      outlined
                      label="Data de Conclusão"
                      v-model="search.data_conclusao"
                    />
                  </v-col>
  
                  <v-col cols="12" md="2">
                    <v-text-field
                      dense
                      outlined
                      clearable
                      clear-icon="mdi-close"
                      append-icon="mdi-magnify"
                      label="ID da Venda"
                      v-model="search.id_venda"
                    />
                  </v-col>
                  <v-col cols="12" md="2">
                    <v-text-field
                      dense
                      outlined
                      clearable
                      clear-icon="mdi-close"
                      append-icon="mdi-magnify"
                      label="ID do Serviço"
                      v-model="search.id_servico"
                    />
                  </v-col>
                  <v-col cols="12" md="2">
                    <v-text-field
                      dense
                      outlined
                      clearable
                      clear-icon="mdi-close"
                      append-icon="mdi-magnify"
                      label="Localizador"
                      v-model="search.localizador"
                    />
                  </v-col>
                  <v-col cols="12" md="2">
                    <v-text-field
                      dense
                      outlined
                      clearable
                      clear-icon="mdi-close"
                      append-icon="mdi-magnify"
                      label="Emissor"
                      v-model="search.emissor"
                    />
                  </v-col>
                  <v-col cols="12" md="2">
                    <v-text-field
                      dense
                      outlined
                      clearable
                      clear-icon="mdi-close"
                      append-icon="mdi-magnify"
                      label="ID da Emissão"
                      v-model="search.id_emissao"
                    />
                  </v-col>
                </v-row>
              </v-container>
            </template>
  
            <!-- <template v-slot:item.venda_consultor="{ item, value }">
              {{ value ? `${value.nome} ${value.sobrenome}` : "" }}
            </template> -->

            <!-- <template v-slot:item.servico_cotacao_cotado_por="{ item, value }">
              {{ value ? `${value.nome} ${value.sobrenome}` : "" }}
            </template> -->

            <template v-slot:item.cotado_por="{ item }">
              <!-- <span v-if="!item.servico.cotacao.cotador_por"> --- </span>
              <span v-else>
                {{ item?.servico?.cotacao?.cotado_por }}
              </span> -->
              <!-- {{ item?.servico?.cotacao?.cotado_por }} -->
              {{ findCotador(item?.servico?.cotacao?.cotado_por) | nomeSobrenome }}
            </template>
  
            <template v-slot:item.status="{ value }">
              <StatusEmissao :status="value" />
            </template>

            <template v-if="loggedUser.pertencente.includes('comissao')" v-slot:item.valor_base="{ item }">
              {{ item.valor_base | dinheiroReal }}
            </template>
    
            <template v-if="loggedUser.pertencente.includes('comissao')" v-slot:item.porcentagem_consultor="{ item }">
              {{ item.porcentagem_consultor | dinheiroReal }}
            </template>

            <template v-if="loggedUser.pertencente.includes('comissao')" v-slot:item.porcentagem_cotador="{ item }">
              {{ item.porcentagem_cotador | dinheiroReal }}
            </template>

            <template v-if="loggedUser.pertencente.includes('comissao')" v-slot:item.porcentagem_emissor="{ item }">
              {{ item.porcentagem_emissor | dinheiroReal }}
            </template>

            <template v-slot:item.servico_status="{ item }">
              {{ item.servico.status | formatStatusServico }}
            </template>
  
            <template v-slot:item.venda="{ item }">
              <v-link-text-field
                :to="{ name: 'venda-info', params: { id: item.venda?.id } }"
              >
                #{{ item.venda?.id | formatVendaId }}
              </v-link-text-field>
            </template>
  
            <template v-slot:item.cotacao="{ item }">
              <v-link-text-field
                :to="{
                  name: 'orcamento',
                  params: { id: item.servico.cotacao.orcamento.id },
                }"
              >
                #{{ item.servico.cotacao.id | formatId }}
              </v-link-text-field>
            </template>
  
            <template v-slot:item.orcamento="{ item }">
              <v-link-text-field
                :to="{
                  name: 'orcamento',
                  params: { id: item.servico.cotacao.orcamento.id },
                }"
              >
                #{{ item.servico.cotacao.orcamento.id | formatId }}
              </v-link-text-field>
            </template>
  
            <template v-slot:item.servico_id="{ item }">
              Serv:{{ item.servico.id }}
            </template>

            <template v-slot:item.venda_consultor="{ item, value }">
              {{item?.venda?.consultor | nomeSobrenome}}
            </template>
  
            <template v-slot:item.cliente="{ item }">
              {{ item?.venda?.cliente | nomeSobrenome }}
              {{ item?.venda?.cliente_empresa?.nome }}
            </template>
  
            <template v-slot:item.actions="{ item }">
              <v-btn
                icon
                color="transparent"
                :to="{ name: 'emissao', params: { id: item.id } }"
              >
                <v-icon small color="primary" class="mr-2" @click="item"
                  >mdi-open-in-new
                </v-icon>
              </v-btn>
            </template>
  
            <template v-slot:item.data_hora_emissao="{ item }">
              <div v-if="!item.data_hora_emissao && !item.cancelada_em">---</div>
              <div v-else-if="!item.data_hora_emissao">
                {{ item.cancelada_em | dataHoraBR }}
              </div>
              <div v-else>
                {{ item.data_hora_emissao | dataHoraBR }}
              </div>
            </template>
  
            <template v-slot:item.created_at="{ item }">
              <div v-if="!item.created_at">---</div>
              <div v-else>
                {{ item.created_at | dataHoraBR }}
              </div>
            </template>
  
            <template v-slot:item.emitido_por="{ item }">
              <span v-if="!item.emitido_por"> --- </span>
              <span v-else>
                {{ item?.emitido_por | nomeSobrenome }}
              </span>
            </template>
            <template v-slot:item.tempo="{ item }">
              <div
                v-if="
                  item.status != 'cancelada' &&
                  item.status != 'finalizada' &&
                  item.created_at != null
                "
              >
                <div v-if="verificarTempo(item.created_at)">
                  <v-icon small color="red"> mdi-emoticon-angry </v-icon>
                  {{ item.created_at | horasAtras }}
                </div>
                <div v-else>
                  <v-icon small> mdi-clock </v-icon>
                  {{ item.created_at | horasAtras }}
                </div>
              </div>
              <div v-else-if="item.status == 'cancelada'">
                <v-icon small color="red"> mdi-emoticon-angry </v-icon>
                {{ item.created_at | horasAtras(item.cancelada_em) }}
              </div>
              <div v-else>
                <v-icon color="green" small> mdi-emoticon-happy </v-icon>
                {{ item.created_at | horasAtras(item.data_hora_emissao) }}
              </div>
            </template>
            <template v-slot:item.servico="{ item }">
              <span v-if="item.subcotacao_voo">
                {{
                  item.subcotacao_voo?.viagem?.trechos[0]?.pernas[0]
                    ?.data_hora_partida | convertDataBR
                }}
              </span>
            </template>
  
            <template v-slot:item.tipo="{ item }">
              <div v-if="item.tipo === 'emissao_voo_pagante'">
                <v-icon small color="green"> mdi-cash </v-icon>
                Pagante
              </div>
              <div v-if="item.tipo === 'emissao_voo_milha'">
                <v-icon small color="yellow darken-2"> mdi-airplane </v-icon>
                Milha
              </div>
              <div v-if="item.tipo === 'emissao_voo_wallet'">
                <v-icon small color="yellow darken-2"> mdi-wallet</v-icon>
                Wallet
              </div>
              <div v-if="item.tipo === 'emissao_hotel'">
                <v-icon small color="yellow darken-2"> mdi-home</v-icon>
                Hotel
              </div>
              <div v-if="item.tipo === 'emissao_carro'">
                <v-icon small color="yellow darken-2"> mdi-car</v-icon>
                Carro
              </div>
              <div v-if="item.tipo === 'emissao_outro'">
                <v-icon small color="yellow darken-2"> mdi-help-circle</v-icon>
                Outro
              </div>
            </template>
  
            <template v-slot:no-data> Nenhuma emissão encontrada </template>
          </v-data-table>
          <v-pagination
            v-model="currentPage"
            :length="numPages"
            :total-visible="7"
          ></v-pagination>
        </v-col>
      </v-row>
    </v-card>
  </template>
  <script>
  import SolicitarOrcamento from "@/components/widgets/SolicitarOrcamento";
  import StatusEmissao from "@/components/widgets/StatusEmissao";
  import VLinkTextField from "@/components/widgets/VLinkTextField";
  import api from "@/api/api.js";
  import CotacaoMixin from "@/mixins/CotacaoMixin";

  import FormDatePicker from "../widgets/FormDatePicker";
  import FormDatePickerMultiple from "@/components/widgets/FormDatePickerMultiple";
  
  import STATUS_EMISSAO from "@/constants/statusEmissao";
  import TIPO_BILHETE from "@/constants/tipoBilhete";
  
  import DateSkyler from "@/helpers/Date/date";
  
  export default {
    mixins: [CotacaoMixin],

    components: {
      StatusEmissao,
      SolicitarOrcamento,
      VLinkTextField,
      FormDatePickerMultiple,
    },
    data() {
      return {
        statusEmissao: STATUS_EMISSAO,
        tipoBilhete: TIPO_BILHETE,
        filter: {
          participante:"",
          consultor: "",
          status: "",
          tipo: "",
          localizador: "",
          emissor: "",
          data_criacao: [],
          data_conclusao: [],
          id_venda: "",
          id_servico: "",
          id_emissao: "",
        },
        loadingTable: false,
        pressingCtrl: false,
        headers: [
          {
            text: "ID Emissão",
            align: "start",
            sortable: true,
            value: "id",
          },
          {
            text: "Venda",
            value: "venda",
          },
          {
            text: "Valor Base",
            value: "valor_base"
          },
          {
            text: "Consultor",
            value: "venda_consultor"
          },
          {
            text: "% Consultor",
            value: "porcentagem_consultor"
          },
          {
            text: "Cotador",
            value: "cotado_por"
          },
          {
            text: "% Cotador",
            value: "porcentagem_cotador"
          },
          {
            text: "Emissor",
            value: "emitido_por",
          },
          {
            text: "% Emissor",
            value: "porcentagem_emissor"
          },
          // {
          //   text: "Orçamento",
          //   value: "orcamento",
          // },
          // {
          //   text: "Cotacao",
          //   value: "cotacao",
          // },
          // {
          //   text: "Serviço",
          //   value: "servico_id",
          // },
          {
            text: "Cliente",
            value: "cliente",
          },
          // {
          //   text: "Status",
          //   value: "status",
          // },
          // {
          //   text: "Status Servico",
          //   value: "servico_status",
          // },
          {
            text: "Data de Conclusão",
            value: "data_hora_emissao",
          },
          {
            text: "Data de Criação",
            value: "created_at",
          },
          // {
          //   text: "Tempo",
          //   value: "tempo",
          // },
          {
            text: "Tipo",
            value: "tipo",
          },
          // {
          //   text: "Partida",
          //   value: "servico",
          // },
        ],
  
        emissoes: [],
        currentPage: 1,
        numPages: 1,
        timer: undefined,
        search: {
          localizador: "",
          emissor: "",
        },
      };
    },
  
    mounted() {
      this.initialize();
    },
  
    computed: {
      // valorBase(){
      //   if(this.loggedUser.pertencente.includes('comissao')){
      //     return 'valor_base'
      //   }
      //   return ''
      // },

      loggedUser() {
      return this.$store.getters["usuario/loggedUser"];
    },

      users() {
        return this.$store.getters["usuario/usuarios"];
      },
    },
  
    watch: {
      currentPage: {
        handler() {
          this.changePage(this.currentPage);
        },
      },
      "search.localizador": {
        handler() {
          clearTimeout(this.timer);
  
          this.timer = setTimeout(() => {
            this.filter.localizador = this.search.localizador;
          }, 1000);
        },
      },
      "search.data_criacao": {
       handler() {
         clearTimeout(this.timer);
         this.timer = setTimeout(() => {
           this.filter.data_criacao = this.search.data_criacao;
         }, 3000);
       },
      },
      "search.data_conclusao": {
       handler() {
         clearTimeout(this.timer);
         this.timer = setTimeout(() => {
           this.filter.data_conclusao = this.search.data_conclusao;
         }, 3000);
       },
      },
      "search.id_venda": {
        handler() {
          clearTimeout(this.timer);
  
          this.timer = setTimeout(() => {
            this.filter.id_venda = this.search.id_venda;
          }, 1000);
        },
      },
      "search.id_servico": {
        handler() {
          clearTimeout(this.timer);
  
          this.timer = setTimeout(() => {
            this.filter.id_servico = this.search.id_servico;
          }, 1000);
        },
      },
      "search.emissor": {
        handler() {
          clearTimeout(this.timer);
  
          this.timer = setTimeout(() => {
            this.filter.emissor = this.search.emissor;
            
          }, 1000);
        },
      },
      "search.id_emissao": {
        handler() {
          clearTimeout(this.timer);
  
          this.timer = setTimeout(() => {
            this.filter.id_emissao = this.search.id_emissao;
          }, 1000);
        },
      },
      filter: {
        deep: true,
        handler() {
          this.changePage(1);
        },
      },
    },
  
    methods: {

      findCotador(id){
        const users = Array.from(this.users)
        return users.filter(user => user.id === id)[0]
      },

      changePressingCtrlState(e) {
        if (e.key != "Control") return;
        this.pressingCtrl = e.type == "keydown" ? true : false;
      },
  
      changePage(page = 1) {
        const filterProperties = [
          "participante",
          "consultor",
          "status",
          "localizador",
          "tipo",
          "data_criacao",
          "data_conclusao",
          "id_venda",
          "id_servico",
          "emissor",
          "id_emissao",
        ];
        let filters = [];
  
        filterProperties.forEach((property) => {
          let filterValue = this.filter[property];
  
          if (filterValue) {
            if (property == "data_criacao")
              filterValue = filterValue.map((date) =>
                DateSkyler.dateToString(date)
              );
  
            filters.push([property, filterValue]);
          }
          if (filterValue) {
            if (property == "data_conclusao")
              filterValue = filterValue.map((date) =>
                DateSkyler.dateToString(date)
              );
  
            filters.push([property, filterValue]);
          }
        });
  
        page = this.currentPage;
        this.loadingTable = true;
  
        api.getEmissoesComissao(this.loggedUser,page,filters).then((res) => {
          this.emissoes = res.data.emissoes;
          this.emissoes.forEach((emissao) => {
            emissao.valor_base = this.calculoValorBase(emissao.id,emissao.servico,emissao.pagamentos_emissao).toFixed(2);
            emissao.porcentagem_consultor = (0.03 * emissao.valor_base).toFixed(2);
            emissao.porcentagem_cotador = (0.025 * emissao.valor_base).toFixed(2);
            emissao.porcentagem_emissor = (0.015 * emissao.valor_base).toFixed(2);
          })
          this.numPages = res.data.pages;
          this.loadingTable = false;
        });
      },
      
      decidirHeaders(){
        if(!this.loggedUser.pertencente.includes('comissao')){
          this.headers= [
          {
            text: "ID Emissão",
            align: "start",
            sortable: true,
            value: "id",
          },
          {
            text: "Venda",
            value: "venda",
          },
          // {
          //   text: "Valor Base",
          //   value: "valor_base"
          // },
          // {
          //   text: "% Consultor",
          //   value: "porcentagem_consultor"
          // },
          // {
          //   text: "% Cotador",
          //   value: "porcentagem_cotador"
          // },
          // {
          //   text: "% Emissor",
          //   value: "porcentagem_emissor"
          // },

          // {
          //   text: "Orçamento",
          //   value: "orcamento",
          // },
          // {
          //   text: "Cotacao",
          //   value: "cotacao",
          // },
          // {
          //   text: "Serviço",
          //   value: "servico_id",
          // },
          {
            text: "Emissor",
            value: "emitido_por",
          },
          {
            text: "Cotador",
            value: "cotado_por"
          },
          {
            text: "Consultor",
            value: "venda_consultor"
          },
          {
            text: "Cliente",
            value: "cliente",
          },
          // {
          //   text: "Status",
          //   value: "status",
          // },
          // {
          //   text: "Status Servico",
          //   value: "servico_status",
          // },
          {
            text: "Data de Conclusão",
            value: "data_hora_emissao",
          },
          {
            text: "Data de Criação",
            value: "created_at",
          },
          // {
          //   text: "Tempo",
          //   value: "tempo",
          // },
          {
            text: "Tipo",
            value: "tipo",
          },
          // {
          //   text: "Partida",
          //   value: "servico",
          // },
        ]}
      },

      initialize() {
        // this.decidirHeaders();
        this.loadingTable = true;
        api.getEmissoesComissao(this.loggedUser).then((data) => {
          this.emissoes = data.data.emissoes;
          this.emissoes.forEach((emissao) => {
            emissao.valor_base = this.calculoValorBase(emissao.id,emissao.servico,emissao.pagamentos_emissao).toFixed(2);
            emissao.porcentagem_consultor = (0.03 * emissao.valor_base).toFixed(2);
            emissao.porcentagem_cotador = (0.025 * emissao.valor_base).toFixed(2);
            emissao.porcentagem_emissor = (0.015 * emissao.valor_base).toFixed(2);
          })
          this.numPages = data.data.pages;
          this.loadingTable = false;
        });
  
        this.$store.dispatch("usuario/fetchUsuarios");
  
        document.onkeydown = (e) => this.changePressingCtrlState(e);
        document.onkeyup = (e) => this.changePressingCtrlState(e);
      },
  
      getEmissor(emissorId) {
        const usuarios = this.$store.state.usuario.usuarios;
        if (!usuarios) return {};
  
        return usuarios.find((usuario) => usuario.id === emissorId);
      },
  
      rowClick(value) {
        if (this.pressingCtrl)
          return window.open("/emissoes/" + value.id, "_blank");
        this.$router.push({ name: "emissao", params: { id: value.id } });
      },
  
      verificarTempo(data) {
        const dataConvertida = this.$options.filters.horasAtras(data);
  
        const time = parseInt(dataConvertida.split(" ")[0]);
        const unit = dataConvertida.split(" ")[1];
  
        if (time > 48 && unit == "horas") {
          return true;
        }
      },
  
      cotacaoID(emissao) {
        const servico = emissao.servico;
        if (!servico) return "";
        const cotacao = servico.cotacao;
        if (!cotacao) return "";
        return cotacao.id;
      },
  
      orcamentoID(emissao) {
        const servico = emissao.servico;
        if (!servico) return "";
        const cotacao = servico.cotacao;
        if (!cotacao) return "";
        const orcamento = cotacao.orcamento;
        if (!orcamento) return "";
        return orcamento.id;
      },
    },
  };
  </script>
  
  