<template>
  <div v-if="formCotacao">
    <v-card>
      <v-card-title>
        <span class="text-h5">{{
          formCotacao.id
            ? `Editando cotação # ${formCotacao.id}`
            : "Nova Cotação"
        }}</span>
      </v-card-title>
      <v-card-text>
        <v-container fluid class="ma-0">
          <v-row>
            <cotacao-edit-form-orcamento-vinculado
              :orcamento="orcamento"
              :cotacao="formCotacao"
              :item="item"
            />
          </v-row>
          <v-row class="py-2">
            <v-divider />
          </v-row>
          <v-row>
            <cotacao-edit-form-secao-cotacao
              :orcamento="orcamento"
              :value="formCotacao"
              v-model="formCotacao"
            />
          </v-row>
          <v-row class="py-2">
            <v-divider></v-divider>
          </v-row>
          <v-row>
            <v-col>
              <v-text-field
                label="Descrição rápida"
                v-model="formCotacao.descricao"
                :rules="[
                  (v) =>
                    (v || '').length <= 30 ||
                    'Descrições devem ter menos de 30 caracteres',
                ]"
              ></v-text-field>
            </v-col>
          </v-row>

          <v-row>
            <v-col cols="12" md="4">
              <p>
                Ajustes
                <abbr
                  title="FEE Skyler: Margem adicional ao valor final depois da precificação em todos os fornecedores de todos os bilhetes. O valor de RAV deve ser preenchido na precificação de cada bilhete."
                >
                  ⓘ
                </abbr>
              </p>
              <v-checkbox
                dense
                label="Aplicar ajuste?"
                v-model="formCotacao.ajuste_ativado"
              ></v-checkbox>
              
              <v-moeda-field
                v-if="formCotacao.ajuste_ativado"
                dense
                outlined
                label="Ajustes +/-"
                v-model="formCotacao.valor_ajuste_absoluto"
              />
              <v-text-field
                v-if="formCotacao.ajuste_ativado"
                dense
                outlined
                label="Ajustes %"
                v-model="formCotacao.valor_ajuste_porcentagem"
              ></v-text-field>
            </v-col>
            <!-- verificando se existe algum bilhete que não é milha, caso exista, a coluna incentivo é exibida. -->
            <v-col cols="12" md="4" v-if="formCotacao.cotacao_voo?.subcotacoes.some(bilhete => bilhete?.dados?.tipo_cotacao !== 'milha') || formCotacao.cotacao_hotel || formCotacao.cotacao_carro || formCotacao.cotacao_outro">
              <p>
                Incentivo
                <abbr
                  title="Incentivo."
                >
                  ⓘ
                </abbr>
              </p>
              <v-checkbox
                dense
                label="Há incentivo?"
                v-model="formCotacao.incentivo_ativado"
              >
              </v-checkbox>
              <div v-if="(formCotacao.cotacao_voo && formCotacao.incentivo_ativado) && formCotacao.cotacao_voo?.subcotacoes" >
                <!-- bilhetes que forem milhas não serão exibidos -->
                <v-card v-for="(bilhete, index) in formCotacao.cotacao_voo?.subcotacoes" :key="index" class="my-5" v-if="bilhete.dados?.tipo_cotacao !== 'milha'">
                  <v-card-title class="justify-center">Bilhete {{ index + 1 }}</v-card-title>
                <v-row >
                  <v-col cols="6">
                    <v-text-field
                      dense
                      outlined
                      label="Incentivo %"
                      v-model="bilhete_porcentagem_incentivo[index]"
                    >
                    </v-text-field>
                  </v-col>
                    <v-col cols="6">
                      <v-text-field
                        dense
                        outlined
                        label="R$"
                        v-model="bilhete_valor_incentivo[index]"
                        disabled
                      >
                      </v-text-field>
                    </v-col>
                </v-row>
              </v-card>
              </div>

              <div v-if="formCotacao.cotacao_hotel && formCotacao.incentivo_ativado">
                <v-text-field
                  dense
                  outlined
                  label="Incentivo %"
                  v-model="incentivo_porcentagem"
                >
                </v-text-field>
            
                <v-text-field
                  dense
                  outlined
                  label="R$"
                  v-model="incentivo_valor"
                  disabled
                >
                </v-text-field>
              </div>

              <div v-if="formCotacao.cotacao_carro && formCotacao.incentivo_ativado">
                <v-text-field
                  dense
                  outlined
                  label="Incentivo %"
                  v-model="incentivo_porcentagem"
                >
                </v-text-field>
            
                <v-text-field
                  dense
                  outlined
                  label="R$"
                  v-model="incentivo_valor"
                  disabled
                >
                </v-text-field>
              </div>

              <div v-if="formCotacao.cotacao_outro && formCotacao.incentivo_ativado">
                <v-text-field
                  dense
                  outlined
                  label="Incentivo %"
                  v-model="incentivo_porcentagem"
                >
                </v-text-field>
            
                <v-text-field
                  dense
                  outlined
                  label="R$"
                  v-model="incentivo_valor"
                  disabled
                >
                </v-text-field>
              </div>
            </v-col>
            <v-spacer></v-spacer>
            <v-col cols="12" md="4" align-self="center">
              <cotacao-edit-form-secao-valor-final :cotacao="formCotacao" />
            </v-col>
          </v-row>
          <v-row>
            <v-autocomplete
              :items="cotadores"
              v-model="formCotacao.cotador"
              outlined
              attach
              :item-text="(item) => `${item.nome} ${item.sobrenome}`"
              :item-value="(item) => item.id"
              label="Cotação Feita Por"
            >
            </v-autocomplete>
          </v-row>
          <v-row class="py-2">
            <v-divider></v-divider>
          </v-row>
          <!-- Issue #853, comentário no lugar de exclusão para rastreamente de real função desse componente -->
          <!-- <cotacao-edit-form-secao-info-busca
            v-model="formCotacao.data_hora_cotacao"
          /> -->
        </v-container>
      </v-card-text>
      <v-card-actions class="ma-0">
        <v-spacer></v-spacer>
        <v-btn text @click="cancel">Cancelar</v-btn>
        <v-btn
          color="primary"
          :disabled="!allowSave"
          :loading="loadingSalvarCotacao"
          @click="checkSave"
          >Salvar</v-btn
        >
        <div class="text-center">
          <v-dialog v-model="dialog" width="500">
            <v-card>
              <v-card-title class="headline grey lighten-2" primary-title>
                Confirmação
              </v-card-title>

              <v-card-text>
                Por favor, pense em colocar as datas e horários dos vôos com
                escala.
              </v-card-text>

              <v-divider></v-divider>

              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn
                  color="green darken-1"
                  text
                  @click="() => (dialog = false)"
                >
                  Voltar
                </v-btn>

                <v-btn
                  color="green darken-1"
                  text
                  @click="
                    (e) => {
                      dialog = false;
                      save();
                    }
                  "
                >
                  Continuar mesmo assim
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>
        </div>
      </v-card-actions>
    </v-card>
  </div>
  <div v-else>Loading...</div>
</template>
<script>
import SelectTrechosVoo from "@/components/Orcamento/Widgets/SelectTrechosVoo";
import VFieldValue from "@/components/widgets/VFieldValue";
import VMoedaField from "@/components/widgets/VMoedaField";

import CotacaoEditFormSecaoInfoBusca from "./editForm/CotacaoEditFormSecaoInfoBusca";
import CotacaoEditFormSecaoValorFinal from "./editForm/CotacaoEditFormSecaoValorFinal";
import CotacaoEditFormSecaoCotacaoHotel from "./editForm/cotacao/CotacaoEditFormSecaoCotacaoHotel";
import CotacaoEditFormSecaoCotacao from "./editForm/cotacao/CotacaoEditFormSecaoCotacao";
import CotacaoEditFormOrcamentoVinculado from "./editForm/orcamento/CotacaoEditFormOrcamentoVinculado";

import CotacaoMixin from "@/mixins/CotacaoMixin";

import TIPOS_AJUSTE from "@/constants/ajuste";
import { converteValorFinanceiroBRL } from "@/helpers/financeiro";
import { arredondarNumero } from "@/helpers/numeros";

export default {
  components: {
    CotacaoEditFormOrcamentoVinculado,
    CotacaoEditFormSecaoCotacao,
    CotacaoEditFormSecaoCotacaoHotel,
    CotacaoEditFormSecaoValorFinal,
    CotacaoEditFormSecaoInfoBusca,
    VMoedaField,
    VFieldValue,
    SelectTrechosVoo,
  },
  props: {
    value: {
      type: Object,
      default: () => {},
    },
    orcamento: {
      type: Object,
      default: () => {},
      required: true,
    },
    allowSave: {
      type: Boolean,
      default: true,
    },
    isVisualizacao: {
      type: Boolean,
      default: false,
    },
    item: { // item == cotação
      type: Object,
      default: () => {},
    },
  },
  mixins: [CotacaoMixin],

  data() {
    return {
      loading: false,
      formCotacao: null,
      tipos_ajuste: TIPOS_AJUSTE,
      dialog: false,
      valor_ajuste_porcentagem: null,
      valor_ajuste_absoluto: null,
      bilhete_porcentagem_incentivo: [],
      bilhete_valor_incentivo: [],
      incentivo_porcentagem: null,
      incentivo_valor: null
    };
  },
  computed: {
    loggedUser() {
      return this.$store.getters["usuario/loggedUser"];
    },
    users() {
      return this.$store.getters["usuario/usuarios"];
    },
    cotadores() {
      // const arr = this.users.filter((usuario) => usuario.id === 114);
      const arr = [];
      this.users.forEach((usuario) => {
        if (
          usuario.cotador ||
          this.loggedUser.cotador_extra.some(
            (username) => usuario.username === username
          ) ||
          usuario.username === this.loggedUser.username
        ) {
          arr.push(usuario);
        }
      });
      arr.sort((usuarioA, usuarioB) => usuarioA.username.localeCompare(usuarioB.username));
      return arr.filter((usuario) => !usuario.cliente);
    },
    valor_ajuste() {
      let ajuste = 0;
      if (!this.formCotacao) return;
      const custoTotal = this.cotacaoCustoTotal(this.formCotacao);
      const moedaBRL = this.$store.getters["global/moedas"].find(
        (moeda) => moeda.sigla === "BRL"
      );
      if (!moedaBRL) return;
      if (this.formCotacao.valor_ajuste_absoluto) {
        ajuste += converteValorFinanceiroBRL(
          this.formCotacao.valor_ajuste_absoluto
        );
      }
      if (this.formCotacao.valor_ajuste_porcentagem) {
        ajuste +=
          (custoTotal + ajuste) *
          (this.formCotacao.valor_ajuste_porcentagem / 100);
      }
      const ajusteFinanceiro = {
        valor: arredondarNumero(ajuste),
        moeda: moedaBRL.id,
      };
      return ajusteFinanceiro;
    },
    orcamentoId() {
      return this.orcamento && this.orcamento.id;
    },
    custo_total(){
      if(this.formCotacao && this.formCotacao.cotacao_hotel){
        return this.formCotacao.cotacao_hotel.custo_diaria?.valor * this.formCotacao.cotacao_hotel.diarias;
      }
      else if(this.formCotacao && this.formCotacao.cotacao_carro){
        return this.formCotacao.cotacao_carro.custo_diaria?.valor * this.formCotacao.cotacao_carro.diarias;
      }
      else if(this.formCotacao && this.formCotacao.cotacao_outro){
        return this.formCotacao.cotacao_outro.custo_total?.valor;
      }
    },
    valor_incentivo() {
      if(this.formCotacao.cotacao_voo){
        const valor_incentivo_voo = this.formCotacao.cotacao_voo.subcotacoes.map((subcotacao, index) => {
          const porcentagem = parseFloat(this.bilhete_porcentagem_incentivo[index]);
          if(isNaN(porcentagem)){
            return 0;
          }else{
            return ((subcotacao.dados?.custo_bilhete.valor * porcentagem) / 100).toFixed(2);
          }
        });
        return valor_incentivo_voo;
      }else if(this.formCotacao.cotacao_hotel){
        const valor_incentivo_hotel = this.calcularValorIncentivo(this.formCotacao.cotacao_hotel);
        return isNaN(valor_incentivo_hotel) ? 0 : valor_incentivo_hotel; 
      }else if(this.formCotacao.cotacao_carro){
        const valor_incentivo_carro = this.calcularValorIncentivo(this.formCotacao.cotacao_carro);
        return isNaN(valor_incentivo_carro) ? 0 : valor_incentivo_carro;
      }else if(this.formCotacao.outro){
        const valor_incentivo_outro = this.calcularValorIncentivo(this.formCotacao.cotacao_outro);
        return isNaN(valor_incentivo_outro) ? 0 : valor_incentivo_outro;
      }
      return 0;
    }
  },
  mounted() {
    this.initialize();
  },
  watch: {
    formCotacao: {
      deep: true,
      handler: function (val) {
        this.addValorAjuste(this.valor_ajuste);
        this.incentivo_valor = this.valor_incentivo;
        this.bilhete_valor_incentivo = this.valor_incentivo;
        return this.updateCotacao(val);
      },
    },
    'incentivo_porcentagem': {
      handler: function (val) {
        isNaN(val) ? this.incentivo_valor = 0 : this.incentivo_valor = this.calcularValorIncentivo(this.formCotacao);
      },
    },
    'bilhete_porcentagem_incentivo': {
      handler: function () {
        this.bilhete_valor_incentivo = this.valor_incentivo;
      },
    }
  },
  methods: {
    initialize() {
      if (!this.orcamento) {
        this.$root.vtoast.show({
          message: "Orçamento não encontrado",
          color: "error",
        });
        return;
      }
      this.formCotacao = {
        ajuste_ativado: false,
        data_hora_cotacao: new Date(),
        valor_ajuste: { value: 0, moeda: null },
      };

      // Se value for {} não sobrescrevemos nada
      if (!(Object.entries(this.value).length === 0)) {
        this.formCotacao = {
          ...this.formCotacao,
          ...this.value,
        };
        // this.formCotacao = this.value;
      }
       //forçando os valores de incentivo aparecerem ao visualizar uma cotação.
       if (this.isVisualizacao && this.formCotacao.incentivo_ativado) {
        if (this.formCotacao.cotacao_hotel) {
          this.incentivo_porcentagem = this.formCotacao.cotacao_hotel.porcentagem_incentivo;
          this.incentivo_valor = this.formCotacao.cotacao_hotel.valor_incentivo;
        } else if (this.formCotacao.cotacao_carro) {
          this.incentivo_porcentagem = this.formCotacao.cotacao_carro.porcentagem_incentivo;
          this.incentivo_valor = this.formCotacao.cotacao_carro.valor_incentivo;
        } else if (this.formCotacao.cotacao_outro) {
          this.incentivo_porcentagem = this.formCotacao.cotacao_outro.porcentagem_incentivo;
          this.incentivo_valor = this.formCotacao.cotacao_outro.valor_incentivo;
        } else if (this.formCotacao.cotacao_voo) {
          this.formCotacao.cotacao_voo.subcotacoes.map((subcot, index) => {
            this.bilhete_porcentagem_incentivo[index] = this.formCotacao.cotacao_voo.subcotacoes[index].porcentagem_incentivo
            this.bilhete_valor_incentivo[index] = this.formCotacao.cotacao_voo.subcotacoes[index].valor_incentivo;
          })
        }
      }
      this.loading = false;
    },

    addValorAjuste(val) {
      if (this.formCotacao) {
        if (this.isVisualizacao) {
          this.formCotacao.valor_ajuste.value = val;
        } else {
          this.formCotacao.valor_ajuste = val;
        }
      }
    },

    updateCotacao(val) {
      if (val.cotacao_hotel) {
       return{
        ...val,
        cotacao_hotel:{
          ...val.cotacao_hotel,
          porcentagem_incentivo: this.incentivo_porcentagem,
          valor_incentivo: this.incentivo_valor
        }
       }
      }

      if (val.cotacao_carro) {
        return{
          ...val,
          cotacao_carro:{
            ...val.cotacao_carro,
            porcentagem_incentivo: this.incentivo_porcentagem,
            valor_incentivo: this.incentivo_valor
          }
        }
      }

      if (val.cotacao_outro) {
       return{
        ...val,
        cotacao_outro:{
          ...val.cotacao_outro,
          porcentagem_incentivo: this.incentivo_porcentagem,
          valor_incentivo: this.incentivo_valor
        }
       }
      }

      if(val.cotacao_voo){
        return {
        ...val,
        cotacao_voo: {
          ...val.cotacao_voo,
          subcotacoes: val.cotacao_voo.subcotacoes.map((subcot, index) => ({
            ...subcot,
            dados: {
              ...subcot.dados,
              milheiro_efetivo: parseFloat(this.milheiroEfetivo(val, subcot)),
              ajuste_efetivo: parseFloat(this.ajusteEfetivo(val, subcot)),
              porcentagem_incentivo: parseFloat(this.bilhete_porcentagem_incentivo[index]),
              valor_incentivo: parseFloat(this.bilhete_valor_incentivo[index])
              // programa_fidelidade:
              //   typeof subcot.dados.programa_fidelidade === "object" &&
              //   subcot.dados.programa_fidelidade
              //     ? subcot.dados.programa_fidelidade.id
              //     : subcot.dados.programa_fidelidade,
            },
          })),
        },
      };
      }
    },

    cancel() {
      this.formCotacao = null;
      this.$emit("close");
    },

    checkRequired(viagem) {
      // Checa se todas as viagems com escala tem a data e a hora escritos
      const trechos_conexao = viagem.trechos.map((trecho) =>
        trecho.pernas.filter((perna) => perna.tipo_perna == "conexao")
      );

      if (trechos_conexao.length == 0) {
        return true;
      }
      const datas_horas_array = trechos_conexao
        .map((trecho) =>
          trecho.map((perna) => [
            perna.data_hora_chegada,
            perna.data_hora_partida,
          ])
        )
        .flat(Infinity);
      let required_data_hora_escalas = false;
      // Checa se tem null e depois checa se a soma das horas com os minutos dá 0(conta como não feito)
      if (!datas_horas_array.some((x) => x === null)) {
        if (
          !datas_horas_array.some((x) => x.getHours() + x.getMinutes() == 0)
        ) {
          required_data_hora_escalas = true;
        }
      }
      return required_data_hora_escalas;
    },

    // Abre diálogo se houver vôos com conexão sem a hora de chegada e partida adicionados
    saveWithoutRequired() {
      this.dialog = true;
      return;
    },

    checkSave() {
      if (this.formCotacao.cotacao_voo) {
        const subcotacoes = this.formCotacao.cotacao_voo.subcotacoes;
        const required = subcotacoes.every((subcotacao) =>
          this.checkRequired(subcotacao.dados.viagem)
        );
        if (!required) {
          this.saveWithoutRequired();
          return;
        }
      }

      if (this.formCotacao.cotacao_outro) {
        if (!this.formCotacao.cotacao_outro.nome_servico) {
          return this.$root.vtoast.show({
            message: "Nome do serviço é obrigatório!",
            color: "error",
            icon: "mdi-close",
          });
        }
      }
      this.save();
    },

    async save() {
      if (!this.formCotacao) return;
      if(this.formCotacao.cotacao_hotel){
        if(!this.formCotacao.cotacao_hotel.fornecedor){
          this.$root.vtoast.show({
              message: "Fornecedor é obrigatório!",
              color: "error",
              icon: "mdi-close",
            });
            return;
        }
      }
      if(this.formCotacao.cotacao_carro){
        if(!this.formCotacao.cotacao_carro.fornecedor){
          this.$root.vtoast.show({
              message: "Fornecedor é obrigatório!",
              color: "error",
              icon: "mdi-close",
            });
            return;
        }
      }
      if(this.formCotacao.cotacao_outro){
        if(!this.formCotacao.cotacao_outro.fornecedor){
          this.$root.vtoast.show({
              message: "Fornecedor é obrigatório!",
              color: "error",
              icon: "mdi-close",
            });
            return;
        }
      }
      if(this.formCotacao.cotacao_voo){
        const subcotacoes = this.formCotacao.cotacao_voo.subcotacoes;
        for( let i = 0; i < subcotacoes.length; i++){
          if(subcotacoes[i].dados.fornecedor) {
            continue;
          } 
          if(subcotacoes[i].dados.tipo_cotacao === 'pagante'){
            this.$root.vtoast.show({
              message: "Fornecedor é obrigatório!",
              color: "error",
              icon: "mdi-close",
            });
            return; 
          }
        }
      }
      if (this.formCotacao.cotador == null) {
        return this.$root.vtoast.show({
          message: "Cotador é obrigatório!",
          color: "error",
          icon: "mdi-close",
        });
      }
      if (this.formCotacao.descricao == null) {
        return this.$root.vtoast.show({
          message: "Descrição deve ser preenchida!",
          color: "error",
          icon: "mdi-close",
        });
      }
      const res = await this.salvarCotacao(
        this.orcamentoId,
        this.updateCotacao(this.formCotacao)
      );
      if (!res) return;
      this.$emit("save", res);
    },
    calcularValorIncentivo(cotacao) {
      if(cotacao.valor_incentivo && cotacao.porcentagem_incentivo){
        this.formCotacao.incentivo_ativado = true;
        this.incentivo_porcentagem = cotacao.porcentagem_incentivo;
        return cotacao.valor_incentivo;
      }else{
        return ((this.custo_total * this.incentivo_porcentagem) / 100).toFixed(2);
      }
    }
  },
};
</script>
