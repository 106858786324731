<template>
  <v-container fluid v-if="formVoo">
    <cotacao-edit-form-secao-cotacao-voo-sub-cotacao-list
      v-model="formVoo"
      :value="valueProp"
      @input="$emit('input', formVoo)"
    />
  </v-container>
</template>
<script>
import SearchAeroporto from "@/components/widgets/SearchAeroporto";

import CotacaoEditFormSecaoCotacaoVooSubCotacaoList
  from "./CotacaoEditFormSecaoCotacaoVooSubCotacaoList";
import CotacaoVooMixin from "@/mixins/CotacaoVooMixin";

export default {
  components: {CotacaoEditFormSecaoCotacaoVooSubCotacaoList, SearchAeroporto},
  mixins: [CotacaoVooMixin],
  props: {
    orcamento: {
      type: Object,
      default: () => {
      },
    },
    value: {
      type: Object,
      default: () => {
      }
    }
  },
  data() {
    return {
      formVoo: null,
      valueProp: this.value? this.value : null,
    }
  },
  watch: {
    formVoo: {
      deep: true,
      handler: function (val) {
        this.$emit('input', val)
      }
    }
  },
  created() {
    this.initialize();
  },
  methods: {
    initialize() {
      this.formVoo = this.getFormCotacao(this.orcamento, this.value);
    }
  }
}
</script>
