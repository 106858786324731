<template >
    <v-container>
        <v-row>
            <v-col>
                <div class="text-h5 transition-swing">
                    Exportar Dados 
                </div>
            <v-breadcrumbs :items="breadcumb_items"></v-breadcrumbs>
            </v-col>
        </v-row>

        <v-row><v-col>
            <exporter></exporter>
        </v-col></v-row>

    </v-container>
</template>
<script>
import Exporter from '@/components/Exporter/Exporter.vue'

export default {
    components: {Exporter},
    data: () => ({
        breadcumb_items: [
        {
            text: 'Home',
            to: {
                name: "home",
            },
            exact: true
        },
        {
            text: 'Exportar',
            to: {
                name: "exportar"
            }
        }
      ],
    }),

    mounted() {
        this.initialize()
    },

    methods: {
        initialize() {
        },
    }
    
}
</script>