import Vue from "vue"
import "./dates"
import "./servicos"
import "./nomes"
import "./local"
import "./fidelidades"
import "./fornecedores"
import "./endereco"
import "./meiosPagamento"
import "./contato"
import "./emissao"
import "./venda"
import "./orcamentos"
import "./campoFormadorPreco"
import "./ids"
import "./contaFidelidade"
import "./notificacoes"
import "./rac"
import "./racStatus"

import PERFIS from "@/constants/perfisContatos.js";

function capitalize(value) {
    if (!value) return "";
    value = value.toString();
    return value.charAt(0).toUpperCase() + value.slice(1);
}

function dinheiroReal(value) {
    if (value !== 0 && !value) return "R$ - ";
    // * Alteração da forma de mostrar valor quando = 0 a pedido da Gabi para issue #776, atentar a mudanças indesejadas em outras partes do sistema 
    if (value == 0) return "-";
    value = parseFloat(value);
    const valueStr = value.toLocaleString("pt-BR", {minimumFractionDigits: 2, maximumFractionDigits: 2})
    return `R$ ${valueStr}`
}

function dinheiro(value, simbolo) {
    if (!value)
        return "";
    value = parseFloat(value);
    const valueStr = value.toLocaleString("pt-BR", {minimumFractionDigits: 2, maximumFractionDigits: 3})
    const simboloStr = simbolo ? simbolo : "";
    return `${simboloStr} ${valueStr}`
}

function pluralize(value) {
    if (!value)
        return "";
    if (Number(value) > 1)
        return "s";

    return ""
}

function formatPerfil(perfil){
    const found = PERFIS.find(p => p.key === perfil)
    if(!found) return "-"
    return found.value
}

Vue.filter("capitalize", capitalize);
Vue.filter("dinheiroReal", dinheiroReal)
Vue.filter("dinheiro", dinheiro)
Vue.filter("pluralize", pluralize)
Vue.filter("formatPerfil", formatPerfil)

