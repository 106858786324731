<template>
  <v-container class="w-auto" max-width="100%">
    <v-card class="px-4 mx-0">
      <v-card-title class="text-h5"> Protocolos </v-card-title>
      <v-card-text>
        <v-row v-for="item in protocolos" :key="item.id">
          <v-col>
            <v-field-value label="Nome">
              {{ item.nome }}
            </v-field-value>
          </v-col>
          <v-col>
            <v-field-value label="Descrição">
              {{ item.descricao }}
            </v-field-value>
          </v-col>
          <v-col>
            <v-field-value label="Data">
              {{ item.data | convertDataBR }}
            </v-field-value>
          </v-col>
          <v-col>
            <v-field-value label="Criado em">
              {{ item.created_at | dataHoraBR }}
            </v-field-value>
          </v-col>
          <v-col>
            <v-field-value label="Criado por">
              {{ item.created_by | nomeSobrenome }}
            </v-field-value>
          </v-col>
          <v-divider></v-divider>
        </v-row>

        <v-row>
          <v-text-field
            v-model="value.nome"
            dense
            outlined
            label="Nome"
            :rules="[
              (v) =>
                (v && v.length >= 10) ||
                'Observações deve ter mais de 10 caracteres',
            ]"
          ></v-text-field>
        </v-row>
        <v-row>
          <v-textarea
            label="Descrição"
            outlined
            name="descricao"
            rows="2"
            v-model="value.descricao"
            :rules="[
              (v) =>
                (v && v.length >= 20 && v.length <= 500) ||
                'Observações devem ter entre 20 e 500 caracteres',
            ]"
          />
        </v-row>
        <v-row>
          <form-date-picker label="Data" v-model="value.data" />
        </v-row>
      </v-card-text>

      <v-card-actions>
        <v-btn text @click="closeDialog">
          <v-icon left>mdi-arrow-left</v-icon>
          Voltar
        </v-btn>
        <v-spacer />
        <v-btn
          text
          @click="addProtocolo()"
          :disabled="value.nome.length < 10 || value.descricao.length < 20"
        >
          Adicionar protocolo
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-container>
</template>

<script>
import FormDatePicker from "@/components/widgets/FormDatePicker";
import VFieldValue from "@/components/widgets/VFieldValue";
export default {
  components: { FormDatePicker, VFieldValue },
  data() {
    return {
      motivo: "",
      loading: false,
      value: { nome: "", descricao: "" },
    };
  },
  props: {
    dialog: {
      type: Boolean,
      default: false,
    },
    protocolos: {
      type: Array,
      default: () => [],
    },
  },
  methods: {
    closeDialog() {
      this.$emit("closeDialog");
    },
    addProtocolo() {
      this.loading = true;
      this.$emit("adicionarProtocolo", this.value);
      setTimeout(() => {
        this.value = { nome: "", descricao: "" };
      }, 2000);
    },
  },
};
</script>
