<template>
  <v-card>
  <v-card-text>
  <v-data-table
    :headers="headers"
    :items="fornecedores"
    class="elevation-5"
    :loading="loading"
    :hide-default-footer="true"
  >
    <!-- TEMPLATES TABELA-->
    <template v-slot:item.endereco="{ item }">
      {{item.endereco | formatEndereco}}
    </template>
    <template v-slot:item.provedores="{ item }">
      <v-icon color="primary" class="mr-2" @click="showProvedores(item)">
        mdi-magnify
      </v-icon>
    </template>
    <template v-slot:item.cotacoes="{ item }">
      <v-icon color="primary" class="mr-2" @click="showCotacoes(item.id)">
        mdi-magnify
      </v-icon>
    </template>
    <template v-slot:item.subcotacoes="{ item }">
      <v-icon color="primary" class="mr-2" @click="showSubCotacoes(item.id)">
        mdi-magnify
      </v-icon>
    </template>
    <template v-slot:item.tipos_servicos="{ item }">
      {{getTiposServicosProvedores(item.provedores)}}
    </template>
    <template v-slot:item.emissoes="{ item }">
      <v-icon color="primary" class="mr-2" @click="showEmissoes(item.id)">
        mdi-magnify
      </v-icon>
    </template>
    <template v-slot:item.created_by="{ item }">
        {{ item.created_by | nomeSobrenome }}
      </template>
    <template v-slot:item.actions="{ item }">
      <v-icon small class="mr-2" @click="editItem(item)" :disabled="checkUserPermissao">
        mdi-pencil
      </v-icon>
    </template>
    <template v-slot:no-data>
      <v-btn color="primary" @click="$emit('refresh')">
        Reset
      </v-btn>
    </template>

    <!-- DIALOGOS -->
    <template v-slot:top>
      <v-toolbar flat>
        <v-toolbar-title>Fornecedores</v-toolbar-title>
        <v-divider class="mx-4" inset vertical></v-divider>
        <v-spacer></v-spacer>
        <!-- EDIÇÃO--> 
        <fornecedor-new-item
          v-model="dialog"
          :provedores="provedores"
          :item="editedItem"
          :newItem="editedIndex === -1"
          @close="close"
          @save="save"
        />
        <!-- DIALOG DELETE -->
        <v-dialog v-model="dialogDelete" max-width="500px">
          <v-card>
            <v-card-title class="text-h5">Tem certeza que deseja deletar este item?</v-card-title>
            <v-card-text>
                Digite o nome <span class="bold-text"> {{nomeFornecedor}} </span> para confirmar.
                <v-text-field label="Nome completo" v-model="inputNomeFornecedor" />
            </v-card-text>
            <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn text @click="closeDelete">Não</v-btn>
                <v-btn text @click="deleteItemConfirm"
                    :disabled="nomeFornecedor !== inputNomeFornecedor"
                >Sim</v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
        <!-- DIALOG PROVEDORES-->
        <v-dialog v-model="dialogProvedores" max-width="500px">
          <fornecedores-provedores-item
            :provedores="editedItem.provedores"
            @close="dialogProvedores = false"
          ></fornecedores-provedores-item>
        </v-dialog>
        <v-dialog v-model="dialogCotacoes" max-width="500px">
          <fornecedores-cotacoes-item
            :cotacoes="fornecedorCotacoes"
            :loading="loadingCotacoes"
            @close="dialogCotacoes = false"
          ></fornecedores-cotacoes-item>
        </v-dialog>
        <v-dialog v-model="dialogSubCotacoes" max-width="500px">
          <fornecedores-sub-cotacoes-item
            :subcotacoes="fornecedorSubCotacoes"
            :loading="loadingSubCotacoes"
            @close="dialogSubCotacoes = false"
          ></fornecedores-sub-cotacoes-item>
        </v-dialog>
        <v-dialog v-model="dialogEmissoes" max-width="500px">
          <fornecedores-emissoes-item
            :emissoes="fornecedorEmissoes"
            :loading="loadingEmissoes"
            @close="dialogEmissoes = false"
          ></fornecedores-emissoes-item>
        </v-dialog>
      </v-toolbar>
      <v-col cols="12" md="4">
        <v-text-field
          dense
          outlined
          clearable
          clear-icon="mdi-close"
          append-icon="mdi-magnify"
          label="Pesquisar"
          v-model="search.fornecedor"
        />
      </v-col>
    </template>
  </v-data-table>
      <Paginator
        v-model="currentPage"
        :numPages="numPages"
        @pageChange="pageChange"
      />
      </v-card-text>
    </v-card>
</template>

<script>
import Paginator from '@/components/widgets/Paginator.vue'
import FornecedoresProvedoresItem from './FornecedoresProvedoresItem'
import FornecedoresCotacoesItem from './FornecedoresCotacoesItem'
import FornecedoresSubCotacoesItem from './FornecedoresSubCotacoesItem'
import FornecedoresEmissoesItem from './FornecedoresEmissoesItem'
import FornecedorNewItem from './FornecedorNewItem'
import VLinkTextField from "@/components/widgets/VLinkTextField";

import api from "@/api/api"
import checkPermissao from "@/helpers/checkUserPermissao.js";


import {filterEndereco} from "@/helpers/endereco"
import {formatApiError} from "@/helpers/apiErrors"
import {validateCPF, validateCNPJ} from "@/helpers/identificadores"
import { telefoneString } from "@/helpers/telefone"

export default {

  components: {
    FornecedoresProvedoresItem,
    FornecedoresCotacoesItem,
    FornecedoresSubCotacoesItem,
    FornecedorNewItem,
    FornecedoresEmissoesItem,
    VLinkTextField,
    Paginator,
  },

  props: {
    value: {
      type: Array,
      default: () => []
    },
    provedores: {
      type: Array,
      default: () => []
    },
    loading: {
      type: Boolean,
      default: true
    },
    numPages: {
      type: Number,
      default: 1
    },
  },

  data: () => ({
    inputNomeFornecedor: "",
    fornecedorCotacoes: [],
    fornecedorSubCotacoes: [],
    fornecedorEmissoes: [],
    loadingCotacoes: false,
    loadingSubCotacoes: false,
    loadingEmissoes: false,

    dialogProvedores: false,
    dialogCotacoes: false,
    dialogSubCotacoes: false,
    dialogEmissoes: false,

    dialog: false,
    dialogDelete: false,
    headers: [
      {
        text: 'ID',
        align: 'start',
        sortable: false,
        value: 'id',
      },
      { text: 'Nome', value: 'nome' },
      { text: 'Email', value: 'email' },
      { text: 'Telefone', value: 'telefone' },
      { text: 'Endereço', value: 'endereco' }, 
      { text: 'Provedores', value: 'provedores', align: 'center'},
      { text: 'Cotações', value: 'cotacoes', align: 'center'},
      { text: 'Bilhetes', value: 'subcotacoes', align: 'center'},
      { text: 'Emissões', value: 'emissoes' , align: 'center'},
      { text: 'Tipos de Servicos', value: 'tipos_servicos', align: 'center'},
      { text: 'Criado por', value: 'created_by', align: 'center'},
      { text: 'Actions', value: 'actions', sortable: false, align: 'center' },
    ],
    editedIndex: -1,
    editedItem: {
      nome: '',
      email: '',
      telefone: '',
      cpf: '',
      cnpj: '',
      provedores: [],
      endereco: {},
    },
    defaultItem: {
      nome: '',
      email: '',
      telefone: '',
      cpf: '',
      cnpj: '',
      provedores: [],
      endereco: {},
    },
    currentPage: 1,
    search: {
      fornecedor: ''
    },
    filter: {
      fornecedor: ''
    },
    filters: [],
    timer: undefined
  }),

  computed: {
    user() {
      return this.$store.getters['usuario/loggedUser'];
      },
    checkUserPermissao(){
        const pertencente = this.user.pertencente;
        return checkPermissao(pertencente);
      },
    cep () {
      if (!this.editedItem.endereco) return ""
      return this.editedItem.endereco.cep
    },
    endereco (){
      return this.editedItem.endereco
    },
    fornecedores(){
      return this.value
    },
    nomeFornecedor() {
      return this.editedItem.nome
    }
  },

  watch: {
    dialog (val) {
      val || this.close()
    },
    dialogDelete (val) {
      val || this.closeDelete()
    },
    "search.fornecedor": {
      handler() {
        clearTimeout(this.timer);

        this.timer = setTimeout(() => {
          this.filter.fornecedor = this.search.fornecedor;
        }, 1000);
      },
    },
    "filter.fornecedor": {
      handler() {
        this.filters = [["nome", this.filter.fornecedor]]
        this.pageChange(this.currentPage)
      },
    },
  },

  methods: {

    showProvedores(item) {
      this.editedItem = Object.assign({}, item)
      this.dialogProvedores = true
    },

    showCotacoes(fornecedorID){
      this.loadingCotacoes = true
      api.getFornecedorCotacoes(fornecedorID).then(
        res => {
          this.fornecedorCotacoes = res.data
          this.loadingCotacoes = false
        }
      ).catch(err => 
        this.$root.vtoast.show({
            message: 'Erro ao carregar cotações associadas.' + err,
            color: 'error',
            icon: 'mdi-close'
        })
      )
      this.dialogCotacoes = true
    },

    showSubCotacoes(fornecedorID){
      this.loadingSubCotacoes = true
      api.getFornecedorSubCotacoes(fornecedorID).then(
        res => {
          this.fornecedorSubCotacoes = res.data
          this.loadingSubCotacoes = false
        }
      ).catch(err => 
        this.$root.vtoast.show({
            message: 'Erro ao carregar bilhetes associados.' + err,
            color: 'error',
            icon: 'mdi-close'
        })
      )
      this.dialogSubCotacoes = true
    },

    showEmissoes(fornecedorID){
      this.loadingEmissoes = true
      api.getFornecedorEmissoes(fornecedorID).then(
        res => {
          this.fornecedorEmissoes = res.data
          this.loadingEmissoes = false
        }
      ).catch(err => 
        this.$root.vtoast.show({
            message: 'Erro ao carregar emissões associadas.' + err,
            color: 'error',
            icon: 'mdi-close'
        })
      )
      this.dialogEmissoes = true
    },


    getTiposServicosProvedores(provedores){
      if(!provedores) return ""
      const tipos_servicos = [...new Set(
        provedores
        .map(prov => this.$options.filters.formatTipoServico(prov.tipo_servico))
      )]
      return tipos_servicos.join(" ")
    },

    validate_data(){
      const data = this.editedItem
      if(!validateCPF(data.cpf) && !validateCNPJ(data.cnpj)) return false
      return true
    },

    saveEndereco(end){
      this.editedItem.endereco = end
    },

    editItem (item) {
      this.editedIndex = this.fornecedores.indexOf(item)
      this.editedItem = Object.assign({}, item)
      this.dialog = true
    },

    deleteItem (item) {
      this.editedIndex = this.fornecedores.indexOf(item)
      this.editedItem = Object.assign({}, item)
      this.dialogDelete = true
    },

    async deleteItemConfirm () {
      await api.deleteFornecedor(this.editedItem.id)
      .then(response => {
        this.fornecedores.splice(this.editedIndex, 1)
        this.$root.vtoast.show({
            message: "Fornecedor deletado com sucesso.",
            icon: 'mdi-check',
            color: 'success'
        })
      })
      .catch(err => {
        this.$root.vtoast.show({
            message: `Erro ao deletar fornecedor: ${err}`,
            icon: 'mdi-close',
            color: 'error'
        })
      })
      this.closeDelete()
      this.$emit('refresh')
    },

    close () {
      this.dialog = false
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem)
        this.editedIndex = -1
      })
    },

    closeDelete () {
      this.dialogDelete = false
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem)
        this.editedIndex = -1
      })
    },

    async post(){
      if(!this.validate_data()){
        this.$root.vtoast.show({
            message: 'Faltam dados ou dados fornecidos são inválidos.',
            color: 'error',
            icon: 'mdi-close',
        })
        return
      }
      await api.postFornecedor(this.editedItem)
      .then(response => {
        this.fornecedores.push(response.data)
        this.$root.vtoast.show({
            message: "Fornecedor criado com sucesso!",
            icon: 'mdi-check',
            color: 'success'
        })
        this.close()
      })
      .catch(err => {
        window.alert(formatApiError(err))
      })
    },

    async put(){
      if(!this.validate_data()){
        this.$root.vtoast.show({
            message: 'Faltam dados ou dados fornecidos são inválidos.',
            color: 'error',
            icon: 'mdi-close',
        })
        return
      }
      await api.putFornecedor(this.editedItem.id, this.editedItem)
      .then(response => {
        this.$emit('refresh')
        this.$root.vtoast.show({
            message: "Dados do fornecedor alterado com sucesso!",
            icon: 'mdi-check',
            color: 'success'
        })
        this.close()
      })
      .catch(err => {
        window.alert(formatApiError(err))
      })
    },

    async save () {
      this.editedItem.endereco = filterEndereco(this.editedItem.endereco)
      this.editedItem.telefone = telefoneString(this.editedItem.telefone)

      if (this.editedIndex > -1) {
        await this.put()
      } else {
        await this.post()
      }
    },
    pageChange(page) {
      this.currentPage = page
      this.$emit('refreshFornecedores', {page: page, filters: this.filters})
    },
  },
  }
</script>
