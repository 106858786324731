<template lang="">
    <div class="page pb-2">
           <card-cotacao-voo 
           v-if="servico.cotacao.cotacao_voo"
           :cotacao="servico.cotacao"
           :beneficiarios="beneficiariosServico"
           :valorAbertoCotacoes="valorAbertoCotacoes"
            />
            <card-cotacao-hotel 
           v-if="servico.cotacao.cotacao_hotel"
           :cotacao="servico.cotacao"
           :beneficiarios="beneficiariosServico"
           :valorAbertoCotacoes="valorAbertoCotacoes"
            />
            <card-cotacao-carro 
           v-if="servico.cotacao.cotacao_carro"
           :cotacao="servico.cotacao"
           :beneficiarios="beneficiariosServico"
           :valorAbertoCotacoes="valorAbertoCotacoes"
            />
            <card-cotacao-outros 
           v-if="servico.cotacao.cotacao_outro"
           :cotacao="servico.cotacao"
           :beneficiarios="beneficiariosServico"
           :valorAbertoCotacoes="valorAbertoCotacoes"
            />
            <div v-if="index==2 || (index-2)%4==0" class="html2pdf__page-break"></div>
    </div>
</template>
<script>
import CotacaoMixin from "@/mixins/CotacaoMixin";
import CardCotacaoVoo from './CardCotacaoVoo.vue'
import CardCotacaoCarro from './CardCotacaoCarro.vue'
import CardCotacaoHotel from './CardCotacaoHotel.vue'
import CardCotacaoOutros from './CardCotacaoOutros.vue'

export default {
    components: {
        CardCotacaoVoo,
        CardCotacaoHotel,
        CardCotacaoCarro,
        CardCotacaoOutros
    },
    data() {
        return {
        }
    },
    props: {
        servico: {
            type: Object,
            default: () => { },
        },
        beneficiarios: {
            type: Array,
            default: () => []
        },
        index: {
            type: Number,
            default: 0
        },
        valorAbertoCotacoes: {
            type: Object,
            default: () => { }
        },
    },
    mixins: [CotacaoMixin],
    computed: {
        beneficiariosServico() {
            return this.beneficiarios.filter(beneficiario => beneficiario.servico === this.servico.id)
        }
    }
}
</script>