<template>
  <div>
    <pagamento
      :emissao="emissao"
    />
  </div>
</template>
<script>
import Pagamento from "./Pagamento/Pagamento";
import api from "@/api/api";

export default {
  props: {
    emissao: {
      type: Object,
    },
    precoMilheiro: {
      type: Boolean,
      default: false,
    },
  },

  components: {
    Pagamento,
  },

  data: () => ({
    loadingServicos: false,
    loadingPagamentos: false,
    servicos: [],
  }),

  computed: {
    emissaoID() {
      if (!this.emissao) return null;
      return this.emissao.id;
    },

    filteredServicos() {
      if (!this.emissao) return [];
      return [this.emissao.servico];
    },
  },

  async mounted() {
    await this.initialize();
  },

  methods: {
    async initialize() {
    },
  },
};
</script>
