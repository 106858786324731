<template >
    <div>
        <pagamento-table 
            v-model="pagamentos"
            :servicos="filteredServicos"
            :loading="loadingPagamentos"
            :loadingServicos="loadingServicos"
            :venda="venda"
            :pagamentosVenda="pagamentos"
            :deactivateCheckItem="true"
            @reset="initialize"
        />
    </div>
</template>
<script>
import PagamentoTable from "@/components/Pagamento/PagamentoTable";
import CotacaoMixin from "@/mixins/CotacaoMixin";
import api from "@/api/api";

export default {
  mixins: [CotacaoMixin],

  components: {
    PagamentoTable,
  },

  data: () => ({
    loadingServicos: false,
    loadingPagamentos: true,
    servicos: [],
    pagamentos: [],
  }),

  computed: {
    venda() {
      return parseInt(this.$route.params.id);
    },

    filteredServicos() {
      return this.servicos
        .filter((servico) => servico.venda?.id === this.venda)
        // .filter((servico) => servico.status === "pagamento");
    },
  },

  async mounted() {
    await this.initialize();
  },

  methods: {
    async initialize() {
      await api.getPagamentos(this.venda).then((res) => {
        this.pagamentos = res.data;
        this.pagamentos.forEach((pagamento) => {
          pagamento.valor_base = this.calculoValorBase(pagamento.servico,pagamento.valor_pagamento,pagamento.taxa_meio_pagamento)
        })
        this.loadingPagamentos = false;
      });

      this.loadingServicos = true;
      await api.getServicosVenda(this.venda).then((res) => {
        this.servicos = res.data;
        this.loadingServicos = false;
      });
    },

    filterPagamentos(pagamentos) {
      if (!pagamentos.length) return [];
      return pagamentos.filter((pagamento) => pagamento.venda === this.venda);
    },
  },
};

</script>
