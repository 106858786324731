<template>
  <v-card>
    <v-card-title>Pagamentos em Aberto</v-card-title>

    <v-card-text>
      <v-data-table
        :items="pagamentosAbertos"
        :loading="loading"
        :headers="headers"
        :hide-default-footer="true"
        sort-by="id"
        :items-per-page="15"
        sort-desc
      >
        <template v-slot:top>
          <v-container fluid>
            <v-row>
              <v-col cols="6" md="2">
                <v-autocomplete
                  clear-icon="mdi-close"
                  clearable
                  outlined
                  v-model="filter.consultor"
                  label="Consultor"
                  :item-text="(item) => `${item.nome} ${item.sobrenome}`"
                  :item-value="(item) => item.id"
                  :items="users"
                  dense
                >
                </v-autocomplete>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="6" md="2">
                <v-autocomplete
                  clear-icon="mdi-close"
                  clearable
                  outlined
                  v-model="filter.contato"
                  label="Nome Cliente"
                  :item-text="(item) => `${item.nome} ${item.sobrenome}`"
                  :item-value="(item) => item.id"
                  :items="clientes"
                  dense
                >
                </v-autocomplete>
              </v-col>
              <v-col cols="6" md="2">
                <v-text-field
                  dense
                  outlined
                  clearable
                  clear-icon="mdi-close"
                  append-icon="mdi-magnify"
                  label="ID da Venda"
                  v-model="search.id_venda"
                />
              </v-col>
            </v-row>
          </v-container>
        </template>

        <template v-slot:item.servico="{ item }">
          {{ item.servico?.id ?? "-" }}
        </template>

        <template v-slot:item.responsavel_financeiro="{ item }">
          {{ item.responsavel_financeiro | nomeSobrenome }}
        </template>

        <template v-slot:item.created_by="{ item }">
          {{ item.created_by | nomeSobrenome }}
        </template>

        <template v-slot:item.created_at="{ item }">
          {{ item.created_at | convertDataBR }}
        </template>

        <template v-slot:item.valor_pagamento="{ item }">
          {{ item.valor_pagamento?.valor | dinheiroReal }}
        </template>
        <template v-slot:item.venda.id="{ item }">
          <v-link-text-field
            :key="item.venda.id"
            :to="{ name: 'venda-servicos', params: { id: item.venda?.id } }"
          >
            #{{ item.venda.id | formatId }}
          </v-link-text-field>
        </template>
      </v-data-table>
      <v-pagination v-model="currentPage" :length="pages" :total-visible="7" />
    </v-card-text>
  </v-card>
</template>
  <script>
import CotacaoMixin from "@/mixins/CotacaoMixin";
import VLinkTextField from "@/components/widgets/VLinkTextField";
import statusServico from "@/constants/statusServico";
import ServicoDescricao from "@/components/venda/validacao/VendaServicosDescricao.vue";
import FormDatePickerMultiple from "@/components/widgets/FormDatePickerMultiple.vue";

import api from "@/api/api";

export default {
  components: {
    VLinkTextField,
    ServicoDescricao,
    FormDatePickerMultiple,
  },
  props: {
    pagamentosAbertos: {
      type: Array,
      default: [],
    },
    loading: {
      type: Boolean,
      default: false,
    },
    showVenda: {
      type: Boolean,
      default: false,
    },
    pages: {
      type: Number,
      default: 1,
    },
    showFiltro: {
      type: Boolean,
      default: true,
    },
    emVenda: {
      type: Boolean,
      default: false,
    },
  },
  mixins: [CotacaoMixin],
  data: () => ({
    headers: [
      { text: "Pagamento ID", value: "id" },
      { text: "Valor pagamento", value: "valor_pagamento" },
      { text: "Meio de pagamento", value: "meio_pagamento" },
      { text: "Cliente", value: "nome_cliente" },
      { text: "Criado por", value: "created_by" },
      { text: "Serviço Associado", value: "servico" },
      { text: "Criado em", value: "created_at" },
    ],
    currentPage: 1,
    filter: {
      status: "",
      data_servico: "",
      contato: "",
      id_venda: "",
      consultor: "",
    },
    search: {
      localizador: "",
      emissor: "",
      id_venda: "",
    },
    desagioProprio: 0,
    newServicoKey: 0,
  }),

  methods: {
    changePage() {
      let filters = [];

      if (this.filter.consultor)
        filters.push(["consultor", this.filter.consultor]);
      if (this.filter.contato) filters.push(["contato", this.filter.contato]);
      if (this.filter.id_venda) filters.push(["venda", this.filter.id_venda]);
      this.$emit("getPagamentosAbertos", this.currentPage, filters);
    },
  },
  async mounted() {
    await this.$store.dispatch("global/fetchFornecedores");
    await this.$store.dispatch("global/fetchMoedas");
    await this.$store.dispatch("contato/fetchContatos");
  },

  created() {
    if (!this.showVenda) return;
    this.headers.splice(2, 0, { text: "Venda ID", value: "venda.id" });
  },

  computed: {
    fornecedores() {
      return this.$store.getters["global/fornecedores"];
    },
    users() {
      return this.$store.getters["usuario/usuarios"];
    },
    clientes() {
      return this.$store.getters["contato/contatos"];
    },
  },

  watch: {
    currentPage() {
      this.changePage();
    },
    "filter.id_venda": {
      handler() {
        clearTimeout(this.timer);
        this.timer = setTimeout(() => {
          this.filter.id_venda = this.search.id_venda;
        }, 1000);
      },
    },

    filter: {
      deep: true,
      handler(val) {
        this.currentPage = 1;
        this.changePage();
      },
    },
  },
};
</script>
  