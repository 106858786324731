<template>
  <v-dialog v-model="dialog" max-width="800px">
    <template v-slot:activator="{ on, attrs }">
      <v-btn color="primary" dark class="mb-2" v-bind="attrs" v-on="on">
        Novo Fornecedor
      </v-btn>
    </template>
    <v-card>
      <v-card-title>
        <span class="text-h5">{{ formTitle }}</span>
      </v-card-title>
      <v-card-text>
        <small>Todos os campos são obrigatórios</small>
        <v-container fluid>
          <v-row>
            <v-col>
              <fornecedor-form :item="item"></fornecedor-form>
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              <v-select
                :items="provedores"
                label="Provedores"
                item-text="nome"
                item-value="id"
                multiple
                chips
                v-model="item.provedores"
              ></v-select>
            </v-col>
          </v-row>
          <v-divider></v-divider>
          <v-row>Endereço</v-row>
          <v-row>
            <v-col>
              <endereco-edit-create
                v-model="item.endereco"
              ></endereco-edit-create>
            </v-col>
          </v-row>
        </v-container>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="blue darken-1" text @click="close"> Cancelar </v-btn>
        <v-btn color="blue darken-1" text @click="save"> Salvar </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>
import FornecedorForm from "./FornecedorForm"; // todo provedor tem pelo menos as mesmas infos de um fornecedor
import EnderecoEditCreate from "@/components/widgets/EnderecoEditCreate";
import api from '@/api/api';
export default {
  components: {
    FornecedorForm,
    EnderecoEditCreate,
  },

  data: () => ({
    dialog: false,
    provedores: [],
  }),

  props: {
    item: {
      type: Object,
      default: () => {},
    },

    newItem: {
      type: Boolean,
      default: true,
    },

    value: {
      // Dialog
      type: Boolean,
      default: false,
    },

  },

  computed: {
    formTitle() {
      return this.newItem ? "Novo Fornecedor" : "Editar Fornecedor";
    },
  },

  watch: {
    dialog(val) {
      this.$emit("input", val);
    },
    value(val) {
      this.dialog = val;
    },
  },

  mounted() {
    this.dialog = this.value;
    this.initialize();
  },

  methods: {
    close() {
      this.$emit("close");
    },

    save() {
      this.$emit("save");
    },
    initialize(){
      api.getProvedoresSimples().then(res =>{
        this.provedores = res.data
      })
    },
  },
};
</script>
