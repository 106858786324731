<template>
    <table-pagamentos-abertos
      :pagamentosAbertos="pagamentosAbertos"
      :loading="loading"
      :pages="numPages"
      @getPagamentosAbertos="(page, filters) => getPagamentosAbertos(page, filters)"
      showVenda
      />
  </template>
  <script>
  import api from "@/api";
  import TablePagamentosAbertos from "../components/PagamentosAbertos/TablePagamentosAbertos";

  
  export default {
    components: {
      TablePagamentosAbertos,
    },
  
    data: () => ({
      pagamentosAbertos: [],
      loading: false,
      numPages: 1
    }),
  
    mounted() {
      this.initialize()
    },
  
    methods: {

      getPagamentosAbertos(page=1,filters = []){
        this.loading = true;
        api
        .getPagamentosAbertos(page, filters)
        .then(
            (res) => {
                this.pagamentosAbertos = res.data.pagamentos_abertos
                this.numPages = res.data.pages;

                console.log(res)
            }
        )
        .catch((err) => {
            console.log(err);
            this.$root.vtoast.show({
              message: "Erro ao carregar pagamentos.",
              color: "error",
              icon: "mdi-close",
            });
        }).finally(() => {
            this.loading = false;
        })
      },

      initialize() {
        this.getPagamentosAbertos();
      },
    },
  };
  </script>
  