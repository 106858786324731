/*****************************************************
 O formato do objeto deve ser:
 ******************************************************/
const defaultFormCotacao = {
    subcotacoes: [{
        dados: {
            viagem: {
                trechos: [{
                    pernas: [{}]
                }]
            }
        }
    }]
}

function getFormCotacaoFromOrcamento(orcamento) {
    const orcamento_voo = orcamento.orcamento_voo;
    const trechosOrcamentoVoo = orcamento_voo.trechos || [];
    const trechos = trechosOrcamentoVoo
    .map(trechoOrcamento => ({
        pernas: [{
            origem: trechoOrcamento.origem,
            destino: trechoOrcamento.destino,
            flexibilidade_origem: trechoOrcamento.flexibilidade_origem,
            flexibilidade_destino: trechoOrcamento.flexibilidade_destino,
            data_hora_partida: trechoOrcamento.data_inicio,
            adultos: orcamento_voo.adultos,
            bebes: orcamento_voo.bebes,
            criancas: orcamento_voo.criancas,
            primeira_classe: orcamento_voo.primeira_classe,
            executiva: orcamento_voo.executiva,
            economica: orcamento_voo.economica
        }]
    }));
    return {
        subcotacoes: [{
            dados: {
                tipo_cotacao: "pagante", 
                viagem: {
                    trechos: trechos
                },
            }
        }]
    };
}


function getFromCotacaoFromCotacao(cotacao) {
    const subcotacoes = cotacao.subcotacoes.map(subcotacao => {
        return {
            dados: {
                ...subcotacao,
                viagem: {
                    ...subcotacao.viagem
                }
            }
        }
    });
    return {
        ...cotacao,
        subcotacoes: [...subcotacoes]
    };
}

var cotacaoVooMixin = {
    data() {
        return {}
    }, methods: {
        getFormCotacao(orcamento, cotacaoVoo) {
            // se foi inicializado somente com orçamento
            if (orcamento && !cotacaoVoo) {
                return getFormCotacaoFromOrcamento(orcamento);
            }
            // Se foi inicializado com cotação
            if (cotacaoVoo) {
                return getFromCotacaoFromCotacao(cotacaoVoo);
            }

            //se não foi inicializado
            return Object.assign({}, defaultFormCotacao);
        }
    }
}

export default cotacaoVooMixin;
