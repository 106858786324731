<template>
  <div>
    <v-container fluid>
      <v-row>
        <v-col>
          <v-data-table
            dense
            :headers="headers"
            :search="search"
            :items="loadingData ? [] : tableItems"
            sort-by="id"
            show-expand
            sort-desc
            :loading="loading || loadingData"
          >
            <template v-slot:top>
              <v-container v-if="orcamentosList.length > 0" fluid>
                <v-row>
                  <v-col cols="12" md="4">
                    <v-text-field
                      dense
                      outlined
                      clearable
                      clear-icon="mdi-close"
                      append-icon="mdi-magnify"
                      label="Pesquisa"
                      v-model="search"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" md="2">
                    <v-select
                      clear-icon="mdi-close"
                      clearable
                      outlined
                      v-model="filter.status"
                      label="Status"
                      :items="statusOrcamento"
                      :item-text="(status) => status.value"
                      :item-value="(status) => status.key"
                      dense
                    >
                    </v-select>
                  </v-col>
                </v-row>
              </v-container>
            </template>
            <template v-slot:expanded-item="{ headers, item }">
              <td :colspan="headers.length" v-if="cotacoes">
                <venda-cotacoes-list
                  :cotacoes="
                    cotacoes.filter((cotacao) =>
                      item.cotacoes.map(cot => cot.id).includes(cotacao.id)
                    )
                  "
                />
              </td>
            </template>
            <template v-slot:item.status="{ value }">
          <status-orcamento  :status="value"></status-orcamento>
            </template>

            <template v-slot:item.servico="{ item, value }">
              <div class="text-center">
                {{ item.tipo_servico | formatTipoOrcamento }}
              </div>
            </template>
            <template v-slot:item.descricao="{ item, value }">
              <div class="text-center">
                {{ item.descricao }}
              </div>
            </template>

            <template v-slot:item.qtd_cotacoes="{ item, value }">
              <div>
                <v-badge
                  v-if="item.cotacoes && item.cotacoes.length > 0"
                  :value="2"
                  :content="(item.cotacoes && item.cotacoes.length )">
                </v-badge>
                <div v-else>Nenhuma</div>
              </div>
            </template>

            <template v-slot:item.data_solicitacao="{ item, value }">
              <div>
                {{ item.created_at | convertDataBR }} às
                {{ item.created_at | dataHoraMinuto }}
              </div>
            </template>

            <template v-slot:item.ultima_alteracao="{ item, value }">
              <div>
                {{ item.updated_at | convertDataBR }} às
                {{ item.updated_at | dataHoraMinuto }}
              </div>
            </template>

            <template v-slot:item.solicitado_por="{ item, value }">
              <div>
                {{ item.created_by | nomeSobrenome }}
              </div>
            </template>

            <template v-slot:item.actions="{ item }">
              <div class="d-flex">
                <v-btn
                  icon
                  color="transparent"
                  :to="{ name: 'orcamento', params: { id: item.id } }"
                >
                  <v-icon small color="primary" class=""
                    >mdi-open-in-new
                  </v-icon>
                </v-btn>
                <v-btn icon color="transparent" @click="openConfirmDIalog" :disabled="item.status != 'aberto'" :loading="loadingFinalizarOrcamento">
                  <v-icon small prepend-icon="" color="success">
                    mdi-check-bold
                  </v-icon>
                </v-btn>
                          <!-- DIALOG DE CONFIRMAÇÃO -->
                <div>
                  <v-dialog width="280px" persistent v-model="confirmDialog" :retain-focus="false" transition="dialog-bottom-transition">
                    <v-card style="border-radius: 10px;">
                      <v-card-actions class="py-0 px-0">
                        <v-container>
                          <v-row>
                            <v-col cols="12" class="text-center">
                              Deseja realmente <strong class="red--text">finalizar</strong> o orçamento?
                            </v-col>
                          </v-row>
                          <v-row>
                            <v-col cols="6" >
                              <v-btn block color="error" style="height: 45px;" class="rounded" :disabled="loadingFinalizarOrcamento" @click="closeConfirmDialog">
                                Não
                              </v-btn>
                            </v-col>
                            <v-col cols="6" >
                              <v-btn block color="success" style="height: 45px;" class="rounded" :loading="loadingFinalizarOrcamento" @click="finalizarOrcamento(item.id)">
                                Sim
                              </v-btn>
                            </v-col>
                          </v-row>
                        </v-container>
                      </v-card-actions>
                    </v-card>
                  </v-dialog>
                </div>
              </div>
            </template>
            
            <template v-slot:no-data>
              Nenhum orçamento solicitado para essa venda
            </template>

          </v-data-table>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import VendaCotacoesList from "@/components/venda/VendaCotacaoList";
import StatusOrcamento from "@/components/widgets/StatusOrcamento";
import CopyLinkField from "@/components/widgets/CopyLinkField";
import PreviewNewPdfOrcamento from "@/components/PDF/PreviewNewPdfOrcamento";

import STATUS_ORCAMENTO from "@/constants/statusOrcamento";
import OrcamentoMixin from "@/mixins/OrcamentoMixin";

export default {
  components: {
    VendaCotacoesList,
    CopyLinkField,
    StatusOrcamento,
    PreviewNewPdfOrcamento,
  },
  mixins: [OrcamentoMixin],
  props: {
    orcamentos: {
      type: Array,
      required: false,
      default: () => [],
    },
    loadingData: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  data() {
    return {
      orcamentosList: [],
      statusOrcamento: STATUS_ORCAMENTO,
      filter: {
        status: "",
      },
      loading: false,
      confirmDialog: false,
      search: "",
      headers: [
        {
          text: "ID Orçamento",
          align: "start",
          sortable: true,
          value: "id",
        },
        {
          text: "Status",
          value: "status",
        },
        {
          text: "Tipo de serviço",
          value: "servico",
        },
        {
          text: "Descrição",
          value: "descricao",
        },
        {
          text: "Quantidade de cotações",
          value: "qtd_cotacoes",
        },
        {
          text: "Data da Solicitação",
          value: "data_solicitacao",
        },
        {
          text: "Data última alteração",
          value: "ultima_alteracao",
        },
        {
          text: "Solicitado por",
          value:"solicitado_por"
        },
        {
          text: "Ações",
          value: "actions",
        },
      ],
    };
  },
  watch: {
    orcamentos: {
      immediate: true,
      deep: true,
      handler: function (orcamentosFromPros) {
        if (orcamentosFromPros) {
          this.orcamentosList = orcamentosFromPros;
        } else {
          this.orcamentosList = [];
        }
      },
    },
  },
  computed: {
    tableItems() {
      if (!this.filter.status) return this.orcamentosList;
      return this.orcamentosList.filter((x) => x.status === this.filter.status);
    },
    cotacoes() {
      if (!this.vendas) {
        return [];
      }
      return this.vendas.cotacoes?.filter(cotacao => !cotacao.archived_at);
    },
    vendas() {
      return this.$store.state.venda;
    },
  },
  async mounted() {
    await this.$store.dispatch("venda/fetchCotacoes");
  },

  methods: {
    openConfirmDIalog(){
      this.confirmDialog = true;
    },
    closeConfirmDialog(){
      this.confirmDialog = false;
    }
  },
};
</script>