function numPassageiros(perna){
    return 0 +
        perna.adultos +
        perna.criancas +
        perna.bebes
}

export {
    numPassageiros
}
