<template>
  <v-container v-if="contato">
    <v-form id="info-form">
      <v-row>
        <v-col cols="12" sm="6">
          <CopyTextField
            label="Nome"
            id="nome"
            :value="contato.nome"
            icon="mdi-account-outline"
          />
        </v-col>
        <v-col cols="12" sm="6">
          <CopyTextField
            label="Sobrenome"
            name="sobrenome"
            id="sobrenome"
            :value="contato.sobrenome"
            icon="mdi-account-outline"
          />
        </v-col>
      </v-row>

      <v-row>
        <v-col cols="12" sm="6">
          <CopyTextField
            label="CPF"
            id="cpf"
            mask="###.###.###-##"
            :value="contato.cpf"
            icon="mdi-card-account-details-outline"
          />
        </v-col>
        <v-col cols="12" sm="6">
          <v-autocomplete
            label="Empresa relacionada"
            id="empresas"
            name="empresas"
            v-model="contatoEmpresas"
            :items="empresas"
            item-text="nome"
            item-value="id"
            outlined
            clearable
            multiple
            prepend-inner-icon="mdi-domain"
          />
        </v-col>
      </v-row>

      <v-row>
        <v-col cols="12" sm="6">
          <CopyTextField
            label="CNH/ID"
            id="cnh"
            :value="contato.cnh"
            icon="mdi-card-account-details-outline"
            mask="###########"
          />
        </v-col>
        <v-col cols="12" sm="6">
          <SearchContato v-model="cliente" label="Indicação" />
          <input type="hidden" name="indicacao" :value="cliente?.id" />
        </v-col>
      </v-row>

      <v-row>
        <v-col cols="12" sm="6">
          <CopyTextField
            label="E-mail"
            id="email"
            :value="contato.email"
            icon="mdi-at"
          />
        </v-col>
        <v-col cols="12" sm="6">
          <TelefoneField v-model="telefone" :pais="telefone.pais" outlined />
        </v-col>
      </v-row>

      <v-row>
        <v-col cols="12" sm="4">
          <FormDatePicker
            v-model="models.nasc"
            outlined
            icon="mdi-cake"
            :dense="false"
            label="Data de Nascimento"
            name="data_nascimento"
          />
        </v-col>
        <v-col cols="12" sm="4">
          <v-select
            label="Gênero"
            id="genero"
            name="genero"
            :items="['Masculino', 'Feminino']"
            :value="genero"
            outlined
            prepend-inner-icon="mdi-gender-male-female"
          />
        </v-col>
        <v-col cols="12" sm="4">
          <v-select
            label="Tier"
            id="tier"
            name="tier"
            :items="['VIP', 'Tier 1', 'Tier 2']"
            :value="contato.tier"
            outlined
            clearable
            prepend-inner-icon="mdi-medal"
          />
        </v-col>
      </v-row>

      <v-checkbox
        label="Estrangeiro"
        id="Estrangeiro"
        v-model="contato.cliente_estrangeiro"
      />
    </v-form>
  </v-container>
</template>

<script>
import CopyTextField from "@/components/widgets/CopyTextField.vue";
import SearchContato from "@/components/widgets/SearchContato.vue";
import TelefoneField from "@/components/widgets/TelefoneField.vue";
import FormDatePicker from "@/components/widgets/FormDatePicker.vue";
import skylerDate from "@/helpers/Date/date";

export default {
  name: "Info",

  components: {
    CopyTextField,
    SearchContato,
    TelefoneField,
    FormDatePicker,
  },

  data() {
    return {
      cliente: 0,
      telefone: {},
      contatoEmpresas: [],
      models: { nasc: null },
    };
  },

  created() {
    this.initialize();
    const unwatch = this.$watch("contato", () => {
      this.initialize();
      unwatch();
    });
  },

  computed: {
    genero() {
      if (!this.contato.genero) return "";
      return this.contato.genero == "M" ? "Masculino" : "Feminino";
    },

    empresas() {
      return this.$store.state.global.empresas;
    },

    contato() {
      return this.$store.getters["contato/contato"];
    },
  },

  methods: {
    initialize() {
      if (!this.contato) return;
      if (this.contato.data_nascimento)
        this.models.nasc = skylerDate.parseISOtoUTCDate(
          this.contato?.data_nascimento
        );
      this.telefone = this.contato?.telefone;
      this.cliente = this.contato?.indicacao;
      this.contatoEmpresas = this.contato?.empresas;
    },
  },
};
</script>