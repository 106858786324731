<template>
  <v-card elevation="0">
    <v-card-title>Informações da solicitação</v-card-title>
    <v-card-text>
      <v-container fluid>
        <v-row>
          <v-col>
            <v-field-value label="Status">
              <v-chip
                small
                :color="orcamento.status === 'aberto' ? '#4caf50' : '#f44336'"
                :text-color="orcamento.status === 'aberto' ? 'black' : 'white'"
                >{{ orcamento.status[0].toUpperCase() + orcamento.status.substring(1) }}</v-chip
              >
            </v-field-value>
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <v-field-value label="Solicitado em">
              {{ orcamento.created_at | convertDataBR }} - há
              {{ orcamento.created_at | diasAtras }} dia(s)
            </v-field-value>
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <v-field-value label="Solicitado por">
              {{ orcamento.created_by | nomeSobrenome }}
            </v-field-value>
          </v-col>
        </v-row>
        <v-row v-if="orcamento.status == 'fechado'">
          <v-col>
            <v-field-value label="Fechado por">
              {{ orcamento.fechado_por | nomeSobrenome }}
            </v-field-value>
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <v-field-value label="Tipo de serviço">
              {{ orcamentoTipoServico(orcamento) }}
            </v-field-value>
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <v-field-value label="Resumo" />
          </v-col>
        </v-row>
      </v-container>
      <orcamento-resumido-voo
        v-if="orcamento.tipo === 'voo'"
        :voo="orcamento.voo"
      >
        <template v-slot:actions>
          <slot name="actions" />
        </template>
      </orcamento-resumido-voo>
    </v-card-text>
  </v-card>
</template>
<script>
import OrcamentoResumidoVoo from "@/components/Orcamento/Tipos/Voo/OrcamentoResumidoVoo";
import VFieldValue from "@/components/widgets/VFieldValue";
import orcamentoMixin from "@/mixins/OrcamentoMixin";

export default {
  components: { VFieldValue, OrcamentoResumidoVoo },
  mixins: [orcamentoMixin],
  props: {
    orcamento: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {};
  },
};
</script>