<template>
  <div>
    <v-dialog v-model="dialog">
      <v-card>
        <v-card-title class="text-h5"> Aprovar? </v-card-title>
        <v-card-text>
          <v-container>
            <v-row> Entre o CPF da conta de aprovação. </v-row>
            <v-row v-if="porMilhas">
              Programa de fidelidade:
              {{ subcotacao?.programa_fidelidade?.nome }}
            </v-row>
          </v-container>
        </v-card-text>
        <v-container>
          <v-row>
            <v-col>
              <v-form v-model="valid">
                <v-row>
                  <v-card
                    v-for="(dataPoint, idx) in form"
                    :key="idx"
                    class="elevation-5 px-6 my-6 w-full"
                  >
                    <v-row align="center">
                      <v-col cols="auto">
                        <v-btn @click="() => toggleCustomConta(idx)" icon small>
                          <v-icon>mdi-account-plus</v-icon>
                        </v-btn>
                      </v-col>
                      <v-col cols="auto">
                        <v-autocomplete
                          v-model="dataPoint.conta_fidelidade"
                          :items="filteredContasFidelidade"
                          label="Conta Fidelidade"
                          item-value="id"
                          :disabled="
                            dataPoint.customConta || dataPoint.mesma_conta
                          "
                          :item-text="
                            (conta) =>
                              conta?.titular?.nome +
                              conta?.titular?.sobrenome +
                              conta?.programa?.nome +
                              conta?.provedor?.nome
                          "
                        >
                          <template v-slot:selection="{ item }">
                            {{ item | formatContaFidelidade }}
                          </template>
                          <template v-slot:item="{ item }">
                            {{ item | formatContaFidelidade }}
                          </template>
                        </v-autocomplete>
                      </v-col>
                      <v-col>
                        <v-autocomplete
                          v-model="dataPoint.contato"
                          :items="contatos"
                          label="Passageiro Vinculado"
                          item-value="id"
                          :item-text="
                            (cont) => `${cont.nome} ${cont.sobrenome}`
                          "
                        >
                          <template v-slot:selection="{ item }">
                            {{ item | nomeSobrenome }}
                          </template>
                          <template v-slot:item="{ item }">
                            {{ item | nomeSobrenome }}
                          </template>
                        </v-autocomplete>
                      </v-col>
                      <v-col cols="auto">
                        <vuetify-money
                          v-if="porMilhas"
                          v-model.number="dataPoint.valor"
                          label="Montante"
                          required
                          type="number"
                          v-bind:options="{ precision: 0, locale: 'pt-BR' }"
                        >
                        </vuetify-money>
                      </v-col>
                      <v-col>
                        <v-moeda-field
                          v-if="porMilhas"
                          label='Valor de "compra" (1000 milhas)'
                          v-model="dataPoint.valor_compra"
                        />
                      </v-col>
                      <v-col cols="auto">
                        <v-btn
                          icon
                          class="center-vertical"
                          @click="excludeLine(idx)"
                        >
                          <v-icon color="error"> mdi-close </v-icon>
                        </v-btn>
                      </v-col>
                    </v-row>
                    <v-row v-if="idx >= 1"
                      ><v-checkbox
                        dense
                        label="Continuar com a mesma autorização da primeira conta."
                        name="mesma_conta_fidelidade"
                        :value="mesma_conta"
                        v-model="dataPoint.mesma_conta"
                      ></v-checkbox
                    ></v-row>

                    <v-row v-if="dataPoint.customConta">
                      <v-col>
                        <v-autocomplete
                          v-model="dataPoint.provedor_milhas"
                          :items="provedores"
                          label="Provedor de Milhas"
                          item-value="id"
                          item-text="nome"
                          :disabled="dataPoint.mesma_conta"
                        >
                          <template v-slot:selection="{ item }">
                            {{ item.nome }}
                          </template>
                          <template v-slot:item="{ item }">
                            {{ item.nome }}
                          </template>
                        </v-autocomplete>
                      </v-col>
                      <v-col>
                        <template>
                          <v-autocomplete
                            v-model="dataPoint.programa_fidelidade"
                            :items="
                              subcotacao.programa_fidelidade
                                ? [subcotacao.programa_fidelidade]
                                : programasFidelidade.filter(
                                      (programa) =>
                                       programa.nome === 'LATAM Pass' || programa.nome === 'Azul'
                                    )
                                  
                            "
                            label="Programa de Fidelidade"
                            item-value="id"
                            item-text="nome"
                            :disabled="dataPoint.mesma_conta"
                          >
                            <template v-slot:selection="{ item }">
                              {{ item.nome }}
                            </template>
                            <template v-slot:item="{ item }">
                              {{ item.nome }}
                            </template>
                          </v-autocomplete>
                        </template>
                      </v-col>
                      <v-col>
                        <v-text-field
                          v-model="dataPoint.login"
                          label="Login"
                          :disabled="dataPoint.mesma_conta"
                        ></v-text-field>
                      </v-col>
                      <v-col>
                        <v-text-field
                          v-model="dataPoint.senha"
                          label="Senha"
                          :disabled="dataPoint.mesma_conta"
                        ></v-text-field>
                      </v-col>
                      <v-col>
                        <v-text-field
                          v-model="dataPoint.cpf"
                          label="CPF"
                          v-mask="masks.cpf"
                          :rules="[rules.cpf]"
                          :disabled="dataPoint.mesma_conta"
                        ></v-text-field>
                      </v-col>
                      <v-col>
                        <v-text-field
                          v-if="!porMilhas"
                          label="Deságio de compra (%)"
                          type="number"
                          v-model="dataPoint.desagio_compra"
                          :disabled="dataPoint.mesma_conta"
                        />
                      </v-col>
                      <v-col>
                        <v-checkbox
                          v-model="dataPoint.milheiro_emite"
                          label="Milheiro Emite"
                          :disabled="dataPoint.mesma_conta"
                        ></v-checkbox>
                      </v-col>
                    </v-row>
                  </v-card>
                </v-row>
                <v-row>
                  <v-btn icon class="center-vertical" @click="addLine">
                    <v-icon> mdi-plus </v-icon>
                  </v-btn>
                </v-row>
              </v-form>
            </v-col>
          </v-row>
          <v-row>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="blue darken-1" text @click="close">
                Cancelar
              </v-btn>
              <v-btn
                color="blue darken-1"
                text
                :disabled="loading"
                @click="check"
              >
                Aprovar
              </v-btn>
            </v-card-actions>
          </v-row>
        </v-container>
      </v-card>
    </v-dialog>
    <v-dialog width="600px" persistent v-model="openBestPracticesMilhasDialog">
      <v-card>
        <v-card-title>Seguiu o manual de boas práticas de milhas?</v-card-title>
        <v-card-text>
          <div v-if="showReasonInputMilhas">
            <v-textarea
              label="Motivo*"
              v-model="reasonForNoBestPracticesMilhas"
              :rules="[rules.noBestPracticesMilhasRule]"
              outlined
              counter
              maxlength="50"
            />
          </div>
        </v-card-text>
        <v-card-actions>
          <v-container>
            <v-row>
              <v-col align="left">
                <v-btn color="primary" @click="closeBestPracticesMilhasDialog">
                  <v-icon> mdi-exit </v-icon>
                  Sair
                </v-btn>
              </v-col>
              <v-col align="right">
                <v-btn
                  color="error"
                  v-if="showBestPracticesMilhasCancelButton"
                  @click="showReason"
                  class="mr-2"
                >
                  Não
                </v-btn>
                <v-btn
                  color="success"
                  v-if="showBestPracticesMilhasConfirmButton"
                  :loading="loadingBoasPraticas"
                  @click="confirmBestPracticesMilhas"
                >
                  Sim
                </v-btn>
                <v-btn
                  color="success"
                  :disabled="!confirmNoBestPracticesMilhas"
                  :loading="loadingBoasPraticas"
                  v-if="showNoBestPracticesMilhasConfirmButton"
                  @click="confirmBestPracticesMilhas"
                >
                  Confirmar
                </v-btn>
              </v-col>
            </v-row>
          </v-container>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
import MASKS from "@/constants/masks";
import { validateCPF } from "@/helpers/identificadores";
import VMoedaField from "@/components/widgets/VMoedaField";

export default {
  components: { VMoedaField },

  props: {
    form: {
      type: Array,
      default: () => [],
    },
    value: {
      type: Boolean,
      default: false,
    },
    contasFidelidade: {
      type: Array,
      default: () => [],
    },
    programasFidelidade: {
      type: Array,
      default: () => [],
    },
    contatos: {
      type: Array,
      default: () => [],
    },
    provedores: {
      type: Array,
      default: () => [],
    },
    porMilhas: {
      type: Boolean,
      default: () => false,
    },
    subcotacao: {
      type: Object,
      default: () => {},
    },
    openBestPracticesMilhasDialog: {
      type: Boolean,
      default: false,
    },
  },

  data: () => ({
    loadingBoasPraticas: false,
    loading: false,
    dialog: false,
    valid: false,
    masks: MASKS,
    rules: {
      cpf: (value) => !value || validateCPF(value) || "CPF não é valido",
      noBestPracticesMilhasRule: (string) =>
        (string.length >= 10 && string.length <= 50) ||
        "Justificativa deve ter entre 10 e 50 caracteres.",
    },
    bestPracticesMilhas: true,
    reasonForNoBestPracticesMilhas: "",
    showReasonInputMilhas: false,
    showNoBestPracticesMilhasConfirmButton: false,
    showBestPracticesMilhasCancelButton: true,
    showBestPracticesMilhasConfirmButton: true,
    contaCustomizada: {
      count: 1,
      0: false,
    },
  }),

  computed: {
    filteredContasFidelidade() {
      let filtered = this.contasFidelidade;
      filtered = filtered.filter(
        (conta) => conta?.provedor?.tipo_servico === "milhas"
      );
      if (this.porMilhas)
        filtered = filtered.filter(
          (conta) =>
            conta?.programa?.id === this?.subcotacao?.programa_fidelidade?.id
        );
      else
        filtered = filtered.filter(
          (conta) => conta?.programa?.nome === "LATAM Pass"
        );
      for (let i = 0; i < this.form.length; i++) {
        if (this.form[i]) {
          if (this.form[i].mesma_conta && !this.contaCustomizada[0]) {
            const contaFidelidade = this.form[0].conta_fidelidade;
            this.form[i].conta_fidelidade = contaFidelidade;
          } else if (this.form[i].mesma_conta && this.contaCustomizada[0]) {
            this.form[i].conta_fidelidade = null;

            const cusConta = this.form[0].customConta;
            this.form[i].customConta = cusConta;

            const ProvedorMilhas = this.form[0].provedor_milhas;
            this.form[i].provedor_milhas = ProvedorMilhas;

            const ProgramaFidelidade = this.form[0].programa_fidelidade;
            this.form[i].programa_fidelidade = ProgramaFidelidade;

            const Login = this.form[0].login;
            this.form[i].login = Login;

            const Senha = this.form[0].senha;
            this.form[i].senha = Senha;

            const CPF = this.form[0].cpf;
            this.form[i].cpf = CPF;

            const MilheiroEmite = this.form[0].milheiro_emite;
            this.form[i].milheiro_emite = MilheiroEmite;
          }
        }
      }
      return filtered;
    },
    confirmNoBestPracticesMilhas() {
      return (
        this.reasonForNoBestPracticesMilhas.length <= 50 &&
        this.reasonForNoBestPracticesMilhas.length >= 10
      );
    },
  },

  mounted() {
    this.dialog = this.value;
  },

  watch: {
    dialog(val) {
      this.$emit("input", val);
    },
    value(val) {
      this.dialog = this.value;
    },
  },

  methods: {
    close() {
      this.$emit("input", false);
    },
    check() {
      //Checando se todos as contas foram preenchidas
      this.form.forEach((item, idx) => {
        if (!item.conta_fidelidade && !item.customConta) {
          this.$root.vtoast.show({
            message: "Preencha a(s) conta(s) Fidelidade!",
            color: "error",
            icon: "mdi-close",
          });
          return;
        }
        if (item.valor_compra?.valor === 0) {
          this.$root.vtoast.show({
            message: "Valor de compra deve ser diferente de 0!",
            color: "error",
            icon: "mdi-close",
          });
          return;
        }
        if (
          item.customConta &&
          (!item.provedor_milhas ||
            !item.programa_fidelidade ||
            !item.login ||
            !item.login ||
            !item.cpf)
        ) {
          this.$root.vtoast.show({
            message:
              "Preencha todos os campos da(s) conta(s) Fidelidade de Terceiros!",
            color: "error",
            icon: "mdi-close",
          });
          return;
        }
      });
      // A priori o loading resolve as issue #584 e #725
      this.loading = true;
      this.$emit("check");

      setTimeout(() => {
        this.loading = false;
      }, 1500);
    },
    confirmBestPracticesMilhas() {
      this.loadingBoasPraticas = true;
      this.$emit(
        "confirmBestPracticesMilhas",
        this.bestPracticesMilhas,
        this.reasonForNoBestPracticesMilhas
      );
      setTimeout(() => {
        this.loadingBoasPraticas = false;
      }, 1500);
    },
    addLine() {
      this.$emit("addLine");
      this.contaCustomizada.count += 1;
      this.contaCustomizada[this.contaCustomizada.count - 1] = false;
    },
    excludeLine(val) {
      this.$emit("excludeLine", val);
      this.contaCustomizada[val] = false;
      this.contaCustomizada.count -= 1;
    },
    toggleCustomConta(val) {
      this.$emit("toggleCustomConta", val);
      this.contaCustomizada[val] = !this.contaCustomizada[val];
    },
    openBestPracticesMilhasDialogFunction() {
      this.openBestPracticesMilhasDialog = true;
    },
    showReason() {
      this.bestPracticesMilhas = false;
      this.showReasonInputMilhas = !this.showReasonInputMilhas;
      this.showBestPracticesMilhasConfirmButton =
        !this.showBestPracticesMilhasConfirmButton;
      this.showNoBestPracticesMilhasConfirmButton =
        !this.showNoBestPracticesMilhasConfirmButton;
      this.showBestPracticesMilhasCancelButton = false;
    },
    closeBestPracticesMilhasDialog() {
      this.bestPracticesMilhas = true;
      this.openBestPracticesMilhasDialog = false;
      this.showReasonInputMilhas = false;
      this.showBestPracticesMilhasConfirmButton = true;
      this.showNoBestPracticesMilhasConfirmButton = false;
      this.showBestPracticesMilhasCancelButton = true;
      this.reasonForNoBestPracticesMilhas = "";
      this.$emit("closeBestPracticesMilhasDialog");
    },
  },
};
</script>
