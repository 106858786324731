import moment from "moment/moment"

// String dd/mm/aaaa
function parseUTCDate(dateString) {
    const dateFormat = "DD/MM/YYYY";
    const date = moment.utc(dateString, dateFormat);
    return date.toDate();
}

// String ISO
function parseISOtoUTCDate(dateString) {
    const dateFormat = "YYYY-MM-DD";
    const date = moment.utc(dateString, dateFormat);
    return date.toDate();
}

function dateToString(date) {
    if (!date) return null
    const momentData = moment.utc(date, 'DD/MM/YYYY')
        .locale('en-ca')
        .format('L')
    return momentData
}

function isDateValid(date) {
    if (!date) return false
    return moment(date).isValid()
}

function dateToUTCString(date) {
    return moment.utc(date).format('YYYY-MM-DD');
}

export default {
    parseUTCDate,
    parseISOtoUTCDate,
    dateToString,
    dateToUTCString,
    isDateValid
}