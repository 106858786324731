<template>
  <v-chip
      label
      :color="corStatus"
      :text-color="corTextoStatus">
    {{ statusText }}
  </v-chip>
</template>

<script>

import STATUS_ORCAMENTO from "@/constants/statusOrcamento"


const cores = STATUS_ORCAMENTO.reduce(
  (cum, curr) => ({
      ...cum, 
      [curr.key]: {
        ...curr
      }
    }), {}
)

export default {
  props: {
    status: {
      type: String,
      default: ""
    }
  },
  computed: {
    corStatus() {
      const status = this.status
      if (status in cores) {
        return cores[status].background
      }
      return "grey";
    },
    corTextoStatus() {
      const status = this.status
      if (status in cores) {
        return cores[status].text
      }
      return "white";
    },
    statusText(){
      let statusObj = STATUS_ORCAMENTO.find(s => s.key === this.status)

      if(!statusObj) return this.status
      return statusObj.value
    }
  }
}
</script>
