import { get, post, paginate, filter, convertToFormData,postFormData } from '../modules'

export default {
    getServicosDetalhado(page, filters=[]) {
        const paginatedURL = paginate("api/servicos/detailed/", page)
        const filteredURL = filter(paginatedURL, filters)

        return get(filteredURL);
    },

    getServico(id) {
        return get(`/api/servicos/${id}`);
    },

    getServicosValidacaoEmissao(page=1) {
        const url = 'api/servicos/validation_emissao/'
        const paginatedURL = paginate(url, page)
        return get(paginatedURL);
    },
    getValidation(){
        return get('api/servicos/validation/')
    },
    getServicoPagamento(page = 1) {
        const paginatedUrl = paginate('api/servicos/pagamento/', page)
        return get(paginatedUrl)
    },
    getServicoValidacoes(id) {
        return get(`api/servicos/${id}/validacoes/`)
    },
    faturarServico(servicoId, data = {}){
        return post(`api/servicos/${servicoId}/faturar/`, data)
    },
    cancelarServico(servicoId, data){
        return post(`api/servicos/${servicoId}/cancel/`, data)
    },
       // PDF fATURA
       async postPdfFatura(data) {
        const formData = convertToFormData(data);
        return postFormData(`/api/pdf-fatura/pdffatura/`, formData);
      },
}
