import AZU from './AZU.png'
import AA from './AA.png'
import BA from './BA.png'
import EK from './EK.png'
import G3 from './G3.png'
import IB from './IB.png'
import LA from './LA.png'
import LH from './LH.png'
import LX from './LX.png'
import QR from './QR.png'
import RK from './RK.png'
import RV from './RV.png'
import TK from './TK.png'
import TP from './TP.png'
import UA from './UA.png'
import VS from './VS.png'
import CMP from './CMP.jpg'

export default {
    AZU: AZU,
    AA: AA,
    BA: BA,
    EK: EK,
    G3: G3,
    IB: IB,
    LA: LA,
    LH: LH,
    LX: LX,
    QR: QR,
    RK: RK,
    RV: RV,
    TK: TK,
    TP: TP,
    UA: UA,
    VS: VS,
    CMP: CMP,
}