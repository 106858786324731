import api from "@/api/api";
import {
    converteValorFinanceiroBRL
} from '@/helpers/financeiro'
import {
    arraySameElements
} from "@/helpers/algos"
import { arredondarNumero } from '@/helpers/numeros'

import { formatApiError } from "@/helpers/apiErrors"
import precificacaoJuros from "@/constants/precificacaoJuros";

// valor base de milha
const VALOR_MILHA = 25.0;

var cotacaoMixin = {
    data() {
        return {
            loadingSalvarCotacao: false,
            loadingDeleteCotacao: false,
        }
    },
    async mounted() {
        await this.fetchMilhas();
    },
    computed: {
        tableMilhas() {
            return this.$store.state.global.milhas.filter(milha => milha.tipo==="preco_de_venda")
        },
        tableMilhasCusto() {
            return this.$store.state.global.milhas.filter(milha => milha.tipo==="custo")
        }
    },
    methods: {

        ////////////////////////////////////////////////////////////////
        //Chamdas de API
        ////////////////////////////////////////////////////////////////

        async salvarCotacao(orcamentoId, cotacao) {
            this.loadingSalvarCotacao = true;
            const cotacaoId = cotacao.id;
            if (cotacaoId) { // Update
                const res = await api.updateCotacao(cotacaoId, cotacao).then(res => {
                    this.$root.vtoast.show({ message: "Cotação atualizada com sucesso!", color: 'success' })
                    return res.data;
                }).catch(err => {
                    this.loadingSalvarCotacao = false;
                    this.$root.vtoast.show({ message: "Erro ao atualizar cotação.", color: "error" });
                    window.alert(formatApiError(err))
                })
                this.loadingSalvarCotacao = false;
                return res;
            } else { // Criar
                const res = await api.createOrcamentoCotacao(orcamentoId, cotacao).then(res => {
                    this.$root.vtoast.show({ message: "Cotação criada com sucesso!", color: 'success' })
                    return res.data;
                }).catch(err => {
                    this.loadingSalvarCotacao = false;
                    this.$root.vtoast.show({ message: "Erro ao criar cotação.", color: "error" });
                    window.alert(formatApiError(err))
                })
                this.loadingSalvarCotacao = false;
                return res;
            }
        },
        async deleteCotacao(cotacao) {
            this.loadingDeleteCotacao = true;
            const cotacaoId = cotacao.id;

            const res = await api.deleteCotacao(cotacaoId).then(res => {
                this.$root.vtoast.show({ message: "Cotação arquivada com sucesso!", color: 'success' })
                return { success: true };
            }).catch(err => {
                this.loadingDeleteCotacao = false;
                this.$root.vtoast.show({ message: "Erro ao arquivar cotação.", color: "error" });
            })

            this.loadingDeleteCotacao = false;
            return res;
        },

        ////////////////////////////////////////////////////////////////
        // Métodos milhas 
        ////////////////////////////////////////////////////////////////
        getMilheiro(programaFidelidade, quantidadeMilhas, operadoPor, classe, piso = false) {

            if (!programaFidelidade) return 0
            if (!quantidadeMilhas) return VALOR_MILHA
            const programaID = typeof programaFidelidade === 'object' ? programaFidelidade.id : programaFidelidade

            const milheirosPorPrograma = this.tableMilhas.filter(e => e.programa === programaID)
            const milheirosPorOperacao = milheirosPorPrograma.filter(e => operadoPor ? e.operado_por === operadoPor : true)
            const milheirosPorClasse = milheirosPorOperacao.filter(e => classe ? e.classe === classe : true)

            const milheiros = milheirosPorClasse.length ? milheirosPorClasse :
                milheirosPorOperacao.length ? milheirosPorOperacao :
                    milheirosPorPrograma


            if (!milheiros.length) return VALOR_MILHA

            const custoProprio = this.getMilheiroCustoProprio(programaFidelidade) 
            const custoTerceiros = this.getMilheiroCustoTerceiros(programaFidelidade)
            if (piso)
                return custoProprio > custoTerceiros ? custoProprio : custoTerceiros

            const precos = milheiros[0]?.precos
            const ranges = [5_000, 20_000, 50_000, 100_000, 200_000, Infinity]
            return precos.filter((_, idx) => quantidadeMilhas <= ranges[idx])[0]
        },

        subcotacaoGetValorMilha(subcotacao) {
            if (!subcotacao) return VALOR_MILHA

            subcotacao = { ...subcotacao, ...subcotacao.dados }

            if (subcotacao.tipo_cotacao !== "milha") return null
            if (subcotacao.milheiro) return parseFloat(subcotacao.milheiro)

            const programas = subcotacao.programas_fidelidade
            const qtdMilhas = subcotacao.quantidade_milhas
            const milha = this.getMilheiro(programas, qtdMilhas)

            if (!milha) return VALOR_MILHA

            return parseFloat(milha.preco)
        },

        cotacaoGetValorMilha(cotacao) {
            if (!cotacao.cotacao_voo) return []
            return cotacao.cotacao_voo.subcotacoes.map(
                subcot => this.subcotacaoGetValorMilha(subcot)
            ).filter(subcot => subcot)
        },

        async fetchMilhas() {
            await this.$store.dispatch('global/fetchMilhas').catch(err => {
                this.$root.vtoast.show({ message: err.message, type: 'error' });
            });
        },

        ajusteEfetivo(cotacao, subcotacao) {
            if (!cotacao || !cotacao.cotacao_voo || !subcotacao) return null;

            //filtrando dados
            const subcot = { ...subcotacao, ...subcotacao.dados }
            const subcots = cotacao.cotacao_voo.subcotacoes.map(subcot => ({ ...subcot, ...subcot.dados }))

            const ajuste = this.cotacaoValorAjuste(cotacao)
            if (subcots.every(sub => ['pagante', 'wallet'].includes(sub.tipo_cotacao))) {
                // caso todos pagantes distribuir ponderado no valor do bilhete
                const totalBilhetes = subcots.reduce(
                    (sum, value) => sum + this.converteValorFinanceiroBRL(value.custo_bilhete)
                    , 0)
                return (this.converteValorFinanceiroBRL(subcot.custo_bilhete) / totalBilhetes * ajuste).toFixed(2)
            } else {
                const subcotacoesMilhas = subcots.filter(
                    subcot => subcot.tipo_cotacao === "milha"
                )
                const valorTotalMilhas = subcotacoesMilhas.reduce(
                    (sum, value) => sum + parseFloat(value.quantidade_milhas) / 1000 * this.subcotacaoGetValorMilha(value)
                    , 0)
                if (subcot.tipo_cotacao !== 'milha') return "0.00"
                return (parseFloat(subcot.quantidade_milhas) / 1000 * this.subcotacaoGetValorMilha(subcot) / valorTotalMilhas * ajuste).toFixed(2)
            }
        },

        milheiroEfetivo(cotacao, subcotacao) {
            const subcot = { ...subcotacao, ...subcotacao.dados }
            const ajuste = parseFloat(this.ajusteEfetivo(cotacao, subcot))
            const valorMilha = this.subcotacaoGetValorMilha(subcot)
            const qtdMilhas = parseFloat(subcot.quantidade_milhas)
            const milheiroEfetivo = valorMilha + ajuste / (qtdMilhas / 1000)
            if (Number.isNaN(milheiroEfetivo)) return null
            return milheiroEfetivo.toFixed(2)
        },

        ////////////////////////////////////////////////////////////////
        // Milheiro Custo
        ////////////////////////////////////////////////////////////////
        getMilheiroCustoProprio(programaFidelidade) {
            if (!programaFidelidade) return 0
            const programaID = typeof programaFidelidade === 'object' ? programaFidelidade.id : programaFidelidade

            const milheiros= this.tableMilhasCusto.filter(e => e.programa === programaID)

            if (!milheiros?.length) return VALOR_MILHA
            return milheiros[0].custo_proprio
        },
        getMilheiroCustoTerceiros(programaFidelidade) {
            if (!programaFidelidade) return 0
            const programaID = typeof programaFidelidade === 'object' ? programaFidelidade.id : programaFidelidade

            const milheiros= this.tableMilhasCusto.filter(e => e.programa === programaID)

            if (!milheiros.length) return VALOR_MILHA
            return milheiros[0].custo_terceiros
        },

        lucroMilheiroCusto(milheiroFixado, milheiroProprio, milheiroTerceiros, quantidadeMilhas) {
            return {lucroProprio: quantidadeMilhas*(milheiroFixado-milheiroProprio)/1000, lucroTerceiros: quantidadeMilhas*(milheiroFixado-milheiroTerceiros)/1000}
        },


        ////////////////////////////////////////////////////////////////
        // Métodos formatação
        ////////////////////////////////////////////////////////////////
        descricaoCotacao(cotacao) {
            const tipoServico = this.cotacaoTipoServico(cotacao).toLowerCase();
            switch (tipoServico) {
                case "hotel":
                    return `Descrição de serviço do tipo 'hotel'`;
                case "outro":
                    return `Descrição de Serviço 'outro'`;
                case "carro":
                    return `Descrição de serviço do tipo 'carro'`;
                case "vôo":
                    return `Descrição de serviço do tipo vôo`;
                default:
                    return "Serviço não encontrado.";
            }
        },

        cotacaoLucroFormatadoPercentagem(cotacao) {
            const lucro = this.cotacaoLucro(cotacao);
            if (lucro === 0) return "0%";
            if (lucro === Number.NaN) return "0%";

            const valueStr = lucro.toLocaleString("pt-BR", {
                minimumFractionDigits: 2,
                maximumFractionDigits: 2
            })
            return `${valueStr}%`;
        },

        ////////////////////////////////////////////////////////////////
        // Métodos cálculo
        ////////////////////////////////////////////////////////////////

        calculateCustoTotalCotacaoCarro(cotacao_carro) {
            if (!cotacao_carro) return 0.0;
            const custoDiarias = converteValorFinanceiroBRL(cotacao_carro.custo_diaria) * Number(cotacao_carro.diarias || 0)
            const rav = converteValorFinanceiroBRL(cotacao_carro.rav);
            return rav + custoDiarias + this.converteValorFinanceiroBRL(cotacao_carro.custo_extras)
        },

        calculateCustoTotalCotacaoHotel(cotacao_hotel) {
            if (!cotacao_hotel) return 0.0;
            const checkMinMaxDiariasPreenchido = cotacao_hotel.diarias_max && cotacao_hotel.diarias_min;
            const diarias = cotacao_hotel.diarias && checkMinMaxDiariasPreenchido ?  Math.floor((Number(cotacao_hotel.diarias_min) + Number(cotacao_hotel.diarias_max)) / 2) : cotacao_hotel.diarias;
            const custoDiarias = converteValorFinanceiroBRL(cotacao_hotel.custo_diaria) * Number(diarias || 0)
            const rav = converteValorFinanceiroBRL(cotacao_hotel.rav)
            return rav + custoDiarias + this.converteValorFinanceiroBRL(cotacao_hotel.custo_extras)
        },

        calculateCustoTotalOutro(cotacao_outro) {
            if (!cotacao_outro) return 0.0;
            const custoTotal = converteValorFinanceiroBRL(cotacao_outro.custo_total);
            const rav = converteValorFinanceiroBRL(cotacao_outro.rav);
            return custoTotal + rav
        },

        calculateCustoTotalCotacaoVoo(cotacao_voo) {
            if (!cotacao_voo) return 0.0;
            let subcotacoes = cotacao_voo.subcotacoes;
            let total = 0.0;
            // filtra campo dados se existir
            subcotacoes = subcotacoes.map(e => ({ ...e, ...e.dados }))
            subcotacoes.forEach(subcotacao => total += this.subcotacaoVooCustoTotal(subcotacao));
            return total;
        },

        converteValorFinanceiroBRL(valorFinanceiro) {
            return converteValorFinanceiroBRL(valorFinanceiro)
        },
        // calculoValorBase(emissao_id, servico, pagamentos = null){
        //     if (!servico.cotacao) return 0.0;
        //     // if(!taxa) taxa = 0.0;
        //     let taxa = 0.0;
        //     let total = 0.0;
        //     let valorPagamento = 0.0;
        //     if(pagamentos) {
        //         pagamentos = pagamentos.map((pagamento) => {
        //             if(pagamento?.servico?.emissoes[0]){
        //                 return pagamento.servico.emissoes[0].id === emissao_id;
        //             }
        //             return false;
        //         })
        //         valorPagamento = pagamentos.reduce(function(accumulator, currentValue) {
        //             return accumulator + converteValorFinanceiroBRL(currentValue.valor_pagamento)
        //         },0);
        //         taxa = pagamentos.reduce(function(accumulator,currentValue){
        //             if(currentValue.taxa_meio_pagamento){
        //                 return accumulator + currentValue.taxa_meio_pagamento
        //             } 
        //             return accumulator + 0
        //         },0)
        //     } 

        //     if (servico.cotacao.cotacao_hotel) {
        //         let precificadores = this.custosServicoHotelPrecificadores(servico)
        //         console.log(precificadores)
        //         total = (0.8914) * ((valorPagamento + precificadores["RAV via fornecedor"]) - (precificadores["Custo total"] + taxa))
        //     } else if (servico.cotacao.cotacao_outro) {
        //         let precificadores = this.custosServicoOutroPrecificadores(servico)
        //         total = (0.8914) * ((valorPagamento + precificadores["RAV via fornecedor"]) - (precificadores["Custo total"] + taxa))
        //     } else if (servico.cotacao.cotacao_carro) {
        //         let precificadores = this.custosServicoCarroPrecificadores(servico)
        //         total = (0.8914) * ((valorPagamento + precificadores["RAV via fornecedor"]) - (precificadores["Custo total"] + taxa))
        //     } else if (servico.cotacao.cotacao_voo) {
        //         let precificadores = this.custosServicoVooSubcotacaoPrecificadores(servico.cotacao.cotacao_voo.subcotacoes[0])
        //         total = (0.8914) * ((valorPagamento) - (precificadores["Custo total"] + taxa))
        //     } else {
        //         return Number.NaN;
        //     }
        //     return total;
        // },
        calculoValorBase(emissao_id, servico, pagamentos = null){
            if (!servico.cotacao) return 0.0;
            let taxa = 0.0;
            let total = 0.0;
            let valorPagamento = 0.0;
            if(pagamentos) {
                const pagamentosPorEmissao = {};

                pagamentos.forEach(pagamento => {
                    const emissaoId = pagamento.servico?.emissoes[0]?.id;
                    if (emissaoId === emissao_id) {
                        if (!pagamentosPorEmissao[emissaoId]) {
                            pagamentosPorEmissao[emissaoId] = [];
                        }

                        pagamentosPorEmissao[emissaoId].push(pagamento);

                        valorPagamento = pagamentosPorEmissao[emissao_id].reduce((accumulator, currentValue) => {
                            return accumulator + converteValorFinanceiroBRL(currentValue.valor_pagamento);
                        }, 0);
            
                        taxa = pagamentosPorEmissao[emissao_id].reduce((accumulator, currentValue) => {
                            return currentValue.taxa_meio_pagamento ? accumulator + currentValue.taxa_meio_pagamento : accumulator;
                        }, 0);

                        if (servico.cotacao.cotacao_hotel) {
                            let precificadores = this.custosServicoHotelPrecificadores(servico)
                            console.log(precificadores)
                            total = (0.8914) * ((valorPagamento + precificadores["RAV via fornecedor"]) - (precificadores["Custo total"] + taxa))
                        } else if (servico.cotacao.cotacao_outro) {
                            let precificadores = this.custosServicoOutroPrecificadores(servico)
                            total = (0.8914) * ((valorPagamento + precificadores["RAV via fornecedor"]) - (precificadores["Custo total"] + taxa))
                        } else if (servico.cotacao.cotacao_carro) {
                            let precificadores = this.custosServicoCarroPrecificadores(servico)
                            total = (0.8914) * ((valorPagamento + precificadores["RAV via fornecedor"]) - (precificadores["Custo total"] + taxa))
                        } else if (servico.cotacao.cotacao_voo) {
                            let precificadores = this.custosServicoVooSubcotacaoPrecificadores(servico.cotacao.cotacao_voo.subcotacoes[0])
                            total = (0.8914) * ((valorPagamento) - (precificadores["Custo total"] + taxa))
                        } else {
                            return Number.NaN;
                        }
                    }
                });
            }
            return total;
        },
        cotacaoCustoTotal(cotacao) {
            if (!cotacao) return 0.0;
            let total = 0.0;
            if (cotacao.cotacao_hotel) {
                total = this.calculateCustoTotalCotacaoHotel(cotacao.cotacao_hotel);
            } else if (cotacao.cotacao_outro) {
                total = this.calculateCustoTotalOutro(cotacao.cotacao_outro);
            } else if (cotacao.cotacao_carro) {
                total = this.calculateCustoTotalCotacaoCarro(cotacao.cotacao_carro);
            } else if (cotacao.cotacao_voo) {
                total = this.calculateCustoTotalCotacaoVoo(cotacao.cotacao_voo);
            } else {
                return Number.NaN;
            }
            return total;
        },

        subcotacaoVooCustoTotal(subcotacao) {
            if (!subcotacao) return total
            const moedas = this.$store.state.global.moedas;
            const precificadores = [
                subcotacao.custo_taxa_embarque, 
                subcotacao.custo_taxa_bagagem,
                subcotacao.custo_taxas_extras, 
                subcotacao.custo_assento_conforto,
                subcotacao.rav
            ]
            let total = precificadores.reduce(
                (cum, cur) => cum + (!cur ? 0 : converteValorFinanceiroBRL(cur)),
                0
            )

            const qtd_milhas = parseFloat(subcotacao.quantidade_milhas);
            if (subcotacao.tipo_cotacao === 'milha')
                total += qtd_milhas * this.subcotacaoGetValorMilha(subcotacao) / 1000;
            else
                total += converteValorFinanceiroBRL(subcotacao.custo_bilhete, moedas);
            return total
        },

        // Cria uma lista de precificadores, que são os diferentes custos envolvidos na subcotação, como taxa de embarque, taxa de bagagem, taxas extras e assento conforto.
        // Inicializa a variável total como 0.
        // Percorre a lista de precificadores e soma seus valores ao total. Cada valor é convertido para o formato de moeda BRL (Real Brasileiro) antes de ser somado.
        // Retorna o total final.

        cotacaoValorAjuste(cotacao) {
            if (!cotacao) return 0
            const valor = Number((cotacao.ajuste_ativado && converteValorFinanceiroBRL(cotacao.valor_ajuste))) || 0;
            return arredondarNumero(valor)
        },
        // Em seguida, a função verifica se a propriedade ajuste_ativado da cotação está definida e é verdadeira. Se sim, o valor de ajuste é convertido para um valor numérico utilizando a função converteValorFinanceiroBRL e atribuído à variável valor. Caso contrário, o valor é definido como 0.

        cotacaoValorTotal(cotacao) {
            return arredondarNumero(
                parseFloat(this.cotacaoCustoTotal(cotacao)) + parseFloat(this.cotacaoValorAjuste(cotacao))
            );
        },
        // Chama a função cotacaoCustoTotal(cotacao) para obter o custo total da cotação.
        // Chama a função cotacaoValorAjuste(cotacao) para obter o valor de ajuste da cotação.
        // Converte os valores do custo total e do valor de ajuste para números decimais usando parseFloat().
        // Soma o custo total e o valor de ajuste.
        // Retorna o valor total da cotação após arredondá-lo usando a função arredondarNumero().



        // Preço ajustado por juros de n meses
        cotacaoValorTotalJuros(cotacao, n = 12) {
            if (n===0) return this.cotacaoValorTotal(cotacao)
            if (this.cotacaoValorTotal(cotacao)===0) return 0
            return this.calculoJuros(this.cotacaoValorTotal(cotacao), n)
        },
        // Caso contrário, chama a função calculoJuros passando o valor total da cotação e n como argumentos. Essa função é responsável por calcular o valor dos juros com base no valor total e no número de parcelas.


        calculoJuros(valor, n=12) {
            const corrigidoAntecipacao = valor*precificacaoJuros.taxa_antecipacao*(1-precificacaoJuros.taxa_antecipacao**n)/(1-precificacaoJuros.taxa_antecipacao)/n
            
            const precoCliente = arredondarNumero(corrigidoAntecipacao.toFixed(2)/(1-Math.max(precificacaoJuros.taxas_pagarme[n-1], precificacaoJuros.taxas_rede[n-1])))
            return arredondarNumero(precoCliente + precificacaoJuros.taxas_adicionais_pagarme)

        },

        //  Calcula o valor corrigido com antecipação utilizando a fórmula de cálculo de juros: valor * taxa_antecipacao * (1 - taxa_antecipacao**n) / (1 - taxa_antecipacao) / n. O resultado desse cálculo é armazenado na variável corrigidoAntecipacao.

        // Calcula o preço para o cliente, levando em consideração as taxas de pagamentos parcelados. Isso é feito dividindo corrigidoAntecipacao por (1 - Math.max(taxas_pagarme[n-1], taxas_rede[n-1])) e arredondando o resultado para duas casas decimais utilizando a função arredondarNumero.

        // Adiciona as taxas adicionais de pagamentos parcelados a precoCliente, resultando no preço final com juros. Esse valor é retornado pela função após ser arredondado utilizando novamente a função arredondarNumero.

        cotacaoLucro(cotacao) {
            // não leva em conta o lucro associado à diferença do preco de compra e de venda da milha
            const lucro = this.cotacaoValorTotal(cotacao) / this.cotacaoCustoTotal(cotacao) - 1;
            if (Number.isNaN(lucro)) return 0.0;
            return lucro * 100;
        },

        cotacaoTipoServico(cotacao) {
            if (!cotacao) return "";

            if (cotacao.cotacao_hotel)
                return 'Hotel';
            else if (cotacao.cotacao_voo)
                return 'Vôo';
            else if (cotacao.cotacao_carro)
                return 'Carro';
            else if (cotacao.cotacao_outro)
                return 'Outro';
            else
                return '';
        },

        servicoValorTotal(servico) {
            return this.cotacaoValorTotalJuros(servico.cotacao) - this.converteValorFinanceiroBRL(servico.desconto)
        },

        /////////////////////////////////////////////////////////////////
        /// Servico valor base
        /////////////////////////////////////////////////////////////////

        receitaServico(servico) {
            return this.servicoValorTotal(servico)
        },

        custosServicoVooSubcotacaoPrecificadores(subcotacao) {
            const taxa_bagagem = converteValorFinanceiroBRL(subcotacao.custo_taxa_bagagem)
            const taxa_embarque = converteValorFinanceiroBRL(subcotacao.custo_taxa_embarque)
            const taxa_extra = converteValorFinanceiroBRL(subcotacao.custo_taxas_extras)
            const custo_assento_conforto = converteValorFinanceiroBRL(subcotacao.custo_assento_conforto)
            const rav = converteValorFinanceiroBRL(subcotacao.rav)
            const precificadores = {
                "Taxa de embarque": taxa_embarque,
                "Taxa de bagagem": taxa_bagagem,
                "Taxas extras": taxa_extra,
                "Custo assento conforto": custo_assento_conforto,
                "RAV via fornecedor": rav,
            }
            let total = taxa_bagagem+taxa_embarque+taxa_extra+custo_assento_conforto+rav
            if (subcotacao.tipo_cotacao==="pagante") {
                return {
                    ...precificadores,
                    "Custo bilhete": converteValorFinanceiroBRL(subcotacao.custo_bilhete),
                    "Custo total": total+converteValorFinanceiroBRL(subcotacao.custo_bilhete),
                }
            }
            // Custo das milhas é calculado usando as cotações milhas validadas, aqui são apenas os outros custos
            else if (subcotacao.tipo_cotacao==="milha") {
                return {
                    ...precificadores,
                    "Custo total": total,
                }
            }
            // O custo total de wallet precisa aplicar o deságio
            else if (subcotacao.tipo_cotacao==="wallet") {
                return {
                    ...precificadores,
                    "Custo bilhete": converteValorFinanceiroBRL(subcotacao.custo_bilhete),
                    "Custo total": (total+converteValorFinanceiroBRL(subcotacao.custo_bilhete)),
                }

            }
        },

        custosServicoCarroPrecificadores(servico) {
            if (!servico.cotacao.cotacao_carro) return {}
            const cotacao_carro = servico.cotacao.cotacao_carro;
            const diarias = Number(cotacao_carro.diarias|| 0);
            const custoDiarias = converteValorFinanceiroBRL(cotacao_carro.custo_diaria) * Number(diarias || 0)
            const rav = converteValorFinanceiroBRL(cotacao_carro.rav)
            return {
                "Custo diária": converteValorFinanceiroBRL(cotacao_carro.custo_diaria),
                "Custo diárias Total": custoDiarias,
                "RAV via fornecedor": rav,
                "Custos Extras": this.converteValorFinanceiroBRL(cotacao_carro.custo_extras),
                "Custo total": rav + custoDiarias + this.converteValorFinanceiroBRL(cotacao_carro.custo_extras)
            }
        },

        custosServicoOutroPrecificadores(servico) {
            if (!servico.cotacao.cotacao_outro) return {}
            const cotacao_outro = servico.cotacao.cotacao_outro
            const rav = converteValorFinanceiroBRL(cotacao_outro.rav)
            return {
                "Custo Total sem RAV": converteValorFinanceiroBRL(cotacao_outro.custo_total),
                "RAV via fornecedor": rav,
                "Custo total": rav + converteValorFinanceiroBRL(cotacao_outro.custo_total)
            }
        },

        custosServicoHotelPrecificadores(servico) {
            if (!servico.cotacao.cotacao_hotel) return {}
            const cotacao_hotel = servico.cotacao.cotacao_hotel;
            const diarias = cotacao_hotel.diarias ? cotacao_hotel.diarias : Math.floor((cotacao_hotel.diarias_min + cotacao_hotel.diarias_max) / 2)
            const custoDiarias = converteValorFinanceiroBRL(cotacao_hotel.custo_diaria) * Number(diarias || 0)
            const rav = converteValorFinanceiroBRL(cotacao_hotel.rav)
            return {
                "Custo diária": converteValorFinanceiroBRL(cotacao_hotel.custo_diaria),
                "Custo diária total": custoDiarias,
                "RAV via fornecedor": rav,
                "Custos Extras": this.converteValorFinanceiroBRL(cotacao_hotel.custo_extras),
                "Custo total": rav + custoDiarias + this.converteValorFinanceiroBRL(cotacao_hotel.custo_extras)
            }
        },
        
        custoServicoPrecificadoresSemVoo(servico) {
            if(servico?.cotacao?.cotacao_hotel) {
                return [this.custosServicoHotelPrecificadores(servico)]
            }
            else if(servico?.cotacao?.cotacao_carro) {
                return [this.custosServicoCarroPrecificadores(servico)]
            }
            else if(servico?.cotacao?.cotacao_outro) {
                return [this.custosServicoOutroPrecificadores(servico)]
            }
            return [{}]
        },
    }
}

export default cotacaoMixin;
