<template>
    <div>
        <v-card class="elevation-5">
            <v-container fluid>
                <v-row>
                    <v-col cols="2" style="max-height: 70px;">
                        <v-select
                            clear-icon="mdi-close"
                            clearable
                            outlined
                            v-model="filters.campo_formador"
                            label="Campo formador"
                            :items="campo_formador"
                            item-text="value"
                            item-value="key"
                            dense
                            class="my-5"                      
                        >
                        </v-select>
                    </v-col>
                    <v-col cols="2" style="max-height: 70px;">
                        <v-text-field
                            dense
                            outlined
                            clearable
                            clear-icon="mdi-close"
                            append-icon="mdi-magnify"
                            label="ID da Venda"
                            v-model="filters.id_venda"
                            class="my-5"
                        >
                        </v-text-field>
                    </v-col>
                    <v-col cols="2" style="max-height: 70px;">
                        <v-select
                            clear-icon="mdi-close"
                            clearable
                            outlined
                            v-model="filters.fornecedor"
                            label="Fornecedor"
                            :items="fornecedores"
                            item-text="nome"
                            item-value="id"
                            dense
                            class="my-5"                      
                        >
                        </v-select>
                    </v-col>
                    <v-col cols="2" style="max-height: 70px;">
                        <v-select
                            clear-icon="mdi-close"
                            clearable
                            outlined
                            v-model="filters.card"
                            label="Cartão"
                            :items="cards"
                            :item-text="formatCard"
                            item-value="id"
                            dense
                            class="my-5"
                        >
                        </v-select>
                    </v-col>
                    <v-col cols="2" style="max-height: 70px;">
                        <v-text-field
                            dense
                            outlined
                            clearable
                            clear-icon="mdi-close"
                            append-icon="mdi-magnify"
                            label="Localizador"
                            v-model="filters.localizador"
                            class="my-5"
                        >
                        </v-text-field>
                    </v-col>
                    <v-col cols="2" style="max-height: 70px;">
                        <v-text-field
                            dense
                            outlined
                            clearable
                            clear-icon="mdi-close"
                            append-icon="mdi-magnify"
                            label="Valor"
                            v-model="filters.valor"
                            class="my-5"
                            :prefix="prefix.valor"
                        >
                        </v-text-field>
                    </v-col>
                </v-row>
            </v-container>
            <v-container fluid v-if="loadingTable">
                <v-card>
                    <v-card-text class="text-h6 text-center">
                        Aguarde um momento...
                    </v-card-text>
                </v-card>
            </v-container>
            <v-container fluid v-else-if="ordemPagamento && ordemPagamento.length > 0">
                <v-card
                    v-for="(emissaoOP, index) in OPPerEmissao"
                    :key="index"
                    class="my-6 elevation-5"
                    style="border-radius: 10px;"
                >
                    <v-card-title class="pa-3 white--text primary">
                        <v-row class="">
                            <v-col cols="1">  
                            </v-col>
                            <v-col cols="10" class="text-center">
                                <span class="text-uppercase">Emissão: {{ emissaoOP.emissao.OPs[0].emissao.id }}</span>
                                <v-divider vertical color="white" class="mx-2 pa-0" v-if="emissaoOP.emissao.OPs[0].emissao.localizador"/>
                                <span class="text-uppercase" v-if="emissaoOP.emissao.OPs[0].emissao.localizador">LOC: {{ emissaoOP.emissao.OPs[0].emissao.localizador }}</span>
                            </v-col>
                            <v-col cols="1" class="pa-2" style="max-height: 50px;">
                                <v-select
                                    clear-icon="mdi-close"
                                    clearable
                                    label="status"
                                    :items="statusPago"
                                    item-value="key"
                                    item-text="value"
                                    dense
                                    v-model="selectedNewStatus"
                                    class=""
                                    style="border-radius: 10px;"
                                    solo
                                    v-if="isCPEmissaoSelected(emissaoOP.emissao.OPs[0].emissao.id)"
                                    @change="checkStatusChange(selectedCPs, selectedNewStatus)"
                                >
                                </v-select>
                            </v-col>
                        </v-row>
                    </v-card-title>
                    <v-card-text>
                        <v-data-table
                            :headers="headers"
                            :items="emissaoOP.emissao.OPs"
                            :loading="loadingTable"
                            :hide-default-footer="true"
                            :items-per-page="10"
                            :single-select="singleSelect"
                            v-model="selectedCPs"
                            checkbox-color="primary"
                            show-select
                        >  

                            <template v-slot:header.data-table-select="{ props, on }">
                                <v-simple-checkbox
                                    :value="props.value || props.indeterminate"
                                    v-on="on"
                                    :indeterminate="props.indeterminate"
                                    color="primary"
                                />
                            </template>

                            <template v-slot:item.servico.venda="{ item }">
                                <v-link-text-field
                                :to="{ name: 'venda-info', params: { id: item.emissao.venda } }"
                                >
                                #{{ item.emissao.venda | formatVendaId }}
                                </v-link-text-field>
                            </template>

                            <template v-slot:item.valor="{ item }">
                                {{ item.valor | dinheiroReal}}
                            </template>

                            <template v-slot:item.emissao.tipo="{ item }">
                                {{ item.emissao.tipo | formatTipoEmissao}}
                            </template>

                            <template v-slot:item.campo_formador="{ item }">
                                {{ item.campo_formador | formatCampoFormadorPreco}}
                            </template>

                            <template v-slot:item.meio_pagamento="{ item }">
                                {{ item.meio_pagamento | formatMeioPagamento}}
                            </template>

                            <template v-slot:item.data_solicitacao="{ item }">
                                {{ item.data_solicitacao | convertDataBR}}
                                às
                                {{ item.data_solicitacao | dataHoraMinuto }}
                            </template>

                            <template v-slot:item.data_alteracao="{ item }">
                                {{ item.data_alteracao | convertDataBR}}
                                <span v-if="item.data_alteracao">às</span>
                                {{ item.data_alteracao | dataHoraMinuto }}
                            </template>

                            <template v-slot:item.fornecedor="{ item }">
                                {{ item.fornecedor }}
                            </template>

                            <template v-slot:item.emissao.status="{ item }">
                                {{ item.emissao.status | formatStatusEmissao}}
                            </template>

                            <template v-slot:item.cartao="{ item }">
                                {{ formatCard(item.cartao) }}
                            </template>

                            <template v-slot:no-data> 
                                Nenhuma ordem de pagamento em aberto encontrada. 
                            </template>

                            <template v-slot:item.actions="{ item }">
                                <div class="actionButtons">
                                    <v-row>
                                        <v-col cols="1" class="text-center pa-2" style="max-height: 50px;">
                                            <v-btn
                                                icon
                                                color="transparent"
                                                @click="openNewValueDialog(item)"
                                            >
                                                <v-icon
                                                    color="primary"
                                                    small
                                                >mdi-pencil
                                                </v-icon>
                                            </v-btn>
                                        </v-col>
                                    </v-row>
                                </div>
                                <!-- DIALOG PARA MUDANÇAS DE VALORES -->
                                <v-dialog v-model="newValueDialog" max-width="500px" persistent :overlay-opacity="0.1" transition="dialog-bottom-transition" :retain-focus="false">
                                    <v-card class="overflow-y-hidden elevation-0" style="border-radius: 10px;">
                                        <v-card-title class="text-h5 justify-center white--text primary">CP {{ OPId }}</v-card-title>
                                        <v-card-text>
                                            <v-card-title class="px-0 py-3 black--text">Deseja alterar o valor de {{ selectedCampoFormador | formatCampoFormadorPreco }}?</v-card-title>
                                            <v-row>
                                                <v-col cols="11">
                                                    <v-moeda-field
                                                        outlined
                                                        v-model="newValue"
                                                        :label="labelText"
                                                        :key="newValueKey"
                                                    />
                                                </v-col>
                                                <v-col cols="1" class="px-0 mt-2">
                                                    <v-btn
                                                        icon
                                                        color="transparent"
                                                        @click="setNewValueIntoCotacao"
                                                    >
                                                        <v-progress-circular
                                                            v-if="loadingSetNewValue"
                                                            indeterminate
                                                            color="green"
                                                            size="24"
                                                        >
                                                        </v-progress-circular>
                                                        <v-icon
                                                            v-else
                                                            color="green"
                                                            small
                                                        >mdi-check
                                                        </v-icon>
                                                    </v-btn>
                                                </v-col>
                                            </v-row>
                                            <v-divider class="my-2"/>
                                            <v-card-title class="px-0 py-3 black--text">{{ (selectedCard ? 'Deseja alterar o cartão selecionado?' : 'Deseja inserir um cartão?') }}</v-card-title>
                                            <v-row>
                                                <v-col cols="11">
                                                    <v-select
                                                        :items="cards"
                                                        :item-text="formatCard"
                                                        item-value="id"
                                                        v-model="selectedCard"
                                                        outlined
                                                        dense
                                                        clearable
                                                    />
                                                </v-col>
                                                <v-col cols="1" class="px-0 mt-1">
                                                    <v-btn
                                                        icon
                                                        color="transparent"
                                                        @click="changeCard"
                                                    >
                                                        <v-progress-circular
                                                            v-if="loadingChangeCard"
                                                            indeterminate
                                                            color="green"
                                                            size="24"
                                                        >
                                                        </v-progress-circular>
                                                        <v-icon
                                                            v-else
                                                            color="green"
                                                            small
                                                        >mdi-check
                                                        </v-icon>
                                                    </v-btn>
                                                </v-col>
                                            </v-row>
                                        </v-card-text>
                                        <v-divider class="my-2"/>
                                        <v-card-actions>
                                        <v-row>
                                            <v-col cols="6">
                                                <v-btn large min-width="100px"  color="error" @click="closeNewValueDialog">Sair</v-btn>
                                            </v-col>
                                        </v-row>
                                        </v-card-actions>
                                    </v-card>
                                </v-dialog>
                            </template>
                        </v-data-table>
                        <!-- DIALOG PARA ALTERAÇÃO DE STATUS -->
                        <v-dialog v-model="dialogAlterStatus" persistent max-width="500px" :overlay-opacity="0.1" transition="dialog-bottom-transition" :retain-focus="false">
                            <v-card class="overflow-y-hidden elevation-0" style="border-radius: 10px;">
                                <v-card-title class="text-h5 justify-center white--text primary">Informações do pagamento</v-card-title>
                                <v-divider/>
                                <v-card-text>
                                    <v-row>
                                        <v-col cols="12" style="max-height: 100px;">
                                            <v-text-field label="ID fatura" outlined :rules="[rules.required]" v-model="pagamento.id_fatura" />
                                        </v-col>
                                    </v-row>
                                    <v-row>
                                        <v-col cols="12" style="max-height: 100px;">
                                            <FormDatePicker
                                                v-model="pagamento.data_pagamento"
                                                outlined
                                                :dense="false"
                                                label="Data do pagamento"
                                            />
                                        </v-col>
                                    </v-row>
                                </v-card-text>
                                <v-divider class="my-1"/>
                                <v-card-actions>
                                    <v-row>
                                        <v-col cols="6">
                                            <v-btn block large color="error" @click="closeDialogAlterStatus">Sair</v-btn>
                                        </v-col>
                                        <v-col cols="6">
                                            <v-btn block large color="success" :loading="loadingAlterStatus" @click="ConfirmChangeStatus">Confirmar</v-btn>
                                        </v-col>
                                    </v-row>
                                </v-card-actions>
                            </v-card>
                        </v-dialog>
                        <!-- LOADING PARA ALTERAÇÃO DE STATUS/MUDANÇAS DE VALORES -->
                        <v-dialog v-model="loadingChanges" persistent :overlay-opacity="0.1" transition="dialog-bottom-transition" :retain-focus="false">
                            <div style="overflow: hidden;">
                                <v-row justify="center" align="center" style="height: 100%;">
                                    <v-col cols="12" class="d-flex justify-center align-center">
                                        <v-progress-circular :rotate="-90" :size="70" :width="5" :value="value" color="white" indeterminate>
                                            {{ value }}%
                                        </v-progress-circular>
                                    </v-col>
                                </v-row>
                            </div>
                        </v-dialog>
                        <!-- DIALOG PARA MUDANÇAS DE VALORES DE INCENTIVO -->
                        <v-dialog v-model="newValueIncentivoDialog" max-width="500px" persistent :overlay-opacity="0.1" transition="dialog-bottom-transition" :retain-focus="false">
                            <v-card class="overflow-y-hidden elevation-0" style="border-radius: 10px;">
                                <v-card-title class="text-h5 justify-center white--text primary">Incentivo</v-card-title>
                                <v-card-text>
                                    <v-card-title class="px-0 py-3 black--text">Deseja alterar o valor de incentivo?</v-card-title>
                                    <v-row>
                                        <v-col cols="11">
                                            <v-moeda-field
                                                outlined
                                                v-model="newValueIncentivo"
                                                :key="newValueIncentivoKey"
                                                :disabledEscolhaMoeda="true"
                                            />
                                        </v-col>
                                        <v-col cols="1" class="px-0 mt-2">
                                            <v-btn
                                                icon
                                                color="transparent"
                                                @click="changeIncentivo(cotacao)"
                                            >
                                                <v-progress-circular
                                                    v-if="loadingSetNewValue"
                                                    indeterminate
                                                    color="green"
                                                    size="24"
                                                >
                                                </v-progress-circular>
                                                <v-icon
                                                    v-else
                                                    color="green"
                                                    small
                                                >   mdi-check
                                                </v-icon>
                                            </v-btn>
                                        </v-col>
                                    </v-row>
                                    <v-divider class="my-2"/>
                                    <v-card-title class="px-0 py-3 black--text">Porcentagem de incentivo</v-card-title>
                                    <v-row>
                                        <v-col cols="11">
                                            <v-text-field
                                                outlined
                                                v-model="newValuePercentageIncentivo"
                                                :key="newValueIncentivoKey"
                                                :prefix="prefix.porcentagem"
                                                label="Porcentagem"
                                                disabled
                                            />
                                        </v-col>
                                    </v-row>
                                </v-card-text>
                                <v-divider class="my-2"/>
                                <v-card-actions>
                                    <v-row>
                                        <v-col cols="6">
                                            <v-btn large min-width="100px"  color="error" @click="closeNewValueIncentivoDialog">Sair</v-btn>
                                        </v-col>
                                    </v-row>
                                </v-card-actions>
                            </v-card>
                        </v-dialog>
                         <!-- PANELS -->
                        <div style="max-width: 470px; max-height:200px;">
                            <v-row>
                                <v-col cols="6" v-for="(panel, panelIndex) in emissaoOP.panels" :key="panelIndex" v-if="panel.showPanel">
                                    <v-expansion-panels>
                                        <v-expansion-panel>
                                            <v-expansion-panel-header>
                                                {{ panel.title }}
                                            </v-expansion-panel-header>
                                            <v-divider class="my-2 mt-0"/>
                                            <v-expansion-panel-content>
                                                <v-row>
                                                    <v-col cols="5" v-if="panel.filter">
                                                        {{ panel.content | dinheiroReal}}
                                                    </v-col>
                                                    <v-col cols="5" v-else>
                                                        {{ panel.content }}
                                                    </v-col>
                                                    <v-col cols="5" v-if="panel.hasExtraContent">
                                                        <span>%</span>
                                                        <br>
                                                        {{ panel.extraContent}}
                                                    </v-col>
                                                    <v-col cols="2">
                                                        <v-icon
                                                            color="black"
                                                            small
                                                            v-if="panel.showButton"
                                                        >
                                                            mdi-cash-edit
                                                        </v-icon>
                                                        <v-btn icon small v-if="panel.showButton" color="transparent" class="" style="width: 0px; height: 0px;" @click="openNewValueIncentivoDialog(panel)">
                                                            <v-icon
                                                                color="blue"
                                                                small
                                                                class="pl-4"
                                                            >
                                                                mdi-pencil
                                                            </v-icon>
                                                        </v-btn>
                                                    </v-col>
                                                </v-row>
                                            </v-expansion-panel-content>
                                        </v-expansion-panel>
                                    </v-expansion-panels>
                                </v-col>
                            </v-row>
                        </div>
                    </v-card-text>
                </v-card>
            </v-container>
            <v-container fluid v-else>
                <v-card class=" elevation-5">
                    <v-card-title class="justify-center">
                        <span>Nenhuma ordem de pagamento em aberto encontrada.</span>
                    </v-card-title>
                </v-card>
            </v-container>
            <v-pagination
                v-model="currentPage"
                :length="numPagesOrdemPagamento"
                :total-visible="7"
            />
        </v-card>
    </div>
</template>
<script>
import api from "@/api/api";
import Paginator from '@/components/widgets/Paginator.vue';
import statusOrdemPagamento from '@/constants/statusOrdemPagamento';
import MEIOS_DE_PAGAMENTO from "@/constants/meiosPagamento";
import VLinkTextField from "@/components/widgets/VLinkTextField";
import tipoCampoFormadorPreco from "@/constants/tipoCampoFormadorPreco";
import FormDatePicker from "../widgets/FormDatePicker.vue";
import VMoedaField from "@/components/widgets/VMoedaField";
import CotacaoMixin from "@/mixins/CotacaoMixin";

export default {
    components: {Paginator, VLinkTextField, FormDatePicker, VMoedaField},
    mixins:[CotacaoMixin],
    data: () => ({
        headers: [
            { text: "ID", value: "id" },
            { text: "Venda", value: "emissao.venda" },
            { text: "Serviço", value: "emissao.servico" },
            { text: "Valor", value: "valor" },
            { text: "Tipo da emissão", value: "emissao.tipo"},
            { text: "Campo formador", value: "campo_formador" },
            { text: "Meio de pagamento", value: "meio_pagamento" },
            { text: "Data de solicitação", value: "data_solicitacao" },
            { text: "Data da ultima alteração", value: "data_alteracao" },
            { text: "Fornecedor", value: "fornecedor.nome" },
            { text: "Status da emissão", value: "emissao.status" },
            { text: "Cartões", value: "cartao"},
            { text: "Ações", value: "actions" },
        ],
        ordemPagamento: [],
        currentPage: 1,
        numPagesOrdemPagamento: null,
        loadingTable: false,
        selectedOrdemPagamento: {},
        OPId: null,
        statusOrdemPagamento,
        MEIOS_DE_PAGAMENTO,
        loadingAlterStatus: false,
        filters:{
            campo_formador:"",
            id_venda:null,
            fornecedor: null,
            card: null,
            localizador: "",
            valor: null,
        },
        campo_formador: tipoCampoFormadorPreco,
        dialogAlterStatus: false,
        pagamento:{
            id_fatura: '',
            data_pagamento: new Date,
        },
        rules: {
          required: value => !!value || 'Obrigatório.',
        },
        OPstatus: null,
        newValue: {
            valor: null,
        },
        newValueDialog: false,
        selectedCampoFormador: null,
        servicoId: null,
        cotacao: null,
        loadingSetNewValue: false,
        selectedAction: null,
        changeValueDialog: false,
        actionsOptions: [
            { text: "valor", value: "Valor" },
            { text: "cartao", value: "Cartão" },
        ],
        selectActionOption: false,
        cards: null,
        selectedCard: null,
        loadingChangeCard: false,
        fornecedores: null,
        newValueKey:0,
        singleSelect: false, 
        multiSelect: false,
        selectedCPs: [],
        selectedNewStatus: null,
        CPsIds: [],
        loadingChanges: false,
        value: 0,
        newValueIncentivoDialog: false,
        newValueIncentivo: {
            valor: 0.00,
            moeda: 2,
            valor_convertido: 0.00
        },
        newValuePercentageIncentivo: 0,
        newValueIncentivoKey:0,
        prefix: {
            porcentagem: '%',
            valor: 'R$',
        },
        currentCotacao: null,
        CotacaoType: ['hotel', 'carro', 'outro', 'voo'],
        emissao: null,
    }),

    async mounted(){
        await this.initialize()
    },

    watch:{
        currentPage:{
            handler(val){
                this.pageChange(val);
            }
        },
        filters:{
            deep: true,
            handler() {
                this.pageChange(1);
            },
        },
        selectedAction(newVal){
            if(newVal === 'valor'){
                this.changeValueDialog = true;
            }else{
                this.isNewValueDialogOpen = false;
            }
        },
        newValueIncentivo: {
            deep: true,
            handler(val) {
                const valorIncentivo = val.valor;
                let custo = 0;
                if(this.cotacao.custo_diaria){
                    custo = this.cotacao.custo_diaria.valor;
                }else{
                    custo = this.cotacao.custo_bilhete.valor;
                }

                if (!isNaN(valorIncentivo) && custo !== undefined) {
                    const newPercentage = this.calculateIncentivoPercentage(custo, valorIncentivo);
                    this.newValuePercentageIncentivo = newPercentage;
                }
            },
        },
    },

    computed:{
        OPPerEmissao(){
            let groupedByEmissao = {};
            if (this.ordemPagamento) {
                groupedByEmissao = this.ordemPagamento.map((emissao) => {
                    // Setando panels default
                    let panels = [
                        { title: 'Custo total', content: emissao.custo_total, extraContent: 0.00, hasExtraContent: false, showButton: false, op: emissao.OPs[0].id, showPanel: true, filter: true },
                        { title: 'Incentivo', content: 0.00, extraContent: 0.00, hasExtraContent: true, showButton: true, op: emissao.OPs[0].id, cotacao: null, cotacaoType: this.CotacaoType, showPanel: false, filter: true },
                        { title: 'Qtd. de milhas', content: 0.00, extraContent: 0.00, hasExtraContent: false, showButton: false, op: emissao.OPs[0].id, showPanel: false, filter: false }
                    ];

                    // Setando a exibição dos panels de acordo com o tipo da emissão
                    const tipoEmissaoMilha = emissao.OPs.every(op => op.emissao?.tipo === 'emissao_voo_milha');

                    if (tipoEmissaoMilha) {
                        panels[2].showPanel = true;
                    } else {
                        panels[1].showPanel = true;
                    }

                    //setando valor default de incentivo e definindo condições para atribuição de valores de acordo com a cotação.
                    let incentivo = {
                        valor: 0,
                        porcentagem: 0
                    }

                    let cotacao = null;

                    if (emissao.OPs[0].servico && emissao.OPs[0].servico.cotacao.cotacao_hotel) {
                        const cotacaoHotel = emissao.OPs[0].servico.cotacao.cotacao_hotel;
                        incentivo.valor = cotacaoHotel.valor_incentivo;
                        incentivo.porcentagem = this.calculateIncentivoPercentage(cotacaoHotel.custo_diaria?.valor, incentivo.valor);
                        cotacao = cotacaoHotel;
                        this.cotacaoType = 'hotel';
                    }

                    if (emissao.OPs[0].servico && emissao.OPs[0].servico.cotacao.cotacao_carro) {
                        const cotacaoCarro = emissao.OPs[0].servico.cotacao.cotacao_carro;
                        incentivo.valor = cotacaoCarro.valor_incentivo;
                        incentivo.porcentagem = this.calculateIncentivoPercentage(cotacaoCarro.custo_diaria?.valor, incentivo.valor);
                        cotacao = cotacaoCarro
                        this.cotacaoType = 'carro';
                    }

                    if (emissao.OPs[0].servico && emissao.OPs[0].servico.cotacao.cotacao_outro) {
                        const cotacaoOutro = emissao.OPs[0].servico.cotacao.cotacao_outro;
                        incentivo.valor = cotacaoOutro.valor_incentivo;
                        incentivo.porcentagem = this.calculateIncentivoPercentage(cotacaoOutro.custo_diaria?.valor, incentivo.valor);
                        cotacao = cotacaoOutro
                        this.cotacaoType = 'outro'
                    }
                    
                    if (emissao.OPs[0].emissao && emissao.OPs[0].emissao.subcotacao_voo) {
                        const cotacaoVoo = emissao.OPs[0].emissao.subcotacao_voo;
                        incentivo.valor = cotacaoVoo.valor_incentivo;
                        incentivo.porcentagem = this.calculateIncentivoPercentage(cotacaoVoo.custo_bilhete?.valor, incentivo.valor);
                        cotacao = cotacaoVoo;
                        this.cotacaoType = 'voo';
                    }

                    //definindo a exibição dos valores nos panels.
                    if(panels[0].title){
                        panels[0].content = emissao.custo_total
                    }
                    
                    if(panels[1].title){
                        panels[1].content = incentivo.valor;
                        panels[1].extraContent = incentivo.porcentagem ?? 0;
                        panels[1].cotacao = cotacao;
                        panels[1].cotacaoType = this.cotacaoType;
                    }

                    if(panels[2].title){
                        panels[2].content = emissao.OPs[0].emissao.subcotacao_voo?.quantidade_milhas;
                    }

                    return { emissao, panels };
                });
            }
        return groupedByEmissao;
    },
        labelText(){
           switch(this.selectedCampoFormador){
                case "custo_bilhete":
                    return "Custo bilhete";
                case "custo_taxa_embarque":
                    return "Custo taxa de embarque"  
                case "custo_taxa_bagagem":
                    return "Custo taxa de bagagem";
                case "rav":
                    return "RAV";
                case "quantidade_milhas":
                    return "Quantidade de milhas";
                case "custo_taxas_extras":
                    return "Custo taxas extras";
                case "milheiro":
                    return 'Milheiro';
                case "custo_diaria":
                    return "Custo da diária";
                case "custo_extras":
                    return "Extras"
                case "custo_seguro":
                    return "Seguro";
                case "custo_total":
                    return "Custo total"
                default:
                    return "";
            }
        },
        statusPago(){
            return this.statusOrdemPagamento.filter(status => status.key === 'pago');
        },
        custoTotalCotacao(){
            let custo_total_cotacao = [];

            // Convertendo o objeto em um array de valores
            const emissaoArray = Object.values(this.OPPerEmissao);
            
            emissaoArray.map((item) => {
                // Calculando o custo total da cotação para o item atual
                const custoItem = this.cotacaoCustoTotal(item.emissao.OPs[0].servico.cotacao);
                const idEmissao = item.emissao.OPs[0].emissao.id;
                
                // Adicionando o custo total do item à matriz
                custo_total_cotacao.push({ emissao: idEmissao, custo: custoItem });
            });

            return custo_total_cotacao;
        },
    },

    methods:{
        async initialize(){
            this.loadingTable = true
            await api.getOrdensPagamentosAbertos()
            .then(response => {
                this.ordemPagamento = response.data.ordemPagamento
                this.loadingTable = false
                this.numPagesOrdemPagamento = response.data.pages
            })
            .catch(err => {
                this.$root.vtoast.show({
                    message: `Erro ao carregar ordens de pagamentos abertos: ${err}`,
                    icon: 'mdi-close',
                    color: 'error'
                })
            })
            await api
                .getCartoes()
                .then((res) => {
                    const data = res.data;
                    this.cards = data.filter((card) => card.ativo === true);
                })
                .catch((err) =>
                this.$root.vtoast.show({
                    message: "Erro ao recuperar cartões",
                    color: "error",
                    icon: "mdi-close",
                })
                );
            await this.$store.dispatch("global/fetchFornecedores")
                .then((res) => {this.fornecedores = res.data})
                .catch((err) => {
                    this.$root.vtoast.show({
                        message: "Erro ao recuperar fornecedores",
                        color: "error",
                        icon: "mdi-close",
                    })
                }
                );
        },
        async refreshOrdensPagamentosAbertos(page){
            this.loadingTable = true;

            this.filters = {...this.filters};

            api.getOrdensPagamentosAbertos(page, this.filters)
                .then((response) => {
                    this.ordemPagamento = response.data.ordemPagamento
                    this.numPagesOrdemPagamento = response.data.pages
                })
                .catch((err) => {
                    this.$root.vtoast.show({
                        message: `Erro ao carregar ordens de pagamentos abertos: ${err}`,
                        icon: 'mdi-close',
                        color: 'error'
                    })
                })
                .finally(() => {
                    this.loadingTable = false;
                })
        },
        formatCard(cartao){
            if(!cartao) return "";
            return `${cartao.nome} - ${cartao.bandeira} (${cartao.digs})`;
        },
        pageChange(page){
            clearTimeout(this.timer);

            let filters = [];

            if(this.filters.campo_formador){
                filters.push(["campo_formador", this.filters.campo_formador]);
            }

            if(this.filters.id_venda){
                filters.push(["id_venda", this.filters.id_venda]);
            }

            if(this.filters.fornecedor){
                filters.push(["fornecedor", this.filters.fornecedor]);
            }

            if(this.filters.card){
                filters.push(["card", this.filters.card]);
            }

            if(this.filters.localizador){
                filters.push(["localizador", this.filters.localizador]);
            }

            if(this.filters.valor){
                let valorFormatado = this.filters.valor.replace(',', '.');
                filters.push(["valor", valorFormatado]);
            }

            this.timer = setTimeout(() => {
                api.getOrdensPagamentosAbertos(page, status = 'aberto', filters).then((response) => {
                    this.ordemPagamento = response.data.ordemPagamento;
                    this.loadingTable = false;
                    this.numPagesOrdemPagamento = response.data.pages;
                });
            }, 1000);

            this.loadingTable = true;
            this.currentPage = page;
        },
        openDialogAlterStatus(ids, selectedStatus){
            this.loadingChanges = true;
            let progress = 0;
            const interval = setInterval(() => {
                if(progress < 100){
                    progress += 10;
                    this.value = progress;
                }else{
                    clearInterval(interval);
                    this.loadingChanges = false;
                    this.dialogAlterStatus = true;
                    this.CPsIds = ids;
                    this.OPstatus = selectedStatus;
                }
            }, 300);
        },
        closeDialogAlterStatus(){
            this.dialogAlterStatus = false;
            this.OPId = null;
            this.pagamento.id_fatura = null;
            this.pagamento.data_pagamento = null;
        },
        check(){
            if(!this.pagamento.id_fatura){
                this.$root.vtoast.show({
                    message: "Necessário preencher o ID da fatura!",
                    icon: "mdi-error",
                    color: "error",
                })
                return;
            }

            if(!this.pagamento.data_pagamento){
                this.$root.vtoast.show({
                    message: "Necessário preencher a data do pagamento!",
                    icon: "mdi-error",
                    color: "error",
                })
                return;
            }
            return true;
        },
        OPAlterStatus(IDs){
            this.loadingAlterStatus = true;
            const allCPs = IDs.map(async (selectedCP) => {
                const data = {
                    status: 'pago',
                    id_fatura: this.pagamento.id_fatura,
                    data_pagamento: this.pagamento.data_pagamento
                };
                api.postOrdemPagamentoStatus(selectedCP.id, data)
                    .then(async (response) => {
                        await Promise.all(allCPs)
                        this.$root.vtoast.show({
                            message: "Alterado status da ordem de pagamento!",
                            color: "success",
                            icon: "mdi-check",
                        })
                        this.loadingAlterStatus = false;
                    }).catch((error) => {
                            console.error(error)
                            this.$root.vtoast.show({
                                message: "Erro ao alterar o status da ordem de pagamento!",
                                icon: "mdi-error",
                                color: "error",
                            })
                            this.loadingAlterStatus = false;
                            this.closeDialogAlterStatus();
                    }).finally(async () => {
                        await this.refreshOrdensPagamentosAbertos(1);
                        await this.closeDialogAlterStatus();
                    })
            });
        },
        ConfirmChangeStatus(){
            if(!this.check()) return;
            this.OPAlterStatus(this.CPsIds, this.OPstatus);
        },
        async openNewValueDialog(item){
            this.loadingChanges = true;
            await this.$nextTick();
            let progress = 0;
            const interval = setInterval(async () => {
                if(progress < 100){
                    progress += 10;
                    this.value = progress;
                }else{
                    clearInterval(interval);
                    this.loadingChanges = false;
                    this.newValueKey +=1;
                    this.newValueDialog = true;
                    this.selectedCampoFormador = item.campo_formador;
                    this.servicoId = item.emissao.servico;
                    this.OPId = item.id;
                    this.newValue.valor = item.valor;
                    this.emissao = item.emissao.id;
                    
                    if(item.cartao){
                        this.selectedCard = item.cartao;
                    }else{
                        this.selectedCard = null;
                    }

                    await api.getServico(this.servicoId)
                        .then(res => {
                            this.cotacao = res.data.cotacao
                        }).catch(err => {
                            console.error(err);
                        })
                    }
            }, 300);
        },
        async closeNewValueDialog(){
            await this.$nextTick();
            this.newValueDialog = false;
            this.newValue.valor = null;
        },
        async setNewValueIntoCotacao(){
            this.loadingSetNewValue = true;

            await this.$nextTick();
            const id = this.OPId;

            const emissaoCustoTotalCotacao = this.custoTotalCotacao.find((item) => {
                return item.emissao === this.emissao;
            })

            const emissaoCustoTotalGroupedByEmissao = this.OPPerEmissao.find((item) => {
                return item.emissao.id_emissao === this.emissao;
            })

            const data = {
                valor: this.newValue,
                cotacao: this.cotacao,
                campo_formador: this.selectedCampoFormador,
                custo_total_cotacao: emissaoCustoTotalCotacao.custo,
                OPs: emissaoCustoTotalGroupedByEmissao.emissao.OPs,
            }

            await api.putUpdateCotacao(id, data)
                .then(res => {
                    this.$root.vtoast.show({
                        message: "Valor alterado com sucesso!",
                        color: "success",
                        icon: "mdi-check"
                    })
                }).catch(err => {
                    console.error(err);
                }).finally(async () => {
                    this.loadingSetNewValue = false;
                    await this.closeNewValueDialog();
                    await this.refreshOrdensPagamentosAbertos(1);
                })
        },
        async changeCard(){
            this.loadingChangeCard = true;

            const data = {
                card: this.selectedCard
            }

            const id = this.OPId;

            await api.putChangeCard(id, data)
                .then(res => {
                    this.$root.vtoast.show({
                        message: "Cartão alterado com sucesso!",
                        color: "success",
                        icon: "mdi-check"
                    })
                }).catch(err => {
                    console.error(err);
                    this.$root.vtoast.show({
                        message: "Erro ao alterar o cartão!",
                        color: "error",
                        icon: "mdi-error"
                    })
                }).finally(() => {
                    this.loadingChangeCard = false;
                    this.refreshOrdensPagamentosAbertos(1);
                })
        },
        async clearFilters(){
           this.filters.campo_formador = "";
           this.filters.card = null;
           this.filters.fornecedor = null;
           this.filters.id_venda = null;
        },
        checkStatusChange(ids, selectedStatus){
            if(selectedStatus){
                this.openDialogAlterStatus(ids, selectedStatus);
            }
        },
        isCPEmissaoSelected(emissaoOPId){
            return this.selectedCPs.some(item => item.emissao.id === emissaoOPId);
        },
        async openNewValueIncentivoDialog(panel){
            this.loadingChanges = true;
            let progress = 0;
            
            const interval = setInterval(async () => {
                if(progress < 100){
                    progress += 10;
                    this.value = progress;
                }else{
                    clearInterval(interval);
                    this.newValueIncentivoKey +=1;
                    this.loadingChanges = false;
                    this.OPId = panel.op;
                    this.cotacao = panel.cotacao;
                    this.cotacaoType = panel.cotacaoType;
                    this.newValueIncentivo = {valor: panel.content ?? 0, moeda: 2};
                    this.newValuePercentageIncentivo = parseFloat(panel.extraContent).toFixed(2) ?? 0;
                    this.newValueIncentivoDialog = true;
                }
            }, 300);
        },
        async changeIncentivo(){
            const data = {
                cotacao: this.cotacao,
                valor_incentivo: this.newValueIncentivo,
                porcentagem_incentivo: this.newValuePercentageIncentivo,
                tipo_cotacao: this.cotacaoType,
                op: this.OPId
            }

            await api.putChangeIncentivo(null, data)
                .then(res => {
                    this.$root.vtoast.show({
                        message: "Valor de incentivo alterado com sucesso!",
                        color: "success",
                        icon: "mdi-check"
                    })
                }).catch(err => {
                    console.error(err);
                }).finally(() => {
                     this.closeNewValueIncentivoDialog();
                     this.refreshOrdensPagamentosAbertos(1);;
                })
        },
        //fazer o cálculo de incentivo com base no valor de incentivo ao invés da porcentagem.
        calculateIncentivoPercentage(custo, valorIncentivo) {
            if(custo !== 0){
                const porcentagem = (valorIncentivo / custo) * 100;
                return porcentagem.toFixed(2);
            }else{
                return 0;
            }
        },
        async closeNewValueIncentivoDialog(){
            this.newValueIncentivoDialog = false;
        },
    },
}
</script>
<style scoped>
/* fixing v-dialog box-shadow being "weird" */
::v-deep .v-dialog.v-dialog--persistent {
  box-shadow: none;
}
tr .actionButtons {
  visibility: hidden;
}

tr:hover .actionButtons {
  visibility: visible !important;
}

.v-progress-circular {
  margin: 1rem;
}

.v-expansion-panel::before {
    box-shadow: none;
}

.v-expansion-panel--active::before {
    box-shadow: 0 5px 5px rgba(0, 0, 0, 0.1);
}
</style>
