import Vue from "vue"
import STATUS_VENDA from "@/constants/statusVenda"
import formatStatus from "@/helpers/formatStatus"

const formatStatusVenda = formatStatus(STATUS_VENDA)

const formatVendaId = id => {
    return !id ? "0000" : id.toString().padStart(4,'0')
}

Vue.filter('formatStatusVenda', formatStatusVenda)
Vue.filter('formatVendaId', formatVendaId)