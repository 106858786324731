<template>
    <v-pagination
        v-model="currentPage"
        :length="numPages"
        :total-visible="totalVisible"
    />
</template>

<script>
export default {
    props: {
        numPages: {
            type: Number,
            default: 1,
        },

        totalVisible: {
            type: Number,
            default: 9
        }
    },
    data() {
        return {
            currentPage: 1
        }
    },
    watch: {
        currentPage() {
            return this.$emit('pageChange', this.currentPage)
        }
    }
}
</script>
