<template>
  <transition>
    <v-container fluid>
      <solicitar-orcamento  ref="dialogSolicitarOrcamento" @save="save" v-if="venda.status === 'proposta'"></solicitar-orcamento>
      <venda-orcamentos-list
          :loading-data="loading"
          :orcamentos="orcamentos"
          :key="vendaOrcamentoListKey">
      </venda-orcamentos-list>
    </v-container>
  </transition>
</template>


<script>
import {mapState} from "vuex";
import SolicitarOrcamento from "@/components/widgets/SolicitarOrcamento";
import VendaOrcamentosList from "@/components/venda/VendaOrcamentosList";

export default {
  components: {VendaOrcamentosList, SolicitarOrcamento},
  data() {
    return {
      loading: false,
      vendaOrcamentoListKey: 0,
    }
  },
  async mounted() {
    await this.initialize();
    //ouvir o evento que será emitido pela função finalizarOrcamento dentro do orcamentoMixin.
    this.$root.$on("orcamentoFinalizado", () => {
      this.refreshOrcamentos();
    });
  },
  computed: {
    ...mapState({
      orcamentos: state => state.venda.orcamentos,
      venda: state => state.venda.venda,
    })
  },
  methods: {
    async initialize() {
      this.loading = true;
      const res = await this.$store.dispatch("venda/fetchOrcamentos");
      this.loading = false;
      if (!res.error) {
      } else {
        this.$root.vtoast.show({message: res, color: "error"});
      }
    },
    async save(items) {
      const payload = {
        orcamentos: items
      }
      const res = await this.$store.dispatch("venda/createOrcamentos", payload).then(
          res => {
            this.$root.vtoast.show({message: `Orçamento(s) criado(s) com sucesso.`});
          }
      ).catch(err => {
        this.$root.vtoast.show({message: err, color: "error"});
      })
      this.$refs.dialogSolicitarOrcamento.close();

    },
    async refreshOrcamentos() {
      this.vendaOrcamentoListKey += 1;
      await this.$nextTick();
      await this.$store.dispatch("venda/fetchOrcamentos");
    }
  }
}
</script>
