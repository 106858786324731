<template>
  <div>
    <v-data-table
      :headers="headers"
      :items="racs"
      sort-by="id"
      class="elevation-5"
    >
      <template v-slot:item.emissao="{ item }">
        <VLinkTextField
          :to="{ name: 'emissao', params: { id: item.emissao.id } }"
        >
          {{ item.emissao.id }}
        </VLinkTextField>
      </template>
      <template v-slot:item.tipo="{ item }">
        {{ item.tipo | formatTipoRac }}
      </template>

      <template v-slot:item.status="{ item }">
        {{ item.status | formatStatusRac }}
      </template>

      <template v-slot:item.created_by="{ item }">
        {{ item.created_by | nomeSobrenome }}
      </template>
      <template v-slot:item.created_at="{ item, value }">
        <div>
          {{ item.created_at | convertDataBR }} às
          {{ item.created_at | dataHoraMinuto }}
        </div>
      </template>

      <template v-slot:top>
        <v-toolbar flat>
          <v-toolbar-title>R.A.C.s</v-toolbar-title>
          <v-divider class="mx-4" inset vertical></v-divider>
          <v-spacer></v-spacer>
          <v-dialog v-model="dialog">
            <template v-slot:activator="{ on, attrs }">
              <v-btn color="primary" dark class="mb-2" v-bind="attrs" v-on="on">
                Nova R.A.C.
              </v-btn>
            </template>
            <!-- Edit / create card -->
            <v-card>
              <v-card-title>
                <span class="text-h5">{{ formTitle }}</span>
              </v-card-title>
              <v-card-text>
                <v-container fluid>
                  <v-row>
                    <v-col cols="auto">
                      <v-select
                        v-model="editedItem.tipo"
                        label="Tipo"
                        :items="racTipos"
                        item-value="key"
                        item-text="value"
                      ></v-select>
                    </v-col>
                    <v-col cols="auto">
                      <v-select
                        v-model="emissaoAssociada"
                        label="Emissão associada"
                        :items="
                          emissaoAssociada != null ? emissaoAssociada : emissoes
                        "
                        item-value="id"
                        :item-text="(emissao) => `Emissão #${emissao.id}`"
                      >
                      </v-select>
                    </v-col>
                    <v-col>
                      <small
                        >*Só aparecerão emissões concluídas/finalizadas.</small
                      >
                    </v-col>
                  </v-row>

                  <PagamentoRAC
                    :emissao="editedEmissao"
                    :cards="cards"
                    :pagamentos="pagamentos"
                    ref="pagamentoRac"
                  >
                  </PagamentoRAC>
                </v-container>
                <v-divider></v-divider>
                <v-row>
                  <v-col>Custo para Skyler:</v-col>
                </v-row>
                <v-row>
                  <v-col cols="auto">
                    <v-checkbox
                      v-model="editedItem.custo_skyler"
                      label="Há custos para a Skyler?"
                    >
                    </v-checkbox>
                  </v-col>
                </v-row>
                <div>
                  <v-container v-if="editedItem.custo_skyler">
                    <v-row>
                      <v-col cols="auto">
                        <v-moeda-field
                          outlined
                          dense
                          v-model="editedItem.custo_skyler_valor"
                        ></v-moeda-field>
                      </v-col>
                    </v-row>
                    <v-row>
                      <v-col cols="auto">
                        <v-datetime-text-field
                          dense
                          outlined
                          v-model="editedItem.estorno_skyler_data_previsto"
                          label="Data Prevista"
                        ></v-datetime-text-field>
                      </v-col>
                      <v-col cols="auto">
                        <v-datetime-text-field
                          dense
                          outlined
                          v-model="editedItem.estorno_skyler_data_realizado"
                          label="Data Realização"
                        ></v-datetime-text-field>
                      </v-col>
                    </v-row>
                    <v-row>
                      <v-col cols="-1">
                        <v-textarea
                          rows="3"
                          filled
                          label="Anotações"
                          v-model="editedItem.custo_skyler_anotacao"
                        ></v-textarea>
                      </v-col>
                    </v-row>
                  </v-container>
                </div>
                <v-divider></v-divider>
                <v-row>
                  <v-col>Cobrança do cliente:</v-col>
                </v-row>
                <v-row>
                  <v-col cols="auto">
                    <v-checkbox
                      v-model="editedItem.cobranca_cliente"
                      label="Há cobrança para o cliente?"
                    >
                    </v-checkbox>
                  </v-col>
                </v-row>
                <div>
                  <v-container v-if="editedItem.cobranca_cliente">
                    <v-row>
                      <v-col cols="auto">
                        <v-select
                          v-model="editedItem.cobranca_cliente"
                          label="Via de cobrança"
                          :items="tiposCobranca"
                        >
                        </v-select>
                      </v-col>
                      <v-col
                        cols="auto"
                        v-if="editedItem.cobranca_cliente === 'fornecedor'"
                      >
                        <v-select
                            :items="fornecedores"
                            v-model="editedItem.cobranca_cliente_fornecedor"
                            label="Fornecedor"
                            dense
                            item-text="nome"
                            item-value="id"
                        />
                      </v-col>
                    </v-row>
                    <v-row>
                      <v-col cols="auto">
                        <v-moeda-field
                          outlined
                          dense
                          v-model="editedItem.cobranca_cliente_valor"
                        ></v-moeda-field>
                      </v-col>
                    </v-row>
                    <v-row>
                      <v-col cols="auto">
                        <v-datetime-text-field
                          dense
                          outlined
                          v-model="editedItem.estorno_cliente_data_previsto"
                          label="Data Prevista"
                        ></v-datetime-text-field>
                      </v-col>
                      <v-col cols="auto">
                        <v-datetime-text-field
                          dense
                          outlined
                          v-model="editedItem.estorno_cliente_data_realizado"
                          label="Data Realização"
                        ></v-datetime-text-field>
                      </v-col>
                    </v-row>
                    <v-row>
                      <v-col cols="auto">
                        <PagarMe 
                          v-if="editedItem.cobranca_cliente === 'skyler'"
                          @updatePagamentos="updatePagamentos" 
                          type="pix"
                          color="blue"
                          icon="mdi-qrcode-plus"
                          :disabled="!allowSave"
                          :servicosProp="servicos"
                          :pagamentosVenda="pagamentos"
                          :valorPagamentoRAC="editedItem.cobranca_cliente_valor"
                        />
                        <PagarMe 
                          v-if="editedItem.cobranca_cliente === 'skyler'"
                          @updatePagamentos="updatePagamentos" 
                          type="cartao"
                          icon="mdi-credit-card-plus"
                          :disabled="!allowSave"
                          :servicosProp="servicos"
                          :pagamentosVenda="pagamentos"
                          :valorPagamentoRAC="editedItem.cobranca_cliente_valor"
                        />
                      </v-col>
                    </v-row>
                    <v-row>
                      <v-col cols="-1">
                        <v-textarea
                          rows="3"
                          dense
                          filled
                          label="Anotações"
                          v-model="editedItem.cobranca_cliente_anotacao"
                        ></v-textarea>
                      </v-col>
                    </v-row>
                  </v-container>
                </div>
              </v-card-text>
              <!-- Card Action buttons -->
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="blue darken-1" text @click="close">
                  Cancelar
                </v-btn>
                <v-btn
                  :disabled="!allowSave"
                  color="blue darken-1"
                  text
                  @click="save"
                >
                  Salvar
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>
        </v-toolbar>
      </template>

      <template v-slot:item.actions="{ item }">
        <v-icon small class="mr-2" @click="viewRacItem(item)"> mdi-eye </v-icon>
        <v-icon small class="mr-2" @click="viewServicoItem(item)">
          mdi-hammer
        </v-icon>
        <v-icon small class="mr-2" @click="viewDocItem(item)">
          mdi-file
        </v-icon>
        <v-icon small class="mr-2" @click="viewProtocolosItem(item)">
          mdi-file-document-outline
        </v-icon>
        <v-icon
          small
          class="mr-2"
          @click="viewStatusRac(item)"
          :disabled="!isFinanceiro"
        >
          mdi-list-status
        </v-icon>
      </template>
      <template v-slot:no-data>
        <v-btn color="primary" @click="initialize"> Reset </v-btn>
      </template>
    </v-data-table>
    <v-dialog v-model="documentosDialog" max-width="75%">
      <VendaRacsListDialogDocumentos
        :dialog="documentosDialog"
        :documentos="documentos"
        @adicionarDocumento="addDocumento"
        @closeDialog="documentosDialog = false"
      />
    </v-dialog>
    <v-dialog v-model="servicosDialog" max-width="75%">
      <VendaRacsListDialogServicos
        :dialog="servicosDialog"
        :servicosRelacionados="servicosRelacionados"
        :servicosCliente="servicosCliente"
        @adicionarServico="addServico"
        @closeDialog="servicosDialog = false"
      />
    </v-dialog>
    <v-dialog v-model="protocolosDialog" max-width="75%">
      <VendaRacsListDialogProtocolos
        :dialog="protocolosDialog"
        :protocolos="protocolos"
        @adicionarProtocolo="addProtocolo"
        @closeDialog="protocolosDialog = false"
      />
    </v-dialog>
    <v-dialog v-model="statusRacDialog" max-width="75%">
      <VendaRacsListDialogStatus
        :dialog="statusRacDialog"
        :statusRac="statusRac"
        @mudarStatusRac="setStatusRac"
        @closeDialog="statusRacDialog = false"
      />
    </v-dialog>
  </div>
</template>
<script>
import EmissaoDetalheTabDocumentos from "@/components/Emissao/Tabs/EmissaoDetalheTabDocumentos";

import CotacaoMixin from "@/mixins/CotacaoMixin.js";
import VMoedaField from "@/components/widgets/VMoedaField";
import VFieldValue from "@/components/widgets/VFieldValue";
import api from "@/api/api.js";
import { formatApiError } from "@/helpers/apiErrors";
import VLinkTextField from "@/components/widgets/VLinkTextField";
import PagamentoRAC from "@/components/Emissao/Tabs/Pagamento/PagamentoRAC";
import VDatetimeTextField from "@/components/widgets/VDatetimeTextField";
import VendaRacsListDialogDocumentos from "@/components/venda/rac/VendaRacsListDialogDocumentos.vue";
import VendaRacsListDialogServicos from "@/components/venda/rac/VendaRacsListDialogServicos.vue";
import VendaRacsListDialogProtocolos from "@/components/venda/rac/VendaRacsListDialogProtocolos.vue";
import VendaRacsListDialogStatus from "@/components/venda/rac/VendaRacsListDialogStatus.vue";
import TIPOS from "@/constants/tipoRac";
import STATUS from "@/constants/statusRac";
import PagarMe from '@/components/Pagamento/PagarMe';

const TIPOS_COBRANCA = ["skyler", "fornecedor"];
const TIPOS_ESTORNO_MILHAS = ["pix", "saldo"];
const TIPOS_ESTORNO_PAGANTE = ["pix", "cartao"];

export default {
  components: {
    VMoedaField,
    VFieldValue,
    VLinkTextField,
    PagamentoRAC,
    EmissaoDetalheTabDocumentos,
    VDatetimeTextField,
    VendaRacsListDialogDocumentos,
    VendaRacsListDialogServicos,
    VendaRacsListDialogProtocolos,
    VendaRacsListDialogStatus,
    PagarMe,
  },
  mixins: [CotacaoMixin],
  props: {
    servicos: {
      type: Array,
      default: [],
    },
    cotacoes: {
      type: Array,
      default: () => [],
    },
    venda: {
      type: Object,
      default: () => {},
    },
    allowSave: {
      type: Boolean,
      default: true,
    },
  },
  data: () => ({
    dialog: false,
    headers: [
      {
        text: "ID RAC",
        align: "start",
        sortable: false,
        value: "id",
      },
      { text: "ID Emissão", value: "emissao", sortable: false },
      { text: "Tipo", value: "tipo", sortable: false },
      { text: "Status", value: "status", sortable: false },
      { text: "Criado Por", value: "created_by", sortable: false },
      { text: "Criado Em", value: "created_at", sortable: false },
      { text: "Ações", value: "actions", sortable: false },
    ],
    emissaoAssociada: null,
    racs: [],
    cards: [],
    emissoes: [],
    emissoesRac: [],
    pagamentos: [],
    racTipos: TIPOS,
    statusRac: STATUS,
    tiposCobranca: TIPOS_COBRANCA,
    tiposEstornoPagante: TIPOS_ESTORNO_PAGANTE,
    tiposEstornoMilhas: TIPOS_ESTORNO_MILHAS,
    editedIndex: -1,
    editedItem: {
      tipo: TIPOS[0].key,
    },
    defaultItem: {
      tipo: TIPOS[0].key,
    },
    documentos: [],
    documentosDialog: false,
    servicosCliente: [],
    servicosDialog: false,
    statusRacDialog: false,
    servicosRelacionados: [],
    protocolos: [],
    protocolosDialog: false,
    isFinanceiro: false,
  }),


  computed: {
    loggedUser() {
      return this.$store.getters["usuario/loggedUser"];
    },
    vendaId() {
      return this.$route.params.id;
    },
    editedEmissao() {
      return this.emissaoporId(this.emissaoAssociada);
    },
    formTitle() {
      if (!this.allowSave) return "Visualizar RAC";
      return this.editedIndex === -1 ? "Nova RAC" : "Editar RAC";
    },
    pagamentosDefault() {
      return this.camposFormadores[this.emissao.tipo]
        .filter((campo) => !this.campoZerado(this.cotacaoFormadora[campo.nome]))
        .map((campo) => ({
          campo_formador: campo.nome,
        }));
    },
    fornecedores() {
      return this.$store.getters['global/fornecedores']
    }
  },

  watch: {
    dialog(val) {
      val || this.close();
    },

    emissaoAssociada(val) {
      this.editedItem.emissao = val;
      if (val) {
        api
          .getPagamentoEmissao(val)
          .then((res) => {
            const data = res.data;
            !data.length
              ? (this.pagamentos = this.pagamentosDefault)
              : (this.pagamentos = this.unformatResponsavel(data));
          })
          .catch((err) => {
            console.error(err);
          });
      }
      if (!this.emissaoporId(val)?.subcotacao_voo?.id) return;
      api
        .getValidacoesSubcotacao(this.emissaoporId(val)?.subcotacao_voo?.id)
        .then((res) => (this.validacoes = res.data))
        .catch((err) => {
          console.error(err);
        });
    },
  },
  created() {
    this.initialize();
    this.financeiro();
  },

  methods: {
    initialize() {
      api.getCartoes().then((res) => (this.cards = res.data));

      api
        .getRACSVenda(this.venda.id)
        .then((res) => {
          this.racs = res.data;
        })
        .catch((err) =>
          this.$root.vtoast.show({
            message: "Problema ao carregar RACs.",
            color: "error",
          })
        );
      api
        .getEmissoesConcluidasVenda(this.vendaId)
        .then((res) => (this.emissoes = res.data))
        .catch((err) =>
          this.$root.vtoast.show({
            message: "Problema ao carregar emissões.",
            color: "error",
          })
        );
      api
        .getEmissoesVenda(this.vendaId)
        .then((res) => (this.emissoesRac = res.data))
        .catch((err) =>
          this.$root.vtoast.show({
            message: "Problema ao carregar emissões.",
            color: "error",
          })
        );
      this.editedItem = { ...this.editedItem, venda: this.venda.id };
      this.defaultItem = { ...this.defaultItem, venda: this.venda.id };
      this.$store.dispatch("global/fetchFornecedores")
    },
    unformatResponsavel(pagamentos) {
      return pagamentos.map((pagamento) => ({
        ...pagamento,
        responsavel:
          pagamento.responsavel === "skyler"
            ? 1
            : pagamento.responsavel === "cliente"
            ? 0
            : null,
      }));
    },

    financeiro() {
      const pertencente = this.loggedUser.pertencente;
      const possuiFinanceiro = pertencente.includes("financeiro");
      if (possuiFinanceiro) {
        this.isFinanceiro = true;
      }
    },

    emissaoporId(id) {
      return this.emissoesRac.find((e) => e.id == id);
    },

    viewProtocolosItem(item) {
      this.edittingItem = item;
      api
        .getRACProtocolos(this.edittingItem.id)
        .then((res) => {
          const data = res.data;
          this.protocolos = data;
          this.editedIndex = this.racs.indexOf(item);
          this.protocolosDialog = true;
        })
        .catch((err) => {
          console.error(err);
        });
    },
    addProtocolo(protocolo) {
      api
        .postRACProtocolo(this.edittingItem.id, protocolo)
        .then((res) => {
          this.$root.vtoast.show({
            message: "Protocolo cadastrado com sucesso",
          });
          this.protocolosDialog = false;
        })
        .catch((err) => {
          console.error(err);
          window.alert(formatApiError(err));
        });
    },

    viewDocItem(item) {
      this.edittingItem = item;
      api
        .getRACDocumentos(this.edittingItem.id)
        .then((res) => {
          const data = res.data;
          this.documentos = data;
          this.editedIndex = this.racs.indexOf(item);
          this.documentosDialog = true;
        })
        .catch((err) => {
          console.error(err);
        });
    },
    addDocumento(documento) {
      api
        .postRACDocumento(this.edittingItem.id, documento)
        .then((res) => {
          this.$root.vtoast.show({
            message: "Documento cadastrado com sucesso",
          });
          this.documentosDialog = false;
        })
        .catch((err) => {
          window.alert(formatApiError(err));
        });
    },

    viewServicoItem(item) {
      this.edittingItem = item;
      for (let j = 0; j < this.servicos.length; j++) {
        if (this.servicos[j].id != this.edittingItem.emissao.servico) {
          this.servicosCliente = this.servicos[j];
        }
      }

      api
        .getRACServicos(this.edittingItem.id)
        .then((res) => {
          const data = res.data;
          this.servicosRelacionados = data;
        })
        .catch((err) => {
          console.error(err);
        });
      this.editedIndex = this.racs.indexOf(item);
      this.servicosDialog = true;
    },

    viewStatusRac(item) {
      this.edittingItem = item;
      this.statusRacDialog = true;
    },

    addServico(servico) {
      api
        .putRACServico(this.edittingItem.id, servico)
        .then((res) => {
          this.$root.vtoast.show({ message: "Serviço cadastrado com sucesso" });
          this.servicosDialog = false;
        })
        .catch((err) => {
          console.error(err);
          window.alert(formatApiError(err));
        });
    },

    setStatusRac(status) {
      api
        .postStatusRac(this.edittingItem.id, status)
        .then((res) => {
          this.$root.vtoast.show({
            message: "Novo status adicionado!",
            color: "success",
            icon: "mdi-check",
          });
          this.initialize();
        })
        .catch((err) => {
          console.error(err);
          window.alert(formatApiError(err));
        });

      this.statusRacDialog = false;
    },

    viewRacItem(item) {
      this.allowSave = false;
      this.edittingItem = item;
      this.editedIndex = this.racs.indexOf(item);
      this.emissaoAssociada = item.emissao.id;
      this.editedItem = Object.assign({}, item);
      this.dialog = true;
    },

    close() {
      this.dialog = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
        this.emissaoAssociada = null;
        this.pagamentos = [];
        this.documentos = [];
        this.servicosRelacionados = [];
        this.allowSave = true;
      });
    },

    async save() {
      const editedPagamentos = this.$refs.pagamentoRac.save();
      this.editedItem = {
        ...this.editedItem,
        venda: this.vendaId,
        emissao: this.emissaoAssociada,
      };
      api
        .addRACPagamentos({
          racs: this.editedItem,
          pagamentos: editedPagamentos,
        })
        .then((res) => {
          this.$root.vtoast.show({ message: "RAC cadastrada com sucesso!" });
          this.initialize();
        })
        .catch((err) => {
          console.error(err);
          window.alert(formatApiError(err));
        });
      this.close();
    },
    updatePagamentos(pagamento) {
      if (!this.pagamentosVenda) this.pagamentosVenda = [];
      this.pagamentosVenda.push(pagamento)
    },

  },
};
</script>
