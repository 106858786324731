<template>
    <v-container >

        <v-form id="busca-form">
            <v-btn
                            color="primary"
                            @click="clickBusca()"
                            :loading="loadingBusca"
                            v-if="!parsed_data"
                            :disabled="checkUserPermissao"
                            >
                            Buscar
                    </v-btn>
        <v-list v-if="parsed_data">
            <v-list-item>Pesquisa feita por {{parsed_data.search_information.user}} em {{parsed_data.search_information.data}}</v-list-item>
    <CardBusca label="Informações Gerais" :legenda="legendaCompany" 
        :selectedInformation="selectedCompanyInformation" 
        :information="parsed_data.company_information">
    </CardBusca>

    <CardBusca label="Processos" :flag="parsed_data.process_information.flag"  
        :selectedInformation="selectedProcessInformation"
        :legenda="legendaProcess"
        :information="parsed_data.process_information"
        >
    </CardBusca>
        <CardBusca label="Restrições" :flag="parsed_data.restriction_information.flag" 
        :legenda="legendaRestriction" :selectedInformation="selectedRestrictionInformation" 
        :information="parsed_data.restriction_information">
    </CardBusca>

    <CardBusca label="Cheques sem fundo ou sustados" :flag="parsed_data.bank_check_information.flag" 
        :legenda="legendaBankCheck" :selectedInformation="selectedBankCheckInformation" 
        :information="parsed_data.bank_check_information"
        >
    </CardBusca>

    <CardBusca label="Protestos" :flag="parsed_data.protest_information.flag" 
        :legenda="legendaBankCheck" :selectedInformation="selectedProtestInformation" 
        :information="parsed_data.protest_information"
        
        >
    </CardBusca>

    <CardBusca label="Score de Crédito" :flag="parsed_data.credit_score_information.flag" 
        :legenda="legendaCreditScore" :selectedInformation="selectedCreditScoreInformation" 
        :information="parsed_data.credit_score_information"
        >
    </CardBusca>

    <CardBusca label="Outros Documentos" 
        :legenda="legendaRestriction" :selectedInformation="selectedOtherDocumentInformation" 
        :information="parsed_data.other_document_information"
        >
    </CardBusca>

    <CardBusca label="Parceiros" 
            :legenda="legendaRestriction" :selectedInformation="selectedPartnershipsInformation" 
            :information="parsed_data.partnerships_information"
            >
    </CardBusca>

    <CardBusca label="Sócios" 
    :legenda="legendaShareholders" :selectedInformation="selectedShareholdersInformation" 
    :information="parsed_data.shareholder_information"
    >
    </CardBusca>
    
</v-list>
        </v-form>
    </v-container>

</template>

<script>

import CopyTextField from "@/components/widgets/CopyTextField.vue";
import CardBusca from "@/components/widgets/CardBusca.vue";
import checkPermissao from "@/helpers/checkUserPermissao.js";
import api from "@/api/api.js"
import {companyInformation as COMPANY_INFORMATION,
        lawProcessInformation as LAW_PROCESS_INFORMATION,
        protestInformation as PROTEST_INFORMATION,
        phoneInformation as PHONE_INFORMATION,
        emailInformation as EMAIL_INFORMATION,
        bankCheckInformation as BANK_CHECK_INFORMATION,
        restrictionInformation as RESTRICTION_INFORMATION,
        otherdocumentInformation as OTHER_DOCUMENT_INFORMATION,
        shareholderInformation as SHAREHOLDER_INFORMATION,
        partnershipInformation as PARTNERSHIPS_INFORMATION,
        creditScoreInformation as CREDIT_SCORE_INFORMATION} from "@/constants/databusca.js";
import {selectedCompanyInformation as SELECTED_COMPANY_INFORMATION,
        selectedProcessInformation as SELECTED_PROCESS_INFORMATION,
        selectedRestrictionInformation as SELECTED_RESTRICTION_INFORMATION,
        selectedBankCheckInformation as SELECTED_BANK_CHECK_INFORMATION,
        selectedCreditScoreInformation as SELECTED_CREDIT_SCORE_INFORMATION,
        selectedEmailInformation as SELECTED_EMAIL_INFORMATION,
        selectedOtherDocumentInformation as SELECTED_OTHER_DOCUMENT_INFORMATION,
        selectedPhonesInformation as SELECTED_PHONES_INFORMATION,
        selectedProtestInformation as SELECTED_PROTEST_INFORMATION,
        selectedPartnershipInformation as SELECTED_PARTNERSHIPS_INFORMATION,
        selectedShareholderInformation as SELECTED_SHAREHOLDER_INFORMATION} from "@/constants/databuscaSelected.js";

export default {
    
    props: {
        empresa: {
            type: Object,
            default: () => {}
        }
    },
    components: { CopyTextField, CardBusca },

    data() {
        return {
            selectedRestrictionInformation: SELECTED_RESTRICTION_INFORMATION,
            selectedCompanyInformation: SELECTED_COMPANY_INFORMATION,
            selectedProcessInformation: SELECTED_PROCESS_INFORMATION,
            selectedBankCheckInformation: SELECTED_BANK_CHECK_INFORMATION,
            selectedOtherDocumentInformation: SELECTED_OTHER_DOCUMENT_INFORMATION,
            selectedProtestInformation: SELECTED_PROTEST_INFORMATION,
            selectedEmailInformation: SELECTED_EMAIL_INFORMATION,
            selectedPhoneInformation: SELECTED_PHONES_INFORMATION,
            selectedCreditScoreInformation: SELECTED_CREDIT_SCORE_INFORMATION,
            selectedPartnershipsInformation: SELECTED_PARTNERSHIPS_INFORMATION,
            selectedShareholdersInformation: SELECTED_SHAREHOLDER_INFORMATION,
            
            dialog:false,
            legendaCompany: COMPANY_INFORMATION,
            legendaRestriction: RESTRICTION_INFORMATION,
            legendaBankCheck: BANK_CHECK_INFORMATION,
            legendaProcess: LAW_PROCESS_INFORMATION,
            legentaOtherDocument: OTHER_DOCUMENT_INFORMATION, 
            legendaProtest: PROTEST_INFORMATION, 
            legendaEmail: EMAIL_INFORMATION,
            legendaPhone: PHONE_INFORMATION,
            legendaCreditScore: CREDIT_SCORE_INFORMATION,
            legendaPartnerships: PARTNERSHIPS_INFORMATION,
            legendaShareholders: SHAREHOLDER_INFORMATION,

            loadingBusca: false,

            parsed_data: null,
        }
    },
    methods: {
    clickBusca() {
        this.loadingBusca = true
        if(!this.empresa.cnpj) {
                this.$root.vtoast.show({
                    message: 'CNPJ não disponível',
                    color: 'error',
                    icon: 'mdi-close',
                })
                this.loadingBusca = false
                return
            }

        api.postDataBuscaAPIEmpresa(this.empresa.id, this.empresa.cnpj).then(response => {
                this.loadingBusca = false
                this.parsed_data = JSON.parse(response.data.formatted_data)
            }).catch(e => {
                this.loadingBusca = false
                this.$root.vtoast.show({
                    message: 'Erro ao fazer a busca',
                    color: 'error',
                    icon: 'mdi-close'
                })
                console.error(e)
            })
        },
    },
    computed: {
        user() {
            return this.$store.getters['usuario/loggedUser'];
        },
        checkUserPermissao(){
            const pertencente = this.user.pertencente;
            return checkPermissao(pertencente);
        }
    },
    mounted() {
        this.parsed_data = JSON.parse(this.empresa.data_busca.formatted_data)
    }
}

</script>