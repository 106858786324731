import Vue from 'vue'

const CLASSES = [
  {key: "economica", value: "Econômica"},
  {key: "executiva", value: "Executiva"},
]

const formatClasse = (classe) => {
    if(!classe) return ""
    const classeObj = CLASSES.find(
        e => e.key === classe
    )
    if(classeObj) return classeObj.value
    return classe
}

Vue.filter("formatClasse", formatClasse);