<template>
  <v-container fluid v-if="form" class="ma-0">
    <v-row>
      <v-col>
        <v-radio-group
          :disabled="disabled"
          dense
          row
          v-model="form.tipo_trecho"
        >
          <v-radio label="Ida e Volta" value="ida_volta"></v-radio>
          <v-radio label="Só Ida" value="so_ida"></v-radio>
          <v-radio label="Várias cidades" value="multitrecho"></v-radio>
        </v-radio-group>
      </v-col>
    </v-row>
    <v-row class="ma-0" v-if="this.form.tipo_trecho !== 'multitrecho'">
      <v-col>
        <search-aeroporto
          :disabled="disabled"
          outlined
          label="Origem"
          v-model="ida_volta.origem"
        ></search-aeroporto>
        <v-checkbox
          label="Flexibilidade"
          v-model="ida_volta.flexibilidade_origem"
        ></v-checkbox>
      </v-col>
      <v-col>
        <search-aeroporto
          :disabled="disabled"
          v-model="ida_volta.destino"
          outlined
          label="Destino"
        ></search-aeroporto>
        <v-checkbox
          label="Flexibilidade"
          v-model="ida_volta.flexibilidade_destino"
        ></v-checkbox>
      </v-col>
      <v-col>
        <form-date-picker-multiple
          :disabled="disabled"
          v-model="ida_volta.ida"
          outlined
          label="Ida"
        ></form-date-picker-multiple>
      </v-col>
      <v-col>
        <form-date-picker-multiple
          v-model="ida_volta.volta"
          multiple
          outlined
          label="Volta"
          :disabled="disabled || form.tipo_trecho === 'so_ida'"
        ></form-date-picker-multiple>
      </v-col>
      <v-col align-self="center">
        <v-menu
          v-model="showOptionsMenu"
          :close-on-content-click="false"
          :nudge-width="200"
          offset-x
        >
          <template v-slot:activator="{ on, attrs }">
            <v-textarea
              outlined
              v-bind="attrs"
              v-on="on"
              readonly
              :disabled="disabled"
              no-resize
              rows="3"
              dense
              height="auto"
              :value="getClassePaxLabel"
            >
              <template v-slot:append>
                <v-btn
                  small
                  icon
                  :disabled="disabled"
                  color="primary"
                  v-bind="attrs"
                  v-on="on"
                >
                  <v-icon> mdi-dots-vertical </v-icon>
                </v-btn>
              </template>
            </v-textarea>
          </template>

          <v-card>
            <v-divider></v-divider>
            <v-list>
              <v-list-item>
                <v-list-item-content>
                  <v-list-item-title>Classes</v-list-item-title>
                </v-list-item-content>
              </v-list-item>
              <v-list-item-action>
                <v-row>
                  <v-col cols="auto">
                    <v-checkbox
                      dense
                      label="Econômica"
                      v-model="form.economica"
                    ></v-checkbox>
                  </v-col>
                  <v-col cols="auto">
                    <v-checkbox
                      dense
                      label="Econômica Premium"
                      v-model="form.economica_premium"
                    ></v-checkbox>
                  </v-col>
                  <v-col cols="auto">
                    <v-checkbox
                      dense
                      label="Executiva"
                      v-model="form.executiva"
                    ></v-checkbox>
                  </v-col>
                  <v-col cols="auto">
                    <v-checkbox
                      dense
                      label="Primeira Classe"
                      v-model="form.primeira_classe"
                    ></v-checkbox>
                  </v-col>
                </v-row>
              </v-list-item-action>
            </v-list>
            <v-divider></v-divider>

            <v-list>
              <v-list-item>
                <v-list-item-content>
                  <v-list-item-title>
                    Quantidade de passageiros
                  </v-list-item-title>
                </v-list-item-content>
              </v-list-item>
              <v-list-item>
                <v-list-item-action>
                  <v-text-field dense label="Adultos" v-model="form.adultos">
                  </v-text-field>
                </v-list-item-action>
                <v-list-item-title>Mais que 16 anos</v-list-item-title>
              </v-list-item>
              <v-list-item>
                <v-list-item-action>
                  <v-text-field dense label="Crianças" v-model="form.criancas">
                  </v-text-field>
                </v-list-item-action>
                <v-list-item-title>2-15 anos</v-list-item-title>
              </v-list-item>
              <v-list-item>
                <v-list-item-action>
                  <v-text-field dense label="Bebês" v-model="form.bebes">
                  </v-text-field>
                </v-list-item-action>
                <v-list-item-title>0-2 anos</v-list-item-title>
              </v-list-item>
            </v-list>
          </v-card>
        </v-menu>
      </v-col>
    </v-row>
    <v-row fluid class="ma-0" v-else>
      <v-container fluid>
        <v-row>
          <v-col cols="12" md="9">
            <v-row v-for="(trecho, index) in multitrecho" :key="index">
              <v-col cols="4">
                <search-aeroporto
                  outlined
                  label="Origem"
                  v-model="trecho.origem"
                ></search-aeroporto>
                <v-checkbox
                  label="Flexibilidade"
                  v-model="trecho.flexibilidade_origem"
                ></v-checkbox>
              </v-col>
              <v-col cols="4">
                <search-aeroporto
                  outlined
                  label="Destino"
                  v-model="trecho.destino"
                ></search-aeroporto>
                <v-checkbox
                  label="Flexibilidade"
                  v-model="trecho.flexibilidade_destino"
                ></v-checkbox>
              </v-col>
              <v-col cols="3">
                <form-date-picker-multiple
                  outlined
                  label="Data"
                  v-model="trecho.datas"
                ></form-date-picker-multiple>
              </v-col>
              <v-col cols="1">
                <v-btn icon @click="removeTrecho(trecho)">
                  <v-icon>mdi-close</v-icon>
                </v-btn>
              </v-col>
            </v-row>
          </v-col>
          <v-divider vertical />
          <v-col cols="12" md="3">
            <v-menu
              v-model="showOptionsMenu"
              :close-on-content-click="false"
              :nudge-width="200"
              offset-x
            >
              <template v-slot:activator="{ on, attrs }">
                <v-textarea
                  outlined
                  v-bind="attrs"
                  v-on="on"
                  readonly
                  :disabled="disabled"
                  no-resize
                  rows="3"
                  dense
                  height="auto"
                  :value="getClassePaxLabel"
                >
                  <template v-slot:append>
                    <v-btn
                      small
                      icon
                      :disabled="disabled"
                      color="primary"
                      v-bind="attrs"
                      v-on="on"
                    >
                      <v-icon> mdi-dots-vertical </v-icon>
                    </v-btn>
                  </template>
                </v-textarea>
              </template>

              <v-card>
                <v-divider></v-divider>
                <v-list>
                  <v-list-item>
                    <v-list-item-content>
                      <v-list-item-title>Classes</v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>
                  <v-list-item-action>
                    <v-row>
                      <v-col cols="auto">
                        <v-checkbox
                          dense
                          label="Econômica"
                          v-model="form.economica"
                        ></v-checkbox>
                      </v-col>
                      <v-col cols="auto">
                        <v-checkbox
                          dense
                          label="Econômica Premium"
                          v-model="form.economica_premium"
                        ></v-checkbox>
                      </v-col>
                      <v-col cols="auto">
                        <v-checkbox
                          dense
                          label="Executiva"
                          v-model="form.executiva"
                        ></v-checkbox>
                      </v-col>
                      <v-col cols="auto">
                        <v-checkbox
                          dense
                          label="Primeira Classe"
                          v-model="form.primeira_classe"
                        ></v-checkbox>
                      </v-col>
                    </v-row>
                  </v-list-item-action>
                </v-list>
                <v-divider></v-divider>

                <v-list>
                  <v-list-item>
                    <v-list-item-content>
                      <v-list-item-title>
                        Quantidade de passageiros
                      </v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>
                  <v-list-item>
                    <v-list-item-action>
                      <v-text-field
                        dense
                        label="Adultos"
                        v-model="form.adultos"
                      >
                      </v-text-field>
                    </v-list-item-action>
                    <v-list-item-title>Mais que 16 anos</v-list-item-title>
                  </v-list-item>
                  <v-list-item>
                    <v-list-item-action>
                      <v-text-field
                        dense
                        label="Crianças"
                        v-model="form.criancas"
                      >
                      </v-text-field>
                    </v-list-item-action>
                    <v-list-item-title>0-15 anos</v-list-item-title>
                  </v-list-item>
                </v-list>
              </v-card>
            </v-menu>
          </v-col>
        </v-row>
      </v-container>
      <v-container fluid>
        <v-row>
          <v-col cols="auto">
            <v-btn small color="primary" @click="addTrecho">
              <v-icon small color="white"> mdi-plus </v-icon>
              Adicionar trecho
            </v-btn>
          </v-col>
        </v-row>
      </v-container>
    </v-row>
  </v-container>
</template>
<script>
import SearchAeroporto from "@/components/widgets/SearchAeroporto";
import FormDatePicker from "@/components/widgets/FormDatePicker";
import FormDatePickerMultiple from "@/components/widgets/FormDatePickerMultiple";
import { validateDates } from "@/helpers/dates";

export default {
  components: { FormDatePickerMultiple, FormDatePicker, SearchAeroporto },
  props: {
    value: {
      type: Object,
      required: false,
      default: () => null,
    },
    disabled: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  data() {
    return {
      showOptionsMenu: false,
      form: null,
      multitrecho: [],
      ida_volta: {
        origem: null,
        destino: null,
        ida: [],
        volta: [],
      },
      classes_opcoes: [
        {
          text: "Econômica",
          value: "economica",
        },
        {
          text: "Econômica Premium",
          value: "economica_premium",
        },
        {
          text: "Executiva",
          value: "executiva",
        },
        {
          text: "1a Classe",
          value: "primeira_classe",
        },
      ],
    };
  },
  computed: {
    nextTrechoordem() {
      return this.multitrecho.length;
    },
    getClassePaxLabel() {
      let classes = [];
      if (this.form.economica) {
        classes.push("Econômica");
      }
      if (this.form.economica_premium) {
        classes.push("Econômica Premium");
      }
      if (this.form.executiva) {
        classes.push("Executiva");
      }
      if (this.form.primeira_classe) {
        classes.push("1a Classe");
      }
      const classes_str = classes.join(", ");
      let arr_str = [];
      const totalPax =
        parseInt(this.form.adultos) + parseInt(this.form.criancas);
      arr_str.push(
        `${totalPax} passageiro${this.$options.filters.pluralize(totalPax)}`
      );
      arr_str.push(classes_str);
      return arr_str.join("\n");
    },
  },
  watch: {
    form: {
      immediate: true,
      deep: true,
      handler: function () {
        this.input();
      },
    },
    ida_volta: {
      immediate: true,
      deep: true,
      handler: function () {
        this.input();

        const { ida, volta } = this.ida_volta;
        if (validateDates(ida[0], volta[0])) {
          this.ida_volta.volta = [];
        }
      },
    },
    multitrecho: {
      immediate: true,
      deep: true,
      handler: function (val) {
        this.input();
      },
    },
  },
  created() {
    const defaultForm = {
      tipo_trecho: "ida_volta",
      economica: true,
      adultos: 1,
      criancas: 0,
      bebes: 0,
    };
    this.form = { ...defaultForm };
    if (this.value) {
      this.form = {
        ...this.form,
        ...this.value,
      };
    }
    this.setTrechos(this.form.tipo_trecho, this.form.trechos);
    this.input();
  },
  methods: {
    addTrecho() {
      const trecho = {
        ordem: this.nextTrechoordem,
        origem: null,
        destino: null,
        datas: [],
      };
      let newTrecho = Object.assign({}, trecho);
      this.multitrecho.push(newTrecho);
    },
    removeTrecho(trecho) {
      let index = this.multitrecho.indexOf(trecho);
      this.multitrecho.splice(index, 1);
    },
    setTrechos(type, trechos) {
      if (type !== "multitrecho" && trechos && trechos.length > 0) {
        let ida_volta = {
          origem: trechos[0].origem,
          destino: trechos[0].destino,
          ida: trechos[0].datas,
          volta: trechos[1] ? trechos[1].datas : [],
        };
        this.ida_volta = Object.assign({}, ida_volta);
      } else if (type === "multitrecho" && trechos) {
        this.multitrecho = [...trechos];
      } else {
        this.addTrecho();
      }
    },
    trechosCompletos() {
      const form = Object.assign({}, this.form);
      let trechos = [];
      switch (form.tipo_trecho) {
        case "so_ida":
          const so_ida = {
            ordem: 0,
            origem: this.ida_volta.origem,
            destino: this.ida_volta.destino,
            flexibilidade_origem: this.ida_volta.flexibilidade_origem,
            flexibilidade_destino: this.ida_volta.flexibilidade_destino,
            datas: this.ida_volta.ida,
          };
          trechos.push(so_ida);
          break;
        case "multitrecho":
          const trechosOrdenados = this.multitrecho.map((trecho, index) => {
            return {
              ...trecho,
              ordem: index,
            };
          });
          trechos.push(...trechosOrdenados);
          break;
        case "ida_volta":
          const trecho1 = {
            ordem: 0,
            origem: this.ida_volta.origem,
            destino: this.ida_volta.destino,
            flexibilidade_origem: this.ida_volta.flexibilidade_origem,
            flexibilidade_destino: this.ida_volta.flexibilidade_destino,
            datas: this.ida_volta.ida,
          };
          trechos.push(trecho1);
          const trecho2 = {
            ordem: 1,
            origem: this.ida_volta.destino,
            destino: this.ida_volta.origem,
            flexibilidade_origem: this.ida_volta.flexibilidade_destino,
            flexibilidade_destino: this.ida_volta.flexibilidade_origem,
            datas: this.ida_volta.volta,
          };
          trechos.push(trecho2);
          break;
      }

      return trechos;
    },
    input(val) {
      if (!this.form) return;
      const completeForm = {
        ...this.form,
        trechos: this.trechosCompletos(),
      };
      this.$emit("input", completeForm);
    },
  },
};
</script>
