<template>
    <div class="wrapper">
        <div class="header">
            <img src="@/assets/logo-skyler.svg" class="logo"/> 
            <div class="skyler-infos">
                <p>SKYLER TURISMO E VIAGENS LTDA</p>
                <p>Av. Paulista 1079 - 8° andar</p>
                <p>Bela Vista - CEP 01311-200</p>
                <p>São Paulo/SP</p>
                <p>CNPJ: 42.312.620/0001-06</p>
            </div>
        </div>
        <hr/>
    </div>
</template>
<script>
export default {
    
}
</script>
<style>
    .wrapper{
        width: 100%;
        padding-top: 3rem;
    }
    .logo{
        height: 5rem;
    }
    .header {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
    }
    .skyler-infos{
        display: flex;
        flex-direction: column;
        align-items: flex-end;
        margin-bottom: 1rem;
    }
    .skyler-infos > p {
        margin: 0;
    }
    hr{
        border-top: 1px solid black;
        width: 100%;
        margin: auto;
        margin-bottom: 2rem;
    }
</style>