<template >
    <v-card class="my-6 elevation-5" :loading="loading">
      <v-card-title>Serviços esperando pagamento</v-card-title>
      <v-card-text>
        <v-data-table
          :items="servicos"
          :headers="headers"
          v-if="servicos"
          :hide-default-footer="true"
        >
        <template v-slot:item.cotacao="{ item }">
            {{ item.cotacao?.id}}
        </template>
        <template v-slot:item.orcamento="{item}">
          <v-link-text-field :to="{name:'orcamento', params:{id:item?.cotacao?.orcamento?.id}}" text small>
            # {{ item.cotacao?.orcamento.id }}
          </v-link-text-field>
        </template>
          <template v-slot:item.status="{ item }">
            {{ item.status | formatStatusServico}}
          </template>
          <template v-slot:item.valor_pre_desconto="{ item }">
            {{ cotacaoValorTotalJuros(item.cotacao) | dinheiroReal }}
          </template>
          <template v-slot:item.valor_desconto="{ item }">
            {{ converteValorFinanceiroBRL(item.desconto) | dinheiroReal }}
          </template>
          <template v-slot:item.valorTotal="{ item }">
            {{ servicoValorTotal(item) | dinheiroReal }}
          </template>
          <template v-slot:item.metodo_pagamento="{ item }">
            {{ item.metodo_pagamento }}
          </template>
          <!-- <template v-slot:item.valorAberto="{ item }">
            {{ valorAberto[item.id] | dinheiroReal }}
          </template> -->
          <template v-slot:item.venda="{item}">
            <v-link-text-field :to="{name:'venda-pagamentos', params:{id:item?.venda?.id}}" text small>
              # {{ item.venda.id }}
            </v-link-text-field>
          </template>
          <template v-slot:item.consultor="{item}">
            {{item.venda.consultor |nomeSobrenome}}
          </template>
          <template v-slot:item.cliente="{item}">
            {{item.venda.cliente |nomeSobrenome}}
            {{ item.venda.cliente_empresa?.nome }}
          </template>
  
          <template v-slot:item.actions="{ item }">
            <v-btn icon color="transparent" @click="selectBestPractices(item.id)">
              <v-icon small color='green' class='mr-2'>mdi-check
            </v-icon>
            </v-btn>
            <v-btn icon color="transparent" @click="openCancelDialog(item.id)">
              <v-icon small color="red" class="mr-2">mdi-cancel</v-icon>
            </v-btn>
            <v-btn icon color="transparent" @click="select(item.id)">
              <v-icon small color="primary" class="mr-2">mdi-open-in-new</v-icon>
            </v-btn>
          </template>
        </v-data-table>
        <Paginator
        v-model="currentPage"
        :numPages="numPages"
        @pageChange="pageChange"
      />
        <v-dialog width="600px" persistent v-model="cancelDialog">
      <v-card>
        <v-card-title>Deseja mesmo cancelar esse serviço?</v-card-title>
        <v-card-text>
          Insira o id do serviço:
          <v-text-field v-model="cancelString" outlined></v-text-field>
          Justificativa de cancelamento
          <v-textarea
            v-model="justificativaCancelamento"
            :rules="[cancelamentoRule]"
            outlined
          />
        </v-card-text>
        <v-card-actions>
          <v-container>
            <v-row>
              <v-col align="left">
                <v-btn color="primary" @click="cancelDialog = false">
                  <v-icon> mdi-exit </v-icon>
                  Sair
                </v-btn>
              </v-col> 
              <v-col align="right">
                <v-btn color="error" :disabled="!canCancel" @click="cancel()">
                  <v-icon> mdi-cancel </v-icon>
                  Confirmar
                </v-btn>
              </v-col>
            </v-row>
          </v-container>
        </v-card-actions>
      </v-card>
    </v-dialog>
      <v-dialog width="600px" persistent v-model="openBestPracticesDialog">
    <v-card>
        <v-card-title>Seguiu o manual e boas práticas de pagamento?</v-card-title>
        <v-card-text>
          <div v-if="showReasonInput">
            <v-textarea
              label="Motivo*"
              v-model="reasonForNoBestPractices"
              :rules="[noBestPracticesRule]"
              outlined
              counter
              maxlength="50"
              />
          </div>
        </v-card-text>
            <v-card-actions>
              <v-container>
                <v-row>
                  <v-col align="left">
                    <v-btn color="primary" @click="closeBestPracticesDialog">
                      <v-icon> mdi-exit </v-icon>
                      Sair
                    </v-btn>
                  </v-col> 
                  <v-col align="right">
                    <v-btn color="error" v-if="showBestPracticesCancelButton" @click="showReason" class="mr-2">
                    Não
                    </v-btn>
                    <v-btn color="success" v-if="showBestPracticesConfirmButton" :loading="faturando" @click='faturarServico(item)'>
                    Sim
                    </v-btn>
                    <v-btn color="success" :disabled="!confirmNoBestPractices" v-if="showNoBestPracticesConfirmButton" :loading="faturando" @click='faturarServico(item)'>
                    Confirmar
                    </v-btn>
                  </v-col>
                </v-row>
              </v-container>
            </v-card-actions>
    </v-card>
      </v-dialog>
      </v-card-text>
    </v-card>
  </template>
  <script>
  import api from "@/api/api"
  import cotacaoMixin from "@/mixins/CotacaoMixin"
  import VLinkTextField from "@/components/widgets/VLinkTextField";
  import {formatApiError} from "@/helpers/apiErrors"
  import Paginator from '@/components/widgets/Paginator.vue'
  export default {
  
    mixins: [cotacaoMixin],
    components: {VLinkTextField, Paginator },
  
    props: {
      servicos: {
        type: Array,
        default: () => []
      },
      loading: {
        type: Boolean,
        default: true,
      },
      numPages: {
      type: Number,
      default: 1
      },
    },
  
    data: () => ({
      faturando: false,
      cancelId: null,
      cancelDialog: false,
      cancelString: "",
      justificativaCancelamento: "",
    cancelamentoRule: (string) =>
      (string.length >= 10 && string.length <= 50) ||
      "Justificativa deve ter entre 10 e 50 caracteres.",
      headers:[
        { text: "Servico ID", value: "id" },
        { text: "Venda ID", value: "venda" },
        { text: "Cliente", value: "cliente" },
        { text: "Consultor", value: "consultor" },
        { text: "Orçamento ID", value: "orcamento" },
        { text: "Cotação ID", value: "cotacao" },
        { text: "Valor pré Desconto", value: "valor_pre_desconto"},
        { text: "Valor Desconto", value: "valor_desconto"},
        { text: "Valor total", value: "valorTotal"},
        { text: "Método de Pagamento", value:"metodo_pagamento"},
        { text: "Status", value: "status" },
        { text: "Actions", value: "actions" },
      ],
      currentPage: 1,
      openBestPracticesDialog: false,
      bestPractices: true,
      reasonForNoBestPractices: '',
      showReasonInput: false,
      showBestPracticesConfirmButton: true,
      showNoBestPracticesConfirmButton: false,
      showBestPracticesCancelButton: true,
      itemId: null,
      noBestPracticesRule: (string) =>
      (string.length >= 10 && string.length <= 50) ||
      "Justificativa deve ter entre 10 e 50 caracteres.",
      }),
    computed: {
    canCancel() {
      return (
        this.cancelString === "" + this.cancelId &&
        this.justificativaCancelamento.length <= 50 &&
        this.justificativaCancelamento.length >= 10 
      );
    },
    confirmNoBestPractices(){
      return (
        this.reasonForNoBestPractices.length <= 50 &&
        this.reasonForNoBestPractices.length >= 10 
      );
    }
  },
    methods: {
      select(id) {
      this.$emit("select", id);
    },
      openCancelDialog(id) {
      this.cancelId = id;
      this.cancelDialog = true;
    },
      selectBestPractices(id) {
      this.itemId = id;
      this.openBestPracticesDialog = true;
    },

    cancel() {
      api.cancelarServico(this.cancelId, {motivo_cancelamento: this.justificativaCancelamento}).then(
        res => {
          this.$root.vtoast.show({
            message: "Servico cancelado com sucesso.",
            icon: "mdi-check",
            color: "success",
          })
          this.$emit('initialize')
          this.cancelDialog = false;
        }
      ).catch(
        err => {
          console.error(err)
          this.$root.vtoast.show({
            message: "Servico não pode ser cancelado no momento",
            icon: "mdi-error",
            color: "error",
          })
        }
      )},
      faturarServico(){
        this.faturando = true;
        api.faturarServico(this.itemId, {seguiu_manual_boas_praticas: this.bestPractices, motivo_nao_seguimento_boas_praticas: this.reasonForNoBestPractices}).then(
          res => {
            this.$root.vtoast.show({
                message: 'Servico faturado com sucesso!',
                color: 'success',
                icon: 'mdi-check'
            })
            this.$emit("initialize")
            setTimeout(() => (this.closeBestPracticesDialog()), 1000)
          }
        ).catch(
          err => {
            console.error(err)
            window.alert(formatApiError(e))
            this.$root.vtoast.show({
                message: 'Problema ao faturar servico!',
                color: 'error',
                icon: 'mdi-close'
            })
            setTimeout(() => (this.closeBestPracticesDialog()), 1000)
          }
        )
      },
      pageChange(page) {
      this.currentPage = page
      this.$emit('refreshPage', page)
      },
      showReason() {
        this.bestPractices = false
        this.showReasonInput = !this.showReasonInput
        this.showBestPracticesConfirmButton = !this.showBestPracticesConfirmButton
        this.showNoBestPracticesConfirmButton = !this.showNoBestPracticesConfirmButton
        this.showBestPracticesCancelButton = false
      },
      closeBestPracticesDialog(){
        this.bestPractices = true
        this.openBestPracticesDialog = false
        this.showReasonInput = false
        this.showBestPracticesConfirmButton = true
        this.showNoBestPracticesConfirmButton = false
        this.showBestPracticesCancelButton = true
        this.reasonForNoBestPractices = ''
        this.faturando = false
      },
    },
  }
  </script>