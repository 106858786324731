<template>
  <v-container>
    <v-form id="info-form" validate="true">
      <v-row
        ><v-checkbox
          dense
          label="Cliente Estrangeiro"
          name="cliente_estrangeiro"
          v-model="estangeiro"
          :value="isEstrangeiro"
        ></v-checkbox
      ></v-row>
      <v-row>
        <v-col cols="12" sm="6">
          <v-text-field
            label="Nome*"
            name="nome"
            prepend-inner-icon="mdi-account"
            outlined
            required
          />
        </v-col>

        <v-col cols="12" sm="6">
          <v-text-field
            label="Sobrenome*"
            name="sobrenome"
            prepend-inner-icon="mdi-account"
            outlined
            required
          />
        </v-col>
      </v-row>

      <v-row>
        <v-col cols="12" sm="6">
          <v-text-field
            label="CPF"
            name="cpf"
            v-model="models.cpf"
            v-mask="masks.cpf"
            :rules="[rules.cpf]"
            outlined
            prepend-inner-icon="mdi-card-account-details-outline"
          />
        </v-col>

        <v-col cols="12" sm="6">
          <v-select
            label="Empresa relacionada"
            :items="empresas"
            item-text="nome"
            item-value="id"
            name="empresa"
            prepend-inner-icon="mdi-domain"
            outlined
          />
        </v-col>
      </v-row>

      <v-row>
        <v-col cols="12" sm="6">
          <v-text-field
            label="CNH"
            name="cnh"
            outlined
            prepend-inner-icon="mdi-card-account-details-outline"
          />
        </v-col>

        <v-col cols="12" sm="6">
          <SearchContato v-model="contato" label="Indicação*" />
          <input type="hidden" name="indicacao" :value="contato?.id" />
        </v-col>
      </v-row>

      <v-row>
        <v-col cols="12" sm="6">
          <TelefoneField outlined />
        </v-col>
        <v-col cols="12" sm="6">
          <v-text-field
            label="Email"
            name="email"
            v-model="models.email"
            prepend-inner-icon="mdi-at"
            outlined
            :rules="emailRules"
          />
        </v-col>
      </v-row>

      <v-row>
        <v-col cols="12" sm="4">
          <FormDatePicker
            v-model="models.nasc"
            outlined
            icon="mdi-cake"
            :dense="false"
            label="Data de Nascimento"
            name="data_nascimento"
          />
        </v-col>
        <v-col cols="12" sm="4">
          <v-select
            label="Gênero"
            name="genero"
            outlined
            prepend-inner-icon="mdi-gender-male-female"
            :items="['Masculino', 'Feminino']"
          />
        </v-col>
        <v-col cols="12" sm="4">
          <v-select
            label="Tier"
            id="tier"
            name="tier"
            :items="['VIP', '1', '2']"
            outlined
            prepend-inner-icon="mdi-medal"
          />
        </v-col>
      </v-row>
    </v-form>
  </v-container>
</template>

<script>
import masks from "@/constants/masks";
import { validateCPF, validateCNPJ } from "@/helpers/identificadores";

import SearchContato from "@/components/widgets/SearchContato.vue";
import TelefoneField from "@/components/widgets/TelefoneField.vue";

import FormDatePicker from "@/components/widgets/FormDatePicker.vue";

export default {
  props: {
    contatos: Array,
    required: true,
  },
  components: { SearchContato, TelefoneField, FormDatePicker },
  data() {
    return {
      isEstrangeiro: null,
      estangeiro: null,

      masks: masks,

      rules: {
        cpf: (value) => !value || validateCPF(value) || "CPF não é valido",
        cnpj: (value) => !value || validateCNPJ(value) || "CNPJ não é valido",
      },

      contato: null,

      models: {
        email: null,
        cpf: null,
        tel: null,
        nasc: null,
      },

      emailRules: [
        (v) =>
          /.+@.+..+/.test(v) || "E-mail deve ser válido (exemplo@email.com)",
      ],
    };
  },
  watch: {
    estangeiro() {
      if (this.estangeiro) {
        this.isEstrangeiro = true;
      }
    },
  },
  computed: {
    empresas() {
      return this.$store.state.global.empresas;
    },
  },
  mounted() {
    this.$store.dispatch("global/fetchEmpresas");
  },
};
</script>