<template>
  <v-container fluid v-if="orcamento">
    <v-row>
      <v-col>
        Todos os campos são obrigatórios.
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <div v-if="notFound">
          Problema encontrado: Componente do tipo "{{ type }}" não encontrado.
        </div>
        <component v-else :is="component" v-model="orcamento" @input="input"></component>
        
      </v-col>
    </v-row>

  </v-container>
</template>

<script>

import NovoOrcamentoVoo from "@/components/Orcamento/Tipos/Voo/NovoOrcamentoVoo";
import NovoOrcamentoHotel from "@/components/Orcamento/Tipos/Hotel/NovoOrcamentoHotel";
import NovoOrcamentoOutro from "@/components/Orcamento/Tipos/Outro/NovoOrcamentoOutro";
import NovoOrcamentoCarro from "@/components/Orcamento/Tipos/Carro/NovoOrcamentoCarro";


export default {
  components: {NovoOrcamentoVoo, NovoOrcamentoCarro, NovoOrcamentoOutro, NovoOrcamentoHotel},
  props: {
    value: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      orcamento: null,
      notFound: false
    }
  },
  created() {
    this.orcamento = this.value ? {...this.value} : {};
  },
  methods: {
    input() {
      this.$emit('input', this.orcamento);
    }
  },
  computed: {
    component() {
      this.notFound = true;
      let map = {
        "orcamento_voo": "novo-orcamento-voo",
        "orcamento_carro": "novo-orcamento-carro",
        "orcamento_hotel": "novo-orcamento-hotel",
        "orcamento_outro": "novo-orcamento-outro"
      }
      if (!(this.type in map)) {
        throw Error("Componente não encontrado");
      }
      this.notFound = false
      return map[this.type];
    },
    type() {
      return this.orcamento && this.orcamento.tipo_servico;
    }
  }
}

</script>
