<template>
  <div>
    <v-row dense>
      <v-col cols="12" md="4">
        <v-autocomplete
            dense
            :items="opcoes_meio_contato"
            v-model="dados_contato.meio_contato"
            label="Meio de contato"
            @input="setMeioContato"
        >
          <template v-slot:selection="data">
            {{ data.item |capitalize }}
          </template>
        </v-autocomplete>
      </v-col>
      <v-col
          cols="12"
          md="8"
      >
        <v-text-field
            v-for="meio in opcoes_meio_contato"
            :key="meio"
            dense
            v-model="dados_contato[meio]"
            v-if="dados_contato.meio_contato===meio"
            @input="setMeioContato"
        >
          <template v-slot:label>
            {{ meio|capitalize }}
          </template>
        </v-text-field>
      </v-col>
    </v-row>
  </div>
</template>

<script>

export default {
  props: ['value'],
  data() {
    return {
      dados_contato: this.value,
      opcoes_meio_contato: [
        "whatsapp", "email", "instagram", "facebook", "telefone", "outro"
      ],
      meio_value: ""
    }
  },
  filters: {
    capitalize: function (value) {
      if (!value) return ''
      value = value.toString()
      return value.charAt(0).toUpperCase() + value.slice(1)
    }
  },
  created() {
    if (!this.dados_contato) {
      let dados_contato_default = {
        meio_contato: 'whatsapp',
        telefone: "",
        email: "",
        whatsapp: "",
        instagram: "",
        facebook: "",
        outro: ""
      };
      this.dados_contato = Object.assign({}, dados_contato_default)
    }
  },
  methods: {
    setMeioContato(item) {
      this.$emit('input', this.dados_contato)
    }
  }
}
</script>
