<template>
  <v-container class="pa-10" fluid v-if="!loading">
    <v-row>
      <v-col>
        <div
            class="text-h5 transition-swing"
        >
          <v-btn icon @click="backToVenda"><v-icon>mdi-arrow-left</v-icon></v-btn>
          Detalhes da Emissão
        </div>
      </v-col>
      <v-spacer></v-spacer>
      <v-breadcrumbs :items="items"></v-breadcrumbs>
    </v-row>
    <v-row>
      <emissao-detalhe :emissao="emissao"></emissao-detalhe>
    </v-row>
  </v-container>
  <v-container v-else>
    Loading...
  </v-container>
</template>

<script>
import api from '@/api/api'

import EmissaoDetalhe from "@/components/Emissao/EmissaoDetalhe";

export default {
  components: {EmissaoDetalhe},
  data() {
    return {
      id: this.$route.params.id,
      emissao: null,
      loading: false,
    }
  },

  async mounted() {
    await this.initialize()
  },

  methods: {
    async initialize() {
      this.loading = true;
      await api.getEmissao(this.id).then((data) => {
        this.emissao = data.data;
      }).catch(e => {
        this.$root.vtoast.show({message: 'Erro ao carregar emissão', type: 'error'});
        this.$root.vtoast.show({message: e.msg, type: 'error'});

      });
      this.loading = false;
    },
    backToVenda() {
      history.back()
    }
  },

  computed: {
    items() {
      return [
        {
          text: 'Home',
          to: "home"
        },
        {
          text: 'Emissões',
          to: {name: "emissoes"},
          disabled: false,
          exact: true
        },
        {
          text: 'E' + this.id,
          disabled: true,
          to: ""
        }
      ]
    }
  }
}
</script>
