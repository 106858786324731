export const filterEndereco = (endereco, formatCep = true) => {
    if(!endereco) return {}
    return {
        cep: !formatCep ? endereco.cep  : endereco.cep ? endereco.cep.replace('-', '') : null,
        logradouro: endereco.logradouro,
        numero: endereco.numero,
        bairro: endereco.bairro,
        estado: endereco.estado || endereco.uf,
        cidade: endereco.cidade || endereco.localidade,
        pais: endereco.pais,
    }
}