const getFornecedoresCotacaoVoo = cotacaoVoo => {
    if(!cotacaoVoo) return []
    const subcotacoes = cotacaoVoo.subcotacoes
    if(!subcotacoes) return []
    return subcotacoes.map(subcot => subcot.fornecedor)
}

export const getListFornecedores = cotacao => {
    if(cotacao.cotacao_voo) 
        return getFornecedoresCotacaoVoo(cotacao.cotacao_voo)
    
    let cotacaoEspecifica = {}
    if(cotacao.cotacao_hotel) cotacaoEspecifica = cotacao.cotacao_hotel
    else if(cotacao.cotacao_carro) cotacaoEspecifica = cotacao.cotacao_carro
    else if(cotacao.cotacao_outro) cotacaoEspecifica = cotacao.cotacao_outro
    if(!cotacaoEspecifica.fornecedor) return []
    return [cotacaoEspecifica.fornecedor]
}

export const formatFornecedor = (fornecedor, fornecedores) => {
    if (!fornecedor) return ""
    const fornecedorObj = fornecedores.find(
        forn => forn.id === fornecedor
    )
    if (!fornecedorObj) return ""
    return fornecedorObj.nome
}