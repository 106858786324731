<template>
  <v-container fluid v-if="form">
    <v-row>
      <v-col cols="12" sm="12">
        <v-text-field
          outlined
          label="Localizador"
          v-model="form.localizador"
        ></v-text-field>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12" sm="12">
        <v-select
          label="Emissor"
          outlined
          :items="
            emissao.emitido_por == null ? filteredUsers : emissao.emitido_por
          "
          :item-text="(user) => `${user.nome} ${user.sobrenome}`"
          item-value="id"
          v-model="form.emitido_por"
          v-if="emissao.status === 'Finalizada' || filteredUsers.length > 0"
          :disabled="emissao.status !== 'fila_de_emissao'"
        />
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12" sm="12">
        <v-textarea
          outlined
          label="Anotações/Observações"
          v-model="form.observacoes"
          :rules="rules"
        />
      </v-col>
    </v-row>

    <v-row>
      <v-col>
        <EmissaoDetalheTabInfosExtraVoo :emissao="emissao" />
      </v-col>
    </v-row>

    <v-row>
      <v-btn
        color="error"
        @click="cancelarDialog = true"
        :loading="loading.cancelar"
        :disabled="emissao?.status !== 'fila_de_emissao'"
      >
        <v-icon left>mdi-cancel</v-icon>
        Cancelar
      </v-btn>
      <v-spacer />
      <v-btn
        :loading="loading.salvar"
        color="primary"
        @click="save"
        :disabled="emissao?.status !== 'fila_de_emissao'"
      >
        <v-icon left> mdi-content-save </v-icon>
        Salvar
      </v-btn>
      <v-spacer />
      <v-btn
        color="success"
        @click="concluir"
        :loading="loading.concluir"
        :disabled="emissao?.status !== 'fila_de_emissao'"
      >
        <v-icon left>mdi-check</v-icon>
        Concluir
      </v-btn>
    </v-row>
    <v-dialog max-width="600" persistent v-model="cancelarDialog">
      <v-card>
        <v-card-title class="text-h5"> Cancelar emissão </v-card-title>

        <v-card-text>
          <v-label> Qual é o motivo do cancelamento da emissao? </v-label>
          <v-text-field
            required="true"
            v-model="motivoCancelamento"
            prepend-inner-icon="mdi-pencil"
            label="Motivo*"
            counter
            maxlength="255"
            :rules="maxLength"
          />
        </v-card-text>

        <v-card-actions>
          <v-btn text @click="cancelarDialog = false">
            <v-icon left>mdi-arrow-left</v-icon>
            Voltar
          </v-btn>
          <v-spacer />
          <v-btn
            color="red darken-1"
            text
            @click="cancelar"
            :loading="loading.cancelar"
            :disabled="motivoCancelamento.length < 3"
          >
            <v-icon left>mdi-alert</v-icon>
            Confirmar
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import CotacaoMixin from "@/mixins/CotacaoMixin";
import VDatetimeTextField from "@/components/widgets/VDatetimeTextField";
import VMoedaField from "@/components/widgets/VMoedaField";
import EmissaoMixin from "@/mixins/EmissaoMixin";
import { formatApiError } from "@/helpers/apiErrors";

import EmissaoDetalheTabInfosExtraVoo from "./EmissaoDetalheTabInfosExtraVoo";

import api from "@/api/api.js";
import financeiro from "@/api/routes/financeiro";

export default {
  props: {
    emissao: {
      type: Object,
      required: true,
    },
    voo: {
      type: Boolean,
      default: false,
    },
  },

  mixins: [CotacaoMixin, EmissaoMixin],

  components: {
    VMoedaField,
    VDatetimeTextField,
    EmissaoDetalheTabInfosExtraVoo,
  },

  data() {
    return {
      motivoCancelamento: "",
      maxLength: [(v) => v.length <= 255 || "Maximo 255 caracteres"],
      cancelarDialog: false,
      form: null,
      rules: [(v) => (v && v.length <= 30) || "Maximo 30 caracteres"],
      loading: {
        concluir: false,
        salvar: false,
      },
    };
  },

  computed: {
    tipo_servico() {
      const cotacao =
        this.form && this.form.servico && this.form.servico.cotacao;
      return this.cotacaoTipoServico(cotacao);
    },

    users() {
      return this.$store.getters["usuario/usuarios"];
    },
    loggedUser() {
      return this.$store.getters["usuario/loggedUser"];
    },

    filteredUsers() {
      if (this.tipo_servico != "Vôo") {
        return this.users.filter(
          (user) =>
            (user.perfil_user && user?.perfil_user?.includes("emissor")) ||
            this.loggedUser.emissores_extra.some(
              (username) => user.username === username
            ) ||
            user.username === this.loggedUser.username
        );
      } else if (
        this.loggedUser.pertencente?.includes("vendas_1") ||
        this.loggedUser.pertencente?.includes("vendas_2")
      ) {
        return this.users.filter((user) =>
          this.loggedUser.emissores_extra.some(
            (username) => user.username === username
          )
        );
      } else {
        return this.users.filter(
          (user) =>
            (user.perfil_user && user?.perfil_user?.includes("emissor")) ||
            this.loggedUser.emissores_extra.some(
              (username) => user.username === username
            ) ||
            user.username === this.loggedUser.username
        );
      }
    },

    cotadores() {
      let arr = [];
      this.users.forEach((usuario) => {
        if (
          usuario.cotador ||
          this.loggedUser.cotador_extra.some(
            (username) => usuario.username === username
          ) ||
          usuario.username === this.loggedUser.username
        ) {
          arr.push(usuario);
        }
      });
      return arr.filter((usuario) => !usuario.cliente);
    },

    fornecedorCotacao() {
      const servico = this.emissao.servico;
      if (!servico) return null;
      const cotacao = servico.cotacao;
      if (!cotacao) return null;
      const cotacaoGeral =
        cotacao.cotacao_hotel || cotacao.cotacao_carro || cotacao.cotacao_outro;
      if (!cotacaoGeral) return null;
      return cotacaoGeral.fornecedor;
    },

    fornecedorSubCotacao() {
      const subcotacao = this.emissao.subcotacao_voo;
      if (!subcotacao) return null;
      return subcotacao.fornecedor;
    },

    fornecedor() {
      return this.fornecedorCotacao || this.fornecedorSubCotacao;
    },
  },

  created() {
    this.$store.dispatch("usuario/fetchUsuarios");
    if (!this.emissao) return;
    this.form = {
      ...this.emissao,
    };
  },

  methods: {
    validate() {
      if (
        !this.form.data_hora_emissao ||
        this.form.data_hora_emissao === "Invalid Date"
      ) {
        this.$root.vtoast.show({
          message: "Data e hora de emissão inválida",
          color: "error",
          icon: "mdi-close",
        });
        return false;
      }
      return true;
    },

    async save() {
      if (!this.validate) return;
      this.loading.salvar = true;
      const emissao = await this.salvarEmissao(this.form);
      if (emissao && emissao.venda) {
        this.form = emissao;
        this.$root.vtoast.show({
          message: "Emissão salva com sucesso!",
          color: "success",
          icon: "mdi-check",
        });
      }
      this.loading.salvar = false;
    },

    cancelar() {
      this.loading.cancelar = true;
      api
        .cancelarEmissao(this.emissao.id, {
          motivo_cancelamento: this.motivoCancelamento,
        })
        .then((res) => {
          this.$router.push({
            name: "venda-info",
            params: { id: this.emissao.venda.id },
          });
          this.$root.vtoast.show({
            message: "Emissão cancelada.",
            color: "success",
            icon: "mdi-check",
          });
          this.loading.cancelar = false;
          this.$router.go(0);
        })
        .catch((err) => {
          console.error(err);
          this.$root.vtoast.show({
            message: "Nâo foi possível cancelar a emissão",
            color: "error",
            icon: "mdi-close",
          });
          this.loading.cancelar = false;
        });
    },

    concluir() {
      if (
        confirm("Tem certeza que deseja marcar essa emissão como concluída?")
      ) {
        api
          .concluirEmissao(this.emissao.id)
          .then((res) => {
            this.$root.vtoast.show({
              message: "Emissão concluída com sucesso!",
              color: "success",
              icon: "mdi-check",
            });
            this.$router.go(0);
          })
          .catch((err) => {
            console.error(err);
            window.alert(formatApiError(err));
          });
      }
    },
  },
};
</script>
