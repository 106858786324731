<template >
    <div class="page">
        <div v-for="(cotacao, idxCotacao) in cotacoes" class="cotacao-container">
            <Header/>
            <h3 class="cotacao">Cotação #{{idxCotacao + 1}} (id: {{cotacao.id}})</h3>
            <div class="vehiculo-info">
                <div class="column">
                    <p>Local de retirada</p>
                    <p>Data de retirada</p>
                    <p>Local de devolução</p>
                    <p>Data de devolução</p>
                    <p>Empresa</p>
                    <p>Tipo de carro</p>
                </div>
                <div class="column right">
                    <p>{{cotacao.cotacao_carro.local_retirada.nome}}</p>
                    <p>{{convertHora(cotacao.cotacao_carro.data_hora_retirada)}}</p>
                    <p>{{cotacao.cotacao_carro.local_devolucao.nome}}</p>
                    <p>{{convertHora(cotacao.cotacao_carro.data_hora_devolucao)}}</p>
                    <p>{{cotacao.cotacao_carro.empresa}}</p>
                    <p>{{cotacao.cotacao_carro.tipo_carro}}</p>
                </div>
            </div>
            <div class="precos">
                <p>{{format(cotacao.cotacao_carro.custo_total )}}</p>
                <p>Custo da diária: {{format(diaria(cotacao))}}</p>
                <p>Custo do seguro: {{format(cotacao.cotacao_carro.custo_seguro)}}</p>
                <p>Custos extras: {{format(cotacao.cotacao_carro.custo_extras)}}</p>
            </div>
            <div class="html2pdf__page-break"></div>
        </div>
        <resumo-orcamento :cotacoes="cotacoes" />
    </div>
</template>
<script>

import Header from '../Template/Header.vue';
import CotacaoMixin from "@/mixins/CotacaoMixin";
import ResumoOrcamento from "./ResumoOrcamento.vue";
import {convertHora} from "@/helpers/dates"
export default {
    components: {
        Header,
        ResumoOrcamento
    },
    props: {
        cotacoes: {
            type: Array,
            default: () => [],
            required: true
        }
    },
    mixins: [CotacaoMixin],
    methods: {
        convertHora(data){
            return convertHora(data)
        },
        format(num){
            return this.$options.filters['dinheiroReal'](this.converteValorFinanceiroBRL(num).toFixed(2))
        },
        diaria(cotacao){
            return this.converteValorFinanceiroBRL(cotacao.cotacao_carro.custo_diaria) + 
                this.cotacaoValorAjuste(cotacao)/cotacao.cotacao_carro.diarias
        },
    }
}
</script>
<style >
.vehiculo-info {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: space-between;
}
.precos {
    background-color: #819486;
    border-radius: 15px;
    color: white;
    margin: 3rem 1rem 0 1rem ;
    padding: 1rem;
} 
.precos > p:nth-child(1){
    font-size: 1.5rem;
    margin-left: 1rem;
    margin-bottom: 2rem;
}
.cotacao-container > h3 {
    margin-left: 0;
    color: white;
    background-color: #819486;
    border-radius: 15px;
    padding: 1.5rem;
    text-align: center;
}
.column{
    padding: 0;
    margin: 1rem;
}
.right{
    text-align: right
}
.cotacao{
    padding-left: 4rem;
    margin: 0.5rem 0 0.5rem 0
}
</style>