import Vue from 'vue'
import Vuex from 'vuex'
import vendaStore from "@/store/modules/Venda/VendaStore";
import usuarioStore from "@/store/modules/Usuario/UsuarioStore";
import globalStore from "@/store/modules/Global/GlobalStore";
import contatoStore from "@/store/modules/Contato/ContatoStore";

Vue.use(Vuex)

export default new Vuex.Store({
    modules: {
        venda: vendaStore,
        usuario: usuarioStore,
        global: globalStore,
        contato: contatoStore
    }
})

