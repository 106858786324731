import { get } from '../modules'

export default {
    getMoedas() {
        return get("/api/moedas/");
    },

    getOrcamentoCotacoes(orcamentoId) {
        return get(`/api/orcamentos/${orcamentoId}/cotacoes/`);
    },
}
