<template>
  <v-container class="ma-4" fluid>
    <v-row>
      <v-col>
        <div class="text-h5 transition-swing">Contatos</div>
        <v-breadcrumbs :items="breadcumb_items" />
      </v-col>

      <NovoContato
        :contatos="contatos"
        @refresh="initialize()"
        :key="novoContatoKey"
      />
    </v-row>
    <v-row>
      <ContatosTable
        :contatos="contatos"
        :numPages="numPages"
        :loading="loadingTable"
        @refresh="refreshTable"
        @rowClick="rowClick"
      />
    </v-row>
  </v-container>
</template>

<script>
import ContatosTable from "@/components/Contatos/ContatosTable.vue";
import NovoContato from "@/components/Contatos/NovoContato/NovoContato.vue";

export default {
  components: { NovoContato, ContatosTable },

  data() {
    return {
      loadingTable: true,
      breadcumb_items: [
        {
          text: "Home",
          to: "home",
        },
        {
          text: "Contatos",
          to: "contatos",
        },
      ],
      novoContatoKey: 0,
    };
  },

  created() {
    this.initialize();
  },

  methods: {
    newContatoRefresh() {
      this.novoContatoKey += 1;
    },

    initialize() {
      this.newContatoRefresh();
      this.refreshTable();
    },

    refreshTable({ page, filters } = { page: 1, filters: [] }) {
      this.loadingTable = true;

      this.$store
        .dispatch("contato/fetchContatosPaginated", { page, filters })
        .catch((err) => {
          this.$root.vtoast.showApiError({
            message: err.message,
            type: "error",
          });
        })
        .finally(() => {
          this.loadingTable = false;
        });
    },

    rowClick(value) {
      if (this.pressingCtrl)
        return window.open("/contatos/" + value.id, "_blank");
      this.$router.push({ name: "contato-info", params: { id: value.id } });
    },
  },

  computed: {
    contatos() {
      return this.$store.state.contato.contatos;
    },

    numPages() {
      return this.$store.state.contato.contatosNumPages;
    },
  },
};
</script>
