export const formatCidades = (cidades) => {
    const formatedCidades = cidades.map((cid) => {
        const res = { ...cid};
        const cidade = cid.cidade;
        if (!cidade) return "";
        res.text = `${cidade.nome}${cidade.estado_sigla ? " - " + cidade.estado_sigla : ""} - ${cidade.pais}`;
        return res;
    });

    return formatedCidades;
}