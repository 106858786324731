export const formatApiError = (error) => {
    const errors = error.response.data
    if(!errors) return "Requerimento não pode ser completado."

    const messages = []
    for(const err in errors){
        messages.push(`${err}: ${errors[err].join(', ')}`)
    }

    return `Requerimento não pode ser completado: \n
    ${messages.join("\n    ")}
    `
}