<template>
  <router-link :to="to">
    <slot></slot>
  </router-link>
</template>

<script>
export default {
  props: ["to"],
  methods: {
    goLink() {
      if (this.to) {
        this.$router.push(this.to);
      }
    },
  },
};
</script>
