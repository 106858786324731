<template>
  <v-container fluid>
    <v-row>
      <v-col>
        <v-field-value label="Tipo de serviço">Vôo</v-field-value>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <v-field-value label="Trechos">
          <trechos-widget :trechos="voo.trechos"></trechos-widget>
        </v-field-value>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <v-field-value label="Adultos">{{ voo.adultos }}</v-field-value>
      </v-col>
      <v-col>
        <v-field-value label="Crianças">{{ voo.criancas }}</v-field-value>
      </v-col>
      <v-col>
        <v-field-value label="Bebes">{{ voo.bebes}}</v-field-value>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <v-field-value label="Classes">{{ voo.classes.join(", ") }}</v-field-value>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <v-field-value label="Observação">
          <v-btn icon>
            <v-icon color="orange">mdi-information-outline</v-icon>
          </v-btn>
        </v-field-value>
      </v-col>
    </v-row>
    <v-divider class="my-4"></v-divider>
    <v-row>
      <slot name="actions"></slot>
    </v-row>
  </v-container>
</template>
<script>
import VFieldValue from "@/components/widgets/VFieldValue";
import TrechosWidget from "@/components/widgets/TrechosWidget";

export default {
  components: {TrechosWidget, VFieldValue},
  props:{
    voo: {
      type: Object,
      required: true
    }
  }
}
</script>
