<template>
  <v-container fluid v-if="form">
    <v-row>
      <v-col>
        <v-row>
          <div class="pa-2 text-subtitle-1">Itinerário da viagem</div>
          <voo-edit-form-viagem v-model="form.viagem" />
        </v-row>
      </v-col>
    </v-row>
    <v-row>
      <v-divider />
    </v-row>
    <v-row>
      <div class="ml-4 mt-2">Detalhes da cotação da viagem</div>
      <cotacao-edit-form-secao-cotacao-voo-sub-cotacao-secao-cotacao
        v-model="form"
        :ciaAereas="ciaAereas"
      />
    </v-row>
  </v-container>
</template>
<script>
import CotacaoEditFormSecaoCotacaoVooSubCotacaoSecaoCotacao from "./CotacaoEditFormSecaoCotacaoVooSubCotacaoSecaoCotacao";
import VMoedaField from "@/components/widgets/VMoedaField";
import VooEditFormViagem from "@/components/Voo/VooEditFormViagem";

export default {
  components: {
    VooEditFormViagem,
    CotacaoEditFormSecaoCotacaoVooSubCotacaoSecaoCotacao,
    VMoedaField,
  },
  props: {
    value: {
      type: Object,
      required: false,
      default: () => null,
    },
    disabled: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  data() {
    return {
      showOptionsMenu: false,
      form: null,
      fidelidades: [],
      ciaAereas: [],
    };
  },
  watch: {
    form: {
      immediate: true,
      deep: true,
      handler: function () {
        this.input();
      },
    },
  },
  created() {
    const defaultForm = {
      viagem: null,
      tipo_cotacao: "pagante",
    };
    this.form = { ...defaultForm };
    if (this.value) {
      this.form = {
        ...this.form,
        ...this.value,
      };
      // Se depois disso o form tiver uma propriedade form.dados, o processo deu certo
      // Basta agora sobrescrever a viagem com o retorno do pnr.
      if (this.form.hasOwnProperty("dados")) {
        this.form = this.form.dados;
      }
    }

    this.input();
  },
  methods: {
    input(val) {
      if (!this.form) return;
      const completeForm = {
        ...this.value,
        ...this.form,
      };

      this.$emit("input", completeForm);

      if (completeForm.viagem.trechos) {
        for (let i = 0; i < completeForm.viagem.trechos.length; i++) {
          const pernas = completeForm.viagem.trechos[i].pernas;
          for (let j = 0; j < pernas.length; j++) {
            const cia_aerea = pernas[j].cia_aerea;

            if (
              Number.isInteger(cia_aerea) &&
              !this.ciaAereas.includes(cia_aerea)
            ) {
              this.ciaAereas.push(cia_aerea);
            }
          }
        }
      }
    },
  },
};
</script>