<template>
  <v-container fluid v-if="formOutro">
    <v-row class="ma-0">
      <v-col cols="auto">
        <v-text-field
          dense
          outlined
          label="Nome do serviço*"
          v-model="formOutro.nome_servico"
        />
      </v-col>
      <v-col cols="auto">
        <search-local v-model="formOutro.local" outlined label="Local" />
      </v-col>
      <v-col cols="auto">
        <form-date-picker
          v-model="formOutro.data_inicio"
          outlined
          label="Data Início"
        ></form-date-picker>
      </v-col>
      <v-col cols="auto">
        <form-date-picker
          v-model="formOutro.data_fim"
          outlined
          label="Data Fim"
        ></form-date-picker>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="auto">
        <v-select
          :items="fornecedores"
          v-model="formOutro.fornecedor"
          outlined
          dense
          label="Fornecedor"
          item-value="id"
          item-text="nome"
        >
        </v-select>
      </v-col>
      <v-col>
        <v-select
          :items="provedores"
          v-model="formOutro.provedor"
          outlined
          dense
          label="Provedor"
          item-value="id"
          item-text="nome"
        />
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <CotacaoImageSelect
          :provedor="formOutro.provedor"
          v-model="formOutro.imagens_provedor"
        />
      </v-col>
    </v-row>
    <v-row class="ma-0">
      <v-col cols="auto">
        <v-moeda-field
          outlined
          label="Custo total"
          v-model="formOutro.custo_total"
        />
      </v-col>
      <v-col cols="auto">
        <v-moeda-field
          outlined
          label="RAV via fornecedor"
          v-model="formOutro.rav"
        />
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
import FormDatePicker from "@/components/widgets/FormDatePicker";
import VMoedaField from "@/components/widgets/VMoedaField";
import VFieldValue from "@/components/widgets/VFieldValue";
import SearchLocal from "@/components/widgets/SearchLocal";
import CotacaoImageSelect from "./CotacaoImageSelect";

export default {
  components: {
    SearchLocal,
    VFieldValue,
    VMoedaField,
    FormDatePicker,
    CotacaoImageSelect,
  },
  props: {
    orcamento: {
      value: Object,
    },
    value: {
      type: Object,
    },
  },
  data() {
    return {
      type: null,
      menu: false,
      formOutro: null,
    };
  },
  watch: {
    formOutro: {
      deep: true,
      handler: function (val) {
        this.$emit("input", val);
      },
    },
  },
  computed: {
    fornecedores() {
      return this.$store.state.global.fornecedores;
    },
    provedores() {
      if (!this.fornecedores) return [];
      const fornecedor = this.fornecedores.find(
        (f) => f.id == this.formOutro.fornecedor
      );
      const provedores = fornecedor?.provedores.filter(
        (provedor) =>
          !["hospedagem", "aereo", "locadora_de_carro", "milhas"].includes(
            provedor.tipo_servico
          )
      );
      return provedores;
    },
  },
  created() {
    this.initialize();
  },
  methods: {
    initialize() {
      if (!this.orcamento) {
        this.formOutro = {};
        return;
      }
      const orcamento_outro = this.orcamento.orcamento_outro;
      if (!orcamento_outro) this.formOutro = {};
      else
        this.formOutro = {
          local: orcamento_outro.local,
          nome_servico: orcamento_outro.nome_servico,
          data_inicio: new Date(orcamento_outro.data_inicio_inicio),
          data_fim: new Date(orcamento_outro.data_inicio_fim),
        };
      this.formOutro = {
        ...this.formOutro,
        ...this.value,
      };
    },
  },
};
</script>
