<template>
  <v-text-field
    v-model="form"
    @input="input"
    label="Email"
    :rules="emailRules"
  ></v-text-field>
</template>
<script>
export default {
  props: {
    value: {
      type: String,
      default: () => "",
    },
  },
  data: () => ({
    form: null,
    emailRules: [
      (v) => /.+@.+..+/.test(v) || "E-mail deve ser válido (exemplo@email.com)",
    ],
  }),
  created() {
    if (!this.value) return;
    this.form = this.value;
  },
  methods: {
    input() {
      this.$emit("input", this.form);
      this.form = "";
    },
  },
};
</script>
