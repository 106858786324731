<template>
  <v-container class="ma-1" fluid v-if="ready">
    <v-row dense>
      <v-col cols="12">
        <small>Dados da venda</small>
        <div>Todos os campos marcados com * são obrigatórios</div>
      </v-col>
    </v-row>
    <v-row dense>
      <v-col cols="12" md="4">
        <SearchContato
          v-if="!paraEmpresa"
          v-model="formVenda.selectedCliente"
          label="Cliente*"
          :disabled="checkUserPermissao"
        />

        <v-select
          v-if="paraEmpresa"
          label="Empresa*"
          :items="empresas"
          item-text="nome"
          item-value="id"
          v-model="formVenda.cliente_empresa"
          prepend-inner-icon="mdi-domain"
          outlined
        />
      </v-col>
      <div class="pt-3">
        <v-btn
          color="primary"
          @click="paraEmpresa = !paraEmpresa"
          :disabled="checkUserPermissao"
        >
          {{ (descricaoBotao) }}</v-btn
        >
      </div>
      <v-col
        v-if="formVenda.selectedCliente && !paraEmpresa"
        class="pt-3"
        cols="12"
        md="6"
      >
        <v-field-value label="Indicação">{{
          indicacao | nomeSobrenome
        }}</v-field-value>
      </v-col>
    </v-row>
    <v-row dense>
      <v-col cols="12" md="6">
        <v-row dense>
          <v-col cols="12" md="4">
            <v-autocomplete
              :items="[
                'Whatsapp',
                'Telefone',
                'Email',
                'Instagram',
                'Linkedin',
                'Outro',
              ]"
              v-model="formVenda.meio_contato_tipo"
              outlined
              label="Meio de contato*"
              :disabled="checkUserPermissao"
            >
            </v-autocomplete>
          </v-col>
          <v-col cols="12" md="8">
            <TelefoneField
              v-if="['Whatsapp', 'Telefone'].includes(meio_contato_tipo)"
              outlined
              :disabled="checkUserPermissao"
              v-model="formVenda.meio_contato_valor"
            />
            <v-text-field
              v-else
              outlined
              v-model="formVenda.meio_contato_valor"
              :disabled="checkUserPermissao"
            >
              <template v-slot:label>
                {{ formVenda.meio_contato_tipo | capitalize }}*
              </template>
            </v-text-field>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
    <v-row dense>
      <v-col cols="12" md="3">
        <v-autocomplete
          :items="consultores"
          v-model="formVenda.consultor"
          outlined
          :item-text="(item) => `${item.nome} ${item.sobrenome}`"
          :item-value="(item) => item.id"
          label="Consultor*"
          :disabled="checkUserPermissao"
        >
        </v-autocomplete>
      </v-col>
      <v-col cols="12" md="3">
        <v-autocomplete
          :items="sdr"
          v-model="formVenda.sdr"
          outlined
          :item-text="(item) => `${item.nome} ${item.sobrenome}`"
          :item-value="(item) => item.id"
          label="SDR"
          :disabled="checkUserPermissao"
        >
        </v-autocomplete>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <small>Anotações:</small>
        <v-textarea dense filled v-model="formVenda.anotacoes"> </v-textarea>
      </v-col>
    </v-row>
    <v-row>
      <v-divider></v-divider>
    </v-row>
    <v-row v-if="!hideSecaoOrcamento">
      <v-col>
        <v-row>
          <div class="text-h6">Solicitações de orçamentos</div>
        </v-row>
        <v-row>
          <criar-orcamentos v-model="formOrcamentos"></criar-orcamentos>
        </v-row>
      </v-col>
    </v-row>
    <v-row>
      <v-divider></v-divider>
    </v-row>
    <v-row dense class="mt-6" v-show="false">
      <v-col cols="12" md="6">
        <v-textarea
          dense
          label="Observações"
          v-model="formVenda.anotacoes"
        ></v-textarea>
      </v-col>
    </v-row>
    <v-row>
      <v-spacer></v-spacer>
      <v-col cols="auto">
        <v-btn :disabled="loading" text color="primary" @click="cancel">
          Cancelar
        </v-btn>
      </v-col>
      <v-col cols="auto">
        <v-btn :loading="loading" color="primary" @click="save">
          <v-icon>mdi-check</v-icon>
          Salvar
        </v-btn>
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
import { defaultVenda } from "@/constants/vendaDefault";
import masks from "@/constants/masks";
import MeioContato from "@/components/venda/widgets/MeioContato";
import SearchContato from "@/components/widgets/SearchContato";
import SearchEmpresa from "@/components/widgets/SearchEmpresa";
import NovoOrcamentoRow from "@/components/Orcamento/NovoOrcamentoRow";
import CriarOrcamentos from "@/components/Orcamento/CriarOrcamentos";
import VFieldValue from "@/components/widgets/VFieldValue";
import checkPermissao from "@/helpers/checkUserPermissao.js";


import TelefoneField from "@/components/widgets/TelefoneField.vue";
import VUsuarioAutoComplete from "@/components/widgets/VUsuarioAutoComplete";

export default {
  components: {
    VUsuarioAutoComplete,
    CriarOrcamentos,
    NovoOrcamentoRow,
    SearchEmpresa,
    MeioContato,
    SearchContato,
    VUsuarioAutoComplete,
    VFieldValue,
    TelefoneField,
  },
  props: {
    isNewVenda: {
      required: false,
      default: false,
      type: Boolean,
    },
    venda: {
      required: false,
      type: Object,
    },
    orcamentos: {
      required: false,
      type: Array,
    },
    hideSecaoOrcamento: {
      type: Boolean,
      default: false,
    },
    paraEmpresas: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      formVenda: null,
      formOrcamentos: null,
      count: 0,
      loading: false,
      contatos: [],
      masks: masks,
      paraEmpresa: false,
      empresas: [],
    };
  },
  computed: {
    ready() {
      return this.formVenda !== null && this.formOrcamentos !== null;
    },
    checkUserPermissao() {
      if (this.isNewVenda) {
        return false;
      }
      const pertencente = this.loggedUser.pertencente;
      return checkPermissao(pertencente);
    },
    loggedUser() {
      return this.$store.getters["usuario/loggedUser"];
    },
    usuarios() {
      return this.$store.state.usuario.usuarios;
    },
    sdr() {
      return this.usuarios.filter((usuario) => usuario.sdr);
    },
    consultores() {
      const arr = [];
      this.usuarios.forEach((usuario) => {
        if (
          usuario.consultor ||
          this.loggedUser.consultores_extra.some(
            (username) => usuario.username === username
          ) ||
          usuario.username === this.loggedUser.username
        ) {
          arr.push(usuario);
        }
      });
      return arr.filter((usuario) => !usuario.cliente);
    },

    cliente() {
      return this.formVenda?.selectedCliente;
    },

    indicacao() {
      if (!this.cliente || !this.contatos) return null;
      if (this.cliente && this.cliente.indicacao) return this.cliente.indicacao;
    },

    meio_contato_tipo() {
      if (!this.formVenda) return null;
      return this.formVenda.meio_contato_tipo;
    },

    descricaoBotao() {
      if (this.paraEmpresa) {
        return "Cliente";
      }
      return "Empresa";
    },
  },

  mounted() {
    this.setup();
  },

  watch: {
    meio_contato_tipo: {
      deep: true,
      handler: function (val) {
        this.setMeioContato(val);
      },
    },

    cliente: {
      deep: true,
      handler: function (val) {
        this.setMeioContato(this.meio_contato_tipo);
      },
    },

    formVenda: {
      deep: true,
      handler: function (val) {
        this.preloadedContato = true;
      },
    },
  },

  methods: {
    async setMeioContato(val) {
      if (!this.cliente) return;

      switch (val) {
        case "Email":
          this.formVenda.meio_contato_valor = this.cliente.email;
          break;
        case "Telefone":
        case "Whatsapp":
          this.formVenda.meio_contato_valor = this.cliente.telefone;
          break;
        case "Instagram":
          this.formVenda.meio_contato_valor = this.cliente.extras.instagram;
          break;
        case "Linkedin":
          this.formVenda.meio_contato_valor = this.cliente.extras.linkedin;
          break;
        case "Outro":
          this.formVenda.meio_contato_valor = null;
          break;
      }
    },
    async setup() {
      this.formOrcamentos = this.orcamentos ? [...this.orcamentos] : [];
      this.formVenda = this.venda ? { ...this.venda } : defaultVenda;
      this.paraEmpresa = this.paraEmpresas;

      if (this.formVenda.cliente)
        this.formVenda.selectedCliente = this.formVenda.cliente;
      if (this.formVenda.cliente_empresa)
        this.formVenda.cliente_empresa = this.formVenda.cliente_empresa.id;
      if (this.formVenda.consultor)
        this.formVenda.consultor = this.formVenda.consultor.id;
      if (this.formVenda.sdr) this.formVenda.sdr = this.formVenda.sdr.id;
      else this.formVenda.sdr = null;
      if (this.formVenda.indicacao)
        this.formVenda.indicacao = this.formVenda.indicacao.id;

      await this.$store.dispatch("global/fetchContatos");
      await this.$store.dispatch("usuario/fetchUsuarios");
      await this.$store.dispatch("global/fetchEmpresas");
      this.contatos = this.$store.state.contato.contatos;
      this.empresas = this.$store.state.global.empresas;
    },
    save() {
      if (
        !(this.formVenda.selectedCliente || this.formVenda.cliente_empresa) ||
        !this.formVenda.consultor ||
        !this.formVenda.meio_contato_tipo ||
        !this.formVenda.meio_contato_valor ||
        (this.paraEmpresa === true && !this.formVenda.cliente_empresa)
      ) {
        this.$root.vtoast.show({
          message: "Preencha os campos obrigatórios.",
          icon: "mdi-close",
          color: "error",
        });
        return;
      }
      this.loading = true;

      if (this.paraEmpresa === false && this.formVenda.cliente_empresa) {
        this.formVenda.cliente_empresa = null;
      }
      if (this.paraEmpresa === true) {
        this.formVenda.selectedCliente = null;
        this.formVenda.cliente = null;
      }

      if (this.paraEmpresa === false && this.formVenda.selectedCliente) {
        this.formVenda.cliente = this.formVenda.selectedCliente.id;
      }

      // Convert phone object into a string (code + phone)
      if (["Whatsapp", "Telefone"].includes(this.formVenda.meio_contato_tipo)) {
        let valor = this.formVenda.meio_contato_valor;
        this.formVenda.meio_contato_valor = `${valor.codigo} ${valor.numero}`;
      }

      this.$emit("save", {
        ...this.formVenda,
        orcamentos: this.formOrcamentos,
      });
    },

    cancel() {
      this.formVenda = { ...this.venda };
      this.formOrcamentos = { ...this.orcamentos };
      this.$emit("cancel");
    },
  },
};
</script>
