export const origem = subcotacao => {
    const trechos = subcotacao.viagem.trechos
    if (!trechos) return ""
    const pernas = trechos[0].pernas
    if(!pernas[0]) return ""
    const origem  = pernas[0].origem
    if (!origem) return ""
    return origem.nome
}

export const dataPartida = subcotacao => {
    const trechos = subcotacao.viagem.trechos
    if (!trechos) return ""
    const pernas = trechos[0].pernas
    if(!pernas[0]) return ""
    return pernas[0].data_hora_partida
}

export const destino = subcotacao => {
    const trechos = subcotacao.viagem.trechos
    if(!trechos) return ""
    const pernas = trechos[trechos.length - 1].pernas
    if(!pernas.lenght) return ""
    const destino = pernas[pernas.length - 1].destino
    if (!destino) return ""
    return destino.nome
}

export const dataChegada = subcotacao => {
    const trechos = subcotacao.viagem.trechos
    if(!trechos) return ""
    const pernas = trechos[trechos.length - 1].pernas
    if(!pernas.lenght) return ""
    return pernas[pernas.length - 1].data_hora_chegada
}


export const numPessoasDetalhado = subcotacao => {
    const perna = subcotacao?.viagem?.trechos[0]?.pernas[0]
    if (!perna) return "-";
    return {
        total: perna.adultos + perna.criancas + perna.bebes,
        adultos: perna.adultos,
        criancas: perna.criancas,
        bebes: perna.bebes
    }
}

export const numPessoas = subcotacao => {
    const perna = subcotacao.viagem.trechos[0].pernas[0]
    if (!perna) return "-";
    return perna.adultos + perna.criancas + perna.bebes;
}

export const ciasAereas = subcotacao => { 
    if(!subcotacao) return []
    const trechos = subcotacao.viagem.trechos
    if(!trechos) return []
    let pernas = []
    trechos.forEach(
        trecho => {
            pernas = [...pernas, ...trecho.pernas]
        }
    )
    if(!pernas) return []
    const cias = pernas.map(
        perna => perna.cia_aerea
    ).filter(cia => cia)
    if(!cias) return []
    return [...new Set(cias)]
}