import InfoGeral from '../../components/Contatos/Detalhes/Tabs/Info.vue'
import Endereco from '../../components/Contatos/Detalhes/Tabs/Endereco.vue'
import Documentos from '../../components/Contatos/Detalhes/Tabs/Documentos.vue'
import Vendas from '../../components/Contatos/Detalhes/Tabs/Vendas.vue'
import Extras from '../../components/Contatos/Detalhes/Tabs/Extras.vue'
import Busca from '../../components/Contatos/Detalhes/Tabs/Busca.vue'
import CotacoesMilhasValidadas from '../../components/Contatos/Detalhes/Tabs/CotacoesMilhasValidadas.vue'
import EmissoesBeneficiario from '../../components/Contatos/Detalhes/Tabs/EmissoesBeneficiario.vue'
import ContasFidelidade from '../../components/Contatos/Detalhes/Tabs/ContasFidelidade'

export default [
    {
        path: "info",
        name: "contato-info",
        component: InfoGeral
    },
    {
        path: "endereco",
        name: "contato-endereco",
        component: Endereco,
    },
    {
        path: "documentos",
        name: "contato-documentos",
        component: Documentos,
    },
    {
        path: "vendas",
        name: "contato-vendas",
        component: Vendas,
    },
    {
        path: "extras",
        name: "contato-extras",
        component: Extras,
    },
    {
        path: "busca",
        name: "contato-busca",
        component: Busca,
    },
    {
        path: "cotacoesmilhas",
        name: "contato-cotacoes-milhas-validadas",
        component: CotacoesMilhasValidadas,
    },
    {
        path: "emissoesbeneficiario",
        name: "contato-emissoes-beneficiario",
        component: EmissoesBeneficiario,
    },
    {
        path: "contasfidelidade",
        name: "contato-contas-fidelidade",
        component: ContasFidelidade,
    },
    
];
