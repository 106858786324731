
<template>
  <v-col align-self="center" cols="12" md="auto">
    <v-dialog v-model="dialog" persistent max-width="1200px">
      <template v-slot:activator="{ on, attrs }">
        <v-btn
          color="primary"
          dark
          class="mb-2"
          v-bind="attrs"
          v-on="on"
          icon-left
        >
          <v-icon left fab> mdi-account-plus </v-icon>
          Contato
        </v-btn>
      </template>
      <v-card>
        <v-card-title>
          <v-row>
            <span class="text-h5 ma-5"> Novo Contato </span>
            <v-spacer></v-spacer>
            <v-btn @click="close" icon right>
              <v-icon>mdi-close</v-icon>
            </v-btn>
          </v-row>
        </v-card-title>
        <v-card-text>
          Todos os campos marcados com * são obrigatórios
        </v-card-text>
        <v-card-text>
          <v-container>
            <v-stepper v-model="step">
              <v-stepper-header>
                <v-stepper-step step="1" :complete="step > 1">
                  Informações Pessoais
                </v-stepper-step>

                <v-divider />

                <v-stepper-step step="2" :complete="step > 2">
                  Endereço
                </v-stepper-step>

                <v-divider />

                <v-stepper-step step="3" :complete="step > 3">
                  Complementares
                </v-stepper-step>

                <v-divider />

                <v-stepper-step step="4"> Documentos </v-stepper-step>
              </v-stepper-header>

              <v-stepper-items>
                <v-stepper-content step="1">
                  <StepInfo ref="info" :contatos="contatos" />

                  <v-row class="pa-5">
                    <v-spacer />
                    <v-btn icon disabled large>
                      <v-icon>mdi-arrow-left</v-icon>
                    </v-btn>
                    <v-btn @click="step = 2" icon large>
                      <v-icon>mdi-arrow-right</v-icon>
                    </v-btn>
                  </v-row>
                </v-stepper-content>

                <v-stepper-content step="2">
                  <StepEndereco ref="endereco" />

                  <v-row class="pa-5">
                    <v-spacer />
                    <v-btn @click="step = 1" icon large>
                      <v-icon>mdi-arrow-left</v-icon>
                    </v-btn>
                    <v-btn @click="step = 3" icon large>
                      <v-icon>mdi-arrow-right</v-icon>
                    </v-btn>
                  </v-row>
                </v-stepper-content>

                <v-stepper-content step="3">
                  <StepExtras ref="extras" />

                  <v-row class="pa-5">
                    <v-spacer />
                    <v-btn @click="step = 2" icon large>
                      <v-icon>mdi-arrow-left</v-icon>
                    </v-btn>

                    <v-btn @click="step = 4" icon large>
                      <v-icon>mdi-arrow-right</v-icon>
                    </v-btn>
                  </v-row>
                </v-stepper-content>

                <v-stepper-content step="4">
                  <StepDocumentos ref="documentos" />

                  <v-row class="pa-5">
                    <v-spacer />
                    <v-btn @click="step = 3" icon large>
                      <v-icon>mdi-arrow-left</v-icon>
                    </v-btn>
                    <v-btn
                      color="secondary"
                      @click="novoContato"
                      :loading="loading.saving"
                    >
                      Finalizar
                    </v-btn>
                  </v-row>
                </v-stepper-content>
              </v-stepper-items>
            </v-stepper>
          </v-container>
        </v-card-text>
      </v-card>
    </v-dialog>
  </v-col>
</template>

<script>
import StepInfo from "./Steps/Info.vue";
import StepEndereco from "./Steps/Endereco.vue";
import StepExtras from "./Steps/Extras.vue";
import StepDocumentos from "./Steps/Documentos.vue";

import ContatoMixin from "@/mixins/ContatoMixin";

export default {
    props: {
        contatos: Array,
    },
    
    components: {
        StepInfo,
        StepEndereco,
        StepExtras,
        StepDocumentos,
    },

    mixins: [
        ContatoMixin
    ],

    data() {
        return {
            step: 1,
            dialog: false,
            disableCancel: false,
            loading: {
                saving: false
            }
        };
    },

    methods: {
        close() {
          this.disableCancel = true;
    
          if (confirm("Deseja mesmo fechar? Todas as alterações serão perdidas."))
            this.dialog = false;
    
          this.disableCancel = false;
        },
    }
}
</script>
