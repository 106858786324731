<template>
  <v-dialog v-model="dialog" max-width="900px">
    <template v-slot:activator="{ on, attrs }">
      <v-btn color="primary" dark class="mb-2" v-bind="attrs" v-on="on">
        Novo Provedor
      </v-btn>
    </template>
    <v-card>
      <v-card-title>
        <span class="text-h5">{{ formTitle }}</span>
      </v-card-title>

      <v-card-text>
        <small>Todos os campos são obrigatórios</small>
        <!-- create new form -->
        <v-container fluid v-if="newItem">
          <v-row>
            <v-col>
              <fornecedor-form :item="item"></fornecedor-form>
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              <v-select
                :items="TiposServicos"
                label="Tipo de serviço"
                item-text="value"
                item-value="key"
                v-model="item.tipo_servico"
              ></v-select>
            </v-col>
          </v-row>
          <v-divider></v-divider>
          <v-row>Endereço</v-row>
          <v-row>
            <v-col>
              <endereco-edit-create
                v-model="item.endereco"
              ></endereco-edit-create>
            </v-col>
          </v-row>
        </v-container>

        <!-- edit form -->
        <v-container fluid v-else>
          <v-row>
            <v-col>
              <v-text-field v-model="item.nome" label="Nome"></v-text-field>
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              <v-email v-model="item.email"></v-email>
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              <v-text-field
                v-model="item.telefone"
                label="Telefone"
                v-mask="masks.tel"
                :returnRaw="true"
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              <v-select
                :items="TiposServicos"
                label="Tipo de serviço"
                item-text="value"
                item-value="key"
                v-model="item.tipo_servico"
              ></v-select>
            </v-col>
          </v-row>
        </v-container>
      </v-card-text>

      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="blue darken-1" text @click="close"> Cancelar </v-btn>
        <v-btn color="blue darken-1" text @click="save"> Salvar </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>
import FornecedorForm from "./FornecedorForm"; // todo provedor tem pelo menos as mesmas infos de um fornecedor
import EnderecoEditCreate from "@/components/widgets/EnderecoEditCreate";
import VEmail from "@/components/widgets/VEmail";

import MASKS from "@/constants/masks";
import { TiposServicos as TIPOS_SERVICOS } from "@/constants/fornecedores";

export default {
  components: {
    FornecedorForm,
    EnderecoEditCreate,
    VEmail
  },

  data: () => ({
    masks: MASKS,
    TiposServicos: TIPOS_SERVICOS,
    dialog: false,
  }),

  props: {
    item: {
      type: Object,
      default: () => {},
    },

    newItem: {
      type: Boolean,
      default: true,
    },

    value: {
      // Dialog
      type: Boolean,
      default: false,
    },
  },

  computed: {
    formTitle() {
      return this.newItem ? "Novo Provedor" : "Editar Provedor";
    },
  },

  watch: {
    dialog(val) {
      this.$emit("input", val);
    },
    value(val) {
      this.dialog = val;
    },
  },

  mounted() {
    this.dialog = this.value;
  },

  methods: {
    close() {
      this.$emit("close");
    },

    save() {
      this.$emit("save");
    },
  },
};
</script>
