<template>
  <div>
    <v-card v-for="(perna, index) in pernas" :key="index">
      <v-card-text>
        <p>
          {{ descricaoPerna(perna) }} às
          {{ perna.data_hora_partida | dataHoraBR }}
          {{ perna?.cia_aerea?.nome_resumido? '--- ' + perna.cia_aerea.nome_resumido: '' }}
      
        </p>
        <v-text-field
          label="Número do voo"
          outlined
          dense
          v-model="perna.numero_do_voo"
        ></v-text-field>
      </v-card-text>
    </v-card>
  </div>
</template>
<script>
export default {
  props: {
    emissao: {
      type: Object,
      default: () => {},
    },
  },

  computed: {
    pernas() {
      if (!this.emissao) return [];
      const subcot = this.emissao.subcotacao_voo;
      if (!subcot) return [];
      const viagem = subcot.viagem;
      if (!viagem) return [];
      const trechos = viagem.trechos;
      if (!trechos.length) return [];
      const pernas = trechos
        .reduce((cum, el) => [...cum, el.pernas], [])
        .reduce((cum, el) => [...cum, ...el], []);
      if (!pernas) return [];
      return pernas;
    },
  },

  methods: {
    descricaoPerna(perna) {
      if (!perna) return "";
      let origem = perna.origem;
      let destino = perna.destino;
      if (!origem) origem = { nome: "" };
      if (!destino) destino = { nome: "" };
      return `De ${origem.nome} --- Para: ${destino.nome} ---`;
    },
  },
};
</script>
