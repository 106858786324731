<template>
  <v-container>
    <v-row>
      <v-col>
        <div class="text-h5 transition-swing">Painel de Autorização</div>
        <v-breadcrumbs :items="breadcumb_items"></v-breadcrumbs>
      </v-col>
    </v-row>
    <v-row>
      <autorizacao-voo :servicos="servicos" :loading="loadingTable" @refresh="initialize"/>
    </v-row>
  </v-container>
</template>
<script>
import AutorizacaoVoo from "@/components/PainelAutorizacao/AutorizacaoVoo";
import api from "@/api/api.js";

export default {
  components: {
    AutorizacaoVoo,
  },
  data: () => ({
    servicos: [],
    orcamentos: [],
    loadingTable: true,
    breadcumb_items: [
      {
        text: "Home",
        to: {
          name: "home",
        },
        exact: true,
      },
      {
        text: "Painel de Autorização",
        to: {
          name: "painel-autorizacao",
        },
      },
    ],
  }),
  mounted() {
    this.initialize()
  },
  methods: {
    initialize(){
      this.loadingTable = true;
      api
        .getValidation()
        .then((res) => {
          this.loadingTable = false;
          this.servicos = res.data;
          return res;
        })
        .catch((err) => {
          this.loadingTable = false;
          this.$root.vtoast.show({
            message: "Erro ao carregar servicos.",
            color: "error",
          });
          this.$root.vtoast.show({ message: err.message, color: "error" });
          throw err;
        });
      }
  },
};
</script>
<style></style>
