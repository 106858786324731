<template>
  <v-container fluid v-if="formHotel">
    <v-row class="ma-0">
      <v-col cols="auto">
        <v-autocomplete
          :items="fornecedores"
          v-model="formHotel.fornecedor"
          outlined
          dense
          label="Nome do Fornecedor"
          item-value="id"
          item-text="nome"
        />
      </v-col>
      <v-col cols="auto" v-if="formHotel.fornecedor">
        <v-autocomplete
          :items="filteredProvedores"
          v-model="formHotel.provedor"
          outlined
          dense
          label="Nome do Hotel"
          item-value="id"
          item-text="nome"
        />
      </v-col>
      <v-col cols="auto">
        <search-local-hotel
            v-model="formHotel.local_hotel"
            :outlined="true" label="Local"></search-local-hotel>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <CotacaoImageSelect
          :provedor="formHotel.provedor"
          v-model="formHotel.imagens_provedor"
          buttonLabel="Imagens do Hotel"
        />
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="auto">
        <form-date-picker-multiple
            v-model="checkin"
            outlined label="Check-in"></form-date-picker-multiple>
      </v-col>
      <v-col cols="auto">
        <form-date-picker-multiple
            v-model="checkout"
            outlined label="Check-out"></form-date-picker-multiple>
      </v-col>
      <v-col cols="auto">
        <v-row v-if="variableDates"> 
          <v-col cols="auto">
            <v-text-field
              :rules="[checkDiarias]"
              dense
              outlined
              type="number"
              label="Mínimo de diárias"
              v-model="formHotel.diarias_min"/>
          </v-col>
          <v-col cols="auto">
            <v-text-field
              :rules="[checkDiarias]"
              dense
              outlined
              type="number"
              label="Máximo de diárias"
              v-model="formHotel.diarias_max"/>
          </v-col>
        </v-row >
        <v-field-value
            v-else
            outlined
            dense
            label="Número de Diárias"
        >
          {{
            numDiarias
          }}
        </v-field-value>
      </v-col>
    </v-row>
    <v-row>
      <v-col align-self="center" cols="auto">
        <v-menu
            v-model="menu"
            :close-on-content-click="false"
            :nudge-width="200"
            offset-x
        >
          <template v-slot:activator="{ on, attrs }">
            <v-textarea
                outlined
                v-bind="attrs"
                v-on="on"
                readonly
                no-resize
                rows="3"
                dense
                height="auto"
                :value="getPaxQuartosLabel"
            >
              <template v-slot:append>
                <v-btn
                    small
                    icon
                    color="primary"
                    v-bind="attrs"
                    v-on="on">
                  <v-icon>
                    mdi-dots-vertical
                  </v-icon>
                </v-btn>
              </template>
            </v-textarea>
          </template>

          <v-card>

            <v-divider></v-divider>

            <v-list>
              <v-list-item>
                <v-list-item-action>
                  <v-text-field
                      dense
                      label="Adultos"
                      type="number"
                      v-model="formHotel.adultos"
                  >
                  </v-text-field>
                </v-list-item-action>
                <v-list-item-title>18 ou mais</v-list-item-title>
              </v-list-item>
              <v-list-item>
                <v-list-item-action>
                  <v-text-field
                      dense
                      type="number"
                      label="Crianças"
                      v-model="formHotel.criancas">
                  </v-text-field>
                </v-list-item-action>
                <v-list-item-title>2-17 anos</v-list-item-title>
              </v-list-item>
              <v-list-item>
                <v-list-item-action>
                  <v-text-field
                      dense
                      type="number"
                      label="Bebês"
                      v-model="formHotel.bebes">
                  </v-text-field>
                </v-list-item-action>
                <v-list-item-title>0-2 anos</v-list-item-title>
              </v-list-item>
              <v-list-item>
                <v-list-item-action>
                  <v-text-field
                      dense
                      type="number"
                      label="Quartos"
                      v-model="formHotel.quartos">
                  </v-text-field>
                </v-list-item-action>
                <v-list-item-title>Quartos</v-list-item-title>
              </v-list-item>
            </v-list>

            <v-card-actions>
              <v-spacer></v-spacer>

              <v-btn
                  color="primary"
                  text
                  @click="menu = false"
              >
                Fechar
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-menu>
      </v-col>
    </v-row>
    <v-row class="ma-0">
      <v-col cols="auto">
        <v-moeda-field
            outlined
            dense
            label="Custo diária"
            v-model="formHotel.custo_diaria"/>
      </v-col>
      <v-col cols="auto">
        <v-moeda-field
            outlined
            dense
            label="RAV via fornecedor"
            v-model="formHotel.rav"/>
      </v-col>
      <v-col cols="auto">
        <v-moeda-field
            outlined
            dense
            label="Custos extras"
            v-model="formHotel.custo_extras"/>
      </v-col>
      <v-col cols="auto" v-if="formHotel">
        <v-field-value
            outlined
            dense
            label="*Custo Total"
        >
          {{
            custoTotal|dinheiro('R$')
          }}
        </v-field-value>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <v-textarea
          v-if="temCustoExtra"
          rows="3"
          filled
          label="Explicações dos custos extras"
          counter
          :rules="rules"
          v-model="formHotel.anotacao_custo_extra"
        ></v-textarea>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <v-checkbox v-model="formHotel.cafe" label="Café da manhã incluso"></v-checkbox>
      </v-col>
    </v-row>
    <v-row>
      <div>* Computado a partir da média do número de diárias, caso o período específico não for fornecido.</div>
    </v-row>
  </v-container>
</template>
<script>

import FormDatePicker from "@/components/widgets/FormDatePicker";
import SearchLocalHotel from "@/components/widgets/SearchLocalHotel";
import FormDatePickerMultiple from "@/components/widgets/FormDatePickerMultiple";
import VMoedaField from "@/components/widgets/VMoedaField";
import VFieldValue from "@/components/widgets/VFieldValue";
import CotacaoMixin from "@/mixins/CotacaoMixin"
import {daysBetween} from "@/helpers/dates"
import CotacaoImageSelect from "./CotacaoImageSelect";

import api from "@/api/api"

export default {
  components: {VFieldValue, VMoedaField, FormDatePickerMultiple, SearchLocalHotel, FormDatePicker, CotacaoImageSelect},
  props: {
    orcamento: {
      value: Object,
      default: () => {}
    },
    value: {
      type: Object,
      default: () => {}
    }
  },
  mixins: [CotacaoMixin],
  data() {
    return {
      type: null,
      menu: false,
      formHotel: null,
      checkin: [],
      checkout: [],
      provedores: [],
      filteredProvedores: [],
      fornecedores: [],
      rules: [v => !v ? false : v.length <= 500 || 'Máximo 500 caracteres']
    }
  },
  watch: {
    formHotel: {
      deep: true,
      handler: function (val) {
        this.$emit('input', val)
        if(this.formHotel.fornecedor){
          const idFornecedor = this.formHotel.fornecedor;
          this.filteredProvedores = this.fornecedores.filter((fornecedor) => {
            return fornecedor.id === idFornecedor
          })[0].provedores;
        }
      }
    },
    checkin(val) {
      if(val.length > 0)
        this.formHotel = {...this.formHotel,
          checkin_inicio: val[0],
          checkin_fim: null
        }
      if(val.length > 1)
        this.formHotel = {...this.formHotel,
          checkin_inicio: val[0],
          checkin_fim: val[1]
        }
    },
    checkout(val) {
      if(val.length > 0)
        this.formHotel = {...this.formHotel,
          checkout_inicio: val[0],
          checkout_fim: null
        }
      if(val.length > 1)
        this.formHotel = {...this.formHotel,
          checkout_inicio: val[0],
          checkout_fim: val[1]
        }
    },
  },
  created() {
    this.initialize();
  },
  computed: {
    temCustoExtra(){
      if(!this.formHotel.custo_extras) return false
      if(this.formHotel.custo_extras.valor === 0) return false
      return true
    },
    custoDiarias() {
      if (!this.formHotel.custo_diaria)
        return 0;
      return this.formHotel.custo_diaria.valor * Number(this.formHotel.diarias || 0);
    },
    custoTotal() {
      return this.calculateCustoTotalCotacaoHotel(this.formHotel)
    },
    getPaxQuartosLabel() {
      let arr_str = []
      if (this.formHotel.adultos > 0) {
        arr_str.push(`${this.formHotel.adultos} Adulto${this.pluralize(this.formHotel.adultos)}`);
      }
      if (this.formHotel.criancas > 0) {
        arr_str.push(`${this.formHotel.criancas} Criança${this.pluralize(this.formHotel.criancas)}`)
      }

      let quartos_str = `${this.formHotel.quartos} Quarto${this.pluralize(this.formHotel.quartos)}`
      arr_str.push(quartos_str)
      return arr_str.join('\n')
    },
    variableDates(){
      const variable = (this.checkin.length > 1 && this.checkValidDate(this.checkin[0]) && this.checkValidDate(this.checkin[this.checkin.length - 1])) 
        || (this.checkout.length > 1 && this.checkValidDate(this.checkout[0]) && this.checkValidDate(this.checkout[this.checkout.length - 1])) 
      if (!variable) this.formHotel.diarias_max = this.formHotel.diarias_min = null
      return variable
    },
    numDiarias() {
      let num = 0
      if(!this.variableDates) num = daysBetween(this.checkin[0], this.checkout[0])
      this.formHotel = {...this.formHotel, diarias: num}
      if(!num) return this.formHotel.diarias;
      
      return num
    },
  },
  methods: {
    checkValidDate(date){
      return isFinite(date) && Object.prototype.toString.call(date) === '[object Date]';
    },
    initialize() {
      if (!this.orcamento) {
        this.formHotel = {};
        return;
      }
      
      // completando o formHotel com informações do orcamento
      const orcamento_hotel = this.orcamento.orcamento_hotel;
      if(!orcamento_hotel) this.formHotel = {...this.value}
      else
        this.formHotel = {
          local_hotel: orcamento_hotel.local && orcamento_hotel.local.id,
          checkin_inicio: orcamento_hotel.checkin_inicio,
          checkin_fim: orcamento_hotel.checkin_fim,
          checkout_inicio: orcamento_hotel.checkout_inicio,
          checkout_fim: orcamento_hotel.checkout_fim,
          adultos: orcamento_hotel.adultos,
          quartos: orcamento_hotel.quartos,
          criancas: orcamento_hotel.criancas,
          bebes: orcamento_hotel.bebes,
          ...this.value
        };
      this.checkin = [this.formHotel.checkin_inicio, this.formHotel.checkin_fim]
      this.checkout = [this.formHotel.checkout_inicio, this.formHotel.checkout_fim]
      
      api.getProvedores(0, [['tipo', 'hospedagem']])
        .then(response => {
          this.provedores = response.data;
          this.filteredProvedores = this.provedores;
        })
        .catch(error => {
          console.error(error)
        })

      api.getFornecedores(0 ,[['tipo', 'hospedagem']]).then(response =>{
        this.fornecedores = response.data;
      })
    },
    pluralize(val) {
      return this.$options.filters.pluralize(val);
    },
    checkDiarias(value){
      let diariasMinDiff = 1;
      if(this.checkValidDate(this.checkin[this.checkin.length - 1])){
        diariasMinDiff = daysBetween(
        this.checkin[this.checkin.length - 1], this.checkout[0])
      } else {
        diariasMinDiff = daysBetween(
        this.checkin[0], this.checkout[0])
      }


      let diariasMaxDiff = 1;
      if(this.checkValidDate(this.checkout[this.checkout.length - 1])) {
        diariasMaxDiff = daysBetween(
        this.checkin[0], this.checkout[this.checkout.length - 1])
      
    } else {
        diariasMaxDiff = daysBetween(
        this.checkin[0], this.checkout[0])
      }
     
      return (diariasMaxDiff >= value && value >= diariasMinDiff) || "Valor inválido para o range especificado."
    },
  }

}
</script>
