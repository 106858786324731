import { get, post, put, del, postFormData, paginate, filter } from '../modules'

export default {
    getEmissoes() {
        return get("/api/emissoes/");
    },

    getEmissoesDetalhado(page=1, filters=[]) {
        const paginatedURL = paginate("/api/emissoes/detalhado/", page)
        const filteredURL = filter(paginatedURL, filters)

        return get(filteredURL);
    },

    getEmissoesComissao(user, page=1, filters=[]) {
        console.log(user)
        filters.push(["user",user.id])
        const paginatedURL = paginate("/api/emissoes/detalhado_comissao/", page)
        const filteredURL = filter(paginatedURL, filters)

        return get(filteredURL);
    },

    getEmissoesVenda(vendaId) {
        return get(`/api/vendas/${vendaId}/emissoes/`);
    },

    getEmissoesConcluidasVenda(vendaId) {
        return get(`/api/vendas/${vendaId}/emissoes_concluidas/`);
    },

    getEmissao(id) {
        return get(`/api/emissoes/${id}`);
    },

    updateEmissao(emissaoId, emissao) {
        return put(`/api/emissoes/${emissaoId}/`, emissao);
    },

    cancelarEmissao(emissaoId, data) {
        return post(`/api/emissoes/${emissaoId}/cancel/`, data);
    },

    getBeneficiariosEmissao(emissaoId){
        return get(`/api/emissoes/${emissaoId}/beneficiarios/`)
    },

    updateBeneficiario(id, beneficiario) {
        return put(`/api/beneficiarios/${id}/`, beneficiario);
    },


    async postDocumentoEmissao(emissaoId, documento) {
        const formData = new FormData();

        formData.append("nome", documento.nome);
        formData.append("documento", documento.arquivo);
    
        return await postFormData(`/api/emissoes/${emissaoId}/documentos/`, formData);
    },

    getDocumentosEmissao(emissaoId) {
        return get(`/api/emissoes/${emissaoId}/documentos/`)
    },

    deleteDocumentoEmissao(emissaoId, data) {
        return post(`/api/emissoes/${emissaoId}/documento_delete/`, data)
    },

    concluirEmissao(emissaoId){
        return post(`/api/emissoes/${emissaoId}/concluir/`)
    },

    getPagamentoEmissao(emissaoId){
        return get(`/api/emissoes/${emissaoId}/pagamentos/`)
    },

    postPagamentoEmissao(emissaoId, data){
        return post(`/api/emissoes/${emissaoId}/pagamentos/`, data)
    },
}
