import Vue from "vue";
import VueRouter from "vue-router";
import store from "@/store/index";

import PrivateRoutes from './PrivateRoutes'
import PublicRoutes from './PublicRoutes'
import ClientRoutes from './ClientRoutes'
import RestrictedRoutes from './RestrictedRoutes'

/*
    Routes are divided in 4 categories: 
    - private routes: accessible to all employees (system users)
    - public routes: accessible to everybody on the web
    - client routes: accessible to skyler clients (being implemented by matheus)
    - restricted routes: routes accessible only by users with given access (see admin panel to change access permissions)
*/

Vue.use(VueRouter);

// all routes
const routes = [
    ...PublicRoutes,
    ...PrivateRoutes,
    ...ClientRoutes,
    ...RestrictedRoutes
];

// route names
const getNames = routes => new Set([
    ...routes
        .filter(r => r.name)
        .map(r => r.name),
    ...routes
        .filter(r => r.children)
        .map(r => getNames(r.children))
        .reduce((curr, prev) => new Set([...curr, ...prev]), [])
])
const publicNames = getNames(PublicRoutes)
const privateNames = getNames(PrivateRoutes)
const clientNames = getNames(ClientRoutes)
const restrictedNames = getNames(RestrictedRoutes)

const router = new VueRouter({
    mode: "history",
    base: process.env.BASE_URL,
    routes,
});

router.beforeEach(async (to, from, next) => {
    // public route accessible to clients
    if(publicNames.has(to.name)){
        next()
        return
    }

    // checking logged user
    let logged = store.state.usuario.logged_user
    if(!logged) {
        await store.dispatch("usuario/whoami");
        logged = store.state.usuario.logged_user
    }
    const authenticated = store.state.usuario.authenticated_user
    const client = store.state.usuario.cliente 
    const authorized_restricted_routes = store.state.usuario.authorized_restricted_routes // to be implemented

    // main logic
    if(!logged){
        if(authenticated && to.name == 'otp') next()
        else next({name: 'login'})
    }
    else {
        // for clients
        if(client){
            if(clientNames.has(to.name)) next();
            else next({name: 'usuario-vendas'})
        }
        // for employees
        else {
            // checking for restricted routes
            if(restrictedNames.has(to.name)){
                if(authorized_restricted_routes.includes(to.name)) next()
                else next({name: 'home'})
            }
            // every other private route
            else if(privateNames.has(to.name)){
                if(to.name === 'login') next({name: 'home'})
                else next()
            }
            else next({name: 'home'})
        }
    }
});

export default router;
