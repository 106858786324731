import TableRacsAbertas from "@/components/venda/rac/TableRacsAbertas.vue";
import TableRacsEmProcesso from "@/components/venda/rac/TableRacsEmProcesso.vue";
import TableRacsFinalizadas from "@/components/venda/rac/TableRacsFinalizadas.vue";

export default [
   {
       path: "aberta",
       name: "rac-aberta",
       component: TableRacsAbertas
   },
   {
      path: "em_processo",
      name: "rac-em-processo",
      component: TableRacsEmProcesso
  },
  {
   path: "finalizada",
   name: "rac-finalizada",
   component: TableRacsFinalizadas
},]