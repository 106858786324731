<template>
    <v-container class="pa-10" fluid>
      <v-row>
        <v-col>
          <div class="text-h5 transition-swing">
            Emissões
          </div>
          <v-breadcrumbs :items="breadcumb_items"></v-breadcrumbs>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12">
          <EmissoesTable />
        </v-col>
      </v-row>
    </v-container>
</template>

<script>
import EmissoesTable from "@/components/Emissoes/EmissoesTable";

export default {
  components: {
    EmissoesTable,
  },
  data() {
    return {
      loadingSaving: false,
      loadingTable: true,
      breadcumb_items: [
        {
          text: 'Home',
          to: {
            name: "home",
          },
          exact: true

        },
        {
          text: 'Emissões',
          to: {
            name: "emissoes"
          }
        }
      ]
    }
  },
}
;
</script>


