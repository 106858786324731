<template>
    <div>
        <v-container class="text-center">
            <img src="logo-skyler.png" alt="Logo Skyler" width="300vw" max-width="200px">
            <v-divider />
  
            <v-card width="30rem" class="mx-auto mt-9" flat>
                <v-form>
                    <v-card-title>
                        <v-spacer />
                        <h2> Verificação de dois fatores </h2>
                        <v-spacer />
                    </v-card-title>

                    <v-card-text>
                        <span>
                            Enviamos um código ao seu e-mail <br>
                            Por favor, digite o código enviado ao seu e-mail para fazer o login.
                        </span>

                        <v-col class="text-h5" v-if="timer>0">
                            <v-icon>mdi-timer</v-icon> {{ timer }}
                        </v-col>
                        <v-col class="text-h5" v-else>
                            <v-icon color="red">mdi-timer-remove</v-icon> Expirado
                        </v-col>

                        <v-otp-input
                            length="4"
                            v-model="form.otp"
                        />
                    </v-card-text>

                    <v-card-actions>
                        <v-spacer />
                        <v-btn :loading="loading" block color="secondary" @click="verify">
                            Verificar e Acessar
                        </v-btn>
                    </v-card-actions>
                </v-form>
            </v-card>
        </v-container>
        <Footer/>
    </div>
</template>
<script>
import Footer from '@/components/App/Footer.vue'
  
export default {
    name: "TwoFactorAuth",
    components: {Footer},
    data() {
        return {
            interval: null
        }
    },
    methods: {
        async verify() {
            this.loading = true;
            await this.$store.dispatch("usuario/login_otp", this.form)
                .catch(err => {
                    this.loading = false;
                    console.error(err)
                    this.$root.vtoast.show({message: "Erro ao fazer login", color: "error"});
                })
            
            window.clearInterval(this.interval)
            await this.$router.push({name: "home"})
            this.loading = false;
        },
    },

    created() {
        this.interval = window.setInterval(() => {
            if(this.timer == 1){
                this.verify()
                this.timer -= 1
            } else {
                this.timer -= 1
            }
        }, 1000)
    },
    
    data() {
        return {
            loading: false,
            timer: 60,
            form: {
            otp: ""
            }
        }
    }
  }
</script>
