<template>
  <v-container fluid class="pa-0 w-12">
    <v-row class="pa-0">
      <v-col v-show="showSelectMoeda">
        <v-autocomplete
          @input="inputSelectMoeda"
          outlined
          :dense="dense"
          @blur="showSelectMoeda = false"
          v-model="form.moeda"
          item-text="sigla"
          item-value="id"
          :items="moedas"
          :disabled="disabled"
        />
      </v-col>
      <v-col class="ma-0" v-show="!showSelectMoeda">
        <v-text-field
          :loading="loading"
          :disabled="loading || disabled"
          :outlined="outlined"
          :dense="dense"
          :prefix="simbolo"
          :label="label"
          v-model="valorFormatado"
          @blur="onBlur"
          :hide-details="false"
          @input="input"
          :messages="valorRealCotacao"
        >
          <template v-slot:append v-if="!showSelectMoeda">
            <v-btn
              :loading="loading"
              :disabled="loading || disabled || disabledEscolhaMoeda"
              small
              icon
              @click="showSelectMoeda = !showSelectMoeda"
            >
              <v-icon>mdi-bank</v-icon>
            </v-btn>
          </template>
          <template v-slot:message="{ key, message }">
            <div class="primary--text">
              {{ message }}
            </div>
          </template>
        </v-text-field>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { taxaConversao } from "@/helpers/financeiro";

export default {
  props: {
    value: {
      type: Object,
      required: false,
      default: () => {},
    },
    dense: {
      type: Boolean,
      default: false,
    },
    label: {
      type: String,
      required: false,
      default: "Valor",
    },
    outlined: {
      type: Boolean,
      required: false,
      default: false,
    },
    disabled: {
      type: Boolean,
      required: false,
      default: false,
    },
    disabledEscolhaMoeda: {
      type: Boolean,
      required: false, 
      default: false,
    },
  },
  data() {
    return {
      showSelectMoeda: false,
      valorFormatado: null,
      form: null,
      loading: false,
    };
  },
  watch: {
    valorFormatado: {
      immediate: true,
      handler(valorFormatado) {
        if (!valorFormatado) return 
        this.form.valor = Number(
          valorFormatado.replace(/\./g, "").replace(/,/g, ".")
        );
        // Apenas para garantir que obteremos o valorFormatado mais atual no pai
        const taxa = this.getTaxa();
        const valorConvertido = this.form.valor * this.cotacao * taxa
        this.form.valor_convertido = parseFloat(valorConvertido)
        this.input();
      },
    },
  },
  created() {
    const defaultValue = {
      moeda: null,
      valor: 0,
      valor_convertido: 0
    };
    this.form = {
      ...defaultValue,
    };
    if (this.value) {
      this.form = {
        ...defaultValue,
        ...this.value,
      };
    }
    this.valorFormatado = this.$options.filters["dinheiro"](this.form.valor);
    this.input();
  },

  async mounted() {
    this.loading = true;
    this.$store
      .dispatch("global/fetchMoedas")
      .then((res) => {
        if (!this.form.moeda) {
          this.form.moeda = this.moedas.find(
            (moeda) => moeda.sigla === "BRL"
          ).id;
        }
        this.loading = false;
      })
      .catch((err) => {
        this.loading = false;
        this.$root.vtoast.show({ message: err.message, type: "error" });
      });
  },
  methods: {
    onBlur(e) {
      this.valorFormatado = this.$options.filters["dinheiro"](this.form.valor);
    },
    input() {
      if (this.form.valor !== null && this.form.valor !== undefined) {
        this.form.valor = parseFloat(this.form.valor).toFixed(2);
      }
      this.$emit("input", this.form);
    },
    inputSelectMoeda() {
      this.showSelectMoeda = false;
      this.input();
    },
    getTaxa() {
      return taxaConversao(
          !this.moedaSelecionada ? undefined : this.moedaSelecionada.sigla
      )
    }
  },
  computed: {
    moedas() {
      return this.$store.state.global.moedas;
    },
    moedaSelecionada() {
      if (!(this.form && this.form.moeda)) return undefined;
      const id = this.form.moeda.id || this.form.moeda;
      return this.moedas.find((moeda) => id === moeda.id);
    },
    simbolo() {
      if (!this.moedaSelecionada) {
        return "R$";
      }
      return this.moedaSelecionada.simbolo;
    },
    cotacao() {
      if (!this.moedaSelecionada) {
        return 1.0;
      }
      let cambioStr = this.moedaSelecionada.cambio;
      return Number(cambioStr);
    },
    valorRealCotacao() {
      if (this.loading) return "";
      if (!this.form.valor) return "";
      if (this.moedaSelecionada && this.moedaSelecionada.sigla === "BRL") {
        return "";
      }
      const taxa = this.getTaxa()
      const valorRealStr = this.$options.filters["dinheiroReal"](
        this.form.valor * this.cotacao * taxa
      );
      const cotacaoStr = this.$options.filters["dinheiro"](this.cotacao);
      return `${valorRealStr} - Cotação: ${cotacaoStr}`;
    },
  },
};
</script>
