<template>
  <v-card>
    <v-card-title>Beneficiário</v-card-title>
    <v-spacer></v-spacer>
    <v-card-text>
      <v-container>
        <v-row>
          <v-col>
            <search-contato
              v-model="selectedContato"
              :defaultContatos="contatos"
              :filters="filters"
              :isButtonDisabled="isButtonDisabled"
            />
          </v-col>
        </v-row>
        <v-row v-if="cotacaoVoo">
          <v-col>
            <v-text-field label="Assento" outlined v-model="value.assento" />
          </v-col>
          <v-col>
            <v-text-field
              label="Tipo de Assento"
              outlined
              v-model="value.tipo_assento"
            />
          </v-col>
        </v-row>
        <v-row v-if="cotacaoVoo">
          <v-col>
            <v-text-field label="Bagagem" outlined v-model="value.bagagem" />
          </v-col>
        </v-row>
        <v-row v-if="cotacaoHotel">
          <v-col>
            <v-text-field
              label="Tipo do Quarto"
              outlined
              v-model="value.tipo_quarto"
            />
          </v-col>
        </v-row>
      </v-container>
    </v-card-text>
    <v-card-actions>
      <v-spacer></v-spacer>
      <v-btn text @click="close">Sair</v-btn>
      <v-btn text @click="save">Salvar</v-btn>
    </v-card-actions>
  </v-card>
</template>
<script>
import SearchContato from "@/components/widgets/SearchContato";

export default {
  components: { SearchContato },

  props: {
    value: {
      type: Object,
      default: () => {},
    },
    dialog: {
      type: Boolean,
      default: false,
    },
    cotacao: {
      type: Object,
      default: () => {},
    },
    contatos: {
      type: Array,
      default: () => [],
    },
    filters: {
      type: Array,
      default: () => [],
    },
    isButtonDisabled: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      selectedContato: null,
    };
  },

  watch: {
    selectedContato() {
      if (this.selectedContato) {
        this.value.contato = this.selectedContato.id;
      }
    },
  },

  computed: {
    cotacaoVoo() {
      if (!this.cotacao) return false;
      if (this.cotacao.cotacao_voo) return true;
      return false;
    },
    cotacaoHotel() {
      if (!this.cotacao) return false;
      if (this.cotacao.cotacao_hotel) return true;
      return false;
    },
  },

  methods: {
    save() {
      if (this.selectedContato != null) {
        this.$emit("save");
        // this.selectedContato = null;
        this.close();
      } else {
        this.$emit("close");
      }
    },
    close() {
      this.selectedContato = null;
      this.$emit("close");
    },
  },
};
</script>
