<template>
  <v-container fluid v-if="formCarro">
    <v-row class="ma-0">
      <v-col cols="auto">
        <search-local
            v-model="formCarro.local_retirada"
            outlined label="Local retirada"/>
      </v-col>
      <v-col cols="auto">
        <search-local
            v-model="formCarro.local_devolucao"
            outlined label="Local devolução"/>
      </v-col>

      <!-- Estamos verificando se há mais de um dia possível para retirada/devolucao.
      Caso não haja, não há necessidade de possibilitar uma nova escolha de datas -->

      <v-col cols="auto" v-if=" numDiasRetirada > 0">
        <v-datetime-text-field
        dense
        v-model="formCarro.data_hora_retirada"
        
        label="Data retirada"/>
      </v-col>
      <v-col cols="auto" v-else>
        <v-field-value label="Data de retirada">
          {{ data_retirada | convertDataBR }} 
        </v-field-value>
      </v-col>

      <v-col cols="auto" v-if=" numDiasDevolucao > 0">
        <v-datetime-text-field
        dense
        v-model="formCarro.data_hora_devolucao"
        label="Data de devolução"/>
      </v-col>
      <v-col cols="auto" v-else>
        <v-field-value label="Data de devolução">
          {{ data_devolucao | convertDataBR }} 
        </v-field-value>
      </v-col>
    </v-row>
    <v-row class="ma-0">
      <v-col cols="auto">
        <v-text-field
            outlined
            dense
            label="Empresa"
            v-model="formCarro.empresa"/>
      </v-col>
      <v-col cols="auto">
        <v-text-field
            outlined
            dense
            label="Tipo de carro"
            v-model="formCarro.tipo_carro"/>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols='auto'>
        <v-select
          :items="fornecedores"
          v-model="formCarro.fornecedor"
          outlined
          dense
          label="Fornecedor"
          item-value="id"
          item-text="nome"
        >
        </v-select>
      </v-col>
      <v-col>
        <v-select
          :items="provedores"
          v-model="formCarro.provedor"
          outlined
          dense
          label="Provedor"
          item-value="id"
          item-text="nome"
        />
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <CotacaoImageSelect
          :provedor="formCarro.provedor"
          v-model="formCarro.imagens_provedor"
        />
      </v-col>
    </v-row>
    <v-row class="ma-0">
      <v-col cols="auto">
        <v-field-value label="Quantidade de diárias">
          {{ diariasCarro }} 
        </v-field-value>
      </v-col>
      <v-col cols="auto">
        <v-moeda-field
            outlined
            dense
            label="Custo diária"
            v-model="formCarro.custo_diaria"/>
      </v-col>
      <v-col cols="auto">
        <v-moeda-field
            outlined
            dense
            label="Custos extras"
            v-model="formCarro.custo_extras"/>
      </v-col>
      <v-col cols="auto">
        <v-moeda-field
            outlined
            dense
            label="RAV via fornecedor"
            v-model="formCarro.rav"/>
      </v-col>
      <v-col cols="auto" v-if="formCarro">
        <v-field-value
            outlined
            dense
            label="Custo Total"
        >
          {{
            custoTotal|dinheiro('R$')
          }}
        </v-field-value>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <v-textarea
          v-if="temCustoExtra"
          rows="3"
          filled
          label="Explicações dos custos extras"
          counter
          :rules="rules"
          v-model="formCarro.anotacao_custo_extra"
        ></v-textarea>
      </v-col>
    </v-row>
  </v-container>
</template>
<script>

import FormDatePicker from "@/components/widgets/FormDatePicker";
import VMoedaField from "@/components/widgets/VMoedaField";
import VFieldValue from "@/components/widgets/VFieldValue";
import SearchLocal from "@/components/widgets/SearchLocal";
import VDatetimeTextField from "@/components/widgets/VDatetimeTextField";
import CotacaoMixin from "@/mixins/CotacaoMixin"
import {daysBetween, formatDates} from "@/helpers/dates"
import CotacaoImageSelect from "./CotacaoImageSelect"

export default {
  components: {VDatetimeTextField, SearchLocal, VFieldValue, VMoedaField, FormDatePicker, CotacaoImageSelect},
  mixins: [CotacaoMixin],
  props: {
    orcamento: {
      value: Object
    },
    value: {
      type: Object
    },
  },
  data() {
    return {
      numDiasRetirada:  null,
      numDiasDevolucao: null, 
      data_retirada: null,
      data_devolucao: null,
      type: null,
      menu: false,
      formCarro: null,
      rules: [v => !v ? false : v.length <= 500 || 'Máximo 500 caracteres'],
    }
  },
  computed: {

    diariasCarro(){
      return daysBetween(this.data_retirada,this.data_devolucao);
    },

    temCustoExtra(){
      if(!this.formCarro.custo_extras) return false
      if(this.formCarro.custo_extras.valor === 0) return false
      return true
    },
    custoTotal() {
      return this.calculateCustoTotalCotacaoCarro(this.formCarro) 
    },
    fornecedores() {
      return this.$store.state.global.fornecedores
    },
    provedores() {
      if(!this.fornecedores) return []
      const fornecedor = this.fornecedores.find(f => f.id == this.formCarro.fornecedor)
      const provedores = fornecedor?.provedores.filter(provedor => provedor.tipo_servico == 'locadora_de_carro')
      return provedores
    }
  },
  watch: {
    formCarro: {
      deep: true,
      handler: function (val) {
       /*  Só precisamos tratar a mudança das datas que podem ser mudadas.
        Ou seja, as que tem um span de dias possíveis.
        Mesma ideia utilizada no v-if v-else do template */
        if(  this.numDiasRetirada > 0 )  this.data_retirada = formatDates(val.data_hora_retirada);
        if(  this.numDiasDevolucao > 0 )  this.data_devolucao = formatDates(val.data_hora_devolucao);
        this.$emit('input', val);
      }
    },
   
  },
  created() {
    this.initialize();
  },
  methods: {
    async initialize() {
      if (!this.orcamento) {
        this.formCarro = {};
        return;
      }
      const orcamento_carro = this.orcamento.orcamento_carro;
      if(!orcamento_carro) this.formCarro = {...this.value}
      else
        await this.$nextTick();
        this.data_retirada = orcamento_carro?.data_retirada_inicio || this.value?.data_hora_retirada;
        this.data_devolucao = orcamento_carro?.data_devolucao_fim || this.value?.data_hora_devolucao;
        
        /* Contando se há mais de uma dia possível na retirada e/ou na devolução */
        this.numDiasRetirada =  daysBetween(orcamento_carro?.data_retirada_inicio,orcamento_carro?.data_retirada_fim);
        this.numDiasDevolucao = daysBetween(orcamento_carro?.data_devolucao_inicio,orcamento_carro?.data_devolucao_fim);

        this.formCarro = {
          diarias: this.diariasCarro,
          local_retirada: orcamento_carro?.local_retirada,
          local_devolucao: orcamento_carro?.local_devolucao,
          data_hora_retirada: orcamento_carro?.data_retirada_inicio,
          data_hora_devolucao: orcamento_carro?.data_devolucao_fim,
          flexibilidade_devolucao: orcamento_carro?.flexibilidade_devolucao,
          flexibilidade_retirada: orcamento_carro?.flexibilidade_retirada,
          ...this.value
        };
    }
  }

}
</script>
