import { get, post } from '../modules'

export default {
    getCarrinho(venda_id) {
        return get(`/api/vendas/${venda_id}/carrinho/`)
    },
    
    addCarrinho(venda_id, cotacao) {
        return post(`/api/vendas/${venda_id}/carrinho/`, cotacao)
    },

    removeFromCarrinho(venda_id, servico) {
        return post(`/api/vendas/${venda_id}/remove_carrinho/`, servico)
    },

    getFatura(venda_id){
        return get(`/api/vendas/${venda_id}/fatura/`)
    },

    postFatura(venda_id, carrinho){
        return post(`/api/vendas/${venda_id}/fatura/`, carrinho)
    },

}
