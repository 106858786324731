<template>
  <v-container fluid v-if="loading"> Loading... </v-container>
  <v-container fluid v-else>
    <venda-racs-list :cotacoes="cotacoes" :venda="venda" :servicos="servicos" />
  </v-container>
</template>


<script>
import { mapState } from "vuex";

import VendaRacsList from "./VendaRacsList";

export default {
  components: { VendaRacsList },

  data() {
    return {
      loading: false,
    };
  },

  async mounted() {
    this.loading = true;
    await this.$store.dispatch("venda/fetchCotacoes");
    await this.$store.dispatch("venda/fetchServicos");
    this.loading = false;
  },

  computed: {
    ...mapState({
      orcamentos: (state) => state.venda.orcamentos,
      venda: (state) => state.venda.venda,
      cotacoes: (state) => state.venda.cotacoes,
      servicos: (state) => state.venda.servicos,
    }),
  },

  methods: {},
};
</script>
