<template>
  <v-container fluid>
    <v-row dense v-for="(trecho, index) in trechos" :key="trecho.order">
      <v-col v-show="!hideItems[index]">
        <v-row dense class="pl-4">
          <v-field-value :label="'Trecho ' + (index + 1)" />
          <v-spacer />
        </v-row>
        <v-row dense class="pl-8">
          <v-col cols="auto">
            <v-field-value label="Origem">{{ trecho.origem }}</v-field-value>
          </v-col>
          <v-col cols="auto">
            <v-field-value label="Destino">{{ trecho.destino }}</v-field-value>
          </v-col>
          <v-col cols="auto">
            <v-field-value label="Datas">{{
              trecho.datas.join(", ")
            }}</v-field-value>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import VFieldValue from "@/components/widgets/VFieldValue";

export default {
  components: { VFieldValue },
  props: {
    trechos: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      hideItems: [],
    };
  },
  watch: {
    trechos: {
      deep: true,
      immediate: true,
      handler: function (val, oldVal) {
        this.hideItems = this.trechos.map(() => false);
      },
    },
  },
  methods: {
    toggleHide(index) {
      this.hideItems[index] = !this.hideItems[index];
    },
  },
};
</script>
