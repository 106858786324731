export default [
    {key: 'venda', value: 'Venda'},
    {key: 'orcamento', value: 'Orçamento'},
    {key: 'cotacao', value: 'Cotação'},
    {key: 'servico', value: 'Serviço'},
    {key: 'pagamento', value: 'Pagamento'},
    {key: 'emissao', value: 'Emissão'},
    {key: 'milhas', value: 'Milhas'},
    {key: 'contato', value: 'Contato'},
    {key: 'rac', value: 'RACs'},
    {key: 'ordem_pagamento', value: 'Ordem de pagamento'},
]