import { get, post, del, paginate, filter } from '../modules'

export default {
    getOrcamentos() {
        return get("/api/orcamentos/");
    },

    getOrcamentosDetalhado(page=1, filters=[]) {
        const paginatedURL = paginate("/api/orcamentos/detalhado/", page)
        const filteredURL = filter(paginatedURL, filters)

        return get(filteredURL);
    },

    getOrcamento(orcamentoId) {
        return get(`/api/orcamentos/${orcamentoId}/`);
    },

    deleteOrcamento(orcamentoId) {
        return del(`/api/orcamentos/${orcamentoId}/`);
    },

    createOrcamentoCotacao(orcamentoId, cotacaoData) {
        return post(`/api/orcamentos/${orcamentoId}/cotacoes/`, cotacaoData);
    },

    finalizarOrcamento(id) {
        return post(`/api/orcamentos/${id}/finalizar/`);
    },

    pdfOrcamento(data) {
        return post('/api/pdf/orcamento_pdf/', data)
    }
}
