<template>
  <v-container fluid>
    <v-row>
      <v-col>
        <div
            class="text-h5 transition-swing"
        >
          Detalhe do orçamento
        </div>
      </v-col>
      <v-spacer></v-spacer>
      <v-breadcrumbs :items="items"></v-breadcrumbs>
    </v-row>
    <v-row>
      <v-col>
        <orcamento-detalhe></orcamento-detalhe>
      </v-col>
    </v-row>
  </v-container>
</template>
<script>


import OrcamentoDetalhe from "@/components/Orcamento/OrcamentoDetalhe";
export default {
  components: {OrcamentoDetalhe},
  data() {
    return {};
  },
  computed: {
    items() {
      return [
        {
          text: 'Home',
          to: {name:"home"},
          exact: true,
        },
        {
          text: 'Orçamentos',
          to: {name: "orcamentos"},
          disabled: false,
          exact: true
        },
        {
          text: 'Orçamento #0004',
          disabled: true,
          to: ""
        }
      ]
    }
  }
};

</script>
