<template lang="">
    <div class="page pb-2 px-0">
           <servico-container 
           v-for="(servico, index) in servicosLivres"
           :key="servico.id"
           :servico="servico"
           :index="index"
           :beneficiarios="beneficiarios"
           :valorAbertoCotacoes="valorAbertoCotacoes"
           /> 
    </div>
</template>
<script>
import CotacaoMixin from "@/mixins/CotacaoMixin";
import ServicoContainer from './ServicoContainer.vue'

export default {
    components: {
        ServicoContainer,
    },
    data() {
        return {
        }
    },
    props: {
        servicos: {
        type: Array,
        default: () => []
        },
        vendas: {
        type: Array,
        default: () => []
        },
        valorAbertoCotacoes: {
        type: Object,
        default: () => {}
        },
        beneficiarios: {
            type: Array,
            default: () => []
        },
    },
    mixins: [CotacaoMixin],
    computed: {
        servicosLivres() {
            const servicosVenda = this.servicos.map(servico => servico.servicos).flat()
            const selectedServicos = servicosVenda.filter(servico =>
                this.vendas.some(venda=>
                venda.id === servico.cotacao.orcamento.venda))

            const servicosDistintos = selectedServicos.filter((v,i,a)=>
            a.findIndex(v2=>(v2.cotacao.id===v.cotacao.id))===i)
            return servicosDistintos
        },
    }
}
</script>