<template lang="">
  <v-card class="elevation-5">
    <v-card-title>Compras milhas/wallet validadas</v-card-title>
    <v-data-table 
    :headers="headers" 
    :items="items" 
    :hide-default-footer="true" 
    :loading="loading"
    :items-per-page="10"
    sort-by="id" 
    sort-desc
    >
      <template v-slot:top>
        <v-container fluid>
          <v-row class="">
            <v-col cols=3>
              <v-select
                clear-icon="mdi-close"
                clearable
                outlined
                v-model="selectedTipoProvedor"
                label="Tipo de provedor"
                :items="tipoProvedor"
                item-text="text"
                item-value="value"
                dense
              />
            </v-col>
            <v-col cols=3 v-if="selectedTipoProvedor === 'conta_propria'">
              <v-text-field
                clear-icon="mdi-close"
                clearable
                outlined
                v-model="filters.titular_conta_propria"
                label="Nome do titular"
                dense
              />
            </v-col>
            <v-col cols=3 v-if="selectedTipoProvedor === 'custo_milheiro'">
              <v-select
                clear-icon="mdi-close"
                clearable
                outlined
                v-model="filters.provedor_milhas"
                label="Provedor de Milhas"
                :items="provedoresMilheiro"
                item-text="nome"
                item-value="id"
                dense
              />
            </v-col>
            <v-col cols=3>
              <v-text-field
                dense
                outlined
                clearable
                clear-icon="mdi-close"
                append-icon="mdi-magnify"
                label="ID da Venda"
                v-model="filters.id_venda"
              />
            </v-col>
            <v-col cols=3>
              <v-text-field
                dense
                outlined
                clearable
                clear-icon="mdi-close"
                append-icon="mdi-magnify"
                label="Passageiro"
                v-model="filters.passageiro"
              />
            </v-col>
          </v-row>
          <v-row>
            <v-col cols=3>
              <v-select
                clear-icon="mdi-close"
                clearable
                outlined
                v-model="filters.cia_aerea"
                label="Companhia Aerea"
                :items="ciaAereas"
                item-text="text"
                item-value="value"
                dense
              />
            </v-col>
            <v-col cols=2>
              <FormDatePicker
                v-model="filters.data_inicio"
                outlined
                :dense="false"
                label="Data solicitação"
                name="data_inicio"
                @clear="() => filters.data_inicio = null"
              />
            </v-col>
            <v-col cols="auto" class="mt-4">
              <span>até</span>
            </v-col>
            <v-col cols=2>
              <FormDatePicker
                v-model="filters.data_fim"
                outlined
                :dense="false"
                label="Data solicitação"
                name="data_fim"
                @clear="() => filters.data_fim = null"
              />
            </v-col>
          </v-row>
        </v-container>
      </template>
      <template v-slot:item.conta_fidelidade="{ item }">
  <div>
    {{
      item.conta_fidelidade
        ? getContaFidelidade(item.conta_fidelidade)
        : null | formatContaFidelidade
    }}
    {{ !item.conta_fidelidade ? item : "" | formatContaMilheiro }}
  </div>
</template>
      <template v-slot:item.contato="{ item }">
  {{ item.contato | nomeSobrenome }}
</template>
      <template v-slot:item.milhas_bilhete="{ item }">
  {{
    item.valor
      ? formatPrice(item.valor)
      : formatValorFinanceiro(item?.subcotacao?.custo_bilhete)
  }}
</template>
      <template v-slot:item.venda="{ item }">
  <v-link-text-field
    :to="{ name: `venda-info`, params: { id: item?.cotacao?.venda } }"
    text
    small
  >
    {{ item?.cotacao?.venda }}
  </v-link-text-field>
</template>
      <template v-slot:item.cotacao="{ item }">
  <v-link-text-field
    :to="{ name: `orcamento`, params: { id: item?.cotacao?.orcamento } }"
    text
    small
  >
    {{ item?.cotacao?.id }}
  </v-link-text-field>
</template>
      <template v-slot:item.subcotacao="{ item }">
  <v-link-text-field
    :to="{ name: `orcamento`, params: { id: item?.cotacao?.orcamento } }"
    text
    small
  >
    {{ item?.subcotacao?.id }}
  </v-link-text-field>
</template>
      <template v-slot:item.servico="{ item }">
  <v-link-text-field
    :to="{ name: `venda-servicos`, params: { id: item?.cotacao?.venda } }"
    text
    small
  >
    {{ item?.servico?.id }}
  </v-link-text-field>
</template>
      <template v-slot:item.data_solicitacao="{item, value}">
  <div v-if="item.servico">
    {{ item.servico?.created_at | convertDataBR }}
  </div>
  <div v-else>--</div>
</template>
<template v-slot:item.created_at="{item, value}">
  <div v-if="item.servico">
    {{ item.created_at | convertDataBR }}
  </div>
  <div v-else>--</div>
</template>
      <template v-slot:item.created_by="{ item }">
  {{ item.created_by | nomeSobrenome }}
</template>
      <template v-slot:item.desagio_valor="{item}">
  {{
    item.valor_compra
      ? formatValorFinanceiro(item.valor_compra)
      : `${item.desagio_compra}%`
  }}
</template>
      <template v-slot:item.emissao="{item}">
  <div v-for="emissaoID in item.subcotacao.emissoes">
    <v-link-text-field
      :to="{ name: `emissao`, params: { id: emissaoID } }"
      text
      small
    >
      {{ emissaoID }}
    </v-link-text-field>
  </div>
</template>
    </v-data-table>
    <v-pagination
      v-model="currentPage"
      :length="numPages"
      :total-visible="7"
    ></v-pagination>
  </v-card>
</template>
<script>
import { formatFornecedor } from "@/helpers/fornecedores";
import { formatValorFinanceiro } from "@/helpers/financeiro";
import VLinkTextField from "@/components/widgets/VLinkTextField";
import FormDatePicker from "../widgets/FormDatePicker.vue";
import { formatDates } from "@/helpers/dates";

export default {
  components: {
    VLinkTextField,
    FormDatePicker,
  },
  props: {
    items: {
      type: Array,
      default: () => [],
    },

    loading: {
      type: Boolean,
      default: false,
    },

    contasFidelidade: {
      type: Array,
      default: () => [],
    },

    numPages: {
      type: Number,
      default: 1,
    },
  },

  data: () => ({
    milhas: [],
    headers: [
      {
        text: "ID",
        align: "start",
        sortable: true,
        value: "id",
      },
      { text: "Conta fidelidade", value: "conta_fidelidade" },
      { text: "Passageiro (PAX)", value: "contato" },
      { text: "Venda", value: "venda" },
      { text: "Cotação", value: "cotacao" },
      { text: "Serviço", value: "servico" },
      { text: "Bilhete", value: "subcotacao" },
      { text: "Emissão", value: "emissao" },
      { text: "Quantidade de Milhas/Custo Bilhete", value: "milhas_bilhete" },
      { text: "Deságio/Valor de compra", value: "desagio_valor" },
      { text: "Validado por", value: "created_by" },
      { text: "Data de Validação", value: "created_at" },
    ],
    currentPage: 1,
    filters: {
      provedor_milhas: null,
      cia_aerea: null,
      id_venda: "",
      passageiro: "",
      milheiro: "",
      data_inicio: "",
      data_fim: "",
      conta_propria: "",
      titular_conta_propria: "",
    },
    timer: undefined,
    tipoProvedor: [
      { text: "Conta própria", value: "conta_propria" },
      { text: "Custo milheiro", value: "custo_milheiro" },
    ],
    selectedTipoProvedor: null,
  }),

  mounted() {
    const resCias = this.$store
      .dispatch("global/fetchCiaAereas")
      .catch((err) => {
        this.$root.vtoast.show({ message: err.message, type: "error" });
      });
  },

  computed: {
    provedoresMilheiro() {
      return this.$store.state.global.provedores.filter(
        (provedor) => provedor.tipo_servico === "milhas"
      );
    },

    ciaAereas() {
      const ciaAereas = this.$store.state.global.ciaAereas;
      return ciaAereas.map((cia) => cia.nome);
    },
  },

  methods: {
    changePage() {
      clearTimeout(this.timer);

      let filters = [];

      if (this.filters.provedor_milhas) {
        filters.push(["provedor_milhas", this.filters.provedor_milhas]);
      }

      if (this.filters.cia_aerea) {
        filters.push(["cia_aerea", this.filters.cia_aerea]);
      }

      if (this.filters.conta_propria) {
        filters.push(["conta_propria", this.filters.conta_propria]);
      }

      if (this.filters.titular_conta_propria) {
        filters.push([
          "titular_conta_propria",
          this.filters.titular_conta_propria,
        ]);
      }

      if (this.filters.id_venda) {
        filters.push(["id_venda", this.filters.id_venda]);
      }

      if (this.filters.passageiro) {
        filters.push(["passageiro", this.filters.passageiro]);
      }

      if (this.filters.data_inicio && this.filters.data_fim) {
        let formattedDataInicio = formatDates(this.filters.data_inicio);
        let formattedDataFim = formatDates(this.filters.data_fim);
        filters.push(
          ["data_inicio", formattedDataInicio],
          ["data_fim", formattedDataFim],
          ["data_solicitacao", [formattedDataInicio, formattedDataFim]]
        );
      }

      this.timer = setTimeout(() => {
        this.$emit("pageUpdate", this.currentPage, filters);
      }, 1000);
    },

    formatPrice(value) {
      return value.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1.");
    },
    getContaFidelidade(contaFidelidadeID) {
      if (!this.contasFidelidade) return null;
      return this.contasFidelidade.find((e) => e.id === contaFidelidadeID);
    },

    formatValorFinanceiro,
  },
  watch: {
    dialog(val) {
      val || this.close();
    },
    currentPage(val) {
      this.changePage();
    },
    filters: {
      deep: true,
      handler() {
        this.changePage(1);
        this.loadingTable = true;
      },
    },
    selectedTipoProvedor: {
      deep: true,
      handler: function (val) {
        if (this.selectedTipoProvedor === "conta_propria") {
          this.filters.conta_propria = val;
        } else {
          this.filters.conta_propria = null;
        }
      },
    },
  },
};
</script>