<template>
  <v-app>
    <template>
      <v-app-bar
          v-if="!isOutPage"
          app
          dark
          dense>
        <v-app-bar-nav-icon color="white" @click.stop="drawer = !drawer"></v-app-bar-nav-icon>
        <v-spacer></v-spacer>
        <v-menu
            top
            close-on-click
        >
          <template v-slot:activator="{ on, attrs }">

            <v-btn icon

                   v-bind="attrs"
                   v-on="on">

              <v-avatar
                  color="primary"
                  size="40"
              >
                {{ avatarText }}
              </v-avatar>
            </v-btn>
          </template>

          <v-list>
            <v-list-item
                link
                v-if="loggedUser?.id_token"
            >
              <v-list-item-title>
                <v-icon>mdi-key </v-icon>
                {{ loggedUser?.id_token }}
              </v-list-item-title>
            </v-list-item>
            <v-list-item
                link
                @click="logout"
            >
              <v-list-item-title>
                <v-icon>mdi-logout</v-icon>
                Logout
              </v-list-item-title>
            </v-list-item>
          </v-list>
        </v-menu>

      </v-app-bar>

      <Menu v-model="drawer" v-if="!isOutPage"/>
      <v-main>
        <router-view/>
      </v-main>
      <Vtoast ref="vtoast"/>
    </template>
  </v-app>
</template>

<script>
import Menu from './components/App/Menu.vue'
import Login from '@/views/LoginView.vue'
import Vtoast from "@/components/widgets/VToast";
import markerSDK from '@marker.io/browser';

export default {
  name: 'App',
  components: {
    Vtoast,
    Menu,
    Login
  },
  data: () => {
    return {
      drawer: true,
      loginPage: false,
      out_pages: ['login', 'otp']
    }
  },
  computed: {
    loggedUser() {
      return this.$store.getters['usuario/loggedUser'];
    },

    avatarText() {
      if (!this.loggedUser) return "";
      
      const n = this.loggedUser.nome[0].toUpperCase();
      const s = this.loggedUser.sobrenome[0].toUpperCase();
      return n + s;
    },

    isOutPage() {
      return this.out_pages.includes(this.$route.name)
    }
  },
  async mounted() {
    if(process.env.NODE_ENV !== 'development')
      await markerSDK.loadWidget({ destination: '632e0792d78dcc3730b6c1b6' });
    this.$root.vtoast = this.$refs.vtoast;
  },
  methods: {
    async logout() {
      await this.$store.dispatch("usuario/logout")
      await this.$router.push('/login')
    }
  }
};
</script>
<style>
* {
    font-family: Manrope;
}
.h1, h2, h3 {
    font-family: TenorSans
}
</style>
