import axios from "@/helpers/axios.js";

// Métodos do axios
function get(url, params) {
  return axios.get(url, { params: params });
}

function post(url, data) {
  return axios.post(url, data);
}

function put(url, data) {
  return axios.put(url, data);
}

function del(url, data) { // delete is a reserved word
    return axios.delete(url, {'data': data})
}

function paginate(url, page) {
  return url + "?page=" + page;
}

function filter(url, filters) {
  let filteredURL = url;

  filters.forEach((filter, index) => {
    if (index == 0 && !filteredURL.includes("?")) filteredURL += "?";
    else filteredURL += "&";

    filteredURL += `${filter[0]}=${filter[1]}`;
  });

  return filteredURL;
}

async function postFormData(url, formData) {
  return await axios.post(url, formData, {
    headers: { "Content-Type": "multipart/form-data" },
  });
}

function convertToFormData(object) {
  const formData = new FormData();

  Object.keys(object).forEach((key) => {
    let value = object[key];
    if (value != null) formData.append(key, value);
  });

  return formData;
}

async function postDataBuscaAPIContato(contato_id, cpf) {
  if (!cpf) return;

  return await axios({
    method: "post",
    url: `/api/contatos/${contato_id}/databusca/`,
    data: {
      Document: cpf,
      DocumentType: "CPF",
    },
  });
}

async function postDataBuscaAPIEmpresa(empresa_id, cnpj) {
  if (!cnpj) return;

  return await axios({
    method: "post",
    url: `/api/empresas/${empresa_id}/databusca/`,
    data: {
      Document: cnpj,
      DocumentType: "CNPJ",
    },
  });
}

export {
  get,
  post,
  put,
  del,
  paginate,
  filter,
  postDataBuscaAPIContato,
  postDataBuscaAPIEmpresa,
  convertToFormData,
  postFormData,
};
