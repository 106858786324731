<template>
  <v-container>
    <v-row>Origem: {{ origem(subcotacao) }}</v-row>
    <v-row>Partida: {{ dataPartida(subcotacao) | dataHoraBR }}</v-row>

    <v-row>Destino: {{ origem(subcotacao) }}</v-row>
    <v-row>Chegada: {{ dataChegada(subcotacao) | dataHoraBR }}</v-row>

    <v-row>Tipo de passagem: {{ subcotacao.tipo_cotacao }}</v-row>

    <v-row v-if="subcotacao.tipo_cotacao === 'milhas'"
      >Quantidade de Milhas: {{ subcotacao.quantidade_milhas }}</v-row
    >
    <v-row v-if="subcotacao.tipo_cotacao === 'milhas'"
      >Milheiro Efetivo: {{ subcotacao.milheiro_efetivo }}</v-row
    >

    <v-row v-if="['pagante', 'wallet'].includes(subcotacao.tipo_cotacao)"
      >Custo Bilhete:
      {{ formatValorFinanceiro(subcotacao.custo_bilhete) }}</v-row
    >

    <v-row>RAV via fornecedor: {{ formatValorFinanceiro(subcotacao.rav) }}</v-row>
    <v-row
      >Custo Bagagem:
      {{ formatValorFinanceiro(subcotacao.custo_taxa_bagagem) }}</v-row
    >
    <v-row
      >Custo Taxas Extras:
      {{ formatValorFinanceiro(subcotacao.custo_taxas_extras) }}</v-row
    >
  </v-container>
</template>
<script>
import { formatValorFinanceiro } from "@/helpers/financeiro";
import {
  origem,
  destino,
  dataPartida,
  dataChegada,
} from "@/helpers/subcotacao";

export default {
  props: {
    subcotacao: {
      type: Object,
      default: () => {},
    },
  },

  methods: {
    formatValorFinanceiro(valor) {
      return formatValorFinanceiro(valor);
    },

    origem(subcotacao) {
      return origem(subcotacao);
    },

    dataPartida(subcotacao) {
      return dataPartida(subcotacao);
    },

    destino(subcotacao) {
      return destino(subcotacao);
    },

    dataChegada(subcotacao) {
      return dataChegada(subcotacao);
    },
  },
};
</script>
