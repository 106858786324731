<template >
  <v-container class="ma-4" fluid>
    <v-row>
      <v-col>
        <div class="text-h5 transition-swing">
          Logs
        </div>
      <v-breadcrumbs :items="breadcumb_items"></v-breadcrumbs>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <log-table
          :logs="logs"
          :numPages="numPages"
          :loading="loading"
          :headers="headers"
          @getLogs="getLogs"
        ></log-table>
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
import LogTable from '@/components/Logs/LogTable.vue'
import api from "@/api/api"

export default {
  components: {LogTable},

  data: () => ({
    loading: false,
    logs: [],
    numPages: 1,
    headers: [
      {text: "ID", value: "id"},
      {text: "Tipo", value: "tipo"},
      {text: "Venda ID", value: "venda"},
      {text: "Usuário", value: "usuario"},
      {text: "Data", value: "created_at"},
      {text: "Ações", value: "actions"}
    ],
    breadcumb_items: [
      {
        text: 'Home',
        to: {name: "home"},
        exact: true
      },
      {
        text: 'Logs',
        to: {name: "logs"}
      }
    ],
  }),

  async mounted(){
    await this.initialize()
  },

  methods: {
    async initialize(){
      this.loading = true
      this.getLogs()
    },

    getLogs(page=1, filters=[]) {
      api.getLogs(page, filters).then(
        res => {
          this.logs = res.data.logs
          this.numPages = res.data.pages
          this.loading = false
        }
      )
    }
  }
    
}
</script>
<style >
.link-painel{
    margin: auto 0 auto 0;
}
</style>