export const TiposServicos = [
{key: 'aereo', value: 'Aéreo', icon: '✈️'},
{key: 'guia_turistico', value: 'Guia Turístico', icon: '🎟️'},
{key: 'hospedagem', value: 'Hospedagem', icon: '🏨'},
{key: 'ingressos', value: 'Ingressos', icon: '🛳️'},
{key: 'locadora_de_carro', value: 'Locadora de Carro', icon: '🚗'},
{key: 'milhas', value: 'Milhas', icon: '🛩️'},
{key: 'passeio_aereo', value: 'Passeio Aéreo', icon: ' 🚁'},
{key: 'passeio_aquatico', value: 'Passeio Aquático', icon: '⛵'},
{key: 'passeio_terrestre', value: 'Passeio Terrestre', icon: ' 🚌'},
{key: 'seguro_viagem', value: 'Seguro Viagem', icon: '🧳'},
{key: 'servicos_aeroporto', value: 'Serviços no Aeroporto', icon: ' 🛫'},
{key: 'translado_aquatico', value: 'Translado Aquático', icon: ' 🚤'},
{key: 'translado_terrestre', value: 'Translado Terrestre', icon: ' 🚕'},
]

export const TiposServicosOutros = [
   {key: 'guia_turistico', value: 'Guia Turístico', icon: '🎟️'},
   {key: 'ingressos', value: 'Ingressos', icon: '🛳️'},
   {key: 'passeio_aereo', value: 'Passeio Aéreo', icon: ' 🚁'},
   {key: 'passeio_aquatico', value: 'Passeio Aquático', icon: '⛵'},
   {key: 'passeio_terrestre', value: 'Passeio Terrestre', icon: ' 🚌'},
   {key: 'seguro_viagem', value: 'Seguro Viagem', icon: '🧳'},
   {key: 'servicos_aeroporto', value: 'Serviços no Aeroporto', icon: ' 🛫'},
   {key: 'translado_aquatico', value: 'Translado Aquático', icon: ' 🚤'},
   {key: 'translado_terrestre', value: 'Translado Terrestre', icon: ' 🚕'},
   ]