<template>
  <v-container>
    <!-- Tabela -->
    <v-card tile class="mx-auto">
      <v-list-item two-line>
        <v-list-item-content>
          <v-list-item-title>
            <v-icon> mdi-account </v-icon>
            Último consultor
          </v-list-item-title>
          <v-list-item-subtitle>
            {{ ultimo_consultor }}
          </v-list-item-subtitle>
        </v-list-item-content>
      </v-list-item>
      <v-list-item>
        <v-list-item-content>
          <v-list-item-title class="text-h6">
            <v-row>
              <v-col> Vendas relacionadas: </v-col>
              <v-col class="d-flex">
                <v-btn
                  class="ml-auto"
                  @click="showDialogExportarPdf = true"
                  color="secondary"
                >
                  <v-icon>mdi-arrow-collapse-down</v-icon>
                  Gerar Fatura
                </v-btn>
              </v-col>
            </v-row>
          </v-list-item-title>
        </v-list-item-content>
      </v-list-item>

      <v-data-table
        dense
        :headers="headers"
        :items="vendas"
        sort-by="id"
        sort-desc
        show-select
        v-model="selectedVendas"
        item-key="id"
      >
        <template v-slot:item.actions="{ item }">
          <v-btn
            icon
            color="transparent"
            :to="{ name: 'venda-info', params: { id: item.id } }"
          >
            <v-icon small color="blue" class="mr-2"
              >mdi-open-in-new
            </v-icon>
          </v-btn>
        </template>

        <template v-slot:item.consultor="{ item }">
          <div v-if="item.consultor">
            {{ item.consultor.nome + " " + item.consultor.sobrenome }}
          </div>
        </template>
        <template v-slot:item.valorTotal="{ item }">
          {{ valorTotal[item.id] | dinheiroReal }}
        </template>
        <template v-slot:item.valor_aberto="{ item }">
          {{ valorAbertoVendas[item.id] | dinheiroReal }}
        </template>
        <template v-slot:item.indicacao="{ item }">
          <div v-if="item.indicacao">
            {{ item.indicacao.nome + " " + item.indicacao.sobrenome }}
          </div>
        </template>

        <template v-slot:no-data> Nenhuma venda encontrada </template>
      </v-data-table>
    </v-card>
    <!-- Dialogs -->
    <div>
      <v-dialog v-model="showDialogExportarPdf" v-if="showDialogExportarPdf">
        <preview-new-pdf-venda
          :exportPdfData="exportPdf"
          :vendas="selectedVendas"
          :servicos="servicos"
          :valorTotal="valorTotal"
          :valorAbertoCotacoes="valorAbertoCotacoes"
          :valorAbertoVendas="valorAbertoVendas"
          :beneficiarios="beneficiarios"
        ></preview-new-pdf-venda>
      </v-dialog>
    </div>
  </v-container>
</template>

<script>
import api from "@/api/api";
import { converteValorFinanceiroBRL } from "@/helpers/financeiro";
import CotacaoMixin from "@/mixins/CotacaoMixin";
import PreviewNewPdfVenda from "@/components/PDF/PreviewNewPdfVenda";

export default {
  mixins: [CotacaoMixin],
  props: {
    empresa: {
      type: Object,
      default: () => {},
    },
  },
  components: {
    PreviewNewPdfVenda,
  },
  data() {
    return {
      dialog: false,
      dialogDelete: false,
      filter: {
        status: "",
      },
      exportPdf: null,
      selectedVendas: [],
      showDialogExportarPdf: false,
      search: "",
      headers: [
        { text: "Status", value: "status" },
        { text: "Indicação", value: "indicacao" },
        { text: "Consultor", value: "consultor" },
        { text: "Valor total", value: "valorTotal" },
        { text: "Valor em aberto", value: "valor_aberto" },
        { text: "Ações", value: "actions", width: 100 },
      ],
      valor_aberto: {},
      vendas: [],
      servicos: [],
      pagamentos: [],
      ultima_venda: "",
      ultimo_consultor: "",
      beneficiarios: [],
    };
  },
  mounted() {
    this.initialize();
  },
  created() {
    this.pegarDados();
    this.getPagamentosEmpresa();
  },

  methods: {
    initialize() {
      this.exportPdf = {
        ...this.exportPdf,
        show: this.showDialogExportarPdf,
      };
    },
    pegarDados() {
      const empresa_id = this.$route.params.id;
      api.getEmpresaVendas(empresa_id).then((response) => {
        this.vendas = response.data;
        this.ultima_venda = response.data[response.data.length - 1]?.created_at;
        this.ultimo_consultor =
          response.data[response.data.length - 1]?.consultor.nome +
          " " +
          response.data[response.data.length - 1]?.consultor.sobrenome;
      });
      api.getEmpresaBeneficiarios(empresa_id).then((response) => {
        this.beneficiarios = response.data;
      });
      api.getEmpresaServicos(empresa_id).then((response) => {
        response.data.forEach((servico) => {
          if (!this.servicos.find((item) => item.id_venda === servico.venda)) {
            const item = { servicos: [servico], id_venda: servico.venda };
            this.servicos.push(item);
            this.valorTotalVenda(servico.venda);
          } else {
            this.servicos
              .find((item) => item.id_venda === servico.venda)
              .servicos.push(servico);
          }
        });
        return response.data;
      });
    },
    getPagamentosEmpresa() {
      const empresa_id = this.$route.params.id;
      api.getEmpresaCotacoesPago(empresa_id).then((response) => {
        this.pagamentos = response.data;
        return response.data;
      });
    },

    valorTotalVenda(idVenda) {
      let idVendaFiltrado = idVenda;
      if(typeof(idVenda)==='object'){
         idVendaFiltrado = idVenda.id     
      }
      let valorTotal = 0;
      this.servicos.forEach((item) => {
        if (item.id_venda.id === idVendaFiltrado) {
          valorTotal = valorTotal + item.servicos.reduce(
            (total, value) => total + this.servicoValorTotal(value),
            0
          );
          return valorTotal;
        }
      });
      return valorTotal;
    },
  },
  computed: {
    valorTotal() {
      return Object.fromEntries(
        this.vendas.map((venda) => [venda.id, this.valorTotalVenda(venda.id)])
      );
    },
    valorAbertoVendas() {
      return Object.fromEntries(
        this.vendas.map((venda) => [
          venda.id,
          this.servicosLivres
            .filter((servico) => servico.cotacao.orcamento.venda === venda.id)
            .reduce(
              (total, servico) =>
                (total += this.valorAbertoCotacoes[servico.cotacao.id]),
              0
            ),
        ])
      );
    },
    valorAbertoCotacoes() {
      return Object.fromEntries(
        this.servicosLivres.map((servico) => [
          servico.cotacao.id,
          this.servicoValorTotal(servico) -
            this.pagamentos
              .filter((pagamento) => pagamento.cotacao === servico.cotacao.id && pagamento.status === 'concluido')
              .reduce(
                (sum, pagamento) =>
                  (sum += converteValorFinanceiroBRL(pagamento)),
                0
              ),
        ])
      );
    },
    servicosLivres() {
      const servicosVenda = this.servicos
        .map((cotacao) => cotacao.servicos)
        .flat();
      const servicosDistintos = servicosVenda.filter(
        (v, i, a) => a.findIndex((v2) => v2.cotacao.id === v.cotacao.id) === i
      );
      return servicosDistintos;
    },
  },
};
</script>