<template>
    <div>
        <v-card>
            
                <v-card-title>
                    <span class="text-h5"> Nova Cotação via PNR</span>
                    <span> 
                        <v-icon size="20"
                        color="primary"
                        class="pl-5 pb-1"
                        @click="showExampleDialog = true">
                        mdi-help-circle
                        </v-icon>
                    </span>
                </v-card-title>
            <v-card-text>
                <v-row>
                    <v-col>
                        Escolha o formato da sua cotação abaixo:
                    <v-radio-group v-model="qtdTrechos">
                        <v-radio label="Apenas um trecho" :value="1"></v-radio>
                        <v-radio label="Ida e Volta (2 trechos)" :value="2"></v-radio>
                        <v-radio label="Multitrecho (3 trechos ou mais)" :value="3"></v-radio>
                    </v-radio-group>
                </v-col>
                </v-row>
                <v-row v-if="qtdTrechos > 0" v-for="idx in qtdTrechos" :key="idx">
                    <v-col>
                        <v-textarea label="Insira aqui os PNR da cotação, um por linha! " v-model="pnr[idx]" :rules="[
                                (v) =>
                                  (v || '').length <= 10000 ||
                                  'Descrições devem ter menos de 1000 caracteres',
                              ]">
                        </v-textarea>
                    </v-col>
                  </v-row>
                  <v-row v-if="qtdTrechos >= 3">
                    <v-col cols="12" class="text-center">
                      <v-btn
                          color="primary"
                          @click="addTrecho"
                      >
                        <v-icon
                            small
                            color="white">
                          mdi-plus
                        </v-icon>
                        Adicionar trecho
                      </v-btn>
                      <v-btn
                          color="error"
                          @click="removeTrecho"
                      >
                        <v-icon
                            small
                            color="white">
                          mdi-plus
                        </v-icon>
                        Remover trecho
                      </v-btn>
                    </v-col>
                  </v-row>
            </v-card-text>
            <v-card-actions class="ma-0">
                <v-spacer></v-spacer>
                <v-btn text @click="cancel">Cancelar</v-btn>
                <v-btn
                    color="primary"
                    :disabled="qtdTrechos === 0"
                    :loading="false"
                    @click="createCotacaoPnr"
                    >Criar
                </v-btn
        >
            </v-card-actions>
        </v-card>

        <v-dialog v-model="showExampleDialog" :persistent="true" max-width="500">
            <v-card>
              <v-card-title>
                <span class="text-h6">Exemplo de preenchimento do formulário</span>
              </v-card-title>
              <v-card-text>
                <div>
                  <p>Passo 1: Escolha quantos trechos sua cotação terá.</p>
                  <p>Passo 2: Em cada trecho, insira os PNRs de cada voo (perna), um por linha, separados por vírgulas.</p>
                  <p>Passo 3: Clique em "Criar" para criar a cotação.</p>
                  <p> Exemplo genérico para 2 trechos:</p>
                  <p> Trecho 1 :  <br/>
                     NZ456 Y 26OCT WLGAKL 1945 2050 26OCT, <br/>
                     NZ456 Y 26OCT WLGAKL 1945 2050 26OCT 
                  </p>
                  <p>
                    Trecho 2: <br/>
                     NZ456 Y 26OCT WLGAKL 1945 2050 26OCT, <br/>
                     NZ456 Y 26OCT WLGAKL 1945 2050 26OCT 
                  </p>

                  <p>
                    O formato do PNR deve seguir a estrutura: <br/>
                    [Número do voo] [Classe do voo (o padrão é Y)] [Data partida] [IATA partida] [IATA chegada] [Horário Partida] [Horário Chegada] [Data Chegada]
                  </p>
                </div>
              </v-card-text>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="primary" text @click="showExampleDialog = false">Fechar</v-btn>
              </v-card-actions>
            </v-card>
        </v-dialog>
    </div>
</template>

<script>
import api from "@/api/api"
import cotacaoPnrMixin from "@/mixins/CotacaoPnrMixin";

export default {
    mixins: [cotacaoPnrMixin],
    components: {

    },
  
    props: {
        orcamento: {
            type: Object,
            required: true
        },
        showDialogNovaCotacaoPNR: {
            type: Boolean, 
            required: true
        },
        cancel: {
            type: Function,
            required: true,
        }
    },

    data() {
        return {
            showExampleDialog: false,
            qtdTrechos: 0,
            pnr: {},
            pnrConvertido: {},
            formCotacaoPnr: {},
        };
    },
   
    computed: {

    },

    async mounted() {

        const resCidades = await this.$store.dispatch('global/fetchCidades').catch(err => {
            this.$root.vtoast.show({message: err.message, type: 'error'});
        });

        const resCias = await this.$store.dispatch('global/fetchCiaAereas').catch(err => {
            this.$root.vtoast.show({message: err.message, type: 'error'});
        });

        const res = await this.$store
          .dispatch("global/fetchAeroportos")
          .catch((err) => {
            this.$root.vtoast.show({
              message: "Erro ao carregar aeroportos",
              color: "error",
            });
            this.$root.vtoast.show({ message: err.message, color: "error" });
          });
    },

    watch: {
        qtdTrechos() {
            // Limpando o pnr caso a pessoa decida por outra qtdTrechos
            this.clearPnr();
        }
    },
    
    methods: {
        clearPnr(){
            this.pnr = {};
        },

        verificaPnrVazio(){
            // verifica se todas as caixas de texto contém algo.
            // Caso alguma não contenha, retorna true. 
            for(let i = 1; i <= this.qtdTrechos; i++){
                if(!this.pnr.hasOwnProperty(i)) {
                    return true;
                } 
            }
            return false;
        },

        removeTrecho(){
            this.qtdTrechos -= 1;
        },

        addTrecho(){
            this.qtdTrechos += 1;
        },

        async converterPnr(){
            
            let pnrFinal = '';
            let distribuicaoVoos = {}
            for( const [key,value] of Object.entries(this.pnr)){
                distribuicaoVoos[key] = value.trim().split("\n").length; 
                pnrFinal = pnrFinal + "\n" + value;
            }

            await api.convertPnr({
                "pnr": pnrFinal,
                "qtd_trechos": this.qtdTrechos,
                "distribuicao_voos": distribuicaoVoos
            }).then(response => {
                this.pnrConvertido = response.data;
            }).catch((e) => {
                if (e.response.status === 400){
                    this.$root.vtoast.show({
                    message: e.response.data, 
                    color: 'error',
                    icon: 'mdi-close'
                });
            }
            });


          this.formCotacaoPnr = this.convertPnr2CotacaoVooPnr(this.pnrConvertido, this.orcamento);

          // Resetando o pnr preenchido
          this.pnr = {};
        },

        async createCotacaoPnr(){
            //Caso tudo esteja preenchido, chama a request
            if(!this.verificaPnrVazio()) {
                await this.converterPnr();    
            }
            else {
                this.$root.vtoast.show({
                    message: "Preencha todos os trechos!", 
                    color: "error", 
                    icon:'mdi-close'
                });
            }

            this.$emit("create", this.formCotacaoPnr);
            this.formCotacaoPnr = {};
        },

        
    }
}
</script>

