<template>
  <v-container>
    <v-dialog width="500" v-model="anexosDialog" persistent>
      <template v-slot:activator="{ on, attrs }">
        <v-btn :disabled="emissaoNaFila" color="primary" v-bind="attrs" v-on="on">
          <v-icon left>mdi-file-plus</v-icon>
          Anexar Arquivo
        </v-btn>
      </template>
      <v-card>
        <v-card-title>Anexar Arquivo</v-card-title>
        <v-card-subtitle> Os campos com * são obrigatorios </v-card-subtitle>

        <v-card-text>
          <v-form id="anexo">
            <v-text-field
              v-model="novoAnexoNome"
              label="Nome *"
              prepend-icon="mdi-pencil"
            />

            <v-file-input
              v-model="novoAnexoArquivo"
              label="Selecione o arquivo *"
              placeholder="Selecione o arquivo *"
              prepend-icon="mdi-file-upload"
              color="primary"
              required
            />
          </v-form>
        </v-card-text>
        <v-divider />
        <v-card-actions>
          <v-btn color="secondary" @click="fecharForm" outlined>
            Cancelar
          </v-btn>
          <v-spacer />
          <v-btn color="success" @click="salvarAnexo" :loading="loading">
            Salvar
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-list subheader two-line v-if="documentos.length > 0">
      <v-subheader inset> Anexos </v-subheader>
      <v-list-item v-for="doc in documentos" :key="doc.id">
        <v-list-item-avatar>
          <v-icon class="blue lighten-1" dark> mdi-file </v-icon>
        </v-list-item-avatar>
        <v-list-item-content>
          <v-list-item-title v-text="doc.nome" />
          <v-list-item-subtitle v-text="doc.documento" />
          <v-list-item-subtitle v-if="doc.created_by" v-text="'Anexado por '+ doc.created_by.nome + ' ' + doc.created_by.sobrenome" />
          <v-field-value v-if="doc.created_at" class="v-list-item__subtitle">
            em {{ doc.created_at | dataHoraBR }}
          </v-field-value>
        </v-list-item-content>
        <v-list-item-action>
          <v-btn icon @click="abrirDocumento(doc.documento)">
            <v-icon color="blue lighten-1">mdi-eye</v-icon>
          </v-btn>
          <v-btn icon @click="deletarDocumento(doc.emissao_id, doc.id)" :disabled="emissao.status !== 'fila_de_emissao'">
            <v-icon color="red lighten-1">mdi-delete</v-icon>
          </v-btn>
        </v-list-item-action>
      </v-list-item>
    </v-list>
    <p v-else>Nenhum documento anexado</p>
  </v-container>
</template>
<script>
import api from "@/api/api";

export default {
  props: {
    emissao: {
      type: Object,
      default: {}
    }
  },

  data() {
    return {
      anexosDialog: false,
      novoAnexoArquivo: null,
      novoAnexoNome: "",
      documentos: [],
      loading: false,
    };
  },

  created() {
    this.getDocumentos();
  },


  computed: {
    emissaoNaFila () {
      return this.emissao.status === 'fila_de_emissao' ? false : true;
    },
    dataAnexo() {
      return 'Anexado por ' + this.doc.created_by.nome + ' ' + this.doc.created_by.sobrenome + ' em ' + this.doc.created_at | formatarData;
  }
  },

  methods: {    

    async salvarAnexo() {
      const emissaoId = this.emissao?.id;
      const nome = this.novoAnexoNome;
      const arquivo = this.novoAnexoArquivo;
      if (nome.trim() == "")
        return this.$root.vtoast.show({
          message: 'Preencha o campo "nome"',
          color: "error",
          icon: "mdi-close",
        });
      if (!arquivo)
        return this.$root.vtoast.show({
          message: 'Campo "documento" não pode ser vazio',
          color: "error",
          icon: "mdi-close",
        });
      this.loading = true;
      const response = await api.postDocumentoEmissao(emissaoId, {
        nome,
        arquivo,
      });
      this.loading = false;
      this.anexosDialog = false;
      this.documentos.push(response.data);
    },

    async deletarDocumento(emissaoId, doc_id) {
      if (confirm("Tem certeza que deseja deletar esse documento?")) {
        await api.deleteDocumentoEmissao(emissaoId, { id: doc_id });
        this.documentos = this.documentos.filter((doc) => doc.id != doc_id);
      }
    },

    async getDocumentos() {
      let emissaoId = this.$route.params.id;
      let response = await api.getDocumentosEmissao(emissaoId);
      this.documentos = response.data;
    },

    fecharForm() {
      if (this.novoAnexoArquivo || this.novoAnexoNome.length > 0) {
        let cancelar = confirm(
          "Certeza que deseja cancelar o envio desse anexo?"
        );
        if (!cancelar) return;
      }
      this.anexosDialog = false;
    },

    abrirDocumento(url) {
      window.open(url);
    },
  },
};
</script>
