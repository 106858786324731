<template >
  <v-container>
    <v-col align-self="end" cols="12" md="auto">
      <v-btn @click="dialogPlanilha = true" color="primary" class="mb-2">
        Planilha Fatura
      </v-btn>
    </v-col>
    <servicos-pagamento-list
      @select="select"
      @initialize="initialize"
      @refreshPage="refreshPage"
      :loading="loadingPagamento"
      :servicos="servicosPagamento"
      :num-pages="numPages"
    />
    <servicos-validacao-list
      @initialize="initialize"
      @atualizar="atualizar"
      :loading="loadingValidacao"
      :pages="pages"
      :servicos="servicosValidacao"
    />
    <v-dialog v-model="dialog" scrollable>
      <v-card>
        <beneficiarios-resumo :beneficiarios="selectedBeneficiarios" />
        <v-card-actions>
          <v-btn color="blue darken-1" text @click="dialog = false">
            Fechar
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="dialogPlanilha" persistent max-width="600px">
      <v-card class="pa-3">
        <v-form id="docs-form">
          <v-card>
            <v-card-title>
              <span class="text-h5"> Adicionar Panilha</span>
            </v-card-title>
            <v-card-text>
              <v-form id="new-Planilha" ref="form-planilha">
                <v-text-field
                  name="id_servico"
                  label="Id do Serviço"
                  outlined
                  prepend-inner-icon="mdi-pencil"
                />
                <v-file-input
                  name="planilha"
                  label="Planilha"
                  prepend-icon=""
                  v-model="loadedFilePlanilha"
                  accept=".xlsx"
                  prepend-inner-icon="mdi-paperclip"
                  outlined
                />
              </v-form>
            </v-card-text>
            <v-card-actions>
              <v-btn
                color="success"
                @click="savePlanilha()"
                :loading="loadingAdicionarPlanilha"
              >
                Adicionar
              </v-btn>

              <v-btn color="secondary" @click="dialogPlanilha = false">
                Cancelar
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-form>
      </v-card>
    </v-dialog>
  </v-container>
</template>
<script>
import api from "@/api/api";
import cotacaoMixin from "@/mixins/CotacaoMixin";
import ServicosPagamentoList from "@/components/PagamentoAutorizacao/ServicosPagamentoList.vue";
import ServicosValidacaoList from "@/components/PagamentoAutorizacao/ServicosValidacaoList.vue";
import BeneficiariosResumo from "@/components/widgets/BeneficiariosResumo.vue";
import { getAllFields } from "@/helpers/forms";

export default {
  components: {
    ServicosPagamentoList,
    ServicosValidacaoList,
    BeneficiariosResumo,
  },
  mixins: [cotacaoMixin],

  data: () => ({
    dialog: false,
    selectedBeneficiarios: [],
    selectedServicoPagamento: null,
    servicosPagamento: null,
    servicosValidacao: null,
    loadingPagamento: true,
    loadingValidacao: true,
    pages: 6,
    numPages: 1,
    dialogPlanilha: false,
    loadingAdicionarPlanilha: false,
    loadedFilePlanilha: null,
    docPlanilha: [],
  }),
  computed: {},
  async mounted() {
    await this.initialize();
  },
  methods: {
    select(id) {
      this.selectedServicoPagamento = this.servicosPagamento.find(
        (servico) => servico.id === id
      );
      this.selectedBeneficiarios = this.selectedServicoPagamento.beneficiarios;
      this.dialog = true;
    },

    async initialize() {
      this.loadingPagamento = true;
      this.loadingValidacao = true;
      api
        .getServicoPagamento(1)
        .then((response) => {
          this.servicosPagamento = response.data.servicos;
          this.numPages = response.data.pages;
        })
        .catch((err) => {
          console.error(err);
          this.$root.vtoast.show({
            message: "Serviços não encontrados",
            color: "error",
            icon: "mdi-close",
          });
        })
        .finally(() => (this.loadingPagamento = false));
      api
        .getServicosValidacaoEmissao(1)
        .then((response) => {
          this.servicosValidacao = response.data.servicos;
          this.pages = response.data.pages;
        })
        .catch((err) => {
          console.error(err);
          this.$root.vtoast.show({
            message: "Serviços não encontrados",
            color: "error",
            icon: "mdi-close",
          });
        })
        .finally(() => (this.loadingValidacao = false));
    },
    async atualizar(page = 1) {
      this.loadingValidacao = true;
      api
        .getServicosValidacaoEmissao(page)
        .then((res) => {
          this.servicosValidacao = res.data.servicos;
        })
        .catch((err) => {
          console.error(err);
          this.$root.vtoast.show({
            message: "Serviços não encontrados",
            color: "error",
            icon: "mdi-close",
          });
        })
        .finally(() => (this.loadingValidacao = false));
    },
    async refreshPage(page = 1) {
      this.loadingPagamento = true;
      api
        .getServicoPagamento(page)
        .then((res) => {
          this.servicosPagamento = res.data.servicos;
        })
        .catch((err) => {
          console.error(err);
          this.$root.vtoast.show({
            message: "Serviços não encontrados",
            color: "error",
            icon: "mdi-close",
          });
        })
        .finally(() => (this.loadingPagamento = false));
    },
    close() {
      this.dialogSave = false;
    },

    //Planilha Fatura
    savePlanilha() {
      let data = getAllFields("new-Planilha");
      data.planilha = this.loadedFilePlanilha;
      this.loadingAdicionarPlanilha = true;
      api
        .postPdfFatura(data)
        .then((response) => {
          this.docPlanilha.push(response.data);
          this.dialogPlanilha = false;
          this.loadingAdicionarPlanilha = false;
          this.$refs["form-planilha"].reset();
          this.loadedFilePlanilha = null;
        })
        .catch((e) => {
          this.loadingAdicionarPlanilha = false;
          this.dialogPlanilha = false;
          this.$root.vtoast.show({
            message: "Erro ao adicionar Planilha",
            color: "error",
            icon: "mdi-close",
          });
          console.error(e);
        });
    },
  },
};
</script>