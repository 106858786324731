import { get, put, post, paginate, filter } from '../modules'

export default {
    getVendas() {
        return get("/api/vendas");
    },

    getVendasDetalhado(page=1, filters=[]) {
        const paginatedURL = paginate("/api/vendas/detailed/", page)
        const filteredURL = filter(paginatedURL, filters)

        return get(filteredURL);
    },

    getVenda(id) {
        return get(`/api/vendas/${id}/`);
    },

    updateVenda(id, venda) {
        return put(`/api/vendas/${id}/`, venda);
    },
    cancelarVenda(id, venda) {
        return put(`/api/vendas/${id}/cancelar/`, venda);
    },
    concluirVenda(id, venda) {
        return put(`/api/vendas/${id}/concluir/`, venda);
    },

    getVendaOrcamentos(vendaId) {
        return get(`/api/vendas/${vendaId}/orcamentos/`);
    },

    createVenda(item) {
        return post("/api/vendas/", item);
    },

    createVendaOrcamentos(id, orcamentos) {
        return post(`/api/vendas/${id}/orcamentos/`, orcamentos);
    },

    getVendaCotacoes(vendaId) {
        return get(`/api/vendas/${vendaId}/cotacoes/`);
    },

    getFatura(vendaId) {
        return get(`/api/vendas/${vendaId}/fatura/`);
    },

    getValidacao(vendaId){
        return get(`/api/vendas/${vendaId}/validacao/`)
    },
    
    getServicosVenda(vendaId){
        return get(`/api/vendas/${vendaId}/servicos/`)
    },
    getRACSVenda(vendaId){
        return get(`/api/vendas/${vendaId}/racs/`)
    },
}
