<template>
  <v-container fluid>
    <v-row class="ma-0">
      <v-card outlined width="100%" rounded elevation="2">
        <v-row class="ma-1">
          <v-col>
            <v-row>
              <v-col>
                <v-field-value label="Tipo de serviço">Aluguel de Carro</v-field-value>
              </v-col>
            </v-row>
            <v-row class="ma-2">
              <novo-orcamento-carro-form @input="input" v-model="orcamento.orcamento_carro"></novo-orcamento-carro-form>
            </v-row>
          </v-col>
        </v-row>

      </v-card>
    </v-row>
  </v-container>
</template>

<script>

import SelectTrechosVoo from "@/components/Orcamento/Widgets/SelectTrechosVoo";
import VFieldValue from "@/components/widgets/VFieldValue";
import NovoOrcamentoCarroForm from "@/components/Orcamento/Tipos/Carro/NovoOrcamentoCarroForm";

export default {
  components: {NovoOrcamentoCarroForm, VFieldValue, SelectTrechosVoo},
  props: {
    value: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      orcamento: null
    }
  },
  created() {
    this.orcamento = this.value ? {...this.value} : {tipo: "orcamento_carro", orcamento_carro: {}}
  },
  methods: {
    input() {
      this.$emit('input', this.orcamento)
    }
  }
}

</script>
