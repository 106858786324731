<template>
  <v-container>
    <v-row>Cotação de Hotel</v-row>
    <v-row>Nome do hotel: {{ cotacao.nome_hotel }}</v-row>
    <v-row
      >Checkin: {{ cotacao.checkin_inicio }} -
      {{ cotacao.checkin_fim || "" }}</v-row
    >
    <v-row
      >Checkout: {{ cotacao.checkout_inicio }} -
      {{ cotacao.checkout_fim || "" }}</v-row
    >
    <v-row>Adultos: {{ cotacao.adultos }}</v-row>
    <v-row>Crianças: {{ cotacao.criancas }}</v-row>
    <v-row>Diárias: {{ cotacao.diarias }}</v-row>
    <v-row>Café da Manhã: {{ cotacao.cafe }}</v-row>
    <v-row
      >Custo da diária: {{ formatValorFinanceiro(cotacao.custo_diaria) }}</v-row
    >
    <v-row
      >Custos extras: {{ formatValorFinanceiro(cotacao.custo_extras) }}</v-row
    >
    <v-row>
      <v-btn link :to="orcamentoLinkTo">
        Ir para orcamento
        <v-icon color="primary" class="ml-2">mdi-open-in-new </v-icon>
      </v-btn>
    </v-row>
  </v-container>
</template>
<script>
import { formatValorFinanceiro } from "@/helpers/financeiro";

export default {
  props: {
    cotacao: {
      type: Object,
      default: () => {},
    },
    orcamentoLinkTo: {
      type: Object,
      default: () => {},
    },
  },

  methods: {
    formatValorFinanceiro(valor) {
      return formatValorFinanceiro(valor);
    },
  },
};
</script>
