<template>
  <v-card v-if="venda">
    <v-card-title>Venda vinculada</v-card-title>
    <v-card-text>
      <v-container fluid>
        <v-row cols="auto" align-self="center">
          <v-field-value label="Venda">
            <v-link-text-field
              :to="{ name: 'venda-orcamentos', params: { id: venda.id } }"
              text
              small
            >
              # {{ venda.id }}
            </v-link-text-field>
            <v-btn icon>
              <v-icon> mdi-content-copy </v-icon>
            </v-btn>
          </v-field-value>
        </v-row>
        <v-row cols="auto" align-self="center">
          <v-field-value label="Status">
            <v-chip
              small
              :color="venda.status === 'proposta' ? '#005B96' : '#4caf50'"
              :text-color="venda.status === 'proposta' ? 'white' : 'black'"              
              >{{ venda.status[0].toUpperCase() + venda.status.substring(1) }}</v-chip
            >
          </v-field-value>
        </v-row>
        <v-row cols="auto" align-self="center">
          <v-field-value label="Consultor">
            {{ venda.consultor | nomeSobrenome }}
          </v-field-value>
        </v-row>
        <v-row>
          <v-field-value label="Indicação">
            {{ venda.indicacao | nomeSobrenome }}
          </v-field-value>
        </v-row>
        <v-row cols="auto" align-self="center">
          <v-field-value v-if="venda.cliente" label="Cliente">
            <v-link-text-field
              :to="{ name: 'cliente', params: { id: venda.cliente.id } }"
            >
              {{ venda.cliente | nomeSobrenome }}
            </v-link-text-field>
          </v-field-value>
          <v-field-value v-if="venda.cliente_empresa" label="Empresa">
            {{ venda.cliente_empresa ? `${venda.cliente_empresa.nome}` : "" }}
            <v-btn
              small
              icon
              color="primary"
              :to="{
                name: 'empresa',
                params: { id: venda.cliente_empresa.id },
              }"
            >
              <v-icon> mdi-open-in-new </v-icon>
            </v-btn>
          </v-field-value>
        </v-row>
        <v-row cols="auto" align-self="center">
          <v-field-value :label="venda.meio_contato_tipo">
            <img
              :src="flag"
              :alt="code"
              width="20px"
              v-if="['Whatsapp', 'Telefone'].includes(venda.meio_contato_tipo)"
            />
            <copy-link-field>{{ venda.meio_contato_valor }}</copy-link-field>
          </v-field-value>
        </v-row>
        <v-row>
          <v-field-value label="Data de abertura">
            {{ venda.created_at | convertDataBR }} -
            {{ venda.created_at | diasAtras }} dia(s)
          </v-field-value>
        </v-row>
      </v-container>
    </v-card-text>
  </v-card>
  <div v-else>
    <v-alert color="error"> Erro ao carregar venda </v-alert>
  </div>
</template>
<script>
import VFieldValue from "@/components/widgets/VFieldValue";
import CopyLinkField from "@/components/widgets/CopyLinkField";
import VLinkTextField from "@/components/widgets/VLinkTextField";
import Phones from "@/constants/phones";

export default {
  components: { VLinkTextField, CopyLinkField, VFieldValue },
  props: {
    venda: {
      type: Object,
      default: null,
    },
  },
  data() {
    return {
      phones: Phones,
      flag: null,
      code: null,
    };
  },
  methods: {
    getPhoneFlag() {
      let code = this.venda.meio_contato_valor.split(" ")[0];
      let country = this.phones.find((phone) => phone.code == code);
      this.code = country.iso;
      this.flag = country.flag;
    },
  },
  created() {
    this.getPhoneFlag();
  },
};
</script>
