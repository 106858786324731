import Vue from "vue"
import {TiposServicos} from '@/constants/fornecedores'

const formatTipoServico = (key) => {
    if(!key) return ""
    const tipo = TiposServicos.find(e => e.key === key)
    if(!tipo) return ""
    return tipo.icon
} 

Vue.filter("formatTipoServico", formatTipoServico)