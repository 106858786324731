<template>
  <v-container fluid v-if="venda">
    <v-row>
      <v-col cols="6">
        <v-row>
          <v-col>
            <v-field-value label="Venda"
              >#{{ venda.id | formatVendaId }}</v-field-value
            >
          </v-col>
        </v-row>
       
        <v-row v-if="venda.status === 'fechada'">
          <v-col>
            <v-field-value label="Motivo do cancelamento">
              {{ venda.motivo_cancelamento }}
            </v-field-value>
          </v-col>
          <v-col>
            <v-field-value label="Ultima edição">
              {{ venda.last_edited_by | nomeSobrenome }}
            </v-field-value>
          </v-col>
        </v-row>
        <v-row>
          <v-col v-if="venda.cliente">
            <v-field-value label="Cliente">
              {{
                venda.cliente &&
                venda.cliente.nome + " " + venda.cliente.sobrenome
              }}
              <v-btn
                small
                icon
                color="primary"
                :to="{ name: 'cliente', params: { id: venda.cliente.id } }"
              >
                <v-icon> mdi-open-in-new </v-icon>
              </v-btn>
            </v-field-value>
          </v-col>
          <v-col v-if="venda.cliente_empresa">
            <v-field-value label="Empresa">
              {{ venda.cliente_empresa ? `${venda.cliente_empresa.nome}` : "" }}
              <v-btn
                small
                icon
                color="primary"
                :to="{
                  name: 'empresa',
                  params: { id: venda.cliente_empresa.id },
                }"
              >
                <v-icon> mdi-open-in-new </v-icon>
              </v-btn>
            </v-field-value>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="auto" align-self="center" v-if="venda.consultor">
            <v-field-value label="Consultor">
              {{ venda.consultor | nomeSobrenome }}
            </v-field-value>
          </v-col>
          <v-col cols="auto" align-self="center" v-if="venda.sdr">
            <v-field-value label="SDR">
              {{ venda.sdr | nomeSobrenome }}
            </v-field-value>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="auto" align-self="center" v-if="venda.created_by">
            <v-field-value label="Criado por">
              {{ venda.created_by | nomeSobrenome }}
            </v-field-value>
          </v-col>

          <v-col cols="auto" align-self="center" v-if="venda.concluida_por">
            <v-field-value label="Concluida por">
              {{ venda.concluida_por | nomeSobrenome }}
            </v-field-value>
          </v-col>

          <v-col cols="auto" align-self="center" v-if="venda.cancelado_por">
            <v-field-value label="Cancelado por">
              {{ venda.cancelado_por | nomeSobrenome }}
            </v-field-value>
          </v-col>
        </v-row>

        <v-row>
          <v-col cols="auto">
            <v-field-value label="Meio de contato">
              <copy-link-field :value="venda.meio_contato_valor">
                {{ venda.meio_contato_tipo }} - {{ venda.meio_contato_valor }}
              </copy-link-field>
            </v-field-value>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="auto">
            <v-field-value label="Data de abertura">
              {{ venda.created_at | convertDataBR }}
            </v-field-value>
          </v-col>
          <v-col cols="auto">
            <v-field-value label="Última modificação">
              {{ venda.updated_at | convertDataBR }} às
              {{ venda.updated_at | dataHoraMinuto }}
            </v-field-value>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="auto">
            <!-- <v-field-value label="Serviços orçados"> ... </v-field-value> -->
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12" md="6">
            <v-field-value label="Anotações">
              {{ venda.anotacoes }}
            </v-field-value>
          </v-col>
        </v-row>
      </v-col>
      <v-col cols="6" class="d-flex flex-column align-start  text-center ">
        <v-row>
          <v-col class="">
            <v-card outlined class="rounded-lg ">
              <v-card-title class="text-subtitle-1 primary white--text" style="border-bottom: 1px solid #000;">
                manual de boas práticas
              </v-card-title>
              <v-row class="mx-0 my-0">
                <v-col cols="6" class="px-0 py-0">
                  <v-card-text class="font-weight-black" style="border-bottom: 1px solid #000; border-right: 0.5px solid #000;">
                    serviços
                  </v-card-text>
                </v-col>
                <v-col cols="6" class="px-0 py-0">
                  <v-card-text class="font-weight-black" style="border-bottom: 1px solid #000;">
                    seguiu?
                  </v-card-text>
                </v-col>
              </v-row>
              <v-row class="mx-0 my-0">
                <v-col cols="6" class="px-0 py-0">
                  <v-card-text style="border-right: 0.5px solid #000;">
                    {{ qtdServicosMBP }}/{{ totalServicos }}
                  </v-card-text>
                </v-col>
                <v-col cols="6" class="px-0 py-0">
                  <v-card-text>
                    {{ totalServicos > 0 ? (seguiuMBP ? 'seguiu' : 'não seguiu') : '-' }}
                  </v-card-text>
                </v-col>
              </v-row>
            </v-card>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import VFieldValue from "@/components/widgets/VFieldValue";
import StatusVenda from "@/components/widgets/StatusVenda";
import CopyLinkField from "@/components/widgets/CopyLinkField";
import { mapState } from "vuex";

export default {
  components: { CopyLinkField, StatusVenda, VFieldValue },
  computed: {
    ...mapState({
      venda: (state) => state.venda.venda,
    }),
    servicos() {
      return this.$store.state.venda.servicos;
    },
    seguiuMBP() {
      return this.servicos.every((servico) => servico.seguiu_manual_boas_praticas === true && servico.seguiu_manual_boas_praticas_milhas === true)
    },
    qtdServicosMBP() {
      return this.servicos.filter((servico) => servico.seguiu_manual_boas_praticas).length;
    },
    totalServicos() {
      return this.servicos.length;
    },
  },
  async mounted() {
    await this.initialize()
  },
  methods: {
    async initialize() {
      await this.$store.dispatch("venda/fetchServicos");
    },
  },
};
</script>
