import Vue from "vue"

function localCidadeResumida(value){
    if (!value)
        return "";
    const cidade = value.cidade;
    if (!cidade)
        return "";
    return `${cidade.nome} - ${cidade.pais}`;
}


Vue.filter('localCidadeResumida', localCidadeResumida)
