export default [
    {
        key: 'proposta',
        value: 'Proposta',
        text: "white",
        background: "#005b96",
    },
    {
        key: 'fechada',
        value: 'Cancelada',
        text: "white",
        background: "red",
    },
    {
        key: 'concluida',
        value: 'Concluída',
        text: "white",
        background: "green",
    },
]