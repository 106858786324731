<!-- Anotações:
  - Não usar new Date, sempre criar dates usando moment, os Dates do JS são muito imprevisíveis
  - Eu criei a classe DateSkyler, não sei quão útil ela é e se vale apena a gente continuar com ela, mas as funções dentro dela são úteis
  - Os três forms tão entregando os mesmos horários, com a diferença que o multiple tá entregando em utc
-->

<!-- TODO: 
  - Precisa trocar os models de DateField para DateTimefield, recomendado não trocar tudo de uma vez
  - Trocar o máximo possível as funções que usam Date pra usar moment
  - DONE: função diasAtras em filters tá com problema
  - Verificar os helpers
-->

<template>
    <div class="container">
    <v-row>
        <v-datetime-text-field
          v-model="form.data1"
          label="DatetimeTextField"
        />
        
        <form-date-picker-multiple
          v-model="form.data2"
          outlined label="DatePickerMultiple"
        />
        
        <form-date-picker
          v-model="form.data3"
          outlined
          label="DatePicker"
        />
    </v-row>
    <v-row>
        filter {{ form.data1 | dataHoraBR}}<br/>
        data3 {{form.data3}} data3 formatted:{{  form.data3 |dataHoraBR }} <br/>

        {{ form.data2 }} <br/>
        format {{ form?.data1 }}
    </v-row>
</div>    

</template>
<script>
import FormDatePicker from "@/components/widgets/FormDatePicker.vue"
import FormDatePickerMultiple from "@/components/widgets/FormDatePickerMultiple"
import VDatetimeTextField from "@/components/widgets/VDatetimeTextField.vue"
import skylerDate from "@/helpers/Date/date"

export default {
  components: {
    FormDatePicker,
    FormDatePickerMultiple,
    VDatetimeTextField
  },

  data: () => ({
    form: {}
  }),
  computed: {
    format()  {
        return skylerDate.dateToString(this.form.data1)
    }
  }
}
</script>
