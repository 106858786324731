export default [
    {key: 'custo_bilhete', value: 'Bilhete'},
    {key: 'custo_taxa_embarque', value: 'Taxa de Embarque'},
    {key: 'custo_taxa_bagagem', value: 'Taxa de Bagagem'},
    {key: 'rav', value: 'RAV via fornecedor'},
    {key: 'quantidade_milhas', value: 'Quantidade de Milhas'},
    {key: 'custo_taxas_extras', value: 'Taxas Extras'},
    {key: 'milheiro', value: 'Milheiro de venda'},
    {key: 'custo_diaria', value: 'Diária'},
    {key: 'custo_extras', value: 'Extras'},
    {key: 'custo_seguro', value: 'Seguro'},
    {key: 'custo_total', value: 'Custo Total'},
]