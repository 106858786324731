<template >
    <div class="page">
        <Header />
        <div class="infos">
            <h1>Orçamento de Serviços</h1>
            <div class="my-container">
                <h2>Cliente</h2>
                <div class="sub-infos">
                    <p>Nome: {{orcamento.venda.cliente.nome}} {{orcamento.venda.cliente.sobrenome}}</p>
                    <p>Email: {{orcamento.venda.cliente.email}}</p>
                    <p>Telefone: {{orcamento.venda.cliente.telefone.numero}}</p>
                    <p v-if="orcamento.venda.cliente.cpf">CPF: {{orcamento.venda.cliente.cpf}}</p>
                    <p v-if="orcamento.venda.cliente.cnpj">CNPJ: {{orcamento.venda.cliente.cnpj}}</p>
                </div>
            </div>
            <div class="my-container">
                <h2>Venda</h2>
                <div class="sub-infos">
                    <p>ID da Venda: {{orcamento.venda.id}}</p>
                    <p>Criação do orçamento: {{data()}}</p>
                    <p v-if="orcamento.venda.consultor">Consultor: {{orcamento.venda.consultor.nome}} {{orcamento.venda.consultor.sobrenome}}</p>
                    <p v-if="orcamento.venda.consultor">Email consultor: {{orcamento.venda.consultor.email}}</p>
                </div>
            </div>
            <p>Proposta não vinculante.</p>
            <p>
                Proposta válida até 12 horas após emissão ({{data(12)}}).
            </p>
        </div>
    </div>
</template>
<script>

import Header from '../Template/Header.vue'
export default {
    components: {
        Header
    },
    props: {
        orcamento: {
            type: Object,
            default: () => {},
            required: true
        },
        cotacoes: {
            type: Array,
            default: () => [],
            required: true
        }
    },
    methods: {
        data (plusHours = 0) {
            const options = {hour: '2-digit', minute: '2-digit', year: 'numeric', month: 'long', day: 'numeric' }
            const event = new Date()
            event.setHours(event.getHours() + plusHours)
            return event.toLocaleString('pt-BR', options)
        },
    }
}
</script>
<style >
.infos {
    display: flex;
    flex-direction: column;
    align-items: center;
}
.my-container{
    margin: 1rem auto 1rem auto;
    display: flex;
    flex-direction: column;
    align-items: center;
}
.sub-infos {
    margin-top: 1rem;
}
.sub-infos > p {
    margin: 0;
}
    
</style>