import VendaRouter from "./VendaRouter.js";
import ContatoRouter from "./ContatoRouter.js";
import RacRouter from "./RacRouter.js";
import HomeView from "@/views/HomeView.vue";
import ContatosView from "@/views/ContatosView";
import ContatoView from "@/views/ContatoView";
import EmpresasView from "@/views/EmpresasView";
import EmpresaView from "@/views/EmpresaView";
import VendasView from "@/views/VendasView";
import VendaDetalheView from "@/views/VendaDetalheView";
import OrcamentoDetalheView from "@/views/OrcamentoDetalheView";
import OrcamentosView from "@/views/OrcamentosView";
import EmissoesView from "@/views/EmissoesView";
import EmissaoView from "@/views/EmissaoView";
import PagamentosView from "@/views/PagamentosView";
import LogsView from "@/views/LogsView";
import TwoFactorAuthView from "@/views/TwoFactorAuthView";
import TesteView from "@/views/TesteView";
import FornecedoresView from "@/views/FornecedoresView";
import ServicosView from "@/views/ServicosView";
import BuscaMoblixView from "@/views/BuscaMoblixView"
import RACView from "@/views/RACView"
import MilhasView from "@/views/MilhasView";

export default [
    // subrouters
    {
        path: "/vendas/:id",
        component: VendaDetalheView,
        props: true,
        children: VendaRouter
    },
    {
        path: "/contatos/:id",
        component: ContatoView,
        redirect: {name: "contato-info"},
        name: 'cliente',
        props: true,
        children: ContatoRouter
    },
    {
        path: "/rac/",
        component: RACView,
        props: true,
        children: RacRouter
    },

    //routes
    {
        path: "/",
        name: "home",
        component: HomeView,
    },
    {
        path: "/contatos",
        name: "contatos",
        component: ContatosView,
    },
    {
        path: "/empresas",
        name: "empresas",
        component: EmpresasView,
    },
    {
        path: "/empresas/:id",
        name: "empresa",
        component: EmpresaView,
    },
    {
        path: "/vendas",
        name: "vendas",
        component: VendasView,
    },
    {
        path: "/orcamentos",
        name: "orcamentos",
        component: OrcamentosView,
    },
    {
        path: "/orcamentos/:id",
        name: "orcamento",
        component: OrcamentoDetalheView,
    },
    {
        path: "/teste",
        name: "teste",
        component: TesteView
    },
    {
        path: "/emissoes",
        name: "emissoes",
        component: EmissoesView,
    },
    {
        path: "/emissoes/:id",
        name: "emissao",
        component: EmissaoView,
    },
    {
        path: "/pagamentos",
        name: "pagamentos",
        component: PagamentosView,
    },
    {
        path: "/otp_verification",
        name: "otp",
        component: TwoFactorAuthView
    },
    {
        path: "/logs",
        name: "logs",
        component: LogsView,
    },
    {
        path: "/fornecedores",
        name: "fornecedores",
        component: FornecedoresView,
    },
    {
        path: '/servicos',
        name: 'servicos',
        component: ServicosView 
    },
    {
        path: '/busca-moblix/:id',
        name: 'busca-moblix',
        component: BuscaMoblixView
    },
    {
        path: '/racs/aberta',
        name: 'rac',
        component: RACView 
    },
    {
        path: '/milhas',
        name: 'milhas',
        component: MilhasView
    },
];
