var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-card',[_c('v-card-title',[_vm._v("Descrição Serviços - "+_vm._s(_vm.cotacaoTipoServico(_vm.servico.cotacao)))]),_c('v-card-text',[_c('v-container',[_c('v-row',[_c('v-col',[_c('v-row',[_c('v-col',{staticClass:"font-weight-bold"},[_vm._v(" Valor Inicial: ")]),_c('v-col',[_vm._v(" "+_vm._s(_vm._f("dinheiroReal")(_vm.cotacaoCustoTotal(_vm.servico.cotacao)))+" ")])],1),_c('v-row',[_c('v-col',{staticClass:"font-weight-bold"},[_vm._v(" Valor Ajuste: ")]),_c('v-col',[_vm._v(" "+_vm._s(_vm._f("dinheiroReal")(_vm.cotacaoValorAjuste(_vm.servico.cotacao)))+" ")])],1),_c('v-row',[_c('v-col',{staticClass:"font-weight-bold"},[_vm._v(" Valor Pós-Ajuste: ")]),_c('v-col',[_vm._v(" "+_vm._s(_vm._f("dinheiroReal")(_vm.cotacaoValorTotal(_vm.servico.cotacao)))+" ")])],1),_c('v-row',[_c('v-col',{staticClass:"font-weight-bold"},[_vm._v(" Valor 12x: ")]),_c('v-col',[_vm._v(" "+_vm._s(_vm._f("dinheiroReal")(_vm.cotacaoValorTotalJuros(_vm.servico.cotacao)))+" ")])],1),_c('v-row',[_c('v-col',{staticClass:"font-weight-bold"},[_vm._v(" Desconto: ")]),_c('v-col',[_vm._v(" "+_vm._s(_vm._f("dinheiroReal")(_vm.converteValorFinanceiroBRL(_vm.servico.desconto)))+" ")])],1),_c('v-row',[_c('v-col',{staticClass:"font-weight-bold"},[_vm._v(" Receitas: ")]),_c('v-col',[_vm._v(" "+_vm._s(_vm._f("dinheiroReal")(_vm.servicoValorTotal(_vm.servico)))+" ")])],1)],1)],1),_c('v-divider',{staticClass:"mb-2"}),_c('v-col',[_c('v-row',{staticClass:"font-weight-bold ma-0"},[_vm._v(" Custos: ")]),_c('v-row',{staticClass:"ml-2 pb-0"},[_c('v-col',[(_vm.servico?.cotacao?.cotacao_voo)?_c('v-row',[_c('v-col',[_vm._l((_vm.servico?.cotacao?.cotacao_voo
                    ?.subcotacoes),function(subcotacao){return _c('v-row',{key:subcotacao.id},[_c('v-col',[_c('v-row',{staticClass:"font-weight-bold"},[_vm._v(" Subcotação #"+_vm._s(subcotacao.id)+" - "+_vm._s(subcotacao.tipo_cotacao))]),_vm._l((Object.entries(
                        _vm.custosServicoVooSubcotacaoPrecificadores(subcotacao)
                      )),function([key, value]){return (subcotacao.tipo_cotacao === 'pagante')?_c('v-row',{key:key},[(key !== 'Custo assento conforto')?_c('v-col',{staticClass:"font-weight-bold",attrs:{"align-self":"auto"}},[_vm._v(_vm._s(key))]):_vm._e(),(key !== 'Custo assento conforto')?_c('v-col',{attrs:{"align-self":"auto"}},[_vm._v(_vm._s(_vm._f("dinheiroReal")(value)))]):_vm._e(),(_vm.emissao == 'finalizada')?_c('v-col',[(key == 'Taxa de embarque')?_c('v-field-value',{attrs:{"label":"Responsável"}},[_vm._v(_vm._s(_vm.getCustoTaxaEmbarqueResponsavel()))]):_vm._e(),(key == 'Taxa de bagagem')?_c('v-field-value',{attrs:{"label":"Responsável"}},[_vm._v(" "+_vm._s(_vm.getCustoTaxaBagagemResponsavel()))]):_vm._e(),(key == 'Taxas extras')?_c('v-field-value',{attrs:{"label":"Responsável"}},[_vm._v(" "+_vm._s(_vm.getCustoTaxaExtraResponsavel())+" ")]):_vm._e(),(key == 'RAV via fornecedor')?_c('v-field-value',{attrs:{"label":"Responsável"}},[_vm._v(" "+_vm._s(_vm.getRavResponsavel())+" ")]):_vm._e(),(key == 'Custo bilhete')?_c('v-field-value',{attrs:{"label":"Responsável"}},[_vm._v(" "+_vm._s(_vm.getCustoBilheteResponsavel())+" ")]):_vm._e()],1):_vm._e()],1):_vm._e()}),(
                        subcotacao.tipo_cotacao === 'milha' &&
                        _vm.servico.status !== 'emissao'
                      )?_c('v-row',{staticClass:"mb-2"},[_vm._v(" Custos para milhas só estão disponíveis para serviços em emissão ")]):_vm._e(),(
                        subcotacao.tipo_cotacao === 'milha' &&
                        _vm.servico.status === 'emissao'
                      )?_c('v-row',[_c('v-col',[_c('v-row',[_c('v-col',[_c('v-row',[_c('v-col',{staticClass:"font-weight-bold"},[_vm._v(" Taxa de embarque")]),_c('v-col',[_vm._v(_vm._s(_vm._f("dinheiroReal")(_vm.converteValorFinanceiroBRL( subcotacao.custo_taxa_embarque ))))]),(_vm.emissao == 'finalizada')?_c('v-col',[_c('v-field-value',{attrs:{"label":"Responsável"}},[_vm._v(_vm._s(_vm.getCustoTaxaEmbarqueResponsavel()))])],1):_vm._e()],1),_c('v-row',[_c('v-col',{staticClass:"font-weight-bold"},[_vm._v(" Taxa de bagagem")]),_c('v-col',[_vm._v(_vm._s(_vm._f("dinheiroReal")(_vm.converteValorFinanceiroBRL( subcotacao.custo_taxa_bagagem ))))]),(_vm.emissao == 'finalizada')?_c('v-col',[_c('v-field-value',{attrs:{"label":"Responsável"}},[_vm._v(_vm._s(_vm.getCustoTaxaBagagemResponsavel()))])],1):_vm._e()],1),_c('v-row',[_c('v-col',{staticClass:"font-weight-bold"},[_vm._v(" Taxas extras")]),_c('v-col',[_vm._v(_vm._s(_vm._f("dinheiroReal")(_vm.converteValorFinanceiroBRL( subcotacao.custos_taxas_extras ))))]),(_vm.emissao == 'finalizada')?_c('v-col',[_c('v-field-value',{attrs:{"label":"Responsável"}},[_vm._v(_vm._s(_vm.getCustoTaxaExtraResponsavel()))])],1):_vm._e()],1),_c('v-row',[_c('v-col',{staticClass:"font-weight-bold"},[_vm._v(" RAV via fornecedor")]),_c('v-col',[_vm._v(_vm._s(_vm._f("dinheiroReal")(_vm.converteValorFinanceiroBRL(subcotacao.rav))))]),(_vm.emissao == 'finalizada')?_c('v-col',[_c('v-field-value',{attrs:{"label":"Responsável"}},[_vm._v(_vm._s(_vm.getRavResponsavel()))])],1):_vm._e()],1),_c('v-row',[_c('v-col',{staticClass:"font-weight-bold"},[_vm._v(" Custo total(sem as milhas)")]),_c('v-col',[_vm._v(_vm._s(_vm._f("dinheiroReal")(_vm.custosServicoVooSubcotacaoPrecificadores( subcotacao )["Custo total"])))])],1),_c('v-divider',{staticClass:"mb-2"})],1)],1),_vm._l((_vm.cotacoesValidadas(
                            subcotacao
                          )),function(milhaValidada){return _c('v-row',{key:milhaValidada.id},[_c('v-col',[_c('v-row',[_c('v-col',{staticClass:"font-weight-bold"},[_vm._v(" Milhas validadas #"+_vm._s(milhaValidada.id))])],1),_c('v-row',[_c('v-col',{staticClass:"font-weight-bold"},[_vm._v(" Valor compra")]),_c('v-col',[_vm._v(_vm._s(_vm._f("dinheiroReal")(_vm.converteValorFinanceiroBRL( milhaValidada.valor_compra ))))])],1),_c('v-row',[_c('v-col',{staticClass:"font-weight-bold"},[_vm._v(" Quantidade milhas")]),_c('v-col',[_vm._v(_vm._s(milhaValidada.valor))])],1),_c('v-row',[_c('v-col',{staticClass:"font-weight-bold"},[_vm._v(" Custo total")]),_c('v-col',[_vm._v(_vm._s(_vm._f("dinheiroReal")(((milhaValidada.valor * _vm.converteValorFinanceiroBRL( milhaValidada.valor_compra )) / 1000))))])],1),_c('v-divider',{staticClass:"mb-2"})],1)],1)})],2)],1):_vm._e(),(
                        subcotacao.tipo_cotacao === 'wallet' &&
                        _vm.servico.status !== 'emissao'
                      )?_c('v-row',{staticClass:"mb-2"},[_vm._v(" Custos para wallet só estão disponíveis para serviços em emissão ")]):_vm._e(),(
                        subcotacao.tipo_cotacao === 'wallet' &&
                        _vm.servico.status === 'emissao'
                      )?_c('v-row',{staticClass:"pb-0"},[_c('v-col',[_c('v-row',[_vm._v(" Deságio "+_vm._s(_vm.desagioWallet(subcotacao))+"% ")]),_vm._l((Object.entries(
                            _vm.custosServicoVooSubcotacaoPrecificadores(
                              subcotacao
                            )
                          )),function([key, value]){return _c('v-row',{key:key},[(key !== 'Custo assento conforto')?_c('v-col',{staticClass:"font-weight-bold"},[_vm._v(_vm._s(key))]):_vm._e(),(key !== 'Custo assento conforto')?_c('v-col',[_vm._v(_vm._s(_vm._f("dinheiroReal")(value)))]):_vm._e(),(_vm.emissao == 'finalizada')?_c('v-col',[(key == 'Taxa de embarque')?_c('v-field-value',{attrs:{"label":"Responsável"}},[_vm._v(_vm._s(_vm.getCustoTaxaEmbarqueResponsavel()))]):_vm._e(),(key == 'Taxa de bagagem')?_c('v-field-value',{attrs:{"label":"Responsável"}},[_vm._v(" "+_vm._s(_vm.getCustoTaxaBagagemResponsavel()))]):_vm._e(),(key == 'Taxas extras')?_c('v-field-value',{attrs:{"label":"Responsável"}},[_vm._v(" "+_vm._s(_vm.getCustoTaxaExtraResponsavel())+" ")]):_vm._e(),(key == 'RAV via fornecedor')?_c('v-field-value',{attrs:{"label":"Responsável"}},[_vm._v(" "+_vm._s(_vm.getRavResponsavel())+" ")]):_vm._e(),(key == 'Custo bilhete')?_c('v-field-value',{attrs:{"label":"Responsável"}},[_vm._v(" "+_vm._s(_vm.getCustoBilheteResponsavel())+" ")]):_vm._e()],1):_vm._e()],1)}),_c('v-row',[_c('v-col',{staticClass:"font-weight-bold"},[_vm._v("Custo após deságio")]),_c('v-col',[_vm._v(_vm._s(_vm._f("dinheiroReal")(_vm.custoWallet(subcotacao))))])],1)],2)],1):_vm._e(),_c('v-divider',{staticClass:"mb-2"})],2)],1)}),_c('v-row',{staticClass:"font-weight-bold ml-2 mb-2"},[_vm._v(" Cotação #"+_vm._s(_vm.servico.cotacao.id))]),_c('v-field-value',{attrs:{"label":"Custo Total"}},[_vm._v(" "+_vm._s(_vm._f("dinheiroReal")(_vm.custoServico(_vm.servico))))])],2)],1):_vm._e(),_vm._l((Object.entries(
                _vm.custoServicoPrecificadoresSemVoo(_vm.servico)[0]
              )),function([key, value]){return (!_vm.servico?.cotacao?.cotacao_voo)?_c('v-row',{key:key,staticClass:"pa-0"},[_c('v-col',{staticClass:"font-weight-bold pb-0"},[_vm._v(_vm._s(key))]),_c('v-col',{staticClass:"pb-0"},[_vm._v(_vm._s(_vm._f("dinheiroReal")(value)))])],1):_vm._e()})],2)],1)],1),_c('v-divider',{staticClass:"mt-6 mb-2"}),_c('v-row',[_c('v-col',{staticClass:"font-weight-bold"},[_vm._v(" Valor Base(Receitas - Custos totais): ")]),_c('v-col',[_vm._v(" "+_vm._s(_vm._f("dinheiroReal")((_vm.servicoValorTotal(_vm.servico) - _vm.custoServico(_vm.servico))))+" ")])],1)],1)],1),_c('v-card-actions',[_c('v-btn',{attrs:{"color":"blue darken-1","text":""},on:{"click":_vm.close}},[_vm._v(" Fechar ")])],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }