<template>
  <v-menu
    v-model="menu2"
    :close-on-content-click="false"
    :nudge-right="40"
    transition="scale-transition"
    offset-y
    clearable
    min-width="auto"
  >
    <template v-slot:activator="{ on, attrs }">
      <v-text-field
        :disabled="disabled"
        :outlined="outlined"
        dense
        clearable
        :value="formattedDates"
        :label="label"
        prepend-icon="mdi-calendar"
        v-bind="attrs"
        v-on="on"
      ></v-text-field>
    </template>
    <v-date-picker range clearable v-model="dates"></v-date-picker>
  </v-menu>
</template>
<script>
import DateSkyler from "@/helpers/Date/date";
export default {
  props: {
    value: {
      type: Array,
      required: false,
      default: () => [],
    },
    label: String,
    outlined: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    multiple: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      dates: [],
      menu2: false,
    };
  },

  computed: {
    formattedDates() {
      if(!this.dates) return
      const formattedDates = this.dates.map((date) => {
        return this.$options.filters.convertDataBR(date);
      });
      return formattedDates.join(", ");
    },
  },

  watch: {
    dates: {
      immediate: true,
      handler: function (datesFromDatePicker) {
        if (!datesFromDatePicker) return;
        const datesUTC = this.dates.map((date) => DateSkyler.parseISOtoUTCDate(date))
        this.$emit("input", datesUTC);
      },
    },
  },

  mounted() {
    if(this.dates.length == 0) this.dates = this.value
    if (!this.dates || !Array.isArray(this.dates)) this.dates = [];
  }
};
</script>
