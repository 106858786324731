export const personalInformation = {
    nome: "Nome",
    flags: "Flags",
    idade: "Idade",
    data_nascimento: "Data de Nascimento",
    nome_mae: "Nome da Mãe",
    nome_pai: "Nome do Pai",
}

export const lawProcessInformation = {
    quantidade: "Quantidade de processos",
}
export const protestInformation = {
    quantidade: "Quantidade",
}
export const phoneInformation = {
    quantidade: "Quantidade",
}
export const emailInformation = {
    quantidade: "Quantidade",
}
export const otherdocumentInformation = {
    quantidade: "Quantidade",
}
export const restrictionInformation = {
    quantidade: "Quantidade de Restrições",
    flag: "Flag",
}
export const bankCheckInformation = {
    quantidade: "Número de cheques",
    flag: "Flag",
}

export const companyInformation = {
    nome: "Nome",
    flags: "Flags",
}

export const partnershipInformation = {
    quantidade: "Quantidade",
}
export const shareholderInformation = {
    quantidade: "Quantidade",
}

export const creditScoreInformation = {
    D00: "Atual",
    D30: "Últimos 30 dias",
    D60: "Últimos 60 dias",
    D90: "Últimos 90 dias",

}



