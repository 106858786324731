<template>
    <v-container fluid>
        <v-col cols="12">
            <v-card rounded="10" :loading="loading">
                <v-card-title>
                    <v-col cols="12" md="4">
                        <v-text-field
                            dense
                            outlined
                            clearable
                            clear-icon="mdi-close"
                            append-icon="mdi-magnify"
                            label="Pesquisa"
                            v-model="search.nome"
                        />
                    </v-col>
                    <v-spacer />
                    <v-row>
                        <v-col class="d-flex flex-row-reverse">
                            <v-btn fab text x-small @click="refreshTable()">
                                <v-icon>mdi-refresh</v-icon>
                            </v-btn>
                        </v-col>
                    </v-row>
                </v-card-title>

                <v-card-text>
                    <v-data-table
                        @click:row="rowClick"
                        :headers='headers'
                        :items='contatos'
                        :hide-default-footer="true"
                    >
                        <template v-slot:no-data>
                            Nenhum cliente encontrado.
                        </template>

                        <template v-slot:[`item.data_nascimento`]='{ item }'>
                            {{item.data_nascimento | convertDataBR}}
                        </template>

                        <template v-slot:[`item.created_by`]='{ item }'>
                            {{item.created_by | nomeSobrenome}}
                        </template>

                        <template v-slot:[`item.created_at`]='{ item }'>
                            {{item.created_at | convertDataBR}}
                        </template>

                        <template v-slot:[`item.last_edited_by`]='{ item }'>
                            {{item.last_edited_by | nomeSobrenome}}
                        </template>

                        <template v-slot:item.ultima_alteracao="{item}">
                            <div>
                            {{ item.updated_at| convertDataBR }} às {{ item.updated_at| dataHoraMinuto }}
                            </div>
                        </template>

                        <template v-slot:[`item.nome`]=' { item } '>
                            <v-icon color="yellow" v-if="item.tier == 'VIP'"> mdi-crown </v-icon>
                            {{ item | nomeSobrenome}}
                        </template>

                        <template v-slot:[`item.telefone`]=' { item } '>
                            <span v-if="item.telefone?.numero != ''">
                                <img
                                    :src="bandeiraPais(item.telefone?.pais)"
                                    width="20px"
                                    style="margin-right: 10px"
                                    outlined
                                />{{ item.telefone?.numero }}
                            </span>
                            <span v-else>
                                -
                            </span>
                        </template>

                        <template v-slot:item.perfil='{ item }'>
                            {{item.perfil | formatPerfil}}
                        </template>
                    </v-data-table>

                    <Paginator
                        :numPages="numPages"
                        @pageChange="refreshTable"
                    />
                </v-card-text>
            </v-card>
        </v-col>
    </v-container>
</template>

<script>
import Paginator from '@/components/widgets/Paginator.vue'

export default {
    components: {Paginator},
    props: {
        contatos: {
            type: Array,
            default: () => [],
        },
        numPages: {
            type: Number,
            default: () => Number
        },
        loading: {
            type: Boolean,
            default: false
        },
        filters: {
            type: Array,
            default: () => []
        }
    },

    mounted() {
        document.onkeydown = (e) => this.changePressingCtrlState(e);
        document.onkeyup = (e) => this.changePressingCtrlState(e)
    },

    data() {
        return {
            headers: [
                {
                    text: 'Nome',
                    sortable: true,
                    value: 'nome'
                },
                { text: "Perfil", value: "perfil"},
                { text: 'CPF', value: 'cpf' },
                { text: 'Email', value: 'email' },
                { text: 'Telefone', value: 'telefone'},
                { text: 'Data de Nascimento', value: 'data_nascimento' },
                { text: 'Criado por', value: 'created_by' },
                { text: 'Criação do Contato', value: 'created_at' },
                { text: 'Alterado por', value: 'last_edited_by' },
                { text: 'Data última alteração', value: 'ultima_alteracao' },
            ],
            breadcumb_items: [
                {
                    text: 'Home',
                    to: "home"
                },
                {
                    text: 'Contatos',
                    to: "contatos"
                }
            ],
            search: {
                nome: ""
            },
            filter: {
                nome: "",
                empresa: this.filters[0]?.empresa
            },
            pressingCtrl: false,
        }
    },

    watch: {
        'search.nome': {
            handler() {
                clearTimeout(this.timer)

                this.timer = setTimeout(() => {
                    this.filter.nome = this.search.nome
                }, 1000)
            }
        },

        filter: {
            deep: true,
            immediate: true,
            handler() {
                this.refreshTable(1)
            }
        }
    },

    methods: {
        refreshTable(page) {
            let filters = []
            const filterProperties = ['nome', 'empresa']

            filterProperties.forEach(property => {
                if(this.filter[property]) filters.push([property, this.filter[property]])
            })

            this.$emit('refresh', {
                page,
                filters
            })
        },

        rowClick(value) {
            this.$emit('rowClick', value)
        },

        bandeiraPais(pais_iso="BR") {
            return 'https://cdn.kcak11.com/CountryFlags/countries/' + pais_iso.toLowerCase()+ '.svg'
        },
        changePressingCtrlState(e) {
            if(e.key != "Control") return
            this.pressingCtrl = e.type == 'keydown' ? true : false
        }
    }
}
</script>
