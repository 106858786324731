<template>
  <v-container fluid>
    <Transition>
      <div key="1" v-if="!editting">
        <v-row>
          <venda-info-geral-view></venda-info-geral-view>
        </v-row>
        <v-row>
          <v-col cols="auto">
            <v-btn
              color="primary"
              @click="editar"
              :disabled="venda.status !== 'proposta'"
            >
              <v-icon>mdi-pencil</v-icon>
              Editar
            </v-btn>
          </v-col>
          <v-spacer></v-spacer>
          <v-col cols="auto">
            <v-btn
              color="green"
              class="white--text"
              :disabled="venda.status !== 'proposta'"
              @click="() => concluirVenda()"
              :loading="loadingServicos"
            >
              <v-icon>mdi-check</v-icon>
              Concluir Venda
            </v-btn>
          </v-col>
          <v-col cols="auto">
            <v-btn
              color="error darken-1"
              :disabled="venda.status !== 'proposta'"
              @click="cancelDialog = true"
            >
              <v-icon>mdi-close</v-icon>
              Cancelar Venda
            </v-btn>
          </v-col>
        </v-row>
      </div>
      <div key="2" v-else>
        <v-row>
          <venda-edit-form
            @save="salvar"
            @cancel="editting = false"
            :venda="venda"
            :paraEmpresas="venda.cliente_empresa !== null"
            hide-secao-orcamento
          />
        </v-row>
      </div>
    </Transition>
    <VendaCancelarDialog
      :dialog="cancelDialog"
      @confirmarCancelamento="cancelarVenda"
      @closeDialog="cancelDialog = false"
    />
  </v-container>
</template>
<script>
import VendaInfoGeralView from "@/components/venda/VendaInfoGeralView";
import VendaEditForm from "@/components/venda/VendaEditForm";
import VendaCancelarDialog from "@/components/venda/VendaCancelarDialog.vue";
import { converteValorFinanceiroBRL } from "@/helpers/financeiro";
import CotacaoMixin from "@/mixins/CotacaoMixin";

import { formatApiError } from "@/helpers/apiErrors";
import { mapState } from "vuex";
import api from "@/api/api";

export default {
  mixins: [CotacaoMixin],
  components: { VendaEditForm, VendaInfoGeralView, VendaCancelarDialog },
  data() {
    return {
      loading: false,
      editting: false,
      edittingVenda: {},
      cancelDialog: false,
      vendas: [],
      servicos: [],
      pagamentos: [],
      loadingServicos: false,
    };
  },
  computed: {
    ...mapState({
      venda: (state) => state.venda.venda,
    }),

    valorAbertoVendas() {
      return Object.fromEntries(
        this.vendas.map((venda) => [
          venda.id,
          this.valorTotalVenda(venda.id) - this.valorPagoVenda(venda.id),
        ])
      );
    },
  },

  created() {
    this.pegarDados();
    this.getPagamentos();
  },

  // Need to retrieve the data to calculate ValorAberto
  methods: {
    checkSave() {
      const checkServicosCancelados = this.servicos.filter(
        (servico) => servico.servicos[0].status === "cancelado"
      ).length;
      return (
        this.valorAbertoVendas[this.venda.id] <= 0 ||
        checkServicosCancelados === this.servicos.length
      );
    },

    valorTotalVenda(idVenda) {
      let idVendaFiltrado = idVenda;
      if (typeof idVenda === "object") {
        idVendaFiltrado = idVenda.id;
      }
      let valorTotal = 0;
      this.servicos.forEach((item) => {
        if (item.id_venda.id === idVendaFiltrado) {
          valorTotal = item.servicos.reduce(
            (total, value) => total + this.servicoValorTotal(value),
            0
          );
          return valorTotal;
        }
      });
      return valorTotal;
    },

    valorPagoVenda(idVenda) {
      const total_pago = this.pagamentos
        .filter(
          (pagamento) =>
            pagamento.venda === idVenda && pagamento.status === "concluido"
        )
        .reduce(
          (total, pagamento) =>
            (total += converteValorFinanceiroBRL({
              valor: pagamento.valor,
              moeda: pagamento.moeda,
            })),
          0
        );
      return total_pago;
    },

    getPagamentos() {
      const venda_id = this.venda.id;
      if (this.venda.cliente) {
        const cliente_id = this.venda.cliente.id;
        api.getContatoCotacoesPago(cliente_id).then((response) => {
          this.pagamentos = response.data.filter(
            (pagamento) => pagamento.venda === venda_id
          );
          return response.data;
        });
      } else {
        const cliente_id = this.venda.cliente_empresa.id;
        api.getPagamentos(this.venda.id).then((res) => {
          this.pagamentos = res.data;
          return res.data;
        });
      }
    },
    pegarDados() {
      if (this.venda.cliente) {
        const cliente_id = this.venda.cliente.id;
        this.vendas = [this.venda];
        this.loadingServicos = true;
        api
          .getContatoServicos(cliente_id)
          .then((response) => {
            response.data.forEach((servico) => {
              if (
                !this.servicos.find((item) => item.id_venda === servico.venda)
              ) {
                const item = { servicos: [servico], id_venda: servico.venda };
                this.servicos.push(item);
                this.valorTotalVenda(servico.venda);
              } else {
                this.servicos
                  .find((item) => item.id_venda === servico.venda)
                  .servicos.push(servico);
              }
            });
            return response.data;
          })
          .finally(() => {
            this.loadingServicos = false;
          });
      } else {
        this.vendas = [this.venda];
        this.loadingServicos = true;
        const venda_id = this.vendas[0].id;
        api
          .getServicosVenda(venda_id)
          .then((response) => {
            response.data.forEach((servico) => {
              if (
                !this.servicos.find((item) => item.id_venda === servico.venda)
              ) {
                const item = { servicos: [servico], id_venda: servico.venda };
                this.servicos.push(item);
                this.valorTotalVenda(servico.venda);
              } else {
                this.servicos
                  .find((item) => item.id_venda === servico.venda)
                  .servicos.push(servico);
              }
            });
            return response.data;
          })
          .finally(() => {
            this.loadingServicos = false;
          });
      }
    },
    editar() {
      this.editting = true;
    },
    async concluirVenda() {
      // const check = await this.checkSave();
      // if(!check){
      //   this.$root.vtoast.show({
      //       message: "Existe valor em aberto nos pagamentos para a venda em questão",
      //       color: "error",
      //       icon: "mdi-close",
      //     });
      //     return console.error('Existe valor em aberto nos pagamentos para a venda em questão');
      // }

      await api
        .concluirVenda(this.venda.id, {
          status: "concluida",
        })
        .then((data) => {
          this.$router.push("/vendas");
        })
        .catch((e) => {
          this.$root.vtoast.show({
            message: formatApiError(e),
            color: "error",
            icon: "mdi-close",
          });
        });
    },
    async cancelarVenda(motivo) {
      await api
        .cancelarVenda(this.venda.id, {
          status: "fechada",
          motivo_cancelamento: motivo,
        })
        .then((data) => {
          this.$router.push("/vendas");
        })
        .catch((e) => {
          console.error(e);
          window.alert(formatApiError(e));
          this.cancelDialog = false;
        });
    },

    async salvar(venda, orcamentos) {
      this.loading = true;
      const res = await this.$store.dispatch("venda/updateVenda", venda);
      this.loading = false;
      if (!res.error) {
        this.editting = false;
        this.$root.vtoast.show({ message: "Venda atualizada com sucesso." });
      } else {
        this.$root.vtoast.show({ message: res, color: "error" });
      }
    },
  },
};
</script>

<style scoped>
.v-enter-active,
.v-leave-active {
  transition: opacity 0.5s ease;
}

.v-enter-from,
.v-leave-to {
  opacity: 0;
}
</style>
