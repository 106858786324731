<template>
  <v-container class="w-auto" max-width="100%">
    <v-card class="px-4 mx-0">
      <v-card-title class="text-h5"> Documentos </v-card-title>
      <v-card-text>
        <v-row v-for="doc in documentos" :key="doc.id">
          <v-col>
            <v-icon> mdi-file </v-icon>
          </v-col>
          <v-col>
            {{ doc.tipo }}
          </v-col>
          <v-col>
            <v-btn icon @click="abrirDocumento(doc.documento)">
              <v-icon color="blue lighten-1">mdi-eye</v-icon>
            </v-btn>
          </v-col>
          <v-col> Adicionado por {{ doc.created_by | nomeSobrenome }} </v-col>
          <v-col> Adicionado em {{ doc.created_at | dataHoraBR }} </v-col>
        </v-row>

        <v-divider></v-divider>
        <v-row>
          <v-file-input
            label="Documento"
            name="documento"
            v-model="value.documento"
            chips
          />
        </v-row>
        <v-row>
          <v-text-field
            v-model="value.tipo"
            dense
            outlined
            label="Tipo"
            :rules="[(v) => v?.length >= 10 || 'Mínimo 10 caracteres']"
          ></v-text-field>
        </v-row>
      </v-card-text>

      <v-card-actions>
        <v-btn text @click="closeDialog">
          <v-icon left>mdi-arrow-left</v-icon>
          Voltar
        </v-btn>
        <v-spacer />
        <v-btn
          text
          @click="addDocumento()"
          :disabled="value.tipo.length < 10 || !value.tipo"
        >
          Adicionar documento
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-container>
</template>

<script>
export default {
  data() {
    return {
      motivo: "",
      loading: false,
      value: { tipo: "" },
    };
  },
  props: {
    dialog: {
      type: Boolean,
      default: false,
    },
    documentos: {
      type: Array,
      default: () => [],
    },
  },
  methods: {
    closeDialog() {
      this.$emit("closeDialog");
    },
    addDocumento() {
      this.loading = true;
      this.$emit("adicionarDocumento", this.value);
      setTimeout(() => {
        this.value = { tipo: "" };
      }, 2000);
    },
    abrirDocumento(url) {
      window.open(url);
    },
  },
};
</script>
