<template >
    <div>
        <div class="up-half">
            <div class="bg-circle"></div>
            <img src="@/assets/logo-skyler-white.png" /> 
            <div class="titulo">
                <h2 v-if="orcamento.orcamento_voo">Aqui estão os melhores voos que encontramos para você:</h2>
                <h2 v-else-if="orcamento.orcamento_hotel">Aqui estão os melhores hoteis que encontramos para você:</h2>
                <h2 v-else-if="orcamento.orcamento_carro">Aqui estão os melhores alugueis de veículos que encontramos para você:</h2>
                <h2 v-else>Aqui estão as melhores oportunidades que encontramos para você:</h2>
            </div>
            <div v-if="orcamento.orcamento_voo" class="percurso">
                <div class="">
                    <p>DE</p>
                    <p class="airport-name">{{getTo(cotacoes)}}</p>
                </div>
                <img src="@/assets/aviao.png"  /> 
                <div class="">
                    <p>PARA</p>
                    <p class="airport-name">{{getFrom(cotacoes)}}</p>
                </div>
            </div>
        </div>
        <div class="down-half">
            <h2>Detalhes do seu pedido:</h2>
            <div class="sub-infos">
                <div v-if="orcamento.orcamento_voo">
                    <div class="trecho-voo">
                        <p>IDA</p>
                        <p>{{formatData(orcamento.orcamento_voo.trechos[0].data_inicio)}}</p>
                    </div>
                    <div class="trecho-voo">
                        <p>VOLTA</p>
                        <p>{{formatData(orcamento.orcamento_voo.trechos[1].data_inicio)}}</p>
                    </div>
                </div>
                <p v-if="orcamento.venda.cliente"><span>NOME</span> {{orcamento.venda.cliente.nome}} {{orcamento.venda.cliente.sobrenome}}</p>
                <p v-if="orcamento.venda.cliente"><span>EMAIL</span>  {{orcamento.venda.cliente.email}}</p>
                <p v-if="orcamento.venda.cliente"><span>TEL</span> {{orcamento.venda.cliente.telefone.numero}}</p>
                <p v-if="orcamento.venda.cliente && orcamento.venda.cliente.cpf"><span>CPF</span>{{orcamento.venda.cliente.cpf}}</p>
                <p><span>ID VENDA</span>{{orcamento.venda.id}}</p>
                <p><span>CRIAÇÃO ORÇAMENTO</span>{{data()}}</p>
                <p v-if="orcamento.venda.consultor"><span>CONSULTOR</span>{{orcamento.venda.consultor.nome}} {{orcamento.venda.consultor.sobrenome}}</p>
                <p v-if="orcamento.venda.consultor"><span>EMAIL CONSULTOR</span>{{orcamento.venda.consultor.email}}</p>
            </div>
            <p>Proposta não vinculante.</p>
           
        </div>
        <div class="html2pdf__page-break"></div>
    </div>
</template>
<script>
export default {
    props: {
        orcamento: {
            type: Object,
            default: () => {},
            required: true
        },
        cotacoes: {
            type: Array,
            default: () => [],
            required: true
        }
    },
    methods: {
        data (plusHours = 0) {
            const options = {hour: '2-digit', minute: '2-digit', year: 'numeric', month: 'long', day: 'numeric' }
            const event = new Date()
            event.setHours(event.getHours() + plusHours)
            return event.toLocaleString('pt-BR', options)
        },
        formatAeroporto(local){
            if(!local.cidade) return ""
            return `${local.cidade.nome} - ${local.cidade.estado_sigla} (${local.aeroporto_iata || "IATA"})`
        },
        getTo(cotacoes){
            if(!cotacoes.every(cot => cot.cotacao_voo)) return "";
            // versão correta a ser utilizada
            // return this.formatAeroporto(subcotacoes[0].viagem.origem)

            // versão gambiarra
            const trecho = cotacoes[0].cotacao_voo.subcotacoes[0].viagem.trechos[0]
            return this.formatAeroporto(trecho.pernas[0].origem)
        },
        getFrom(cotacoes){
            if(!cotacoes.every(cot => cot.cotacao_voo)) return "";
            // return this.formatAeroporto(subcotacoes[0].viagem.destino)

            // versão gambiarra
            const trecho = cotacoes[0].cotacao_voo.subcotacoes[0].viagem.trechos[0]
            return this.formatAeroporto(trecho.pernas[0].destino)
        },
        formatData(data) {
            const date = new Date(data);
            return(`${date.getDate()}/${date.getMonth()+1}/${date.getFullYear()}`)
        }
    }
}
</script>
<style >
.up-half {
    background-color: #819486;
    width: 100%;
    min-height: 500px;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
}
.up-half > img {
    max-height: 100px;
}
.titulo {
    max-width: 500px;
    color: white;
    font-weight: light;
    text-align: center;
    margin-bottom: 3rem;
}
.percurso{
    display: flex;
    flex-direction: row;
    align-items: flex-end;
    justify-content: space-around;
    width: 80%;
}
.percurso > div{
    display: flex;
    flex-direction: column;
    align-items: center;
}
.percurso > img {
    max-height: 100px;
}
.percurso > * > p {
    width: auto;
    color: white;
}
.percurso > * > p:nth-child(2){
    font-style: italic;
    font-size: 1.3rem;
}
    

.down-half{
    margin: 2rem;
    background-color: white;
}
.down-half > h2 {
    font-style: italic; 
    color: #9c9c9c;
}
.down-half > p {
    text-align: right;
}
.my-container{
    margin: 1rem auto 1rem auto;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}
.my-container > h2 {
    font-size: 1.1rem;
    font-style: italic;
}
.sub-infos {
    margin-top: 1rem;
    margin-bottom: 5rem;
}
.sub-infos > p {
    margin-top: 1rem;
}
.sub-infos span {
    margin-right: 1rem;
    color: #9c9c9c;
    font-size: 1rem;
}
.sub-infos > div {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    text-align: center;
    margin: 2rem 0 1rem 0;
}
.sub-infos > div > div > p{
    margin-bottom: 0
}
.sub-infos .trecho-voo > p:nth-child(1){
    color: #9c9c9c;
}
.sub-infos .trecho-voo > p:nth-child(2){
    color: black;
    font-size: 1.3rem;
    font-style: italic;
    font-family: TenorSans;
}
</style>