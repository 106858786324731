<template>
  <v-autocomplete
    :disabled="loading || disabled"
    dense
    :loading="loading"
    :label="label"
    :items="aeroportos"
    v-model="selected"
    :item-text="getFieldText"
    item-value="id"
    :outlined="outlined"
    @input="$emit('input', selected)"
  >
    <template v-slot:selection="{ item }">
      {{ item.aeroporto_iata }}
    </template>
    <template v-slot:item="{ item }">
      {{ item.aeroporto_iata }} - {{ item.nome }}
    </template>
  </v-autocomplete>
</template>

<script>
export default {
  props1: ["label", "value", "outlined"],
  props: {
    label: {
      type: String,
      default: "Aeroporto",
    },
    value: {
      type: [Object, Number],
      default: () => {},
    },
    outlined: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      loading: false,
      selected: this.value,
    };
  },
  watch: {
    value: {
      immediate: true,
      handler: function (val) {
        if (val) this.selected = val;
      },
    },
  },
  computed: {
    aeroportos() {
      const aeroportos = this.$store.state.global.aeroportos;
      return aeroportos.map((aeroporto) => {
        return {
          ...aeroporto,
          text: `${aeroporto.codigo_iata} - ${aeroporto.nome}`,
        };
      });
    },
  },
  methods: {
    getFieldText(item) {
      return `${item.aeroporto_iata} - ${item.nome}`;
    },
  },
  async mounted() {
    this.loading = true;
    const res = await this.$store
      .dispatch("global/fetchAeroportos")
      .catch((err) => {
        this.$root.vtoast.show({
          message: "Erro ao carregar aeroportos",
          color: "error",
        });
        this.$root.vtoast.show({ message: err.message, color: "error" });
      });
    this.loading = false;
  },
};
</script>
