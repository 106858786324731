<template>
  <v-container fluid v-if="!error && venda">
    <v-row v-if="!loading && venda">
      <v-col cols="auto" align-self="center">
        <span class="text font-weight-bold">Venda: #{{ venda.id | formatVendaId }}</span>
      </v-col>
      <v-col cols="auto" align-self="center">
        <v-field-value label="Status">
          {{ venda.status | formatStatusVenda}}
        </v-field-value>
      </v-col>
      <v-col cols="auto" align-self="center">
        <v-field-value label="Cliente">
          {{ venda.cliente_empresa ? venda.cliente_empresa.nome : ""}}
          {{ venda.cliente  | nomeSobrenome }}
        </v-field-value>
      </v-col>
      <v-col cols="auto" align-self="center">
        <v-field-value label="Meio de contato">
          <copy-link-field :value="venda.meio_contato_valor">
            {{ venda.meio_contato_tipo |capitalize }} - {{ venda.meio_contato_valor }}
          </copy-link-field>
        </v-field-value>
      </v-col>
      <v-col>
        <v-field-value label="Plataforma"
          >{{ venda?.created_by?.cliente ? "📱 MOBILE" : "💻 WEB" }}</v-field-value
        >
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12">
        <v-tabs v-model="activeTab">
          <v-tabs-slider color="primary"></v-tabs-slider>
          <v-tab
              v-for="tab in tabs"
              :key="tab.name"
              :to="tab.route"
              exact-path>
            <v-icon v-if="tab.icon" class="pr-2">{{ tab.icon }}</v-icon>
            {{ tab.name }}
            <v-badge v-if="tab.badge" class="pl-1 pb-1" color="red"></v-badge>
          </v-tab>
        </v-tabs>
        <v-container fluid class="ma-4" v-if="loading">
          Loading...
        </v-container>
        <router-view v-else/>
      </v-col>
    </v-row>
  </v-container>
  <v-container fluid v-else-if="error">
    <v-row>
      <v-col cols="12">
        <v-alert
            color="error"
            class="ma-4"
        >
        {{ error }}
        </v-alert>
      </v-col>
    </v-row>
  </v-container>
  <v-container fluid v-else>
    Loading...
  </v-container>
</template>

<script>
import VFieldValue from "@/components/widgets/VFieldValue";
import CopyLinkField from "@/components/widgets/CopyLinkField";

export default {
  components: {
    CopyLinkField,
    VFieldValue
  },
  data() {
    return {
      error: null,
      loading: false,
      activeTab: "",
      tabs: [
        {id: '1', name: 'Info geral', route: {name: 'venda-info'}, icon: "mdi-information-outline"},
        {id: '2', name: 'Orçamentos', route: {name: 'venda-orcamentos'}, icon: "mdi-file"},
        {id: '4', name: 'Carrinho', route: {name: 'venda-carrinho'}, icon: "mdi-cart"},
        {id: '5', name: 'Servicos', route: {name: 'venda-servicos'}, icon: "mdi-hammer"},
        {id: '6', name: 'Pagamento', route: {name: 'venda-pagamentos'}, icon: "mdi-cash"},
        {id: '7', name: 'Emissões', route: {name: 'venda-emissoes'}, icon: "mdi-printer"},
        {id: '8', name: 'Notificacões', route: {name: 'venda-notificacoes'}, icon: "mdi-bell"},
        {id: '9', name: 'RACs', route: {name: 'venda-racs'}, icon: "mdi-clipboard-edit-outline"},
      ]
    }
  },
  computed: {
    venda() {
      return this.$store.state.venda.venda;
    },
    vendaId() {
      return this.$route.params.id;
    }
  },
  async mounted() {
    this.loading = true;
    const response = await this.$store.dispatch("venda/fetchVenda", this.vendaId)
    if (response.error) {
      this.error = response.message;
      this.$root.vtoast.show({message: "Erro ao carregar vendas", color: "error"});
    }
    this.loading = false;
  }
}
</script>

