<template>
    <div>
        <v-container class="pa-10" v-if="empresa == 'error'">
            A empresa não foi encontrada. (404)
        </v-container>
        <v-container class="pa-10" v-if="typeof(empresa) == 'object'">
            <v-row>
                <v-col>
                    <div
                        class="text-h5 transition-swing"
                    >
                        Detalhes da Empresa
                    </div>
                </v-col>
                <v-spacer />
                <v-breadcrumbs :items="items" />
            </v-row>
            <v-row>
                <v-col v-if="empresa">
                    <Tabs :empresa="empresa" />
                </v-col>
            </v-row>
            <v-row sm="16" align="center">
                <div class="text-center pa-5" right>
                    <v-btn block color="green" dark @click="editarEmpresa">
                        Salvar
                    </v-btn>
                </div>
                <div class="text-center" right>
                    <v-btn block color="red" style="font-weight: bolder;" outlined dark @click="confirmDeleteEmpresa">
                        Deletar
                    </v-btn>
                </div>
            </v-row>
        </v-container>
        <v-row justify="center">
            <v-dialog
                v-model="deleteDialog"
                max-width="600px"
            >
                <v-card>
                    <v-card-title class="text-h5">
                        Deletar empresa?
                    </v-card-title>

                    <v-card-text>
                        <p> Digite <strong> {{empresa?.nome}} </strong> para confirmar. </p>
                        <v-text-field label="Nome da empresa" v-model="empresaNome"/>
                    </v-card-text>

                    <v-card-actions>
                        <v-spacer />
                        <v-btn text @click="deleteDialog = false">
                            Não
                        </v-btn>
                        <v-btn text @click="deleteEmpresa" :disabled="empresaNome != empresa?.nome">
                            Sim
                        </v-btn>
                    </v-card-actions>
                </v-card>
            </v-dialog>
        </v-row>
    </div>
</template>

<style scoped>
    .bold-text {
        font-weight: bold;
    }
</style>

<script>
import api from '@/api/api.js';
import Tabs from '@/components/Empresas/Tabs.vue';

export default {
    components: {
        Tabs
    },

    data () {
        return {
            id: this.$route.params.id,
            empresa: null,
            empresaNome: "",
            tab: 1,
            deleteDialog: false,
        }
    },

    created() {
        this.initialize();
    },

    methods: {
        initialize() {
            api.getEmpresa(this.id).then((data) => {
                this.empresa = data.data
            }).catch(e => {
                this.empresa = "error"
                console.error(e)
            });
        },

        getRawNumber(maskedValue) {
            if(!maskedValue) return null
            return maskedValue.match(/[0-9]+/g).join('')
        },

        getAllFields(formId){
            let form = document.getElementById(formId);
            if(!form) return null
            let fields = Object.values(form).reduce((obj,field) => {
                obj[field.name] = field.value;
                return obj;
            }, {});

            return fields
        },

        confirmDeleteEmpresa() {
            this.deleteDialog = true
        },

        async deleteEmpresa() {
            api.deleteEmpresa(this.id).then(() => {
                this.$router.push('/empresas')
            }).catch(e => {
                console.error(e)
            })
        },

        async editarEmpresa() {
            let infoEmpresa = this.getAllFields('info-form')
            infoEmpresa.telefone = {
                numero: infoEmpresa.telefone,
                pais: infoEmpresa.telefone_pais
            }
            infoEmpresa.cnpj = this.getRawNumber(infoEmpresa.cnpj)

            await api.putEmpresa(this.id, infoEmpresa).then(response => {
                this.$router.push({name: 'empresas'})
            }).catch(error=>{
                console.error(error)
            })
        }
    },

    computed: {
        items() {
            return [
                {
                    text: 'Home',
                    to: "home"
                },
                {
                    text: 'Empresas',
                    to: {name:"empresas"},
                    disabled: false,
                    exact:true
                },
                {
                    text: 'E' + this.empresa?.id,
                    disabled: true,
                    to: ""
                }
            ]
        }
    }
}
</script>