import {
    SET_LOADING,
    SET_USUARIOS
} from "@/store/modules/Usuario/mutation-types";
import api from "@/api/api";

const usuarioStore = {
    namespaced: true,
    state: {
        logged_user: null,
        authenticated_user: false,
        cliente: false,
        authorized_restricted_routes: [],
        loading: false,
        usuarios: [],
        id_token: null,
        pertencente: []
    },

    getters: {
        isLoggedIn(state) {
            return !!state.logged_user;
        },
        loggedUser(state) {
            return state.logged_user;
        },
        isStaffUser(state) {
            return state.logged_user && state.logged_user.is_staff;
        },
        usuarios(state) {
            return state.usuarios
        }
    },

    mutations: {
        [SET_LOADING](state, value) {
            state.loading = value;
        },
        [SET_USUARIOS](state, usuarios) {
            state.usuarios = usuarios;
        },
        SET_LOGGED_USER(state, user) {
            state.logged_user = user;
            if(!user) return

            if(user.authorized_restricted_routes) 
                state.authorized_restricted_routes = user.authorized_restricted_routes
            if(user.id_token)
                state.id_token = user.id_token
            if(user.cliente) state.cliente = true
            if(user.pertencente) state.pertencente = user.pertencente
        },
        SET_AUTHENTICATED_USER(state, is_authenticated) {
            state.authenticated_user = is_authenticated;
        },
    },
    actions: {
        async whoami({commit}) {
            commit(SET_LOADING, true);
            await api.whoami().then((response) => {
                commit(SET_LOADING, false);
                if (response.data.authenticated) {
                    commit("SET_LOGGED_USER", response.data.user);
                } else {
                    commit("SET_LOGGED_USER", null);
                }
            }).catch((error) => {
                commit(SET_LOADING, false);
                commit("SET_LOGGED_USER", null);
                throw error;
            });

        },

        async login({commit, state}, {username, password}) {
            commit(SET_LOADING, true);
            const response = await api.login(username, password).catch(error => {
                commit(SET_LOADING, false);
                throw error;
            });
            commit(SET_LOADING, false);
            if (response.data.authenticated) {
                commit("SET_AUTHENTICATED_USER", response.data.authenticated);
            }
        },

        async login_otp({commit, state}, {otp}) {
            commit(SET_LOADING, true);
            const response = await api.login_otp(otp).catch(error => {
                commit(SET_LOADING, false);
                throw error;
            });
            commit(SET_LOADING, false);
            if (response.data.authenticated) {
                commit("SET_LOGGED_USER", response.data.user);
            }
        },

        async logout({commit, state}) {
            commit(SET_LOADING, true);
            const response = await api.logout().catch(error => {
                commit(SET_LOADING, false);
                commit("SET_LOGGED_USER", null);
                throw error;
            });
            commit(SET_LOADING, false);
            commit("SET_LOGGED_USER", null);

        }
        ,
        async fetchUsuarios({commit, state}) {
            commit(SET_LOADING, true);
            await api.getUsuarios().then(res => {
                const usuarios = res.data;
                commit(SET_LOADING, false);
                commit(SET_USUARIOS, usuarios);
            })
        }
    },
}
export default usuarioStore;
