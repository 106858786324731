<template>
  <v-container class="pa-4" fluid>
    <v-row>
      <v-col>
        <div class="text-h5 transition-swing">RAC</div>
      </v-col>
      <v-spacer></v-spacer>
    </v-row>
    <v-row>
      <v-col cols="12">
        <v-tabs v-model="activeTab">
          <v-tabs-slider color="primary"></v-tabs-slider>
          <v-tab v-for="tab in tabs" :key="tab.name" :to="tab.route" exact-path>
            <v-icon v-if="tab.icon" class="pr-2">{{ tab.icon }}</v-icon>
            {{ tab.name }}
            <v-badge v-if="tab.badge" class="pl-1 pb-1" color="red"></v-badge>
          </v-tab>
        </v-tabs>
        <v-container fluid class="ma-4" v-if="loading">
          Loading...
        </v-container>
        <router-view />
      </v-col>
    </v-row>
  </v-container>
</template>
    <script>
import VLinkTextField from "@/components/widgets/VLinkTextField";

export default {
  components: {
    VLinkTextField,
  },
  data: () => ({
    error: null,
    loading: false,
    activeTab: "",
    tabs: [
      {
        name: "Solicitada",
        route: { name: "rac-aberta" },
        icon: "mdi-circle-outline",
      },
      {
        name: "Liberada",
        route: { name: "rac-em-processo" },
        icon: "mdi-circle-half-full",
      },
      {
        name: "Concluída",
        route: { name: "rac-finalizada" },
        icon: "mdi-circle",
      },
    ],
  }),
};
</script>
