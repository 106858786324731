import { get, post, put, paginate, filter} from '../modules'

export default {
      getOrdensPagamentos(page = 1, status = null){
        const paginatedUrl = paginate('/api/ordem-pagamento', page);
        let filteredUrl = paginatedUrl;
    
        if (status) {
          filteredUrl += `&status=${status}`;
        }
    
        return get(filteredUrl);
      },

      getOrdensPagamentosAbertos(page = 1, status = 'aberto', filters=[]){
        const paginatedUrl = paginate('/api/ordem-pagamento', page);
        let filteredUrl = filter(paginatedUrl, filters);
    
        if (status) {
          filteredUrl += `&status=${status}`;
        }
    
        return get(filteredUrl);
      },

      getOrdensPagamentosPagos(page = 1, status = 'pago', filters=[]) {
        const paginatedUrl = paginate('/api/ordem-pagamento', page);
        let filteredUrl = filter(paginatedUrl, filters);
    
        if (status) {
          filteredUrl += `&status=${status}`;
        }
    
        return get(filteredUrl);
      },

      postOrdemPagamento(data){
        return post("/api/ordem-pagamento/", data);
      },

      postOrdemPagamentoStatus(id, data){
        return post(`/api/ordem-pagamento/${id}/alter_status/`, data);
      },

      putUpdateCotacao(id, data){
        return put(`/api/ordem-pagamento/${id}/update_cotacao_op/`, data);
      },

      putChangeCard(id, data){
        return put(`/api/ordem-pagamento/${id}/change_card/`, data)
      },
      
      putChangeIncentivo(id, data){
        return put(`/api/ordem-pagamento/${id}/change_incentivo/`, data)
      }

}

