<template>
  <v-container fluid class="pa-0">
    <v-row class="pa-0">
      <v-col>
        <FormDatePicker
          v-model="form.data"
          :dense="false"
          @input="input"
          :label="label"
          outlined
          appendIcon="mdi-clock"
          @toggleShowHoraMinuto="showHoraMinuto = !showHoraMinuto"
          :clearable="false"
        />
      </v-col>

      <v-col v-if="showHoraMinuto"
        ><v-flex>
          <v-dialog
            ref="dialog"
            v-model="watchModal"
            :return-value.sync="form.horaMinuto"
            lazy
            full-width
            width="290px"
          >
            <template v-slot:activator="{ on }">
              <v-text-field
                outlined
                v-model="form.horaMinuto"
                label="Hora"
                readonly
                v-on="on"
              >
                <template v-slot:append>
                  <v-btn small icon @click="removeHour">
                    <v-icon>mdi-close</v-icon>
                  </v-btn>
                </template></v-text-field
              >
            </template>

            <v-time-picker
              v-if="watchModal"
              v-model="form.horaMinuto"
              format="24hr"
              full-width
            >
              <v-spacer></v-spacer>
              <v-btn text color="primary" @click="watchModal = false"
                >Cancel</v-btn
              >
              <v-btn text color="primary" @click="saveInput">OK</v-btn>
            </v-time-picker>
          </v-dialog>
          {{ form.horaMinuto }}
        </v-flex>
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
import FormDatePicker from "@/components/widgets/FormDatePicker";
import moment from "moment";

export default {
  components: { FormDatePicker },
  props: {
    value: {
      type: [Date, String],
      required: false,
      default: function () {
        return new Date();
      },
    },
    initialDataAgora: {
      type: Boolean,
      required: false,
      default: false,
    },
    dense: {
      type: Boolean,
      default: false,
    },
    label: {
      type: String,
    },
    mostraHoraMinuto: {
      type: Boolean,
      default: false,
    },
    readonly: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      showHoraMinuto: this.mostraHoraMinuto,
      regraData: [(v) => v.length === 10 || "DD/MM/AAAA"],
      regraHoraMinuto: [(v) => v.length === 5 || "HH:MM"],
      form: {
        data: "",
        horaMinuto: "21:00",
      },
      watchModal: false,
    };
  },
  created() {
    if (!this.value && !this.initialDataAgora) {
      return;
    }
    let datetime = this.value ? this.value : new Date();
    if (typeof datetime === "string" || datetime instanceof String) {
      datetime = new Date(datetime);
    }
    if (datetime instanceof Date) {
      this.form.data = new Date(datetime);
      this.form.horaMinuto = this.$options.filters.dataHoraMinuto(datetime);
    }
  },
  computed: {
    dataHora() {
      const parsedDateString = this.form.data;
      if (!parsedDateString) return null;
      const dateFormat = "DD/MM/YYYY";
      const dateObject = moment.utc(parsedDateString, dateFormat);
      if (!dateObject.isValid()) return null;

      const timeString = this.form.horaMinuto;
      if (
        timeString.length !== 5 ||
        !/^([0-1][0-9]|[2][0-3]):([0-5][0-9])$/.test(timeString)
      )
        return null;
      let [hour, minute] = timeString.split(":");
      dateObject.hour(hour).minute(minute);
      return dateObject.utc().toDate();
    },
  },
  methods: {
    removeHour() {
      this.showHoraMinuto = false;
      this.input();
    },
    input(event) {
      const datetime = this.dataHora;
      this.$emit("input", datetime);
    },
    saveInput() {
      this.$refs.dialog.save(this.form.horaMinuto);
      this.input();
    },
  },
};
</script>
