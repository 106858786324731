<template>
  <v-dialog v-model="value" max-width="800px">
    <v-card>
      <v-card-title>Fornecedores</v-card-title>
      <v-card-text>
        <v-skeleton-loader
          class="mx-auto"
          width="max"
          type="article"
          v-if="loading"
        ></v-skeleton-loader>
        <v-container
          v-else
          v-for="(fornecedor, index) in fornecedores"
          :key="index"
        >
          <div v-if="fornecedor">
            <v-row># {{ index + 1 }}</v-row>
            <v-row>Nome: {{ fornecedor.nome }}</v-row>
            <v-row>CNPJ: {{ fornecedor.cnpj }}</v-row>
            <v-row>CPF: {{ fornecedor.cpf }}</v-row>
            <v-row>Email: {{ fornecedor.email }}</v-row>
            <v-row>Telefone: {{ fornecedor.telefone }}</v-row>
          </div>
        </v-container>
      </v-card-text>
      <v-card-text v-if="fornecedores.length == 0">
        Nenhum fornecedor associado.
      </v-card-text>
      <v-card-actions>
        <v-btn color="blue darken-1" text @click="close"> Fechar </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>
export default {
  props: {
    value: {
      type: Boolean,
      default: false,
    },
    fornecedores: {
      type: Array,
      default: () => [],
    },
    loading: {
      type: Boolean,
      default: false,
    },
  },

  methods: {
    close() {
      this.$emit("input", false);
    },
  },
};
</script>
