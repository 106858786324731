<template>
  <v-container class="pa-4" fluid>
    <v-row>
      <v-col>
        <div
            class="text-h5 transition-swing"
        >
          Detalhe da venda
        </div>
      </v-col>
      <v-spacer></v-spacer>
      <v-breadcrumbs :items="items"></v-breadcrumbs>
    </v-row>
    <v-row>
      <v-col>
        <venda-detalhe></venda-detalhe>
      </v-col>
    </v-row>
      <Chat :vendaID="venda ? venda.id: 0"></Chat>
  </v-container>
</template>
<script>
import VendaDetalhe from "@/components/venda/VendaDetalhe";
import Chat from "@/components/widgets/Chat";

export default {
  components: {VendaDetalhe, Chat},
  data() {
    return {};
  },
  computed: {
    venda() {
      return this.$store.state.venda.venda;
    },
    items() {
      const id = !this.venda ? null : this.venda.id
      return [
        {
          text: 'Home',
          to: "home"
        },
        {
          text: 'Vendas',
          to: {name: "vendas"},
          disabled: false,
          exact: true
        },
        {
          text: `Venda #${this.$options.filters.formatVendaId(id)}`,
          disabled: true,
          to: ""
        }
      ]
    }
  }
};

</script>
