<template>
  <div>
    <v-card v-if="chatConnected && messages != null">
      <v-card-title>
        <v-icon left> mdi-chat </v-icon>
        Chat da venda
      </v-card-title>

      <v-card-text>
        <v-list style="height: 250px; overflow-y: scroll" id="chat-view">
          <template v-for="msg in messages">
            <v-list-item>
              <v-list-item-avatar>
                <v-avatar color="green" size="36">
                  {{ iniciaisNome(msg.created_by) }}
                </v-avatar>
              </v-list-item-avatar>

              <v-list-item-content>
                <v-list-item-title>
                  <strong>{{ nomeCompleto(msg.created_by) }}</strong>
                </v-list-item-title>
                <v-list-item-subtitle>
                  às {{ msg.created_at | dataHoraMinuto }}
                  {{ msg.created_at | convertDataBR }}
                </v-list-item-subtitle>
                <v-list-item-title>
                  <p style="white-space: normal">
                    {{ msg.message }}
                  </p>
                </v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </template>
        </v-list>
        <v-dialog
          transition="dialog-bottom-transition"
          max-width="600"
          v-model="dialogArquivo"
        >
          <v-card>
            <v-toolbar color="primary" dark>Enviar anexo</v-toolbar>
            <v-card-text pd="10">
              <v-form>
                <v-col style="padding-top: 5vh">
                  <v-row>
                    <v-file-input
                      name="arquivo"
                      label="Arquivo"
                      prepend-icon=""
                      v-model="loadedFile"
                      accept="image/*, application/pdf"
                      prepend-inner-icon="mdi-paperclip"
                      outlined
                    />
                  </v-row>
                  <v-row>
                    <v-text-field
                      outlined
                      prepend-inner-icon="mdi-pencil"
                      label="Mensagem"
                    ></v-text-field>
                  </v-row>
                </v-col>
              </v-form>
            </v-card-text>
            <v-card-actions class="justify-end">
              <v-btn text @click="dialogArquivo = false">Cancelar</v-btn>
              <v-btn text> Enviar </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-card-text>

      <v-card-actions>
        <v-col>
          <v-row>
            <v-text-field
              justify-content="center"
              outlined
              label="Mensagem"
              v-model="messageModel"
              v-on:keyup.enter="sendMessage"
            >
              <template slot="append">
                <v-icon @click="sendMessage"> mdi-send </v-icon>
              </template>
              <template slot="prepend-inner">
                <v-icon @click="emojiPicker = !emojiPicker" size="20">
                  mdi-emoticon
                </v-icon>
              </template>
            </v-text-field>

            <VEmojiPicker
              @click="watchClick"
              @select="selectEmoji"
              @blur="emojiPicker = !emojiPicker"
              style="position: absolute"
              v-if="emojiPicker"
            />
          </v-row>
        </v-col>
      </v-card-actions>
    </v-card>

    <v-card v-else-if="!chatConnected"> Conectando ao chat... </v-card>
    <v-card v-else-if="messages === null">
      Carregando histórico de mensagens...
    </v-card>
  </div>
</template>

<script>
import { VEmojiPicker } from "v-emoji-picker";
import api from "@/api/api";

export default {

  components: { VEmojiPicker },

  props: {
    vendaID:{
      type: Number,
    }
  },

  data() {
    return {
      messageModel: "",
      chatSocket: null,
      messages: null,
      chatConnected: true,
      emojiPicker: false,
      dialogArquivo: false,
      loadedFile: null,
      polling: null,
    };
  },

  watch: {
    messages: {
      immediate: true,
      handler(newMessages) {
        this.scrollToBottom();
      },
    },
  },

  async created() {
    if(process.env.NODE_ENV === 'development') return 
    this.polling = setInterval(() => this.loadMessages(), 2000);
    this.scrollToBottom();
  },

  beforeDestroy() {
    clearInterval(this.polling);
  },

  methods: {
    async loadMessages() {
      let msgs = await this.$store.dispatch(
        "venda/fetchChatMessages",
        this.vendaID
      );
      if (!this.messages) {
        this.messages = msgs;
      } else if (this.messages.length < msgs.length) {
        this.messages = msgs;
        this.scrollToBottom();
      }
    },

    windowOnFocus(event) {
      this.startTimer();
    },
    windowOnBlur(event) {
      this.stopTimer();
    },

    startTimer() {
      this.loadChatMessages = true;
    },
    stopTimer() {
      this.loadChatMessages = false;
    },

    async sendMessage() {
      if (this.messageModel.trim().length == 0) return;
      let message = this.messageModel;
      this.messageModel = "";

      await api.sendChatMessage(this.vendaID, {
        message,
      });

      this.scrollToBottom();
    },

    nomeCompleto(sender) {
      let nome = sender.nome;
      let sobrenome = sender.sobrenome;
      return nome + " " + sobrenome;
    },

    iniciaisNome(sender) {
      let nome = sender.nome;
      let sobrenome = sender.sobrenome;
      return nome.charAt(0) + sobrenome.charAt(0);
    },

    scrollToBottom() {
      let chat = document.getElementById("chat-view");
      if (!chat || !chat.lastChild) return;
      chat.lastChild.scrollIntoView({ behavior: "smooth" });
    },

    selectEmoji(emoji) {
      this.emojiPicker = false;
      this.messageModel += emoji.data;
    },

  },
};
</script>
