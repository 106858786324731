<template>
  <v-dialog v-model="dialog" max-width="500px">
    <v-card>
      <v-card-title>
        <span class="text-h5">{{ formTitle }}</span>
      </v-card-title>
      <v-card-text>
        <v-container>
          <v-row>
            <v-col>
              {{ formatProgramaFidelidade(item.programa) }} -
              {{ formatCiaAerea(item.operado_por) }} [{{
                item.classe | formatClasse
              }}]
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              <v-text-field
                v-model="item.custo_proprio"
                label="Custo Proprio"
                type="number"
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              <v-text-field
                v-model="item.custo_terceiros"
                label="Custo Terceiros"
                type="number"
              ></v-text-field>
            </v-col>
          </v-row>

        </v-container>
      </v-card-text>

      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="blue darken-1" text @click="close"> Cancelar </v-btn>
        <v-btn color="blue darken-1" text @click="save"> Salvar </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>
import { PRECOS_RANGE } from "@/constants/milhas";

export default {
  props: {
    item: {
      type: Object,
      default: () => {},
    },

    newItem: {
      type: Boolean,
      default: false,
    },

    value: {
      type: Boolean,
      default: false,
    },

    formatCiaAerea: {
      type: Function,
      default: () => {},
    },

    formatProgramaFidelidade: {
      type: Function,
      default: () => {},
    },
  },

  computed: {
    formTitle() {
      return this.newItem ? "Novo Item" : "Editar Item";
    },
  },

  data: () => ({
    precosRange: PRECOS_RANGE,
    dialog: false,
  }),

  mounted() {
    this.dialog = this.value
  },

  watch:{
    dialog(val){
      this.$emit('input', val)
    },
    value(val){
      this.dialog = this.value
    }
  },

  methods: {
    save() {
      this.$emit("save");
    },
    close() {
      this.dialog = false
      this.$emit("close");
    },
  },
};
</script>
