
/*****************************************************
 O formato do objeto deve ser:
 ******************************************************/
const defaultFormCotacao = {
    subcotacoes: [{
        dados: {
            viagem: {
                trechos: []
            }
        }
    }]
}

var cotacaoPnrMixin = {
    data() {
        return {
            formCotacaoPnr: {}
        };
    },

    async mounted() {

        const resCidades = await this.$store.dispatch('global/fetchCidades').catch(err => {
            this.$root.vtoast.show({message: err.message, type: 'error'});
        });

        const resCias = await this.$store.dispatch('global/fetchCiaAereas').catch(err => {
            this.$root.vtoast.show({message: err.message, type: 'error'});
        });

        const res = await this.$store
          .dispatch("global/fetchAeroportos")
          .catch((err) => {
            this.$root.vtoast.show({
              message: "Erro ao carregar aeroportos",
              color: "error",
            });
            this.$root.vtoast.show({ message: err.message, color: "error" });
          });
    },
    computed: {

            cidades()  { 
                return this.$store.state.global.cidades;
            },

            aeroportos() {
              const aeroportos = this.$store.state.global.aeroportos;
              return aeroportos.map((aeroporto) => {
                return {
                  ...aeroporto,
                  text: `${aeroporto.codigo_iata} - ${aeroporto.nome}`,
                };
              });
            },
            
            ciaAereas(){
                return this.$store.state.global.ciaAereas; 
            }
    },

    methods: {
        /*****************************************************
         Constrói o esqueleto do form de cotação Voo:
        ******************************************************/
        buildEsqueletoFormCotacaoPnr(){
            this.formCotacaoPnr = {};
            this.formCotacaoPnr.ajuste_ativado = false;
            this.formCotacaoPnr.data_hora_cotacao = new Date();
            this.formCotacaoPnr.valor_ajuste = {value: 0, moeda: null};
            this.formCotacaoPnr.cotacao_voo = defaultFormCotacao;
            this.formCotacaoPnr.loading = false;
            this.formCotacaoPnr.loadingDeleteCotacao = false;
            this.formCotacaoPnr.loadingSalvarCotacao = false;
            this.buildEsqueletoDadosFormCotacaoPnr();

        },

        buildEsqueletoDadosFormCotacaoPnr(){
            this.formCotacaoPnr.cotacao_voo.subcotacoes[0].dados.tipo_cotacao = "pagante";
            this.formCotacaoPnr.cotacao_voo.subcotacoes[0].dados.valor_ajuste = {
                "valor": 0,
                "moeda": null
            };
            this.formCotacaoPnr.cotacao_voo.subcotacoes[0].dados.milheiro = "0.00",
            this.formCotacaoPnr.cotacao_voo.subcotacoes[0].dados.custo_bilhete = {
                "moeda": 2,
                "valor": 0,
                "valor_convertido": 0
            };
            this.formCotacaoPnr.cotacao_voo.subcotacoes[0].dados.rav = {
                "moeda": 2,
                "valor": 0,
                "valor_convertido": 0
            };
            this.formCotacaoPnr.cotacao_voo.subcotacoes[0].dados.custo_taxa_embarque = {
                "moeda": 2,
                "valor": 0,
                "valor_convertido": 0
            };
            this.formCotacaoPnr.cotacao_voo.subcotacoes[0].dados.custo_taxa_bagagem = {
                "moeda": 2,
                "valor": 0,
                "valor_convertido": 0
            };
            this.formCotacaoPnr.cotacao_voo.subcotacoes[0].dados.custo_taxas_extras = {
                "moeda": 2,
                "valor": 0,
                "valor_convertido": 0
            };
            this.formCotacaoPnr.cotacao_voo.subcotacoes[0].dados.viagem.origem = null;
            this.formCotacaoPnr.cotacao_voo.subcotacoes[0].dados.viagem.destino = null;
            this.formCotacaoPnr.cotacao_voo.subcotacoes[0].dados.viagem.tipo_trecho = "";
            this.formCotacaoPnr.cotacao_voo.subcotacoes[0].dados.viagem.ordem = 0;
        },
        converterIata2Aeroporto(iata){
            return this.aeroportos.filter((aer) => aer.aeroporto_iata === iata);
        },

        converterCiaAerea(nomeCiaAerea){
            return this.ciaAereas.filter((cia) => cia.nome === nomeCiaAerea);
        },

        convertPnr2CotacaoVooPnr(pnr, orcamento){
            this.formCotacaoPnr = {};
            this.buildEsqueletoFormCotacaoPnr();
            const adultos = orcamento.orcamento_voo.adultos;
            const criancas = orcamento.orcamento_voo.criancas;
            const bebes = orcamento.orcamento_voo.bebes;

            //Index para acessarmos o voo correto no pnr
            let indexVoo = 0;
            //Montando os trechos
            for( let i = 1; i <= pnr.qtdTrechos; i++){
                let trecho = {};
                trecho.origem = null;
                trecho.destino = null;
                trecho['tipo_trecho'] = "";
                trecho.ordem = i-1;
                trecho.pernas = [];

                //Para cada trecho, verificar quantos voos tem em cada e preencher as pernas de acordo. 
                for(let j = 1; j <= pnr.distribuicaoVoos[i]; j++){
                   let perna = {};
                   perna.classe = "executiva";
                   perna.ordem = j-1;
                   perna.adultos = adultos;
                   perna.criancas = criancas;
                   perna.bebes = bebes;
                   perna['flexibilidade_origem'] = false;
                   perna['flexibilidade_destino'] = false;
                   perna['primeira_classe'] = false;
                   perna.executiva = true;
                   perna.economica = false;

                   // Daqui pra baixo convertemos os dados do pnr
                   //Local de Origem do Voo
                   const aeroportoOrigem = this.converterIata2Aeroporto(pnr.flightData.flights[indexVoo].dep.airportcode);
                   //Caso encontremos o aeroporto de partida no BD, preenchemos
                   if(aeroportoOrigem.length > 0){
                        perna.origem = {};
                        perna.origem.id = aeroportoOrigem[0].id;
                        perna.origem.cidade = aeroportoOrigem[0].cidade;
                        perna.origem.aeroporto = aeroportoOrigem[0].aeroporto;
                        perna.origem.nome = aeroportoOrigem[0].nome;
                        perna.origem.tipo = aeroportoOrigem[0].tipo;
                        perna.origem['aeroporto_iata'] = aeroportoOrigem[0].aeroporto_iata;
                   } else { 
                    perna.origem = null;
                    }
                   //Local de chegada do Voo
                   perna.destino = null;
                   const aeroportoChegada = this.converterIata2Aeroporto(pnr.flightData.flights[indexVoo].arr.airportcode);
                   if(aeroportoChegada.length > 0) {
                        perna.destino = {};
                        perna.destino.id = aeroportoChegada[0].id;
                        perna.destino.cidade = aeroportoChegada[0].cidade;
                        perna.destino.aeroporto = aeroportoChegada[0].aeroporto;
                        perna.destino.nome = aeroportoChegada[0].nome;
                        perna.destino.tipo = aeroportoChegada[0].tipo;
                        perna.destino['aeroporto_iata'] = aeroportoChegada[0].aeroporto_iata;
                    }

                    // Datas e Horários
                    perna.data_hora_partida = new Date(pnr.flightData.flights[indexVoo].flt.departure.string);
                    perna.data_hora_chegada = new Date(pnr.flightData.flights[indexVoo].flt.arrival.string);
                    
                    // Companhia Aerea 
                    
                    const ciaAerea = this.converterCiaAerea(pnr.flightData.flights[indexVoo].flt.operated_by);
                    if(ciaAerea.length > 0){
                        perna['cia_aerea'] = ciaAerea.id;
                    } else {
                        perna['cia_aerea'] = null;
                    }
                    trecho.pernas.push(perna);
                    indexVoo++;
                }
                this.formCotacaoPnr.cotacao_voo.subcotacoes[0].dados.viagem.trechos.push(trecho);
            }
            
            //this.formCotacaoPnr.cotacao_voo.subcotacoes[0].dados.viagem.trechos.splice(0,1)
            // this.formCotacaoPnr.cotacao_voo.subcotacoes[0].dados.viagem.trechos.shift();
            return this.formCotacaoPnr;

        }


    }

}

export default cotacaoPnrMixin;

