<template>
  <v-container>
    <v-row>Cotação de Veículo</v-row>
    <v-row>Nome da empresa: {{ cotacao.nome_hotel }}</v-row>
    <v-row>Retirada: {{ cotacao.data_hora_retirada | dataHoraBR }}</v-row>
    <v-row>Devolução: {{ cotacao.data_hora_devolucao | dataHoraBR }}</v-row>
    <v-row
      >Local de retirada:
      {{ cotacao.local_retirada ? cotacao.local_retirada.nome : "" }}</v-row
    >
    <v-row
      >Local de devolução:
      {{ cotacao.local_devolucao ? cotacao.local_devolucao.nome : "" }}</v-row
    >
    <v-row>Diárias: {{ cotacao.diarias }}</v-row>
    <v-row>Tipo do vehículo: {{ cotacao.tipo_carro }}</v-row>
    <v-row
      >Custo da diária: {{ formatValorFinanceiro(cotacao.custo_diaria) }}</v-row
    >
    <v-row
      >Custo do seguro: {{ formatValorFinanceiro(cotacao.custo_seguro) }}</v-row
    >
    <v-row
      >Custos extras: {{ formatValorFinanceiro(cotacao.custo_extras) }}</v-row
    >
    <v-row>
      <v-btn link :to="orcamentoLinkTo">
        Ir para orcamento
        <v-icon color="primary" class="ml-2">mdi-open-in-new </v-icon>
      </v-btn>
    </v-row>
  </v-container>
</template>
<script>
import { formatValorFinanceiro } from "@/helpers/financeiro";

export default {
  props: {
    cotacao: {
      type: Object,
      default: () => {},
    },
    orcamentoLinkTo: {
      type: Object,
      default: () => {},
    },
  },

  methods: {
    formatValorFinanceiro(valor) {
      return formatValorFinanceiro(valor);
    },
  },
};
</script>
