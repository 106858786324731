export default [
    {
        key: "aberto",
        value: "Aberto",
        text: "black", 
        background: "green",
    },
    {
        key: "fechado",
        value: "Fechado",
        text: "white",
        background: "red",
    },
]