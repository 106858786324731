<template>
  <v-container fluid v-if="loadingGetOrcamento"> Loading... </v-container>
  <v-container fluid v-else-if="orcamento">
    <v-row>
      <p class="text pa-2 font-weight-bold">ID: # {{ orcamento.id }}</p>
      <p>
        <v-field-value label="Status">
          {{ orcamento.status | formatStatusOrcamento }}
        </v-field-value>
      </p>
      <p>
        <v-field-value label="Solicitado em">
          {{ orcamento.created_at | convertDataBR }} às
          {{ orcamento.created_at | dataHoraMinuto }} -
          {{ orcamento.venda.created_at | diasAtras }} dia(s) atrás
        </v-field-value>
      </p>
      <p>
        <v-field-value label="Tipo de serviço">
          {{ orcamentoTipoServico(orcamento) }}
        </v-field-value>
      </p>
      <p class="pt-2 pl-4">
        <v-btn
          small
          color="green"
          class="white--text"
          :disabled="!orcamentoAberto"
          @click="openConfirmDIalog"
        >
          Finalizar Orçamento
        </v-btn>
      </p>
      <v-spacer />
    </v-row>
    <v-row>
      <v-divider></v-divider>
    </v-row>
    <v-row class="mt-2">
      <v-col cols="12" md="8">
        <v-row>
          <v-col>
            <div class="text title">Lista de cotações</div>
          </v-col>
          <v-spacer></v-spacer>
        </v-row>
        <v-row>
          <v-spacer />
          <v-col cols="auto">
            <v-btn
              v-if="isOrcamentoVoo && orcamento.status != 'Concluído'"
              :disabled="!orcamentoAberto"
              @click="
                () => {
                  keyPnr++;
                  showDialogNovaCotacaoPNR = true;
                }
              "
              color="primary"
            >
              <v-icon>mdi-plus</v-icon>
              Cotação PNR
            </v-btn>
            <v-btn
              :disabled="!orcamentoAberto"
              @click="showDialogNovaCotacao = true"
              color="primary"
              v-if="orcamento.status != 'Concluído'"
            >
              <v-icon>mdi-plus</v-icon>
              Cotação
            </v-btn>
            <v-btn
              :disabled="!orcamentoAberto"
              @click="
                if (selectedCotacoes.length) showDialogEscolhaPagamento = true;
                else showDialogExportarPdf = true;
              "
              color="secondary"
            >
              <v-icon>mdi-arrow-collapse-down</v-icon>
              Exportar
            </v-btn>
            <v-btn v-if="orcamento.tipo_servico === 'orcamento_voo'" @click="showDialogMoblix = true" color="secondary">
              <v-icon>mdi-magnify</v-icon>
              Moblix
            </v-btn>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12">
            <orcamento-cotacao-list
              :v-model="selectedCotacoes"
              @setSelectedCotacoes="setSelectedCotacoes"
              @save="cotacaoCreated"
              :loading="loadingGetOrcamentoCotacoes"
              :orcamento="orcamento"
              :cotacoes="cotacoes"
            />
          </v-col>
        </v-row>
      </v-col>
      <v-spacer> </v-spacer>
      <v-col cols="12" md="4">
        <orcamento-tab-view :orcamento="orcamento" />
      </v-col>
    </v-row>

    <!--    Dialogs  -->
    <!--    Dialog escolha pagamento -->
    <div>
      <v-dialog
        v-model="showDialogEscolhaPagamento"
        v-if="showDialogEscolhaPagamento"
      >
        <dialog-tipo-pagamento
          @exportar="exportar"
          :cotacoes="selectedCotacoes"
          :loading="pdfLoading"
          :orcamento="orcamento"
        />
      </v-dialog>
    </div>
    <!--    Dialog exportar pdf -->
    <div>
      <v-dialog v-model="showDialogExportarPdf" v-if="showDialogExportarPdf">
        <preview-new-pdf-orcamento
          :exportPdfData="exportPdf"
          :orcamento="orcamento"
          :cotacoes="selectedCotacoes"
          :tipoPagamento="tipoPagamento"
          :informacoesPagamento="informacoesPagamento"
        />
      </v-dialog>
    </div>
    <!--    Dialog nova cotacao PNR  -->
    <div>
      <v-dialog
        v-model="showDialogNovaCotacaoPNR"
        v-if="showDialogNovaCotacaoPNR"
      >
        <orcamento-cotacao-pnr-edit-item
          :key="keyPnr"
          :orcamento="orcamento"
          @create="criarCotacaoPnr"
          :showDialogNovaCotacaoPNR="showDialogNovaCotacaoPNR"
          :cancel="cancelPnrDialog"
        >
        </orcamento-cotacao-pnr-edit-item>
        <!-- <v-btn @click="showDialogNovaCotacaoPNR = false" color="primary">
         Cancelar
        </v-btn> -->
      </v-dialog>
    </div>

    <!--    Dialog nova cotacao  -->
    <div>
      <v-dialog v-model="showDialogNovaCotacao" v-if="showDialogNovaCotacao">
        <orcamento-cotacao-edit-item
          :orcamento="orcamento"
          :value="valueFormCotacaoPnr"
          @close="cancelDialogNovaCotacao"
          @save="cotacaoCreated"
          :users="users"
          :allowSave="true"
        >
        </orcamento-cotacao-edit-item>
      </v-dialog>
    </div>

    <!--   Dialog Moblix -->
    <v-dialog v-model="showDialogMoblix" v-if="showDialogMoblix">
      <busca-moblix :orcamento="orcamento" @close="" />
    </v-dialog>

    <!-- Dialog de confirmação finalização de orçamento-->
    <div>
    <v-dialog width="280px" persistent v-model="confirmDialog" transition="dialog-bottom-transition">
      <v-card style="border-radius: 10px;">
        <v-card-actions class="py-0 px-0">
          <v-container>
            <v-row>
              <v-col cols="12" class="text-center">
                Deseja realmente <strong class="red--text">finalizar</strong> o orçamento?
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="6" >
                <v-btn block color="error" style="height: 45px;" class="rounded" :disabled="loadingFinalizarOrcamento" @click="closeConfirmDialog">
                  Não
                </v-btn>
              </v-col>
              <v-col cols="6" >
                <v-btn block color="success" style="height: 45px;" class="rounded" :loading="loadingFinalizarOrcamento" @click="finalizarOrcamento(orcamentoId)">
                  Sim
                </v-btn>
              </v-col>
            </v-row>
          </v-container>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
  </v-container>
</template>
<script>
import VFieldValue from "@/components/widgets/VFieldValue";
import OrcamentoTabView from "@/components/Orcamento/TabInformacoes/OrcamentoTabView";
import OrcamentoCotacaoList from "@/components/Orcamento/OrcamentoCotacaoList";
import OrcamentoCotacaoEditItem from "@/components/cotacao/CotacaoEditItem";
import OrcamentoCotacaoPnrEditItem from "@/components/cotacao/CotacaoPnrEditItem";
import PreviewNewPdfOrcamento from "@/components/PDF/PreviewNewPdfOrcamento";
import DialogTipoPagamento from "@/components/Orcamento/DialogTipoPagamento.vue";
import BuscaMoblix from "@/components/Orcamento/Widgets/BuscaMoblix.vue";

import OrcamentoMixin from "@/mixins/OrcamentoMixin";
import api from "@/api/api";

export default {
  components: {
    OrcamentoCotacaoPnrEditItem,
    OrcamentoCotacaoEditItem,
    PreviewNewPdfOrcamento,
    OrcamentoCotacaoList,
    OrcamentoTabView,
    VFieldValue,
    DialogTipoPagamento,
    BuscaMoblix,
  },
  mixins: [OrcamentoMixin],
  data() {
    return {
      keyPnr: 0,
      valueFormCotacaoPnr: {},
      showDialogNovaCotacaoPNR: false,
      showDialogNovaCotacao: false,
      showDialogExportarPdf: false,
      showDialogMoblix: false,
      showDialogEscolhaPagamento: false,
      orcamento: null,
      cotacoes: [],
      exportPdf: null,
      selectedCotacoes: [],
      tipoPagamento: [],
      informacoesPagamento: {},
      users: null,
      pdfLoading: false,
      confirmDialog: false,
    };
  },
  computed: {
    isOrcamentoVoo() {
      return this.orcamento.tipo_servico === "orcamento_voo";
    },
    orcamentoId() {
      return this.$route.params.id;
    },
    orcamentoAberto() {
      return this.orcamento && this.orcamento.status === "aberto";
    },
    selectedCotacoesId() {
      return this.selectedCotacoes.map((cot) => cot.id);
    },
    loggedUser() {
      return this.$store.getters["usuario/loggedUser"];
    },
  },
  async mounted() {
    await this.initialize();
  },
  methods: {
    cancelDialogNovaCotacao() {
      this.showDialogNovaCotacao = false;
      this.valueFormCotacaoPnr = {};
    },
    criarCotacaoPnr(formCotacaoPnr) {
      this.cancelPnrDialog();
      this.valueFormCotacaoPnr = formCotacaoPnr;
      this.showDialogNovaCotacao = true;
    },
    cancelPnrDialog() {
      this.showDialogNovaCotacaoPNR = false;
    },

    initialize() {
      this.getOrcamento(this.orcamentoId).then((orcamento) => {
        this.orcamento = orcamento;
      });
      this.getOrcamentoCotacoes(this.orcamentoId).then((cotacoes) => {
        this.cotacoes = cotacoes;
      });
      this.exportPdf = {
        ...this.exportPdf,
        show: this.showDialogExportarPdf,
      };
      this.$store.dispatch("usuario/fetchUsuarios").then((users) => {
        this.users = this.$store.state.usuario.usuarios;
      });
      this.$store.dispatch("global/fetchMoedas");
    },
    cotacaoCreated(cotacao) {
      this.showDialogNovaCotacao = false;
      this.cotacoes.push(cotacao);
      this.valueFormCotacaoPnr = {};
    },
    setSelectedCotacoes(val) {
      this.selectedCotacoes = val;
    },
    async exportar(informacoesPagamento, valorTotal) {
      this.pdfLoading = true;

      await api
        .pdfOrcamento({
          cotacoes: this.selectedCotacoesId,
          orcamento: this.orcamento,
          user: this.loggedUser.id,
          valor_total: valorTotal,
          infoPagamento: informacoesPagamento,
        })
        .then((data) => {
          this.$root.vtoast.show({
            message: "PDF foi criado com sucesso e enviado ao seu e-mail!",
            timer: 5000,
          });
        })
        .catch((err) => {
          let msg = err.response?.data?.PDF;
          if (!msg) msg = err;

          if (err.response?.status != 400) {
            return this.$root.vtoast.showApiError({
              message: "Ocorreu um erro interno! Reporte o bug.",
              timer: 5000,
              color: "error",
            });
          }
          this.$root.vtoast.showApiError({ message: msg, color: "error" });
        })
        .finally(() => {
          this.pdfLoading = false;
          this.showDialogExportarPdf = false;
        });
    },
    openConfirmDIalog(){
      this.confirmDialog = true;
    },
    closeConfirmDialog(){
      this.confirmDialog = false;
    }
  },
};
</script>
