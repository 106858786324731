import api from "@/api/api";


var orcamentoMixin = {
    data() {
        return {
            loadingGetOrcamento: false,
            loadingFinalizarOrcamento: false,
            loadingApagarOrcamento: false,
            loadingGetOrcamentoCotacoes: false,
        }
    },
    methods: {
        ///////////////////////////////////////////////////////
        // Métodos API
        ///////////////////////////////////////////////////////
        async getOrcamento(orcamentoId) {
            this.loadingGetOrcamento = true;
            const res = await api.getOrcamento(orcamentoId).then(res => {
                return res.data
            }).catch(err => {
                this.loadingGetOrcamento = false;
                this.$root.vtoast.show({message: "Erro ao obter orçamento", color: "error"});
                this.$root.vtoast.show({message: err.message, color: "error"});
                console.error(err);
            });
            this.loadingGetOrcamento = false;
            return res;
        },
        async getOrcamentoCotacoes(orcamentoId) {
            this.loadingGetOrcamentoCotacoes = true;
            const res = await api.getOrcamentoCotacoes(orcamentoId).then(res => {
                return res.data
            }).catch(err => {
                this.loadingGetOrcamento = false;
                this.$root.vtoast.show({message: "Erro ao obter cotações", color: "error"});
                this.$root.vtoast.show({message: err.message, color: "error"});
                console.error(err);
            });
            this.loadingGetOrcamentoCotacoes = false;
            return res;
        },
        async finalizarOrcamento(orcamentoId) {
            this.loadingFinalizarOrcamento = true;
            const res = await api.finalizarOrcamento(orcamentoId).then(async res => {
                if(this.orcamento){
                    this.orcamento.status = "fechado"
                }
                else{
                    const orcamento = await this.getOrcamento(orcamentoId);
                    this.orcamento = {...orcamento};
                }
                this.$root.vtoast.show({message: "Orçamento concluído com sucesso!", color: "success"});
                this.$root.$emit("orcamentoFinalizado");
                return res.data
            }).catch(err => {
                this.loadingFinalizarOrcamento = false;
                this.$root.vtoast.showApiError({message: "Erro ao finalizar orçamento", color: "error"});
                this.$root.vtoast.showApiError({message: err.message, color: "error"});
                console.error(err);
            });
            this.loadingFinalizarOrcamento = false;
            this.confirmDialog = false;
            return res;
        },
        async apagarOrcamento(orcamentoId) {
            this.loadingApagarOrcamento = true;
            const res = await api.deleteOrcamento(orcamentoId).then(res => {
                this.$root.vtoast.show({message: "Orçamento excluído com sucesso!", color: "success"});
                return res;
            }).catch(err => {
                this.loadingApagarOrcamento = false;
                this.$root.vtoast.show({message: "Erro ao excluir orçamento", color: "error"});
                this.$root.vtoast.show({message: err.message, color: "error"});
            });
            this.loadingApagarOrcamento = false;
            return res;
        },


        ///////////////////////////////////////////////////////
        // Métodos de formatação
        ///////////////////////////////////////////////////////
        orcamentoTipoServico(orcamento) {
            // verifica se o orcamento é str
            if (orcamento.tipo_servico) {
                if (orcamento.tipo_servico === 'orcamento_hotel')
                    return 'Hotel';
                else if (orcamento.tipo_servico === 'orcamento_voo')
                    return 'Aéreo';
                else if (orcamento.tipo_servico === 'orcamento_carro')
                    return 'Aluguel de veículo';
                else if (orcamento.tipo_servico === 'orcamento_outro')
                    return 'Outros';
            }
            if (!orcamento) return "";
            if (orcamento.orcamento_hotel)
                return 'Hotel';
            else if (orcamento.orcamento_voo)
                return 'Aéreo';
            else if (orcamento.orcamento_carro)
                return 'Aluguel de veículo';
            else if (orcamento.orcamento_outro)
                return 'Outros';
            else
                return '';
        }
    }
}


export default orcamentoMixin;
