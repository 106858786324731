<template>
  <v-container class="ma-4 pr-8" fluid>
    <v-row>
      <v-col>
        <div class="text-h5 transition-swing">Vendas</div>
        <v-breadcrumbs :items="breadcumb_items"></v-breadcrumbs>
      </v-col>
      <v-col align-self="end" cols="12" md="auto">
        <v-btn
          :disabled="loadingTable"
          @click="dialogSave = true"
          color="primary"
          class="mb-2"
        >
          + Venda
        </v-btn>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12">
        <v-container fluid>
          <v-row>
            <v-col cols="12">
              <v-card
                rounded="10"
                :loading="loadingTable"
                raised
                elevation="10"
              >
                <v-card-text>
                  <v-data-table
                    dense
                    :headers="headers"
                    :items="vendas"
                    sort-by="id"
                    sort-desc
                    @click:row="rowClick"
                    :hide-default-footer="true"
                    :items-per-page="15"
                  >
                    >
                    <template v-slot:top>
                      <v-container fluid>
                        <v-row v-if="loadingTable === false">
                          <v-col cols="12" md="2">
                            <v-autocomplete
                              clear-icon="mdi-close"
                              clearable
                              outlined
                              v-model="filter.consultor"
                              label="Consultor"
                              :item-text="
                                (item) => `${item.nome} ${item.sobrenome}`
                              "
                              :item-value="(item) => item.id"
                              :items="users"
                              dense
                            >
                            </v-autocomplete>
                          </v-col>
                          <v-col cols="12" md="2">
                            <v-select
                              clear-icon="mdi-close"
                              clearable
                              outlined
                              v-model="filter.status"
                              label="Status"
                              :items="statusVenda"
                              item-text="value"
                              item-value="key"
                              dense
                            >
                            </v-select>
                          </v-col>
                          <v-col cols="12" md="2">
                            <v-select
                              clear-icon="mdi-close"
                              clearable
                              outlined
                              v-model="filter.cliente"
                              label="Tipo Cliente"
                              :items="tiposCliente"
                              item-text="value"
                              item-value="key"
                              dense
                            >
                            </v-select>
                          </v-col>
                          <v-col cols="12" md="2">
                            <v-text-field
                              dense
                              outlined
                              clearable
                              clear-icon="mdi-close"
                              append-icon="mdi-magnify"
                              label="ID da Venda"
                              v-model="search.id_venda"
                            />
                          </v-col>

                          <v-col cols="12" md="2">
                            <v-select
                              clear-icon="mdi-close"
                              clearable
                              outlined
                              v-model="filter.tipos_orcamento"
                              label="Tipo de Serviço"
                              :items="tipoOrcamento"
                              item-text="value"
                              item-value="key"
                              dense
                            >
                            </v-select>
                          </v-col>
                        </v-row>
                      </v-container>
                    </template>
                    <template v-slot:item.clienteOuEmpresa="{ item }">
                      {{
                        item.cliente
                          ? `${item.cliente.nome} ${item.cliente.sobrenome}`
                          : ""
                      }}
                      {{
                        item.cliente_empresa
                          ? `${item.cliente_empresa.nome}`
                          : ""
                      }}
                    </template>
                    <template v-slot:item.indicacao="{ item, value }">
                      {{
                        (item.cliente_empresa
                          ? item.cliente_empresa.indicacao
                          : null) | nomeSobrenome
                      }}
                      {{
                        (item.cliente ? item.cliente.indicacao : null)
                          | nomeSobrenome
                      }}
                    </template>
                    <template v-slot:item.consultor="{ item, value }">
                      {{ value ? `${value.nome} ${value.sobrenome}` : "" }}
                    </template>
                    <template v-slot:item.status="{ item }">
                      <status-venda :status="item.status" />
                    </template>
                    <template v-slot:item.tipos_servicos="{ item, value }">
                      {{ item.tipos_servicos | formatTiposOrcamento }}
                    </template>
                    <template v-slot:item.last_edited_by="{ item, value }">
                      {{ item.last_edited_by | nomeSobrenome }}
                    </template>
                    <template v-slot:item.plataforma="{ item, value }">
                      {{ item.created_by.cliente ? "📱 MOBILE" : "💻 WEB"}}
                    </template>
                    <template v-slot:no-data>
                      Nenhuma venda encontrada
                    </template>
                  </v-data-table>
                  <v-pagination
                    v-model="currentPage"
                    :length="numPages"
                    :total-visible="7"
                  ></v-pagination>
                </v-card-text>
              </v-card>
            </v-col>
          </v-row>
        </v-container>
      </v-col>
    </v-row>
    <v-dialog v-model="dialogSave" :persistent="true" max-width="auto">
      <v-card class="pa-3">
        <v-card-title>
          <span class="text-h5">Nova venda</span>
        </v-card-title>
        <v-card-text>
          <venda-edit-form
            :key="newVendaKey"
            :isNewVenda="true"
            @save="save"
            @cancel="dialogSave = false"
          />
        </v-card-text>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import VendaEditForm from "@/components/venda/VendaEditForm";
import StatusVenda from "@/components/widgets/StatusVenda";
import STATUS_VENDA from "@/constants/statusVenda";
import api from "@/api/api.js";
import TIPO_ORCAMENTO from "@/constants/tipoOrcamento";

export default {
  components: { VendaEditForm, StatusVenda },
  data() {
    return {
      loadingSaving: false,
      loadingTable: true,
      userModel: null,
      statusVenda: STATUS_VENDA,
      tipoOrcamento: TIPO_ORCAMENTO,
      tiposCliente: ["Contato", "Empresa"],
      paraEmpresa: false,
      breadcumb_items: [
        {
          text: "Home",
          to: "home",
        },
        {
          text: "Venda",
          to: "vendas",
        },
      ],
      newVendaKey: 0,
      dialogSave: false,
      dialogChoice: false,
      filter: {
        status: "",
        consultor: "",
        cliente: "",
        id_venda: "",
        tipos_servicos: "",
      },
      search: {
        id_venda: "",
      },
      headers: [
        {
          text: "ID",
          align: "start",
          sortable: true,
          value: "id",
        },
        { text: "Status", value: "status" },
        { text: "Cliente", value: "clienteOuEmpresa" },
        { text: "Indicação", value: "indicacao" },
        { text: "# Orçamentos Ativos", value: "num_orcamentos_ativos" },
        { text: "# Cotações em Carrinho", value: "num_cotacoes_carrinho" },
        { text: "# Emissões na fila", value: "num_emissoes_fila" },
        { text: "# Emissões Finalizadas", value: "num_emissoes_finalizadas" },
        { text: "# Servicos em Validação", value: "num_servicos_validacao" },
        { text: "Tipos de Servicos", value: "tipos_servicos" },
        { text: "Última alteração", value: "last_edited_by" },
        { text: "Plataforma", value: "plataforma" },
      ],
      vendas: [],
      pressingCtrl: false,
      currentPage: 1,
      numPages: 6,
    };
  },
  computed: {
    loggedUser() {
      return this.$store.getters["usuario/loggedUser"];
    },
    users() {
      return this.$store.getters["usuario/usuarios"];
    },
  },

  watch: {
    dialog(val) {
      val || this.close();
    },
    currentPage(val) {
      this.changePage();
    },
    "search.id_venda": {
      handler() {
        clearTimeout(this.timer);

        this.timer = setTimeout(() => {
          this.filter.id_venda = this.search.id_venda;
        }, 1000);
      },
    },
    filter: {
      deep: true,
      handler(val) {
        let filters = [];
        if (this.filter.consultor)
          filters.push(["consultor", this.filter.consultor]);
        if (this.filter.status) filters.push(["status", this.filter.status]);
        if (this.filter.cliente) filters.push(["cliente", this.filter.cliente]);
        if (this.filter.id_venda)
          filters.push(["id_venda", this.filter.id_venda]);
        if (this.filter.tipos_orcamento)
          filters.push(["tipos_orcamento", this.filter.tipos_orcamento]);

        this.currentPage = 1;

        api.getVendasDetalhado(this.currentPage, filters).then((res) => {
          this.vendas = res.data.vendas;
          this.numPages = res.data.pages;
          this.loadingTable = false;
        });
      },
    },
  },

  created() {
    this.initialize();
  },

  methods: {
    changePressingCtrlState(e) {
      if (e.key != "Control") return;
      this.pressingCtrl = e.type == "keydown" ? true : false;
    },

    changePage() {
      let filters = [];
      if (this.filter.consultor)
        filters.push(["consultor", this.filter.consultor]);
      if (this.filter.status) filters.push(["status", this.filter.status]);
      if (this.filter.cliente) filters.push(["cliente", this.filter.cliente]);
      if (this.filter.id_venda)
        filters.push(["id_venda", this.filter.id_venda]);
      if (this.filter.tipos_orcamento)
        filters.push(["tipos_orcamento", this.filter.tipos_orcamento]);

      this.loadingTable = true;
      api.getVendasDetalhado(this.currentPage, filters).then((res) => {
        this.vendas = res.data.vendas;
        this.loadingTable = false;
      });
    },

    initialize() {
      this.loadingTable = true;
      api.getVendasDetalhado().then((res) => {
        this.vendas = res.data.vendas;
        this.numPages = res.data.pages;
        this.loadingTable = false;
      });

      this.$store.dispatch("usuario/fetchUsuarios");
      document.onkeydown = (e) => this.changePressingCtrlState(e);
      document.onkeyup = (e) => this.changePressingCtrlState(e);
    },

    choiceEmpresa() {
      this.paraEmpresa = true;
      this.dialogChoice = false;
      this.dialogSave = true;
    },
    choiceCliente() {
      this.paraEmpresa = false;
      this.dialogChoice = false;
      this.dialogSave = true;
    },

    refreshCreateVendaForm() {
      this.newVendaKey += 1;
    },
    close() {
      this.dialogSave = false;
    },
    rowClick(e) {
      if (this.pressingCtrl) return window.open(`/vendas/${e.id}/info/`);
      this.$router.push({ name: "venda-info", params: { id: e.id } });
    },
    save(venda) {
      this.loadingSaving = true;
      api
        .createVenda(venda)
        .then((res) => {
          this.dialogSave = false;
          this.refreshCreateVendaForm();
          this.$root.vtoast.show({ message: "Venda criada com sucesso!" });
          this.loadingSaving = false;
          this.initialize();
          this.close();
        })
        .catch((err) => {
          this.$root.vtoast.show({ message: err.data && err.data.detail });
          this.loadingSaving = false;
        });
      this.loadingSaving = false;
    },
  },
};
</script>


