import { get, post, put, paginate, filter, convertToFormData } from '../modules'

export default {
    getRAC(){
        return get('/api/rac/')
    },

    getRACDetalhado(page, filters=[]) {
        const paginatedURL = paginate("api/rac/detailed/", page)
        const filteredURL = filter(paginatedURL, filters)
        let url = "/api/rac/detailed/?page="+page
        filters.forEach( filter => {
            url += `&${filter[0]}=${filter[1]}`
        })

        return get(url)
    },

    getRACDetalhadoAberta(page, filters=[]) {
        const paginatedURL = paginate("api/rac/detailedAberta/", page)
        const filteredURL = filter(paginatedURL, filters)
        let url = "/api/rac/detailedAberta/?page="+page
        filters.forEach( filter => {
            url += `&${filter[0]}=${filter[1]}`
        })

        return get(url)
    },

    getRACDetalhadoEmProcesso(page, filters=[]) {
        const paginatedURL = paginate("api/rac/detailedEmProcesso/", page)
        const filteredURL = filter(paginatedURL, filters)
        let url = "/api/rac/detailedEmProcesso/?page="+page
        filters.forEach( filter => {
            url += `&${filter[0]}=${filter[1]}`
        })

        return get(url)
    },

    getRACDetalhadoFinalizada(page, filters=[]) {
        const paginatedURL = paginate("api/rac/detailedFinalizada/", page)
        const filteredURL = filter(paginatedURL, filters)
        let url = "/api/rac/detailedFinalizada/?page="+page
        filters.forEach( filter => {
            url += `&${filter[0]}=${filter[1]}`
        })

        return get(url)
    },

    addRACPagamentos(data){
        return post(`/api/rac/pagamentos/`, data)
    },

    // Documentos 
    getRACDocumentos(id) {
        return get(`/api/rac/${id}/documentos/`)
    },

    postRACDocumento(id, data) {
        const formData = convertToFormData(data)
        return post(`/api/rac/${id}/documentos/`, formData)
    },

    // Protocolos 
    getRACProtocolos(id) {
        return get(`/api/rac/${id}/protocolos/`)
    },
    postRACProtocolo(id, data) {
        return post(`/api/rac/${id}/protocolos/`, data)
    },

    // Serviços
    getRACServicos(id) {
        return get(`/api/rac/${id}/servicos/`)
    },
    putRACServico(id, data) {
        return put(`/api/rac/${id}/servicos/`, data)
    },

    //Status da Rac
    postStatusRac(id, data) {
        return post(`/api/rac/${id}/rac_status_post/`, data)
    },

}

