<template>
  <div>
    <v-container class="ma-4" fluid>
      <v-row>
        <v-col>
          <div
              class="text-h5 transition-swing"
          >
            Orçamentos
          </div>
          <v-breadcrumbs :items="breadcumb_items"></v-breadcrumbs>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12">
          <v-container fluid>
            <orcamentos-list/>
          </v-container>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>
<script>

import OrcamentosList from "@/components/Orcamento/OrcamentoList";
import VendaOrcamentosList from "@/components/venda/VendaOrcamentosList";

export default {
  components: {VendaOrcamentosList, OrcamentosList},
  data() {
    return {
      breadcumb_items: [
        {
          text: 'Home',
          to: {
            name: "home",
          },
          exact: true
        },
        {
          text: 'Orcamentos',
          to: {
            name: "orcamentos"
          }
        }
      ]
    }
  },
}
;
</script>


