import api from "@/api/api";

const globalStore = {
    namespaced: true,
    state: {
        usuarios: [],
        contatos: [],
        locais: [],
        aeroportos: [],
        cidades: [],
        moedas: [],
        programas: [],
        ciaAereas: [],
        milhas: [],
        loading: false,
        empresas: [],
        fornecedores: [],
        provedores: [],
        contatosNumPages: 1
    },
    getters: {
        moedas: state => state.moedas,
        programas: state => state.programas,
        fornecedores: state => state.fornecedores,
    },
    mutations: {
        setValue(state, { key, value }) {
            state[key] = value;
        },

        setMoedas(state, moedas) {
            state.moedas = moedas;
        },

        setMilhas(state, milhas){
            state.milhas = milhas
        }
    },

    actions: {
        async fetchCidades({commit, state}) {
            if (state.cidades.length > 0) return;

            commit("setValue", {key: "loading", value: true});

            return await api.getCidades().then(res => {
                commit("setValue", {key: "loading", value: false});
                commit("setValue", {key: "cidades", value: res.data});
                return res;
            }).catch(err => {
                commit("setValue", {key: "loading", value: false});
                throw err;
            });
        },
        async fetchMoedas({commit, state}) {
            // if (state.moedas.length > 0) return

            commit("setValue", {key: "loading", value: true});

            return await api.getMoedas().then(res => {
                commit("setValue", {key: "loading", value: false});
                commit("setValue", {key: "moedas", value: res.data});
                return res;
            }).catch(err => {
                commit("setValue", {key: "loading", value: false});
                throw err;
            });
        },

        async fetchContatosPaginated({commit, state}, pagination) {
            commit("setValue", {key: "loading", value: true});

            const page = pagination.page || 1
            const filters = pagination.filters || []
            
            return await api.getContatos(page, filters).then(res => {
                commit("setValue", {key: "loading", value: false});
                commit("setValue", {key: "contatos", value: res.data.contatos});
                commit("setValue", {key: "contatosNumPages", value: res.data.pages});
                return res;
            }).catch(err => {
                commit("setValue", {key: "loading", value: false});
                throw err;
            })
        },

        async fetchContatos({commit, state}) {
            commit("setValue", {key: "loading", value: true});
            
            return await api.getContatos(0).then(res => {
                commit("setValue", {key: "loading", value: false});
                commit("setValue", {key: "contatos", value: res.data});
                return res;
            }).catch(err => {
                commit("setValue", {key: "loading", value: false});
                throw err;
            })
        },

        async fetchEmpresas({commit, state}) {
            commit("setValue", {key: "loading", value: true});
            
            return await api.getEmpresas(0).then(res => {
                commit("setValue", {key: "loading", value: false});
                commit("setValue", {key: "empresas", value: res.data});
                return res;
            }).catch(err => {
                commit("setValue", {key: "loading", value: false});
                throw err;
            })
        },

        async fetchAeroportos({commit, state}) {
            if (state.aeroportos.length > 0) return

            commit("setValue", {key: "loading", value: true});

            let error = false;
            const res = await api.getAeroportos().then(res => {
                if (res.status === 200) commit("setValue", {key: "aeroportos", value: res.data});
                commit("setValue", {key: "loading", value: false});
                return res
            }).catch(err => {
                error = true;
                commit("setValue", {key: "loading", value: false});
                return err;
            });
        },

        async fetchProgramas({commit, state}) {
            if(state.programas.length > 0) return

            commit("setValue", {key: "loading", value: true});
            return await api.getProgramas().then(res => {
                commit("setValue", {key: "loading", value: false});
                commit("setValue", {key: "programas", value: res.data});
                return res;
            }).catch(err => {
                commit("setValue", {key: "loading", value: false});
                throw err;
            })
        },
        async fetchCiaAereas({commit, state}) {
            commit("setValue", {key: "loading", value: true});
            
            return await api.getCiaAereas().then(res => {
                commit("setValue", {key: "loading", value: false});
                commit("setValue", {key: "ciaAereas", value: res.data});
                return res;
            }).catch(err => {
                commit("setValue", {key: "loading", value: false});
                throw err;
            })
        },

        async fetchMilhas({commit, state}) {
            commit("setValue", {key: "loading", value: true});
            
            return await api.getTabelaMilhas().then(res => {
                commit("setValue", {key: "loading", value: false});
                commit("setValue", {key: "milhas", value: res.data});
                return res;
            }).catch(err => {
                commit("setValue", {key: "loading", value: false});
                throw err;
            })
        },

        async fetchFornecedores({commit, state}, filters=[]) {
            commit("setValue", {key: "loading", value: true});
            return await api.getFornecedores(0, filters).then(res => {
                commit("setValue", {key: "loading", value: false});
                commit("setValue", {key: "fornecedores", value: res.data});
                return res;
            }).catch(err => {
                commit("setValue", {key: "loading", value: false});
                throw err;
            })
        },

        async fetchProvedores({commit, state}) {
            commit("setValue", {key: "loading", value: true});
            
            return await api.getProvedores(0).then(res => {
                commit("setValue", {key: "loading", value: false});
                commit("setValue", {key: "provedores", value: res.data});
                return res;
            }).catch(err => {
                commit("setValue", {key: "loading", value: false});
                throw err;
            })
        },
    },
}
export default globalStore;
