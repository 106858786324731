<template >
    <div class= "">
        <Capa
            :orcamento="orcamento"
            :cotacoes="cotacoes"
        />
        <Voo
            v-if="cotacoes[0].cotacao_voo"
            :cotacoes="cotacoes"
            :tipoPagamento="tipoPagamento"
            :informacoesPagamento="informacoesPagamento"
        />
        <Hotel
            v-if="cotacoes[0].cotacao_hotel"
            :cotacoes="cotacoes"
            :tipoPagamento="tipoPagamento"
            :informacoesPagamento="informacoesPagamento"
        />
        <Veiculo
            v-if="cotacoes[0].cotacao_carro"
            :cotacoes="cotacoes"
            :tipoPagamento="tipoPagamento"
            :informacoesPagamento="informacoesPagamento"
        />
        <Outros
            v-if="cotacoes[0].cotacao_outro"
            :cotacoes="cotacoes"
            :tipoPagamento="tipoPagamento"
            :informacoesPagamento="informacoesPagamento"
        />
    </div>
</template>
<script>

// usa todas cotações e não somente as selecionadas.

import Infos from './Infos.vue';
import Voo from './Voo.vue';
import Hotel from './Hotel.vue';
import Veiculo from './Veiculo.vue';
import Outros from './Outros.vue';
import Capa from './Capa.vue';

export default {
    components: {
        Infos,
        Voo,
        Hotel,
        Veiculo,
        Outros,
        Capa
    },
    props: {
        orcamento: {
            type: Object,
            default: () => {},
            required: true
        },
        cotacoes: {
            type: Array,
            default: () => [],
            required: true
        },
        tipoPagamento: {
            type: Array,
            default: () => ["pagamentoAVista"]
        },
        informacoesPagamento: {
            type: Object,
            default: () => {}
        }
    },
    async mounted() {
    },
    data() {
        return {
        }
    },
    methods: {
    }
}
</script>
<style >
html { -webkit-print-color-adjust: exact; }
@font-face {
  font-family: TenorSans;
  src: url('@/assets/TenorSans-Regular.ttf');
}
@font-face {
  font-family: Manrope;
  src: url('@/assets/Manrope-Regular.otf');
}
.cotacao {
    margin-bottom: 2rem;
}
.page {
    margin-bottom: 2rem;
    padding: 3rem;
    padding-top: 0;
}
.small{
    width: 50%
}
p {
    font-size: 0.85rem;
}
</style>