<template>
    <v-container>
        <v-form id="extras-form">
            <v-row>
                <v-col cols="12" sm="6">
                    <v-text-field
                        label="Instagram"
                        name="instagram"
                        prepend-inner-icon="mdi-instagram"
                        outlined
                        required
                    />
                </v-col>
                <v-col cols="12" sm="6">
                    <v-text-field
                        label="LinkedIn"
                        name="linkedin"
                        prepend-inner-icon="mdi-linkedin"
                        outlined
                        required
                    />
                </v-col>
                <v-col cols="12" sm="6">
                    <SearchContato
                        label="Relação"
                        v-model="relacao"
                    />
                    <input
                        type="hidden"
                        name="relacao"
                        v-model="relacao"
                    />
                </v-col>
                <v-col cols="12" sm="6">
                    <v-text-field
                        label="Tipo de relação"
                        name="tipo_relacao"
                        prepend-inner-icon="mdi-account-multiple"
                        outlined
                        required
                    />
                </v-col>
                <v-col cols="12" sm="6">
                    <FormDatePicker
                        v-model="models.data_especial"
                        outlined
                        :dense="false"
                        label="Data Especial"
                        name="data_especial"
                    />
                </v-col>
                <v-col cols="12" sm="6">
                    <v-text-field
                        label="Ocasião"
                        name="ocasiao"
                        prepend-inner-icon="mdi-lead-pencil"
                        outlined
                        required
                    />
                </v-col>
                <v-col cols="12" sm="12">
                    <v-textarea
                        label="Observações"
                        name="observacoes"
                        prepend-inner-icon="mdi-pencil"
                        outlined
                        required
                        :rules="[v => v && v.length < 500 || 'Observações devem ter menos de 500 caracteres']"
                    />
                </v-col>
            </v-row>
        </v-form>
    </v-container>
</template>

<script>
import SearchContato from "@/components/widgets/SearchContato.vue"
import FormDatePicker from "@/components/widgets/FormDatePicker.vue"

export default {
    components: {SearchContato, FormDatePicker},
    data () {
        return {
            relacao: null,
            models: {
                data_especial: null
            }
        }
    }
}
</script>