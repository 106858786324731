import api from "@/api/api";

import { getAllFields } from "@/helpers/forms";
import { formatApiError } from "@/helpers/apiErrors";
import { validateCPF } from "@/helpers/identificadores";

import skylerDate from "@/helpers/Date/date";

const contatoMixin = {
  data() {
    return {
      contatoCreated: null,
    };
  },

  methods: {
    getRawNumber(maskedValue) {
      if (!maskedValue) return null;
      return maskedValue.match(/[0-9]+/g).join("");
    },

    scrapInfo() {
      const infoContato = getAllFields("info-form");

      infoContato.cpf = this.getRawNumber(infoContato.cpf);
      infoContato.genero = infoContato.genero ? infoContato.genero[0] : null;

      infoContato.data_nascimento = skylerDate.dateToString(
        infoContato.data_nascimento
      );
      if (!skylerDate.isDateValid(infoContato.data_nascimento))
        infoContato.data_nascimento = null;

      infoContato.telefone = {
        numero: infoContato.telefone,
        pais: infoContato.telefone_pais,
      };

      return infoContato;
    },

    scrapEndereco() {
      const enderecoContato = getAllFields("endereco-form");
      enderecoContato.cep = this.getRawNumber(enderecoContato.cep);
      enderecoContato.estado = this.$refs.endereco.selectedEstado.nome;

      return { endereco: enderecoContato };
    },

    scrapExtras() {
      const extrasContato = getAllFields("extras-form");

      extrasContato.data_especial = skylerDate.dateToString(
        extrasContato.data_especial
      );
      if (!skylerDate.isDateValid(extrasContato.data_especial))
        extrasContato.data_especial = null;

      return { extras: extrasContato };
    },

    scrapCnh() {
      const data = getAllFields("new-cnh");
      data.data_validade = skylerDate.dateToString(data.data_validade);
      data.cnh = this.$refs.documentos.loadedFileCNH;

      return data;
    },

    scrapIdentidade() {
      const dataIdentidade = getAllFields("new-identidade");
      dataIdentidade.data_emissao = skylerDate.dateToString(
        dataIdentidade.data_emissao
      );
      dataIdentidade.identidade = this.$refs.documentos.loadedFileIdentidade;

      return dataIdentidade;
    },

    scrapPassaporte() {
      const dataPassaporte = getAllFields("new-passaporte");
      dataPassaporte.data_validade = skylerDate.dateToString(
        dataPassaporte.data_validade
      );
      dataPassaporte.data_emissao = skylerDate.dateToString(
        dataPassaporte.data_emissao
      );
      dataPassaporte.passaporte = this.$refs.documentos.loadedFile;
      return dataPassaporte;
    },

    validarInfoUsuario(info) {
      // Validar CPF
      if (info.cpf && !validateCPF(info.cpf)) {
        this.$root.vtoast.show({
          message: "CPF inválido",
          color: "error",
          icon: "mdi-close",
        });
        return false;
      }

      // Validar Indicacação
      if (!info.indicacao || info.indicacao == "") {
        this.$root.vtoast.show({
          message: " Indicação é obrigatória!",
          color: "error",
          icon: "mdi-close",
        });
        setTimeout(() => {
          this.loading.saving = false;
        }, 1000)
        return false;
      }

      return true;
    },

    async novoContato() {
      this.loading.saving = true;
      const info = this.scrapInfo();
      const endereco = this.scrapEndereco();
      const extras = this.scrapExtras();

      const cnh = this.scrapCnh();
      const identidade = this.scrapIdentidade();
      const passaporte = this.scrapPassaporte();

      if (!this.validarInfoUsuario(info)) return;

      const contato = {
        ...info,
        ...endereco,
        ...extras,
        data_busca: {},
      };

      await this.criarUsuario(contato);
      console.log(cnh.numero);

      if (cnh.numero != undefined) {
        //evitar de chamar a função sem preencher documento
        this.CriarCnh(cnh);
      }
      if (identidade.numero != undefined) {
        //evitar de chamar a função sem preencher documento
        this.CriarIdentidade(identidade);
      }

      if (passaporte.numero != undefined) {
        //evitar de chamar a função sem preencher documento
        this.CriarPassaporte(passaporte);
      }
    },

    async criarUsuario(contato) {
      await api
        .postContato(contato)
        .then((data) => {
          this.dialog = false;
          this.$emit("refresh");
          this.$root.vtoast.show({
            message: "Contato criado com sucesso!",
            icon: "mdi-account-plus",
            color: "success",
          });
          this.contatoCreated = data.data.id;
        })
        .catch((e) => {
          console.error(e);
          window.alert(formatApiError(e));
        })
        .finally(() => {});
    },

    CriarCnh(cnh) {
      api
        .postContatoCNH(this.contatoCreated, cnh)
        .then((response) => {
          this.docs.cnh.push(response.data);
          this.$refs["form-cnh"].reset();
          this.loadedFile = null;
        })
        .catch((e) => {
          this.$root.vtoast.show({
            message: "Erro ao adicionar esta CNH",
            color: "error",
            icon: "mdi-close",
          });
          console.error(e);
        });
    },
    CriarIdentidade(identidade) {
      api
        .postContatoIdentidade(this.contatoCreated, identidade)
        .then((response) => {
          this.docs.identidade.push(response.data);
          this.$refs["form-identidade"].reset();
          this.loadedFile = null;
        })
        .catch((e) => {
          this.$root.vtoast.show({
            message: "Erro ao adicionar este RG",
            color: "error",
            icon: "mdi-close",
          });
          console.error(e);
        });
    },
    CriarPassaporte(passaporte) {
      api
        .postPassaporte(this.contatoCreated, passaporte)
        .then((response) => {
          this.docs.passaporte.push(response.data);
          this.$refs["form-passaporte"].reset();
        })
        .catch((e) => {
          this.loadingAdicionarPassaporte = false;

          this.$root.vtoast.show({
            message: "Erro ao adicionar passaporte",
            color: "error",
            icon: "mdi-close",
          });

          console.error(e);
        });
    },
  },
};

export default contatoMixin;
