<template>
  <v-card class="elevation-5">
    <v-card-title>Serviços com pendências validação de bilhetes</v-card-title>
    <v-data-table
      :headers="headers"
      :items="items"
      :loading="loading"
      sort-by="id"
      sort-desc
    >
      <template v-slot:item.created_at="{ item }">
        {{ item.created_at | convertDataBR }}
      </template>
      <template v-slot:item.status="{item}">
        {{ item.status | formatStatusServico }}
      </template>
      <template v-slot:item.nome="{ item, value }">
        {{ (!item.venda ? "" : item.venda.cliente) | nomeSobrenome }}
        {{
          !item.venda
            ? ""
            : item.venda.cliente_empresa
            ? item.venda.cliente_empresa.nome
            : ""
        }}
      </template>
      <template v-slot:item.criador="{ item }">
        {{ !item.venda ? "" : item.venda.created_by | nomeSobrenome }}
      </template>
      <template v-slot:item.venda="{ item }">
        <v-link-text-field :to="{ name: `venda-info`, params: { id: item?.venda?.id}}" text small>
          {{item?.venda?.id}}
        </v-link-text-field>
      </template>
      <template v-slot:item.orcamento="{ item }">
        <v-link-text-field :to="{ name: `orcamento`, params: { id: item?.cotacao?.orcamento?.id }}" text small>
          {{item?.cotacao?.orcamento?.id}}
        </v-link-text-field>
      </template>
      <template v-slot:item.cotacao="{ item }">
        <v-link-text-field :to="{ name: `orcamento`, params: { id: item?.cotacao?.orcamento?.id}}" text small>
          {{item?.cotacao?.id}}
        </v-link-text-field>
      </template>
      <template v-slot:item.actions="{ item }">
        <v-btn icon color="transparent" :disabled="checkUserPermissao" @click="select(item.id)">
          <v-icon small color="primary"  class="mr-2">mdi-open-in-new </v-icon>
        </v-btn>
        <v-btn icon color="transparent" :disabled="checkUserPermissao" @click="openCancelDialog(item.id)">
          <v-icon small color="red" class="mr-2">mdi-cancel</v-icon>
        </v-btn>
      </template>
    </v-data-table>
    <v-dialog width="600px" persistent v-model="cancelDialog">
      <v-card>
        <v-card-title>Deseja mesmo cancelar esse serviço?</v-card-title>
        <v-card-text>
          Insira o id do serviço:
          <v-text-field v-model="cancelString" outlined></v-text-field>
          Justificativa de cancelamento
          <v-textarea
            v-model="justificativaCancelamento"
            :rules="[cancelamentoRule]"
            outlined
          />
        </v-card-text>
        <v-card-actions>
          <v-container>
            <v-row>
              <v-col align="left">
                <v-btn color="primary" @click="cancelDialog = false">
                  <v-icon> mdi-exit </v-icon>
                  Sair
                </v-btn>
              </v-col>
              <v-col align="right">
                <v-btn color="error" :disabled="!canCancel" @click="cancel()">
                  <v-icon> mdi-cancel </v-icon>
                  Confirmar
                </v-btn>
              </v-col>
            </v-row>
          </v-container>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-card>
</template>
<script>
import VLinkTextField from "@/components/widgets/VLinkTextField";
import checkPermissao from "@/helpers/checkUserPermissao";

export default {
  components: {
    VLinkTextField
  },
  props: {
    items: {
      type: Array,
      default: () => [],
    },

    loading: {
      type: Boolean,
      default: false,
    },
  },

  data: () => ({
    cancelDialog: false,
    cancelString: "",
    cancelId: null,
    justificativaCancelamento: "",
    cancelamentoRule: (string) =>
      (string.length >= 10 && string.length <= 50) ||
      "Justificativa deve ter entre 10 e 50 caracteres.",
    headers: [
      {
        text: "ID",
        align: "start",
        sortable: true,
        value: "id",
      },
      {
        text: "Data de criação do serviço",
        value: "created_at",
      },
      {
        text: "Status",
        value: "status"
      },
      {
        text: "Nome",
        value: "nome",
      },
      {
        text: "Criador Venda",
        value: "criador",
      },
      {
        text: "ID venda",
        value: "venda",
      },
      {
        text: "ID orcamento",
        value: "orcamento",
      },
      {
        text: "ID cotação",
        value: "cotacao",
      },
      {
        text: "Ações",
        value: "actions",
      },
    ],
  }),

  computed: {    
    user() {
            return this.$store.getters['usuario/loggedUser'];
        },
    checkUserPermissao(){
            const pertencente = this.user.pertencente;
            return checkPermissao(pertencente, 'milhas');
        },

    canCancel() {
      return (
        this.cancelString === "" + this.cancelId &&
        this.justificativaCancelamento.length <= 50 &&
        this.justificativaCancelamento.length >= 10 
      );
    },
  },

  methods: {
    select(id) {
      this.$emit("select", id);
    },
    openCancelDialog(id) {
      this.cancelId = id;
      this.cancelDialog = true;
    },
    cancel() {
      this.$emit("cancel", this.cancelId, this.justificativaCancelamento);
      this.cancelDialog = false;
    },
  },
};
</script>
