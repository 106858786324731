<template>
  <v-container>
    <v-row>
      <v-col>
        <div class="text-h5 mt-4 mb-4 transition-swing">
          Bilhetes pendentes de validação
        </div>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="auto" align-self="center">
        <span class="text font-weight-bold"
          >Servico ID: # {{ servico?.id }}</span
        >
      </v-col>
      <v-col cols="auto" align-self="center">
        <span class="text font-weight-bold"
          >Cotacao ID: # {{ servico?.cotacao?.id }}</span
        >
      </v-col>
      <v-col cols="auto" align-self="center">
        <span class="text font-weight-bold"
          >Cliente:
          {{ servico?.venda?.cliente | nomeSobrenome }}
          {{ servico?.venda?.cliente_empresa?.nome }}
        </span>
      </v-col>
      <v-col cols="auto" align-self="center">
        <span class="text font-weight-bold"
          >Consultor: {{ servico?.venda?.consultor | nomeSobrenome }}</span
        >
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <small>*Valor total inclui taxas extras.</small>
        <v-data-table
          :headers="headers"
          :items="subcotacoes"
          class="elevation-5"
        >
          <template v-slot:item.programa_fidelidade="{ item }">
            {{ formatProgramaFidelidade(item.programa_fidelidade) }}
          </template>
          <template v-slot:item.quantidade_milhas="{ item }">
            {{ formatPrice(item.quantidade_milhas) }}
          </template>
          <template v-slot:item.passageiros="{ item }">
            {{ qtdPassageiros(item) }}
          </template>
          <template v-slot:item.milheiro="{ item }">
            {{ subcotacaoGetValorMilha(item) | dinheiroReal }}
          </template>
          <template v-slot:item.valor="{ item }">
            *{{ subcotacaoVooCustoTotal(item) | dinheiroReal }}
          </template>
          <template v-slot:item.cias_aereas="{ item }">
            <v-btn icon @click="popUpCiasAereas(item.id)">
              <v-icon color="blue"> mdi-magnify </v-icon>
            </v-btn>
          </template>
          <template v-slot:item.actions="{ item }">
            <v-btn
              icon
              @click="openDialog(item.id)"
              :disabled="validated.includes(item.id)"
            >
              <v-icon color="green"> mdi-check </v-icon>
            </v-btn>
          </template>
        </v-data-table>
      </v-col>
    </v-row>
    <v-dialog
      v-model="ciasAereasDialog"
      max-width="500px"
      transition="dialog-transition"
    >
      <cias-aereas-resumo
        :subcotacao="chosen"
        @close="() => (ciasAereasDialog = false)"
      />
    </v-dialog>
    <sub-cotacao-autorizacao-new-item
      :form="form"
      :contasFidelidade="contasFidelidade"
      :contatos="contatos"
      :provedores="provedores"
      :porMilhas="chosen.tipo_cotacao === 'milha'"
      :programasFidelidade="programasFidelidade"
      :subcotacao="chosen"
      v-model="dialog"
      :openBestPracticesMilhasDialog="openBestPracticesMilhasDialog"
      @excludeLine="excludeLine"
      @addLine="addLine"
      @check="check"
      @toggleCustomConta="toggleCustomConta"
      @confirmBestPracticesMilhas="confirmBestPracticesMilhas"
      @closeBestPracticesMilhasDialog="closeBestPracticesMilhasDialog"
    />
  </v-container>
</template>
<script>
import api from "@/api/api.js";
import CotacaoMixin from "@/mixins/CotacaoMixin";
import { formatProgramaFidelidade } from "@/helpers/fidelidades";
import { numPessoas } from "@/helpers/subcotacao";
import { formatApiError } from "@/helpers/apiErrors";
import CiasAereasResumo from "@/components/widgets/CiasAereasResumo";
import SubCotacaoAutorizacaoNewItem from "./SubCotacaoAutorizacaoNewItem";

export default {
  components: {
    SubCotacaoAutorizacaoNewItem,
    CiasAereasResumo,
  },
  mixins: [CotacaoMixin],
  props: {
    servico: {
      type: Object,
      default: () => {},
    },
    contasFidelidade: {
      type: Array,
      default: () => [],
    },
    programasFidelidade: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      dialog: false,
      ciasAereasDialog: false,
      form: [
        {
          contato: null,
          conta_fidelidade: null,
          valor: 0,
          customConta: false,
          mesma_conta: false,
        },
      ],
      chosen: {},
      validated: [],
      headers: [
        {
          text: "ID",
          align: "start",
          sortable: true,
          value: "id",
        },
        {
          text: "Tipo",
          value: "tipo_cotacao",
        },
        {
          text: "Quantidade de milhas",
          value: "quantidade_milhas",
        },
        {
          text: "Programa de fidelidade",
          value: "programa_fidelidade",
        },
        {
          text: "Milheiro de Venda",
          value: "milheiro",
        },
        {
          text: "Valor Total",
          value: "valor",
        },
        {
          text: "Qtd de passageiros",
          value: "passageiros",
        },
        {
          text: "Cias Aereas",
          value: "cias_aereas",
        },
        {
          text: "Validar",
          value: "actions",
        },
      ],
      openBestPracticesMilhasDialog: false,
      bestPracticesMilhas: true,
      reasonForNoBestPracticesMilhas: '',

    };
  },
  computed: {
  
  programas() {
    return this.$store.state.global.programas;
    },
  provedores() {
    return this.$store.state.global.provedores.filter(
    (provedor) => provedor.tipo_servico === "milhas"
    )},
  contatos() {
    if (!this.servico) return [];
    return this.servico.beneficiarios.map((benef) => benef.contato);
    },
  subcotacoes() {
    const cotVoo = this?.servico?.cotacao?.cotacao_voo;
    if (!cotVoo) return [];
    return cotVoo.subcotacoes.filter(
    (subcot) => subcot.tipo_cotacao !== "pagante");    
    },
  },

  methods: {
    validateData(data) {
      // validates the form, making the fields
      // provedor, programa de fidelidade, login, senha, cpf, valor de compra/desagio
      // obrigatórios quando o milheiro é acionado manualmente
      if (data.conta_fidelidade) return true; // verificação não necessaria
      const basicValidation =
        data.provedor_milhas &&
        data.programa_fidelidade &&
        data.login &&
        data.senha &&
        data.cpf;
      const milhasValidation = data.tipo === "milha" && data.valor_compra;
      const walletValidation = data.tipo === "wallet" && data.desagio_compra;
      return basicValidation && (milhasValidation || walletValidation);
    },
    formatPrice(value) {
      if (!value) return "0";
      return value.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1.");
    },
    save(subcotId) {
      const form = this.form.map((e) => ({
      ...e,
      subcotacao: subcotId,
      tipo: this.chosen.tipo_cotacao,
      servico: this.servico.id,
      cotacao: this.servico?.cotacao?.id,
      venda: this.servico?.venda?.id,
    }));
    form.forEach((item) => delete item.customConta);
    if (!form.reduce((cum, cur) => cum && this.validateData(cur), true)) {
      window.alert(
        "Preencha todos os campos obrigatórios para validar a compra"
      );
      return;
    }

    const data = {
      milhas_validadas: form,
      cotacao: this.servico.cotacao.id,
    };
    
    api
      .createCotacoesMilhasValidadas(data)
      .then((res) => {
        this.$root.vtoast.show({
          icon: "mdi-check",
          message: "Autorizado com sucesso!",
        });

        this.form.forEach(item => {
          item.subcotacao = null,
          item.contato = null,
          item.conta_fidelidade = null,
          item.valor = 0,
          item.customConta = false
        });

        this.dialog = false;
        this.$emit("refresh");
        this.validated.push(subcotId);
        
      })
      .catch((err) => {
        console.error(err);
        window.alert(formatApiError(err));
        this.$emit("refresh");
      });
    },

    openBestPracticesMilhasDialogFunction(){
      this.openBestPracticesMilhasDialog = true
    },

    formatProgramaFidelidade(val) {
      return formatProgramaFidelidade(val, this.programas);
    },

    qtdPassageiros(subcotacao) {
      return numPessoas(subcotacao);
    },

    addLine() {
      this.form.push({
        subcotacao: null,
        contato: null,
        conta_fidelidade: null,
        valor: 0,
        customConta: false,
      });
    },

    excludeLine(id) {
      if (id === 0) this.form.shift();
      this.form.splice(id, id);
    },

    toggleCustomConta(id) {
      this.form[id].customConta = !!!this.form[id].customConta;
      this.form[id].conta_fidelidade = null;
    },

    openDialog(id) {
      this.dialog = true;
      this.chosen = this.subcotacoes.find((subcot) => subcot.id === id);
    },

    popUpCiasAereas(id) {
      this.ciasAereasDialog = true;
      this.chosen = this.subcotacoes.find((subcot) => subcot.id === id);
    },

    check() {
      if (this.chosen.tipo_cotacao === "wallet") {
        this.save(this.chosen.id);
        return;
      }

      const sum = this.form.reduce(
        (sum, point) => sum + parseInt(point.valor),
        0
      );
      if (sum === this.chosen.quantidade_milhas) {
        const lastTicket = this.subcotacoes[this.subcotacoes.length - 1];
        const isLastTicket = lastTicket && lastTicket.id === this.chosen.id;

        if (isLastTicket) {
          this.openBestPracticesMilhasDialogFunction();
        } else {
          this.save(this.chosen.id);
        }
      } else {
        this.$root.vtoast.show({
          color: "error",
          icon: "mdi-close",
          message: "Montantes indicados não cobrem a quantidade de milhas necessárias.",
        });
      }
    },
    confirmBestPracticesMilhas(bestPracticesMilhas, reasonForNoBestPracticesMilhas) {
      const form = this.form.map((e) => ({
        ...e,
        subcotacao: this.chosen.id,
        tipo: this.chosen.tipo_cotacao,
        servico: this.servico.id,
        cotacao: this.servico?.cotacao?.id,
        venda: this.servico?.venda?.id,
      }));
      form.forEach((item) => delete item.customConta);

      const data = {
        milhas_validadas: form,
        cotacao: this.servico.cotacao.id,
        seguiu_manual_boas_praticas_milhas: bestPracticesMilhas,
        motivo_nao_seguimento_boas_praticas_milhas: reasonForNoBestPracticesMilhas,
      };
  
      api
        .createCotacoesMilhasValidadas(data)
        .then((res) => {
          this.$root.vtoast.show({
            icon: "mdi-check",
            message: "Autorizado com sucesso!",
          });

          this.form.forEach((item) => {
            item.subcotacao = null;
            item.contato = null;
            item.conta_fidelidade = null;
            item.valor = 0;
            item.customConta = false;
          });

          this.dialog = false;
          this.$emit("refresh");
          this.validated.push(this.chosen.id);
          this.openBestPracticesMilhasDialog = false;
        })
        .catch((err) => {
          console.error(err);
          window.alert(formatApiError(err));
          this.$emit("refresh");
      });
    },
    closeBestPracticesMilhasDialog(){
      this.openBestPracticesMilhasDialog = false;
    }
  },
};
</script>
<style>
.center-vertical {
  margin: auto 0 auto 0;
}
</style>
