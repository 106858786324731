<template lang="">
    <div class="page">
        <div v-for="(cotacao, idxCotacao) in cotacoes" class="cotacao-container">
            <Header/>
            <h3 class="cotacao">Cotação #{{idxCotacao + 1}} (id: {{cotacao.id}})</h3>
            <div v-for="(subcotacao, idxSubcotacao) in cotacao.cotacao_voo.subcotacoes" class="subcotacao">
                <Header v-if="idxSubcotacao > 0"/>
                <h4 >
                    Bilhete #{{idxSubcotacao + 1}}
                </h4>
                <div v-for="(trecho, idxTrecho) in subcotacao.viagem.trechos" class="trecho-container">

                    <!-- título de um trecho -->
                    <h5>
                        <span v-if="subcotacao.viagem.trechos.length === 2">
                            <span v-if="idxTrecho === 0">IDA:</span>
                            <span v-else>VOLTA:</span>
                        </span>
                        <span v-else>TRECHO {{idxTrecho + 1}}: </span>
                        {{formatData(trecho.pernas[0].data_hora_partida)}}
                    </h5>

                    <TableVoo :trecho="trecho" />
                    <hr v-if="idxTrecho < subcotacao.viagem.trechos.length - 1"/>

                     <!-- PÉSSIMA SÓLUÇÃO MAS A BIBLIOTECA NÃO IMPLEMENTOU PAGE BREAKS DIREITO -->
                    <div v-if="idxTrecho % 2 && idxTrecho > 0 && subcotacao.viagem.trechos.length > 2" class="html2pdf__page-break"></div>
                </div>


                <!-- resumo pagamento -->

                <div v-if="pagante(subcotacao)" class="precos">
                    <p>{{total(cotacao, subcotacao)}}</p>
                    <p>Bilhete: {{(
                        converteValorFinanceiroBRL(subcotacao.custo_bilhete) + 
                        cotacaoValorAjuste(cotacao) +
                        calculoJuros(cotacaoValorTotal(cotacao))-cotacaoValorTotal(cotacao)
                        
                    ) |dinheiroReal}}</p>
                    <p>Taxa de Embarque: {{format(subcotacao.custo_taxa_embarque)}}</p>
                    <p>Taxa de Bagagem: {{format(subcotacao.custo_taxa_bagagem)}}</p>
                    <p>Custos Extras: {{format(subcotacao.custo_taxas_extras)}}</p>
                </div>
                <div v-else class="precos">
                    <p>{{total(cotacao, subcotacao) |dinheiroReal}}</p>
                    <p>Programa de fidelidade: {{formatProgramaFidelidade(subcotacao.programa_fidelidade) || "Não especificado"}}</p>
                    <p>Taxa de Embarque: {{format(subcotacao.custo_taxa_embarque)}}</p>
                    <p>Taxa de Bagagem: {{format(subcotacao.custo_taxa_bagagem)}}</p>
                    <p>Custos Extras: {{format(subcotacao.custo_taxas_extras)}}</p>
                </div>
                <div class="html2pdf__page-break"></div>
            </div>
        </div>
        <resumo-orcamento :cotacoes="cotacoes" :cias="cias"/>
    </div>
</template>
<script>
import Header from '../Template/Header.vue'
import ResumoOrcamento from './ResumoOrcamento.vue'
import TableVoo from './TableVoo.vue'
import CotacaoMixin from "@/mixins/CotacaoMixin";
import {formatProgramaFidelidade} from "@/helpers/fidelidades";
import {numPessoas} from "@/helpers/subcotacao"
import {convertHora} from "@/helpers/dates"


export default {
    components: {
        Header,
        TableVoo,
        ResumoOrcamento
    },
    computed: {
        programas() {
            return this.$store.state.global.programas;
        }
    },
    mounted(){
        if(!this.$store.state.global.programas)
            this.$store.dispatch('global/fetchProgramas')
    },
    props: {
        cotacoes: {
            type: Array,
            default: () => [],
            required: true
        },
        tipoPagamento: {
            type: Array,
            default: () => ["pagamentoAVista"]
        },
        informacoesPagamento: {
            type: Object,
            default: () => {}
        },
        cias: [],
    },
    mixins: [CotacaoMixin],
    methods: {
        partida (date){
            return convertHora(date)
        },
        pagante(subcotacao){
            return ['pagante', 'wallet'].includes(subcotacao.tipo_cotacao)
        },
        format(num){
            return this.$options.filters.dinheiroReal(this.converteValorFinanceiroBRL(num).toFixed(2))
        },
        total(cotacao){
            if(this.informacoesPagamento[cotacao.id].tiposPagamento.length===0) return this.$options.filters.dinheiroReal(this.cotacaoValorTotalJuros(cotacao))
            const valores = this.informacoesPagamento[cotacao.id].tiposPagamento.map((tipo) => {
                const valor=this.$options.filters.dinheiroReal(this.cotacaoValorTotalJuros(cotacao)-this.converteValorFinanceiroBRL(this.informacoesPagamento[cotacao.id][tipo].desconto))
                return `${valor.toString()} (${this.informacoesPagamento[cotacao.id][tipo].name} - ${this.$options.filters.dinheiroReal(this.converteValorFinanceiroBRL(this.informacoesPagamento[cotacao.id][tipo].desconto))} de desconto)`
            }).join(", ")
            return valores
        },
        numPessoas(subcotacao){
            return numPessoas(subcotacao)
        },
        formatProgramaFidelidade(val){
            return formatProgramaFidelidade(val, this.programas)
        },
        formatData(data) {
            const date = new Date(data);
            return(`${date.getDate()}/${date.getMonth()+1}/${date.getFullYear()}`)
        }

    }
}
</script>
<style >
.precos {
    background-color: #819486;
    border-radius: 15px;
    color: white;
    margin: 3rem 1rem 0 1rem ;
    padding: 1rem;
} 
.precos > p:nth-child(1){
    font-size: 1.5rem;
    margin-left: 1rem;
    margin-bottom: 2rem;
}
.precos span {
    font-size: 1rem;
    padding-left: 2rem;
}
.cotacao-container > h3 {
    margin-left: 0;
    color: white;
    background-color: #819486;
    border-radius: 15px;
    padding: 1.5rem;
    text-align: center;
}
.subcotacao > h4 {
    margin-left: 0;
    background-color: #b4bfb7;
    border-radius: 15px;
    padding: 0.7rem;
    text-align: center;
    margin-bottom: 1rem;
}
.sub{
    padding-left: 2rem;
}
.subcotacao .trecho-container {
    padding: 2rem 0 0 0;
}
.subcotacao .trecho-container > h5 {
    font-size: 1.2rem;
    color: #9c9c9c;
}
.subcotacao .trecho-container > hr {
    border-top: 1px solid black;
    margin: 3rem auto 1rem auto;
}
.resumo-orcamento > h3 {
    font-size: 2rem;
    padding: 2rem;
    margin: 2rem;
    text-align: center;
    border-radius: 15px;
    background-color: #819486;
    color: white;
}
</style>