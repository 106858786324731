<template>
  <v-card>
    <v-card-title>Pagamento da Emissão</v-card-title>
    <p v-if="campoFormadorFaltante" class="text-hr-9">
      *Caso encontre problemas na escolha do responsável pelo pagamento, contate
      a equipe tech
    </p>

    <v-card-text>
      <v-container>
        <v-row
          v-for="(campo, index) in camposFormadores[tipoEmissao]"
          :key="index"
        >
          <v-col>
            <v-moeda-field
              v-if="campo.tipo === 'valorFinanceiro'"
              outlined
              dense
              disabled
              :label="campo.nome | formatCampoFormadorPreco"
              v-model="cotacaoFormadora[campo.nome]"
            />
            <v-moeda-field
              v-else-if="campo.tipo === 'valorFinanceiroImutavel'"
              outlined
              dense
              disabled
              :label="campo.nome | formatCampoFormadorPreco"
              v-model="cotacaoFormadora[campo.nome]"
            />
            <vuetify-money
              v-else-if="campo.tipo === 'float'"
              dense
              disabled
              v-bind:outlined="true"
              v-bind:label="campo.nome | formatCampoFormadorPreco"
              v-model.number="cotacaoFormadora[campo.nome]"
              v-bind:options="{ precision: 2, locale: 'pt-BR' }"
            ></vuetify-money>
          </v-col>
          <v-col
            v-if="
              (tipoEmissao === 'emissao_hotel') |
                (tipoEmissao === 'emissao_carro') &&
              campo.nome === 'custo_diaria'
            "
          >
            <v-moeda-field
              outlined
              dense
              disabled
              label="Custo total das diárias"
              :value="custoTotalDiarias"
            />
          </v-col>
          <v-col
            v-if="
              tipoEmissao === 'emissao_hotel' && campo.nome === 'custo_diaria'
            "
          >
            <v-field-value label="Nº de diárias">
              {{ numeroDiarias }}
            </v-field-value>
          </v-col>
          <v-col
            v-if="
              tipoEmissao === 'emissao_carro' && campo.nome === 'custo_diaria'
            "
          >
            <v-field-value label="Nº de diárias">
              {{ numeroDiarias }}
            </v-field-value>
          </v-col>

          <v-col
            v-if="
              pagamentosObj[campo.nome] &&
              ['valorFinanceiro', 'valorFinanceiroImutavel', 'float'].includes(
                campo.tipo
              ) &&
              !campoZerado(cotacaoFormadora[campo.nome])
            "
          >
            <v-btn-toggle
              v-if="campo.nome === 'custo_bilhete' || campo.nome === 'milheiro'"
              dense
              :readonly="!emissaoAberta"
              v-model="pagamentosObj[campo.nome].responsavel"
            >
              <v-btn :disabled="!emissaoAberta || checkTipoEmissao"
                >Cliente</v-btn
              >
              <v-btn :disabled="!emissaoAberta">Skyler</v-btn>
            </v-btn-toggle>

            <v-btn-toggle
              v-else
              dense
              :readonly="!emissaoAberta"
              v-model="pagamentosObj[campo.nome].responsavel"
            >
              <v-btn :disabled="!emissaoAberta">Cliente</v-btn>
              <v-btn :disabled="!emissaoAberta">Skyler</v-btn>
            </v-btn-toggle>
          </v-col>
          <v-col v-if="pagamentosObj[campo.nome]">
            <v-select
              v-if="
                pagamentosObj[campo.nome].responsavel === 1 &&
                pagamentosObj[campo.nome] &&
                [
                  'valorFinanceiro',
                  'valorFinanceiroImutavel',
                  'float',
                ].includes(campo.tipo) &&
                !(campo.tipo === 'valorFinanceiroImutavel') &&
                !(campo.nome === 'milheiro')
              "
              :items="cards"
              :item-text="formatCard"
              item-value="id"
              v-model="pagamentosObj[campo.nome].cartao"
              outlined
              :disabled="!emissaoAberta"
              dense
              @input="selectedCard($event, index)"
            />
          </v-col>
        </v-row>
      </v-container>
      <v-container v-if="validacoes.length !== 0">
        <v-row>
          <v-col> Contas de validação </v-col>
        </v-row>
        <v-row v-for="(validacao, index) in validacoes" :key="index">
          <v-col>
            <v-spacer></v-spacer>
            <v-divider></v-divider>
            <v-row>
              <v-field-value
                :label="
                  emissao?.tipo === 'emissao_voo_wallet'
                    ? 'Wallet'
                    : 'Montante ou quantidade'
                "
              >
                {{ formatPrice(validacao.valor) }}
              </v-field-value>
            </v-row>
            <v-row>
              <v-field-value label="CPF">
                {{ validacao.cpf }}
              </v-field-value>
            </v-row>
            <v-row>
              <v-field-value label="Titular">
                {{ validacao?.conta_fidelidade?.titular | nomeSobrenome }}
              </v-field-value>
            </v-row>
            <v-row>
              <v-field-value label="Milheiro">
                {{ validacao?.provedor_milhas?.nome }}
              </v-field-value>
            </v-row>
            <v-row>
              <v-field-value label="Programa de fidelidade">
                {{ validacao?.programa_fidelidade?.nome }}
              </v-field-value>
            </v-row>
            <v-row>
              <v-field-value label="Beneficiário">
                {{ validacao?.contato | nomeSobrenome }}
              </v-field-value>
            </v-row>
            <v-row v-if="emissao.tipo === 'emissao_voo_milha'">
              <v-field-value label="Valor Compra">
                {{
                  converteValorFinanceiroBRL(validacao?.valor_compra)
                    | dinheiroReal
                }}
              </v-field-value>
            </v-row>
            <v-row v-if="emissao.tipo === 'emissao_voo_wallet'">
              <v-field-value label="Deságio de Compra">
                {{ validacao?.desagio_compra + "%" }}
              </v-field-value>
            </v-row>
          </v-col>
          <v-col v-if="!validacao.milheiro_emite">
            <v-spacer></v-spacer>
            <v-divider></v-divider>
            <v-row>
              <v-field-value label="Login">
                {{ validacao.login }}
              </v-field-value>
            </v-row>
            <v-row>
              <v-field-value label="Senha">
                {{ validacao.senha }}
              </v-field-value>
            </v-row>
          </v-col>
        </v-row>
        <v-col> </v-col>
      </v-container>
      <v-container>
        <v-row>
          <v-spacer />
          <v-dialog
            v-model="loadingCreatePagamentoEmissaoAndOrdemPagamento"
            persistent
            width="auto"
            transition="dialog-bottom-transition"
            :scrim="false"
          >
            <v-card color="white">
              <v-card-text class="text-h6 text-center">
                Aguarde um momento...
                <v-progress-linear indeterminate color="blue" class="mb-0">
                </v-progress-linear>
              </v-card-text>
            </v-card>
          </v-dialog>
        </v-row>
      </v-container>
    </v-card-text>
    <v-card-actions>
      <v-spacer></v-spacer>
      <v-btn
        color="primary"
        @click="createPagamentoEmissaoAndOrdemPagamento"
        :disabled="emissao?.status !== 'fila_de_emissao'"
        :loading="loading"
        >Salvar</v-btn
      >
    </v-card-actions>
  </v-card>
</template>
<script>
import VMoedaField from "@/components/widgets/VMoedaField";
import VFieldValue from "@/components/widgets/VFieldValue";
import camposFormadores from "@/constants/camposFormadoresPreco";
import { converteValorFinanceiroBRL } from "@/helpers/financeiro";
import api from "@/api";

export default {
  components: {
    VMoedaField,
    VFieldValue,
  },

  data: () => ({
    camposFormadores: camposFormadores,
    cards: [],
    pagamentos: Array(10).fill(() => {}),
    validacoes: [],
    servico: null,
    fornecedor: null,
    card: null,
    loading: false,
    loadingCreatePagamentoEmissaoAndOrdemPagamento: false,
  }),

  props: {
    emissao: {
      type: Object,
      default: () => {},
    },
  },

  computed: {
    numeroDiarias() {
      return this.cotacaoFormadora.diarias;
    },
    custoTotalDiarias() {
      if (this.tipoEmissao === "emissao_hotel") {
        return {
          valor:
            this.cotacaoFormadora.custo_diaria.valor *
            this.cotacaoFormadora.diarias,
        };
      } else if (this.tipoEmissao === "emissao_carro") {
        return {
          valor:
            this.cotacaoFormadora.custo_diaria.valor *
            this.cotacaoFormadora.diarias,
        };
      }
    },
    campoFormadorFaltante() {
      if (this.pagamentos.length === 0) return true;
      this.pagamentos.forEach((pagamento) => {
        if (pagamento.campo_formador === "" || !pagamento.campo_formador)
          return true;
      });
      return false;
    },
    // Verifica se precisaremos abilitar os btn ou não
    checkTipoEmissao() {
      return (
        this.emissao.tipo === "emissao_voo_milha" ||
        this.emissao.tipo === "emissao_voo_wallet"
      );
    },
    emissaoAberta() {
      return this.emissao.status === "fila_de_emissao";
    },
    emissaoID() {
      if (!this.emissao) return null;
      return this.emissao.id;
    },

    tipoEmissao() {
      if (!this.emissao) return "";
      return this.emissao.tipo;
    },

    subcotacaoRelacionadaID() {
      if (!this.emissao) return null;
      const subcot = this.emissao.subcotacao_voo;
      if (!subcot) return null;
      return subcot.id;
    },

    cotacaoRelacionadaID() {
      if (!this.emissao) return null;
      const servico = this.emissao.servico;
      if (!servico) return null;
      const cotacao = servico.cotacao;
      if (!cotacao) return null;
      return cotacao.id;
    },

    cotacaoFormadora() {
      if (!this.emissao) return {};
      if (this.emissao.subcotacao_voo)
        return { ...this.emissao.subcotacao_voo };
      const servico = this.emissao.servico;
      if (!servico) return {};
      const cotacao = servico.cotacao;
      if (!cotacao) return {};

      if (cotacao.cotacao_hotel) return { ...cotacao.cotacao_hotel };
      if (cotacao.cotacao_carro) return { ...cotacao.cotacao_carro };
      if (cotacao.cotacao_outro) return { ...cotacao.cotacao_outro };

      return {};
    },

    pagamentosDefault() {
      return this.camposFormadores[this.tipoEmissao]
        .filter((campo) => !this.campoZerado(this.cotacaoFormadora[campo.nome]))
        .map((campo) => ({
          campo_formador: campo.nome,
        }));
    },

    pagamentosObj() {
      return this.pagamentos.reduce(
        (cum, cur) => ({ ...cum, [cur.campo_formador]: cur }),
        {}
      );
    },

    meioPagamento() {
      return this.pagamentos.map((pagamento) => {
        if (pagamento.responsavel === 1 && pagamento.cartao) {
          return "";
        } else if (pagamento.responsavel === 0 || pagamento.responsavel === 1) {
          pagamento.cartao = null;
          return "fornecedor";
        } else {
          return "";
        }
      });
    },
  },

  created() {
    this.initialize();
  },

  watch: {
    pagamentosObj: {
      handler(newValue) {
        for (const campo in newValue) {
          if (newValue.hasOwnProperty(campo)) {
            this.$nextTick(() => {
              this.meioPagamento;
            });
          }
        }
      },
      deep: true,
    },
  },

  copyContent(beneficiario) {
    if (!beneficiario) return;
    const nome = beneficiario.contato?.nome;
    const sobrenome = beneficiario.contato?.sobrenome;
    const cpf = beneficiario.contato?.cpf || "";
    const dataNascimento = beneficiario.contato?.data_nascimento || "";
    copyToClipboard(`
        Nome: ${nome} 
        Sobrenome: ${sobrenome} 
        CPF: ${cpf} 
        Data de Nascimento: ${dataNascimento}
      `);
    this.$root.vtoast.show({
      message: "Dados do beneficiário copiados para área de transferencia!",
      color: "success",
      icon: "mdi-check",
    });
  },

  methods: {
    converteValorFinanceiroBRL: converteValorFinanceiroBRL,
    formatPrice(value) {
      return value.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1.");
    },
    initialize() {
      api
        .getCartoes()
        .then((res) => {
          const data = res.data;
          this.cards = data.filter((card) => card.ativo === true);
        })
        .catch((err) =>
          this.$root.vtoast.show({
            message: "Erro ao recuperar cartões",
            color: "error",
            icon: "mdi-close",
          })
        );
      api
        .getPagamentoEmissao(this.emissaoID)
        .then((res) => {
          const data = res.data;
          if (!data.length) this.pagamentos = this.pagamentosDefault;
          else this.pagamentos = this.unformatResponsavel(data);
        })
        .catch((err) => {
          console.error(err);
          this.$root.vtoast.show({
            message: "Erro ao recuperar pagamentos desta emissão.",
            color: "error",
            icon: "mdi-close",
          });
        });
      if (!this.subcotacaoRelacionadaID) return;
      api
        .getValidacoesSubcotacao(this.subcotacaoRelacionadaID)
        .then((res) => (this.validacoes = res.data))
        .catch((err) => {
          console.error(err);
          this.$root.vtoast.show({
            message: "Erro ao recuperar validações desta emissão.",
            color: "error",
            icon: "mdi-close",
          });
        });
      this.servico = this.emissao.servico;
      this.fornecedor = this.emissao.fornecedor;
    },
    campoZerado(campo) {
      if (!campo) return true;
      if (campo.nome == "rav") return false;
      if (isNaN(campo)) return parseFloat(campo.valor) === 0;
      return campo === 0;
    },
    formatCard(card) {
      if (!card) return "";
      return `${card.nome} - ${card.bandeira} (${card.digs})`;
    },
    selectedCard(value, index) {
      if (this.pagamentos && this.pagamentos[index]) {
        this.pagamentos[index].cartao = value;
      } else {
        this.pagamentos[index].cartao = null;
      }
    },
    formatPagamento(pagamentos) {
      return pagamentos.map((pagamento) => ({
        ...pagamento,
        responsavel:
          pagamento.responsavel === 1
            ? "skyler"
            : pagamento.responsavel === 0
            ? "cliente"
            : null,
      }));
    },
    unformatResponsavel(pagamentos) {
      return pagamentos.map((pagamento) => ({
        ...pagamento,
        responsavel:
          pagamento.responsavel === "skyler"
            ? 1
            : pagamento.responsavel === "cliente"
            ? 0
            : null,
      }));
    },
    async save() {
      const pagamentos = this.formatPagamento(this.pagamentos);
      await api
        .postPagamentoEmissao(this.emissaoID, pagamentos)
        .then(
          (res) => (this.pagamentos = res.data),
          this.$root.vtoast.show({
            message: "Pagamento registrado com sucesso",
            color: "success",
            icon: "mdi-check",
          })
        )
        .catch((err) => {
          console.error(err);
          this.$root.vtoast.show({
            message: "Erro ao salvar pagamentos.",
            color: "error",
            icon: "mdi-close",
          })
        })
        .finally(async () => {
          await this.refreshPagamentos();
          this.loading = false;
          this.loadingCreatePagamentoEmissaoAndOrdemPagamento = false;
        })
    },
    async createOrdemPagamento() {
      const allPagamentos = this.pagamentos.map(async (pagamento) => {
        let valor = this.cotacaoFormadora[pagamento.campo_formador]
          .valor_convertido
          ? this.cotacaoFormadora[pagamento.campo_formador].valor_convertido
          : this.cotacaoFormadora[pagamento.campo_formador].valor;

        if (pagamento.campo_formador === "milheiro") {
          valor = this.cotacaoFormadora.milheiro;
        }
        if (pagamento.campo_formador === "custo_diaria") {
          valor = this.custoTotalDiarias.valor;
        }

        const data = {
          servico: pagamento.servico,
          emissao: pagamento.emissao,
          fornecedor: this.emissao.fornecedor?.id,
          valor: valor,
          meio_pagamento:
            this.meioPagamento[this.pagamentos.indexOf(pagamento)],
          status: "aberto",
          pagamento_emissao: pagamento?.id,
          cartao: pagamento.cartao,
          campo_formador: pagamento.campo_formador,
        };

        api
          .postOrdemPagamento(data)
          .then(async (response) => {
            await Promise.all(allPagamentos); //fazer com que vtoast, refreshPagamentos e loading sejam invocados apenas após o fim de todas as requests.
            this.$root.vtoast.show({
              message: "Ordens de pagamentos criadas com sucesso!.",
              icon: "mdi-check",
              color: "success",
            });
            this.loading = false;
            this.loadingCreatePagamentoEmissaoAndOrdemPagamento = false;
            this.$router.go(0);
          })
          .catch((error) => {
            console.error("Erro ao criar ordem de pagamento:", error);
            this.$root.vtoast.show({
              message: "Erro ao criar ordem de pagamento",
              color: "error",
              icon: "mdi-close",
            });
            this.loading = false;
            this.loadingCreatePagamentoEmissaoAndOrdemPagamento = false;
          });
      });
    },
    async createPagamentoEmissaoAndOrdemPagamento() {
      this.loading = true;
      this.loadingCreatePagamentoEmissaoAndOrdemPagamento = true;
      await this.save();
      await this.$nextTick();
      await this.createOrdemPagamento();
    },
    async refreshPagamentos() {
      await api
        .getPagamentoEmissao(this.emissaoID)
        .then((res) => {
          const data = res.data;
          if (!data.length) this.pagamentos = this.pagamentosDefault;
          else this.pagamentos = this.unformatResponsavel(data);
        })
        .catch((err) => {
          console.error(err);
          this.$root.vtoast.show({
            message: "Erro ao recuperar pagamentos desta emissão.",
            color: "error",
            icon: "mdi-close",
          });
        });
    },
  },
};
</script>
