<template>
  <v-chip label :color="corStatus(status)" class=" d-flex align-center text-subtitle-2 m-0" :text-color="corTextoStatus(status)">
    <p class=" d-flex align-center  justify-center text-subtitle-2 my-2 py-2" >
      {{ statusText(status) }}
    </p>
  </v-chip>
</template>

<script>
import STATUS_VENDA from "@/constants/statusVenda"

const cores = STATUS_VENDA.reduce(
  (cum, curr) => ({
      ...cum, 
      [curr.key]: {
        ...curr
      }
    }), {}
)

export default {
  props: {
    status:{
      type: String,
      default: ""
    }
  },
  methods: {
    corStatus(status) {
      if (status in cores) {
        return cores[status].background
      }
      return "grey";
    },
    corTextoStatus(status) {
      if (status in cores) {
        return cores[status].text
      }
      return "white";
    },
    statusText(status){
      let statusObj = STATUS_VENDA.find(s => s.key === status)

      // to be compatible with old statusses like "Não inciado"
      if(!statusObj) 
        statusObj = STATUS_VENDA.find(s => s.value === status)

      if(!statusObj)  return  ""
      return statusObj.value
    }
  },
};
</script>
