<template >
     <v-container class="ma-4" fluid>
        <v-row>
            <v-col>
                <div class="text-h5 transition-swing">
                    Base de Fornecedores
                </div>
            <v-breadcrumbs :items="breadcumb_items"></v-breadcrumbs>
            </v-col>
        </v-row>
        <v-row><v-col>
            <fornecedores-table
                v-model="fornecedores"
                :provedores="provedores"
                :loading="loadingFornecedores && loadingProvedores"
                @refresh="initialize"
                @refreshFornecedores="refreshFornecedores"
                :numPages="numPagesFornecedores"
            ></fornecedores-table>
        </v-col></v-row>

        <v-row><v-col>
            <provedores-table
                v-model="provedores"
                :loading="loadingProvedores"
                @refresh="initialize"
                @refreshProvedores="refreshProvedores"
                :numPages="numPagesProvedores"
            ></provedores-table>
        </v-col></v-row>
    </v-container>
</template>
<script>
import FornecedoresTable from '@/components/Fornecedores/FornecedoresTable.vue'
import ProvedoresTable from '@/components/Fornecedores/ProvedoresTable.vue'

import api from "@/api/api"

export default {
    components: {FornecedoresTable, ProvedoresTable},
    data: () => ({
        fornecedores: [],
        loadingFornecedores: true,
        provedores: [],
        loadingProvedores: true,
        breadcumb_items: [
        {
            text: 'Home',
            to: {
                name: "home",
            },
            exact: true
        },
        {
            text: 'Base de Fornecedores',
            to: {
                name: "fornecedores"
            }
        }
      ],
      numPagesFornecedores: 1,
      numPagesProvedores: 1,
    }),

    async mounted() {
        await this.initialize()
    },

    methods: {
        async initialize() {
            await api.getFornecedores()
            .then(response => {
                this.fornecedores = response.data.fornecedores
                this.loadingFornecedores = false
                this.numPagesFornecedores = response.data.pages
            })
            .catch(err => {
                this.$root.vtoast.show({
                    message: `Erro ao carregar fornecedores: ${err}`,
                    icon: 'mdi-close',
                    color: 'error'
                })
            }),
            await api.getProvedores()
            .then(response => {
                this.provedores = response.data.provedores
                this.loadingProvedores = false
                this.numPagesProvedores = response.data.pages
            })
            .catch(err => {
                this.$root.vtoast.show({
                    message: `Erro ao carregar provedores: ${err}`,
                    icon: 'mdi-close',
                    color: 'error'
                })
            })
            await this.$store.dispatch('global/fetchMoedas')
            .catch(err => {
                this.$root.vtoast.show({
                    message: `Erro ao carregar moedas: ${err}`,
                    icon: 'mdi-close',
                    color: 'error'
                })
            })
        },
        async refreshFornecedores(data){
            await api.getFornecedores(data.page, data.filters)
            .then(response => {
                this.fornecedores = response.data.fornecedores
                this.loadingFornecedores = false
                this.numPagesFornecedores = response.data.pages
            })
            .catch(err => {
                this.$root.vtoast.show({
                    message: `Erro ao carregar fornecedores: ${err}`,
                    icon: 'mdi-close',
                    color: 'error'
                })
            })
        },
        async refreshProvedores(data){
            await api.getProvedores(data.page, data.filters)
            .then(response => {
                this.provedores = response.data.provedores
                this.loadingProvedores = false
                this.numPagesProvedores = response.data.pages
            })
            .catch(err => {
                this.$root.vtoast.show({
                    message: `Erro ao carregar provedores: ${err}`,
                    icon: 'mdi-close',
                    color: 'error'
                })
            })
        }
        
    }
}
</script>