import {
  get,
  post,
  put,
  del,
  postFormData,
  convertToFormData,
  filter,
  paginate,
} from "../modules";

export default {
  getContatos(page = 1, filters = []) {
    const paginatedURL = paginate("/api/contatos/", page);
    const filteredURL = filter(paginatedURL, filters);

    return get(filteredURL);
  },

  getContato(id) {
    return get(`/api/contatos/${id}`);
  },

  postContato(data) {
    return post("/api/contatos/", data);
  },

  putContato(id, data) {
    return put(`/api/contatos/${id}/`, data);
  },

  deleteContato(id) {
    return del(`/api/contatos/${id}/`);
  },

  // Vendas
  getContatoVendas(cliente_id) {
    return get(`/api/contatos/${cliente_id}/vendas/`);
  },
  getContatoCotacoesPago(cliente_id) {
    return get(`/api/contatos/${cliente_id}/cotacoes_pago`);
  },

  // Empresas
  putContatoEmpresas(contato_id, data) {
    return put(`/api/contatos/${contato_id}/empresa/`, data);
  },

  // Passaporte
  async postPassaporte(contato_id, passaporte) {
    const formData = convertToFormData(passaporte);
    return postFormData(`/api/contatos/${contato_id}/passaporte/`, formData);
  },

  getContatoPassaporte(cliente_id) {
    return get(`/api/contatos/${cliente_id}/passaporte`);
  },

  deleteContatoPassaporte(cliente_id, data) {
    return post(`/api/contatos/${cliente_id}/passaporte_delete/`, data);
  },

  // CNH
  getContatoCNH(cliente_id) {
    return get(`/api/contatos/${cliente_id}/cnh`);
  },

  async postContatoCNH(cliente_id, data) {
    const formData = convertToFormData(data);
    return postFormData(`/api/contatos/${cliente_id}/cnh/`, formData);
  },

  deleteContatoCNH(cliente_id, data) {
    return post(`/api/contatos/${cliente_id}/cnh_delete/`, data);
  },

  // Identidade
  getContatoIdentidade(cliente_id) {
    return get(`/api/contatos/${cliente_id}/identidade`);
  },

  async postContatoIdentidade(contato_id, data) {
    const formData = convertToFormData(data);
    return postFormData(`/api/contatos/${contato_id}/identidade/`, formData);
  },

  deleteContatoIdentidade(cliente_id, data) {
    return post(`/api/contatos/${cliente_id}/identidade_delete/`, data);
  },

  // Beneficiarios
  getContatoBeneficiarios(cliente_id) {
    return get(`/api/contatos/${cliente_id}/beneficiarios/`);
  },
  // Emissões
  getContatoEmissoesBeneficiario(cliente_id) {
    return get(`/api/contatos/${cliente_id}/emissoes_beneficiario`);
  },
  // Contas Fidelidade
  getContatoContasFidelidade(cliente_id){
    return get(`/api/contatos/${cliente_id}/contas_fidelidade`);
  },
  // Servicos
  getContatoServicos(cliente_id) {
    return get(`/api/contatos/${cliente_id}/servicos/`);
  },
  // Cotações milhas
  getContatoCotacoesMilhas(cliente_id) {
    return get(`/api/contatos/${cliente_id}/cotacoes_milhas/`);
  },
};
