<template>
  <v-container class="pb-0">
    <v-card class="mx-auto">
      <v-card-title class="text-h6 ml-2 mb-1 pb-1 d-flex">
        <v-icon>mdi-airplane</v-icon>
        Vôo
      </v-card-title>
      <v-card-text class="texto">
        <v-row class="pl-2">
          <v-col class="pl-6 mb-2" cols="9">
            <v-row>
              <div class="my-0 text-subtitle-1">
              {{ tipoCotacao(cotacao) }}
            </div>
            </v-row>
            
            <v-row v-for="subcotacao in cotacao.cotacao_voo.subcotacoes" :key="subcotacao.id">
              {{ descricaoSubcotacaoVoo(subcotacao) }}
            </v-row>
            <v-row>
              Passageiros: {{ descricaoPassageiros(beneficiarios) }}
            </v-row>
          </v-col>
          <v-col cols="3" class="text-right black--text font-weight-medium"  >
            <v-row class="texto">
              Valor total: {{ cotacaoValorTotalJuros(cotacao) |dinheiroReal}}
            </v-row>
              <v-row class="texto" >
              Valor em aberto: {{ valorAbertoCotacoes[cotacao.id] |dinheiroReal}}
            </v-row>
            
          </v-col>
        </v-row>
      </v-card-text>

    </v-card>
  </v-container>
</template>
  
<script>
import { formatDates } from "@/helpers/dates.js"
import { numPassageiros } from "@/helpers/pernas.js"
import { tipoCotacao } from "@/helpers/cotacao.js"
import cotacaoMixin from "@/mixins/CotacaoMixin";
export default {
  props: {
    cotacao: {
      type: Object,
      default: () => { }
    },
    beneficiarios: {
      type: Array,
      default: () => []
    },
    valorAbertoCotacoes: {
      type: Object,
      default: () => { }
    },
  },
  mixins: [cotacaoMixin],


  data() {
    return {
      dialog: false,
    };
  },
  methods: {
    tipoCotacao(cotacao) {
      return tipoCotacao(cotacao)
    },

    descricaoSubcotacaoVoo(subcotacao) {
      const pernas = subcotacao.viagem.trechos.map(trecho => trecho.pernas).flat(Infinity)
      const info_voo = pernas.map(perna => {
        let info_viagem = `${(perna.cia_aerea? perna.cia_aerea.nome_resumido:"" || "")} ${perna.data_hora_partida ? formatDates(new Date(perna.data_hora_partida)) + ":" : ""}\
                ${perna.origem ? perna.origem.aeroporto_iata : " "}\ ->
                ${perna.data_hora_chegada ? formatDates(new Date(perna.data_hora_chegada)) + ":" : ""}\
                ${perna.destino ? perna.destino.aeroporto_iata : ""}`.trim()
        return info_viagem
      }
      )
      return info_voo.join("; \n")
    },
    descricaoPassageiros(beneficiarios) {
      const info_passageiros = beneficiarios.map(beneficiario => {
        return `${this.$options.filters.nomeSobrenome(beneficiario.contato)}\
            ${beneficiario.assento ? " - Assento: " + beneficiario.assento : ""}\
            ${beneficiario.tipo_assento ? " - Tipo assento: " + beneficiario.tipo_assento : ""}\
            ${beneficiario.bagagem ? " - Tipo bagagem: " + beneficiario.bagagem : ""}\
            ${beneficiario.tipo_quarto ? " - Tipo quarto: " + beneficiario.tipo_quarto : ""}`
      })
      return info_passageiros.join("; \n")
    }

  }

};
</script>
<style >
.texto {
  color:black;
}

</style>