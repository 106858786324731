<template>
  <div>
    <v-dialog
      v-model="dialog"
      persistent
      max-width="600px"
      @click:outside="closeDialog()"
    >
    <v-card>
      <v-card-title>
        <span class="text-h5"> Imagens </span>
        <v-spacer/>
        <v-btn color="primary" @click="createDialog = true">
          <v-icon left>mdi-image-plus</v-icon>
          Adicionar imagem
        </v-btn>
      </v-card-title>
      <v-card-text>
        <v-row>
          <v-col
            v-for="image,i in images"
            :key="i"
            class="d-flex child-flex"
            cols="4"
          >
          <v-hover v-slot:default="{ hover }">
              <v-img
                :src="image.imagem"
                aspect-ratio="1"
                class="grey lighten-2"
              >
                <div
                  v-if="hover"
                  class="d-flex darken-2 v-card--reveal white--text"
                >
                  <v-btn color="blue" @click="previewImage(image.id)">
                    <v-icon color="white">mdi-eye</v-icon>
                  </v-btn>
                  
                  <v-spacer></v-spacer>
                  
                  <v-btn color="red" @click="deleteImage(image.id)">
                    <v-icon color="white">mdi-delete</v-icon>
                  </v-btn>
                </div>
              </v-img>
            </v-hover>
            </v-col>
            <v-col>
              <div v-if="images.length==0">
                  Nenhuma imagem foi encontrada para esse provedor.
              </div>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </v-dialog>

    <!-- Preview -->
  <v-dialog 
    v-if="imagePreview"
    v-model="previewDialog"
    max-width="800px"
  >
    <v-card>
      <v-card-title>
        <span class="text-h5"> {{ imagePreview.titulo }} </span>
      </v-card-title>
      <v-card-text>
        <v-img :src="imagePreview.imagem"></v-img>
        <span>Descrição: <br> {{ imagePreview.descricao }}</span>
      </v-card-text>
      <v-card-actions>
        <v-btn @click="previewDialog=false">Fechar</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>

  <!-- Adicionar -->
  <v-dialog
    v-model="createDialog"
    persistent
    max-width="600px"
  >
    <v-card>
      <v-card-title>
        <span class="text-h5"> Cadastrar nova imagem </span>
      </v-card-title>
      <v-card-text>
        <v-container>
          <v-row>
            <v-col>
              <v-file-input 
                show-size
                name="image"
                label="Selecionar imagem*"
                v-model="loadedImage"
                :value="imagePath"
                accept="image/*"
                prepend-inner-icon="mdi-camera"
                prepend-icon=""
                filled
              />
            </v-col>

            <v-col cols="12" v-if="imageURL">
              <v-img :src="imageURL" />
            </v-col>

            <v-col cols="12">
              <v-text-field
                label="Título da imagem*"
                prepend-inner-icon="mdi-pencil"
                v-model="title"
                filled
                required
              />
            </v-col>
            <v-col>
              <v-textarea
                filled
                label="Descrição da imagem"
                v-model="description"
                prepend-inner-icon="mdi-pencil"
              />
            </v-col>
          </v-row>
        </v-container>
      </v-card-text>
      <v-card-actions>
        <v-btn
          color="red"
          text
          @click="closeDialog()"
        >
          Fechar
        </v-btn>
        <v-spacer></v-spacer>
        <v-btn
          color="blue darken-1"
          text
          :loading="loadingSaveButton"
          @click="saveImage()"
        >
          Salvar
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</div>
</template>

<script>
import api from "@/api/api"

export default {
  props: {
    provedorId: {
      type: Number,
      required: true
    },
    dialog: {
      default: false
    }
  },

  data: () => ({
    createDialog: false,
    images: [],

    loadedImage: null,
    title: "",
    description: "",
    imagePath: "",

    previewDialog: false,
    imagePreview: null,

    loadingSaveButton: false,
    loadingGetImages: true
  }),

  computed: {
    imageURL() {
      if (!this.loadedImage) return null;
      return URL.createObjectURL(this.loadedImage)
    }
  },

  created() {
    this.getImages()
  },

  methods: {
    async saveImage() {
      const data = {
        "title": this.title,
        "image": this.loadedImage,
        "description": this.description
      }

      this.loadingSaveButton = true

      await api.postProvedorImage(this.provedorId, data)
        .then(res => {
          this.$root.vtoast.show({
            message: "Imagem adicionada com sucesso!"
          })
          this.$emit('close')
        })
        .catch(error => {
          console.error(error)
          this.$root.vtoast.show({
            message: "Ocorreu um erro ao tentar adicionar essa imagem.",
            color: "error",
            icon: "mdi-close"
          })
        })
        .finally(() => {
          this.loadingSaveButton = false
        })
    },

    async getImages() {
      const response = await api.getProvedorImages(this.provedorId)
      this.images = response.data
    },

    deleteImage(imageId) {
      if(!confirm("Tem certeza que deseja DELETAR essa imagem? Essa ação é irreversível."))
        return

      api.deleteProvedorImage(this.provedorId, imageId)
        .then(response => {
          this.$root.vtoast.show({
            message: "Imagem deletada com sucesso!"
          })
          this.images.splice(this.images.indexOf(imageId), 1)
        })
    },

    async previewImage(imageId) {
      this.imagePreview = this.images.find(image => image.id == imageId)
      this.previewDialog = true
    },

    closeDialog() {
      this.$emit('close')
    }
  }
}
</script>
