import {post, get} from '../modules';

export default {
    postBusca(data) {
        return post(`/api/moblix/busca/`, data);
    },
    getBusca(id) {
        return get(`/api/moblix/${id}`)
    }
}
