function valorTotalServico(servico) {
    let tipo = servico.orcamento.tipo_servico
    if (!tipo)
        return 0;

    switch (tipo) {
        case "voo_milha":
            const voo_milha = servico.voo_milha;
            const qtd_milhas = Number(voo_milha.qtd_milhas);
            const precoMilha = 25.1 / 1000.0;
            const valoresExtras = (voo_milha.valores_extras || []).map(item => Number(item.valor))
            const res = [
                Number(voo_milha.taxa_bagagem),
                Number(voo_milha.taxa_embarque),
                Number(voo_milha.taxas_extras),
                qtd_milhas * Number(precoMilha),
                ...valoresExtras
            ]
            return res.reduce((a, b) => a + b, 0)
        case "voo_pagante":
            const voo_pagante = servico.voo_pagante;
            const valoresExtrasPagante = (voo_pagante.valores_extras || []).map(item => Number(item.valor))
            const resPagante = [
                Number(voo_pagante.taxa_bagagem),
                Number(voo_pagante.taxa_embarque),
                Number(voo_pagante.taxas_extras),
                Number(voo_pagante.valor_bilhete),
                ...valoresExtrasPagante
            ]
            return resPagante.reduce((a, b) => a + b, 0);
        case "hotel":
            const hotel = servico.hotel;
            const resHotel = [
                Number(hotel.valor_total),
            ]
            return resHotel.reduce((a, b) => a + b, 0)
        case "carro":
            let cotacaoCarro = servico.cotacao_carro;
            let valorTotal = 0;
            if(cotacaoCarro) {
                valorTotal = cotacaoCarro.custo_total.valor
            }
            return valorTotal
        case "orcamento_outro":
            const outro = servico.outro;
            const resOutro = [
                Number(outro.valor_total),
            ]
            return resOutro.reduce((a, b) => a + b, 0);
        default:
            return 0;
    }
};

const getAddressByCep = async (cep) => {
    const filteredCep = cep.match(/\d+/g).join('')
    if(filteredCep.length !== 8) return
    const URL = `https://viacep.com.br/ws/${filteredCep}/json/`
    const data = await fetch(URL);
    const address = await data.json();
    return address;
};

export {
    valorTotalServico,
    getAddressByCep,
}
