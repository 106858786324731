<!-- Utilizado em vendas -->

<template>
  <v-container fluid>
    <v-row>
      <v-col>
        <v-row :key="solicitacao.idx" v-for="(solicitacao, index) in formSolicitacoesOrcamento" class="ma-4">
          <v-col cols="12" md="11">
            <novo-orcamento-row v-model="formSolicitacoesOrcamento[index]"></novo-orcamento-row>
          </v-col>
          <v-spacer></v-spacer>
          <v-col cols="1" align-self="center">
            <v-row class="pb-2">
              <v-btn @click="duplicarOrcamento(solicitacao)">Duplicar</v-btn>
            </v-row>
            <v-row class="pt-2">
              <v-btn color="error" @click="removerOrcamento(solicitacao)">Remover</v-btn>
            </v-row>
          </v-col>
        </v-row>
        <v-row class="ma-4">
          <v-menu
              top

          >
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                  small
                  color="primary"
                  v-bind="attrs"
                  v-on="on"
              >
                <v-icon>mdi-plus</v-icon>
                Adicionar Solicitação
              </v-btn>
            </template>

            <v-list>
              <v-list-item link dense @click="adicionarNovaSolicitacao('orcamento_voo')">
                <v-list-item-title
                >
                  Vôo
                </v-list-item-title>
              </v-list-item>
              <v-list-item
                  link
                  dense
                  @click="adicionarNovaSolicitacao('orcamento_hotel')">
                <v-list-item-title
                >
                  Hotel
                </v-list-item-title>
              </v-list-item>
              <v-list-item
                  link
                  dense
                  @click="adicionarNovaSolicitacao('orcamento_carro')">
                <v-list-item-title
                >
                  Aluguel de Carro
                </v-list-item-title>
              </v-list-item
              >
              <v-list-item
                  link
                  dense
                  @click="adicionarNovaSolicitacao('orcamento_outro')">
                <v-list-item-title
                >
                  Outro
                </v-list-item-title>
              </v-list-item>
            </v-list>
          </v-menu>

        </v-row>
        <v-row>
          <v-divider class="my-3"></v-divider>
        </v-row>
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
import NovoOrcamentoRow from "@/components/Orcamento/NovoOrcamentoRow";

export default {
  components: {NovoOrcamentoRow},
  props: {
    value: {
      type: Array
    },

  },
  data() {
    return {
      formSolicitacoesOrcamento: null,
      count: 0,
      loading: false
    };
  },
  created() {
    this.formSolicitacoesOrcamento = this.value ? [...this.value] : [];
  },
  watch: {
    formSolicitacoesOrcamento: {
      deep: true,
      handler: function (val) {
        this.input();
      }
    }
  },
  methods: {
    clear() {
      this.formSolicitacoesOrcamento = []
    },
    adicionarNovaSolicitacao(tipo) {
      let solicitacao = {
        idx: this.count++,
        tipo_servico: tipo,
      }
      this.formSolicitacoesOrcamento.push(solicitacao);
    },
    removerOrcamento(orcamento) {
      let index = this.formSolicitacoesOrcamento.indexOf(orcamento)
      this.formSolicitacoesOrcamento.splice(index, 1);
    },
    duplicarOrcamento(orcamento) {
      let newItem = Object.assign({}, orcamento)
      newItem.idx = this.count++;
      this.formSolicitacoesOrcamento.push(newItem);
    },
    input() {
      this.$emit('input', this.formSolicitacoesOrcamento);
    },
    getOrcamentos() {
      return this.formSolicitacoesOrcamento;
    },
  }
}

</script>
