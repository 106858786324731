<template>
  <v-container fluid v-if="form" class="ma-0">
    <v-row class="ma-0">
      <v-col>
        <search-local
            :disabled="disabled"
            :outlined="true"
            label="Retirada"
            v-model="form.local_retirada"></search-local>
            <v-checkbox label="Flexibilidade retirada" v-model="form.flexibilidade_retirada"></v-checkbox>
      </v-col>
      <v-col>
        <search-local
            :disabled="disabled"
            v-model="form.local_devolucao"
            :outlined="true"
            label="Devolução"></search-local>
            <v-checkbox label="Flexibilidade devolução" v-model="form.flexibilidade_devolucao"></v-checkbox>
      </v-col>
      <v-col>
        <form-date-picker-multiple
            :disabled="disabled"
            v-model="form.data_retirada"
            outlined label="Ida"></form-date-picker-multiple>
      </v-col>
      <v-col>
        <form-date-picker-multiple
            v-model="form.data_devolucao"
            multiple outlined label="Volta"
            :disabled="disabled || form.tipo==='so_ida'"></form-date-picker-multiple>
      </v-col>
      <v-col align-self="center">
        <v-combobox
            outlined
            dense
            label="Tipo de Veículo"
            :items="['Carro', 'Van', 'Caminhão']"
            v-model="form.tipo_veiculo">

        </v-combobox>
      </v-col>
    </v-row>
    <v-text-field label="Descrição rápida" v-model="form.descricao" :rules="[
              (v) =>
                (v || '').length <= 30 ||
                'Descrições devem ter menos de 30 caracteres',
            ]"></v-text-field>
  </v-container>
</template>
<script>

import SearchAeroporto from "@/components/widgets/SearchAeroporto";
import FormDatePicker from "@/components/widgets/FormDatePicker";
import FormDatePickerMultiple from "@/components/widgets/FormDatePickerMultiple";
import SearchLocal from "@/components/widgets/SearchLocal";
import { validateDates } from "@/helpers/dates";

export default {
  components: {SearchLocal, FormDatePickerMultiple, FormDatePicker, SearchAeroporto},
  props: {
    value: {
      type: Object,
      required: false,
      default: () => null
    },
    disabled: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  data() {
    return {
      showOptionsMenu: false,
      form: null,

    }
  },
  watch: {
    form: {
      immediate: true,
      deep: true,
      handler: function () {
        this.input();

        if (this.form) {
          const { data_retirada, data_devolucao } = this.form;
          if (validateDates(data_retirada[0], data_devolucao[0])) {
            this.form.data_devolucao = [];
          }
        } 
      }
    },

  },
  created() {
    const defaultForm = {
    }
    this.form = {...defaultForm};
    if (this.value) {
      this.form = {
        ...this.form,
        ...this.value
      }
    }

    this.input()
  },
  methods: {
    input(val) {
      if (!this.form)
        return;
      this.$emit('input', this.form);

    }
  }

}
</script>
