<template>
  <v-card class="px-8 pb-8 pt-4">
    <div v-for="cotacao in cotacoes" :key="cotacao.id">
      Cotacão #{{ cotacao.id }}
    </div>

    <!-- <v-row>
            <v-col>
              <v-checkbox v-model="informacoesPagamento[cotacao.id].tiposPagamento" label="À vista" value="pagamentoAVista"></v-checkbox>
            </v-col>
            <v-col>
              <v-checkbox v-model="informacoesPagamento[cotacao.id].tiposPagamento" label="Parcelado com Entrada" value="pagamentoParceladoEntrada">
              </v-checkbox>
            </v-col>
            <v-col>
              <v-checkbox v-model="informacoesPagamento[cotacao.id].tiposPagamento" label="Parcelado" value="pagamentoParcelado"></v-checkbox>
            </v-col>
          </v-row>
          <v-row v-if="informacoesPagamento[cotacao.id].tiposPagamento.includes('pagamentoAVista')" class="d-flex flex-column">
              <h4> Pagamento à vista </h4>
              Desconto máximo: {{cotacaoValorTotalJuros(cotacao, 12) - cotacaoValorTotalJuros(cotacao, 0) |dinheiroReal}}
              <v-moeda-field
              outlined
              label="Desconto"
              v-model="informacoesPagamento[cotacao.id].pagamentoAVista.desconto"/>
          </v-row>
  
          <v-row v-if="informacoesPagamento[cotacao.id].tiposPagamento.includes('pagamentoParceladoEntrada')" class="d-flex flex-column">
              
                  <h4> Pagamento parcelado com entrada </h4>
              <v-select
              :items="quantidadeParcelas"
              label="Número de parcelas"
              v-model="informacoesPagamento[cotacao.id].pagamentoParceladoEntrada.numeroParcelas"
              ></v-select>
              <v-moeda-field
              outlined
              label="Valor da entrada"
              v-model="informacoesPagamento[cotacao.id].pagamentoParceladoEntrada.valorEntrada"/>
  
              <div v-if="informacoesPagamento[cotacao.id].pagamentoParceladoEntrada.numeroParcelas && informacoesPagamento[cotacao.id].pagamentoParceladoEntrada.valorEntrada">
                  Desconto máximo: {{(cotacaoValorTotalJuros(cotacao, 12)-
                      converteValorFinanceiroBRL(informacoesPagamento[cotacao.id].pagamentoParceladoEntrada.valorEntrada)-
                      valorTotalJurosEntrada(cotacao, informacoesPagamento[cotacao.id].pagamentoParceladoEntrada.numeroParcelas, converteValorFinanceiroBRL(informacoesPagamento[cotacao.id].pagamentoParceladoEntrada.valorEntrada))) |dinheiroReal}}
                  <v-moeda-field
                  outlined
                  label="Desconto"
                  v-model="informacoesPagamento[cotacao.id].pagamentoParceladoEntrada.desconto"/>
              </div>
            
          </v-row>
          
          <v-row v-if="informacoesPagamento[cotacao.id].tiposPagamento.includes('pagamentoParcelado')" class="d-flex flex-column">
                  <h4>Pagamento parcelado</h4>
                  <v-select
                      :items="quantidadeParcelas"
                      label="Número de parcelas"
                      v-model="informacoesPagamento[cotacao.id].pagamentoParcelado.numeroParcelas"
                  />
                  <div v-if="informacoesPagamento[cotacao.id].pagamentoParcelado.numeroParcelas">
                      <span>
                          Desconto máximo: {{cotacaoValorTotalJuros(cotacao, 12) - cotacaoValorTotalJuros(cotacao, informacoesPagamento[cotacao.id].pagamentoParcelado.numeroParcelas) | dinheiroReal}}
                      </span>
                      
                      <v-moeda-field
                          outlined
                          label="Desconto"
                          v-model="informacoesPagamento[cotacao.id].pagamentoParcelado.desconto"
                      />
                  </div>
              </v-row>
              <v-divider/>
          </div>-->

    <v-btn
      :loading="loading"
      @click="
        () => {
          $emit('exportar', informacoesPagamento, valorTotalCotacoes);
        }
      "
    >
      Exportar
    </v-btn>
  </v-card>
</template>
  
  <script>
import VMoedaField from "@/components/widgets/VMoedaField";
import CotacaoMixin from "@/mixins/CotacaoMixin";

export default {
  mixins: [CotacaoMixin],
  components: { VMoedaField },
  props: {
    cotacoes: {
      type: Array,
      default: () => [],
    },
    loading: {
      type: Boolean,
      default: false,
    },
    orcamento: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      tiposPagamento: [],
      valorEntrada: null,
      quantidadeParcelas: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12],
      defaultinformacoesPagamento: {
        tiposPagamento: [],
        pagamentoAVista: {
          name: "À vista",
          numeroParcelas: 0,
          desconto: null,
        },
        pagamentoParceladoEntrada: {
          name: "Parcelado com Entrada",
          valorEntrada: null,
          numeroParcelas: null,
          desconto: null,
        },
        pagamentoParcelado: {
          name: "Parcelado",
          numeroParcelas: null,
          desconto: null,
        },
      },
      informacoesPagamento: null,
    };
  },

  computed: {
    valorTotalCotacoes() {
      const valores = [];
      this.cotacoes.forEach((cotacao) => {
        const cotacaoValorTotal = this.cotacaoCustoTotal(cotacao);
        const valorTotalFormatado =
          this.$options.filters.dinheiroReal(cotacaoValorTotal);
        valores.push({
          valor: cotacaoValorTotal,
          valor_formatado: valorTotalFormatado,
        });
      });
      return valores;
    },
  },

  mounted() {
    this.informacoesPagamento = Object.fromEntries(
      this.cotacoes.map((cotacao) => [
        cotacao.id,
        { ...this.defaultinformacoesPagamento, cotacao: cotacao.id },
      ])
    );
  },

  methods: {
    valorTotalJurosEntrada(cotacao, n, entrada) {
      return this.calculoJuros(this.cotacaoValorTotal(cotacao) - entrada, n);
    },
  },
};
</script>

<!-- <template>
    <v-card class="px-8 pb-8 pt-4" >
        <div v-for="cotacao in cotacoes" :key="cotacao.id" v-if="informacoesPagamento">
            Cotacão #{{cotacao.id}}
          <v-row>
          <v-col>
            <v-checkbox v-model="informacoesPagamento[cotacao.id].tiposPagamento" label="À vista" value="pagamentoAVista"></v-checkbox>
          </v-col>
          <v-col>
            <v-checkbox v-model="informacoesPagamento[cotacao.id].tiposPagamento" label="Parcelado com Entrada" value="pagamentoParceladoEntrada">
            </v-checkbox>
          </v-col>
          <v-col>
            <v-checkbox v-model="informacoesPagamento[cotacao.id].tiposPagamento" label="Parcelado" value="pagamentoParcelado"></v-checkbox>
          </v-col>
        </v-row>
        <v-row v-if="informacoesPagamento[cotacao.id].tiposPagamento.includes('pagamentoAVista')" class="d-flex flex-column">
            <h4> Pagamento à vista </h4>
            Desconto máximo: {{cotacaoValorTotalJuros(cotacao, 12) - cotacaoValorTotalJuros(cotacao, 0) |dinheiroReal}}
            <v-moeda-field
            outlined
            label="Desconto"
            v-model="informacoesPagamento[cotacao.id].pagamentoAVista.desconto"/>
        </v-row>

        <v-row v-if="informacoesPagamento[cotacao.id].tiposPagamento.includes('pagamentoParceladoEntrada')" class="d-flex flex-column">
            
                <h4> Pagamento parcelado com entrada </h4>
            <v-select
            :items="quantidadeParcelas"
            label="Número de parcelas"
            v-model="informacoesPagamento[cotacao.id].pagamentoParceladoEntrada.numeroParcelas"
            ></v-select>
            <v-moeda-field
            outlined
            label="Valor da entrada"
            v-model="informacoesPagamento[cotacao.id].pagamentoParceladoEntrada.valorEntrada"/>

            <div v-if="informacoesPagamento[cotacao.id].pagamentoParceladoEntrada.numeroParcelas && informacoesPagamento[cotacao.id].pagamentoParceladoEntrada.valorEntrada">
                Desconto máximo: {{(cotacaoValorTotalJuros(cotacao, 12)-
                    converteValorFinanceiroBRL(informacoesPagamento[cotacao.id].pagamentoParceladoEntrada.valorEntrada)-
                    valorTotalJurosEntrada(cotacao, informacoesPagamento[cotacao.id].pagamentoParceladoEntrada.numeroParcelas, converteValorFinanceiroBRL(informacoesPagamento[cotacao.id].pagamentoParceladoEntrada.valorEntrada))) |dinheiroReal}}
                <v-moeda-field
                outlined
                label="Desconto"
                v-model="informacoesPagamento[cotacao.id].pagamentoParceladoEntrada.desconto"/>
            </div>
          
        </v-row>
        
        <v-row v-if="informacoesPagamento[cotacao.id].tiposPagamento.includes('pagamentoParcelado')" class="d-flex flex-column">
                <h4>Pagamento parcelado</h4>
                <v-select
                    :items="quantidadeParcelas"
                    label="Número de parcelas"
                    v-model="informacoesPagamento[cotacao.id].pagamentoParcelado.numeroParcelas"
                />
                <div v-if="informacoesPagamento[cotacao.id].pagamentoParcelado.numeroParcelas">
                    <span>
                        Desconto máximo: {{cotacaoValorTotalJuros(cotacao, 12) - cotacaoValorTotalJuros(cotacao, informacoesPagamento[cotacao.id].pagamentoParcelado.numeroParcelas) | dinheiroReal}}
                    </span>
                    
                    <v-moeda-field
                        outlined
                        label="Desconto"
                        v-model="informacoesPagamento[cotacao.id].pagamentoParcelado.desconto"
                    />
                </div>
            </v-row>
            <v-divider/>
        </div>
        <v-row>
            <v-btn :loading="loading" @click="() => { $emit('exportar', informacoesPagamento, valorTotalCotacoes) }">
                Exportar 
            </v-btn>
        </v-row>
    </v-card>
</template>

<script>
import VMoedaField from "@/components/widgets/VMoedaField";
import CotacaoMixin from "@/mixins/CotacaoMixin";

export default {
    mixins: [CotacaoMixin],
    components: {VMoedaField},
    props: {
        cotacoes: {
            type: Array,
            default: () => []
        },
        loading: {
            type: Boolean,
            default: false
        }
    },
    data() {
        return {
            tiposPagamento:[],
            valorEntrada: null,
            quantidadeParcelas: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12],
            defaultinformacoesPagamento: {
                tiposPagamento: [],
                pagamentoAVista: {
                    name: "À vista",
                    numeroParcelas:0,
                    desconto: null
                },
                pagamentoParceladoEntrada: {
                    name: "Parcelado com Entrada",
                    valorEntrada: null,
                    numeroParcelas: null,
                    desconto: null
                },
                pagamentoParcelado: {
                    name: "Parcelado",
                    numeroParcelas: null,
                    desconto: null
                }
            },
            informacoesPagamento: null
        }
    },

    computed: {
        valorTotalCotacoes(){
            const valores = []
            this.cotacoes.forEach(cotacao => {
                const cotacaoValorTotal = this.cotacaoCustoTotal(cotacao)
                const valorTotalFormatado = this.$options.filters.dinheiroReal(cotacaoValorTotal)
                valores.push({
                    "valor": cotacaoValorTotal,
                    "valor_formatado": valorTotalFormatado
                })
            })
            return valores
        }
    },

    mounted() {
        this.informacoesPagamento = Object.fromEntries(
            this.cotacoes.map((cotacao) => 
                [cotacao.id, {...this.defaultinformacoesPagamento, cotacao:cotacao.id}]
            ))
    },

    methods: {
        valorTotalJurosEntrada(cotacao, n, entrada) {
            return this.calculoJuros(this.cotacaoValorTotal(cotacao)-entrada, n)
        },
    }
}

</script> -->