<template>
  <v-card :persistent="true">
    <v-card-title>
      <span class="text-h5"> Selecionar quem indicou o contato</span>
      <v-spacer />
    </v-card-title>
    <v-card-text>
      <v-col>
        <v-row>
          <ContatosTable
            :contatos="contatosIndicacao"
            :numPages="numPages"
            :loading="loadingTable"
            @refresh="reloadContatos"
            @rowClick="select"
            :filters="filters"
          />
        </v-row>
      </v-col>
    </v-card-text>
    <v-card-actions>
      <v-btn @click="$emit('close')">Cancelar</v-btn>
      <v-spacer></v-spacer>
    </v-card-actions>
  </v-card>
</template>

<script>
import ContatosTable from "../Contatos/ContatosTable.vue";
export default {
  components: {
    ContatosTable
  },
  props: {
    value: {
      type: [Object, Number],
    },
    contatosIndicacao: {
      type: Array,
      default: () => [],
    },
    numPages: {
      type: Number,
      default: 1
    },
    filters: {
      type: Array,
      default: () => [],
    },
  },

  data() {
    return {
      dialogContatoRapido: null,
      search: "",
      headers: [
        {
          text: "Nome",
          align: "start",
          sortable: false,
          value: "nome",
        },
        { text: "Perfil", value: "perfil" },
        { text: "CPF", value: "cpf" },
        { text: "Email", value: "email" },
        { text: "Telefone", value: "telefone" },
        { text: "Indicação", value: "indicacao" },
        { text: "Data de Criação", value: "created_at" },
      ],
      loadingTable: false,
    };
  },

  methods: {
    select(contato) {
      this.$emit("input", contato);
      this.$emit("close");
    },

    reloadContatos(data) {
      this.$emit("refresh", data);
    },
  },
};
</script>
