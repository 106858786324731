<template>
    <v-container class="ma-4" fluid>
        <v-row>
            <v-col>
                <div class="text-h5 transition-swing">
                    Empresas
                </div>
                <v-breadcrumbs :items="breadcumb_items" />
            </v-col>

            <CriarEmpresa @refresh="initialize()" />
        </v-row>

        <v-row>
            <v-col cols="12">
                <v-container fluid >
                    <v-row>
                        <v-col cols="12">
                            <v-card rounded="10" :loading="loadingTable">
                                <v-card-title>
                                    <v-col cols="12" md="4">
                                        <v-text-field
                                            dense
                                            outlined
                                            clearable
                                            clear-icon="mdi-close"
                                            append-icon="mdi-magnify"
                                            label="Pesquisa"
                                            v-model="search"
                                        />
                                    </v-col>
                                    <v-spacer />
                                    <v-row>
                                        <v-col cols="12" md="6">
                                            <v-btn fab text x-small @click="initialize()">
                                                <v-icon> mdi-refresh </v-icon>
                                            </v-btn>
                                        </v-col>
                                    </v-row>
                                </v-card-title>
                                <v-card-text>
                                    <v-data-table
                                        @click:row="rowClick"
                                        :headers='headers'
                                        :items='empresas'
                                        :search="search"
                                        :hide-default-footer="true"
                                        :items-per-page="15"
                                        sort-by='name'
                                    >
                                        <template v-slot:no-data>
                                            Nenhuma empresa encontrada.
                                        </template>

                                        <template v-slot:[`item.data_nascimento`]='{ item }'>
                                            {{item.data_nascimento | convertDataBR}}
                                        </template>

                                        <template v-slot:[`item.telefone`]='{ item }'>
                                            {{item.telefone.numero }}
                                        </template>

                                        <template v-slot:[`item.created_by`]='{ item }'>
                                            {{item.created_by | nomeSobrenome}}
                                        </template>

                                        <template v-slot:[`item.created_at`]='{ item }'>
                                            {{item.created_at | convertDataBR}}
                                        </template>

                                        <template v-slot:[`item.last_edited_by`]='{ item }'>
                                            {{item.last_edited_by | nomeSobrenome}}
                                        </template>

                                        <template v-slot:item.ultima_alteracao="{item}">
                                            <div>
                                            {{ item.updated_at| convertDataBR }} às {{ item.updated_at| dataHoraMinuto }}
                                            </div>
                                        </template>

                                        <template v-slot:[`item.nome`]=' { item } '>
                                            <v-icon color="yellow" v-if="item.tier == 'VIP'"> mdi-crown </v-icon>
                                            {{ item.nome }}
                                        </template>
                                    </v-data-table>
                                        <Paginator
                                            v-model="currentPage"
                                            :numPages="numPagesEmpresas"
                                            @pageChange="pageChange"
                                        />
                                </v-card-text>
                            </v-card>
                        </v-col>
                    </v-row>
                </v-container>
            </v-col>
        </v-row>
    </v-container>
</template>
<script>
import CriarEmpresa from "@/components/Empresas/CriarEmpresa.vue";
import Paginator from '@/components/widgets/Paginator.vue'
import api from "@/api/api"

export default {
    components: {CriarEmpresa, Paginator},
    props:{
        numPages: {
          type: Number,
          default: 1
        },
    },
    data() {
        return {
        loadingSaving: false,
        loadingTable: true,
        breadcumb_items: [
            {
                text: 'Home',
                to: "home"
            },
            {
                text: 'Empresas',
                to: "empresas"
            }
        ],
        filter: {
            status: ""
        },
        search: '',
        headers: [
                {
                    text: 'Nome',
                    sortable: true,
                    value: 'nome'
                },
                { text: "Razão Social", value: "razao_social"},
                { text: 'Telefone', value: 'telefone' },
                { text: 'Email', value: 'email' },
                { text: 'Criado por', value: 'created_by' },
                { text: 'Data criação', value: 'created_at' },
                { text: 'Atualizado por', value: 'last_edited_by' },
                { text: 'Data última alteração', value: 'ultima_alteracao' },
            ],
        currentPage: 1,
        numPagesEmpresas: 1,
        empresas: [],       
        }
    },
    

    async mounted() {
        await this.initialize()
    },

    methods: {
        rowClick(value) {
            this.$router.push({ name: 'empresa', params: { id: value.id } })
        },

        async initialize() {
            this.loadingTable = true
            await api.getEmpresas()
            .then(response => {
                this.empresas = response.data.empresas
                this.loadingTable = false
                this.numPagesEmpresas = response.data.pages
            })
            .catch(err => {
                this.$root.vtoast.show({
                    message: `Erro ao carregar empresas: ${err}`,
                    icon: 'mdi-close',
                    color: 'error'
                })
            })
            },
            
            async refreshEmpresas(page){
                this.loadingTable = true;
                await api.getEmpresas(page)
                .then(response => {
                    this.empresas = response.data.empresas
                    this.numPagesEmpresas = response.data.pages
                })
                .catch(err => {
                    this.$root.vtoast.show({
                        message: `Erro ao carregar empresas: ${err}`,
                        icon: 'mdi-close',
                        color: 'error'
                    })
                })
                .finally(() => {
                    this.loadingTable = false
                })
            },
            async pageChange(page) {
                this.currentPage = page;
                await this.refreshEmpresas(page);
            },               
    },
};
</script>
