<template >
  <v-card class="my-6 elevation-5" :loading="loading">
    <v-card-title>Serviços validados</v-card-title>
    <v-card-text>
      <v-data-table
        :items="servicos"
        :headers="headers"
        v-if="servicos"
        :hide-default-footer="true"
        :items-per-page="15"
      >
        <template v-slot:item.cotacao="{ item }">
          {{ item.cotacao?.id }}
        </template>
        <template v-slot:item.orcamento="{ item }">
          <v-link-text-field
            :to="{
              name: 'orcamento',
              params: { id: item?.cotacao?.orcamento?.id },
            }"
            text
            small
          >
            # {{ item.cotacao?.orcamento?.id }}
          </v-link-text-field>
        </template>
        <template v-slot:item.status="{ item }">
          {{ item.status | formatStatusServico }}
        </template>
        <template v-slot:item.valor_pre_desconto="{ item }">
          {{ cotacaoValorTotalJuros(item.cotacao) | dinheiroReal }}
        </template>
        <template v-slot:item.valor_desconto="{ item }">
          {{ converteValorFinanceiroBRL(item.desconto) | dinheiroReal }}
        </template>
        <template v-slot:item.valorTotal="{ item }">
          {{ servicoValorTotal(item) | dinheiroReal }}
        </template>
        <template v-slot:item.metodo_pagamento="{ item }">
          {{ item.metodo_pagamento }}
        </template>
        <template v-slot:item.venda="{ item }">
          <v-link-text-field
            :to="{ name: 'venda-pagamentos', params: { id: item?.venda?.id } }"
            text
            small
          >
            # {{ item.venda.id }}
          </v-link-text-field>
        </template>
        <template v-slot:item.consultor="{ item }">
          {{ item.venda.consultor | nomeSobrenome }}
        </template>
        <template v-slot:item.validado_por="{ item }">
          {{ item.validado_por | nomeSobrenome }}
        </template>
        <template v-slot:item.cliente="{ item }">
          {{ item.venda.cliente | nomeSobrenome }}
          {{ item.venda.cliente_empresa?.nome }}
        </template>
      </v-data-table>
      <v-pagination v-model="currentPage" :length="pages" :total-visible="7" />
    </v-card-text>
  </v-card>
</template>
  <script>
import api from "@/api/api";
import cotacaoMixin from "@/mixins/CotacaoMixin";
import VLinkTextField from "@/components/widgets/VLinkTextField";
import { formatApiError } from "@/helpers/apiErrors";

export default {
  mixins: [cotacaoMixin],
  components: { VLinkTextField },

  props: {
    servicos: {
      type: Array,
      default: () => [],
    },
    loading: {
      type: Boolean,
      default: true,
    },
    pages: {
      type: Number,
      default: 6,
    },
  },

  data: () => ({
    headers: [
      { text: "Servico ID", value: "id" },
      { text: "Venda ID", value: "venda" },
      { text: "Cliente", value: "cliente" },

      { text: "Consultor", value: "consultor" },
      { text: "Orçamento ID", value: "orcamento" },
      { text: "Cotação ID", value: "cotacao" },
      { text: "Valor pré Desconto", value: "valor_pre_desconto" },
      { text: "Valor Desconto", value: "valor_desconto" },
      { text: "Valor total", value: "valorTotal" },
      { text: "Método de Pagamento", value: "metodo_pagamento" },
      { text: "Status", value: "status" },
      { text: "Validado por", value: "validado_por" },
    ],
    currentPage: 1,
  }),
  computed: {},
  methods: {
    changePage() {
      this.$emit("atualizar", this.currentPage);
    },
  },
  watch: {
    currentPage(val) {
      this.changePage();
    },
  },
};
</script>