<template>
  <v-container fluid v-if="form">
    <div>Destinos no bilhete:</div>
    <v-row fluid>
      <v-col cols="auto" :key="item.value" v-for="item in destino_opcoes">
        <v-checkbox
          dense
          :label="item.text"
          v-model="form[item.value]"
          @change="handleCheckboxChange(item.value)"
        ></v-checkbox>
      </v-col>
    </v-row>
    <v-row fluid>
      <v-col cols="2">
        <v-select
          dense
          outlined
          v-model="form.tipo_cotacao"
          :items="tipoBilhete"
          item-text="value"
          item-value="key"
        >
        </v-select>
      </v-col>
      <v-col v-if="['pagante', 'wallet'].includes(form.tipo_cotacao)">
        <v-moeda-field
          dense
          outlined
          v-model="form.custo_bilhete"
          label="*Custo do bilhete"
        ></v-moeda-field>
      </v-col>
      <v-col v-if="form.tipo_cotacao === 'milha'">
        <v-autocomplete
          dense
          outlined
          :items="fidelidades"
          item-text="nome"
          item-value="id"
          label="Fidelidade"
          v-model="programa_fidelidade"
        >
        </v-autocomplete>
      </v-col>
      <v-col v-if="form.tipo_cotacao === 'milha'">
        <vuetify-money
          dense
          v-bind:outlined="true"
          prepend-inner-icon="mdi-medal"
          v-bind:label="'Qtd. de milhas'"
          v-model.number="form.quantidade_milhas"
          v-bind:options="{ precision: 0, locale: 'pt-BR' }"
        ></vuetify-money>
      </v-col>
      <!-- v-if gambiarra para o valor aparecer  -->
      <v-col v-if="form.tipo_cotacao === 'pagante'">
        <v-moeda-field
          outlined
          dense
          label="RAV via fornecedor"
          v-model="form.rav"
        ></v-moeda-field>
      </v-col>
      <v-col v-if="form.tipo_cotacao">
        <v-moeda-field
          outlined
          dense
          label="Taxa de embarque"
          v-model="form.custo_taxa_embarque"
        ></v-moeda-field>
      </v-col>
      <v-col v-if="form.tipo_cotacao">
        <v-moeda-field
          outlined
          dense
          label="Valor da bagagem"
          v-model="form.custo_taxa_bagagem"
        ></v-moeda-field>
      </v-col>
      <v-col v-if="form.tipo_cotacao">
        <v-moeda-field
          outlined
          dense
          label="Extras"
          v-model="form.custo_taxas_extras"
        ></v-moeda-field>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <v-select
          :items="fornecedores"
          v-model="form.fornecedor"
          outlined
          dense
          label="Fornecedor"
          item-value="id"
          item-text="nome"
          v-if="form.tipo_cotacao === 'pagante'"
        >
        </v-select>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <div v-if="form.tipo_cotacao == 'pagante'" cols="4">
          <CotacaoImageSelect
            v-for="provedor in provedores"
            :key="provedor.id"
            :provedor="provedor"
            v-model="form.imagens_provedor"
          />
        </div>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="6">
        <div v-if="form.tipo_cotacao === 'milha'">
          <v-dialog v-model="milheiroDialog" persistent max-width="1000px">
            <template v-slot:activator="{ on, attrs }">
              <v-btn icon v-bind="attrs" v-on="on">
                <v-icon medium>mdi-pencil</v-icon>
              </v-btn>
            </template>
            <v-card>
              <v-card-title>
                <span class="text-h5">Alterar valor do milheiro</span>
              </v-card-title>
              <v-card-text>
                <v-container>
                  <v-row>
                    <v-col>
                      <v-text-field
                        label="Novo valor"
                        required
                        type="number"
                        v-model="milheiroEdit"
                      ></v-text-field>
                    </v-col>
                  </v-row>
                </v-container>
              </v-card-text>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn
                  color="blue darken-1"
                  text
                  @click="milheiroDialog = false"
                >
                  Close
                </v-btn>
                <v-btn color="blue darken-1" text @click="saveMilheiro">
                  Save
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>
          Milheiro fixado: {{ this.form.milheiro | dinheiroReal }}
        </div>
      </v-col>
      <v-col v-if="form.tipo_cotacao === 'milha'" cols="6">
        <v-row>
          <template>
            <v-slider
              v-model="milheiroFixadoPlus"
              :min="-2.5"
              :max="2.5"
              :step="0.5"
              thumb-label
            ></v-slider>
          </template>
        </v-row>
        <v-row>
          <v-col>
            <v-field-value label="Milheiro Fixado">{{
              this.form.milheiro | dinheiroReal
            }}</v-field-value>
            <v-field-value label="Lucro Próprio">{{
              this.lucroMilheiroCusto(
                this.form.milheiro,
                this.milheiroCustoProprio,
                this.milheiroCustoTerceiros,
                this.form.quantidade_milhas
              ).lucroProprio | dinheiroReal
            }}</v-field-value>
            <v-field-value label="Lucro Terceiros">{{
              this.lucroMilheiroCusto(
                this.form.milheiro,
                this.milheiroCustoProprio,
                this.milheiroCustoTerceiros,
                this.form.quantidade_milhas
              ).lucroTerceiros | dinheiroReal
            }}</v-field-value>
          </v-col>
          <v-col>
            <v-field-value label="Milheiro Próprio">{{
              this.milheiroCustoProprio | dinheiroReal
            }}</v-field-value>
            <v-field-value label="Milheiro Terceiros">{{
              this.milheiroCustoTerceiros | dinheiroReal
            }}</v-field-value>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <v-textarea
          v-if="temCustoExtra"
          rows="3"
          filled
          label="Explicações dos custos extras"
          counter
          :rules="rules"
          v-model="form.anotacao_custo_extra"
        ></v-textarea>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <v-field-value label="Custo Total">{{
          custo_total | dinheiroReal
        }}</v-field-value>
        <p>* Inclui taxas RC e OB (Air France e KLM).</p>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import VMoedaField from "@/components/widgets/VMoedaField";
import api from "@/api/api";
import CotacaoMixin from "@/mixins/CotacaoMixin";
import VFieldValue from "@/components/widgets/VFieldValue";

import CotacaoImageSelect from "./CotacaoImageSelect";

import tipoBilhete from "@/constants/tipoBilhete";

const CLASSES = ["economica", "executiva", "primeira_classe"];

export default {
  components: { VMoedaField, VFieldValue, CotacaoImageSelect },
  mixins: [CotacaoMixin],
  props: {
    value: {
      type: Object,
      default: () => {},
    },
    ciaAereas: {
      type: Array,
    },
  },
  data() {
    return {
      form: {},
      fidelidades: [],
      rules: [(v) => (!v ? false : v.length <= 500 || "Máximo 500 caracteres")],
      milheiroDialog: false,
      milheiroEdit: null,
      preloadedMilheiro: null,
      tipoBilhete: tipoBilhete,
      milheiroFixadoPlus: 0.0,
      disableSlider: false,
      imagesDialog: false,
      programa_fidelidade: "",
      allProvedores: [],
      destino_opcoes: [
        {
          text: "Ida",
          value: "bilhete_ida",
        },
        {
          text: "Volta",
          value: "bilhete_volta",
        },
        {
          text: "Independente",
          value: "bilhete_independente",
        },
        {
          text: "FK",
          value: "bilhete_fk",
        },
      ],
    };
  },
  async mounted() {
    this.initialize();
  },
  computed: {
    custo_total() {
      return this.subcotacaoVooCustoTotal(this.form);
    },
    temCustoExtra() {
      if (!this.form.custo_taxas_extras) return false;
      if (this.form.custo_taxas_extras.valor === 0) return false;
      return true;
    },
    programaFidelidade() {
      return this.programa_fidelidade;
    },
    quantidadeMilhas() {
      return this.form.quantidade_milhas;
    },
    getClasse() {
      if (!this.form.viagem) return "";
      const trechos = this.form.viagem.trechos;
      if (!trechos) return "";
      const pernas = trechos.map((trecho) => trecho.pernas).flat();
      if (!pernas) return "";
      const classes = pernas.reduce(
        (curr, obj) => ({
          economica: obj.economica || curr.economica,
          executiva: obj.executiva || curr.executiva,
          primeira_classe: obj.primeira_classe || curr.primeira_classe,
        }),
        {}
      );
      for (let classe of CLASSES) if (classes[classe]) return classe;
    },
    getCiaAerea() {
      if (!this.form.viagem) return "";
      const trechos = this.form.viagem.trechos;
      if (!trechos) return "";
      const pernas = trechos.map((trecho) => trecho.pernas).flat();
      if (!pernas) return "";
      const cias = new Set(pernas.map((perna) => perna?.cia_aerea));
      if (!cias) return;
      return [...cias][0]; //pegando o primeiro por facilidade
    },
    fornecedores() {
      return this.$store.state.global.fornecedores;
    },

    provedores() {
      const provedoresEscohidos = [];

      for (let j = 0; j < this.ciaAereas.length; j++) {
        const ids_escolhidos = this.ciaAereas[j];

        for (let i = 0; i < this.allProvedores.length; i++) {
          const comProvedores = this.allProvedores[i].cia_aerea;
          if (ids_escolhidos == comProvedores) {
            provedoresEscohidos.push(this.allProvedores[i].id);
          }
        }
      }
      return provedoresEscohidos;
    },
    milheiroCustoProprio() {
      return this.getMilheiroCustoProprio(this.form.programa_fidelidade);
    },
    milheiroCustoTerceiros() {
      return this.getMilheiroCustoTerceiros(this.form.programa_fidelidade);
    },
  },
  methods: {
    handleCheckboxChange(selectedCheckbox) {
      if (
        selectedCheckbox === "bilhete_independente" &&
        this.form["bilhete_independente"]
      ) {
        this.form["bilhete_ida"] = false;
        this.form["bilhete_volta"] = false;
      } else if (
        selectedCheckbox === "bilhete_ida" &&
        this.form["bilhete_independente"]
      ) {
        this.form["bilhete_independente"] = false;
      } else if (
        selectedCheckbox === "bilhete_volta" &&
        this.form["bilhete_independente"]
      ) {
        this.form["bilhete_independente"] = false;
      }
    },
    initialize() {
      this.form = { ...this.value };
      if (this.form.milheiro) this.preloadedMilheiro = this.form.milheiro; // salvando o preloaded
      if (this.form.programa_fidelidade)
        this.programa_fidelidade = this.form.programa_fidelidade;
      if (!this.form.valor_ajuste)
        this.form.valor_ajuste = { valor: 0, moeda: null };
      api.getProgramas().then((response) => {
        this.fidelidades = response.data;
      });
      this.atualizarMilheiro();

      api
        .getProvedores(0, [["tipo", "aereo"]])
        .then((response) => {
          this.allProvedores = response.data;
        })
        .catch((error) => {
          console.error(error);
        });
    },
    editMilheiroFixado() {
      this.milheiroDialog = true;
    },
    saveMilheiro() {
      const piso = this.getMilheiro(
        this.form.programa_fidelidade,
        this.form.quantidade_milhas,
        this.getCiaAerea,
        this.getClasse,
        true
      );
      if (this.form.quantidade_milhas == 0 || !this.form.quantidade_milhas) {
        this.$root.vtoast.show({
          message: `Qtd. de milhas deve ser maior que zero`,
          color: "error",
          icon: "mdi-close",
        });
        this.loadingAdicionarPassaporte = false;
        return;
      }
      if (parseFloat(this.milheiroEdit) < piso) {
        this.$root.vtoast.show({
          message: `Milheiro menor que o piso permitido ${piso}`,
          color: "error",
          icon: "mdi-close",
        });
        this.loadingAdicionarPassaporte = false;
        return;
      }
      this.preloadedMilheiro = this.milheiroEdit;
      this.milheiroDialog = false;
      this.form.milheiro = this.milheiroEdit;
      this.disableSlider = true;
      this.milheiroFixadoPlus = 0.0;
      // gambiarra para atualizar o formulario na hora certa
      this.$emit("input", { ...this.form });
    },
    atualizarMilheiro() {
      if (!this.preloadedMilheiro) {
        this.form = {
          ...this.form,
          programa_fidelidade: this.programa_fidelidade,
          milheiro: (
            parseFloat(
              this.getMilheiro(
                this.programa_fidelidade,
                this.form.quantidade_milhas,
                this.getCiaAerea,
                this.getClasse
              )
            ) + parseFloat(this.milheiroFixadoPlus)
          ).toFixed(2),
        };
      } else {
        this.form = {
          ...this.form,
          milheiro: (
            parseFloat(this.preloadedMilheiro) +
            parseFloat(this.milheiroFixadoPlus)
          ).toFixed(2),
        };
      }
    },
  },
  watch: {
    form: {
      immediate: true,
      deep: true,
      handler: function (val) {
        this.$emit("input", val);
      },
    },
    getClasse() {
      this.atualizarMilheiro();
    },
    programaFidelidade(val) {
      this.atualizarMilheiro();
    },
    quantidadeMilhas(val) {
      this.atualizarMilheiro();
    },
    milheiroFixadoPlus(val) {
      this.atualizarMilheiro();
    },
  },
};
</script>
