import Vue from "vue"
import STATUS_ORCAMENTO from "@/constants/statusOrcamento"
import TIPO_ORCAMENTO from "@/constants/tipoOrcamento"
import formatStatus from "@/helpers/formatStatus"

const formatStatusOrcamento = formatStatus(STATUS_ORCAMENTO)
const formatTipoOrcamento = formatStatus(TIPO_ORCAMENTO)
const formatTiposOrcamento = tipos => tipos.map(formatTipoOrcamento).join(', ')

Vue.filter('formatStatusOrcamento', formatStatusOrcamento)
Vue.filter('formatTipoOrcamento', formatTipoOrcamento)
Vue.filter('formatTiposOrcamento', formatTiposOrcamento)