import { get, post, put, del, filter, paginate} from '../modules'

export default {
    getTabelaMilhas() {
        return get("/api/milhas/")
    },

    putTabelaMilhas(id, data) {
        return put("/api/milhas/"+id+"/", data)
    },

    deleteTabelaMilhas(id) {
        return del("/api/milhas/"+id)
    },

    getHistoricoMilhas(date=null) {
        if(!date)
            date = new Date().toISOString().substring(0, 7)

        let url = "/api/milhas/historico/?date="+date

        return get(url)
    },

    getCotacoesMilhasValidadas(page=1, filters=[]){
        const paginatedURL = paginate('/api/milhas-validadas/', page)
        const filteredURL = filter(paginatedURL, filters)

        return get(filteredURL);
    },

    createCotacoesMilhasValidadas(data){
        return post('/api/milhas-validadas/validate/', data)
    },
    getDesagioProprio(){
        return get(`/api/milhas-validadas/desagio_compra/`)
    },


}
