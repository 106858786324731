<template>
  <v-container fluid v-if="orcamento_hotel || cotacao_hotel">
    <v-row>
      <v-col>
        <v-row>
          <v-field-value label="Local">
          {{orcamento_hotel?.local || cotacao_hotel?.local_hotel | localCidadeResumida}}
        </v-field-value>
        </v-row>
        <v-row>
          <v-col>
        <v-field-value label="Flexibilidade" v-if="orcamento_hotel">
          {{ orcamento_hotel.flexibilidade ? "Sim" : "Não"}}
        </v-field-value>
      </v-col>
        </v-row>
      </v-col>
      <v-col>
        <v-row>
          <v-col>
            <v-field-value label="Checkin">
              {{ orcamento_hotel?.checkin_inicio || cotacao_hotel?.checkin_inicio | convertDataBR }}
              -
              {{ orcamento_hotel?.checkin_fim || cotacao_hotel?.checkin_fim | convertDataBR }}
            </v-field-value>
          </v-col>
        </v-row>
        <v-row>
          <v-col>

            <v-field-value label="Checkout">
              {{ orcamento_hotel?.checkout_inicio || cotacao_hotel?.checkout_inicio | convertDataBR }}
              -
              {{ orcamento_hotel?.checkout_fim || cotacao_hotel?.checkout_fim | convertDataBR }}
            </v-field-value>
          </v-col>
        </v-row>
      </v-col>
      <v-col>
        <v-field-value label="Quartos">
          {{ orcamento_hotel?.quartos || cotacao_hotel?.quartos}}
        </v-field-value>
      </v-col>
      <v-col>
        <v-row>
          <v-col>
            <v-field-value label="Adultos">
              {{ (orcamento_hotel?.adultos || cotacao_hotel?.adultos) }}
            </v-field-value>
          </v-col>
        </v-row>
        <v-row v-if="orcamento_hotel?.criancas || cotacao_hotel?.criancas">
          <v-col>
            <v-field-value label="Crianças">
              {{ orcamento_hotel?.criancas || cotacao_hotel?.criancas}}
            </v-field-value>
          </v-col>
        </v-row>
      </v-col>
      <v-col>
        <v-field-value label="Anotações">
          {{ orcamento_hotel?.anotacoes || cotacao_hotel?.anotacoes}}
        </v-field-value>
      </v-col>
    </v-row>
  </v-container>
  <v-container fluid v-else>
    <v-alert color="error">
      Erro ao exibir orçamento vinculado. Por favor, consulte a equipe técnica.
    </v-alert>
  </v-container>
</template>

<script>
import VFieldValue from "@/components/widgets/VFieldValue";

export default {
  components: {VFieldValue},
  props: {
    orcamento: {
      type: Object,
      default: null
    },
    item:{ // item == cotação
      type: Object,
      default: () => {},
    }
  },
  computed: {
    orcamento_hotel() {
      return this.orcamento && this.orcamento.orcamento_hotel;
    },
    cotacao_hotel(){
      return this.item  && this.item.cotacao_hotel;
    },
  },
}
</script>
