<template>
  <v-container>
    <v-card class="mx-auto">
      <v-card-title class="text-h6 ml-2 mb-2 d-flex">
        <v-icon>mdi-car</v-icon>
        Carro
      </v-card-title>
      <v-card-text>
        <v-row class="pl-2">
          <v-col class="pl-6 mb-2" cols="9">
            <v-row v-if="cotacao.cotacao_carro.local_retirada">
              Local retirada: {{cotacao.cotacao_carro.local_retirada | localCidadeResumida}}
            </v-row>
            <v-row v-if="cotacao.cotacao_carro.local_devolucao">
              Local devolução: {{cotacao.cotacao_carro.local_devolucao | localCidadeResumida}}
            </v-row>
            <v-row v-if="cotacao.cotacao_carro.empresa">
              Empresa: {{cotacao.cotacao_carro.empresa}}
            </v-row>
            <v-row v-if="cotacao.cotacao_carro.tipo_carro">
              Carro: {{cotacao.cotacao_carro.tipo_carro}}
            </v-row>
            <v-row v-if="cotacao.cotacao_carro.data_hora_retirada">
              Data de retirada: {{cotacao.cotacao_carro.data_hora_retirada | dataHoraBR}}
            </v-row>
            <v-row v-if="cotacao.cotacao_carro.data_hora_devolucao">
              Data de devolução: {{cotacao.cotacao_carro.data_hora_devolucao | dataHoraBR}}
            </v-row>
            <v-row>
              Beneficiários: {{descricaoBeneficiario(beneficiarios)}}
            </v-row>
          </v-col>
          <v-col cols="3" class="text-right black--text font-weight-medium"  >
            <v-row class="texto">
              Valor total: {{ cotacaoValorTotalJuros(cotacao) |dinheiroReal}}
            </v-row>
              <v-row class="texto">
              Valor em aberto: {{ valorAbertoCotacoes[cotacao.id] |dinheiroReal}}
            </v-row>
            
          </v-col>
        </v-row>
      </v-card-text>

    </v-card>
  </v-container>
</template>
  
<script>
import { formatDates } from "@/helpers/dates.js"
import { formatCidades } from '@/helpers/cidades.js'
import cotacaoMixin from "@/mixins/CotacaoMixin";
export default {
  props: {
    cotacao: {
      type: Object,
      default: () => { }
    },
    beneficiarios: {
      type: Array,
      default: () => []
    },
    valorAbertoCotacoes: {
      type: Object,
      default: () => { }
    },
  },
  mixins: [cotacaoMixin],


  data() {
    return {
      dialog: false,
    };
  },
  methods: {
    descricaoBeneficiario(beneficiarios) {
      const infoBeneficiario = beneficiarios.map(beneficiario => {
        return `${this.$options.filters.nomeSobrenome(beneficiario.contato)}`.trim()
      })
      return infoBeneficiario.join(",\n")
    }

  }

};
</script>
<style >
.texto {
  color:black;
}

</style>