<template>
  <v-container fluid class="ma-0">
    <!--          Orçamento vinculado    -->
    <v-row>
      <v-col>
        <v-row>
          <div class="text-subtitle-1 mx-4">Solicitação de orçamento vinculada</div>
          <!--      <v-spacer/>-->
          <v-btn small @click="esconderDetalhesOrcamento=!esconderDetalhesOrcamento">
            {{ esconderDetalhesOrcamento ? "Mostrar" : "Ocultar" }}
          </v-btn>
        </v-row>
        <v-row v-show="!esconderDetalhesOrcamento">
          <v-container fluid class="ma-2">

            <!--     Nenhum orçamento passado como opção     -->
            <v-row v-if="!orcamento">
              Nenhum orçamento vinculado
            </v-row>

            <!--        Hotel   -->
            <v-row v-else-if="orcamento.tipo_servico==='orcamento_hotel'">
              <cotacao-edit-form-orcamento-vinculado-hotel :orcamento="orcamento" :item="item"/>
            </v-row>
            <!--        Carro   -->
            <v-row v-else-if="orcamento.tipo_servico==='orcamento_carro'">
              <cotacao-edit-form-orcamento-vinculado-carro :orcamento="orcamento" :item="item"/>
            </v-row>

            <!--        Outro   -->
            <v-row v-else-if="orcamento.tipo_servico==='orcamento_outro'">
              <cotacao-edit-form-orcamento-vinculado-outro :orcamento="orcamento" :item="item"/>
            </v-row>

            <!--        Voo   -->
            <v-row v-else-if="orcamento.tipo_servico==='orcamento_voo'">
              <cotacao-edit-form-orcamento-vinculado-voo :orcamento="orcamento" :item="item"/>
            </v-row>

            <!--            Nenhuma das opções acima -->

            <v-row v-else>
              <v-alert color="error">
                Problema técnico: Orçamento não possui 'tipo_servico'
              </v-alert>
            </v-row>
          </v-container>

        </v-row>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import CotacaoEditFormOrcamentoVinculadoCarro from "./CotacaoEditFormOrcamentoVinculadoCarro";
import CotacaoEditFormOrcamentoVinculadoHotel from "./CotacaoEditFormOrcamentoVinculadoHotel";
import CotacaoEditFormOrcamentoVinculadoOutro from "./CotacaoEditFormOrcamentoVinculadoOutro";
import CotacaoEditFormOrcamentoVinculadoVoo from "./CotacaoEditFormOrcamentoVinculadoVoo";

export default {
  components: {
    CotacaoEditFormOrcamentoVinculadoVoo,
    CotacaoEditFormOrcamentoVinculadoOutro,
    CotacaoEditFormOrcamentoVinculadoHotel, CotacaoEditFormOrcamentoVinculadoCarro
  },
  props: {
    orcamento: {
      type: Object,
      default: null
    },
    item: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      form: {},
      esconderDetalhesOrcamento: true
    }
  }
}
</script>
