<template >
    <v-dialog v-model="value" max-width="800px">
      <v-card>
        <v-card-title class="text-h5">
          Dados
        </v-card-title>
        <v-spacer></v-spacer>
        <v-card-text>
          <p>Descrição: {{metaDados}}</p>
          <pre>{{prettyJson(dados)}}</pre>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="blue darken-1"
            text
            @click="close"
          >
            Fechar
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
</template>
<script>
import {prettyJson} from "@/helpers/json.js"

export default {

  props: {
    dados: {
      type: Object,
      default: () => {}
    },
    metaDados: {
      type: String,
      default: ""
    },
    value: {
      type: Boolean,
      default: false
    }
  },


  methods:{

    close(){
      this.$emit('input', false);
    },

    prettyJson(value){
      return prettyJson(value);
    },

  }
    
}
</script>