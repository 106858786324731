export default {
  emissao_voo_pagante: [
    {
      nome: "custo_bilhete",
      tipo: "valorFinanceiro",
    },
    {
      nome: "custo_taxa_embarque",
      tipo: "valorFinanceiro",
    },
    {
      nome: "custo_taxa_bagagem",
      tipo: "valorFinanceiro",
    },
    {
      nome: "custo_taxas_extras",
      tipo: "valorFinanceiro",
    },
    {
      nome: "rav",
      tipo: "valorFinanceiro",
    },
  ],
  emissao_voo_wallet: [
    {
      nome: "custo_bilhete",
      tipo: "valorFinanceiroImutavel",
    },
    {
      nome: "custo_taxa_embarque",
      tipo: "valorFinanceiro",
    },
    {
      nome: "custo_taxa_bagagem",
      tipo: "valorFinanceiro",
    },
    {
      nome: "custo_taxas_extras",
      tipo: "valorFinanceiro",
    },
    {
      nome: "rav",
      tipo: "valorFinanceiro",
    },
  ],
  emissao_voo_milha: [
    {
      nome: "milheiro",
      tipo: "float"
    },
    {
      nome: "custo_taxa_embarque",
      tipo: "valorFinanceiro",
    },
    {
      nome: "custo_taxa_bagagem",
      tipo: "valorFinanceiro",
    },
    {
      nome: "custo_taxas_extras",
      tipo: "valorFinanceiro",
    },
    {
      nome: "rav",
      tipo: "valorFinanceiro",
    },
  ],
  emissao_hotel: [
    {
      nome: "custo_diaria",
      tipo: "valorFinanceiro",
    },
    {
      nome: "custo_extras",
      tipo: "valorFinanceiro",
    },
    {
      nome: "rav",
      tipo: "valorFinanceiro",
    },
  ],
  emissao_carro: [
    {
      nome: "custo_diaria",
      tipo: "valorFinanceiro",
    },
    {
      nome: "custo_seguro",
      tipo: "valorFinanceiro",
    },
    {
      nome: "custo_extras",
      tipo: "valorFinanceiro",
    },
    {
      nome: "rav",
      tipo: "valorFinanceiro",
    },
  ],
  emissao_outro: [
    {
      nome: "custo_total",
      tipo: "valorFinanceiro",
    },
    {
      nome: "rav",
      tipo: "valorFinanceiro",
    },
  ],
};