<template>
  <v-card>
    <v-card-text>
      <v-data-table
        :items="racs"
        :loading="loading"
        :headers="headers"
        :hide-default-footer="true"
        sort-by="id"
        :items-per-page="15"
        sort-desc
      >
        <template v-slot:top>
          <v-container fluid>
            <v-row>
              <v-col cols="12" md="2">
                <v-select
                  clear-icon="mdi-close"
                  clearable
                  outlined
                  v-model="filter.tipo"
                  label="Tipo"
                  :items="tipoRac"
                  item-text="value"
                  item-value="key"
                  dense
                >
                </v-select>
              </v-col>
              <v-col cols="12" md="2">
                <v-autocomplete
                  clear-icon="mdi-close"
                  clearable
                  outlined
                  v-model="filter.consultor"
                  label="Consultor"
                  :item-text="(item) => `${item.nome} ${item.sobrenome}`"
                  :item-value="(item) => item?.id"
                  :items="users"
                  dense
                >
                </v-autocomplete>
              </v-col>
            </v-row>
          </v-container>
        </template>
        <template>
          <v-container fluid> </v-container>
        </template>

        <template v-slot:item.tipo="{ item }">
          {{ item.tipo | formatTipoRac }}
        </template>
        <template v-slot:item.venda="{ item }">
          <v-link-text-field
            v-if="showVenda"
            :to="{ name: 'venda-info', params: { id: item.venda?.id } }"
          >
            #{{ item.venda?.id | formatVendaId }}
          </v-link-text-field>
        </template>
        <template v-slot:item.cliente="{ item }">
          {{ item.venda?.cliente | nomeSobrenome }}
          {{ item.venda?.cliente_empresa?.nome }}
        </template>
        <template v-slot:item.consultor="{ item, value }">
          {{ value ? `${value.nome} ${value.sobrenome}` : "" }}
        </template>

        <template v-slot:item.created_by="{ item }">
          {{ item.created_by | nomeSobrenome }}
        </template>
        <template v-slot:item.created_at="{ item, value }">
          <div>
            {{ item.created_at | convertDataBR }} às
            {{ item.created_at | dataHoraMinuto }}
          </div>
        </template>

        <template v-slot:item.servico="{ item }">
          <v-link-text-field
            :to="{ name: 'servicos', params: { id: item.emissao?.servico } }"
          >
            #{{ item.emissao?.servico | formatId }}
          </v-link-text-field>
        </template>
        <template v-slot:item.emissao="{ item }">
          <v-link-text-field
            :to="{ name: 'emissao', params: { id: item.emissao?.id } }"
          >
            #{{ item.emissao?.id | formatId }}
          </v-link-text-field>
        </template>
        <template v-slot:item.tipo_emissao="{ item }">
          {{ item.emissao?.tipo | formatTipoEmissao }}
        </template>
        <template v-slot:item.actions="{ item }">
          <v-btn
            icon
            color="transparent"
            :to="{ name: 'venda-racs', params: { id: item.venda?.id } }"
          >
            <v-icon small color="primary" class="mr-2">mdi-open-in-new </v-icon>
          </v-btn>
        </template>
      </v-data-table>
      <v-pagination
        v-model="currentPage"
        :length="numPages"
        :total-visible="7"
      />
    </v-card-text>
  </v-card>
</template>
    <script>
import VLinkTextField from "@/components/widgets/VLinkTextField";
import tipoRac from "@/constants/tipoRac";
import TIPO_ORCAMENTO from "@/constants/tipoOrcamento";
import TIPO_SERVICO from "@/constants/tipoServico";
import api from "@/api/api";

export default {
  components: {
    VLinkTextField,
  },
  props: {
    loading: {
      type: Boolean,
      default: false,
    },
    pages: {
      type: Number,
      default: 1,
    },
    showVenda: {
      type: Boolean,
      default: false,
    },
  },
  data: () => ({
    headers: [
      { text: "Rac ID", value: "id" },
      { text: "Tipo", value: "tipo" },
      { text: "Cliente", value: "cliente" },
      { text: "Servico ID", value: "servico" },
      { text: "Emissão ID", value: "emissao" },
      { text: "Tipo Emissão", value: "tipo_emissao" },
      { text: "Criado Por", value: "created_by" },
      { text: "Criado Em", value: "created_at" },
      { text: "Ações", value: "actions" },
    ],
    currentPage: 1,
    filter: {
      tipo: "",
      consultor: "",
    },
    tipoRac,
    tipoOrcamento: TIPO_ORCAMENTO,
    tipoServico: TIPO_SERVICO,
    racsFinalizadas: [],
    racsAbertas: [],
    racsEmProcesso: [],
    racs: [],
    tab: null,
    loading: false,
    numPages: 1,
  }),
  methods: {
    changePage() {
      let filters = [];
      if (this.filter.consultor)
        filters.push(["consultor", this.filter.consultor]);
      if (this.filter.tipo) filters.push(["tipo", this.filter.tipo]);

      this.loading = true;
      api.getRACDetalhadoEmProcesso(this.currentPage, filters).then((res) => {
        this.racs = res.data.rac;
        this.loading = false;
      });
    },
    getRAC(page = 1, filters = []) {
      this.loading = true;
      api
        .getRACDetalhadoEmProcesso(page, filters)
        .then((res) => {
          this.racs = res.data.rac;
          this.numPages = res.data.pages;
        })
        .catch((err) => {
          console.error(err);

          this.$root.vtoast.show({
            message: "Erro ao carregar racs.",
            color: "error",
            icon: "mdi-close",
          });
        })
        .finally(() => {
          this.loading = false;
        });
    },
    initialize() {
      this.$store.dispatch("usuario/fetchUsuarios");
      this.getRAC();
    },
  },
  computed: {
    users() {
      return this.$store.getters["usuario/usuarios"];
    },
  },
  created() {
    if (!this.showVenda) return;
    this.headers.splice(3, 0, { text: "Venda ID", value: "venda" });
  },
  mounted() {
    this.initialize();
  },
  watch: {
    dialog(val) {
      val || this.close();
    },
    currentPage(val) {
      this.changePage();
    },
    filter: {
      deep: true,
      handler(val) {
        let filters = [];
        if (this.filter.consultor)
          filters.push(["consultor", this.filter.consultor]);
        if (this.filter.tipo) filters.push(["tipo", this.filter.tipo]);
        this.currentPage = 1;

        api.getRACDetalhadoEmProcesso(this.currentPage, filters).then((res) => {
          this.racs = res.data.rac;
          this.numPages = res.data.pages;
          this.loading = false;
        });
      },
    },
  },
};
</script>