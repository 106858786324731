import { get, post, del, put, paginate } from '../modules'

export default {
    getEmpresas(page = 1) {
        const paginatedUrl = paginate('/api/empresas/', page)
        return get(paginatedUrl)
    },

    getEmpresa(id) {
        return get(`/api/empresas/${id}/`)
    },

    postEmpresa(data) {
        return post(`/api/empresas/`, data)
    },

    putEmpresa(id, data) {
        return put(`/api/empresas/${id}/`, data)
    },
    
    deleteEmpresa(id) {
        return del(`/api/empresas/${id}/`)
    },

    getEmpresaRelacoes(id) {
        return get(`/api/empresas/${id}/relacoes/`)
    },

    getEmpresaVendas(id) {
        return get(`/api/empresas/${id}/vendas/`)
    },
    getEmpresaCotacoesPago(id) {
        return get(`/api/empresas/${id}/cotacoes_pago/`)
    },

    // Beneficiarios
    getEmpresaBeneficiarios(id) {
        return get(`/api/empresas/${id}/beneficiarios/`)
    },
    // Servicos
    getEmpresaServicos(id) {
        return get(`/api/empresas/${id}/servicos/`);
    }
}
