<template>
    <v-card>
      <v-card-title>Beneficiários</v-card-title>
      <v-card-text>
        <v-container>
          <v-col v-if="beneficiarios.length > 0">
            <v-row v-for="(beneficiario, idx) in beneficiarios" :key="idx">
              {{beneficiario?.contato | nomeSobrenome }}
            </v-row>
          </v-col>
          <v-col v-else>
            <v-row>
              Nenhum Beneficiário para esse bilhete.
            </v-row>
          </v-col>
        </v-container>
      </v-card-text>
    </v-card>
  </template>
  <script>
 
  
  export default {
    
    props: {
      beneficiarios: {
        type: Array,
        default: () => []
      }
    },
  
    methods: {
      close(){
        this.$emit('close')
      }
    }
  
  };
  </script>
  