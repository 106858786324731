<!-- Necessário passar os props id e value para funcionar -->

<template>
  <v-text-field
    :label="label"
    :id="id"
    :value="valor"
    :name="id"
    v-mask="vmask"
    v-model="valor"
    :prepend-inner-icon="icon"
    append-icon="mdi-content-copy"
    @click:append="copyContent(id)"
    :type="password || 'text'"
    :readonly="readonly"
    outlined
  >
  </v-text-field>
</template>

<script>
export default {
  props: {
    label: String,
    id: String,
    value: String,
    mask: {
      type: String,
      default: null,
    },
    icon: String,
    password: String,
    readonly: Boolean,
  },

  data() {
    return {
      vmask: this.mask || null,
      valor: this.value
    };
  },

  methods: {
    copyContent(id) {
      try {
        const input = document.getElementById(id);
        navigator.clipboard.writeText(input.value);
        this.$root.vtoast.show({ message: "Texto copiado com sucesso!" });
      } catch (e) {
        console.error(e);
        this.$root.vtoast.show({
          message: "Houve algum problema ao tentar copiar o texto.",
          color: "red accent-2",
          icon: "mdi-alert-circle-outline",
        });
      }
    },
  },
};
</script>
