<template>
  <v-container>
    <v-card class="mx-auto">
      <v-card-title class="text-h6 ml-2 mb-2 d-flex">
        Outros 
      </v-card-title>
      <v-card-text>
        <v-row class="pl-2">
          <v-col class="pl-6 mb-2" cols="9">
            <v-row v-if="cotacao.cotacao_outro.local">
              Local: {{ cotacao.cotacao_outro.local | localCidadeResumida}}
            </v-row>
            <v-row v-if="cotacao.cotacao_outro.nome_servico">
              Serviço: {{ cotacao.cotacao_outro.nome_servico }}
            </v-row>
            <v-row v-if="cotacao.cotacao_outro.fornecedor">
              Fornecedor: {{ cotacao.cotacao_outro.fornecedor }}
            </v-row>
            <v-row v-if="cotacao.cotacao_outro.descricao_servico">
              Descrição: {{ cotacao.cotacao_outro.descricao_servico }}
            </v-row>
            <v-row v-if="cotacao.cotacao_outro.data_inicio">
              Data início: {{cotacao.cotacao_outro.data_inicio | convertDataBR}}

            </v-row>
            <v-row v-if="cotacao.cotacao_outro.data_fim">
              Data fim: {{cotacao.cotacao_outro.data_fim | convertDataBR}}
            </v-row>

            <v-row>
              Beneficiários: {{descricaoBeneficiario(beneficiarios)}}
            </v-row>
          </v-col>
          <v-col cols="3" class="text-right black--text font-weight-medium"  >
            <v-row class="texto">
              Valor total: {{ cotacaoValorTotalJuros(cotacao) |dinheiroReal}}
            </v-row>
              <v-row class="texto">
              Valor em aberto: {{ valorAbertoCotacoes[cotacao.id] |dinheiroReal}}
            </v-row>
            
          </v-col>
        </v-row>
      </v-card-text>

    </v-card>
  </v-container>
</template>
  
<script>
import cotacaoMixin from "@/mixins/CotacaoMixin";
export default {
  props: {
    cotacao: {
      type: Object,
      default: () => { }
    },
    beneficiarios: {
      type: Array,
      default: () => []
    },
    valorAbertoCotacoes: {
      type: Object,
      default: () => { }
    },
  },
  mixins: [cotacaoMixin],


  data() {
    return {
      dialog: false,
    };
  },
  methods: {
    descricaoBeneficiario(beneficiarios) {
      const infoBeneficiario = beneficiarios.map(beneficiario => {
        return `${this.$options.filters.nomeSobrenome(beneficiario.contato)}`
      })
      return infoBeneficiario.join(",\n")
    }

  }

};
</script>
<style >
.texto {
  color:black;
}
</style>