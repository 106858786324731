<template>
    <v-card rounded="10" raised elevation="10" class="my-10">
      <v-row>
        <v-col>
          <v-data-table :headers="headers" :items="contasFidelidade" sort-by="id" sort-desc :loading="loadingTable">
            <template v-slot:no-data> Nenhuma conta fidelidade encontrada </template>
          </v-data-table>
        </v-col>
      </v-row>
    </v-card>
  </template>
  
  <script>
  import api from "@/api/api";
  import VLinkTextField from "@/components/widgets/VLinkTextField.vue";
  
  export default {
    components: { VLinkTextField },
    data() {
      return {
        contasFidelidade: [],
        loadingTable: false,
        headers: [
          {
            text: "Programa de Fidelidade",
            align: "start",
            sortable: true,
            value: "programa.nome",
          },
          {
            text: "Número-ID da Conta",
            value: "numero_id_conta",
          }
        ],
      };
    },
    mounted() {
      this.initialize();
    },
    created() {
    },
  
    methods: {
      initialize() {
        this.loading = true
        const cliente_id = this.$route.params.id;
        api.getContatoContasFidelidade(cliente_id).then((response) => {
          this.contasFidelidade = response.data;
        }).catch(e => {
          this.$root.vtoast.show({
            message: 'Erro ao buscar as contas fidelidade',
            color: 'error',
            icon: 'mdi-close',
          })
        }).finally(() => {
          this.loadingTable = false
        })
      },
  
  
    },
    computed: {
  
    },
  };
  </script>
  