<template>
  <div>
    <v-btn @click="dialog = true" color="green" :disabled="isButtonDisabled">
      <v-icon left> mdi-account-plus </v-icon>
      Criar Contato Rápido
    </v-btn>
    <v-row>
      <v-dialog v-model="dialog" :persistent="true" max-width="600px">
        <v-card>
          <v-card-title>
            <span class="text-h5">Criar Contato Rápido</span>
          </v-card-title>

          <v-card-text>
            <v-container>
              <v-row>
                <v-col cols="12">
                  <v-text-field
                    label="Nome"
                    v-model="nome"
                    outlined
                    prepend-inner-icon="mdi-account"
                    required
                  ></v-text-field>
                </v-col>
                <v-col cols="12">
                  <v-text-field
                    label="Sobrenome"
                    v-model="sobrenome"
                    outlined
                    prepend-inner-icon="mdi-account"
                    required
                  ></v-text-field>
                </v-col>
                <v-col cols="12">
                  <v-text-field
                    label="Indicação"
                    name="indicacao"
                    outlined
                    @click="openIndicacaoDialog"
                    @click:clear="selectContatoIndicacao(null)"
                    :value="indicacao | nomeSobrenome"
                    :rules="indicacaoRule"
                    clearable
                  />
                  <v-dialog v-model="indicacaoDialog" max-width="1200px">
                    <SelectContatosIndicacao
                      @close="indicacaoDialog = false"
                      @input="selectContatoIndicacao"
                      @refresh="loadContatos"
                      :numPages="numPages"
                      :contatosIndicacao="contatosIndicacao"
                      :filters="filters"
                      v-model="indicacao"
                    />
                  </v-dialog>
                </v-col>
              </v-row>
            </v-container>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="black" text @click="closeDialog"> Fechar </v-btn>
            <v-btn color="green" text @click="criarContato"> Criar </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-row>
  </div>
</template>

<script>
import api from "@/api/api";
import SelectContatosIndicacao from "@/components/widgets/SelectContatosIndicacao.vue";

export default {
  components: { SelectContatosIndicacao },

  props: {
    contatoRapidoDialog: {
      type: Boolean,
      default: false,
    },
    isButtonDisabled: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      dialog: null,
      indicacao: null,
      nome: null,
      sobrenome: null,
      indicacaoRule: [(v) => !!v || "Indicação é obrigatória!"],
      page: 1,
      allContatos: [],
      numPages: 1,
      indicacaoDialog: false,
      filters: [],
      // isButtonDisabled: true,
    };
  },

  mounted() {
    this.dialog = this.contatoRapidoDialog;
    this.loadContatos();
  },

  computed: {
    contatosIndicacao() {
      return this.allContatos;
    },
  },

  methods: {
    criarContato() {
      if (!this.indicacao) {
        this.$root.vtoast.show({
          message: "Indicação é obrigatória!",
          color: "error",
          icon: "mdi-close",
        });
        return;
      }

      const dados = {
        nome: this.nome,
        sobrenome: this.sobrenome,
        indicacao: this.indicacao.id,
        endereco: {},
        extras: {},
      };

      api
        .postContato(dados)
        .then((response) => {
          this.$emit("refresh");
          this.$root.vtoast.show({
            message: "Contato criado com sucesso!",
            icon: "mdi-account-plus",
            color: "success",
          });
        })
        .catch((e) => {
          this.$root.vtoast.show({
            message: "Erro ao criar contato!",
            icon: "mdi-account-minus",
            color: "error",
          });
          console.error(e);
        });

      this.dialog = false;
    },
    openIndicacaoDialog() {
      this.indicacaoDialog = true;
    },
    loadContatos(
      { page, filters } = { page: this.page, filters: this.filters }
    ) {
      api
        .getContatos(page, filters)
        .then((response) => {
          this.allContatos = response.data.contatos;
          this.numPages = response.data.pages;
        })
        .catch((err) => {
          this.$root.vtoast.show({ message: err.message, type: "error" });
        });
    },
    selectContatoIndicacao(contato) {
      this.indicacao = contato;
    },
    closeDialog() {
      this.dialog = false;
      this.nome = null;
      this.sobrenome = null;
      this.indicacao = null;
    },
  },
};
</script>
