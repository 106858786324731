<template>
  <v-container fluid>
    Usuaŕios emissões view:
    <usuario-emissoes-list></usuario-emissoes-list>
  </v-container>
</template>
<script>
import UsuarioEmissoesList from "@/components/usuario/UsuarioEmissoesList";
export default {
  components: {UsuarioEmissoesList}
}
</script>
