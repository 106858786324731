<template>
  <v-card>
    <v-data-table :headers="headers" :items="value" :loading="loading" class="elevation-5">
      <!-- TEMPLATES PARA OS VALORES DA TABELA -->

      <template v-slot:item.valor_total="{ item }">
        <!-- pode ser alterado para mostrar na moeda nativa -->
        {{ converteValorFinanceiroBRL(item.valor_total) | dinheiroReal }}
      </template>
      <template v-slot:item.valor_pagamento="{ item }">
        <!-- pode ser alterado para mostrar na moeda nativa -->
        {{ converteValorFinanceiroBRL(item.valor_pagamento) | dinheiroReal }}
      </template>

      <template v-slot:item.valor_base="{ item }">
        <!-- pode ser alterado para mostrar na moeda nativa -->
        {{ item.valor_base | dinheiroReal }}
      </template>
      <template v-slot:item.data_pagamento="{ item }">
        {{ item.data_pagamento | convertDataBR }}
      </template>
      <template v-slot:item.data_vencimento="{ item }">
        {{ item.data_vencimento | convertDataBR }}
      </template>
      <template v-slot:item.descricao="{ item }">
        <div text-wrap>{{ item.descricao }}</div>
      </template>
      <template v-slot:item.status="{ item }">
        {{ statusDescription(item.status) }}
      </template>
      <template v-slot:item.meio_pagamento="{ item }">
        {{ meioPagamentoDescription(item.meio_pagamento) }}
      </template>
      <template v-slot:item.servico="{ item }">
        {{ item.servico?.id }}
      </template>
      <template v-slot:item.cotacao="{ item }">
        {{ item.servico?.cotacao?.id }}
      </template>
      <template v-slot:item.orcamento="{ item }">
        {{ item.servico?.cotacao?.orcamento?.id }}
      </template>
      <template v-slot:item.created_by="{ item }">
        {{ item.created_by | nomeSobrenome }}
      </template>
      <template v-slot:footer>
      <tr>
        <td colspan="6">Total</td>
        <td>{{ valorTotal | dinheiroReal }}</td>
      </tr>
    </template>
      <template v-slot:top>
        <v-toolbar flat>
          <v-toolbar-title>Pagamentos</v-toolbar-title>
          <v-divider class="mx-4" inset vertical></v-divider>
          <v-spacer></v-spacer>
          <PagarMe 
            v-if="!deactivatePagarMe" 
            @updatePagamentos="updatePagamentos" 
            type="pix"
            color="blue"
            icon="mdi-qrcode-plus"
            :disabled="desativarBotoes"
            :servicosProp="servicos" 
            :pagamentosVenda="pagamentosVenda"
          />
          <PagarMe 
            v-if="!deactivatePagarMe" 
            @updatePagamentos="updatePagamentos" 
            type="cartao"
            icon="mdi-credit-card-plus"
            :disabled="desativarBotoes"
            :servicosProp="servicos" 
            :pagamentosVenda="pagamentosVenda"
          />
          <v-spacer></v-spacer>
          <v-dialog
            v-model="dialog"
            max-width="1000px"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-btn color="primary" class="mb-2 white--text" v-bind="attrs" v-on="on" :disabled="desativarBotaoMaisPagamento">
                <v-icon left>mdi-plus</v-icon> Pagamento
              </v-btn>
            </template>
            <!-- EDITED ITEM -->
            <new-pagamento v-model="editedItem" :formTitle="formTitle" :servicos="servicos"
              :loadingServicos="loadingServicos" @save="save" @close="close" :pagamentosVenda="pagamentosVenda"
              :deactivateServico="deactivateServico" :deactivateCheckItem="deactivateCheckItem"
              :precoMilheiro="precoMilheiro" />
          </v-dialog>
          <v-dialog v-model="dialogDelete" max-width="500px">
            <v-card>
              <v-card-title class="text-h5">Tem certeza que deseja deletar esse pagamento?</v-card-title>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="blue darken-1" text @click="closeDelete">Cancelar</v-btn>
                <v-btn color="blue darken-1" text @click="deleteItemConfirm">OK</v-btn>
                <v-spacer></v-spacer>
              </v-card-actions>
            </v-card>
          </v-dialog>
        </v-toolbar>
      </template>
      <template v-slot:item.actions="{ item }">
        <v-icon small class="mr-2" v-if="item.pagarme_url" @click="copiarLink(item)">
          mdi-link
        </v-icon>
        <v-icon small class="mr-2" @click="viewComprovante(item.id)">
          mdi-eye-outline
        </v-icon>
        <!-- Agora são segregados por área -->
        <v-icon small class="mr-2" @click="editItem(item)">
          mdi-pencil
        </v-icon>
        <v-icon small :disabled="desativarBotoesSeletivos" @click="deleteItem(item)">
          mdi-delete
        </v-icon>
        <!-- --------------  -->
      </template>
      <template v-slot:no-data>
        <v-btn color="primary" @click="initialize">
          Reset
        </v-btn>
      </template>
    </v-data-table>
  </v-card>
</template>
<script>
import { converteValorFinanceiroBRL } from "@/helpers/financeiro";

import api from '@/api/api'
import checkPermissao from "@/helpers/checkUserPermissao.js";

import { mapState } from "vuex";

import PagarMe from '@/components/Pagamento/PagarMe'
import NewPagamento from './NewPagamento'

import MEIOS_DE_PAGAMENTO from '@/constants/meiosPagamento'
import STATUS from '@/constants/statusPagamento'

import CotacaoMixin from "@/mixins/CotacaoMixin";



const EMPTY_ITEM = {
  id: null,
  venda: null,
  servico: null,
  descricao: "",
  url: "",
  status: "",
  data_pagamento: null,
  data_vencimento: null,
  valor_total: {
    moeda: null,
    valor: 0
  },
  valor_pagamento: {
    moeda: null,
    valor: 0
  },
  pagamento_servicos: [],
  meio_pagamento: "",
  taxa_meio_pagamento: "",
  num_parcelas: null, //integer
};

export default {

  components: { PagarMe, NewPagamento },

  props: {

    servicos: {
      type: Array,
      default: () => []
    },

    value: { // referente a pagamentos
      type: Array,
      default: () => []
    },
    pagamentosVenda: {
      type: Array,
      default: () => []
    },

    loading: {
      type: Boolean,
      default: false
    },
    loadingServicos: {
      type: Boolean,
      default: false
    },

    venda: {
      type: Number,
      default: null
    },

    emissao: {
      type: Number,
      default: null
    },

    deactivateServico: {
      type: Boolean,
      default: false
    },
    deactivateCheckItem: {
      type: Boolean,
      default: false
    },
    deactivatePagarMe: {
      type: Boolean,
      default: false
    },
    precoMilheiro: {
      type: Boolean,
      default: false
    },

  },

  data: () => ({
    dialog: false,
    dialogDelete: false,
    meiosDePagamento: MEIOS_DE_PAGAMENTO,
    headers: [
      {
        text: 'ID Pagamento',
        align: 'start',
        sortable: false,
        value: 'id',
      },
      { text: 'ID Servico', value: 'servico' },
      { text: 'ID Cotação', value: 'cotacao' },
      { text: 'ID Orçamento', value: 'orcamento' },
      { text: 'Status', value: 'status' },
      { text: 'Valor do Pagamento', value: 'valor_pagamento' },
      //{ text: 'Valor Base', value: 'valor_base'},
      { text: 'Meio de Pagamento', value: 'meio_pagamento' },
      { text: 'Data de Vencimento', value: 'data_vencimento' },
      { text: 'Data do Pagamento', value: 'data_pagamento' },
      { text: 'Criado por', value: "created_by"},
      { text: 'Actions', value: 'actions', sortable: false },
    ],
    editedIndex: -1,
    editedItem: { ...EMPTY_ITEM },
    defaultItem: { ...EMPTY_ITEM },
  }),

  computed: {
    ...mapState({
      vendaObject: (state) => state.venda.venda,
    }),
    formTitle() {
      return this.editedIndex === -1 ? 'Novo Pagamento' : 'Editar Pagamento'
    },

    atrasado() {
      // check si passamos da data de vencimento
      if (!this.editedItem.data_vencimento) return true
      const today = new Date()
      today.setHours(23, 59, 59, 998)
      return today >= new Date(this.editedItem.data_vencimento)
    },
    desativarBotoes() {
      return this.vendaObject.status!=='proposta' 
    },
    desativarBotaoMaisPagamento() {
      return this.vendaObject.status!=='proposta' && this.vendaObject.status !== 'concluida'
    },
    desativarBotoesSeletivos(){
      return this.vendaObject.status==='cancelada'  || this.checkUserPermissao 
    },
    valorTotal() {
      let total = 0
      this.value.map((item) => {
        total = total + parseFloat(item.valor_pagamento.valor)
      });
      return total
      },
    user() {
      return this.$store.getters['usuario/loggedUser'];
      },
    checkUserPermissao(){
        const pertencente = this.user.pertencente;
        return checkPermissao(pertencente);
      }
  },

  watch: {

    dialog(val) {
      val || this.close()
    },
    dialogDelete(val) {
      val || this.closeDelete()
    },
    atrasado(val) {
      if (!this.editedItem.data_pagamento && val)
        this.editedItem.status = "atrasado"
      else this.editedItem.status = ""
    },
  },

  created() {
    this.initialize()
  },

  methods: {
    initialize() {
      if (this.deactivateServico) {
        this.headers = this.headers.filter(e => e.value !== 'servicos')
      }
    },

    meioPagamentoDescription(meioPagamento) {
      if (!meioPagamento) return ""
      const fullMeioPagamento = MEIOS_DE_PAGAMENTO.find(
        e => e.key === meioPagamento
      )
      if (!fullMeioPagamento) return ""
      return fullMeioPagamento.value
    },

    viewComprovante(id) {
      api.getComprovante(id)
        .then(response => {
          if (!response.data.length) {
            this.$root.vtoast.show({
              message: 'Comprovante não encontrado',
              icon: 'mdi-close',
              color: 'error'
            })
          }
          window.open(response.data.at(-1).documento_comprovante)
        }).catch(e => {
          this.$root.vtoast.show({
            message: 'Erro ao procurar comprovante',
            icon: 'mdi-close',
            color: 'error'
          })
        })

    },

    statusDescription(status) {
      if (!status) return ""
      const fullStatus = STATUS.find(
        stat => stat.key === status
      )
      if (!fullStatus) return ""
      return fullStatus.value
    },

    converteValorFinanceiroBRL(valorFinanceiro) {
      return converteValorFinanceiroBRL(valorFinanceiro)
    },

    editItem(item) {
      this.editedIndex = this.value.indexOf(item)
      this.editedItem = Object.assign({}, item)
      this.dialog = true
    },

    deleteItem(item) {
      this.editedItem = Object.assign({}, item)
      this.editedIndex = this.value.indexOf(item)
      this.dialogDelete = true
    },

    deleteItemConfirm() {
      this.value.splice(this.editedIndex, 1)
      this.closeDelete()
    },

    close() {
      this.dialog = false
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem)
        this.editedIndex = -1
      })
    },

    closeDelete() {
      /// implement api call
      api.deletePagamento(this.editedItem.id)
        .then(res => {
          this.$root.vtoast.show({
            message: 'Pagamento deletado com sucesso!',
            icon: 'mdi-check',
            color: 'success'
          })
        }).catch(e => {
          this.$root.vtoast.show({
            message: 'Erro ao deletar pagamento!',
            icon: 'mdi-close',
            color: 'error'
          })
          console.error(e)
        })
      this.dialogDelete = false
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem)
        this.editedIndex = -1
      })
    },
    postPagamento(item) {
      api.postPagamento(item)
        .then(res => {
          this.$root.vtoast.show({
            message: 'Pagamento cadastrado com sucesso!',
            icon: 'mdi-check',
            color: 'success'
          })
          this.value.push(res.data)
        }).catch(e => {
          this.$root.vtoast.show({
            message: 'Erro ao cadastrar pagamento!',
            icon: 'mdi-close',
            color: 'error'
          })
          console.error(e)
        })
    },

    updatePagamentos(pagamento) {
      if (!this.pagamentosVenda) this.pagamentosVenda = [];
      this.pagamentosVenda.push(pagamento)
    },

    copiarLink(item) {
      navigator.clipboard.writeText(item.pagarme_url);
      this.$root.vtoast.show({ message: "Texto copiado com sucesso!" })
    },

    save () {
     this.editedItem = {
       ...this.editedItem,
       venda: this.venda,
       emissao: this.emissao,
     }

     if (typeof this.editedItem.servico === 'object' && this.editedItem.servico !== null) this.editedItem.servico = this.editedItem.servico.id

 
     if (this.editedIndex > -1) {
       api.editPagamento(this.editedItem.id, this.editedItem)
       .then(res => {
         this.$root.vtoast.show({
             message: 'Pagamento alterado com sucesso!',
             icon: 'mdi-check',
             color: 'success'
         })
       }).catch(e => {
         this.$root.vtoast.show({
             message: 'Erro ao alterar pagamento!',
             icon: 'mdi-close',
             color: 'error'
         })
         console.error(e)
       })
       Object.assign(this.value[this.editedIndex], this.editedItem)
     } else {
       // saving
       if (!(this.editedItem.servicos && Object.keys(this.editedItem.servicos).length>1)){
          this.editedItem.servico = this.editedItem.servicos[0]
          this.postPagamento(this.editedItem)
        }
        else {
          // A ideia é que se tivermos vários serviços, vamos fazer vários
          // editedItem que possuem as mesmas características, salvo o
          // valor_total, que é o valor de cada pagamento, e o id do serviço
          Object.entries(this.editedItem.servicos).forEach((entry) => {
            const [index, value] = entry
            const cotacao = Object.keys(this.editedItem.pagamento_servicos)[index]
            const pagamento = {
              ...this.editedItem,
              cotacoes: value,
              valor_pagamento: this.editedItem.pagamento_servicos[cotacao],
              servico: value,
            }
            this.postPagamento(pagamento)

          })
          api.logPagamentoTotal(this.editedItem)
        }
      }
      this.close()
    },
  },

}
</script>