<template>
  <v-container fluid v-if="cotacao">
    <v-row>
      <v-col cols="12" sm="6">
        <v-field-value
          label="Valores Milheiros"
          v-if="cotacao.cotacao_voo && porMilhas(cotacao.cotacao_voo)"
        >
          <span v-for="(valor_milha, idx) in valores_milhas"
            >{{ valor_milha | dinheiroReal }}
            <span v-if="idx < valores_milhas.length - 1">, </span></span
          >
        </v-field-value>
        <v-field-value
          label="Milheiro Efetivo"
          v-if="cotacao.cotacao_voo && porMilhas(cotacao.cotacao_voo)"
        >
          <span v-for="(subcotacao, idx) in cotacao.cotacao_voo.subcotacoes">
            {{ milheiroEfetivo(cotacao, subcotacao) | dinheiroReal }}
            <span v-if="idx < cotacao.cotacao_voo.subcotacoes.length - 1"
              >,
            </span>
          </span>
        </v-field-value>
        <v-field-value label="Custo Total">{{
          custo_total | dinheiroReal
        }}</v-field-value>
        <v-field-value label="Ajustes" type="number"
          >{{ (cotacao.ajuste_ativado ? valor_ajuste : 0) | dinheiroReal }}
        </v-field-value>
        <v-field-value
          label="Valor à vista no pix"
          v-bind:class="{
            'green--text': lucroAlto,
            'red--text': lucroBaixo,
            'orange--text': lucroMedio,
          }"
          >{{ valor_final | dinheiroReal }}
          <span v-if="lucro"
            >(Lucro: {{ cotacaoLucroFormatadoPercentagem(cotacao) }})
          </span>
        </v-field-value>
      </v-col>
      <v-col cols="12" sm="6">
        <v-field-value
          class="py-1"
          :label="descricaoCartao(index)"
          v-for="index in 12"
          :key="index"
        >
          {{ valorCartao(index) | dinheiroReal }}
          <v-divider class="mb-0"></v-divider>
        </v-field-value>
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
import VFieldValue from "@/components/widgets/VFieldValue";
import CotacaoMixin from "@/mixins/CotacaoMixin";

export default {
  components: { VFieldValue },
  mixins: [CotacaoMixin],
  props: {
    cotacao: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      valores_milhas: [],
      milhas: this.$store.state.global.milhas,
    };
  },
  watch: {
    cotacao: {
      deep: true,
      handler: function (val) {
        this.valores_milhas = this.cotacaoGetValorMilha(val);
      },
    },
    valor_ajuste: {
      deep: true,
      handler: function (val) {
        if (!this.cotacao || !this.cotacao.cotacao_voo) return;
        this.cotacao.cotacao_voo.subcotacoes
          .map((subcot) => ({ ...subcot, ...subcot.dados }))
          .filter((subcot) => subcot.tipo_cotacao === "milha")
          .forEach((subcot, idx) => {
            if (!subcot.programa_fidelidade) return;
            const efetivo = this.milheiroEfetivo(this.cotacao, subcot);
            const programa = subcot.programa_fidelidade.id;
            const custoProprio = this.getMilheiroCustoProprio(programa);
            const custoTerceiros = this.getMilheiroCustoTerceiros(programa);
            const piso = parseFloat(
              custoProprio > custoTerceiros ? custoProprio : custoTerceiros
            );
            if (efetivo < piso)
              this.$root.vtoast.show({
                message: `Milheiro efetivo menor que o piso para o programa selecionado no bilhete número ${
                  idx + 1
                }!`,
                icon: "mdi-close",
                color: "error",
              });
          });
      },
    },
  },
  computed: {
    valor_ajuste() {
      return this.cotacaoValorAjuste(this.cotacao);
    },
    custo_total() {
      return this.cotacaoCustoTotal(this.cotacao);
    },
    custo_total_juros() {
      return this.cotacaoValorTotalJuros(this.cotacao, 12);
    },
    valor_final() {
      return this.cotacaoValorTotal(this.cotacao);
    },
    lucro() {
      return this.cotacaoLucro(this.cotacao);
    },
    lucroAlto() {
      return this.lucro && this.lucro >= 20;
    },
    lucroMedio() {
      return this.lucro && this.lucro < 20 && this.lucro >= 5;
    },
    lucroBaixo() {
      return !this.lucro || this.lucro < 5;
    },
  },
  methods: {
    descricaoCartao(num) {
      return `Cartão ${num}x`;
    },
    valorCartao(num) {
      return this.cotacaoValorTotalJuros(this.cotacao, num);
    },

    porMilhas(cotacao_voo) {
      return cotacao_voo.subcotacoes
        .map((subcot) => ({ ...subcot, ...subcot.dados }))
        .some((subcot) => subcot.tipo_cotacao === "milha");
    },
  },
};
</script>

