<template>
    <v-container>
        <v-form id="endereco-form">
            <v-row>
                <v-col cols="12" sm="12">
                    <v-select label="País" :items="paises" value="Brasil" name="pais" outlined disabled/>
                </v-col>
                <v-col cols="12" sm="12">
                    <v-text-field label="CEP" v-model="cepModel" name="cep" v-mask="'#####-###'" outlined/>
                </v-col>
                <v-col cols="12" sm="6">
                    <v-autocomplete
                        label="Estado"
                        outlined
                        :items="estados"
                        :search-input.sync="search"
                        item-text="nome"
                        v-model="selectedEstado"
                        cache-items
                        :loading="estadosLoading"
                        @change="alertEstado"
                        @click="getEstados"
                        return-object
                    />
                </v-col>

                <v-col cols="12" sm="6">
                    <v-combobox
                        id="cidade"
                        label="Cidade"
                        name="cidade"
                        item-text="nome"
                        outlined
                        clearable
                        :loading="cidadesLoading"
                        :disabled="cidadesDisabled"
                        :items="cidades"
                        v-model="city"
                    />
                </v-col>

                <v-col cols="12" sm="6">
                    <v-text-field v-model="street" name="logradouro" label="Logradouro" outlined />
                </v-col>

                <v-col cols="12" sm="6">
                    <v-text-field name="numero" label="Número" outlined />
                </v-col>

                <v-col cols="12" sm="12">
                    <v-text-field name="complemento" label="Complemento / Referência" outlined/>
                </v-col>
            </v-row>
        </v-form>
    </v-container>
</template>

<script>
import { getAddressByCep } from '@/helpers/utils'

export default {
    data () {
        return {
            cidadesLoading: false,
            cidadesDisabled: true,
            estadosLoading: false,

            cepModel: "",
            city: '',
            street: '',

            paises: ['Brasil'],
            estados: [''],
            cidades: [''],

            search: "",
            selectedEstado: "",

            masks: {
                "cnpj": "##.###.###/####-##",
                "cpf": "###.###.###-##",
                "tel": "(##) #####-####",
                "nasc": "##/##/####"
            },

            models: {
                email: "",
                cnpj: "",
                cpf: "",
                tel: "",
                nasc: ""
            },

            emailRules: [
                (v) => /.+@.+..+/.test(v) || "E-mail deve ser válido (exemplo@email.com)",
            ],
        }
    },

    created () {
        this.getEstados()
    },

    methods: {
        getEstados() {
            let estadosURL = "https://servicodados.ibge.gov.br/api/v1/localidades/estados/"

            fetch(estadosURL)
                .then(response => response.json())
                .then(data => this.estados = data )
        },

        buscaCEP(cep) {
            let url = `https://viacep.com.br/ws/${cep}/json/`

            fetch(url).then(data => data.json())
                .then(res => {
                    let data = res.data;
                })
        },

        alertEstado(){
            this.cidadesLoading = true
            this.cidadesDisabled = true

            let sigla = this.selectedEstado.sigla

            fetch(`https://servicodados.ibge.gov.br/api/v1/localidades/estados/${sigla}/municipios`)
                .then(response => response.json())
                .then(data => {
                    this.cidadesLoading = false
                    this.cidadesDisabled = false
                    this.cidades = data.map(cidade => cidade.nome)
                }).catch(e=>{
                    this.cidadesLoading = false
                    this.cidadesDisabled = false
                    console.error(e)
                })
        }
    },
        watch: {
            async cepModel() {
            if (this.cepModel.length > 7) {
                const address = await getAddressByCep(this.cepModel);
                const { uf, localidade, logradouro } = address;
                this.selectedEstado = this.estados.filter((state) => state.sigla === uf)[0];
                this.alertEstado();
                this.cidadesDisabled = false;
                this.city = localidade;
                this.street = logradouro;
            }
        }
    }
}
</script>