<template>
    <v-col align-self="center" cols="12" md="auto">
        <v-dialog
            v-model='dialog'
            persistent
            max-width='1200px'
        >
            <template v-slot:activator='{ on, attrs }'>
                <v-btn
                    color='primary'
                    dark
                    class='mb-2'
                    v-bind='attrs'
                    v-on='on'
                    icon-left
                >
                <v-icon left fab> mdi-office-building-plus </v-icon>
                    Empresa
                </v-btn>
            </template>
            <v-card>
                <v-card-title>
                    <v-row>
                    <span class='text-h5 ma-5'>
                        Criar Empresa
                    </span>
                    <v-spacer></v-spacer>
                    <v-btn @click='close' icon right>
                        <v-icon>mdi-close</v-icon>
                    </v-btn>
                    </v-row>
                </v-card-title>

                <v-card-text>
                    <v-row>
                        <Form ref="info" />

                        <v-spacer />
                        
                        <v-btn @click="criarEmpresa" color="success"> Criar </v-btn>
                    </v-row>
                </v-card-text>
            </v-card>
        </v-dialog>
    </v-col>
</template>

<script>
import Form from './Steps/Form'

import AppApi from '@/api/api'
import {getAllFields} from '@/helpers/forms'

export default {
    components: {Form},
    data () {
        return {
            e1: 1,
            stepperModel: "1",
            dialog: false,
            loadingSaving: false,
            disableCancel: false
        }
    },

    methods: {
        getRawNumber(maskedValue) {
            if(!maskedValue) return null
            return maskedValue.match(/[0-9]+/g).join('')
        },

        getAllFields(formId) {
            return getAllFields(formId)
        },

        async criarEmpresa() {
            const infoEmpresa = this.getAllFields('info-form');

            const novaEmpresa = { ...infoEmpresa }

            //check infos
            if(
                !(novaEmpresa.nome || novaEmpresa.razao_social) ||
                !(novaEmpresa.telefone || novaEmpresa.email) ||
                !novaEmpresa.cnpj
            ){
                this.$root.vtoast.show({
                    message: 'É necessário pelo menos um nome, um meio de contato e o CNPJ para realizar cadastro.',
                    color: 'error',
                    icon: 'mdi-close',
                })
                return 
            }

            novaEmpresa.cnpj = this.getRawNumber(novaEmpresa.cnpj)
            novaEmpresa.telefone = {
                numero: novaEmpresa.telefone,
                pais: novaEmpresa.telefone_pais
            }

            await AppApi.postEmpresa(novaEmpresa).then(data => {
                this.dialog = false
                this.$emit('refresh')
                this.$root.vtoast.show({
                    message: "Empresa criada com sucesso!",
                    icon: 'mdi-office-building-plus',
                    color: 'success'
                })
            }).catch(e => {
                console.error(e)
                errors.forEach( errorCheck => errorCheck(e) )
            }).finally(() => {
                this.loadingSaving = false;
            })
        },

        close() {
            this.disableCancel = true

            if (confirm('Deseja mesmo fechar? Todas as alterações serão perdidas.'))
                this.dialog = false;

            this.disableCancel = false
        },
    }
}
</script>