<template>
  <v-container class="ma-4" fluid> <h1>Deu errado :-(</h1> </v-container>
</template>
 
 <script>
export default {
  name: "fail-maxipago",
  data() {
    return {};
  },
};
</script>
 