import { get, post, put, del, paginate, filter, convertToFormData, postFormData } from '../modules'

export default {
    getFornecedores(page = 1, filters=[]) {
        const paginatedUrl = paginate('/api/fornecedores/', page)
        const filteredUrl = filter(paginatedUrl, filters)
        return get(filteredUrl)
    },

    getFornecedorCotacoes(id) {
        return get(`/api/fornecedores/${id}/cotacoes/`)
    },

    getFornecedorSubCotacoes(id) {
        return get(`/api/fornecedores/${id}/subcotacoes/`)
    },
    getFornecedorEmissoes(id) {
        return get(`/api/fornecedores/${id}/emissoes/`)
    },

    postFornecedor(data){
        return post('/api/fornecedores/', data)
    },

    putFornecedor(id, data){
        return put(`/api/fornecedores/${id}/`, data)
    },

    deleteFornecedor(id){
        return del(`/api/fornecedores/${id}`)
    },
    
    getProvedores(page = 1, filters = []) {
        const paginatedUrl = paginate('/api/provedores/', page)
        const filteredUrl = filter(paginatedUrl, filters)
        return get(filteredUrl)
    },
    
    getProvedoresSimples(){
        return get('/api/provedores/simples/')
    },

    getProvedorEmissoes(id) {
        return get(`/api/provedores/${id}/emissoes/`)
    },

    postProvedor(data){
        return post('/api/provedores/', data)
    },

    putProvedor(id, data){
        return put(`/api/provedores/${id}/`, data)
    },

    deleteProvedor(id){
        return del(`/api/provedores/${id}`)
    },

    // Imagens
    getProvedorImages(id) {
        return get(`/api/provedores/${id}/imagem/`);
    },

    postProvedorImage(id, data) {
        const convertedToFormData = convertToFormData(data);
        return postFormData(`/api/provedores/${id}/imagem/`, convertedToFormData);
    },

    deleteProvedorImage(id, imageId) {
        return del(`/api/provedores/${id}/imagem/`, {
            image_id: imageId
        })
    }
}
