<template>
    <div>
        <v-container class="pa-10" v-if="contato == 'error'">
            O contato não foi encontrado. (404)
        </v-container>
        <v-container class="pa-10" v-if="typeof(contato) == 'object'">
            <v-row>
                <v-col>
                    <div class="text-h5 transition-swing">
                        <router-link style="text-decoration: none;" :to="{name: 'contatos'}" replace>
                            <v-icon color="black">mdi-arrow-left</v-icon> 
                        </router-link>
                        Detalhes do Contato
                    </div>
                </v-col>
                <v-spacer />
                <v-breadcrumbs :items="items" />
            </v-row>
            <v-row>
                <v-col>
                    <Tabs v-if="contato" :contato="contato"/>
                </v-col>
            </v-row>

            <!-- Mostrar botão de salvar apenas nas rotas em que é possível editar dados do contato -->
            <v-row v-if="saveRoutes.includes(routeName)"> 
                <div class="text-center pa-5" right>
                    <v-btn block color="green" dark @click="editarContato">
                        <v-icon> mdi-content-save </v-icon> Salvar
                    </v-btn>
                </div>
                <v-spacer></v-spacer>
                <div class="text-center pa-5" right>
                    <v-btn block color="red" style="font-weight: bolder;" outlined dark @click="confirmDeleteContato">
                        <v-icon> mdi-delete </v-icon> Deletar contato
                    </v-btn>
                </div>
            </v-row>
        </v-container>
        <v-row justify="center">
            <v-dialog
                v-model="deleteDialog"
                max-width="600px"
            >
            <v-card>
                <v-card-title class="text-h5">
                    Deletar contato?
                </v-card-title>
                <v-card-text>
                    Digite o nome <span class="bold-text"> {{nome_completo}} </span> para confirmar.
                    <v-text-field
                        label="Nome completo"
                        v-model="inputNomeCompleto"
                    />
                </v-card-text>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn
                        text
                        @click="deleteDialog = false"
                    >
                        Não
                    </v-btn>
                    <v-btn
                        text
                        @click="deleteContato"
                        :disabled="nome_completo ==  inputNomeCompleto ? false : true"
                    >
                        Sim
                    </v-btn>
                </v-card-actions>
            </v-card>
            </v-dialog>
        </v-row>
    </div>
</template>

<style scoped>
    .bold-text {
        font-weight: bold;
    }
</style>

<script>
import AppApi from '@/api/api.js';
import Tabs from '@/components/Contatos/Detalhes/Tabs.vue'
import {formatApiError} from "@/helpers/apiErrors"
import { validateCPF } from "@/helpers/identificadores";
import skylerDate from '@/helpers/Date/date'

export default {
    components: {
      Tabs
    },

    data () {
        return {
            tab: 1,
            deleteDialog: false,
            inputNomeCompleto: null,
            saveRoutes: ['info', 'endereco', 'extras'], // Rotas que recebem o botão de save
        }
    },

    created() {
        this.initialize();
    },

    methods: {
        async initialize() {
            await this.$store.dispatch("contato/fetchContato", this.$route.params.id)
        },

        getRawNumber(maskedValue) {
            if(!maskedValue) return null
            return maskedValue.match(/[0-9]+/g).join('')
        },

        getAllFields(formId){
            let form = document.getElementById(formId);
            if(!form) return null
            let fields = Object.values(form).reduce((obj,field) => {
                obj[field.name] = field.value;
                return obj;
            }, {});

            return fields
        },

        confirmDeleteContato() {
            this.deleteDialog = true
        },

        async deleteContato() {
            await AppApi.deleteContato(this.contatoId)
            this.$router.push({name: 'contatos'})
        },

        saveInfo() {
            let infoContato = this.getAllFields('info-form')

            infoContato.cpf = infoContato.cpf ? this.getRawNumber(infoContato.cpf) : null
            if (infoContato.cpf && !validateCPF(infoContato.cpf)) {
                this.$root.vtoast.show({
                    message: "CPF inválido",
                    color: 'error',
                    icon: 'mdi-close',
                })
                return
            }

            infoContato.empresas = infoContato.empresas.split(',')

            infoContato.cnpj = infoContato.cnpj ? this.getRawNumber(infoContato.cnpj) : null
            infoContato.genero = infoContato.genero == 'Masculino' ? 'M' : 'F' 

            infoContato.data_nascimento = skylerDate.dateToString(infoContato.data_nascimento)
            if(!skylerDate.isDateValid(infoContato.data_nascimento)) infoContato.data_nascimento = null

            infoContato.telefone = {
                numero: infoContato.telefone,
                pais: infoContato.telefone_pais
            }

            return infoContato
        },

        saveEndereco() {
            let enderecoContato = this.getAllFields('endereco-form')
            if(enderecoContato)
                enderecoContato.cep = this.getRawNumber(enderecoContato.cep)
            else
                enderecoContato = this.contato.endereco

            return enderecoContato
        },

        saveExtras() {
            let extrasContato = this.getAllFields('extras-form')
            
            extrasContato.data_especial = skylerDate.dateToString(extrasContato.data_especial)

            if(!skylerDate.isDateValid(extrasContato.data_especial))
                extrasContato.data_especial = null
    
            return extrasContato
        },

        async editarContato() {
            // Apenas campos obrigatórios
            this.editedContato = {
                nome: this.contato.nome,
                sobrenome: this.contato.sobrenome,
                extras: this.contato.extras,
                endereco: this.contato.endereco
            }

            switch(this.routeName) {
                case 'info': 
                    this.editedContato = {
                        ...this.contato,
                        ...this.saveInfo()
                    };
                    break;

                case 'endereco':
                    this.editedContato.endereco = this.saveEndereco();
                    break;

                case 'extras':
                    this.editedContato.extras = this.saveExtras();
                    break;
            }

            if(this.contato?.empresas?.toString() != this.editedContato?.empresas?.toString()){
                if(this.editedContato?.empresas?.toString() == "") this.editedContato.empresas = []

                await AppApi.putContatoEmpresas(this.contatoId, this.editedContato.empresas)
                    .then(response => {})
            }

            delete this.editedContato.empresas

            await AppApi.putContato(this.contatoId, this.editedContato)
                .then(response => {
                    this.$root.vtoast.show({
                        message: 'Contato editado com sucesso!',
                        color: 'success',
                        icon: 'mdi-account-edit'
                    })
                }).catch(
                    err => {
                        console.error(err)
                        window.alert(formatApiError(err))
                    }
                )
        }
    },

    computed: {
        nome_completo() {
            if(!this.contato) return ''
            return `${this.contato.nome} ${this.contato.sobrenome}`
        },

        contato() {
            return this.$store.getters['contato/contato']
        },

        routeName() {
            return this.$route.name.split('contato-')[1]
        },

        contatoId() {
            return this.$route.params.id
        },

        items() {
            return [
                {
                    text: 'Home',
                    to: "home"
                },
                {
                    text: 'Contatos',
                    to: {name:"contatos"},
                    disabled: false,
                    exact:true
                },
                {
                    text: 'C' + (this.contato ? this.contato.id : ''),
                    disabled: true,
                    to: ""
                }
            ]
        }
    }
}
</script>