<template>
    <div v-if="true">
        <v-card>
            <v-card-title>
                <span class='text-h5'>Busca Moblix</span>
            </v-card-title>
            <v-container>
                <v-row>
                    <v-col>
                        <v-select :items="companhiasAereas" label="Companhia Aérea" item-text="value" item-value="key"
                             v-model="item.companhia_aerea_ida"></v-select>
                    </v-col>
                </v-row>
                    <v-divider></v-divider>
            </v-container>
            <v-card-actions class="ma-0">
                <v-spacer></v-spacer>
                <v-btn :loading="loadingButton" color="primary" @click="busca">Buscar</v-btn>
            </v-card-actions>
        </v-card>
    </div>
</template>
<script>
import api from "@/api/api"
import VDatetimeTextField from "@/components/widgets/VDatetimeTextField";
import VTimeTextField from "@/components/widgets/VTimeTextField";
import FormDatePicker from "@/components/widgets/FormDatePicker";
import companhiasAereas from "@/constants/companhiasAereasMoblix"
import {formatApiError} from "@/helpers/apiErrors";
export default {
    components: {FormDatePicker, VDatetimeTextField, VTimeTextField
    },
    props: {
        orcamento: {
            type: Object,
            default: () => { },
        },
    },
    data() {
        return {
            companhiasAereas: companhiasAereas,
            item: {
                internacional: false,
                pagante: false,
                diamanteSmiles: false,
                clubeSmiles: false,
                agenciaExterna: false,
            },
            loadingButton:false,
        }
    },
    created() {
        
    },
    computed: {
        formItem() {
            if (!this.orcamento.orcamento_voo) return
            const formItem = {
                orcamento_voo: this.orcamento.orcamento_voo,
            }
            Object.entries(this.item).forEach(([key, value]) =>{
                formItem[key] = (value instanceof Date) ? value.toISOString().slice(0, -5) : value
            }   
            )
            return formItem
        },
    },

    methods: {
        busca() {
            this.loadingButton=true
            if (!this.orcamento.orcamento_voo) return
            const data = this.formItem
            api.postBusca(data).then(res => {
                res.data.forEach(data => this.openWindow(data.id))
            }).catch(e => {
                console.error(e);
                window.alert(formatApiError(e))
            }).finally(() => {
                this.loadingButton = false
            })
        },
        openWindow(id) {
            const routeData = this.$router.resolve({name: 'busca-moblix', params: {id: id}});
            window.open(routeData.href, '_blank');
        }
    }

}
</script>
<style>
.box {
    margin: 2rem;
    padding: 2rem;
}
</style>
  
