import {SET_CARRINHO, SET_COTACOES, SET_LOADING, SET_ORCAMENTOS, SET_VENDA, SET_FATURA, SET_VALIDACAO, SET_SERVICOS} from "@/store/modules/Venda/mutation-types";
// import {cotacaoCarro, cotacaoHotel, cotacaoOutro, cotacaoVooMilha, cotacaoVooPagante} from "@/types/cotacao";
import api from "@/api/api";
import {formatApiError} from "@/helpers/apiErrors"

const vendaStore = {
    namespaced: true,
    state: {
        venda: null,
        orcamentos: null,
        cotacoes: null,
        emissoes: [],
        carrinho: null,
        fatura: [],
        validacao: [],
        servicos: [],
        loading: false,
        chatMessages: []
    },
    getters: {
        chatMessages: state => state.chatMessages,
    },
    mutations: {
        [SET_LOADING](state, value) {
            state.loading = value;
        },
        [SET_VENDA](state, venda) {
            state.venda = {...this.state.venda.venda, ...venda}
        },
        [SET_ORCAMENTOS](state, orcamentos) {
            state.orcamentos = orcamentos
        },
        setOrcamento(state, orcamento) {
            let idx = state.orcamentos.findIndex(x => x.id === orcamento.id)
            if (idx !== -1) {
                state.orcamentos[idx] = orcamento
            }
        },
        [SET_COTACOES](state, cotacoes) {
            state.cotacoes = cotacoes
        },
        [SET_SERVICOS](state, servicos) {
            state.servicos = servicos 
        },
        [SET_CARRINHO](state, carrinho) {
            state.carrinho = carrinho
        },
        SET_EMISSOES(state, emissoes) {
            state.emissoes = emissoes
        },
        SET_MESSAGES(state, messages) {
            state.chatMessages = messages
        },
        [SET_FATURA](state, servicos){
            state.fatura = servicos
        },
        [SET_VALIDACAO](state, servicos){
            state.validacao = servicos
        },
    },

    actions: {
        async fetchEmissoes({commit, state}) {
            // if (state.emissoes.length > 0) return;

            commit(SET_LOADING, true);

            return await api.getEmissoesVenda(state.venda.id).then(res => {
                commit(SET_LOADING, false);
                commit("SET_EMISSOES", res.data);
                return res;
            }).catch(err => {
                commit(SET_LOADING, false);
                throw err;
            });
        },

        async faturarCarrinho({commit, state}, data) {
            commit(SET_LOADING, true);
            let response = await api.postFatura(state.venda.id, data)
                .then(res => {
                    commit(SET_CARRINHO, []);
                    return res
                })
                .catch(err => {
                    console.error(err);
                    window.alert(formatApiError(err))
                }).finally(() => {
                commit(SET_LOADING, false);
                
            })
            return response
        },

        async fetchCarrinho({commit, state}, force = false) {
            // if (state.carrinho !== null && !force) return

            commit(SET_LOADING, true);
            const venda_id = state.venda.id;

            return await api.getCarrinho(venda_id).then(response => {
                const carrinho = response.data;

                commit(SET_LOADING, false);
                commit(SET_CARRINHO, carrinho);
                return carrinho
            }).catch(error => {
                commit(SET_LOADING, false);
                return error;
            });
        },

        async fetchFatura({commit, state}) {
            commit(SET_LOADING, true);
            const venda_id = state.venda.id;

            return await api.getFatura(venda_id).then(response => {
                const servicos = response.data;

                commit(SET_LOADING, false);
                commit(SET_FATURA, servicos);
                return servicos
            }).catch(error => {
                commit(SET_LOADING, false);
                return error;
            });
        },

        async fetchValidacao({commit, state}) {
            commit(SET_LOADING, true);
            const venda_id = state.venda.id;

            return await api.getValidacao(venda_id).then(response => {
                const servicos = response.data;

                commit(SET_LOADING, false);
                commit(SET_VALIDACAO, servicos);
                return servicos
            }).catch(error => {
                commit(SET_LOADING, false);
                return error;
            });
        },

        async fetchVenda({commit}, id) {
            commit(SET_LOADING, true);
            let error = false;
            const res = await api.getVenda(id).then(res => res).catch(err => {
                error = true;
                return err;
            });
            res["error"] = error;
            commit(SET_LOADING, false);

            if (!res.error) {
                let venda = res.data
                commit(SET_VENDA, venda)
            }
            return res;
        },

        async fetchCotacoes({commit, state, dispatch}, force = false) {
            // if (state.cotacoes !== null && !force) return
            commit(SET_LOADING, true);

            const venda_id = state.venda.id

            await api.getVendaCotacoes(venda_id).then((res) => {
                const cotacoes = res.data;
                commit(SET_LOADING, false);
                commit(SET_COTACOES, cotacoes)
            })
        },

        async fetchServicos({commit, state, dispatch}) {
            commit(SET_LOADING, true);

            const venda_id = state.venda.id

            await api.getServicosVenda(venda_id).then((res) => {
                const servicos = res.data;
                commit(SET_LOADING, false);
                commit(SET_SERVICOS, servicos)
            })
        },

        async finalizarOrcamento({commit}, orcamento) {
            commit(SET_LOADING, true);
            await new Promise((res) => {
                setTimeout(() => {
                    commit(SET_LOADING, false);
                    orcamento.status = "concluido"
                    commit("setOrcamento", orcamento)
                    res();
                }, 1000);
            })
        },

        // -------------------------------------------------
        // -------------------------------------------------
        // Verificado
        // -------------------------------------------------
        // -------------------------------------------------

        async createOrcamentos({commit, state, dispatch}, orcamentos) {
            commit(SET_LOADING, true);

            if (!state.venda || !state.venda.id) {
                commit(SET_LOADING, false)
                return;

            }
            await api.createVendaOrcamentos(state.venda.id, orcamentos)
            await dispatch("fetchOrcamentos", {force: true})
            commit(SET_LOADING, false)

        },
        async fetchOrcamentos({commit, state}, force = false) {
            commit(SET_LOADING, true);

            const res = await api.getVendaOrcamentos(state.venda.id).then(res => res).catch(err => {
                return {
                    error: true,
                }
            });
            if (!res.error) {
                commit(SET_LOADING, false);
                commit(SET_ORCAMENTOS, res.data)
            }
            return res;
        },
        async updateVenda({commit, state, dispatch}, venda) {
            commit(SET_LOADING, true);
            let error = false;
            const res = await api.updateVenda(venda.id, venda).then(res => res).catch(err => {
                error = true;
                return err;
            });
            res["error"] = error;
            commit(SET_LOADING, false);
            if (!res.error) {
                return await dispatch('fetchVenda', venda.id);
            } else return res
        },

        async fetchChatMessages({commit, state, dispatch}, vendaId) {
            if(!state.venda){
                await dispatch('fetchVenda', vendaId);
            }

            const venda_id = state.venda.id;

            commit(SET_LOADING, true);

            const res = await api.getVendaMessages(venda_id)
            commit("SET_MESSAGES", res.data)
            return res.data
        }
    },
}

export default vendaStore
