<template>
  <v-container fluid>
    <v-container fluid v-if="loading"> Loading... </v-container>
    <v-container fluid v-else-if="carrinho">
      <v-row v-if="carrinho">
        <venda-carrinho-list :carrinho="carrinho" @refresh="initialize" />
      </v-row>
      <!--    Dialogs  -->
      <v-row>
        <v-spacer />
        <venda-carrinho-validar-dialog @confirm="faturarCarrinho" :disabled="validacaoDisabled" :carrinho="carrinho" />
      </v-row>
    </v-container>
    <v-container>
      <v-row>
        <v-spacer/>
        <v-dialog v-model="loadingCriacaoPagamentos" persistent max-width="600px">
          <v-card>
            <v-card-title class="text-h5 center"> 
              Faturando carrinho e criando pagamentos
              <v-spacer></v-spacer>
            </v-card-title>
            <v-card-title class="text-h7 center">
                Por favor, aguarde.
            </v-card-title>
          </v-card>
        </v-dialog>
      </v-row>
    </v-container>
  </v-container>
</template>

<script>
import { mapState } from "vuex";

import VendaCarrinhoList from "@/components/venda/carrinho/VendaCarrinhoList";
import VendaCarrinhoValidarDialog from "@/components/venda/carrinho/VendaCarrinhoValidarDialog";
import api from "@/api/api.js";
import { converteValorFinanceiroBRL } from "@/helpers/financeiro";
import cotacaoMixin from "@/mixins/CotacaoMixin";

export default {
  components: {
    VendaCarrinhoList,
    VendaCarrinhoValidarDialog,
  },
  mixins: [cotacaoMixin],
  data() {
    return {
      loading: false,
      loadingCriacaoPagamentos: false,
      pagamento: {
        venda: this.$route.params.id,
        servico: null,
        status: "nao_efetuado",
        descricao: "",
        valor_pagamento: {
          moeda: null,
          valor: 0,
        },
        meio_pagamento: "pagarme",
        responsavel_financeiro: null,
        count: 0,
      },
      counter: 0,
      pagamentosJuntos: [], //Exemplo: [{
      //counts: "[1, 3]", 
      //valor_total: 30000 = 20000+10000,
      //title: "Pagamento conjunto cotacao 1, 3",
      //meio_pagamento: 'pagarme',
      //num_parcelas: 12
      //}]
    };
  },
  async mounted() {
    await this.initialize();
  },
  computed: {
    ...mapState({
      carrinho: (state) => {
        if (!state.venda || !state.venda.carrinho) return [];
        return state.venda.carrinho;
      },
    }),
    validacaoDisabled() {
      if (this.carrinho.length > 0) return false;
      return true;
    },
  },
  methods: {
    async initialize() {
      this.loading = true;
      await this.$store.dispatch("venda/fetchCarrinho");
      this.loading = false;
    },

    async pagamentos(
      informacoesPagamento,
      carrinho,
      response,
      responsaveisFinanceiros,
      juntarPagamentos
    ) {
      this.pagamento.responsavel_financeiro =
        responsaveisFinanceiros.responsavelFinanceiro;
      this.pagamento.responsavel_financeiro_empresa =
        responsaveisFinanceiros.responsavelFinanceiroEmpresa;
      const servicos = response.data;
      // Criar vários pagamentos, um pra cada servico, com apenas um link
      // Só pode juntar pagamentos com número de parcelas igual
      // Para os pagamentos que ficarão juntos, mandar um 
      // objeto com valor total e servicos, criar o link no back e tirar esses pagamentos do data
      // o objeto também tem os counts, que associam ao respectivo pagamento
      const pagamentosFormatados = Object.values(informacoesPagamento)
        .map((pagamento) => this.formatarPagamento(
          pagamento,
          carrinho,
          servicos.find((servico) => servico.cotacao.id === pagamento.cotacao),
          juntarPagamentos

        )
        ).reduce((cur, cum) => [...cur, ...cum], []); // coloca todos os pagamentos em uma única lista
        // retirar pagamentos juntos que só possuem um pagamento
        this.pagamentosJuntos = this.pagamentosJuntos.filter(pagamento => pagamento.counts.length >1)
        

      this.postPagamentos(pagamentosFormatados);
    },

    formatarPagamento(infoPagamento, carrinho, servico, juntarPagamentos) {
      const moedaBRL = this.$store.getters["global/moedas"].find(
        (moeda) => moeda.sigla === "BRL"
      );
      if (!moedaBRL) return;
      const pagamento = { ...this.pagamento, servico: servico.id };
      const cliente = this.$store.state.venda.venda.cliente;
      const cliente_empresa = this.$store.state.venda.venda.cliente_empresa;
      const name = `${this.pagamento.venda} - ${cliente ? cliente.nome : ""}${cliente ? " " + cliente.sobrenome : ""
        }${cliente_empresa ? cliente_empresa.nome : ""} - ${infoPagamento.cotacao
        }`;
      this.counter += 1
      pagamento.count = this.counter

      const pagamentos = []

      switch (infoPagamento.tiposPagamento) {
        case "pagamentoParcelado": {
          const valor_total =
            this.cotacaoValorTotalJuros(
              carrinho.find((cotacao) => cotacao.id === infoPagamento.cotacao)
            ) -
            this.converteValorFinanceiroBRL(
              infoPagamento.pagamentoParcelado.desconto
            );
          const valor = parseInt(valor_total * 100);

          pagamento.valor_pagamento = {
            valor: valor_total.toFixed(2),
            moeda: moedaBRL.id,
          };
          pagamento.pagarme = {
            title: name,
            amount: valor,
            free_installments: infoPagamento.pagamentoParcelado.numeroParcelas,
            interest_rate: 0,
            max_installments: infoPagamento.pagamentoParcelado.numeroParcelas,
          }
          pagamento.num_parcelas = infoPagamento.pagamentoParcelado.numeroParcelas;
          pagamento.meio_pagamento = "pagarme";
          pagamentos.push({ ...pagamento })
          if (juntarPagamentos) {
            if (this.pagamentosJuntos.find(e => e.meio_pagamento === 'pagarme' && e.num_parcelas === pagamento.num_parcelas)) {
              const item = this.pagamentosJuntos.find(e => e.meio_pagamento === 'pagarme' && e.num_parcelas === pagamento.num_parcelas)
              item.counts.push(pagamento.count)
              item.valor_total += pagamento.pagarme.amount
              item.title.concat(' -- ', pagamento.pagarme.title)
            }
            else {
              this.pagamentosJuntos.push({
                counts: [pagamento.count], 
                valor_total: pagamento.pagarme.amount,
                title: pagamento.pagarme.title,
                meio_pagamento: 'pagarme',
                num_parcelas: pagamento.num_parcelas
              })
            }
          }
          break;
        }
        case "pagamentoParceladoEntrada": {
          // setando a entrada
          const valor_entrada = this.converteValorFinanceiroBRL(
            infoPagamento.pagamentoParceladoEntrada.valorEntrada
          )
          pagamento.valor_pagamento = {
            valor: valor_entrada.toFixed(2),
            moeda: moedaBRL.id,
          };
          pagamento.descricao = "Entrada";
          pagamento.meio_pagamento = "pix";
          pagamento.pagarme = {
            title: name,
            amount: parseInt(valor_entrada * 100),
          }
          pagamentos.push({ ...pagamento })
          if (juntarPagamentos) {
            if (this.pagamentosJuntos.find(e => e.meio_pagamento === 'pix')) {
              const item = this.pagamentosJuntos.find(e => e.meio_pagamento === 'pix')
              item.counts.push(pagamento.count)
              item.valor_total += pagamento.pagarme.amount
              item.title = item.title.concat(' -- ', pagamento.pagarme.title)
            }
            else {
              this.pagamentosJuntos.push({
                counts: [pagamento.count], 
                valor_total: pagamento.pagarme.amount,
                title: pagamento.pagarme.title,
                meio_pagamento: 'pix',
                num_parcelas: 1,
              })
            }
          }
          this.counter+=1
          // setando as parcelas
          const valor_total =
            this.cotacaoValorTotalJuros(
              carrinho.find((cotacao) => cotacao.id === infoPagamento.cotacao)
            ) -
            this.converteValorFinanceiroBRL(
              infoPagamento.pagamentoParceladoEntrada.desconto
            );
          const valor = parseInt((valor_total - pagamento.valor_pagamento.valor) * 100);

          pagamento.pagarme = {
            title: name,
            amount: valor,
            free_installments: infoPagamento.pagamentoParceladoEntrada.numeroParcelas,
            max_installments: infoPagamento.pagamentoParceladoEntrada.numeroParcelas,
          },
            pagamento.valor_pagamento = {
              valor: (
                valor_total -
                this.converteValorFinanceiroBRL(pagamento.valor_pagamento) -
                0.001
              ).toFixed(2),
              moeda: infoPagamento.pagamentoParceladoEntrada.valorEntrada.moeda,
            };
          pagamento.num_parcelas = infoPagamento.pagamentoParceladoEntrada.numeroParcelas;
          pagamento.descricao = "Pagamento Parcelado";
          pagamento.meio_pagamento = "pagarme";
          pagamento.count = this.counter
          if (juntarPagamentos) {
            if (this.pagamentosJuntos.find(e => e.meio_pagamento === 'pagarme' && e.num_parcelas === pagamento.num_parcelas)) {
              const item = this.pagamentosJuntos.find(e => e.meio_pagamento === 'pagarme' && e.num_parcelas === pagamento.num_parcelas)
              item.counts.push(pagamento.count)
              item.valor_total += pagamento.pagarme.amount
              item.title.concat(' -- ', pagamento.pagarme.title)
            }
            else {
              this.pagamentosJuntos.push({
                counts: [pagamento.count], 
                valor_total: pagamento.pagarme.amount,
                title: pagamento.pagarme.title,
                meio_pagamento: 'pagarme',
                num_parcelas: pagamento.num_parcelas
              })
            }
          }

          pagamentos.push({ ...pagamento })

          
          break;
        }
        case "pagamentoAVista": {
          const valor_total =
            this.cotacaoValorTotalJuros(
              carrinho.find((cotacao) => cotacao.id === infoPagamento.cotacao)
            ) -
            this.converteValorFinanceiroBRL(
              infoPagamento.pagamentoAVista.desconto
            );
          pagamento.pagarme = {
            title: name,
            amount: parseInt(valor_total * 100),
          },
            pagamento.meio_pagamento = "pix";
          pagamento.descricao = "Serviço pago à vista";
          pagamento.valor_pagamento = {
            valor: valor_total.toFixed(2),
            moeda: moedaBRL.id,
          };
          if (juntarPagamentos) {
            if (this.pagamentosJuntos.find(e => e.meio_pagamento === 'pix')) {
              const item = this.pagamentosJuntos.find(e => e.meio_pagamento === 'pix')
              item.counts.push(pagamento.count)
              item.valor_total += pagamento.pagarme.amount
              item.title.concat(' -- ', pagamento.pagarme.title)
            }
            else {
              this.pagamentosJuntos.push({
                counts: [pagamento.count], 
                valor_total: pagamento.pagarme.amount,
                title: pagamento.pagarme.title,
                meio_pagamento: 'pix',
                num_parcelas: pagamento.num_parcelas
              })
            }
          }
          pagamentos.push({ ...pagamento })
          break;
        }
        case "pagamentoAVistaPortalFornecedor": {
          const valor_total =
            this.cotacaoValorTotalJuros(
              carrinho.find((cotacao) => cotacao.id === infoPagamento.cotacao), 0
            ) -
            this.converteValorFinanceiroBRL(
              infoPagamento.pagamentoAVista.desconto
            );
          pagamento.pagarme = {
            title: name,
            amount: parseInt(valor_total * 100),
          },
            pagamento.meio_pagamento = "fornecedor";
          pagamento.descricao = "Serviço pago à vista";
          pagamento.valor_pagamento = {
            valor: valor_total.toFixed(2),
            moeda: moedaBRL.id,
          };
          if (juntarPagamentos) {
            if (this.pagamentosJuntos.find(e => e.meio_pagamento === 'fornecedor')) {
              const item = this.pagamentosJuntos.find(e => e.meio_pagamento === 'fornecedor')
              item.counts.push(pagamento.count)
              item.valor_total += pagamento.pagarme.amount
              item.title.concat(' -- ', pagamento.pagarme.title)
            }
            else {
              this.pagamentosJuntos.push({
                counts: [pagamento.count], 
                valor_total: pagamento.pagarme.amount,
                title: pagamento.pagarme.title,
                meio_pagamento: 'fornecedor',
                num_parcelas: pagamento.num_parcelas
              })
            }
          }
          pagamentos.push({ ...pagamento })
          break;
        }
      }
      return pagamentos
    },

    async postPagamentos(pagamentos) {
      api
        .postCriarPagamentos({ pagamentos: pagamentos, pagamentosJuntos: this.pagamentosJuntos })
        .then((res) => {
          this.loadingCriacaoPagamentos= false;
          this.$root.vtoast.show({
            message: "Pagamentos cadastrados com sucesso!",
            icon: "mdi-check",
            color: "success",
          });
        })
        .catch((e) => {
          this.$root.vtoast.show({
            message: "Erro ao cadastrar pagamentos!",
            icon: "mdi-close",
            color: "error",
          });
          console.error(e);
        });
    },

    async faturarCarrinho(
      carrinho,
      informacoesPagamento,
      responsaveisFinanceiros,
      juntarPagamentos
    ) {
      this.loading = true;
      this.loadingCriacaoPagamentos = true;

      const data = {
        carrinho: carrinho,
        informacoes_pagamento: informacoesPagamento,
      };

      let response = null;
      await this.$store
        .dispatch("venda/faturarCarrinho", data)
        .then((res) => {
          this.$root.vtoast.show({
            message: "Carrinho enviado para fatura com sucesso!",
            color: "success",
            icon: "mdi-check",
          });
          response = res;
        })
        .catch((err) => {
          console.error(err);
          this.$root.vtoast.show({
            message: "Carrinho não pode ser enviado para a fatura.",
            color: "error",
            icon: "mdi-close",
          });
        });
      await this.pagamentos(
        informacoesPagamento,
        carrinho,
        response,
        responsaveisFinanceiros,
        juntarPagamentos
      );
      this.loading = false;
      await this.initialize;
    },
  },
};
</script>
