import moment from 'moment'

function daysBetween(date1, date2){
    const timeDiff = Date.parse(date2) - Date.parse(date1)
    return Math.floor(timeDiff/(24*60*60*1000))
}

function hoursBetween(date1, date2){
    const timeDiff = Date.parse(date2) - Date.parse(date1)
    return Math.floor(timeDiff/(60*60*1000))
}

function convertHora(data){
    const options = {hour: '2-digit', minute: '2-digit', year: 'numeric', month: 'numeric', day: 'numeric' }
    return new Date(data).toLocaleString('pt-BR', options)
}

const validateDates = (initialDate, finalDate) => {
    const areDatesFilled = initialDate && finalDate;
    
    if (areDatesFilled && initialDate.valueOf() > finalDate.valueOf()) {
        window.alert('A data de ida não pode ser depois que a data de volta');
        return true;
    }
};

const formatDates = (date) => {
    if(!date) return null
    const momentData = moment(date, 'DD/MM/YYYY')
            .locale('en-ca')
            .format('L')

    return momentData
}

const serializeDatePassport = date => {
    if (date === null) return "-";
    const day = date.substr(8, 9);
    const month = date.substr(4, 4).replaceAll("-", "");
    const year = date.substr(0, 4);
    const serializedDate = `${day}/${month}/${year}`;
    return serializedDate;
}

export {
    daysBetween,
    hoursBetween,
    validateDates,
    formatDates,
    convertHora,
    serializeDatePassport,
}