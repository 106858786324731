<template>
  <v-container>
    <v-row>
      <v-text-field
        dense
        readonly
        label="Empresa"
        :value="empresaName"
        :disabled="disabled"
        :error-messages="errors"
        @click:clear="select(null)"
        clearable
      ></v-text-field>

      <v-dialog v-model="showSearch" max-width="1200" :disabled="disabled">
        <template v-slot:activator="{ on, attrs }">
          <v-icon class="ma-2" v-bind="attrs" v-on="on" :disabled="disabled">
            mdi-magnify
          </v-icon>
        </template>
        <SelectContatos
          @close="showSearch = false"
          @refresh="loadContatos()"
          :contatos="contatos"
          @input="select"
        />
      </v-dialog>
    </v-row>
  </v-container>
</template>

<script>
import SelectContatos from "@/components/widgets/SelectContatos";

export default {
  components: { SelectContatos },
  props: {
    value: [Object, Number],
    emptyLabel: String,
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      cliente: null,
      showSearch: false,
    };
  },
  watch: {
    value: {
      immediate: true,
      deep: true,
      handler: function (val) {
        this.cliente = val;
      },
    },
  },
  computed: {
    errors() {
      if (this.emptyLabel && this.cliente == null) return [this.emptyLabel];
      return [];
    },
    empresaName() {
      if (!this.cliente || this.contatos.length === 0) return "";

      const cliente = this.contatos.find((c) => c.id === this.cliente);
      return cliente.nome + " " + cliente.sobrenome;
    },
    contatos() {
      return this.$store.state.contato.contatos;
    },
  },
  created() {
    if (!this.value) return;
    else if (Number.isInteger(this.value)) this.cliente = this.value;
    else this.cliente = this.value.id;
  },

  async mounted() {
    this.loadContatos();
  },

  methods: {
    select(item) {
      this.cliente = item;
      this.$emit("input", item);
    },

    loadContatos() {
      this.$store.dispatch("global/fetchContatos").catch((err) => {
        this.$root.vtoast.show({ message: err.message, type: "error" });
      });
    },
  },
};
</script>
