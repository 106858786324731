<template>
  <v-container fluid>
    <v-row>
      <v-col>
        <v-data-table
          :headers="headers"
          :search="search"
          :items="orcamentos"
          sort-by="id"
          sort-desc
          :loading="loadingTable"
          :hide-default-footer="true"
        >
          <template v-slot:top>
            <v-container fluid>
              <v-row>
                <v-col cols="12" md="2">
                  <v-autocomplete
                    clear-icon="mdi-close"
                    clearable
                    outlined
                    v-model="filter.consultor"
                    label="Consultor"
                    :item-text="(item) => `${item.nome} ${item.sobrenome}`"
                    :item-value="(item) => item.id"
                    :items="users"
                    dense
                  >
                  </v-autocomplete>
                </v-col>
                <v-col cols="12" md="2">
                  <v-select
                    clear-icon="mdi-close"
                    clearable
                    outlined
                    v-model="filter.status"
                    label="Status"
                    :items="statusOrcamento"
                    :item-text="(status) => status.value"
                    :item-value="(status) => status.key"
                    dense
                  >
                  </v-select>
                </v-col>
                <v-col cols="12" md="2">
                  <v-select
                    clear-icon="mdi-close"
                    clearable
                    outlined
                    v-model="filter.status_venda"
                    label="Status Venda"
                    :items="statusVenda"
                    :item-text="(status) => status.value"
                    :item-value="(status) => status.key"
                    dense
                  >
                  </v-select>
                </v-col>
                <v-col cols="12" md="2">
                  <v-select
                    clear-icon="mdi-close"
                    clearable
                    outlined
                    v-model="filter.tipo_servico"
                    label="Tipo de Serviço"
                    :items="tipoOrcamento"
                    :item-text="(tipo) => tipo.value"
                    :item-value="(tipo) => tipo.key"
                    dense
                  >
                  </v-select>
                </v-col>
                <v-col cols="12" md="2">
                  <v-autocomplete
                    clear-icon="mdi-close"
                    clearable
                    outlined
                    v-model="filter.cotado_por"
                    label="Cotado por"
                    :item-text="(item) => `${item.nome} ${item.sobrenome}`"
                    :item-value="(item) => item.id"
                    :items="users"
                    dense
                  >
                  </v-autocomplete>
                </v-col>
                <v-col cols="16" md="4">
                  <v-slider
                    v-model="quantidade_cotacoes"
                    label="Qtd. Cotações"
                    thumb-color="blue"
                    thumb-label
                    max="20"
                    prepend-icon="mdi-close"
                    @click:prepend="clearCotacoesFilter"
                    @change="
                      () => (filter.quantidade_cotacoes = quantidade_cotacoes)
                    "
                  />
                </v-col>
              </v-row>
            </v-container>
          </template>

          <template v-slot:item.status="{ value }">
            <status-orcamento :status="value"></status-orcamento>
          </template>

          <template v-slot:item.venda="{ value }">
            <copy-link-field :value="value.id"
              >#{{ value.id }} - {{ value.cliente | nomeSobrenome }}
              {{
                value.cliente
                  ? ""
                  : value.cliente_empresa
                  ? value.cliente_empresa.nome
                  : ""
              }}</copy-link-field
            >
            <v-btn
              small
              icon
              color="primary"
              :to="{ name: 'venda-info', params: { id: value.id } }"
            >
              <v-icon small> mdi-open-in-new </v-icon>
            </v-btn>
          </template>
          <template v-slot:item.servico="{ item, value }">
            <div class="text-center">
              {{ orcamentoTipoServico(item) }}
            </div>
          </template>
          <template v-slot:item.qtd_cotacoes="{ item, value }">
            <div>
              <v-badge
                v-if="item.cotacoes && item.cotacoes.length > 0"
                :value="2"
                :content="item.cotacoes && item.cotacoes.length"
              >
              </v-badge>
              <div v-else>Nenhuma</div>
            </div>
          </template>
          <template v-slot:item.data_abertura="{ item, value }">
            <div>
              {{ item.created_at | convertDataBR }}
            </div>
          </template>
          <template v-slot:item.ultima_alteracao="{ item, value }">
            <div>
              {{ item.updated_at | convertDataBR }} às
              {{ item.updated_at | dataHoraMinuto }}
            </div>
          </template>
          <template v-slot:item.consultor="{ item, value }">
            <div>
              {{ item.venda.consultor | nomeSobrenome }}
            </div>
          </template>
          <template v-slot:item.fechado_por="{ item, value }">
            {{ item.fechado_por | nomeSobrenome }}
          </template>
          <template v-slot:item.descricao="{ item }">
            {{ item.descricao }}
          </template>
          <template v-slot:item.actions="{ item }">
            <v-btn
              icon
              color="transparent"
              :to="{ name: 'orcamento', params: { id: item.id } }"
            >
              <v-icon small color="primary" class="mr-2"
                >mdi-open-in-new
              </v-icon>
            </v-btn>
          </template>
          <template v-slot:no-data>
            Nenhum orçamento solicitado para essa venda
          </template>
        </v-data-table>
        <v-pagination
          v-model="currentPage"
          :length="numPages"
          :total-visible="9"
        />
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
import SolicitarOrcamento from "@/components/widgets/SolicitarOrcamento";
import StatusOrcamento from "@/components/widgets/StatusOrcamento";
import StatusVenda from "@/components/widgets/StatusVenda";
import CopyLinkField from "@/components/widgets/CopyLinkField";
import OrcamentoMixin from "@/mixins/OrcamentoMixin";
import STATUS_ORCAMENTO from "@/constants/statusOrcamento";
import STATUS_VENDA from "@/constants/statusVenda";
import TIPO_ORCAMENTO from "@/constants/tipoOrcamento";
import api from "@/api/api.js";

export default {
  components: {
    CopyLinkField,
    StatusOrcamento,
    SolicitarOrcamento,
    StatusVenda,
  },
  mixins: [OrcamentoMixin],
  data() {
    return {
      filter: {
        status: null,
        status_venda: null,
        consultor: null,
        tipo_servico: null,
        quantidade_cotacoes: null,
        cotado_por: "",
      },
      quantidade_cotacoes: 0,
      orcamentos: [],
      loadingTable: true,
      statusOrcamento: STATUS_ORCAMENTO,
      statusVenda: STATUS_VENDA,
      tipoOrcamento: TIPO_ORCAMENTO,
      search: "",
      headers: [
        {
          text: "ID",
          align: "start",
          sortable: true,
          value: "id",
        },
        {
          text: "Status",
          value: "status",
        },
        {
          text: "Venda",
          value: "venda",
        },
        {
          text: "Descrição Rápida",
          value: "descricao",
        },
        {
          text: "Tipo de serviço",
          value: "servico",
        },
        {
          text: "Quantidade de cotações",
          value: "qtd_cotacoes",
        },
        {
          text: "Data de entrada do pedido",
          value: "data_abertura",
        },
        {
          text: "Data última alteração",
          value: "ultima_alteracao",
        },
        {
          text: "Consultor",
          value: "consultor",
        },
        {
          text: "Fechado por",
          value: "fechado_por",
        },
        {
          text: "Ações",
          value: "actions",
        },
      ],
      currentPage: 1,
      numPages: 1,
    };
  },

  watch: {
    currentPage: {
      handler() {
        this.changePage();
      },
    },
    filter: {
      deep: true,
      handler() {
        this.currentPage = 1;
        this.changePage();
      },
    },
  },

  methods: {
    changePage() {
      let filters = [];

      const filterProperties = [
        "status",
        "consultor",
        "tipo_servico",
        "quantidade_cotacoes",
        "cotado_por",
        "status_venda",
      ];
      filterProperties.forEach((property) => {
        if (this.filter[property] !== null) {
          filters.push([property, this.filter[property]]);
        }
      });

      this.loadingTable = true;

      api.getOrcamentosDetalhado(this.currentPage, filters).then((res) => {
        this.orcamentos = res.data.orcamentos;
        this.numPages = res.data.pages;
        this.loadingTable = false;
      });
    },

    clearCotacoesFilter() {
      this.filter.quantidade_cotacoes = null;
    },
  },

  async mounted() {
    this.loadingTable = true;
    await api
      .getOrcamentosDetalhado()
      .then((res) => {
        this.loadingTable = false;
        this.orcamentos = res.data.orcamentos;
        this.numPages = res.data.pages;
        return res;
      })
      .catch((err) => {
        this.loadingTable = false;
        this.$root.vtoast.show({
          message: "Erro ao carregar orçamentos",
          color: "error",
        });
        this.$root.vtoast.show({ message: err.message, color: "error" });
        throw err;
      });
    this.loadingTable = false;
    this.$store.dispatch("usuario/fetchUsuarios");
    await this.$store.dispatch("global/fetchMilhas").catch((err) => {
      this.$root.vtoast.show({ message: err.message, type: "error" });
    });
  },

  computed: {
    loggedUser() {
      return this.$store.getters["usuario/loggedUser"];
    },
    users() {
      return this.$store.getters["usuario/usuarios"];
    },
  },
};
</script>
