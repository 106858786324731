import VendaEditOrView from "@/components/venda/VendaEditOrView";
import VendaOrcamentosView from "@/components/venda/VendaOrcamentosView";
import VendaCotacoesView from "@/components/venda/VendaCotacaoView";
import VendaCarrinhoView from "@/components/venda/carrinho/VendaCarrinhoView";
import VendaServicosView from "@/components/venda/VendaServicosView";
import Pagamento from "@/components/venda/VendaPagamentoView";
import NotificacoesVenda from "@/components/Notificacoes/NotificacoesVenda"
import VendaEmissaoView from "@/components/venda/VendaEmissaoView";
import VendaRacsView from "@/components/venda/rac/VendaRacsView";

export default [
    {
        path: "info",
        name: "venda-info",
        component: VendaEditOrView
    },
    {
        path: "orcamentos",
        name: "venda-orcamentos",
        component: VendaOrcamentosView
    },
    {
        path: "cotacoes",
        name: "venda-cotacoes",
        component: VendaCotacoesView
    },
    {
        path: "carrinho",
        name: "venda-carrinho",
        component: VendaCarrinhoView
    },
    {
        path: "validacao",
        name: "venda-servicos",
        component: VendaServicosView 
    },
    {
        path: "pagamentos",
        name: "venda-pagamentos",
        component: Pagamento
    },
    {
        path: "emissoes",
        name: "venda-emissoes",
        component: VendaEmissaoView
    },
    {
        path: "notificacoes",
        name: "venda-notificacoes",
        component: NotificacoesVenda
    },
    {
        path: "racs",
        name: "venda-racs",
        component: VendaRacsView
    }
];
