<template>
  <div>
    <v-autocomplete
      :loading="loading"
      :items="usuarios"
      v-model="form"
      @input="input()"
      item-text="nome"
      item-value="id"
      :label="label"
      outlined
    >
      <template v-slot:item="{ item }">
        {{ item | nomeSobrenome }}
      </template>
      <template v-slot:selection="{ item }">
        {{ item | nomeSobrenome }}
      </template>
    </v-autocomplete>
  </div>
</template>

<script>
import { mapState } from "vuex";

export default {
  props: {
    value: {
      type: [Object, Number],
    },
    label: {
      type: String,
      default: "Usuário",
    },
  },
  data() {
    return {
      loading: false,
      form: null,
    };
  },
  computed: {
    ...mapState({
      usuarios: (state) => state.usuario.usuarios,
    }),
  },
  created() {
    if (!this.value) return;
    if (Number.isInteger(this.value)) {
      this.form = this.value;
    } else {
      this.form = this.value.id;
    }
  },
  async mounted() {
    if (this.usuarios.length === 0) {
      this.loading = true;
      await this.$store.dispatch("usuario/fetchUsuarios");
      this.loading = false;
    }
  },
  methods: {
    input() {
      this.$emit("input", this.form);
    },
  },
};
</script>
