          <!-- feito verificação em relação se os objetos estão vindo da props item(cotação) ou da props orcamento -->
<template>
  <v-container fluid v-if="orcamento_voo || cotacao_voo">
    <v-row v-if="orcamento_voo && orcamento_voo.tipo_trecho !== 'multitrecho' || cotacao_voo">
      <v-col>
        <v-field-value label="Origem" v-if="orcamento_voo?.trechos.length>0 || cotacao_voo?.subcotacoes[0].viagem.trechos.length>0">
          {{ orcamento_voo?.trechos[0].origem || cotacao_voo?.subcotacoes[0].viagem.trechos[0].pernas[0].origem | localCidadeResumida}}
        </v-field-value>
      </v-col>
      <v-col>
        <v-field-value label="Flexibilidade Origem">
          {{ orcamento_voo?.flexibilidade_origem? "Sim":"Não" }}
        </v-field-value>
        <v-field-value label="Flexibilidade Destino">
          {{ orcamento_voo?.flexibilidade_destino? "Sim":"Não" }}
        </v-field-value>
      </v-col>
      <v-col>
        <v-row>
          <v-col>
            <v-field-value label="Ida">
              {{ orcamento_voo?.trechos[0].data_inicio || cotacao_voo?.subcotacoes[0].viagem.trechos[0].pernas[0].data_hora_partida | convertDataBR }}
              -
              {{ orcamento_voo?.trechos[0].data_fim || cotacao_voo?.subcotacoes[0].viagem.trechos[0].pernas[0].data_hora_chegada | convertDataBR }}
            </v-field-value>

            <v-field-value label="Volta" v-if="orcamento_voo?.tipo_trecho==='ida_volta' || cotacao_voo?.subcotacoes[0].viagem.trechos[1]">
              {{ orcamento_voo?.trechos[1].data_inicio || cotacao_voo?.subcotacoes[0].viagem.trechos[1].pernas[0].data_hora_partida | convertDataBR }}
              -
              {{ orcamento_voo?.trechos[1].data_fim || cotacao_voo?.subcotacoes[0].viagem.trechos[1].pernas[0].data_hora_chegada | convertDataBR }}
            </v-field-value>
          </v-col>
        </v-row>
      </v-col>
      <v-col>
        <v-field-value label="Classes">
        </v-field-value>
        <p class="ma-0 pl-4" v-if="orcamento_voo?.economica || cotacao_voo?.subcotacoes[0].viagem.trechos[0].pernas[0].economica">
          Econômica
        </p>
        <p class="ma-0 pl-4" v-if="orcamento_voo?.executiva || cotacao_voo?.subcotacoes[0].viagem.trechos[0].pernas[0].executiva">
          Executiva
        </p>
        <p class="ma-0 pl-4" v-if="orcamento_voo?.primeira_classe || cotacao_voo?.subcotacoes[0].viagem.trechos[0].pernas[0].primeira_classe">
          Primeira Classe
        </p>

      </v-col>
      <v-col>
        <v-field-value label="Adultos">
          {{ orcamento_voo?.adultos || cotacao_voo?.subcotacoes[0].viagem.trechos[0].pernas[0].adultos}}
        </v-field-value>
        <v-field-value label="Crianças">
          {{ orcamento_voo?.criancas || cotacao_voo?.subcotacoes[0].viagem.trechos[0].pernas[0].criancas}}
        </v-field-value>
      </v-col>
    </v-row>
    <v-row v-else>
      <div class="red--text">
        Visualização de vôo multitrecho não implementada ainda
      </div>
    </v-row>
  </v-container>
  <v-container fluid v-else>
    <v-alert color="error">
      Erro ao exibir orçamento vinculado. Por favor, consulte a equipe técnica.
    </v-alert>
  </v-container>
</template>

<script>
import VFieldValue from "@/components/widgets/VFieldValue";

export default {
  components: {VFieldValue},
  props: {
    orcamento: {
      type: Object,
      default: null
    },
    item:{ // item == cotação 
      type: Object,
      default: () => {},
    }
  },
  computed: {
    orcamento_voo() {
      return this.orcamento && this.orcamento.orcamento_voo;
    },
    cotacao_voo(){
      return this.item && this.item.cotacao_voo;
    },
  }
}
</script>
