// Lista de Países, Máscaras de Telefone, Bandeiras

export default [
  {
    "name": "Brasil",
    "code": "+55",
    "iso": "BR",
    "flag": "https://cdn.kcak11.com/CountryFlags/countries/br.svg",
    "mask": [
      "(##)####-####",
      "(##)#####-####"
    ]
  },
  {
    "name": "Angola",
    "code": "+244",
    "iso": "AO",
    "flag": "https://cdn.kcak11.com/CountryFlags/countries/ao.svg",
    "mask": "(###)###-###"
  },
  {
    "name": "Argentina",
    "code": "+54",
    "iso": "AR",
    "flag": "https://cdn.kcak11.com/CountryFlags/countries/ar.svg",
    "mask": "(###)###-####"
  },
  {
    "name": "Australia",
    "code": "+61",
    "iso": "AU",
    "flag": "https://cdn.kcak11.com/CountryFlags/countries/au.svg",
    "mask": "#-####-####"
  },
  {
    "name": "Austria",
    "code": "+43",
    "iso": "AT",
    "flag": "https://cdn.kcak11.com/CountryFlags/countries/at.svg",
    "mask": "(###)###-####"
  },
  {
    "name": "Estados Unidos da América",
    "code": "+1",
    "iso": "US",
    "flag": "https://cdn.kcak11.com/CountryFlags/countries/us.svg",
    "mask": "(###)###-####"
  },
  {
    "name": "Bahamas",
    "code": "+1",
    "iso": "BS",
    "flag": "https://cdn.kcak11.com/CountryFlags/countries/bs.svg",
    "mask": "(242)###-####"
  },
  {
    "name": "Bélgica",
    "code": "+32",
    "iso": "BE",
    "flag": "https://cdn.kcak11.com/CountryFlags/countries/be.svg",
    "mask": "(###)###-###"
  },
  {
    "name": "Belize",
    "code": "+501",
    "iso": "BZ",
    "flag": "https://cdn.kcak11.com/CountryFlags/countries/bz.svg",
    "mask": "###-####"
  },
  {
    "name": "Bolívia",
    "code": "+591",
    "iso": "BO",
    "flag": "https://cdn.kcak11.com/CountryFlags/countries/bo.svg",
    "mask": "#-###-####"
  },
  {
    "name": "Cambodja",
    "code": "+855",
    "iso": "KH",
    "flag": "https://cdn.kcak11.com/CountryFlags/countries/kh.svg",
    "mask": "##-###-###"
  },
  {
    "name": "Canadá",
    "code": "+1",
    "iso": "CA",
    "flag": "https://cdn.kcak11.com/CountryFlags/countries/ca.svg",
    "mask": "(###)###-####"
  },
  {
    "name": "Chade",
    "code": "+235",
    "iso": "TD",
    "flag": "https://cdn.kcak11.com/CountryFlags/countries/td.svg",
    "mask": "##-##-##-##"
  },
  {
    "name": "Chile",
    "code": "+56",
    "iso": "CL",
    "flag": "https://cdn.kcak11.com/CountryFlags/countries/cl.svg",
    "mask": "#-####-####"
  },
  {
    "name": "China",
    "code": "+86",
    "iso": "CN",
    "flag": "https://cdn.kcak11.com/CountryFlags/countries/cn.svg",
    "mask": [
      "(###)####-###",
      "(###)####-####",
      "##-#####-#####"
    ]
  },
  {
    "name": "Colômbia",
    "code": "+57",
    "iso": "CO",
    "flag": "https://cdn.kcak11.com/CountryFlags/countries/co.svg",
    "mask": "(###)###-####"
  },
  {
    "name": "Costa Rica",
    "code": "+506",
    "iso": "CR",
    "flag": "https://cdn.kcak11.com/CountryFlags/countries/cr.svg",
    "mask": "####-####"
  },
  {
    "name": "Croácia",
    "code": "+385",
    "iso": "HR",
    "flag": "https://cdn.kcak11.com/CountryFlags/countries/hr.svg",
    "mask": "##-###-###"
  },
  {
    "name": "Cuba",
    "code": "+53",
    "iso": "CU",
    "flag": "https://cdn.kcak11.com/CountryFlags/countries/cu.svg",
    "mask": "#-###-####"
  },
  {
    "name": "Republica Tcheca",
    "code": "+420",
    "iso": "CZ",
    "flag": "https://cdn.kcak11.com/CountryFlags/countries/cz.svg",
    "mask": "(###)###-###"
  },
  {
    "name": "Dinamarca",
    "code": "+45",
    "iso": "DK",
    "flag": "https://cdn.kcak11.com/CountryFlags/countries/dk.svg",
    "mask": "##-##-##-##"
  },
  {
    "name": "Dominica",
    "code": "+1",
    "iso": "DM",
    "flag": "https://cdn.kcak11.com/CountryFlags/countries/dm.svg",
    "mask": "(767)###-####"
  },
  {
    "name": "República Dominicana",
    "code": "+1",
    "iso": "DO",
    "flag": "https://cdn.kcak11.com/CountryFlags/countries/do.svg",
    "mask": [
      "(809)###-####",
      "(829)###-####",
      "(849)###-####"
    ]
  },
  {
    "name": "Equador",
    "code": "+593",
    "iso": "EC",
    "flag": "https://cdn.kcak11.com/CountryFlags/countries/ec.svg",
    "mask": [
      "#-###-####",
      "##-###-####"
    ]
  },
  {
    "name": "Egito",
    "code": "+20",
    "iso": "EG",
    "flag": "https://cdn.kcak11.com/CountryFlags/countries/eg.svg",
    "mask": "(###)###-####"
  },
  {
    "name": "El Salvador",
    "code": "+503",
    "iso": "SV",
    "flag": "https://cdn.kcak11.com/CountryFlags/countries/sv.svg",
    "mask": "##-##-####"
  },
  {
    "name": "Estônia",
    "code": "+372",
    "iso": "EE",
    "flag": "https://cdn.kcak11.com/CountryFlags/countries/ee.svg",
    "mask": [
      "###-####",
      "####-####"
    ]
  },
  {
    "name": "Etiópia",
    "code": "+251",
    "iso": "ET",
    "flag": "https://cdn.kcak11.com/CountryFlags/countries/et.svg",
    "mask": "##-###-####"
  },
  {
    "name": "Fiji",
    "code": "+679",
    "iso": "FJ",
    "flag": "https://cdn.kcak11.com/CountryFlags/countries/fj.svg",
    "mask": "##-#####"
  },
  {
    "name": "Finland",
    "code": "+358",
    "iso": "FI",
    "flag": "https://cdn.kcak11.com/CountryFlags/countries/fi.svg",
    "mask": "(###)###-##-##"
  },
  {
    "name": "França",
    "code": "+33",
    "iso": "FR",
    "flag": "https://cdn.kcak11.com/CountryFlags/countries/fr.svg",
    "mask": "(###)###-###"
  },
  {
    "name": "Guiana Francesa",
    "code": "+594",
    "iso": "GF",
    "flag": "https://cdn.kcak11.com/CountryFlags/countries/gf.svg",
    "mask": "#####-####"
  },
  {
    "name": "Georgia",
    "code": "+995",
    "iso": "GE",
    "flag": "https://cdn.kcak11.com/CountryFlags/countries/ge.svg",
    "mask": "(###)###-###"
  },
  {
    "name": "Alemanha",
    "code": "+49",
    "iso": "DE",
    "flag": "https://cdn.kcak11.com/CountryFlags/countries/de.svg",
    "mask": [
      "###-###",
      "(###)##-##",
      "(###)##-###",
      "(###)##-####",
      "(###)###-####",
      "(####)###-####"
    ]
  },
  {
    "name": "Gana",
    "code": "+233",
    "iso": "GH",
    "flag": "https://cdn.kcak11.com/CountryFlags/countries/gh.svg",
    "mask": "(###)###-###"
  },
  {
    "name": "Gibraltar",
    "code": "+350",
    "iso": "GI",
    "flag": "https://cdn.kcak11.com/CountryFlags/countries/gi.svg",
    "mask": "###-#####"
  },
  {
    "name": "Grécia",
    "code": "+30",
    "iso": "GR",
    "flag": "https://cdn.kcak11.com/CountryFlags/countries/gr.svg",
    "mask": "(###)###-####"
  },
  {
    "name": "Groenlândia",
    "code": "+299",
    "iso": "GL",
    "flag": "https://cdn.kcak11.com/CountryFlags/countries/gl.svg",
    "mask": "##-##-##"
  },
  {
    "name": "Guatemala",
    "code": "+502",
    "iso": "GT",
    "flag": "https://cdn.kcak11.com/CountryFlags/countries/gt.svg",
    "mask": "#-###-####"
  },
  {
    "name": "Guiné",
    "code": "+224",
    "iso": "GN",
    "flag": "https://cdn.kcak11.com/CountryFlags/countries/gn.svg",
    "mask": "##-###-###"
  },
  {
    "name": "Guiné-Bissau",
    "code": "+245",
    "iso": "GW",
    "flag": "https://cdn.kcak11.com/CountryFlags/countries/gw.svg",
    "mask": "#-######"
  },
  {
    "name": "Guiana",
    "code": "+592",
    "iso": "GY",
    "flag": "https://cdn.kcak11.com/CountryFlags/countries/gy.svg",
    "mask": "###-####"
  },
  {
    "name": "Haiti",
    "code": "+509",
    "iso": "HT",
    "flag": "https://cdn.kcak11.com/CountryFlags/countries/ht.svg",
    "mask": "##-##-####"
  },
  {
    "name": "Vaticano",
    "code": "+39",
    "iso": "VA",
    "flag": "https://cdn.kcak11.com/CountryFlags/countries/va.svg",
    "mask": "06 698#####"
  },
  {
    "name": "Honduras",
    "code": "+504",
    "iso": "HN",
    "flag": "https://cdn.kcak11.com/CountryFlags/countries/hn.svg",
    "mask": "####-####"
  },
  {
    "name": "Hong Kong",
    "code": "+852",
    "iso": "HK",
    "flag": "https://cdn.kcak11.com/CountryFlags/countries/hk.svg",
    "mask": "####-####"
  },
  {
    "name": "Hungria",
    "code": "+36",
    "iso": "HU",
    "flag": "https://cdn.kcak11.com/CountryFlags/countries/hu.svg",
    "mask": "(###)###-###"
  },
  {
    "name": "Islândia",
    "code": "+354",
    "iso": "IS",
    "flag": "https://cdn.kcak11.com/CountryFlags/countries/is.svg",
    "mask": "###-####"
  },
  {
    "name": "Índia",
    "code": "+91",
    "iso": "IN",
    "flag": "https://cdn.kcak11.com/CountryFlags/countries/in.svg",
    "mask": "(####)###-###"
  },
  {
    "name": "Indonesia",
    "code": "+62",
    "iso": "ID",
    "flag": "https://cdn.kcak11.com/CountryFlags/countries/id.svg",
    "mask": [
      "##-###-##",
      "##-###-###",
      "##-###-####",
      "(8##)###-###",
      "(8##)###-##-###"
    ]
  },
  {
    "name": "Irlanda",
    "code": "+353",
    "iso": "IE",
    "flag": "https://cdn.kcak11.com/CountryFlags/countries/ie.svg",
    "mask": "(###)###-###"
  },
  {
    "name": "Israel",
    "code": "+972",
    "iso": "IL",
    "flag": "https://cdn.kcak11.com/CountryFlags/countries/il.svg",
    "mask": [
      "#-###-####",
      "5#-###-####"
    ]
  },
  {
    "name": "Itália",
    "code": "+39",
    "iso": "IT",
    "flag": "https://cdn.kcak11.com/CountryFlags/countries/it.svg",
    "mask": "(###)####-###"
  },
  {
    "name": "Jamaica",
    "code": "+1",
    "iso": "JM",
    "flag": "https://cdn.kcak11.com/CountryFlags/countries/jm.svg",
    "mask": "(876)###-####"
  },
  {
    "name": "Japão",
    "code": "+81",
    "iso": "JP",
    "flag": "https://cdn.kcak11.com/CountryFlags/countries/jp.svg",
    "mask": [
      "(###)###-###",
      "##-####-####"
    ]
  },
  {
    "name": "Coréia do Sul",
    "code": "+82",
    "iso": "KR",
    "flag": "https://cdn.kcak11.com/CountryFlags/countries/kr.svg",
    "mask": "##-###-####"
  },
  {
    "name": "Liechtenstein",
    "code": "+423",
    "iso": "LI",
    "flag": "https://cdn.kcak11.com/CountryFlags/countries/li.svg",
    "mask": "(###)###-####"
  },
  {
    "name": "Lituânia",
    "code": "+370",
    "iso": "LT",
    "flag": "https://cdn.kcak11.com/CountryFlags/countries/lt.svg",
    "mask": "(###)##-###"
  },
  {
    "name": "Luxemburgo",
    "code": "+352",
    "iso": "LU",
    "flag": "https://cdn.kcak11.com/CountryFlags/countries/lu.svg",
    "mask": "(###)###-###"
  },
  {
    "name": "Madagascar",
    "code": "+261",
    "iso": "MG",
    "flag": "https://cdn.kcak11.com/CountryFlags/countries/mg.svg",
    "mask": "##-##-#####"
  },
  {
    "name": "México",
    "code": "+52",
    "iso": "MX",
    "flag": "https://cdn.kcak11.com/CountryFlags/countries/mx.svg",
    "mask": [
      "##-##-####",
      "(###)###-####"
    ]
  },
  {
    "name": "Monaco",
    "code": "+377",
    "iso": "MC",
    "flag": "https://cdn.kcak11.com/CountryFlags/countries/mc.svg",
    "mask": [
      "##-###-###",
      "(###)###-###"
    ]
  },
  {
    "name": "Mongolia",
    "code": "+976",
    "iso": "MN",
    "flag": "https://cdn.kcak11.com/CountryFlags/countries/mn.svg",
    "mask": "##-##-####"
  },
  {
    "name": "Marrocos",
    "code": "+212",
    "iso": "MA",
    "flag": "https://cdn.kcak11.com/CountryFlags/countries/ma.svg",
    "mask": "##-####-###"
  },
  {
    "name": "Moçambique",
    "code": "+258",
    "iso": "MZ",
    "flag": "https://cdn.kcak11.com/CountryFlags/countries/mz.svg",
    "mask": "##-###-###"
  },
  {
    "name": "Myanmar",
    "code": "+95",
    "iso": "MM",
    "flag": "https://cdn.kcak11.com/CountryFlags/countries/mm.svg",
    "mask": [
      "###-###",
      "#-###-###",
      "##-###-###"
    ]
  },
  {
    "name": "Nepal",
    "code": "+977",
    "iso": "NP",
    "flag": "https://cdn.kcak11.com/CountryFlags/countries/np.svg",
    "mask": "##-###-###"
  },
  {
    "name": "Países Baixos",
    "code": "+31",
    "iso": "NL",
    "flag": "https://cdn.kcak11.com/CountryFlags/countries/nl.svg",
    "mask": "##-###-####"
  },
  {
    "name": "Nova Zelândia",
    "code": "+24",
    "iso": "NZ",
    "flag": "https://cdn.kcak11.com/CountryFlags/countries/nz.svg",
    "mask": [
      "#-###-###",
      "(###)###-###",
      "(###)###-####"
    ]
  },
  {
    "name": "Nicaragua",
    "code": "+505",
    "iso": "NI",
    "flag": "https://cdn.kcak11.com/CountryFlags/countries/ni.svg",
    "mask": "####-####"
  },
  {
    "name": "Níger",
    "code": "+227",
    "iso": "NE",
    "flag": "https://cdn.kcak11.com/CountryFlags/countries/ne.svg",
    "mask": "##-##-####"
  },
  {
    "name": "Nigeria",
    "code": "+234",
    "iso": "NG",
    "flag": "https://cdn.kcak11.com/CountryFlags/countries/ng.svg",
    "mask": [
      "##-###-##",
      "##-###-###",
      "(###)###-####"
    ]
  },
  {
    "name": "Noruega",
    "code": "+47",
    "iso": "NO",
    "flag": "https://cdn.kcak11.com/CountryFlags/countries/no.svg",
    "mask": "(###)##-###"
  },
  {
    "name": "Panamá",
    "code": "+507",
    "iso": "PA",
    "flag": "https://cdn.kcak11.com/CountryFlags/countries/pa.svg",
    "mask": "###-####"
  },
  {
    "name": "Paraguai",
    "code": "+595",
    "iso": "PY",
    "flag": "https://cdn.kcak11.com/CountryFlags/countries/py.svg",
    "mask": "(###)###-###"
  },
  {
    "name": "Peru",
    "code": "+51",
    "iso": "PE",
    "flag": "https://cdn.kcak11.com/CountryFlags/countries/pe.svg",
    "mask": "(###)###-###"
  },
  {
    "name": "Filipinas",
    "code": "+63",
    "iso": "PH",
    "flag": "https://cdn.kcak11.com/CountryFlags/countries/ph.svg",
    "mask": "(###)###-####"
  },
  {
    "name": "Polônia",
    "code": "+48",
    "iso": "PL",
    "flag": "https://cdn.kcak11.com/CountryFlags/countries/pl.svg",
    "mask": "(###)###-###"
  },
  {
    "name": "Portugal",
    "code": "+351",
    "iso": "PT",
    "flag": "https://cdn.kcak11.com/CountryFlags/countries/pt.svg",
    "mask": "##-###-####"
  },
  {
    "name": "Porto Rico",
    "code": "+1",
    "iso": "PR",
    "flag": "https://cdn.kcak11.com/CountryFlags/countries/pr.svg",
    "mask": [
      "(787) ### ####",
      "(939) ### ####"
    ]
  },
  {
    "name": "Qatar",
    "code": "+974",
    "iso": "QA",
    "flag": "https://cdn.kcak11.com/CountryFlags/countries/qa.svg",
    "mask": "####-####"
  },
  {
    "name": "Romania",
    "code": "+40",
    "iso": "RO",
    "flag": "https://cdn.kcak11.com/CountryFlags/countries/ro.svg",
    "mask": "##-###-####"
  },
  {
    "name": "Rússia",
    "code": "+7",
    "iso": "RU",
    "flag": "https://cdn.kcak11.com/CountryFlags/countries/ru.svg",
    "mask": "(###)###-##-##"
  },
  {
    "name": "Sérvia",
    "code": "+381",
    "iso": "RS",
    "flag": "https://cdn.kcak11.com/CountryFlags/countries/rs.svg",
    "mask": "##-###-####"
  },
  {
    "name": "Serra Leoa",
    "code": "+232",
    "iso": "SL",
    "flag": "https://cdn.kcak11.com/CountryFlags/countries/sl.svg",
    "mask": "##-######"
  },
  {
    "name": "Singapura",
    "code": "+65",
    "iso": "SG",
    "flag": "https://cdn.kcak11.com/CountryFlags/countries/sg.svg",
    "mask": "####-####"
  },
  {
    "name": "Eslováquia",
    "code": "+421",
    "iso": "SK",
    "flag": "https://cdn.kcak11.com/CountryFlags/countries/sk.svg",
    "mask": "(###)###-###"
  },
  {
    "name": "Slovenia",
    "code": "+386",
    "iso": "SI",
    "flag": "https://cdn.kcak11.com/CountryFlags/countries/si.svg",
    "mask": "##-###-###"
  },
  {
    "name": "África do Sul",
    "code": "+27",
    "iso": "ZA",
    "flag": "https://cdn.kcak11.com/CountryFlags/countries/za.svg",
    "mask": "##-###-####"
  },
  {
    "name": "Espanha",
    "code": "+34",
    "iso": "ES",
    "flag": "https://cdn.kcak11.com/CountryFlags/countries/es.svg",
    "mask": "(###)###-###"
  },
  {
    "name": "Suriname",
    "code": "+597",
    "iso": "SR",
    "flag": "https://cdn.kcak11.com/CountryFlags/countries/sr.svg",
    "mask": [
      "###-###",
      "###-####"
    ]
  },
  {
    "name": "Suécia",
    "code": "+46",
    "iso": "SE",
    "flag": "https://cdn.kcak11.com/CountryFlags/countries/se.svg",
    "mask": "##-###-####"
  },
  {
    "name": "Suíça",
    "code": "+41",
    "iso": "CH",
    "flag": "https://cdn.kcak11.com/CountryFlags/countries/ch.svg",
    "mask": "##-###-####"
  },
  {
    "name": "Taiwan",
    "code": "+886",
    "iso": "TW",
    "flag": "https://cdn.kcak11.com/CountryFlags/countries/tw.svg",
    "mask": [
      "####-####",
      "#-####-####"
    ]
  },
  {
    "name": "Tailândia",
    "code": "+66",
    "iso": "TH",
    "flag": "https://cdn.kcak11.com/CountryFlags/countries/th.svg",
    "mask": [
      "##-###-###",
      "##-###-####"
    ]
  },
  {
    "name": "Timor-Leste",
    "code": "+670",
    "iso": "TL",
    "flag": "https://cdn.kcak11.com/CountryFlags/countries/tl.svg",
    "mask": [
      "###-####",
      "77#-#####",
      "78#-#####"
    ]
  },
  {
    "name": "Ucrânia",
    "code": "+380",
    "iso": "UA",
    "flag": "https://cdn.kcak11.com/CountryFlags/countries/ua.svg",
    "mask": "(##)###-##-##"
  },
  {
    "name": "Reino Unido",
    "code": "+44",
    "iso": "GB",
    "flag": "https://cdn.kcak11.com/CountryFlags/countries/gb.svg",
    "mask": "##-####-####"
  },
  {
    "name": "Uruguai",
    "code": "+598",
    "iso": "UY",
    "flag": "https://cdn.kcak11.com/CountryFlags/countries/uy.svg",
    "mask": "#-###-##-##"
  },
]
