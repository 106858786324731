import {get, put, del} from '../modules';

export default {
    getCotacoes() {
        return get("/api/cotacoes")
    },

    updateCotacao(id, cotacao) {
        return put(`/api/cotacoes/${id}/`, cotacao);
    },

    deleteCotacao(cotacaoId) {
        return del(`/api/cotacoes/${cotacaoId}/`);
    },
}
