<template>
  <div class="text-center">
    <v-snackbar
      :color="color"
      :timeout="timer"
      v-model="showSnackbar"
      top
      right
      app
    >
      <v-icon left>{{ icon }}</v-icon>
      {{ message }}
    </v-snackbar>
  </div>
</template>

<script>
export default {
  name: "vtoast",
  data() {
    return {
      showSnackbar: false,
      message: "",
      color: "success",
      icon: "mdi-check",
      timer: 3000,
    };
  },
  computed: {
    messages() {
      return this.$store.getters[this.normalizedNamespace + "toastMessages"];
    },
    positionClass() {
      return `toast-position-${this.position}`;
    },
    normalizedNamespace() {
      if (this.namespace === "") {
        return "";
      }
      if (!/\/$/.test(this.namespace)) {
        return this.namespace + "/";
      }
      return this.namespace;
    },
  },
  methods: {
    show(data) {
      this.message = data.message || 'missing "message".';
      this.color = data.color || "success";
      this.timer = data.timer || 3000;
      this.icon = data.icon || "mdi-check";
      this.showSnackbar = true;
    },
    showApiError(err) {
      this.show({ message: err.message, color: "error", icon: "mdi-close" });
    },
    close(id) {
      this.$store.dispatch(this.normalizedNamespace + REMOVE_TOAST_MESSAGE, id);
    },
    messageTypeClass(message) {
      return `toast-type-${message.type}`;
    },
  },
};
</script>
