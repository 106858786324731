<template>
<v-container fluid>
  Usuarios emissões list
</v-container>
</template>

<script>
export default {
}
</script>

<style scoped>

</style>
