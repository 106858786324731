<template>
  <v-data-table :headers="headers" :items="beneficiarios" class="elevation-5">
    <!-- TEMPLATES TABELA -->
    <template v-slot:item.contato="{ item }">
      {{ item.contato | formatContatoNome(contatos) }}
    </template>
    <template v-slot:item.actions="{ item }">
      <v-icon small class="mr-2" @click="editItem(item)"> mdi-pencil </v-icon>
      <v-icon small @click="deleteItem(item)"> mdi-delete </v-icon>
    </template>
    <template v-slot:no-data>
      <v-btn color="primary" @click="$emit('refresh')"> Reset </v-btn>
    </template>

    <!-- DIALOGOS -->
    <template v-slot:top>
      <v-toolbar flat>
        <v-toolbar-title>Beneficiários </v-toolbar-title>
        <v-divider class="mx-4" inset vertical></v-divider>
        <v-toolbar-title>
          <v-icon color="green" v-for="i in beneficiarios.length" :key="i">
            mdi-account
          </v-icon>
          <v-icon
            color="red"
            v-for="i in qtdPax.total - beneficiarios.length"
            :key="i + 100"
          >
            mdi-account
          </v-icon>
        </v-toolbar-title>
        <v-divider class="mx-4" inset vertical></v-divider>
        <v-toolbar-title>
          <div small v-if="qtdPax.criancas">
            * {{ qtdPax.criancas }}
            {{ qtdPax.criancas > 1 ? "crianças" : "criança" }}
          </div>

          <div small v-if="qtdPax.bebes">
            * {{ qtdPax.bebes }} {{ qtdPax.bebes > 1 ? "bebês" : "bebê" }}
          </div>
        </v-toolbar-title>
        <v-spacer></v-spacer>
        <!-- EDIÇÃO-->
        <v-dialog v-model="dialog" max-width="800px">
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              v-show="disabledNovoBeneficiario"
              color="primary"
              dark
              class="mb-2"
              v-bind="attrs"
              v-on="on"
            >
              Novo Beneficiário
            </v-btn>
          </template>
          <new-beneficiario
            v-model="editedItem"
            :cotacao="cotacao"
            :dialog="dialog"
            :contatos="contatos"
            @close="dialog = false"
            @save="save"
            :filters="filters"
            :isButtonDisabled="isButtonDisabled"
          />
        </v-dialog>
        <!-- DIALOG DELETE -->
        <v-dialog v-model="dialogDelete" max-width="500px">
          <v-card>
            <v-card-title class="text-h5"
              >Tem certeza que deseja deletar este item?</v-card-title
            >
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn text @click="closeDelete">Não</v-btn>
              <v-btn text @click="deleteItemConfirm">Sim</v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-toolbar>
    </template>
  </v-data-table>
</template>
<script>
import NewBeneficiario from "./NewBeneficiario";
//import { formatContato } from "@/helpers/contato";
import { formatContatoNome } from "@/filters/contatoNome";

export default {
  components: {
    NewBeneficiario,
  },

  props: {
    qtdPax: {
      type: Object,
      default: {
        total: 0,
      },
      required: false,
    },
    value: {
      type: Array,
      default: () => [],
    },
    cotacao: {
      type: Object,
      default: () => {},
    },
    venda: {
      type: Object,
      default: () => {},
    },
    isButtonDisabled: {
      type: Boolean,
      default: false,
    },
  },

  data: () => ({
    dialog: false,
    dialogDelete: false,
    beneficiarios: [],
    headers: [
      { text: "Contato", value: "contato" },
      { text: "Assento", value: "assento" },
      { text: "Tipo de assento", value: "tipo_assento" },
      { text: "Tipo de quarto", value: "tipo_quarto" },
      { text: "Actions", value: "actions" },
    ],
    editedIndex: -1,
    editedItem: {},
    defaultItem: {},
    filters: [],
  }),

  computed: {
    disabledNovoBeneficiario() {
      return this.qtdPax.total > this.beneficiarios.length;
    },
    empresa() {
      return this.venda?.cliente_empresa;
    },
    contatos() {
      return this.$store.getters["contato/contatos"];
    },
  },

  watch: {
    dialog(val) {
      val || this.close();
    },
    dialogDelete(val) {
      val || this.closeDelete();
    },
    beneficiarios(val) {
      this.$emit("input", val);
    },
  },

  mounted() {
    this.getContatos();
    this.beneficiarios = this.value;
  },

  methods: {
    async getContatos() {
      if (this.empresa) {
        this.filters = [{ empresa: this.empresa.id }];
      }
      await this.$store.dispatch("contato/fetchContatos");
    },

    // formatContato(contatoID) {
    //   return formatContato(contatoID, this.contatos);
    // },

    editItem(item) {
      this.editedIndex = this.beneficiarios.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.dialog = true;
    },

    deleteItem(item) {
      this.editedIndex = this.beneficiarios.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.dialogDelete = true;
    },

    async deleteItemConfirm() {
      this.beneficiarios.splice(this.editedIndex, 1);
      this.closeDelete();
      this.$emit("refresh");
    },

    close() {
      this.dialog = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },

    closeDelete() {
      this.dialogDelete = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },
    validate() {
      if (
        this.beneficiarios.some(
          (benefic) => benefic.contato === this.editedItem.contato
        )
      ) {
        this.$root.vtoast.show({
          message: "Beneficiário já inserido!",
          color: "error",
          icon: "mdi-close",
        });
        return false;
      }
      if(!this.editedItem.contato) {
        this.$root.vtoast.show({
          message: "O beneficiário inserido não possui contato vinculado. Favor tentar inserir o beneficiário novamente.",
          color: "error",
          icon: "mdi-close",
        });
        return false;
      }
      return true;
    },
    async save() {
      if (this.validate()) {
        await this.$nextTick(); //aguardar o contato selecionado ser renderizado no DOM
        if (this.editedIndex > -1) {
          Object.assign(this.beneficiarios[this.editedIndex], this.editedItem);
        } else {
          this.beneficiarios.push(this.editedItem);
        }
      }
    },
  },
};
</script>
