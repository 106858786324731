<template >
    <div class="page">
        <div v-for="(cotacao, idxCotacao) in cotacoes" class="cotacao-container">
            <Header/>
            <h3 class="cotacao">Cotação #{{idxCotacao + 1}} (id: {{cotacao.id}})</h3>
            <p>Descrição: {{cotacao.cotacao_outro.descricao_servico}}</p>
            <div class="outro-info">
                <div class="column">
                    <p>Local</p>
                    <p>Nome do Serviço</p>
                    <p>Data início</p>
                    <p>Data fim</p>
                </div>
                <div class="column right">
                    <p>{{cotacao.cotacao_outro.local.nome}}</p>
                    <p>{{cotacao.cotacao_outro.nome_servico}}</p>
                    <p>{{convert(cotacao.cotacao_outro.data_inicio)}}</p>
                    <p>{{convert(cotacao.cotacao_outro.data_fim)}}</p>
                </div>
            </div>
            <div class="precos">
                <p>{{format(
                        converteValorFinanceiroBRL(cotacao.cotacao_outro.custo_total) + 
                        cotacaoValorAjuste(cotacao)
                )}}</p>
            </div>
            <div class="html2pdf__page-break"></div>
        </div>
        <resumo-orcamento :cotacoes="cotacoes" />
    </div>
</template>
<script>
import Header from '../Template/Header.vue'
import CotacaoMixin from "@/mixins/CotacaoMixin";
import ResumoOrcamento from "./ResumoOrcamento.vue";

export default {
    components: {
        Header,
        ResumoOrcamento
    },
    props: {
        cotacoes: {
            type: Array,
            default: () => [],
            required: true
        }
    },
    mixins: [CotacaoMixin],
    methods: {
        format(num){
            if(!num) return "R$ 0,00"
            return this.$options.filters['dinheiroReal'](num)
        },
        convert(date){
            return new  Date(date).toLocaleDateString('pt-BR')
        },
    }
}
</script>
<style >

.precos {
    background-color: #819486;
    border-radius: 15px;
    color: white;
    margin: 3rem 1rem 0 1rem ;
    padding: 1rem;
} 
.precos > p:nth-child(1){
    font-size: 1.5rem;
    margin-left: 1rem;
    margin-bottom: 2rem;
}
.cotacao-container > h3 {
    margin-left: 0;
    color: white;
    background-color: #819486;
    border-radius: 15px;
    padding: 1.5rem;
    text-align: center;
}
.outro-info{
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: space-between;
}
.column{
    padding: 0;
    margin: 1rem;
}
.right{
    text-align: right
}
.cotacao{
    padding-left: 4rem;
    margin: 0.5rem 0 0.5rem 0
}
</style>