<template>
  <v-container class="pb-0">
    <v-card class="mx-auto">
      <v-card-title class="text-h6 ml-2 mb-2 d-flex">
        <v-icon>mdi-office-building</v-icon>
       Hotel 
      </v-card-title>
      <v-card-text>
        <v-row class="pl-2">
          <v-col class="pl-6 mb-2" cols="9">
            <v-row>
              Hotel: {{cotacao.cotacao_hotel.nome_hotel}}
            </v-row>
            <v-row v-if="cotacao.cotacao_hotel.local_hotel">
              Local: {{cotacao.cotacao_hotel.local_hotel | localCidadeResumida}}
            </v-row>
            <v-row v-if="cotacao.cotacao_hotel.checkin_inicio">
              Check-in início: {{cotacao.cotacao_hotel.checkin_inicio | dataHoraBR}}
            </v-row>
            <v-row v-if="cotacao.cotacao_hotel.checkin_fim">
              Check-in fim: {{cotacao.cotacao_hotel.checkin_fim}}
            </v-row>
            <v-row v-if="cotacao.cotacao_hotel.checkout_inicio">
              Check-out início: {{cotacao.cotacao_hotel.checkout_inicio | dataHoraBR}}
            </v-row>
            <v-row v-if="cotacao.cotacao_hotel.checkout_fim">
              Check-out fim: {{cotacao.cotacao_hotel.checkout_fim}}
            </v-row>
            <v-row v-if="cotacao.cotacao_hotel.cafe">
              Café da manhã incluso
            </v-row>
            <v-row>
              Hóspede(s): {{descricaoHospede(beneficiarios)}}
            </v-row>
          </v-col>
          <v-col cols="3" class="text-right black--text font-weight-medium"  >
            <v-row class="texto" v-if="cotacao.cotacao_hotel.local_hotel">
              Valor total: {{ cotacaoValorTotalJuros(cotacao) |dinheiroReal}}
            </v-row>
              <v-row class="texto">
              Valor em aberto: {{ valorAbertoCotacoes[cotacao.id] |dinheiroReal}}
            </v-row >
            
          </v-col>
        </v-row>
      </v-card-text>

    </v-card>
  </v-container>
</template>
  
<script>
import cotacaoMixin from "@/mixins/CotacaoMixin";
export default {
  props: {
    cotacao: {
      type: Object,
      default: () => { }
    },
    beneficiarios: {
      type: Array,
      default: () => []
    },
    valorAbertoCotacoes: {
      type: Object,
      default: () => { }
    },
  },
  mixins: [cotacaoMixin],


  data() {
    return {
      dialog: false,
    };
  },
  methods: {
    descricaoSubcotacaoHotel(cotacaoHotel) {
      const infoHotel = `Hotel: ${cotacaoHotel.nome_hotel} \n
      Local: ${this.$options.filters.localCidadeResumida(cotacaoHotel.local_hotel)}
      ${cotacaoHotel.checkin_inicio ? "Check-in início: "+cotacaoHotel.checkin_inicio : ""}
      `
      return infoHotel
    },
    descricaoHospede(beneficiarios) {
      const infoHospede = beneficiarios.map(beneficiario => {
        return `${this.$options.filters.nomeSobrenome(beneficiario.contato)}\
            ${beneficiario.tipo_quarto ? " - Tipo quarto: " + beneficiario.tipo_quarto : ""}`
      })
      return infoHospede.join(", \n")
    }

  },
};
</script>
<style >
.texto {
  color:black;
}
</style>