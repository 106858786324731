<template>
    <div class="resumo-orcamento">
        <Header/>
        <h3>Resumo do orçamento</h3>
        <div v-for="(preco, idxPreco) in comparePrecos(cotacoes)" class="compare-precos">
            <h5>Cotação {{idxPreco + 1}} {{preco.nome ? ' - ' + preco.nome : ''}}</h5>
            <div>
                <p>{{format(preco.price)}}</p>
                <div v-if="cias" class="cias">
                    <div>
                        <img 
                            v-for="cia in preco.cias"
                            v-if="Object.keys(imgCias).includes(cia.nome_resumido)" 
                            :src="imgCias[cia.nome_resumido]" 
                            alt="CIA"
                        >
                    </div>
                </div>
            </div>
        </div>
        <div class="pagamento">
            <h5>Pagamento</h5>
            <p>Para confirmação da reserva: 1/3 no PIX (CNPJ 42.312.620/0001-06)</p>
            <p>Restante: Cartão de Crédito</p>
        </div>
        <div>
            <h5>Termos de Uso</h5>
            <p>Os termos de uso podem ser consultados em <a href="https://voeskyler.com/termos-de-uso">voeskyler.com/termos-de-uso</a></p>
        </div>
    </div>
</template>
<script>
import CotacaoMixin from "@/mixins/CotacaoMixin";
import imgCias from "@/assets/cias"
export default {
    mixins: [CotacaoMixin],
    props: {
        cotacoes: {
            type: Array,
            default: () => [],
            required: true
        },
    },
    mounted() {
        this.cias = this.$store.state.global.ciaAereas
    },
    data(){
        return {
            cias: [],
            imgCias: imgCias
        }
    },
    methods: {
        format(num){
            if(!num) return "R$ 0,00"
            if(num.valor) num = this.converteValorFinanceiroBRL(num).toFixed(2)
            return this.$options.filters['dinheiroReal'](this.calculoJuros(num))
        },
        comparePrecos(cotacoes){
            const EMPTY_CIA = {
                id: 0,
                created_at: "",
                updated_at: "",
                archived_at: null,
                nome: "",
                nome_resumido: "",
                imagem: "",
                created_by: 0,
                last_edited_by: null,
                archived_by: null
            }
            if(cotacoes.some(cot => cot.cotacao_voo)) return cotacoes.map(
                cotacao => ({
                    id: cotacao.id,
                    cias: [...new Map(
                        cotacao.cotacao_voo.subcotacoes.map(
                            subcot => subcot.viagem.trechos.map(
                                trecho => trecho.pernas.map(perna => perna.cia_aerea || EMPTY_CIA)
                            )
                        )
                    .flat(Infinity)
                    .map(v => v ? [v.id, v] : v)).values()],
                    price: cotacao.cotacao_voo.subcotacoes.reduce(
                            (sum, subcot) => sum + parseFloat(this.subcotacaoVooCustoTotal(subcot)),
                            this.cotacaoValorAjuste(cotacao) 
                        ),
                    nome: ""
                })
            )
            return cotacoes.map(
                cotacao => ({
                    id: cotacao.id,
                    cias: null,
                    price: parseFloat(this.cotacaoValorTotal(cotacao)),
                    nome: cotacao.cotacao_carro ? cotacao.cotacao_carro.empresa :
                        cotacao.cotacao_hotel ? cotacao.cotacao_hotel.nome_hotel : ""

                })
            )
        }
    }
}
</script>
<style>
.resumo-orcamento > h3 {
    font-size: 2rem;
    padding: 2rem;
    margin: 2rem;
    text-align: center;
    border-radius: 15px;
    background-color: #819486;
    color: white;
}
.resumo-orcamento > .compare-precos {
    margin: 2rem;
}
.resumo-orcamento h5 {
    font-size: 1.2rem;
    color: #9c9c9c;
}
.compare-precos p{
    font-size: 1.5rem;
    font-style: italic;
    margin: 1rem;
}
.compare-precos > div {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    width: 100%;
}
.compare-precos .cias img {
    height: 30px;
    margin: 1rem;
}
.resumo-orcamento .pagamento {
    padding: 2rem;
    background-color: #e0e0e0;
    border-radius: 30px;

}
.resumo-orcamento .pagamento h5 {
    margin-bottom: 1rem;
}
</style>