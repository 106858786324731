<template>
  <table-servicos
    :servicos="servicos"
    :loading="loading"
    :pages="numPages"
    @getServicos="(page, filters) => getServicos(page, filters)"
    showVenda
    />
</template>
<script>
import TableServicos from "@/components/Servicos/TableServicos";
import api from "@/api";

export default {
  components: {
    TableServicos,
  },

  data: () => ({
    servicos: [],
    loading: false,
    numPages: 1
  }),

  mounted() {
    this.initialize()
  },

  methods: {
    getServicos(page=1, filters=[]) {
      this.loading = true
      api
        .getServicosDetalhado(page, filters)
        .then((res) => {
          this.servicos = res.data.servicos;
          this.numPages = res.data.pages;
        })
        .catch((err) => {
          console.error(err);
          this.$root.vtoast.show({
            message: "Erro ao carregar servicos.",
            color: "error",
            icon: "mdi-close",
          });
        }).finally(()=>{
          this.loading = false
        });
    },

    initialize() {
      this.getServicos()
    },
  },
};
</script>
