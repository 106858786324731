import Vue from "vue"
import {format} from "date-fns";

import STATUS_SERVICOS from "@/constants/statusServico"
import formatStatus from "@/helpers/formatStatus"
import TIPO_SERVICO from "@/constants/tipoServico"

const formatStatusServico  = formatStatus(STATUS_SERVICOS)
const formatTiposServico = formatStatus(TIPO_SERVICO)


function descricaoTrechos(trechos) {
    const array = (trechos || []).map((trecho) => {
        const data = new Date(trecho.data);
        const formattedData = format(data, "dd/MM/yyyy");
        return ` - Viagem de ${trecho.origem.name} à ${trecho.destino.name} em ${formattedData}`;
    })
    return array.join("\n")
}

function descricaoCotacao(cotacao) {
    if (cotacao.cotacao_hotel) {
        return `${cotacao.cotacao_hotel.nome_hotel} - ${cotacao.cotacao_hotel.local_hotel.nome}`;
    }
    if (cotacao.cotacao_voo) {
        const subcotacoes = cotacao.cotacao_voo.subcotacoes;
        return `${subcotacoes.length} bilhetes`;
    }
    if (cotacao.cotacao_carro) {
        return `${cotacao.cotacao_carro.empresa} - ${cotacao.cotacao_carro.local_retirada.cidade}`;
    }
    if (cotacao.cotacao_outro) {
        const nomeServico = cotacao.cotacao_outro.nome_servico;
        const local = cotacao.cotacao_outro.local.nome;
        return `${nomeServico} - ${local} - ${cotacao.cotacao_outro.custo_total.valor}`;
    }
    return "Cotação desconhecida";
}

function descricaoEmissao(emissao) {
    switch (emissao) {
        case "emissao_voo_pagante":
            return "Vôo Pagante"
        case "emissao_voo_milha":
            return "Vôo Milha"
        case "emissao_voo_wallet":
            return "Vôo Wallet"
        case "emissao_hotel":
            return "Hotel"
        case "emissao_carro":
            return "Carro"
        case "emissao_outro":
            return "Outro Serviço"
    }
    return ""
}

Vue.filter('descricaoEmissao', descricaoEmissao)
Vue.filter('descricaoCotacao', descricaoCotacao)
Vue.filter('formatStatusServico', formatStatusServico)
Vue.filter('formatTiposServico', formatTiposServico)
