<template>
  <v-container class="ma-4" fluid>
    <v-row>
      <v-col>
        <div
            class="text-h5 transition-swing"
        >
          Pagamentos
        </div>
        <v-breadcrumbs :items="breadcumb_items"></v-breadcrumbs>
      </v-col>

    </v-row>
    <v-row>
      <v-col cols="12">
        <v-container fluid>
          <v-row>
            <v-col cols="12">
              <v-card rounded="10"
                      :loading="loadingTable"
                      raised elevation="10">
                      <v-card-title>

                          <v-col cols="12" md="4">
                            <v-text-field
                                dense
                                outlined
                                clearable
                                clear-icon="mdi-close"
                                append-icon="mdi-magnify"
                                label="Pesquisa"
                                v-model="search"
                            ></v-text-field>
                          </v-col>
                      </v-card-title>
                <v-card-text>
                  <v-data-table
                      @click:row="rowClick"
                      :search="search"
                      sort-by='name'
                  >
                      <template v-slot:no-data>
                          Nenhum pagamento encontrado.
                      </template>
                  </v-data-table>
                </v-card-text>
              </v-card>
            </v-col>
          </v-row>
        </v-container>
      </v-col>
    </v-row>
  </v-container>
</template>
<script>

import AppApi from '@/api/api.js';

export default {
  data() {
    return {
      loadingSaving: false,
      loadingTable: true,
      breadcumb_items: [
        {
          text: 'Home',
          to: "home"
        },
        {
          text: 'Pagamentos',
          to: "pagamentos"
        }
      ],
      filter: {
        status: ""
      },
      search: '',
      headers: [
        // venda / emissao / status / servico / valor / cliente / pagamento / data
        { text: 'Venda', value: 'venda', sortable: true },
        { text: 'Emissão', value: 'emissao', sortable: true },
        { text: 'Status', value: 'status', sortable: true },
        { text: 'Serviço', value: 'servico', sortable: true },
        { text: 'Valor', value: 'valor', sortable: true },
        { text: 'Cliente', value: 'cliente', sortable: true },
        { text: 'Pagamento', value: 'pagamento', sortable: true },
        { text: 'Data', value: 'data', sortable: true },
        

      ],
      pagamentos: []
    }
  },

  created() {
    // this.initialize();
  },

  methods: {
    rowClick(value) {
    },

    initialize() {
        AppApi.getPagamentos().then(
          data => {
              this.pagamentos = data.data
              this.loadingTable = false;
          }
        );
    }
  }
};
</script>
