<template >
    <div class="table-voos">
        <div v-for="(perna, idxPerna) in trecho.pernas" class="linha-voo">
            <div class="trechos">
                <img 
                    class="pdf-image" 
                    v-if="perna.cia_aerea && Object.keys(cias).includes(perna.cia_aerea.nome_resumido)" 
                    :src="cias[perna.cia_aerea.nome_resumido]" 
                    alt="CIA"
                >
                <div>
                    <span>{{perna.origem.aeroporto_iata || "IATA"}}</span>
                    <span>{{formatData(perna.data_hora_partida) }}</span>
                </div>
                <span>>>></span>
                <div>
                    <span>{{perna.destino.aeroporto_iata || "IATA"}}</span>
                    <span>{{formatData(perna.data_hora_chegada)}}</span>
                </div>
            </div>
            <div>
                <p>{{formatClasses(perna)}} <span v-if="formatClasses(perna)" class="spacer"> - </span> {{numPassageiros(perna)}} passageiro(s)</p>
                <p v-if="perna.tipo_perna">{{tipos_perna.find(e => e.key === perna.tipo_perna).value}}</p>
                <p v-if="perna.bagagens">{{perna.bagagens}} bagagem(s)</p>
            </div>
        </div>
    </div>
</template>
<script>
import cias from "@/assets/cias";
import {convertHora} from "@/helpers/dates";
import {numPassageiros} from "@/helpers/pernas";
import TIPOS_PERNA from "@/constants/pernas";
import CLASSES from "@/constants/classes";

export default {
    name: "tableVoo",
    props:{
        trecho: {
            type: Object,
            default: () => {},
            required: true
        },
    },
    data(){
        return {
            cias: cias,
            tipos_perna: TIPOS_PERNA
        }
    },
    methods: {
        partida (date){
            return convertHora(date)
        },
        checkAdultos(trecho){
            return trecho.pernas
            .map(perna => perna.adultos)
            .some(e => e > 0)
        },
        checkCrianca(trecho){
            return trecho.pernas
            .map(perna => perna.criancas)
            .some(e => e > 0)
        },
        numPassageiros(perna){
            return numPassageiros(perna)
        },
        formatClasses(perna){
            return CLASSES
            .filter(classe => perna[classe.key])
            .map(classe => classe.value)
            .join(", ")
        },
        formatData(data) {
            if (!data) return ""
            const date = new Date(data);
            return(`${this.partida(data)}`)
        }
    }
    
}
</script>
<style >
.linha-voo .trechos {
    display: flex; 
    flex-direction: row; 
    align-items: center;
    justify-content: flex-start;
    margin: 1.2rem;
}
.linha-voo .trechos img {
    height: 2rem;
    margin: 0.5rem;
}
.trechos span {
    margin: 0.2rem 1.5rem;
}
.trechos > span:nth-child(1){
    font-size: 1.2rem;
    font-style: italic;
}
.trechos div > span:nth-child(1){
    font-size: 1.2rem;
}
.trechos div > span:nth-child(2){
    color: #9c9c9c;
}
.trechos > div {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-right: 1rem;
}
.linha-voo hr {
    border-top: 1px solid black;
    width: 50%
}
.linha-voo .spacer {
    padding: 0 1rem;
}
.linha-voo > div:nth-child(2){
    margin-left: 2rem;
}
</style>