<template>
  <div>
    <v-menu top v-model="showMenu">
      <template v-slot:activator="{ on, attrs }">
        <v-btn color="primary" v-bind="attrs" v-on="on">
          <v-icon>mdi-plus</v-icon>
          Nova Solicitação
        </v-btn>
      </template>

      <v-list>
        <v-list-item link dense @click.stop="openDialog('orcamento_voo')">
          <v-list-item-title> Vôo </v-list-item-title>
        </v-list-item>
        <v-list-item link dense @click="openDialog('orcamento_hotel')">
          <v-list-item-title> Hotel </v-list-item-title>
        </v-list-item>
        <v-list-item link dense @click="openDialog('orcamento_carro')">
          <v-list-item-title> Aluguel de Carro </v-list-item-title>
        </v-list-item>
        <v-list-item link dense @click="openDialog('orcamento_outro')">
          <v-list-item-title> Outro </v-list-item-title>
        </v-list-item>
      </v-list>
    </v-menu>
    <v-dialog eager v-model="showDialog">
      <v-card>
        <v-card-title class="text-h5">Nova solicitação</v-card-title>
        <v-card-text>Todos os campos são obrigatórios.</v-card-text>
        <v-card-text>
          <criar-orcamentos
            @save="criarOrcamentos"
            @cancel="showDialog = false"
            v-model="formOrcamentos"
            ref="dialogCriarOrcamentos"
          ></criar-orcamentos>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn @click="cancel">Cancelar </v-btn>
          <v-btn color="primary" :loading="loading" @click="save">
            Solicitar
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import VFieldValue from "@/components/widgets/VFieldValue";
import VendaDados from "@/components/venda/VendaInfoGeralView";
import VendaEditForm from "@/components/venda/VendaEditForm";
import CriarOrcamentos from "@/components/Orcamento/CriarOrcamentos";

export default {
  components: { CriarOrcamentos, VendaEditForm, VendaDados, VFieldValue },
  data() {
    return {
      showDialog: false,
      loading: false,
      showMenu: false,
      formOrcamentos: [],
    };
  },
  methods: {
    openDialog(tipoServico) {
      this.showDialog = true;
      this.$refs.dialogCriarOrcamentos.clear();
      this.$refs.dialogCriarOrcamentos.adicionarNovaSolicitacao(tipoServico);
    },
    async criarOrcamentos(orcamentos) {
      await this.$store.dispatch("venda/criarOrcamentos", orcamentos);
      setTimeout(() => {
        this.showDialog = false;
        this.loading = false;
        this.$root.vtoast.show({
          message: "Demanda de orçamento criada com sucesso",
        });
      }, 1000);
    },
    close() {
      this.showDialog = false;
      this.loading = false;
    },
    cancel() {
      this.close();
    },
    save() {
      this.loading = true;
      this.$emit("save", this.formOrcamentos);
    },
  },
};
</script>
