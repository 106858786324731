import Vue from "vue"

const formatCpf = (value) => {
    return value.replace(
        /(\d{3})(\d{3})(\d{3})(\d{2})/,
        "$1.$2.$3-$4"
    )
}


Vue.filter('formatCpf', formatCpf)

