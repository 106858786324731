<template>
  <v-container>
    <v-form id="busca-form">
      <v-card class="mx-auto">
        <v-card-title class="text-h6 d-flex">
          {{ label }}
          <v-icon medium @click="dialog = true" color="primary" class="ml-1">
            mdi-magnify
          </v-icon>
          <v-icon class="ml-auto" medium v-if="flag" :color="flag">
            mdi-flag
          </v-icon>
        </v-card-title>
        <v-simple-table dense>
          <thead>
            <tr>
              <th class="text-left" v-for="item in selectedInformation">
                {{ legenda[item] }}
              </th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td v-for="item in selectedInformation">
                {{ information[item] }}
              </td>
            </tr>
          </tbody>
        </v-simple-table>
        <v-dialog v-model="dialog" width="800">
          <v-card>
            <v-card-title class="text-h5 grey lighten-2">
              {{ label }}
            </v-card-title>

            <v-divider></v-divider>
            <VDevValue
              :devMode="true"
              :hideButton="true"
              label=""
              :json="information"
            ></VDevValue>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn text @click="dialog = false"> Voltar </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-card>
    </v-form>
  </v-container>
</template>

<script>
import VDevValue from "@/components/widgets/VDevValue.vue";

export default {
  props: {
    information: {
      type: [Object, Array],
      required: false,
      default: {},
    },
    selectedInformation: {
      type: [Object, Array],
      required: false,
      default: {},
    },
    label: {
      type: String,
      required: false,
      default: "",
    },
    legenda: {
      type: [Object, Array],
      required: false,
      default: {},
    },
    flag: {
      type: String,
      required: false,
      defaut: "",
    },
  },

  components: { VDevValue },

  data() {
    return {
      dialog: false,
    };
  },

};
</script>
