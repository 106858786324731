<template>
    <v-container>
        <v-form id="info-form" validate="true">
        <v-row>
            <v-col>
                <small>Deve conter CNPJ, pelo menos um nome e um meio de contato.</small>
            </v-col>
        </v-row>
        <v-row>
            <v-col cols="12" sm="12">
                <v-text-field label="Nome / Nome Fantasia" name="nome" prepend-inner-icon="mdi-office-building" outlined required />
            </v-col>

            <v-col cols="12" sm="12">
                <v-text-field label="Razão Social" name="razao_social"  prepend-inner-icon="mdi-office-building" outlined required />
            </v-col>
        </v-row>

        <v-row>
            <v-col cols="12" sm="6">
                <v-text-field
                    label="CNPJ"
                    name="cnpj"
                    v-model="models.cnpj"
                    v-mask="masks.cnpj"
                    outlined
                    prepend-inner-icon="mdi-domain"
                    />
            </v-col>
            <v-col cols="12" sm="6">
                    <SearchContato v-model="models.indicacao" label="Indicação" />
                    <input type="hidden" name="indicacao" :value="models.indicacao?.id">
            </v-col>
        </v-row>

        <v-row>
            <v-col cols="12" sm="6">              
                <TelefoneField
                    v-model="models.tel"
                    :outlined="true"
                />
            </v-col>
            <v-col cols="12" sm="6">
                <v-text-field
                    label="Email"
                    name="email"
                    v-model="models.email"
                    prepend-inner-icon="mdi-at"
                    outlined
                    :rules="emailRules"
                    />
            </v-col>
        </v-row>
        <v-row>
            <v-col cols="12" sm="12">
                <v-textarea label="Observações"
                    outlined 
                    prepend-inner-icon="mdi-magnify" 
                    name="observacoes" 
                    :rules="maxCharactersRules"
                    :value="models.observacoes"/>
            </v-col>
        </v-row>

        </v-form>
    </v-container>
</template>

<script>
import TelefoneField from "@/components/widgets/TelefoneField.vue";
import SearchContato from "@/components/widgets/SearchContato.vue";

export default {
    components: {TelefoneField, SearchContato },
    data () {
        return {
            masks: {
                "cnpj": "##.###.###/####-##",
                "cpf": "###.###.###-##",
                "nasc": "##/##/####"
            },

            cliente: null,

            models: {
                email: null,
                cnpj: null,
                cpf: null,
                tel: null,
                nasc: null,
                indicacao: null
            },

            emailRules: [
                (v) => /.+@.+..+/.test(v) || "E-mail deve ser válido (exemplo@email.com)",
            ],
            maxCharactersRules: [
                (v) =>  (v && v.length<500) || "Observações devem ter menos de 500 caracteres",
            ],

        }
    },

}
</script>