<template >
  <div>
    <venda-servicos
    />
  </div>
</template>
<script>
import VendaServicos from "./validacao/VendaServicos"
export default {
  components: {VendaServicos}
}
</script>