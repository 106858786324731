<template>
    <v-container v-if="empresa">
        <v-form id="info-form">
        <v-row>
            <v-col cols="12" sm="12">
                <CopyTextField label="Nome" id="nome" :value="empresa.nome" icon="mdi-domain"/>
            </v-col>
        </v-row>
        <v-row>
            <v-col cols="12" sm="12">
                <CopyTextField label="Razão Social" name="razao_social" id="razao_social" :value="empresa.razao_social" icon="mdi-account-outline"/>
            </v-col>
        </v-row>
        <v-row>
            <v-col cols="12" sm="6">
                <CopyTextField label="CNPJ" id="cnpj" mask="##.###.###/####-##" :value="empresa.cnpj" icon="mdi-card-account-details-outline" />
            </v-col>
            <v-col cols="12" sm="6">
                    <SearchContato v-model="indicacao" label="Indicação" />
                    <input type="hidden" name="indicacao" :value="indicacao?.id">
            </v-col>
        </v-row>

        <v-row>
            <v-col cols="12" sm="6">
                <CopyTextField label="E-mail" id="email" :value="empresa.email" icon="mdi-at" />
            </v-col>
            <v-col cols="12" sm="6">
                <TelefoneField
                    :outlined="true"
                    :telefone="empresa.telefone.numero"
                    :pais="empresa.telefone.pais"
                />
            </v-col>
        </v-row>
        <v-row>
            <v-col cols="12" sm="12">
                <v-textarea 
                label="Observações" 
                outlined 
                prepend-inner-icon="mdi-magnify" 
                name="observacoes" 
                :value="empresa.observacoes"
                :rules="[v => v?.length<500 || 'Observações devem ter menos de 500 caracteres']"
                />
            </v-col>
        </v-row>
        </v-form>
    </v-container>
</template>

<script>
import CopyTextField from "@/components/widgets/CopyTextField.vue";
import SearchContato from "@/components/widgets/SearchContato.vue";
import TelefoneField from "@/components/widgets/TelefoneField.vue";

export default {
    props: {
        empresa: {
            type: Object,
            required: true
        }
    },

    components: {
        CopyTextField,
        SearchContato,
        TelefoneField
    },

    data() {
        return {
            indicacao: 0
        }
    },

    created () {
        this.indicacao = this.empresa?.indicacao
    }
}
</script>