import { get, post } from '../modules'

export default {
    getVendaMessages(vendaId) {
        return get(`/api/vendas/${vendaId}/chat_messages/`)
    },

    sendChatMessage(vendaId, data) {
        return post(`/api/vendas/${vendaId}/chat_messages/`, data)
    },    
}
