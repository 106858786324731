<template>
  <v-card>
    <v-card-title>
      <span class="text-h5">{{ formTitle }}</span>
    </v-card-title>
    <!-- edited item -->
    <v-card-text>
      <v-container>
        <v-row v-if="!deactivateServico && !value.servico">
          <v-col>
            <v-select
              label="Servicos"
              :items="excluirServicosCancelados"
              :loading="loadingServicos"
              outlined
              clearable
              dense
              multiple
              chips
              :item-text="serviceDescription"
              item-value="id"
              v-model="value.servicos"
            ></v-select>
          </v-col>
        </v-row>
        <v-row v-if="value.servico">
          Serviço #{{ value.servico.id }} - Valor Aberto:
          {{ valorAbertoServico(value.servico) | dinheiroReal }} - Valor Total:
          {{ valorTotalServico(value.servico) | dinheiroReal }}</v-row
        >
        <v-row>
          Preço total das cotações: {{ valor_total | dinheiroReal }}</v-row
        >
        <v-row>
          <v-col>
            <v-text-field
              v-if="precoMilheiro"
              label="Valor de compra da milha"
              type="number"
              dense
              outlined
              v-model="value.valor_compra_milha"
            />
            <v-moeda-field
              v-else
              outlined
              dense
              label="Valor pagamento"
              v-model="value.valor_pagamento"
            ></v-moeda-field>
          </v-col>
        </v-row>
        <div v-if="value.servicos && value.servicos.length > 1">
          <v-row v-for="servico in value.servicos" :key="servico">
            <v-col v-if="servico">
              Valor em aberto
              {{ valor_aberto[servicosCotacoes[servico]] | dinheiroReal }}
              <v-moeda-field
                outlined
                dense
                :label="`Cotacao #${servicosCotacoes[servico]}`"
                v-model="value.pagamento_servicos[servicosCotacoes[servico]]"
              ></v-moeda-field>
            </v-col>
          </v-row>
          <v-row> Valor restante: {{ valor_restante | dinheiroReal }} </v-row>
        </div>
        <v-row v-if="!deactivateCheckItem">
          <v-col>
            <v-checkbox
              label="A Skyler é a resposável financeira desse pagamento?"
              v-model="value.skyler_responsavel"
            />
          </v-col>
        </v-row>
        <v-row v-if="!value.skyler_responsavel">
          <v-col>
            <search-contato
              v-if="!paraEmpresa"
              v-model="value.responsavel_financeiro"
              label="Responsável Financeiro"
            />
            <v-select
              v-if="paraEmpresa"
              label="Empresa"
              :items="empresas"
              item-text="nome"
              item-value="id"
              v-model="value.responsavel_financeiro_empresa"
              prepend-inner-icon="mdi-domain"
              outlined
            />
          </v-col>

          <div class="pt-5">
            <v-btn color="primary" @click="clickEmpresa()">
              {{ (descricaoBotao) }}</v-btn
            >
          </div>
        </v-row>
        <v-row>
          <v-col>
            <v-select
              label="Status"
              :items="status"
              outlined
              clearable
              dense
              item-text="value"
              item-value="key"
              v-model="value.status"
              :disabled="checkUserPermissao"
            />
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <v-select
              label="Meio de Pagamento"
              :items="meiosDePagamento"
              outlined
              clearable
              dense
              item-text="value"
              item-value="key"
              prepend-inner-icon="mdi-currency-usd"
              v-model="value.meio_pagamento"
            ></v-select>
          </v-col>
          <v-col>
            <v-text-field
              v-if="pagamentoCartao()"
              v-model="value.num_parcelas"
              dense
              outlined
              type="number"
              label="Número de Parcelas"
            ></v-text-field>
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <v-text-field
              v-if="['pix', 'pagarme'].includes(value.meio_pagamento)"
              v-model="value.pagarme_link"
              dense
              outlined
              label="Link pagar.me"
            ></v-text-field>
          </v-col>
          <v-col>
            <v-text-field
              v-if="['pix', 'pagarme'].includes(value.meio_pagamento)"
              v-model="value.pagarme_id"
              dense
              outlined
              disabled
              label="ID pagar.me"
            ></v-text-field>
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <v-text-field
              v-model="value.taxa_meio_pagamento"
              dense
              outlined
              prepend-inner-icon="mdi-currency-usd"
              label="Taxa associada ao Meio de Pagamento">
            </v-text-field>
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <FormDatePicker
              v-model="value.data_pagamento"
              outlined
              :dense="false"
              label="Data de Pagamento"
              name="data_pagamento"
            />
          </v-col>   
        </v-row>
        <v-row>
          <v-file-input
            label="Comprovante de Pagamento"
            name="pagamento"
            v-model="value.documento_comprovante"
            chips
          />
        </v-row>
        <v-row>
          <v-col>
            <v-textarea
              v-model="value.descricao"
              prepend-inner-icon="mdi-pencil"
              outlined
              rows="3"
              filled
              label="Descrição"
              :rules="[
                (v) =>
                  v?.length < 100 ||
                  'Observações devem ter menos de 100 caracteres',
              ]"
            ></v-textarea>
          </v-col>
        </v-row>
      </v-container>
    </v-card-text>
    <v-card-actions>
      <v-spacer></v-spacer>
      <v-btn color="blue darken-1" text @click="close"> Cancelar </v-btn>
      <v-btn color="blue darken-1" text :disabled="checkUserPermissao" @click="save"> Salvar </v-btn>
    </v-card-actions>
  </v-card>
</template>
<script>
import FormDatePicker from "@/components/widgets/FormDatePicker.vue";
import VMoedaField from "@/components/widgets/VMoedaField";
import VDatetimeTextField from "@/components/widgets/VDatetimeTextField";
import SearchContato from "@/components/widgets/SearchContato";

import CotacaoMixin from "@/mixins/CotacaoMixin";
import MEIOS_DE_PAGAMENTO from "@/constants/meiosPagamento";
import STATUS from "@/constants/statusPagamento";
import checkPermissao from "@/helpers/checkUserPermissao.js";

import { converteValorFinanceiroBRL } from "@/helpers/financeiro";

export default {
  components: { VMoedaField, VDatetimeTextField, SearchContato, FormDatePicker },
  mixins: [CotacaoMixin],
  props: {
    value: {
      type: Object,
      default: () => {},
    },
    servicos: {
      type: Array,
      default: () => [],
    },
    formTitle: {
      type: String,
      default: "Novo Pagamento",
    },
    loadingServicos: {
      type: Boolean,
      default: false,
    },
    deactivateServico: {
      type: Boolean,
      default: false,
    },
    deactivateCheckItem: {
      type: Boolean,
      default: false,
    },
    precoMilheiro: {
      type: Boolean,
      default: false,
    },
    pagamentosVenda: {
      type: Array,
      default: () => [],
    },
  },

  data: () => ({
    meiosDePagamento: MEIOS_DE_PAGAMENTO,
    status: STATUS,
    documento_comprovante: null,
    paraEmpresa: false,
    empresas: [],
  }),

  computed: {
    excluirServicosCancelados(){
      return this.servicos.filter((servico) => {
        return !servico.cancelado_em && !servico.cancelado_por
      })
    },

    user() {
      return this.$store.getters['usuario/loggedUser'];
    },
    checkUserPermissao(){
      if(this.formTitle === 'Editar Pagamento') {
        const pertencente = this.user.pertencente;
        return checkPermissao(pertencente);
      } else if(this.formTitle === 'Novo Pagamento'){
        const pertencente = this.user.pertencente;
        return checkPermissao(pertencente);
      }
      return true;
    },
    valor_total() {
      if (!this.value.servicos) return 0;
      if (this.value.servicos.length === 0) return 0;
      if (this.servicos.length === 0) return 0;
      const sum = this.value.servicos.reduce(
        (total, servico) =>
          (total += this.valor_aberto[this.servicosCotacoes[servico]]),
        0
      );
      return sum.toFixed(2);
    },

    sum_valores() {
      return this.servicosSelecionados.reduce(
        (total, id) =>
          total +
          this.converteValorFinanceiroBRL(
            this.value.pagamento_servicos
              ? this.value.pagamento_servicos[id]
              : null
          ),
        0
      );
    },
    valor_restante() {
      if (this.sum_valores === 0) {
        if (!this.value.servicos) return 0;
        if (this.value.servicos.length > 1)
          return this.converteValorFinanceiroBRL(this.value.valor_pagamento);
        return 0;
      }
      return (
        this.converteValorFinanceiroBRL(this.value.valor_pagamento) -
        this.sum_valores
      );
    },
    valor_aberto() {
      return Object.fromEntries(
        this.servicos.map((servico) => [
          servico.cotacao.id,
          this.servicoValorTotal(servico) -
            this.pagamentosVenda
              .filter((pagamento) => pagamento.servico?.id === servico.id)
              .filter((pagamento) => pagamento.status === "concluido")
              .reduce(
                (sum, pagamento) =>
                  (sum += converteValorFinanceiroBRL(
                    pagamento.valor_pagamento
                  )),
                0
              )
              .toFixed(2),
        ])
      );
    },
    servicosSelecionados() {
      if (!this.value.servicos) return [];
      return this.value.servicos.map(
        (index) =>
          this.servicos?.find((servico) => servico.id === index)?.cotacao.id,
        0
      );
    },
    servicosCotacoes() {
      // Pega o id de um serviço, devolve o id de uma cotação
      return Object.fromEntries(
        this.servicos.map((servico) => [servico.id, servico.cotacao.id])
      );
    },
    descricaoBotao() {
      if (this.paraEmpresa) {
        return "Cliente";
      }
      return "Empresa";
    },
  },
  created() {
    if (this.value.servico) {
      this.value.servicos = [this.value.servico.id];
    }
  },
  async mounted() {
    if (this.value.responsavel_financeiro_empresa) {
      this.paraEmpresa = true;
    }
    if (this.value.servico) {
      this.value.servicos = [this.value.servico];
    }
    await this.$store.dispatch("global/fetchEmpresas");
    this.empresas = this.$store.state.global.empresas;
  },

  methods: {
    converteValorFinanceiroBRL(valor) {
      return converteValorFinanceiroBRL(valor);
    },
    pagamentoCartao() {
      if (!this.value.meio_pagamento) return false;
      return ["pagarme", "rede"].includes(this.value.meio_pagamento);
    },

    serviceDescription(servico) {
      const id = servico.id;
      if (!id) return "";
      const idCotacao = servico.cotacao.id;
      return `Servico # ${servico.id} - Valor Aberto: ${this.valor_aberto[
        idCotacao
      ].toFixed(2)} - Valor Total: ${this.valorTotalServico(servico).toFixed(
        2
      )}`;
    },

    clickEmpresa() {
      
      if (this.paraEmpresa) {
        this.value.responsavel_financeiro_empresa = null;
      } else {
        this.value.responsavel_financeiro = null;
      }
      this.paraEmpresa = !this.paraEmpresa;
    },
    valorAbertoServico(servico) {
      return (
        this.servicoValorTotal(servico) -
        this.pagamentosVenda
          .filter((pagamento) => pagamento.servico?.id === servico.id)
          .reduce(
            (sum, pagamento) =>
              (sum += converteValorFinanceiroBRL(pagamento.valor_pagamento)),
            0
          )
      ).toFixed(2);
    },
    valorTotalServico(servico) {
      return this.servicoValorTotal(servico);
    },
    valorTotalServico(servico) {
      return this.servicoValorTotal(servico)
    },

    checkSave() {
      if (this.value.valor_pagamento.valor === 0) {
        this.$root.vtoast.show({
          message: "Insira o valor do pagamento",
          icon: "mdi-close",
          color: "error",
        });
        return false;
      }
      if ((!this.value.servicos || this.value.servicos.length < 1) && !this.value.servico) {
        this.$root.vtoast.show({
          message: "É necessário selecionar um serviço.",
          icon: "mdi-close",
          color: "error",
        });
        return false;
      }
      if (Math.abs(this.valor_restante) > 0.1) {
        this.$root.vtoast.show({
          message: "Valor do pagamento diferente ao da soma dos pagamentos",
          icon: "mdi-close",
          color: "error",
        });
        return false;
      }

      if(this.value.responsavel_financeiro) {
        this.value.responsavel_financeiro = this.value.responsavel_financeiro.id
      }
      // Condicional de valor pagamento maior que valor aberto retirado por enqu
      // if (Array.isArray(this.value.servicos) && this.value.pagamento_servicos && this.value.servicos.some((servico) => converteValorFinanceiroBRL(this.value.pagamento_servicos[this.servicos.find(item => item.id == servico)?.cotacao?.id]) > this.valor_aberto[servico])) {
      //   this.$root.vtoast.show({
      //     message: 'Valor do pagamento maior que valor do serviço',
      //     icon: 'mdi-close',
      //     color: 'error'
      //   })
      //   return false
      // }
      return true;
    },

    save() {
      if(this.value.taxa_meio_pagamento){
        this.value.taxa_meio_pagamento = parseFloat(this.value.taxa_meio_pagamento.toString().replace(",", "."));
      }
      if (!this.checkSave()) return;
      this.$emit("save");
    },

    close() {
      this.$emit("close");
    },
  },
};
</script>