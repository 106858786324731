<template>
  <v-row no-gutters>
    <v-col cols="4" id="select-country">
      <v-combobox
        :items="phones"
        v-model="country"
        label="Telefone"
        item-text="name"
        width="30px"
        @change="updateCode"
        :outlined="outlined"
        :disabled="disabled"
      >
        <template v-slot:item="{ item }">
          <img
            :src="item.flag"
            width="20px"
            style="margin-right: 10px"
            outlined
          />
          {{ item.name }}
        </template>
        <template v-slot:selection="{ item }">
          <img :src="item.flag" width="20px" style="margin-right: 10px" />
          ({{ item.code }})
        </template>
      </v-combobox>

      <input type="hidden" name="telefone_pais" :value="this.country?.iso" />
    </v-col>
    <v-col id="input-phone-number">
      <v-text-field
        :outlined="outlined"
        name="telefone"
        v-maska="currentMask"
        @maska="rawPhone = $event.target.dataset.maskRawValue"
        placeholder="Telefone"
        v-model="phone"
        @input="updateValue"
        :disabled="disabled"
      />
    </v-col>
  </v-row>
</template>

<script>
// Lista dos países + máscara do número
import phoneCountries from "@/constants/phones";

export default {
  props: {
    disabled:{
      type: Boolean,
      default: false,
    },

    telefone: {
      type: String,
      default: null,
    },

    pais: {
      type: String, // ISO
      default: "BR",
    },
    
    outlined: {
      type: Boolean,
      default: false
    },

    value: { // Valor retornado no v-model
      type: null,
      default: null
    },

    returnRaw: { // Retornar o número sem máscara
      type: Boolean,
      default: false
    }
  },

  model: {
    prop: 'value',
    event: 'input'
  },

  watch: {
    value: {
      deep: true,
      handler: function (val) {
        this.phone = val?.numero
        this.country = this.getCountryByISO(val.pais)
      }
    }
  },

  data() {
    return {
      phones: phoneCountries,
      country: phoneCountries[0],
      code: phoneCountries[0].code,
      phone: "" || this.telefone || this.value,
      rawPhone: "",
    };
  },

  created() {
    this.convertPhone(this.value);
    this.autoSelectCountry();
    this.updateValue()
  },

  methods: {
    // Selecionar país automaticamente
    autoSelectCountry() {
      if(!this.pais) return
      const countries = phoneCountries;
      const country = countries.find(country => country.iso == this.pais);

      if (!country) return;
      this.country = country
      return country;
    },

    getCountryByISO(iso) {
      const country = this.phones.find(country => country.iso == iso);
      return country;
    },

    // Atualiza o país de acordo com o código
    updateCode() {
      this.code = this.country.code;
      this.detectCountry();
      this.updateValue();
    },

    // Atualizar o valor do v-model
    updateValue() {
      let value = {
        pais: this.country.iso,
        codigo: this.country.code,
        numero: this.phone
      }

      if(this.returnRaw)
        value = `${this.country.code} ${this.phone}`

      this.$emit('input', value);
    },

    // Detecta país pelo código
    detectCountry() {
      const countries = phoneCountries;
      const country = countries.find((country) => country.code === this.code);

      if(!country) return

      return (this.country = country);
    },
    
    convertPhone(phone) {
      if(phone && String(phone).startsWith('+')) {
        let code = phone.split(' ').shift();
        this.phone = phone.substr(code.length+1);
        this.code = code
        this.detectCountry()
      } else if(typeof(phone) == 'object') {
        this.phone = phone?.numero;
        this.detectCountry()
      }

      return
    }
  },

  computed: {
    currentMask() {
      return this.country?.mask;
    },
  },
};
</script>
