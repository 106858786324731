<template>
    <v-container fluid class="pa-0">
      <v-row class="pa-0">
        <v-col >
          <v-text-field
            hint="Ex: 21:00"
            @input="input"
            v-mask="'##:##'"
            :rules="regraHoraMinuto"
            :dense="dense"
            outlined
            label="Hora"
            v-model="form.horaMinuto"
          >
          </v-text-field>
        </v-col>
      </v-row>
    </v-container>
  </template>
  
  <script>
  export default {
    components: {  },
    props: {
      value: {
        type: [Date, String],
        required: false,
        default: null,
      },
      initialDataAgora: {
        type: Boolean,
        required: false,
        default: false,
      },
      dense: {
        type: Boolean,
        default: false,
      },
      label: {
        type: String,
      },
    },
    data() {
      return {
        regraHoraMinuto: [(v) => v.length === 5 || "HH:MM"],
        form: {
          horaMinuto: "21:00",
        },
      };
    },
    created() {
      if (!this.value && !this.initialDataAgora) {
        return;
      }
      let datetime = this.value ? this.value : new Date();
      if (typeof datetime === "string" || datetime instanceof String) {
        datetime = new Date(datetime);
      }
      if (datetime instanceof Date) {
        this.form.data = this.$options.filters.convertDataBR(datetime);
        this.form.horaMinuto = this.$options.filters.dataHoraMinuto(datetime);
      }
    },
    computed: {
      hora() {
        const time = this.form.horaMinuto;
        if (time.length !== 5) return null;
  
        let hour, minute;
          [hour, minute] = time.split(":");
          if (!hour || !minute) return null;
        return `${hour}:${minute}`;
      },
    },
    methods: {
      input(event) {
        const datetime = this.hora;
        this.$emit("input", datetime);
      },
    },
  };
  </script>