<template>
  <v-container fluid v-if="carrinho">
    <v-row dense>
      <v-col>
        <v-card>
          <v-data-table :items="carrinho" :headers="headers">
            <template v-slot:top>
              <v-toolbar flat>
                <v-toolbar-title>Cotações em Carrinho</v-toolbar-title>
                <v-divider class="mx-4" inset vertical></v-divider>
                <v-spacer></v-spacer>
              </v-toolbar>
            </template>
            <template v-slot:item.orcamento="{ item }">
              {{ item.orcamento.id }}
            </template>
            <template v-slot:item.descricao="{ item }">
              {{ item | descricaoCotacao }}
            </template>
            <template v-slot:item.valor_total="{ item }">
              {{ cotacaoValorTotal(item) | dinheiroReal }}
            </template>
            <template v-slot:item.valor_cliente="{ item }">
              {{ cotacaoValorTotalJuros(item) | dinheiroReal }}
            </template>
            <template v-slot:item.actions="{ item }">
              <v-icon color="red" class="mr-2" @click="removeItem(item)">
                mdi-close
              </v-icon>
            </template>
          </v-data-table>
        </v-card>
      </v-col>
    </v-row>
    <v-row>
      <v-spacer></v-spacer>
      <v-col cols="auto">
        <v-row>
          <v-col>
            <v-field-value label="Valor total">{{
              total | dinheiroReal
            }}</v-field-value>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
import VFieldValue from "@/components/widgets/VFieldValue";
import api from "@/api/api";
import CotacaoMixin from "@/mixins/CotacaoMixin";

export default {
  components: { VFieldValue },
  mixins: [CotacaoMixin],
  props: {
    carrinho: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      headers: [
        { text: "ID Cotação", value: "id" },
        { text: "ID Orçamento", value: "orcamento" },
        { text: "Descrição", value: "descricao" },
        { text: "Valor à vista", value: "valor_total" },
        { text: "Ações", value: "actions" },
      ],
    };
  },

  computed: {
    total() {
      const totalJuros = this.carrinho.reduce((cum, cur) => {
        const valorComJuros = this.cotacaoValorTotalJuros(cur);
        return cum + parseFloat(valorComJuros);
      }, 0);
      return totalJuros;
    },
  },

  methods: {
    removeItem(item) {
      const vendaId = this.$route.params.id;
      api
        .removeFromCarrinho(vendaId, item)
        .then((res) => {
          this.$root.vtoast.show({
            message: "Cotação removida do carrinho!",
            color: "success",
            icon: "mdi-check",
          });
          this.$emit("refresh");
        })
        .catch((error) => {
          console.error(error);
          this.$root.vtoast.show({
            message: "Cotação não pode ser removida do carrinho.",
            color: "error",
            icon: "mdi-close",
          });
        });
    },
  },
};
</script>
