<template >
  <table-servicos :servicos="servicos" :showFiltro="false" :emVenda="true" />
</template>
<script>
import TableServicos from "@/components/Servicos/TableServicos";

export default {
  components: {
    TableServicos,
  },
  computed: {
    servicos() {
      return this.$store.state.venda.servicos;
    },
  },
  async mounted() {
    await this.initialize();
  },
  methods: {
    async initialize() {
      await this.$store.dispatch("venda/fetchServicos");
    },
  },
};
</script>