import { get } from '../modules'

export default {
    getLocais() {
        return get("/api/locais/");
    },

    getCidades() {
        return get("/api/locais/cidades/");
    },

    getAeroportos() {
        return get("/api/locais/aeroportos/");
    }
}
