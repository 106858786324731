<template>
    <v-tabs>
        <v-tab> <v-icon left> mdi-domain </v-icon> Geral </v-tab>
        <v-tab> <v-icon left> mdi-account-multiple </v-icon> Relações </v-tab>
        <v-tab> <v-icon left> mdi-shopping </v-icon> Vendas </v-tab>
        <v-tab> <v-icon left> mdi-account-multiple </v-icon> Busca <v-icon right :color="flagColor" > mdi-flag </v-icon> </v-tab>

        <v-tab-item> <Geral :empresa="empresa" /> </v-tab-item>
        <v-tab-item> <Relacoes /> </v-tab-item>
        <v-tab-item> <Vendas :empresa="empresa" />      </v-tab-item>
        <v-tab-item> <BuscaEmpresa :empresa="empresa" /> </v-tab-item>

    </v-tabs>
</template>

<script>
import Geral from './Tabs/Geral.vue'
import Relacoes from './Tabs/Relacoes.vue'
import BuscaEmpresa from './Tabs/BuscaEmpresa.vue'
import Vendas from './Tabs/Vendas.vue'

export default {
    props: {
        empresa: {
            type: Object,
            default: () => {},
            required: false
        }
    },
    components: {
        Relacoes,
        Geral,
        BuscaEmpresa,
        Vendas,
    },

    data() {
        return {
            relacoes: [],
        }
    },

    computed: {
        flagColor() {
            const data_busca = this.empresa?.data_busca;
            if(!data_busca) return "gray"
            return data_busca.general_flag
        }
    }
}
</script>
