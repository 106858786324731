import ExportarView from "@/views/ExportarView";
import PainelAutorizacaoView from "@/views/PainelAutorizacaoView";
import PagamentoAutorizacaoView from "@/views/PagamentoAutorizacaoView";
import MilhasPropriasView from "@/views/MilhasPropriasView";
import PegaVendasView from "@/views/PegaVendasView";
import NotificacoesView from "@/views/NotificacoesView";
import PagamentosAbertosView from "@/views/PagamentosAbertosView"
import OrdemPagamentoView from "@/views/OrdemPagamentoView"
import OrdemPagamentoRouter from "./OrdemPagamentoRouter";
import ComissaoView from "@/views/ComissaoView"


export default [
  {
    path: "/exportar",
    name: "exportar",
    component: ExportarView,
  },
  {
    path: "/painel-autorizacao",
    name: "painel-autorizacao",
    component: PainelAutorizacaoView,
  },
  {
    path: "/painel-pagamentos",
    name: "painel-pagamentos",
    component: PagamentoAutorizacaoView,
  },
  {
    path: "/milhas-proprias",
    name: "milhas-proprias",
    component: MilhasPropriasView,
  },
  {
    path: "/pega-vendas",
    name: "pega-vendas",
    component: PegaVendasView,
  },
  {
    path: '/notificacoes',
    name: 'notificacoes',
    component: NotificacoesView 
  },
  {
    path: '/pagamentos-abertos',
    name: 'pagamentos-abertos',
    component: PagamentosAbertosView
  },
  {
    path: "/ordem-pagamento/",
    name: 'ordem-pagamento',
    component: OrdemPagamentoView,
    props: true,
    children: OrdemPagamentoRouter
  },
  {
    path: '/comissao',
    name: 'comissao',
    component: ComissaoView
  },
];
