import api from "@/api/api.js"

export const tablesGetters = new Map([
    ['contatos', api.getContatos],
    ['vendas', api.getVendas],
    ['orcamentos', api.getOrcamentos],
    ['cotacoes', api.getCotacoes],
    ['pagamentos', api.getPagamentos],
    ['empresas', api.getEmpresas],
    ['emissoes', api.getEmissoes],
    ['fidelidades', api.getFidelidades],
    ['programas', api.getProgramas],
    ['servicos', api.getServicos],
    ['moedas', api.getMoedas],
    ['milhas', api.getTabelaMilhas],
    ['milhas validadas', api.getCotacoesMilhasValidadas],
    ['racs', api.getRAC]
])