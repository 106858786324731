<template>
  <v-container class="ma-4 pr-8" fluid>
    <v-row>
      <v-col>
        <div class="text-h5 transition-swing">Pega Vendas</div>
        <v-breadcrumbs :items="breadcumb_items"></v-breadcrumbs>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12">
        <v-container fluid>
          <v-row>
            <v-col cols="12">
              <v-card
                rounded="10"
                :loading="loading"
                raised
                elevation="10"
              >
                <v-card-text>
                  <v-data-table
                    dense
                    :headers="headers"
                    :items="vendas"
                    sort-by="id"
                    sort-desc
                    :hide-default-footer="true"
                    :items-per-page="15"
                  >
                    >
                    <template v-slot:item.clienteOuEmpresa="{ item }">
                      {{
                        item.cliente
                          ? `${item.cliente.nome} ${item.cliente.sobrenome}`
                          : ""
                      }}
                      {{
                        item.cliente_empresa
                          ? `${item.cliente_empresa.nome}`
                          : ""
                      }}
                    </template>
                    <template v-slot:item.status="{ item }">
                      <div style="width: 60%;">
                        <status-venda :status="item.status" />
                      </div>
                    </template>
                    <template v-slot:item.last_edited_by="{ item, value }">
                      {{ item.last_edited_by | nomeSobrenome }}
                    </template>
                    <template v-slot:no-data>
                      Nenhuma venda encontrada
                    </template>
                    <template v-slot:item.actions="{ item }">
                      <v-icon class="mx-2" color="green" @click="() => chooseConsultor(item)"> mdi-account-plus</v-icon>
                      <v-icon class="mx-2" color="blue" @click="() => rowClick(item)"> mdi-open-in-new</v-icon>
                    </template>
                  </v-data-table>
                  <v-pagination
                    v-model="currentPage"
                    :length="numPages"
                    :total-visible="7"
                  ></v-pagination>
                </v-card-text>
              </v-card>
            </v-col>
          </v-row>
        </v-container>
      </v-col>
    </v-row>
    <v-dialog v-model="dialogConsultor" max-width="500px">
      <v-card>
        <v-card-title class="text-h5">Escolha um consultor para essa venda</v-card-title>
        <v-card-text>
          <v-autocomplete
            :items="consultores"
            v-model="consultor"
            outlined
            :item-text="item=>`${item.nome} ${item.sobrenome}`"
            :item-value="item => item.id"
            label="Consultor"
          >
          </v-autocomplete>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn text @click="() => cancelarConsultor()">Cancelar</v-btn>
          <v-btn text @click="() => confirmarConsultor()">Confirmar</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import api from "@/api";
import STATUS_VENDA from "@/constants/statusVenda";
import StatusVenda from "@/components/widgets/StatusVenda";

export default {
  components: {
    StatusVenda
  },

  data: () => ({
    statusVenda: STATUS_VENDA,
    breadcumb_items: [
      {
        text: "Home",
        to: "home",
      },
      {
        text: "Pega Vendas",
        to: "pega-vendas",
      },
    ],
    loading: false,
    dialogConsultor: false,
    vendas: [],
    currentPage: 1,
    numPages: 1,
    headers: [
      {
        text: "ID",
        align: "start",
        sortable: true,
        value: "id",
      },
      { text: "Status", value: "status" },
      { text: "Cliente", value: "clienteOuEmpresa" },
      { text: "Última alteração", value: "last_edited_by" },
      { text: "Ações", value: "actions" },
    ],
    consultor: null,
    vendaId: null,
  }),

  mounted() {
    this.initialize()
  },
  
  computed: {
    usuarios() {
      return this.$store.state.usuario.usuarios;
    },
    consultores() {
      return this.usuarios.filter(user => !user.cliente)
    },
  },

  methods: {
    initialize() {
      this.getVendas();
      this.$store.dispatch("usuario/fetchUsuarios");
    },
    getVendas(){
      this.loading = true;
      api.getVendasDetalhado(this.currentPage, [["sem_consultor", true]])
      .then(res => {
        this.vendas = res.data.vendas;
        this.numPages = res.data.pages;
      })
      .catch(err => {
        this.$root.vtoast.show({
            message: 'Erro ao recuperar vendas.',
            color: 'error',
            icon: 'mdi-close',
        });
        console.error(err);
      })
      .finally(() => this.loading = false)
    },

    rowClick(e) {
      this.$router.push({ name: "venda-info", params: { id: e.id } });
    },
    chooseConsultor(item){
      this.vendaId = item.id;
      this.dialogConsultor = true;
    },
    cancelarConsultor(item){
      this.dialogConsultor = false;
      this.consultor = null;
    },
    confirmarConsultor(item){
      this.dialogConsultor = false;
      api.updateVenda(this.vendaId, {
        consultor: this.consultor
      })
      .then(_ => {
        this.initialize();
      })
      .catch(err => {
        this.$root.vtoast.show({
            message: 'Erro ao atualizar consultor da venda.',
            color: 'error',
            icon: 'mdi-close',
        });
        console.error(err);
      })
    },
  },
};
</script>
