import UsuarioView from "@/views/UsuarioView";
import UsuarioRouter from "../ClientRoutes/UsuarioRouter";

export default [
    {
        path: "/minha-area",
        component: UsuarioView,
        props: true,
        children: UsuarioRouter
    },
]