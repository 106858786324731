

const taxaConversao = (sigla) => {
    const currentDate = new Date();
    const currentYear = currentDate.getFullYear();
    let IOF = Number(1.0538);
    if(currentYear === 2024){
        IOF = Number(1.0438)
    }else if(currentYear === 2025){
        IOF = Number(1.0338)
    }else if(currentYear === 2026){
        IOF = Number(1.0238)
    }else if(currentYear === 2027){
        IOF = Number(1.0138)
    } else if(currentYear > 2027){
        IOF = Number(1)
    }
    return sigla === "BRL" || !sigla ? 1 :
        ["EUR", "USD"].includes(sigla) ? 1.05 * IOF : 
        1.05 * IOF * 1.02
}

const converteValorFinanceiroBRL = (valorFinanceiro) => {
    if (!valorFinanceiro) return 0.0;
    const valor = parseFloat(valorFinanceiro.valor);
    if (!valorFinanceiro.moeda) return 0.0;
    if(! typeof valorFinanceiro.moeda === 'object'){
        // pegando lista de moedas e convertendo si o id for passado na moeda
        valorFinanceiro.moeda = this.$store.getters['global/moedas'].find(
            moeda => moeda.id === valorFinanceiro.moeda
        )
    }
    const cambio = parseFloat(valorFinanceiro.moeda.cambio || 1);
    const taxa = taxaConversao(valorFinanceiro.moeda.sigla)
    return valor * cambio * taxa;

}


const formatValorFinanceiro = (valorFinanceiro) => {
    if (!valorFinanceiro) return "R$ -"
    const valor = parseFloat(valorFinanceiro.valor);
    const valorStr = parseFloat(valor).toLocaleString("pt-BR", {minimumFractionDigits: 2, maximumFractionDigits: 2})
    if (!valorFinanceiro.moeda) return "R$ -";
    if(! typeof valorFinanceiro.moeda === 'object'){
        valorFinanceiro.moeda = this.$store.getters['global/moedas'].find(
            moeda => moeda.id === valorFinanceiro.moeda
        )
    }
    return `${valorFinanceiro.moeda.simbolo} ${valorStr}`
}

export {
    taxaConversao,
    converteValorFinanceiroBRL,
    formatValorFinanceiro,
}