<template>
  <v-card>
    <v-card-title>Bilhetes associados</v-card-title>
    <v-card-text>
      <v-skeleton-loader
        class="mx-auto"
        width="max"
        type="article"
        v-if="loading"
      ></v-skeleton-loader>
      <v-container
        v-else
        v-for="(subcotacao, index) in subcotacoes"
        :key="index"
        class="my-2"
      >
        <v-row>#{{ index + 1 }}</v-row>
        <v-row>ID do bilhete: {{ subcotacao.id }}</v-row>
        <v-row>
          <sub-cotacao-voo
            v-if="subcotacao && !loading"
            :subcotacao="subcotacao"
          ></sub-cotacao-voo>
        </v-row>
        <v-row><v-divider></v-divider></v-row>
      </v-container>
    </v-card-text>
    <v-card-actions>
      <v-btn color="blue darken-1" text @click="close"> Fechar </v-btn>
    </v-card-actions>
  </v-card>
</template>
<script>
import SubCotacaoVoo from "./widgets/SubCotacaoVoo";

export default {
  components: {
    SubCotacaoVoo,
  },

  props: {
    subcotacoes: {
      type: Array,
      default: () => [],
    },
    loading: {
      type: Boolean,
      default: false,
    },
  },

  methods: {
    close() {
      this.$emit("close");
    },
  },
};
</script>
