<template>
  <v-container fluid>
    <div v-if="emissoes && emissoes.length>0">
      <v-card class=" elevation-5">
        <v-card-title>Emissões</v-card-title>
      <v-row>
        <v-col cols="12" md="2">
          <v-select
            class="ml-4"
            clear-icon="mdi-close"
            clearable
            outlined
            dense
            label="Status"
            v-model="filter.status"
            :items="statusEmissao"
            item-text="value"
            item-value="key"
          >
          </v-select>
        </v-col>
      </v-row>
      <v-row v-if="!filter.status" v-for="emissao in emissoes" :key="emissao.id">
        <v-col>
          <venda-emissao-list-item :emissao="emissao"/>
        </v-col>
      </v-row>
      <v-row v-if="filter.status" v-for="emissao in filteredEmissoes" :key="emissao.id">
        <v-col>
          <venda-emissao-list-item :emissao="emissao"/>
        </v-col>
      </v-row>
    </v-card>
    </div>
    <div v-else>
      Sem emissões
    </div>
  </v-container>
</template>

<script>
import VendaEmissaoListItem from "@/components/venda/VendaEmissaoListItem";
import statusEmissao from "@/constants/statusEmissao";
export default {
  components: {VendaEmissaoListItem},
  props: {
    emissoes: {
      type: Array,
      default: () => []
    }
  },
  data(){
    return{
      filter:{
        status: "",
      },
      statusEmissao: statusEmissao
    }
  },
  computed: {
    filteredEmissoes() {
      if (!this.filter.status) {
        return this.emissoes;
      }
      return this.emissoes.filter(emissao => emissao.status === this.filter.status);
    }
  }
}
</script>
