<template>
    <v-container v-if="contato">
        <v-form id="endereco-form">
        <v-row>
            <v-col cols="12" sm="12">
                <v-select
                    :items="['Brasil']"
                    label="País"
                    outlined
                    name="pais"
                    prepend-inner-icon="mdi-flag"
                    :value="contato.endereco.pais"
                    disabled
                />

                    <v-text-field label="CEP" v-model="cepModel" name="cep" v-maska="'#####-###'" outlined/>
            </v-col>
        </v-row>
        <v-row>
            <v-col cols="12" sm="6">
                <v-autocomplete
                    label="Estado"
                    outlined
                    :items="estados"
                    item-text="nome"
                    prepend-inner-icon="mdi-map"
                    :search-input.sync="search"
                    :loading="estadosLoading"
                    v-model="selectedEstado"
                    @change="alertEstado"
                    @click="getEstados"
                    return-object
                />
                <v-input type="hidden" name="estado" item-value="nome" v-model="selectedEstado"/>
            </v-col>
            <v-col cols="12" sm="6">
                <v-autocomplete
                    id="cidade"
                    label="Cidade"
                    name="cidade"
                    outlined
                    clearable
                    prepend-inner-icon="mdi-map-marker-account"
                    :loading="cidadesLoading"
                    :disabled="cidadesDisabled"
                    :items="cidades"
                    :value="contato.endereco.cidade"
                    v-model="city"
                />
            </v-col>
        </v-row>
        <v-row>
            <v-col cols="12" sm="8">
                <v-text-field v-model="street" name="logradouro" label="Logradouro" outlined />
            </v-col>
            <v-col cols="12" sm="4">
                <CopyTextField label="Número" id="numero" icon="mdi-counter" :value="contato.endereco.numero" />
            </v-col>
        </v-row>
        <v-row>
            <v-col cols="12" sm="12">
                <CopyTextField label="Complemento" id="complemento" icon="mdi-routes" :value="contato.endereco.complemento" />
            </v-col>
        </v-row>
        </v-form>
    </v-container>
</template>
<script>
import { getAddressByCep } from '@/helpers/utils';
import CopyTextField from "@/components/widgets/CopyTextField.vue";

export default {
    components: {
        CopyTextField
    },

    data() {
        return {
            cidadesLoading: false,
            cidadesDisabled: true,
            estadosLoading: false,
            cepModel: '',
            city: '',
            street: '',
            paises: ['Brasil', 'EUA'],
            estados: [''],
            cidades: [''],
            search: "",
            selectedEstado: '',
            selectedCidade: '',
        }
    },

    methods: {
        async initialize() {
            if(!this.contato) return
            await this.getEstados()

            this.selectedEstado = this.contato.endereco.estado
            this.cepModel = this.contato.endereco.cep
            this.selectedCidade = this.contato.endereco.cidade

            if(!this.selectedEstado) return
            let foundEstado = this.estados.find(estado => estado.sigla === this.selectedEstado)
            this.selectedEstado = foundEstado
            this.alertEstado()
        },

        getEstados() {
            let estadosURL = "https://servicodados.ibge.gov.br/api/v1/localidades/estados/"
            fetch(estadosURL)
                .then(response => response.json())
                .then(data => this.estados = data )
        },

        alertEstado() {
            this.cidadesLoading = true
            this.cidadesDisabled = true
            let sigla = this.selectedEstado.sigla
            fetch(`https://servicodados.ibge.gov.br/api/v1/localidades/estados/${sigla}/municipios`)
                .then(response => response.json())
                .then(data => {
                    this.cidadesLoading = false
                    this.cidadesDisabled = false
                    this.cidades = data.map(cidade => cidade.nome)
                }).catch(e=>{
                    this.cidadesLoading = false
                    this.cidadesDisabled = false
                    console.error(e)
                })
        }
    },

    computed: {
        contato() {
            return this.$store.getters['contato/contato']
        }
    },

    created() {
        this.initialize()
        const unwatch = this.$watch('contato', (newValue = [], oldValue = []) => {
            this.initialize()
            unwatch()
        })
    },

    watch: {
        async cepModel() {
            if (this.cepModel?.length > 7) {
                if(this.cepModel == this.contato.endereco.cep) return
                const address = await getAddressByCep(this.cepModel);

                const { uf, localidade, logradouro } = address;

                this.selectedEstado = this.estados.filter((state) => state.sigla === uf)[0];
                this.alertEstado();
                this.cidadesDisabled = false;
                this.city = localidade;
                this.street = logradouro;
            }
        },
    }
}
</script>