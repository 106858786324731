<template>
  <v-container>
    <v-row>
      <v-col>
        <v-text-field
          v-model="value.logradouro"
          label="Logradouro"
        ></v-text-field>
      </v-col>
      <v-col>
        <v-text-field v-model="value.numero" label="Número"></v-text-field>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <v-text-field
          v-model="value.complemento"
          label="Complemento"
        ></v-text-field>
      </v-col>
      <v-col>
        <v-text-field v-model="value.bairro" label="Bairro"></v-text-field>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <v-text-field
          v-model="value.cep"
          label="CEP"
          v-mask="masks.cep"
        ></v-text-field>
      </v-col>
      <v-col>
        <v-text-field v-model="value.pais" label="País"></v-text-field>
      </v-col>
      <v-col>
        <v-text-field v-model="value.estado" label="Estado"></v-text-field>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <v-text-field v-model="value.cidade" label="Cidade"></v-text-field>
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
import { getAddressByCep } from "@/helpers/utils";
import { extendObj, substituteObj } from "@/helpers/algos";
import MASKS from "@/constants/masks";
import { filterEndereco } from "@/helpers/endereco";

import Vue from "vue";

export default {
  props: {
    value: {
      type: Object,
      default: () => {},
    },
  },

  data: () => ({
    masks: MASKS,
  }),

  computed: {
    cep() {
      return this.value.cep;
    },
  },

  watch: {
    async cep(val) {
      if (!this.value) return;
      if (this.value.logradouro) return;
      if (!val) return;
      const endereco = await getAddressByCep(val);
      if (!endereco) return;
      this.$emit("input", filterEndereco(endereco, false));
    },
  },
};
</script>
