<template>
    <v-container class="pa-4" fluid>
        <v-row>
            <v-col cols="12">
                <v-tabs v-model="activeTab" grow>
                    <v-tabs-slider color="primary"></v-tabs-slider>
                    <v-tab
                        v-for="tab in tabs"
                        :key="tab.name"
                        :to="tab.route"
                        exact-path>
                        <v-icon v-if="tab.icon" class="pr-2">{{tab.icon}}</v-icon>
                    {{ tab.name }}
                    <v-badge v-if="tab.badge" class="pl-1 pb-1" color="red"></v-badge>
                    </v-tab>
                </v-tabs>
                <v-container fluid class="ma-4" v-if="loading">
                    Loading...
                </v-container>
                <router-view v-else/>
            </v-col>
        </v-row>
    </v-container>
</template>
<script>
import api from "@/api/api.js";

export default {
    data(){
        return{
            loading: false,
            activeTab: "",
            tabs: [
            {
                name: "Abertos",
                route: { name: "ordem-pagamento-abertos" },
                icon: "mdi-cash-clock",
            },
            {
                name: "Pagos",
                route: { name: "ordem-pagamento-pagos" },
                icon: "mdi-cash-check",
            },
            ],
        }}
}
</script>