import OrdemPagamentoTableAbertos from "@/components/OrdemPagamento/OrdemPagamentoTableAbertos";
import OrdemPagamentoTablePagos from "@/components/OrdemPagamento/OrdemPagamentoTablePagos";

export default [
    {
        path: "abertos",
        name: "ordem-pagamento-abertos",
        component: OrdemPagamentoTableAbertos
    },
    {
        path: "pagos",
        name: "ordem-pagamento-pagos",
        component: OrdemPagamentoTablePagos
    },
]