<template>
    <div>
        <v-tabs v-model="activeTab">
            <v-tab
                v-for="tab in tabs"
                :key="tab.nome"
                :to="tab.route"
                exact-path
            > 
                <v-icon left> {{tab.icon}} </v-icon>
                {{tab.nome}}
            </v-tab>
        </v-tabs>
        <router-view />
    </div>
</template>

<script>
export default {
    props: {
        contato: {
            type: Object,
            default: () => {}
        }
    },

    data() {
        return {
            activeTab: "",
            tabs: [
                {nome: "Informações Pessoais", icon: "mdi-account", route: {name: "contato-info"}},
                {nome: "Endereço", icon: "mdi-map-marker" , route: {name: "contato-endereco"}},
                {nome: "Documentos PAX", icon: "mdi-card-account-details-outline" , route: {name: "contato-documentos"}},
                {nome: "Vendas", icon: "mdi-shopping" , route: {name: "contato-vendas"}},
                {nome: "Extras", icon: "mdi-plus" , route: {name: "contato-extras"}},
                {nome: "Busca", icon: "mdi-magnify" , route: {name: "contato-busca"}},
                {nome: "Milhas Validadas", icon: "mdi-medal" , route: {name: "contato-cotacoes-milhas-validadas"}},
                {nome: "Emissões como PAX", icon: "mdi-airplane-takeoff" , route: {name: "contato-emissoes-beneficiario"}},
                {nome: "Contas Fidelidade", icon: "mdi-star" , route: {name: "contato-contas-fidelidade"}},
            ]
        }
    },

    created() {
        this.initialize()
    },

    methods: {
        async initialize() {
            await this.$store.dispatch("global/fetchEmpresas")
        }
    },

    computed: {
        flagColor() {
            if(!this.contato) return ''

            let data_busca = this.contato.data_busca
            if(data_busca) 
                return data_busca.general_flag
            else
                return "gray"
        }
    }
}
</script>
