<template>
    <div>
        <v-container>
            <v-row v-for="field in fields" v-bind:key="field">
                <v-col>
                    <v-btn
                        elevation="3"
                        @click="exportar(field)"
                    >Exportar {{field}}</v-btn>
                </v-col>
            </v-row>
        </v-container>
    </div>
</template>
<script>
import api from "@/api/api.js"

import {flattenObj} from "@/helpers/algos.js"
import {download} from "@/helpers/downloadFile.js"

import {tablesGetters} from "@/constants/exportTablesGetters.js"

const { Parser } = require('json2csv');
const parser = new Parser()


export default {

    data: () => ({
        loading: new Map(),
        dados: new Map(),
        fields: [...tablesGetters.keys()]
    }),

    async mounted(){
        await this.initialize()
    },

    methods: {

        async initialize(){
            this.fields.forEach(field => {
                this.loading.set(field, false)
                this.dados.set(field, "")
            })
        },

        set(field, data) {
            this.loading.set(field, true)
            this.dados.set(field, parser.parse(
                data.map( e => flattenObj(e))
            ))
            this.loading.set(field, false)
        },

        async exportar(field){
            this.$root.vtoast.show({
                message: `Seu download de ${field} deve acontecer em alguns instantes.`,
                color: 'success',
            })
            try{
                const result = await tablesGetters.get(field)()
                this.set(field, result.data)
                download(`${field}.csv`, this.dados.get(field))
            }
            catch(e){
                this.$root.vtoast.show({
                    message: `Erro ao fazer download: ${e}`,
                    color: 'error',
                    icon: 'mdi-close'
                })
            }
        },
    }


}
</script>
