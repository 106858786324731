<template>
    <v-row justify="center">
        <v-dialog
            max-width="600"
            persistent
            v-model="dialog"
        >
            <v-card>
                <v-card-title class="text-h5">
                    Cancelar venda
                </v-card-title>

                <v-card-text>
                    <v-label>
                        Qual é o motivo do cancelamento da venda?
                    </v-label>
                    <v-text-field
                        required="true"
                        v-model="motivo"
                        prepend-inner-icon="mdi-pencil"
                        label="Motivo*"
                        counter
                        maxlength="255"
                        :rules="maxLength"
                    />
                </v-card-text>
  
                <v-card-actions>
                    <v-btn
                        text
                        @click="closeDialog"
                    >
                        <v-icon left>mdi-arrow-left</v-icon>
                        Voltar
                    </v-btn>
                    <v-spacer />
                    <v-btn
                        color="red darken-1"
                        text
                        @click="confirmarCancelamento"
                        :loading="loading"
                        :disabled="motivo.length < 3"
                    >
                        <v-icon left>mdi-alert</v-icon>
                        Confirmar
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </v-row>
</template>

<script>

export default {
    data() {
        return {
            motivo: "",
            loading: false,
            maxLength: [v => v.length <= 255 || 'Maximo 255 caracteres']
        }
    },
    props: {
        dialog: {
            type: Boolean,
            default: false
        }
    },
    methods: {
        closeDialog() {
            this.dialog = false
            this.$emit('closeDialog')
        },
        confirmarCancelamento() {
            this.loading = true
            this.$emit('confirmarCancelamento', this.motivo)
        }
    }
}
</script>
