var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-container',{staticClass:"w-auto",attrs:{"max-width":"100%"}},[_c('v-card',{staticClass:"px-4 mx-0"},[_c('v-card-title',{staticClass:"text-h5"},[_vm._v(" Protocolos ")]),_c('v-card-text',[_vm._l((_vm.protocolos),function(item){return _c('v-row',{key:item.id},[_c('v-col',[_c('v-field-value',{attrs:{"label":"Nome"}},[_vm._v(" "+_vm._s(item.nome)+" ")])],1),_c('v-col',[_c('v-field-value',{attrs:{"label":"Descrição"}},[_vm._v(" "+_vm._s(item.descricao)+" ")])],1),_c('v-col',[_c('v-field-value',{attrs:{"label":"Data"}},[_vm._v(" "+_vm._s(_vm._f("convertDataBR")(item.data))+" ")])],1),_c('v-col',[_c('v-field-value',{attrs:{"label":"Criado em"}},[_vm._v(" "+_vm._s(_vm._f("dataHoraBR")(item.created_at))+" ")])],1),_c('v-col',[_c('v-field-value',{attrs:{"label":"Criado por"}},[_vm._v(" "+_vm._s(_vm._f("nomeSobrenome")(item.created_by))+" ")])],1),_c('v-divider')],1)}),_c('v-row',[_c('v-text-field',{attrs:{"dense":"","outlined":"","label":"Nome","rules":[
            (v) =>
              (v && v.length >= 10) ||
              'Observações deve ter mais de 10 caracteres',
          ]},model:{value:(_vm.value.nome),callback:function ($$v) {_vm.$set(_vm.value, "nome", $$v)},expression:"value.nome"}})],1),_c('v-row',[_c('v-textarea',{attrs:{"label":"Descrição","outlined":"","name":"descricao","rows":"2","rules":[
            (v) =>
              (v && v.length >= 20 && v.length <= 500) ||
              'Observações devem ter entre 20 e 500 caracteres',
          ]},model:{value:(_vm.value.descricao),callback:function ($$v) {_vm.$set(_vm.value, "descricao", $$v)},expression:"value.descricao"}})],1),_c('v-row',[_c('form-date-picker',{attrs:{"label":"Data"},model:{value:(_vm.value.data),callback:function ($$v) {_vm.$set(_vm.value, "data", $$v)},expression:"value.data"}})],1)],2),_c('v-card-actions',[_c('v-btn',{attrs:{"text":""},on:{"click":_vm.closeDialog}},[_c('v-icon',{attrs:{"left":""}},[_vm._v("mdi-arrow-left")]),_vm._v(" Voltar ")],1),_c('v-spacer'),_c('v-btn',{attrs:{"text":"","disabled":_vm.value.nome.length < 10 || _vm.value.descricao.length < 20},on:{"click":function($event){return _vm.addProtocolo()}}},[_vm._v(" Adicionar protocolo ")])],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }