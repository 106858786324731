import VendaOrcamentosView from "@/components/venda/VendaOrcamentosView";
import VendaCotacoesView from "@/components/venda/VendaCotacaoView";
import UsuarioVendasView from "@/components/usuario/UsuarioVendasView";
import UsuarioEmissoesView from "@/components/usuario/UsuarioEmissoesView";

export default [
    {
        path: "",
        name: "usuario-vendas",
        component: UsuarioVendasView
    },
    {
        path: "orcamentos",
        name: "usuario-orcamentos",
        component: VendaOrcamentosView,
    },
    {
        path: "cotacoes",
        name: "usuario-cotacoes",
        component: VendaCotacoesView,
    },
    {
        path: "emissoes",
        name: "usuario-emissoes",
        component: UsuarioEmissoesView,
    },
];
