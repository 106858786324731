<template>
  <v-container>
    <v-row>Cotação de Outro Servico</v-row>
    <v-row>Nome do servico: {{ cotacao.nome_servico }}</v-row>
    <v-row>Data de início: {{ cotacao.data_inicio }} </v-row>
    <v-row>Data de fim: {{ cotacao.data_fim }}</v-row>
    <v-row>Local: {{ cotacao.local ? cotacao.local.nome : "" }}</v-row>
    <v-row>Custo Total: {{ formatValorFinanceiro(cotacao.custo_total) }}</v-row>
    <v-row>
      <v-btn link :to="orcamentoLinkTo">
        Ir para orcamento
        <v-icon color="primary" class="ml-2">mdi-open-in-new </v-icon>
      </v-btn>
    </v-row>
  </v-container>
</template>
<script>
import { formatValorFinanceiro } from "@/helpers/financeiro";

export default {
  props: {
    cotacao: {
      type: Object,
      default: () => {},
    },
    orcamentoLinkTo: {
      type: Object,
      default: () => {},
    },
  },

  methods: {
    formatValorFinanceiro(valor) {
      return formatValorFinanceiro(valor);
    },
  },
};
</script>
