<template>
    <v-card>
      <v-card-title>Emissões associadas</v-card-title>
      <v-card-text>
        <v-container
          v-for="(emissao, index) in emissoes"
          :key="index"
          class="my-2"
        >
          <v-row>#{{ index + 1 }}</v-row>
          <v-row>ID:           
            <v-link-text-field
            :to="{ name: 'emissao', params: { id: emissao?.id  }}"
            >
            #{{  emissao.id | formatId }}
          </v-link-text-field>
        </v-row>
          <v-row>Status: {{ emissao.status | formatStatusEmissao }}</v-row>
          <v-row>Tipo: {{ emissao.tipo | formatTipoEmissao }}</v-row>
          <v-row>Emitido por: {{ emissao.emitido_por | nomeSobrenome }}</v-row>

        </v-container>
      </v-card-text>
      <v-card-actions>
        <v-btn color="blue darken-1" text @click="close"> Fechar </v-btn>
      </v-card-actions>
    </v-card>
  </template>
  <script>
  import VLinkTextField from "@/components/widgets/VLinkTextField";
  export default {
    components: {
      VLinkTextField
    },
    props: {
      emissoes: {
        type: Array,
        default: () => [],
      },
    },
  
    methods: {
      close() {
        this.$emit("close");
      },
    },
  };
  </script>
  