import { get } from '../modules'

export default {
    getFidelidades() {
        return get("/api/fidelidades")
    },
    getProgramas() {
        return get("/api/programas")
    },
    getCiaAereas() {
        return get("/api/ciaaereas")
    }
}
