<template>
    <v-container>
        <v-form id="busca-form">
            <v-btn
                
                color="primary"
                @click="clickBusca()"
                :loading="loadingBusca"
                v-if="!parsed_data"
                :disabled="checkUserPermissao"
            >
                Buscar
            </v-btn>

            <v-list v-if="parsed_data">
                <v-list-item>
                    Pesquisa feita por {{parsed_data.search_information.user}} em {{parsed_data.search_information.data}}
                </v-list-item>

                <CardBusca 
                    label="Informações Pessoais"
                    :legenda="legendaPersonal" 
                    :selectedInformation="selectedPersonalInformation" 
                    :information="parsed_data.personal_information"
                />

                <CardBusca 
                    label="Processos"
                    :flag="parsed_data.process_information.flag"  
                    :selectedInformation="selectedProcessInformation"
                    :legenda="legendaProcess"
                    :information="parsed_data.process_information"
                />

                <CardBusca
                    label="Restrições"
                    :flag="parsed_data.restriction_information.flag" 
                    :legenda="legendaRestriction" 
                    :selectedInformation="selectedRestrictionInformation" 
                    :information="parsed_data.restriction_information"
                />

                <CardBusca label="Cheques sem fundo ou sustados" :flag="parsed_data.bank_check_information.flag" 
                    :legenda="legendaBankCheck" 
                    :selectedInformation="selectedBankCheckInformation" 
                    :information="parsed_data.bank_check_information"   
                />

                <CardBusca label="Protestos" :flag="parsed_data.protest_information.flag" 
                    :legenda="legendaProtest" 
                    :selectedInformation="selectedProtestInformation" 
                    :information="parsed_data.protest_information"
                />

                <CardBusca label="Score de Crédito" :flag="parsed_data.credit_score_information.flag" 
                    :legenda="legendaCreditScore" 
                    :selectedInformation="selectedCreditScoreInformation" 
                    :information="parsed_data.credit_score_information"
                />

                <CardBusca label="Emails"  
                    :legenda="legendaEmail" 
                    :selectedInformation="selectedEmailInformation" 
                    :information="parsed_data.email_information"
                    v-if="parsed_data.email_information.quantidade!==0"
                />

                <CardBusca
                    label="Telefones"  
                    :legenda="legendaPhone" 
                    :selectedInformation="selectedPhoneInformation" 
                    :information="parsed_data.phone_information"
                />

                <CardBusca
                    label="Outros Documentos" 
                    :legenda="legendaOtherDocument" 
                    :selectedInformation="selectedOtherDocumentInformation" 
                    :information="parsed_data.other_document_information"
                />
            </v-list>
        </v-form>
    </v-container>
</template>

<script>
import CopyTextField from "@/components/widgets/CopyTextField.vue";
import CardBusca from "@/components/widgets/CardBusca.vue";
import api from "@/api/api.js";
import checkPermissao from "@/helpers/checkUserPermissao.js";

import {
    personalInformation as PERSONAL_INFORMATION,
    lawProcessInformation as LAW_PROCESS_INFORMATION,
    protestInformation as PROTEST_INFORMATION,
    phoneInformation as PHONE_INFORMATION,
    emailInformation as EMAIL_INFORMATION,
    bankCheckInformation as BANK_CHECK_INFORMATION,
    restrictionInformation as RESTRICTION_INFORMATION,
    otherdocumentInformation as OTHER_DOCUMENT_INFORMATION,
    creditScoreInformation as CREDIT_SCORE_INFORMATION
} from "@/constants/databusca.js";

import {
    selectedPersonalInformation as SELECTED_PERSONAL_INFORMATION,
    selectedProcessInformation as SELECTED_PROCESS_INFORMATION,
    selectedRestrictionInformation as SELECTED_RESTRICTION_INFORMATION,
    selectedBankCheckInformation as SELECTED_BANK_CHECK_INFORMATION,
    selectedCreditScoreInformation as SELECTED_CREDIT_SCORE_INFORMATION,
    selectedEmailInformation as SELECTED_EMAIL_INFORMATION,
    selectedOtherDocumentInformation as SELECTED_OTHER_DOCUMENT_INFORMATION,
    selectedPhonesInformation as SELECTED_PHONES_INFORMATION,
    selectedProtestInformation as SELECTED_PROTEST_INFORMATION
} from "@/constants/databuscaSelected.js";

export default {
    components: {
        CopyTextField,
        CardBusca
    },

    data() {
        return {
            selectedRestrictionInformation: SELECTED_RESTRICTION_INFORMATION,
            selectedPersonalInformation: SELECTED_PERSONAL_INFORMATION,
            selectedProcessInformation: SELECTED_PROCESS_INFORMATION,
            selectedBankCheckInformation: SELECTED_BANK_CHECK_INFORMATION,
            selectedOtherDocumentInformation: SELECTED_OTHER_DOCUMENT_INFORMATION,
            selectedProtestInformation: SELECTED_PROTEST_INFORMATION,
            selectedEmailInformation: SELECTED_EMAIL_INFORMATION,
            selectedPhoneInformation: SELECTED_PHONES_INFORMATION,
            selectedCreditScoreInformation: SELECTED_CREDIT_SCORE_INFORMATION,

            dialog: false,
            legendaPersonal: PERSONAL_INFORMATION,
            legendaProcess: LAW_PROCESS_INFORMATION,
            legendaRestriction: RESTRICTION_INFORMATION,
            legendaBankCheck: BANK_CHECK_INFORMATION,
            legendaOtherDocument: OTHER_DOCUMENT_INFORMATION, 
            legendaProtest: PROTEST_INFORMATION, 
            legendaEmail: EMAIL_INFORMATION,
            legendaPhone: PHONE_INFORMATION,
            legendaCreditScore: CREDIT_SCORE_INFORMATION,

            loadingBusca: false,

            parsed_data: null,
        }
    },

    methods: {
        clickBusca() {
            this.loadingBusca = true
            this.contatoCPF = this.contato.cpf

            if(!this.contatoCPF) {
                this.$root.vtoast.show({
                    message: 'CPF não disponível',
                    color: 'error',
                    icon: 'mdi-close',
                })

                return this.loadingBusca = false

            } else if(this.contatoCPF) {
                api.postDataBuscaAPIContato(this.contato.id, this.contato.cpf)
                    .then(response => {
                        this.parsed_data = JSON.parse(response.data.formatted_data)
                    }).catch(e => {
                        this.$root.vtoast.show({
                            message: 'Erro ao fazer a busca',
                            color: 'error',
                            icon: 'mdi-close'
                        })

                        console.error(e)
                    }).finally(() => {
                        this.loadingBusca = false
                    })
            }
        },

        async initialize() {
            if(this.contato)
                if(this.contato.data_busca)
                    this.parsed_data = JSON.parse(this.contato.data_busca.formatted_data)
        },
    },

    computed: {
        contato() {
            return this.$store.getters['contato/contato']
        },
        user() {
            return this.$store.getters['usuario/loggedUser'];
        },
        checkUserPermissao(){
            const pertencente = this.user.pertencente;
            return checkPermissao(pertencente);
        }
    },

    created() {
        this.initialize()
        const unwatch = this.$watch('contato', (newValue = [], oldValue = []) => {
            this.initialize()
            unwatch()
        })
    },
}

</script>