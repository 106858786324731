<template >
    <div class="page pb-2 mb-0">
        <Header />
        <div class="infos">
            <v-container fluid>
                <div class="container-colunas">
                    <div class="coluna">
                        <v-card height="100%">
                            <v-card-text>
                                <p class="text-h4 text--primary">
                                    Cliente
                                </p>
                                <div class="text--primary">
                                    <p>Nome: {{ informacoesCliente.nome }} {{ informacoesCliente.sobrenome }}</p>
                                    <p v-if="informacoesCliente.email" >Email: {{ informacoesCliente.email }}</p>
                                    <p v-if="informacoesCliente.telefone">Telefone: {{ informacoesCliente.telefone.numero }}</p>
                                    <p v-if="informacoesCliente.cpf">CPF: {{ informacoesCliente.cpf }}</p>
                                    <p v-if="informacoesCliente.cnpj">CNPJ: {{ informacoesCliente.cnpj }}</p>
                                </div>
                            </v-card-text>
                        </v-card>
                    </div>
                    <div class="coluna">
                        <v-card height="100%">
                            <v-card-text>
                                <p class="text-h4 text--primary">
                                    Vendas
                                </p>
                                <div class="text--primary">
                                    <p>ID da Venda: {{ vendas.map(venda => venda.id).join(', ') }}</p>
                                    <p class="preco">Valor total: {{ selectedValorTotal | dinheiroReal }}</p>
                                    <p class="preco">Valor aberto: {{ selectedValorAberto | dinheiroReal }}</p>
                                    <p v-if="informacoesCliente.consultor">Consultor:
                                        {{ informacoesCliente.consultor.nome }} {{ informacoesCliente.consultor.sobrenome }}
                                    </p>
                                    <p v-if="informacoesCliente.consultor">Email consultor:
                                        {{ informacoesCliente.consultor.email }}</p>

                                </div>
                            </v-card-text>
                        </v-card>
                    </div>
                </div>
            </v-container>
        </div>
    </div>
</template>
<script>

import Header from '../Template/Header.vue'
export default {
    components: {
        Header
    },
    props: {
        servicos: {
            type: Array,
            default: () => []
        },
        vendas: {
            type: Array,
            default: () => []
        },
        valorTotal: {
            type: Object,
            default: () => { }
        },
        valorAbertoCotacoes: {
            type: Object,
            default: () => { }
        },
        valorAbertoVendas: {
            type: Object,
            default: () => { }
        },
    },
    computed: {
        informacoesCliente() {
            if (this.vendas[0].cliente) return this.vendas[0].cliente
            if (this.vendas[0].cliente_empresa) return this.vendas[0].cliente_empresa
            
        },
        selectedValorTotal() {
            return this.vendas.reduce((sum, value) => sum += this.valorTotal[value.id], 0)
        },
        selectedValorAberto() {
            return this.vendas.reduce((sum, venda) => sum += this.valorAbertoVendas[venda.id], 0)
        }
    }
}
</script>
<style >
.container-colunas {
    display: flex
}
.coluna {
    width: 100%;
}

.preco {
    color:black;
}
</style>