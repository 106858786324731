<template>
  <v-container>
    <v-row>
    <v-col
      cols="11"
      sm="5"
    >
      <v-menu
        ref="menu"
        v-model="menu"
        :close-on-content-click="false"
        :return-value.sync="date"
        transition="scale-transition"
        offset-y
        max-width="290px"
        min-width="auto"
      >
        <template v-slot:activator="{ on, attrs }">
          <v-text-field
            v-model="monthPicker"
            label="Selecionar mês"
            prepend-icon="mdi-calendar"
            readonly
            v-bind="attrs"
            v-on="on"
          ></v-text-field>
        </template>
        <v-date-picker
          v-model="monthPicker"
          type="month"
          locale="pt-br"
          no-title
          scrollable
        >
          <v-spacer />
          <v-btn
            text
            color="primary"
            @click="menu = false"
          >
            OK
          </v-btn>
        </v-date-picker>
      </v-menu>
    </v-col>
    </v-row>
    <v-data-table
      :headers="headers"
      :items="items"
      class="elevation-5"
      sort-by="archived_at"
      :items-per-page="-1"
      group-by="archived_at"
      :group-desc="true"
      show-group-by
      :loading="loadingTable"
    >
      <template v-slot:item.classe="{ item }">
        {{ item.classe | formatClasse }}
      </template>
      <template v-slot:item.programa="{ item }">
        {{ formatProgramaFidelidade(item.programa) }}
      </template>
      <template v-slot:item.operado_por="{ item }">
        {{ formatCiaAerea(item.operado_por) }}
      </template>
      <template v-slot:group.header="{ group, headers, toggle, isOpen, items }">
        <td :colspan="2">
          <v-btn @click="toggle" small icon :ref="group" :data-open="isOpen">
            <v-icon v-if="isOpen">mdi-chevron-up</v-icon>
            <v-icon v-else>mdi-chevron-down</v-icon>
          </v-btn>
          {{ group }}
        </td>

      </template>
      <template v-slot:top>
        <v-toolbar flat>
          <v-toolbar-title>Preço de Custo de Milhas - Histórico</v-toolbar-title>
          <v-divider class="mx-4" inset vertical></v-divider>
          <v-spacer></v-spacer>
          <v-btn
          color="blue"
          dark
          @click="toggleAll">
          {{isToggledHistoricoMilhasProprias? 'Expandir Todos' : 'Colapsar Todos'}}
          </v-btn>
        </v-toolbar>
      </template>
      <!-- <template v-slot:item.actions="{ item }">
        <v-icon small @click="deleteItem(item)"> mdi-delete </v-icon>
      </template> -->
      <template v-slot:no-data>
        <v-btn color="primary" @click="initialize"> Reset </v-btn>
      </template>
    </v-data-table>
  </v-container>
</template>
<script>
import api from "@/api/api";
import { PRECOS_RANGE } from "@/constants/milhas";

export default {
  name: "MilhasHistoricoTable",
  props: {
    limit: {
      type: null,
      default: 0,
    },
    formatCiaAerea: {
      type: Function,
      default: () => {},
    },
    formatProgramaFidelidade: {
      type: Function,
      default: () => {},
    },
  },
   mounted() {
    
  },
  data: () => ({
    dialog: false,
    dialogDelete: false,
    precos_range: PRECOS_RANGE,
    headers: [
      { text: "Data de Arquivação", value: "archived_at" },
      {
        text: "Programa",
        align: "start",
        sortable: false,
        value: "programa",
        groupable: false,
      },
      { text: "Custo Próprio", value: "custo_proprio" },
      { text: "Custo Terceiros", value: "custo_terceiros" },
      { text: "Ações", value: "actions", sortable: false, groupable: false },
    ],
    rawItems: [],
    editedIndex: -1,
    monthPicker: new Date().toISOString().substring(0, 7),
    menu: false,
    loadingTable: false,
    isToggledHistoricoMilhasProprias: false,
    date: null,
  }),

  computed: {
    formTitle() {
      return this.editedIndex === -1 ? "Novo Item" : "Editar Item";
    },
    programas() {
      return this.$store.state.global.programas;
    },
    ciaAereas() {
      return this.$store.state.global.ciaAereas;
    },
    items() {
      return this.rawItems.filter(item => item.tipo==="custo").map((item) => ({
        ...item,
        archived_at: this.$options.filters.convertDataBR(item.archived_at)}))
    },
    
  },

  watch: {
    dialog(val) {
      val || this.close();
    },
    dialogDelete(val) {
      val || this.closeDelete();
    },
    monthPicker() {
      this.loadHistoricoMilhas()
      this.resetToggle()
    }
  },

  created() {
    this.initialize();
  },

  methods: {
    initialize() {
      this.loadHistoricoMilhas()
    },

    loadHistoricoMilhas() {
      this.loading = true
      api.getHistoricoMilhas(this.monthPicker).then((response) => {
        this.rawItems = response.data;
      }).finally(() => {
        this.loading = false
      });
    },

    deleteItem(item) {
      this.editedIndex = this.items.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.dialogDelete = true;
    },

    deleteItemConfirm() {
      this.items.splice(this.editedIndex, 1);
      this.closeDelete();
    },

    close() {
      this.dialog = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },

    closeDelete() {
      this.dialogDelete = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },

    async save() {
      if (
        !confirm(
          "Tem certeza que deseja alterar o valor das milhas desse programa? Essas alterações serão salvas e mantidas em instancias futuras."
        )
      )
        return;
      await api
        .putTabelaMilhas(this.editedItem.id, this.editedItem)
        .then((response) => {
          this.items = this.items.map((item) =>
            response.data.id === item.id
              ? {
                  ...response.data,
                }
              : item
          );
          this.$root.vtoast.show({
            message: "Valor alterado com sucesso!",
            icon: "mdi-check",
            color: "success",
          });
        });
      this.close();
    },
    toggleAll() {
      this.isToggledHistoricoMilhasProprias = !this.isToggledHistoricoMilhasProprias;
      // Aguarda os dados serem renderizados no DOM
      this.$nextTick(() => {
        Object.keys(this.$refs).forEach((k) => {
          if (this.$refs[k] && this.$refs[k].$el) {
            this.$refs[k].$el.click();
          }
        });
      });
    },
    resetToggle() {
      this.isToggledHistoricoMilhasProprias = false;
    },
  },
};
</script>

<style>
.margin-programas {
  margin-right: 1rem;
}
</style>
