<template>
  <nav>
    <v-navigation-drawer app class="mt-100" :value="value" @input="input">
      <v-list-item class="px-2">
        <v-list-item-avatar>
          <v-icon> mdi-airplane </v-icon>
        </v-list-item-avatar>

        <v-list-item-title>Sistema Skyler</v-list-item-title>
      </v-list-item>

      <v-divider></v-divider>

      <v-list nav dense>
        <v-list-item
          v-for="(item, index) in listItems"
          :key="index"
          :to="{ name: item.route }"
          link
          v-if="
            !item.restricted || (item.restricted && allowedRoute(item.route))
          "
        >
          <v-list-item-icon>
            <v-icon> mdi-{{ item.icon }} </v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>{{ item.text }}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list>
    </v-navigation-drawer>
  </nav>
</template>

<script>
export default {
  props: {
    value: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      drawer: this.value,
      listItems: [
        { text: "Contatos", route: "contatos", icon: "account" },
        { text: "Empresas", route: "empresas", icon: "domain" },
        { text: "Vendas", route: "vendas", icon: "shopping" },
        { text: "Orçamentos", route: "orcamentos", icon: "wallet" },
        { text: "Serviços", route: "servicos", icon: "hammer" },
        { text: "Comissão", route: "comissao", icon: "plus", restricted: true},
        { text: "Contas a Receber", route: "pagamentos-abertos", icon: "cash", restricted: true},
        { text: "Contas a Pagar", route: "ordem-pagamento-abertos", icon: "account-cash", restricted: true},
        { text: "Emissões", route: "emissoes", icon: "airplane-takeoff" },
        { text: "Milhas", route: "milhas", icon: "medal", restricted: true },
        {
          text: "Base de Fornecedores",
          route: "fornecedores",
          icon: "account-tie",
        },
        {
          text: "Autorizações de Milhas",
          route: "painel-autorizacao",
          icon: "key",
          restricted: true,
        },
        {
          text: "Autorizações de Pagamentos",
          route: "painel-pagamentos",
          icon: "credit-card-check",
          restricted: true,
        },
        {
          text: "Notificações",
          route: "notificacoes",
          icon: "bell",
          restricted: true,
        },
        { text: "RAC", route: "rac-aberta", icon: "calendar" },
        {
          text: "Milhas Próprias",
          route: "milhas-proprias",
          icon: "medal",
          restricted: true,
        },
        {
          text: "Pega Vendas",
          route: "pega-vendas",
          icon: "target",
          restricted: true,
        },
      ],
    };
  },
  watch: {},
  methods: {
    input(val) {
      this.$emit("input", val);
    },
    allowedRoute(route) {
      const user = this.$store.getters["usuario/loggedUser"];
      const authorizedRoutes = user?.authorized_restricted_routes || [];
      return authorizedRoutes.includes(route);
    },
  },
  computed: {
    isStaffUser() {
      return this.$store.getters["usuario/isStaffUser"];
    },
  },
};
</script>
