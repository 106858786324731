<template>
  <v-container>
    <!-- Tabela -->
    <v-card tile class="mx-auto">
      <v-data-table
        dense
        :headers="headers"
        :items="cotacoesMilhas"
        sort-by="id"
        sort-desc
      >
        <template v-slot:item.emissao="{ item }">
          <div v-for="emissao in item?.servico?.emissoes">
            <v-link-text-field :to="{ name: `emissao`, params: { id: emissao.id }}" text small>
              {{ emissao.id }}
            </v-link-text-field>
            {{ emissao.status | formatStatusEmissao }}
          </div>
          
        </template>
        <template v-slot:item.data_emissao="{ item }">
          {{ item?.servico?.emissoes[0].data_hora_emissao | dataHoraBR}}
        
        </template>
        <template v-slot:item.conta_fidelidade="{ item }">
          {{ formatContaFidelidade(item)}}
        
        </template>
        <template v-slot:item.venda="{ item }">
          <v-link-text-field :to="{ name: `venda-info`, params: { id: item.cotacao.venda }}" text small>
              {{ item.cotacao.venda }}
            </v-link-text-field>
        
        </template>
        <template v-slot:item.cotacao="{ item }">
          <v-link-text-field :to="{ name: `orcamento`, params: { id: item.cotacao.orcamento }}" text small>
              {{ item.cotacao.id }}
            </v-link-text-field>
        
        </template>

        <template v-slot:no-data> Nenhuma cotação milha validada encontrada </template>
      </v-data-table>
    </v-card>
  </v-container>
</template>

<script>
import api from "@/api/api";
import VLinkTextField from "@/components/widgets/VLinkTextField.vue";

export default {
  components: {VLinkTextField},
  data() {
    return {
      headers: [
        { text: "Emissão", value: "emissao" },
        { text: "Data Criação Emissão", value: "data_emissao" },
        { text: "Conta Fidelidade", value: "conta_fidelidade" },
        { text: "Provedor", value: "provedor_milhas.nome" },
        { text: "Cotação", value: "cotacao" },
        { text: "Venda", value: "venda" },
        { text: "Serviço", value: "servico.id" },
      ],
      cotacoesMilhas: [],

    };
  },
  mounted() {
    this.initialize();
  },
  created() {
  },

  methods: {
    initialize() {
      
      const cliente_id = this.$route.params.id;
      api.getContatoCotacoesMilhas(cliente_id).then((response) => {
        this.cotacoesMilhas = response.data;
      });
    },
    formatContaFidelidade(item) {
      if (item.conta_fidelidade) {
        return `${item.conta_fidelidade?.programa.nome}, ${this.$options.filters.nomeSobrenome(item.conta_fidelidade?.titular) }`
      }
      return `${item?.programa_fidelidade.nome} ${item.cpf}`
    }

    
  },
  computed: {

  },
};
</script>
