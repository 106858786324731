<template>
  <v-container>
    <v-form id="docs-form">
      <v-row justify="center">
        <v-dialog v-model="dialog.passaporte" persistent max-width="600px">
          <template v-slot:activator="{ on, attrs }">
            <v-btn color="primary" dark v-bind="attrs" v-on="on">
              Adicionar Passaporte
            </v-btn>
          </template>
          <v-card>
            <v-card-title>
              <span class="text-h5"> Adicionar Passaporte </span>
            </v-card-title>
            <v-card-text>
              <v-form id="new-passaporte" ref="form-passaporte">
                <v-file-input
                  name="passaporte"
                  label="Passaporte"
                  prepend-icon=""
                  v-model="loadedFile"
                  accept="image/*, application/pdf"
                  prepend-inner-icon="mdi-paperclip"
                  outlined
                />
                <v-text-field
                  name="numero"
                  label="N. Passaporte"
                  outlined
                  prepend-inner-icon="mdi-passport"
                />

                <FormDatePicker
                  v-model="validadeModel"
                  outlined
                  :dense="false"
                  label="Data de Validade"
                  name="data_validade"
                />

                <FormDatePicker
                  v-model="emissaoModel"
                  outlined
                  :dense="false"
                  label="Data de Emissão"
                  name="data_emissao"
                />

                <v-combobox
                  v-model="pais_emissor"
                  label="País Emissor"
                  :items="paises"
                  name="pais"
                  prepend-inner-icon="mdi-map-marker"
                  outlined
                />
              </v-form>
            </v-card-text>
            <v-card-actions>
              <v-btn
                color="success"
                @click="savePassaporte()"
                :loading="loadingAdicionarPassaporte"
              >
                Adicionar
              </v-btn>

              <v-btn color="secondary" @click="dialog.passaporte = false">
                Cancelar
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-row>
      <v-row justify="center">
        <v-dialog v-model="dialog.identidade" persistent max-width="600px">
          <template v-slot:activator="{ on, attrs }">
            <v-btn color="primary" dark v-bind="attrs" v-on="on">
              Adicionar RG
            </v-btn>
          </template>
          <v-card>
            <v-card-title>
              <span class="text-h5"> Adicionar RG </span>
            </v-card-title>
            <v-card-text>
              <v-form id="new-identidade" ref="form-identidade">
                <v-file-input
                  name="identidade"
                  label="Identidade"
                  prepend-icon=""
                  v-model="loadedFileIdentidade"
                  accept="image/*, application/pdf"
                  prepend-inner-icon="mdi-paperclip"
                  outlined
                />
                <v-text-field
                  name="numero"
                  label="N. Identidade"
                  outlined
                  prepend-inner-icon="mdi-card-account-details"
                />

                <FormDatePicker
                  v-model="emissaoidentidadeModel"
                  outlined
                  :dense="false"
                  label="Data de Emissão"
                  name="data_emissao"
                />

                <v-text-field
                  v-model="orgao_emissor"
                  label="Orgão Emissor"
                  name="orgao_emissor"
                  prepend-icon=""
                  outlined
                />
              </v-form>
            </v-card-text>
            <v-card-actions>
              <v-btn
                color="success"
                @click="saveIdentidade()"
                :loading="loadingAdicionarIdentidade"
              >
                Adicionar
              </v-btn>

              <v-btn color="secondary" @click="dialog.identidade = false">
                Cancelar
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-row>
      <v-row justify="center">
        <v-dialog v-model="dialog.cnh" persistent max-width="600px">
          <template v-slot:activator="{ on, attrs }">
            <v-btn color="primary" dark v-bind="attrs" v-on="on">
              Adicionar CNH
            </v-btn>
          </template>
          <v-card>
            <v-card-title>
              <span class="text-h5"> Adicionar CNH </span>
            </v-card-title>
            <v-card-text>
              <v-form id="new-cnh" ref="form-cnh">
                <v-file-input
                  name="cnh"
                  label="CNH"
                  prepend-icon=""
                  v-model="loadedFileCNH"
                  accept="image/*, application/pdf"
                  prepend-inner-icon="mdi-paperclip"
                  outlined
                />

                <v-text-field
                  name="numero"
                  label="N. CNH"
                  outlined
                  prepend-inner-icon="mdi-card-account-details"
                />

                <FormDatePicker
                  v-model="validadecnhModel"
                  outlined
                  :dense="false"
                  label="Data de Validade"
                  name="data_validade"
                />

                <v-combobox
                  v-model="categoriacnhModel"
                  label="Categoria"
                  :items="categorias"
                  name="categoria"
                  outlined
                />
              </v-form>
            </v-card-text>
            <v-card-actions>
              <v-btn
                color="success"
                @click="saveCNH()"
                :loading="loadingAdicionarCNH"
              >
                Adicionar
              </v-btn>

              <v-btn color="secondary" @click="dialog.cnh = false">
                Cancelar
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-row>
      <v-card tile class="mx-auto my-6" v-if="docs.passaporte.length">
        <v-list-item-title class="px-6">
          <v-icon> mdi-passport </v-icon>
          Passaportes
        </v-list-item-title>
        <v-list-item
          v-for="(doc, index) in docs.passaporte"
          :key="index"
          :id="doc.id"
        >
          <v-col cols="12" sm="4">
            <CopyTextField
              label="N° do Passaporte"
              id="numero-passaporte"
              :value="doc.numero"
              readonly
            />
          </v-col>

          <v-col cols="12" sm="2">
            <CopyTextField
              label="Data de Validade"
              id="validade"
              :value="doc.data_validade | convertDataBR"
              readonly
            />
          </v-col>

          <v-col cols="12" sm="2">
            <CopyTextField
              label="Data de Emissão"
              id="emissao"
              :value="doc.data_emissao | convertDataBR"
              readonly
            />
          </v-col>

          <v-col cols="12" sm="2">
            <CopyTextField
              label="País Emissor"
              id="emissor"
              :value="doc.pais"
              readonly
            />
          </v-col>

          <v-col cols="12" sm="2" class="pb-10">
            <v-btn
              fab
              icon
              small
              color="blue"
              @click="visualizarDocumento(doc.passaporte)"
            >
              <v-icon>mdi-eye-outline</v-icon>
            </v-btn>

            <v-btn fab icon small color="red" @click="deletePassaporte(doc.id)">
              <v-icon>mdi-delete</v-icon>
            </v-btn>
          </v-col>
        </v-list-item>
      </v-card>
      <v-card tile class="mx-auto my-6" v-if="docs.identidade">
        <v-list-item-title v-if="docs.identidade.length" class="mx-6">
          <v-icon>mdi-card-account-details</v-icon>
          Carteiras de identidade
        </v-list-item-title>
        <v-list-item
          v-for="(doc, index) in docs.identidade"
          :key="index"
          :id="doc.id"
        >
          <v-col cols="12" sm="4">
            <CopyTextField
              label="N° do RG"
              id="numero-identidade"
              :value="doc.numero"
              readonly
            />
          </v-col>
          <v-col cols="12" sm="3">
            <CopyTextField
              label="Data de Emissão"
              id="emissao"
              :value="doc.data_emissao | convertDataBR"
              readonly
            />
          </v-col>
          <v-col cols="12" sm="3">
            <CopyTextField
              label="Orgão Emissor"
              id="emissor"
              :value="doc.orgao_emissor"
              readonly
            />
          </v-col>
          <v-col cols="12" sm="2" class="pb-10">
            <v-btn
              fab
              icon
              small
              color="blue"
              @click="visualizarDocumento(doc.identidade)"
            >
              <v-icon>mdi-eye-outline</v-icon>
            </v-btn>
            <v-btn fab icon small color="red" @click="deleteIdentidade(doc.id)">
              <v-icon>mdi-delete</v-icon>
            </v-btn>
          </v-col>
        </v-list-item>
      </v-card>
      <v-card tile class="mx-auto my-6" v-if="docs.cnh">
        <v-list-item-title v-if="docs.cnh.length" class="mx-6">
          <v-icon>mdi-card-account-details</v-icon>
          CNH
        </v-list-item-title>
        <v-list-item v-for="(doc, index) in docs.cnh" :key="index" :id="doc.id">
          <v-col cols="12" sm="4">
            <CopyTextField
              label="N° CNH"
              id="numero-cnh"
              :value="doc.numero"
              readonly
            />
          </v-col>
          <v-col cols="12" sm="3">
            <CopyTextField
              label="Data de Validade"
              id="validade"
              :value="doc.data_validade | convertDataBR"
              readonly
            />
          </v-col>
          <v-col cols="12" sm="3">
            <CopyTextField
              label="Categoria"
              id="categoria"
              :value="doc.categoria"
              readonly
            />
          </v-col>
          <v-col cols="12" sm="2" class="pb-10">
            <v-btn
              fab
              icon
              small
              color="blue"
              @click="visualizarDocumento(doc.cnh)"
            >
              <v-icon>mdi-eye-outline</v-icon>
            </v-btn>
            <v-btn fab icon small color="red" @click="deleteCNH(doc.id)">
              <v-icon>mdi-delete</v-icon>
            </v-btn>
          </v-col>
        </v-list-item>
      </v-card>
    </v-form>
  </v-container>
</template>

<script>
import FormDatePicker from "@/components/widgets/FormDatePicker.vue";
import CopyTextField from "@/components/widgets/CopyTextField.vue";
import { getAllFields } from "@/helpers/forms";
import api from "@/api/api";
import skylerDate from "@/helpers/Date/date";

export default {
  components: { CopyTextField, FormDatePicker },

  data() {
    return {
      docs: {
        passaporte: [],
        identidade: [],
        cnh: [],
      },

      dialog: {
        passaporte: false,
        identidade: false,
        cnh: false,
      },

      loadedFile: null,
      loadedFileIdentidade: null,
      loadedFileCNH: null,

      categorias: ["A", "B", "C", "D", "E"],
      paises: [
        "Brasil",
        "Alemanha",
        "Itália",
        "Austrália",
        "Portugal",
        "EUA",
        "França",
      ],

      pais_emissor: "Brasil",
      categoriacnhModel: "A",
      validadeModel: null,
      emissaoModel: null,
      emissaoidentidadeModel: null,
      orgao_emissor: null,
      validadecnhModel: null,

      loadingAdicionarPassaporte: false,
      loadingAdicionarIdentidade: false,
      loadingAdicionarCNH: false,
    };
  },

  methods: {
    // Passaporte
    savePassaporte() {
      this.dialog.passaporte = false;
    },

    deletePassaporte(passaporte_id) {
      if (!confirm("Realmente deseja deletar esse passaporte?")) return;

      api
        .deleteContatoPassaporte(this.contato.id, { id: passaporte_id })
        .then((response) => {
          this.docs.passaporte = this.docs.passaporte.filter(
            (doc) => doc.id != passaporte_id
          );
          this.dialog.passaporte = false;
          this.$root.vtoast.show({
            message: "Passaporte deletado!",
            color: "success",
            icon: "mdi-delete",
          });
        })
        .catch((e) => {
          console.error(e);
          this.$root.vtoast.show({
            message: "Erro ao deletar passaporte",
            color: "error",
            icon: "mdi-close",
          });
        });
    },

    // Identidade
    saveIdentidade() {
      this.dialog.identidade = false;
    },

    deleteIdentidade(identidade_id) {
      if (!confirm("Realmente deseja deletar esse RG?")) {
        return;
      }
      api
        .deleteContatoIdentidade(this.contato.id, { id: identidade_id })
        .then((response) => {
          this.docs.identidade = this.docs.identidade.filter(
            (doc) => doc.id != identidade_id
          );
          this.dialog.identidade = false;
          this.$root.vtoast.show({
            message: "RG deletado!",
            color: "success",
            icon: "mdi-delete",
          });
        })
        .catch((e) => {
          console.error(e);
          this.$root.vtoast.show({
            message: "Erro ao deletar RG",
            color: "error",
            icon: "mdi-close",
          });
        });
    },

    saveCNH() {
      this.dialog.cnh = false;
    },

    visualizarDocumento(documento) {
      window.open(documento);
    },

    deleteCNH(cnh_id) {
      if (!confirm("Realmente deseja deletar esta CNH?")) {
        return;
      }
      api
        .deleteContatoCNH(this.contato.id, { id: cnh_id })
        .then((response) => {
          this.docs.cnh = this.docs.cnh.filter((doc) => doc.id != cnh_id);
          this.dialog.cnh = false;
          this.$root.vtoast.show({
            message: "CNH deletada!",
            color: "success",
            icon: "mdi-delete",
          });
        })
        .catch((e) => {
          console.error(e);
          this.$root.vtoast.show({
            message: "Erro ao deletar CNH",
            color: "error",
            icon: "mdi-close",
          });
        });
    },

    carregarDocumentos() {
      api
        .getContatoPassaporte(this.contato.id)
        .then((response) => (this.docs.passaporte = response.data));
      api
        .getContatoIdentidade(this.contato.id)
        .then((response) => (this.docs.identidade = response.data));
      api
        .getContatoCNH(this.contato.id)
        .then((response) => (this.docs.cnh = response.data));
    },

    carregarListaPaises() {
      fetch("https://servicodados.ibge.gov.br/api/v1/paises/")
        .then((response) => response.json())
        .then(
          (data) => (this.paises = data.map((pais) => pais.nome.abreviado))
        );
    },

    initialize() {
      this.carregarDocumentos();
      this.carregarListaPaises();
    },
  },

  computed: {
    contato() {
      return this.$store.getters["contato/contato"];
    },
  },

  created() {
    if (this.contato) this.initialize();

    const unwatch = this.$watch("contato", (newValue = [], oldValue = []) => {
      this.initialize();
      unwatch();
    });
  },
};
</script>