<template>
  <v-container fluid>
    <div v-if="loading">
      Loading...
    </div>
    <div v-else>
      <v-container fluid>
        <v-row>
          <v-col>
            <venda-emissao-list :emissoes="emissoes"/>
          </v-col>
        </v-row>
      </v-container>
    </div>
  </v-container>
</template>

<script>

import VendaEmissaoList from "@/components/venda/VendaEmissaoList";

export default {
  components: {VendaEmissaoList},
  data() {
    return {
      loading: false
    }
  },
  computed: {
    emissoes() {
      return this.$store.state.venda.emissoes;
    }
  },
  async mounted() {
    await this.initialize();
  },
  methods: {
    async initialize() {
      this.loading = true;
      await this.$store.dispatch('venda/fetchEmissoes');
      this.loading = false;
    }
  }
}

</script>
