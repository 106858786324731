<template>
  <v-container fluid>
    <v-row>
      <v-col cols="11">
        <v-container fluid>
          <v-row>
            <v-col>
              <div v-for="(perna, index) in pernasFullList" :key="index">
                <v-row v-if="!perna.removed">
                  <voo-edit-form-viagem-perna v-model="perna.perna" :ref="'perna'+index" :numPernasValidas="pernasFullList.length" :ordem="index">
                    <template v-slot:actions>
                      <v-btn
                          color="orange"
                          icon
                          @click="removePerna(perna)"
                      >
                        <v-icon>mdi-close</v-icon>
                      </v-btn>
                    </template>
                  </voo-edit-form-viagem-perna>
                </v-row>
              </div>
            </v-col>
          </v-row>

          <v-row>
            <v-col cols="12" class="text-center">
              <v-btn
                  small
                  color="orange"
                  @click="addPerna"
              >
                <v-icon
                    small
                    color="black">
                  mdi-plus
                </v-icon>
                Adicionar conexão
              </v-btn>
            </v-col>
          </v-row>
        </v-container>
      </v-col>
      <!--      Menu -> classe, adultos, crianças  -->
      <v-col cols="1" align-self="center">
        <slot name="actions">
          Action space
        </slot>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import VooEditFormViagemPerna from "@/components/Voo/VooEditFormViagemPerna";

export default {
  components: {VooEditFormViagemPerna},
  props: {
    value: {
      type: Object,
      required: false,
      default: () => {
      }
    }
  },
  data() {
    return {
      form: {},
      pernasFullList: [],
    }
  },
  computed: {
    pernas() {
      return this.pernasFullList.filter(perna => !perna.removed).map(perna => perna.perna);
    }
  },
  created() {
    this.initialize();
  },
  watch: {
    pernasFullList: {
      immediate: true,
      deep: true,
      handler: function (val) {
        this.input();
      },
    }
  },
  methods: {
    initialize() {
      // defaultTrechoForm:
      this.form = {
        origem: null,
        destino: null,
        tipo_trecho: "",
        ordem: 0,
      };
      if (!this.value) {
        return;
      }
      this.form = {
        ...this.form,
        ...this.value
      };
      const originalPernas = this.value.pernas;
      if (originalPernas) {
        this.pernasFullList = originalPernas.map((item) => {
          return {
            perna: {...item},
            removed: false
          };
        });
      }
      if (this.pernasFullList.length === 0) {
        this.addPerna();
      }
    },
    addPerna() {
      const defaultPerna = this.value?.pernas ? this.value?.pernas[0] : {};
      const perna = {
        removed: false,
        perna: {
          ...defaultPerna,
          ordem: this.pernasFullList.length,
        }
      }
      this.pernasFullList.push(perna);
    },
    removePerna(perna) {
      let index = this.pernasFullList.indexOf(perna)
      this.pernasFullList[index].removed = true;
    },
    input(val) {
      
      // envia a viagem e apenas os trechos não removidos
      const form = {
        ...this.form,
        pernas: this.pernas
      };
      this.$emit('input', form);
    }
  }
}
</script>
