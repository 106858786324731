<template>
  <v-card>
    <v-card-title>Serviços</v-card-title>

    <v-card-text>
      <v-data-table
        :items="servicos"
        :loading="loading"
        :headers="headers"
        :hide-default-footer="true"
        sort-by="id"
        :items-per-page="15"
        sort-desc
      >
        <template v-slot:top>
          <v-container fluid>
            <v-row>
              <v-col cols="12" md="2">
                <v-select
                  clear-icon="mdi-close"
                  clearable
                  outlined
                  v-model="filter.status"
                  label="Status"
                  :items="tiposStatusServico"
                  item-text="value"
                  item-value="key"
                  dense
                  v-show="showFiltro"
                >
                </v-select>
              </v-col>
              <v-col cols="12" md="2">
                <v-autocomplete
                  clear-icon="mdi-close"
                  clearable
                  outlined
                  v-model="filter.consultor"
                  label="Consultor"
                  :item-text="(item) => `${item.nome} ${item.sobrenome}`"
                  :item-value="(item) => item.id"
                  :items="users"
                  dense
                >
                </v-autocomplete>
              </v-col>
              <v-row>
                <v-col cols="4" md="2">
                  <FormDatePicker
                    v-model="filter.data_inicio"
                    outlined
                    :dense="false"
                    label="Data Inicio"
                    name="data_inicio"
                    @clear="() => filter.data_inicio = null"
                  />
                </v-col>
                <v-col cols="auto" class="mt-4">
                  <span>até</span>
                </v-col>
                <v-col cols="4" md="2">
                  <FormDatePicker
                    v-model="filter.data_fim"
                    outlined
                    :dense="false"
                    label="Data Fim"
                    name="data_fim"
                    @clear="() => filter.data_fim = null"
                  />
                </v-col>
              </v-row>
            </v-row>
          </v-container>
        </template>
        <template v-slot:item.status="{ item }">
          {{ item.status | formatStatusServico }}
        </template>

        <template v-slot:item.seguiu_manual_boas_praticas="{ item }">
          {{ item.seguiu_manual_boas_praticas ? "Sim" : "Não" }}
        </template>

        <template v-slot:item.created_by="{ item }">
          {{ item.created_by | nomeSobrenome }}
        </template>

        <template v-slot:item.created_at="{ item }">
          {{ item.created_at | convertDataBR }}
        </template>

        <template v-slot:item.venda.consultor="{ item }">
          {{ item.venda.consultor | nomeSobrenome }}
        </template>

        <template v-slot:item.cotacao.fornecedor="{ item }">
          {{
            encontrarFornecedor(item.cotacao)
              ? encontrarFornecedor(item.cotacao)
              : "-"
          }}
        </template>

        <template v-slot:item.cancelado_por="{ item }">
          {{ item.cancelado_por | nomeSobrenome }}
        </template>

        <template v-slot:item.venda="{ item }">
          <v-link-text-field
            v-if="showVenda"
            :to="{ name: 'venda-info', params: { id: item.venda?.id } }"
          >
            #{{ item.venda?.id | formatVendaId }}
          </v-link-text-field>
        </template>
        <template v-slot:item.valor_pre_desconto="{ item }">
          {{ cotacaoValorTotalJuros(item.cotacao) | dinheiroReal }}
        </template>
        <template v-slot:item.valor_desconto="{ item }">
          {{ converteValorFinanceiroBRL(item.desconto) | dinheiroReal }}
        </template>
        <template v-slot:item.valor_total="{ item }">
          {{ servicoValorTotal(item) | dinheiroReal }}
        </template>

        <template v-slot:item.cotacao="{ item }">
          {{ calcularRAV(item.cotacao) | dinheiroReal }}
        </template>

        <template v-slot:item.metodo_pagamento="{ item }">
          {{ item.metodo_pagamento }}
        </template>
        <template v-slot:item.emissao="{ item }">
          <v-link-text-field
            v-for="emissao in item.emissoes"
            :key="emissao.id"
            :to="{ name: 'emissao', params: { id: emissao?.id } }"
          >
            #{{ emissao.id | formatId }}
          </v-link-text-field>
        </template>
        <template v-slot:item.actions="{ item }">
          <v-icon color="primary" class="mr-2" @click="showDescricao(item)">
            mdi-magnify
          </v-icon>
        </template>
      </v-data-table>
      <v-row class="mt-3">
        <v-col cols="2" />
        <v-col cols="8">
          <div class="right" style="font-weight: bold">
            Valor total dos serviços = {{ valorTotalServicos | dinheiroReal }}
          </div>
        </v-col>
        <v-col cols="2" />
      </v-row>
      <v-pagination v-model="currentPage" :length="pages" :total-visible="7" />
    </v-card-text>
    <v-dialog v-model="dialogDescricao">
      <servico-descricao
        :servico="selectedServico"
        :milhasValidadas="selectedServicoMilhasValidadas"
        :desagioProprio="desagioProprio"
        @close="dialogDescricao = false"
        :key="newServicoKey"
      ></servico-descricao>
    </v-dialog>
  </v-card>
</template>
<script>
import CotacaoMixin from "@/mixins/CotacaoMixin";
import VLinkTextField from "@/components/widgets/VLinkTextField";
import statusServico from "@/constants/statusServico";
import ServicoDescricao from "@/components/venda/validacao/VendaServicosDescricao.vue";
import FormDatePickerMultiple from "@/components/widgets/FormDatePickerMultiple.vue";
import FormDatePicker from "@/components/widgets/FormDatePicker.vue";
import {formatDates} from "@/helpers/dates"



import api from "@/api/api";

export default {
  components: {
    VLinkTextField,
    ServicoDescricao,
    FormDatePickerMultiple,
    FormDatePicker,
  },
  props: {
    servicos: {
      type: Array,
      default: [],
    },
    loading: {
      type: Boolean,
      default: false,
    },
    showVenda: {
      type: Boolean,
      default: false,
    },
    pages: {
      type: Number,
      default: 1,
    },
    showFiltro: {
      type: Boolean,
      default: true,
    },
    emVenda: {
      type: Boolean,
      default: false,
    },
  },
  mixins: [CotacaoMixin],
  data: () => ({
    headers: [
      { text: "Servico ID", value: "id" },
      { text: "Seguiu boas práticas", value: "seguiu_manual_boas_praticas" },
      { text: "Criado por", value: "created_by" },
      { text: "Criado em", value: "created_at" },
      { text: "Consultor", value: "venda.consultor" },
      { text: "Fornecedor", value: "cotacao.fornecedor" },
      { text: "Cotacao ID", value: "cotacao.id" },
      { text: "Emissão ID", value: "emissao" },
      { text: "Status", value: "status" },
      { text: "Motivo de Cancelamento", value: "motivo_cancelamento" },
      { text: "Cancelado por", value: "cancelado_por" },
      // { text: "Valor pré Desconto", value: "valor_pre_desconto" },
      // { text: "Valor Desconto", value: "valor_desconto" },
      { text: "Valor Total", value: "valor_total" },
      { text: "RAV", value: "cotacao" },
      { text: "Método de Pagamento", value: "metodo_pagamento" },
      { text: "Actions", value: "actions" },
    ],
    currentPage: 1,
    filter: {
      status: "",
      data_inicio: '',
      data_fim: '',
      data_servico: "",
      consultor: "",
    },
    tiposStatusServico: statusServico,
    dialogDescricao: null,
    selectedServico: null,
    selectedServicoMilhasValidadas: null,
    loadingDescricao: false,
    desagioProprio: 0,
    newServicoKey: 0,
    valorTotalServicos: 0,
    timer: undefined,
  }),

  methods: {
    encontrarFornecedor(cotacao) {
      if (this.fornecedores && this.fornecedores.length > 0) {
        if (cotacao.cotacao_voo) {
          // return this.fornecedores?.find((fornecedor) => fornecedor.id === cotacao.cotacao_voo.subcotacoes[0].fornecedor)?.nome;
          return cotacao.cotacao_voo.subcotacoes[0].fornecedor;
        } else if (cotacao.cotacao_carro) {
          // return this.fornecedores?.find((fornecedor) => fornecedor.id === cotacao.cotacao_carro.fornecedor)?.nome;
          return cotacao.cotacao_carro.fornecedor;
        } else if (cotacao.cotacao_hotel) {
          // return this.fornecedores?.find((fornecedor) => fornecedor.id === cotacao.cotacao_hotel.fornecedor)?.nome;
          return cotacao.cotacao_hotel.fornecedor;
        } else if (cotacao.cotacao_outro) {
          // return this.fornecedores?.find((fornecedor) => fornecedor.id === cotacao.cotacao_outro.fornecedor)?.nome;
          return cotacao.cotacao_outro.fornecedor;
        }
      } else return "-";
    },

    calcularRAV(cotacao) {
      if (cotacao.cotacao_voo) {
        return cotacao.cotacao_voo.subcotacoes[0].rav?.valor;
      } else if (cotacao.cotacao_carro) {
        return cotacao.cotacao_carro.rav?.valor;
      } else if (cotacao.cotacao_hotel) {
        return cotacao.cotacao_hotel.rav?.valor;
      } else if (cotacao.cotacao_outro) {
        return cotacao.cotacao_outro.rav?.valor;
      }
    },

    exibirFornecedor(cotacao) {
      // let copyCotacao = JSON.parse(JSON.stringify(cotacao));
      if (cotacao.cotacao_voo) {
        cotacao.fornecedor = cotacao.cotacao_voo.subcotacoes[0].fornecedor;
      } else if (cotacao.cotacao_carro) {
        cotacao.fornecedor = cotacao.cotacao_carro.fornecedor;
      } else if (cotacao.cotacao_hotel) {
        cotacao.fornecedor = cotacao.cotacao_hotel.fornecedor;
      } else if (cotacao.cotacao_outro) {
        cotacao.fornecedor = cotacao.cotacao_outro.fornecedor;
      }
    },

    calcularValorTotalServicos() {
      this.valorTotalServicos = 0;
      for (const servico of this.servicos) {
        this.exibirFornecedor(servico.cotacao);
        this.valorTotalServicos =
          this.valorTotalServicos + this.servicoValorTotal(servico);
      }
    },

    changePage() {
      clearTimeout(this.timer);
      let filters = [];

      if (this.filter.consultor)
        filters.push(["consultor", this.filter.consultor]);

      if (this.filter.status) filters.push(["status", this.filter.status]);

      if (this.filter.data_inicio && this.filter.data_fim) {
        let formattedDataInicio = formatDates(this.filter.data_inicio);
        let formattedDataFim = formatDates(this.filter.data_fim);
        filters.push(["data_inicio", formattedDataInicio], ["data_fim", formattedDataFim], ["data_solicitacao", [formattedDataInicio, formattedDataFim]]);
      }
      this.timer = setTimeout(() => {
        this.$emit("getServicos", this.currentPage, filters);
      }, 5000);
      this.calcularValorTotalServicos();
    },

    showDescricao(item) {
      this.selectedServico = item;
      this.loadingDescricao = true;
      api
        .getServicoValidacoes(item.id)
        .then((res) => {
          this.selectedServicoMilhasValidadas = res.data;
        })
        .catch((e) =>
          this.$root.vtoast.show({
            message: "Milhas validadas não encontradas",
            color: "error",
            icon: "mdi-close",
          })
        )
        .finally((this.loadingDescricao = false));
      api
        .getDesagioProprio()
        .then((res) => {
          this.desagioProprio = Number(res.data.desagio_compra);
        })
        .catch((e) =>
          this.$root.vtoast.show({
            message: "Deságio próprio não encontrado",
            color: "error",
            icon: "mdi-close",
          })
        );
      this.refreshServicoDescricao();
      this.dialogDescricao = true;
    },
    refreshServicoDescricao() {
      this.newServicoKey += 1;
    },
  },
  
  async mounted() {
    this.calcularValorTotalServicos();
    await this.$store.dispatch("global/fetchFornecedores");
    await this.$store.dispatch("global/fetchMoedas");
  },

  created() {
    this.$store.dispatch("usuario/fetchUsuarios");
    if (!this.showVenda) return;
    this.headers.splice(2, 0, { text: "Venda ID", value: "venda" });
  },

  computed: {
    fornecedores() {
      return this.$store.getters["global/fornecedores"];
    },
    users() {
      return this.$store.getters["usuario/usuarios"];
    },
  },

  watch: {
    servicos() {
      this.calcularValorTotalServicos();
    },
    currentPage() {
      this.changePage();
    },

    filter: {
      deep: true,
      handler(val) {
        this.currentPage = 1;
        this.changePage();
      },
    },
  },
};
</script>
