<template>
  <v-container class="w-auto" max-width="100%">
    <v-card class="px-4 mx-0">
      <v-card-title class="text-h5"> Status </v-card-title>
      <v-card-text>
        <v-divider></v-divider>
        <v-row>
          <v-select
            label="Escolher novo Status"
            outlined
            :items="statusRac"
            v-model="value"
            item-value="key"
            item-text="value"
        /></v-row>
        <v-card-actions>
          <v-btn text @click="closeDialog">
            <v-icon left>mdi-arrow-left</v-icon>
            Voltar
          </v-btn>
          <v-spacer />
          <v-btn text @click="changeStatus()"> Mudar Status </v-btn>
        </v-card-actions>
      </v-card-text>
    </v-card>
  </v-container>
</template>
<script>
export default {
  data() {
    return {
      loading: false,
      value: null,
    };
  },
  props: {
    dialog: {
      type: Boolean,
      default: false,
    },
    statusRac: {
      type: Array,
      default: () => [],
    },
  },
  methods: {
    closeDialog() {
      this.$emit("closeDialog");
    },
    changeStatus() {
      this.loading = true;
      this.$emit("mudarStatusRac", this.value);
    },
  },
};
</script>