<template>
  <v-container>
    <v-row>
      <v-col>
        <v-text-field v-model="item.nome" label="Nome"></v-text-field>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <v-email v-model="item.email"></v-email>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <TelefoneField v-model="item.telefone" returnRaw />
      </v-col>
    </v-row>
    <small>* Obrigatório pelo menos um CPF ou um CPNJ</small>
    <v-row>
      <v-col>
        <v-text-field
          v-model="item.cnpj"
          label="*CNPJ"
          v-mask="masks.cnpj"
          :rules="[rules.cnpj]"
        ></v-text-field>
      </v-col>
      <v-col>
        <v-text-field
          v-model="item.cpf"
          label="*CPF"
          v-mask="masks.cpf"
          :rules="[rules.cpf]"
        ></v-text-field>
      </v-col>
    </v-row>

    <v-row>
      <v-col>
        <v-select
          :items="MeiosPagamentoFornecedor"
          label="Meios de pagamento"
          item-text="value"
          item-value="key"
          multiple
          chips
          v-model="item.meios_de_pagamento"
        ></v-select>
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
import MASKS from "@/constants/masks";
import MEIOS_DE_PAGAMENTO_FORNECEDOR from "@/constants/meiosPagamentoFornecedor";
import VEmail from "@/components/widgets/VEmail";
import TelefoneField from "../widgets/TelefoneField.vue";

import { validateCPF, validateCNPJ } from "@/helpers/identificadores";

export default {
  components: {
    VEmail,
    TelefoneField,
  },

  props: {
    item: {
      type: Object,
      default: () => {},
    },
  },

  data: () => ({
    masks: MASKS,
    MeiosPagamentoFornecedor: MEIOS_DE_PAGAMENTO_FORNECEDOR,
    rules: {
      cpf: (value) => !value || validateCPF(value) || "CPF não é valido",
      cnpj: (value) => !value || validateCNPJ(value) || "CNPJ não é valido",
    },
  }),
};
</script>
