import { get, post, paginate, del, put, filter, convertToFormData,postFormData } from '../modules'

export default {
    // Pagamentos
    getPagamentos(id) {
        if(!id) return get('/api/pagamentos/')
        
        return get(`/api/vendas/${id}/pagamentos/`);
    },

    getPagamentosAbertos(page, filters=[]){
        const paginatedURL = paginate(`/api/pagamentos/pagamentos_abertos`, page)
        const filteredURL = filter(paginatedURL, filters)
        return get(filteredURL);
    },

    getPagamentosCotacao(id_cotacao) {
        return get(`/api/pagamentos/${id_cotacao}/pagamentos_cotacao/`)
    },
    async postPagamento(data) {
        const pagamento = await post(`/api/pagamentos/`, data)
        const { documento_comprovante } = data
    
        if(documento_comprovante)
            await this.postComprovantePagamento(pagamento.data.id, documento_comprovante)
        return pagamento
        },
    
    async postComprovantePagamento(id, documento_comprovante) {
        const formData = convertToFormData({documento_comprovante: documento_comprovante});
        return postFormData(`/api/pagamentos/${id}/documentos/`, formData);
    },

    async editPagamento(id, data) {
        const pagamento = await put(`/api/pagamentos/${id}/`, data)
        const { documento_comprovante } = data
        if(documento_comprovante)
            await this.postComprovantePagamento(pagamento.data.id, documento_comprovante)
        return pagamento
    },

    getComprovante(id) {
        return get(`/api/pagamentos/${id}/documentos/`);
    },


    deletePagamento(id) {
        return del(`/api/pagamentos/${id}/`)
    },

    logPagamentoTotal(data) {
        return post(`/api/pagamentos/pagamento_total/`, data)
    },

    postCriarPagamentos(data){
        return post(`/api/pagamentos/criar_pagamentos/`, data)
    }

}
