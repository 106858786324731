import LoginView from "@/views/LoginView"
import TermosDeUsoView from "@/views/TermosDeUsoView"
import PoliticaDePrivacidadeView from "@/views/PoliticaDePrivacidadeView"
import SucessMaxipagoView from "@/views/SucessMaxipagoView"
import FailMaxipagoView from "@/views/FailMaxipagoView"
import SubmitMaxipagoView from "@/views/SubmitMaxipagoView"


export default [
    {
        path: "/login",
        name: "login",
        component: LoginView
    },
    {
        path: "/termos-de-uso",
        name: "termos-de-uso",
        component: TermosDeUsoView,
    },
    {
        path: "/politica-de-privacidade",
        name: "politica-de-privacidade",
        component: PoliticaDePrivacidadeView,
    },

    {
        path: "/success-maxipago",
        name: "success-maxipago",
        component: SucessMaxipagoView,
    },
    {
        path: "/fail-maxipago",
        name: "fail-maxipago",
        component: FailMaxipagoView,
    },
    {
        path: "/submit-maxipago",
        name: "submit-maxipago",
        component: SubmitMaxipagoView,
    },
]
