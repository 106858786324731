<template>
  <v-container fluid>
    <v-row>
      <v-col>
        <search-aeroporto
          outlined
          label="Origem"
          v-model="form.origem"
        ></search-aeroporto>
        <small v-if="form.flexibilidade_origem">Flexível</small>
      </v-col>
      <v-col>
        <search-aeroporto
          outlined
          label="Destino"
          v-model="form.destino"
        ></search-aeroporto>
        <small v-if="form.flexibilidade_destino">Flexível</small>
      </v-col>
      <v-col>
        <v-select
          v-model="form.cia_aerea"
          :items="cias"
          label="Cia Aérea"
          item-text="nome"
          :outlined="true"
          dense
          item-value="id"
        ></v-select>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="6" md="6">
        <v-datetime-text-field
          dense
          v-model="form.data_hora_partida"
          label="Data partida"
        />
      </v-col>
      <v-col cols="6" md="6">
        <v-datetime-text-field
          dense
          v-model="form.data_hora_chegada"
          label="Data chegada"
        />
      </v-col>
    </v-row>

    <v-row>
      <!--      Menu -> classe, adultos, crianças  -->
      <v-col cols="6">
        <v-menu
          v-model="showOptionsMenu"
          :close-on-content-click="false"
          :nudge-width="200"
          offset-x
        >
          <template v-slot:activator="{ on, attrs }">
            <v-textarea
              outlined
              v-bind="attrs"
              v-on="on"
              readonly
              no-resize
              rows="3"
              dense
              height="auto"
              :value="getClassePaxLabel"
            >
              <template v-slot:append>
                <v-btn small icon color="primary" v-bind="attrs" v-on="on">
                  <v-icon> mdi-dots-vertical </v-icon>
                </v-btn>
              </template>
            </v-textarea>
          </template>
          <v-card>
            <v-divider></v-divider>
            <v-list>
              <v-list-item>
                <v-list-item-content>
                  <v-list-item-title>Classes</v-list-item-title>
                </v-list-item-content>
              </v-list-item>
              <v-list-item-action>
                <!-- <v-row>
                  <v-col
                    cols="auto"
                    :key="item.value"
                    v-for="item in classes_opcoes"
                  >
                    <v-checkbox
                      dense
                      :label="item.text"
                      v-model="form[item.value]"
                    ></v-checkbox>
                  </v-col>
                </v-row> -->
                <v-row>
                  <v-col
                    cols="auto"
                    :key="item.value"
                    v-for="item in classes_opcoes"
                  >
                    <v-checkbox
                      dense
                      :label="item.text"
                      v-model="form[item.value]"
                      @change="handleCheckboxChange(item.value)"
                    ></v-checkbox>
                  </v-col>
                </v-row>
              </v-list-item-action>
            </v-list>

            <v-divider></v-divider>
            <v-row>
              <v-col>
                <v-list>
                  <v-list-item>
                    <v-list-item-title>Bagagem 10kg</v-list-item-title>
                  </v-list-item>
                  <v-list-item>
                    <v-list-item-action>
                      <v-text-field
                        dense
                        type="number"
                        v-model="form.bagagem_10kg"
                        @input="validateBaggage10"
                      />
                      <v-alert v-if="erroClasse" dense type="error">
                        Selecione apenas uma classe
                      </v-alert>
                    </v-list-item-action>
                  </v-list-item>
                </v-list>
              </v-col>
              <v-col>
                <v-list>
                  <v-list-item>
                    <v-list-item-title>Bagagem 23kg</v-list-item-title>
                  </v-list-item>
                  <v-list-item>
                    <v-list-item-action>
                      <v-text-field
                        dense
                        type="number"
                        v-model="form.bagagem_23kg"
                        @input="validateBaggage23"
                      />
                      <v-alert v-if="error10" dense type="error">
                        O valor deve estar entre 0 e 5.
                      </v-alert>
                    </v-list-item-action>
                  </v-list-item>
                </v-list>
              </v-col>
            </v-row>
            <!-- <v-divider></v-divider>
            <v-list>
              <v-list-item>
                <v-list-item-title>Bagagens</v-list-item-title>
              </v-list-item>
              <v-list-item>
                <v-list-item-action>
                  <v-text-field dense type="number" v-model="form.bagagens" />
                </v-list-item-action>
              </v-list-item>
            </v-list> -->
            <v-divider></v-divider>
            <v-list>
              <v-list-item>
                <v-list-item-content>
                  <v-list-item-title>
                    Quantidade de passageiros
                  </v-list-item-title>
                </v-list-item-content>
              </v-list-item>
              <v-list-item>
                <v-list-item-action>
                  <v-text-field
                    dense
                    label="Adultos"
                    disabled
                    v-model="form.adultos"
                  >
                  </v-text-field>
                </v-list-item-action>
                <v-list-item-title>Mais que 12 anos</v-list-item-title>
              </v-list-item>
              <v-list-item>
                <v-list-item-action>
                  <v-text-field
                    dense
                    label="Crianças"
                    disabled
                    v-model="form.criancas"
                  >
                  </v-text-field>
                </v-list-item-action>
                <v-list-item-title>2-11 anos</v-list-item-title>
              </v-list-item>
              <v-list-item>
                <v-list-item-action>
                  <v-text-field
                    dense
                    label="Bebês"
                    disabled
                    v-model="form.bebes"
                  >
                  </v-text-field>
                </v-list-item-action>
                <v-list-item-title>0-2 anos</v-list-item-title>
              </v-list-item>
            </v-list>
          </v-card>
        </v-menu>
        <v-alert v-if="erroClasse" dense type="error">
          Selecione apenas uma classe
        </v-alert>
      </v-col>

      <v-col cols="1">
        <slot name="actions"> </slot>
      </v-col>
    </v-row>
    <v-row class="my-n6 py-0">
      <v-col cols="6">
        <!-- Label mudado -->
        <v-select
          v-if="numPernasValidas > 1 && ordem !== numPernasValidas - 1"
          outlined
          dense
          label="Tipo de conexão"
          :items="tipos_perna"
          item-text="value"
          item-value="key"
          v-model="form.tipo_perna"
        ></v-select>
      </v-col>
    </v-row>
    <v-divider></v-divider>
  </v-container>
</template>

<script>
import SearchAeroporto from "@/components/widgets/SearchAeroporto";
import VDatetimeTextField from "@/components/widgets/VDatetimeTextField";
import TIPOS_PERNA from "@/constants/pernas";

export default {
  components: { VDatetimeTextField, SearchAeroporto },
  props: {
    value: {
      type: Object,
      required: false,
      default: () => {},
    },

    numPernasValidas: {
      type: Number,
    },

    ordem: {
      type: Number,
    },

    tipo_trecho: {
      type: String,
      required: false,
      default: "ida_volta",
    },
  },
  data() {
    return {
      form: {},
      cias: [],
      tipos_perna: TIPOS_PERNA,
      showOptionsMenu: false,
      erroClasse: false,
      error10: false,
      error23: false,
      classes_opcoes: [
        {
          text: "Econômica",
          value: "economica",
        },
        {
          text: "Econômica Premium",
          value: "economica_premium",
        },
        {
          text: "Executiva",
          value: "executiva",
        },
        {
          text: "1a Classe",
          value: "primeira_classe",
        },
      ],
      isDataTimeValid: false,
    };
  },
  watch: {
    form: {
      immediate: true,
      deep: true,
      handler: function (val) {
        this.input();
      },
    },
  },
  async mounted() {
    this.cias = this.$store.state.global.ciaAereas;
  },
  computed: {
    getClassePaxLabel() {
      if (!this.form.classe) {
        return "";
      }
      const classes_str = this.classes_opcoes
        .filter((item) => this.form[item.value])
        .map((item) => item.text);

      if (classes_str.length > 1) {
        this.erroClasse = true;
        return;
      } else {
        this.erroClasse = false;
      }

      let arr_str = [];
      const totalPax =
        parseInt(this.form.adultos) + parseInt(this.form.criancas);
      arr_str.push(
        `${totalPax} passageiro${this.$options.filters.pluralize(totalPax)}`
      );

      arr_str.push(classes_str);
      if (this.form.bagagens) arr_str.push(`${this.form.bagagens} bagagem(s)`);
      if (this.form.bagagem_10kg)
        arr_str.push(`${this.form.bagagem_10kg} bagagem(s) de 10kg`);
      if (this.form.bagagem_23kg)
        arr_str.push(`${this.form.bagagem_23kg} bagagem(s) de 23kg`);
      return arr_str.join("\n");
    },
  },
  created() {
    const defaultForm = {
      classe: "economica",
      ordem: 0,
      adultos: 1,
      criancas: 0,
      bebes: 0,
      origem: null,
      destino: null,
      cia_aerea: null,
      data_hora_partida: null,
      data_hora_chegada: null,
    };
    this.form = { ...defaultForm };
    if (this.value) {
      this.form = {
        ...this.form,
        ...this.value,
      };
      if (this.value.cia_aerea)
        this.form = {
          ...this.form,
          cia_aerea:
            typeof this.value.cia_aerea === "object"
              ? this.value.cia_aerea.id
              : this.value.cia_aerea,
        };
    }
    this.input();
  },
  methods: {
    input(val) {
      if (!this.form) return;
      const completeForm = {
        ...this.form,
      };
      this.$emit("input", completeForm);
    },
    handleCheckboxChange(selectedCheckbox) {
      for (const prop in this.form) {
        if (
          this.form.hasOwnProperty(prop) &&
          prop !== selectedCheckbox &&
          typeof this.form[prop] === "boolean" &&
          this.form[prop]
        ) {
          this.form[prop] = false;
        }
      }
    },
    validateBaggage23() {
      if (this.form.bagagem_23kg < 0) {
        this.form.bagagem_23kg = 0;
        this.error23 = true;
      } else if (this.form.bagagem_23kg > 5) {
        this.form.bagagem_23kg = 5;
        this.error23 = true;
      } else {
        this.error23 = false;
      }
    },
    validateBaggage10() {
      if (this.form.bagagem_10kg < 0) {
        this.form.bagagem_10kg = 0;
        this.error10 = true;
      } else if (this.form.bagagem_10kg > 5) {
        this.form.bagagem_10kg = 5;
        this.error10 = true;
      } else {
        this.error10 = false;
      }
    },
  },
};
</script>
