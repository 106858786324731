<template>
  <v-dialog v-model="imagesDialog" max-width="600px">
    <template v-slot:activator="{ on, attrs }">
      <v-btn
        color="primary"
        dark
        v-bind="attrs"
        v-on="on"
        :style="{ margin: '5px' }"
      >
        <v-icon left>mdi-image</v-icon>
        {{ buttonLabel }}
      </v-btn>
    </template>
    <v-card>
      <v-card-title>
        <span> Selecione até 4 imagens </span>
      </v-card-title>
      <v-card-text>
        <v-row>
          <v-col
            v-for="(image, i) in images"
            :key="i"
            class="d-flex child-flex"
            cols="4"
          >
            <v-card>
              <v-card-text>
                {{ image.titulo }}
              </v-card-text>
              <v-img
                :src="image.imagem"
                aspect-ratio="1"
                :style="isSelected(image.id) ? 'opacity: 1' : 'opacity: 0.2'"
                @click="selectImage(image.id)"
              >
              </v-img>
            </v-card>
          </v-col>
          <v-col>
            <div v-if="images.length == 0">
              Nenhuma imagem foi encontrada para esse provedor.
            </div>
          </v-col>
        </v-row>
      </v-card-text>
      <v-card-actions>
        <v-spacer />
        <v-btn text @click="imagesDialog = false"> Confirmar </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import api from "@/api/api";

export default {
  props: {
    value: {
      type: null,
      default: null,
    },

    provedor: {
      type: [Number],
    },

    buttonLabel: {
      type: String,
      default: "Imagens do Provedor",
    },
  },
  data() {
    return {
      imagesDialog: false,
      images: [],
      selectedImages: [],
    };
  },

  watch: {
    provedor: {
      immediate: true,
      handler() {
        if (this.provedor) this.getImages();

        if (!this.value) return;
        this.selectedImages = this.value;
      },
    },
  },

  methods: {
    selectImage(imageId) {
      const imageIndex = this.selectedImages.indexOf(imageId);

      if (imageIndex > -1) return this.selectedImages.splice(imageIndex, 1);

      if (this.selectedImages.length == 4)
        return this.$root.vtoast.show({
          message: "Limite de 4 imagens atingido!",
          color: "orange",
          icon: "mdi-alert",
        });

      this.selectedImages.push(imageId);

      this.$emit("input", this.selectedImages);
    },

    getImages() {
      api.getProvedorImages(this.provedor).then((response) => {
        this.images = response.data;
      });
    },

    isSelected(id) {
      return this.selectedImages.indexOf(id) > -1;
    },
  },
};
</script>
