<template>
  <v-container fluid>
    <v-row>
      Usuário vendas:
    </v-row>
    <v-row>
      <usuario-vendas-list></usuario-vendas-list>
    </v-row>

  </v-container>
</template>
<script>
import UsuarioVendasList from "@/components/usuario/UsuarioVendasList";
export default {
  components: {UsuarioVendasList}
}
</script>
