<template>
  <v-container fluid v-if="emissao">
    <v-row>
      <v-col cols="12">
        <v-tabs>
          <v-tabs-slider color="primary"></v-tabs-slider>
          <v-tab>
            <v-icon class="pr-2">mdi-information-outline</v-icon>
            Geral
          </v-tab>
          <v-tab>
            <v-icon class="pr-2">mdi-account-outline</v-icon>
            Beneficiários
          </v-tab>
          <v-tab>
            <v-icon class="pr-2">mdi-file-document-outline</v-icon>
            Vouchers e demais documentos
          </v-tab>
          <v-tab>
            <v-icon className="pr-2">mdi-currency-usd</v-icon>
            Pagamentos
          </v-tab>

          <v-tab-item class="pa-4">
            <emissao-detalhe-tab-geral :emissao="emissao" />
          </v-tab-item>
          <v-tab-item>
            <emissao-detalhe-tab-beneficiarios :emissao="emissao" />
          </v-tab-item>
          <v-tab-item>
            <EmissaoDetalheTabDocumentos :emissao="emissao" />
          </v-tab-item>
          <v-tab-item>
            <emissao-detalhe-tab-pagamento :emissao="emissao" />
          </v-tab-item>
        </v-tabs>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import EmissaoDetalheTabGeral from "./Tabs/EmissaoDetalheTabGeral";
import EmissaoDetalheTabBeneficiarios from "./Tabs/EmissaoDetalheTabBeneficiarios";
import EmissaoDetalheTabDocumentos from "./Tabs/EmissaoDetalheTabDocumentos";
import EmissaoDetalheTabPagamento from "./Tabs/EmissaoDetalheTabPagamento";

export default {
  components: {
    EmissaoDetalheTabBeneficiarios,
    EmissaoDetalheTabGeral,
    EmissaoDetalheTabDocumentos,
    EmissaoDetalheTabPagamento,
  },
  props: {
    emissao: {
      type: Object,
      default: null,
    },
  },
};
</script>
