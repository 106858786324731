import { postDataBuscaAPIContato, postDataBuscaAPIEmpresa } from '../modules'

export default {
    postDataBuscaAPIContato(contato_id, cpf) {
        return postDataBuscaAPIContato(contato_id, cpf);
    },

    postDataBuscaAPIEmpresa(empresa_id, cnpj) {
        return postDataBuscaAPIEmpresa(empresa_id, cnpj);
    },
}
