import Vue from "vue"
import { formatDistanceStrict } from "date-fns";
import moment from 'moment';

function convertDataBR(value) {
    if (!value) return "";
    // create a moment object from the input string
    const dateObject = moment.utc(value);
    if(!dateObject.isValid()) return "";
    // Format the date using the 'pt-BR' locale
    const dateFormatted = dateObject.locale('pt-BR').format('DD/MM/YYYY');

    return dateFormatted;
}

function dataHoraMinuto(value) {
    if (!value)
        return "";
    // create a moment object from the input string
    const dateObject = moment(value);
    // Format the time using the 'en-ca' locale
    const timeFormatted = dateObject.locale('pt-br').format("HH:mm");
    return timeFormatted;
}

function dataHoraBR(value){
    if(!value) return ""
    return `${convertDataBR(value)} - ${dataHoraMinuto(value)}`
}

function diasAtras(value){
    if (!value) return "";
    const startDate = moment(value).startOf('day');
    const current = moment().startOf('day');

    const diffDays = moment.duration(current.diff(startDate)).asDays()

    return diffDays
}

function checkDataEntreDatas(data, inicio, fim){
    const dataSelecionada = moment(data, "DD/MM/YYYY")
    const dataInicio = moment(inicio, "DD/MM/YYYY");
    const dataFim = moment(fim, "DD/MM/YYYY");

    
    const isbetween = dataSelecionada.isBetween(dataInicio, dataFim);
    
    return isbetween
}

function horasAtras(value, data_emissao=null) {
    let data_final = Date.now()
    if(data_emissao) data_final = new Date(data_emissao)

    let hoursAgo = formatDistanceStrict(
        new Date(value),
        data_final,
        { unit: 'hour' }
    )

    if(hoursAgo == "0 hours"){
        hoursAgo = formatDistanceStrict(
            new Date(value),
            data_final,
            { unit: 'minute' }
        )
    }

    return hoursAgo.replace("hours", "horas").replace("minutes", "minutos");
}

Vue.filter('convertDataBR', convertDataBR)
Vue.filter('dataHoraMinuto', dataHoraMinuto)
Vue.filter('diasAtras', diasAtras)
Vue.filter('horasAtras', horasAtras)
Vue.filter('dataHoraBR', dataHoraBR)
Vue.filter("checkDataEntreDatas", checkDataEntreDatas)
