export const selectedPersonalInformation = ["nome", "nome_mae", "data_nascimento", "flags"]
export const selectedProcessInformation = ["quantidade"]
export const selectedRestrictionInformation = ["quantidade"]
export const selectedBankCheckInformation = ["quantidade"]
export const selectedOtherDocumentInformation = ["quantidade"]
export const selectedEmailInformation = ["quantidade"] 
export const selectedProtestInformation = ["quantidade"]
export const selectedPhonesInformation = ["quantidade"]
export const selectedCreditScoreInformation = ["D00", "D30", "D60", "D90"]
export const selectedCompanyInformation = ["nome", "flags"]
export const selectedPartnershipInformation = ["quantidade"]
export const selectedShareholderInformation = ["quantidade"]