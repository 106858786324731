<template>
  <span
    style="border-bottom: 2px dotted #111; cursor: pointer"
    @click="copyLink"
  >
    <slot></slot>
  </span>
</template>

<script>
export default {
  props: ["value"],
  methods: {
    copyLink() {
      if (!this.value) return;
      navigator.clipboard.writeText(this.value);

      this.$root.vtoast.show({
        message: "Copiado para área de transferência com sucesso",
      });
    },
  },
};
</script>
