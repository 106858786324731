import user from './routes/user';
import contato from './routes/contato';
import empresa from './routes/empresa';
import fidelidade from './routes/fidelidade';
import cotacao from './routes/cotacao';
import venda from './routes/venda';
import pagamento from './routes/pagamento'
import carrinho from './routes/carrinho'
import servico from "./routes/servico";
import emissao from "./routes/emissao";
import rac from "./routes/rac";
import chat from "./routes/chat";
import financeiro from './routes/financeiro';
import databusca from './routes/databusca';
import fornecedor from './routes/fornecedor';
import logs from './routes/logs'
import milhas from './routes/milhas';
import locais from './routes/locais';
import orcamento from './routes/orcamento';
import cartao from './routes/cartao';
import subcotacao from './routes/subcotacao';
import notificacoes from './routes/notificacoes';
import moblix from './routes/moblix'
import pnr from './routes/pnr'
import ordemPagamento from './routes/ordemPagamento';
import twilio from './routes/twilio';

export default {
    ...user,
    ...contato,
    ...empresa,
    ...fidelidade,
    ...cotacao,
    ...venda,
    ...pagamento,
    ...carrinho,
    ...servico,
    ...emissao,
    ...rac,
    ...chat,
    ...financeiro,
    ...databusca,
    ...fornecedor,
    ...logs,
    ...milhas,
    ...locais,
    ...orcamento,
    ...cartao,
    ...subcotacao,
    ...notificacoes,
    ...moblix,
    ...pnr,
    ...ordemPagamento,
    ...twilio
};
