<template>
  <v-dialog v-model="pagamentoDialog" persistent>
    <v-card class="px-8 pb-8 pt-4">
      <div
        v-for="cotacao in cotacoes"
        :key="cotacao.id"
        v-if="informacoesPagamento"
        grid-list-mb
      >
        Cotacão #{{ cotacao.id }} -
        {{ cotacaoValorTotalJuros(cotacao) | dinheiroReal }}
        <v-radio-group
          v-model="informacoesPagamento[cotacao.id].tiposPagamento"
          row
        >
          <v-radio label="Pagamento à vista" value="pagamentoAVista"></v-radio>
          <v-radio
            label="Pagamento parcelado com entrada"
            value="pagamentoParceladoEntrada"
          ></v-radio>
          <v-radio
            label="Pagamento parcelado"
            value="pagamentoParcelado"
          ></v-radio>
          <v-radio
            label="Cartão do Cliente no Portal do Fornecedor"
            value="pagamentoAVistaPortalFornecedor"
          >
          </v-radio>
        </v-radio-group>
        <v-row
          v-if="
            informacoesPagamento[cotacao.id].tiposPagamento ===
            'pagamentoAVistaPortalFornecedor'
          "
          class="d-flex flex-column pb-4"
        >
          <h4>Pagamento via Cartão do Cliente no Portal do Fornecedor</h4>
          Valor mínimo a ser cobrado:
          {{ valorMinimoASerCobrado(cotacao) | dinheiroReal }}
          <v-moeda-field
            outlined
            @input="preencherDesconto(informacoesPagamento, cotacao)"
            label="Valor a ser cobrado do cliente"
            v-model="
              informacoesPagamento[cotacao.id].pagamentoAVistaPortalFornecedor
                .valorTotal
            "
            :disabledEscolhaMoeda="true"
          />

          <h5>
            Desconto Resultante:
            {{
              descontosAtuais[cotacao.id].pagamentoAVistaPortalFornecedor
                ?.desconto?.valor | dinheiroReal
            }}
          </h5>
        </v-row>
        <v-row
          v-if="
            informacoesPagamento[cotacao.id].tiposPagamento ===
            'pagamentoAVista'
          "
          class="d-flex flex-column pb-4"
        >
          <h4>Pagamento à vista</h4>
          Valor mínimo a ser cobrado:
          {{ valorMinimoASerCobrado(cotacao) | dinheiroReal }}
          <v-moeda-field
            outlined
            @input="preencherDesconto(informacoesPagamento, cotacao)"
            label="Valor a ser cobrado do cliente"
            v-model="
              informacoesPagamento[cotacao.id].pagamentoAVista.valorTotal
            "
            :disabledEscolhaMoeda="true"
          />
          <h5>
            Desconto Resultante:
            {{
              descontosAtuais[cotacao.id].pagamentoAVista?.desconto?.valor
                | dinheiroReal
            }}
          </h5>
        </v-row>

        <v-row
          v-if="
            informacoesPagamento[cotacao.id].tiposPagamento ===
            'pagamentoParceladoEntrada'
          "
          class="d-flex flex-column pb-4"
        >
          <h4>Pagamento parcelado com entrada</h4>
          <v-select
            :items="quantidadeParcelas"
            label="Número de parcelas"
            v-model="
              informacoesPagamento[cotacao.id].pagamentoParceladoEntrada
                .numeroParcelas
            "
          ></v-select>
          <v-moeda-field
            outlined
            label="Valor da entrada"
            @input="preencherDesconto(informacoesPagamento, cotacao)"
            v-model="
              informacoesPagamento[cotacao.id].pagamentoParceladoEntrada
                .valorEntrada
            "
            :disabledEscolhaMoeda="true"
          />

          <div
            v-if="
              informacoesPagamento[cotacao.id].pagamentoParceladoEntrada
                .numeroParcelas &&
              informacoesPagamento[cotacao.id].pagamentoParceladoEntrada
                .valorEntrada
            "
          >
            (Total - Entrada):
            {{ returnValorTotalMenosEntrada(cotacao) | dinheiroReal }} ----
            Valor mínimo a ser cobrado:
            {{
              valorMinimoASerCobrado(cotacao, "pagamentoParceladoEntrada")
                | dinheiroReal
            }}

            <v-moeda-field
              outlined
              label="Valor a ser cobrado do cliente"
              @input="preencherDesconto(informacoesPagamento, cotacao)"
              v-model="
                informacoesPagamento[cotacao.id].pagamentoParceladoEntrada
                  .valorTotal
              "
              :disabledEscolhaMoeda="true"
            />
            <h5>
              Desconto Resultante:
              {{
                descontosAtuais[cotacao.id].pagamentoParceladoEntrada?.desconto
                  ?.valor | dinheiroReal
              }}
            </h5>
          </div>
        </v-row>

        <v-row
          v-if="
            informacoesPagamento[cotacao.id].tiposPagamento ===
            'pagamentoParcelado'
          "
          class="d-flex flex-column pb-4"
        >
          <h4>Pagamento parcelado</h4>
          <v-select
            :items="quantidadeParcelas"
            label="Número de parcelas"
            v-model="
              informacoesPagamento[cotacao.id].pagamentoParcelado.numeroParcelas
            "
          ></v-select>
          <div
            v-if="
              informacoesPagamento[cotacao.id].pagamentoParcelado.numeroParcelas
            "
          >
            Valor mínimo a ser cobrado:
            {{ valorMinimoASerCobrado(cotacao) | dinheiroReal }}
            <v-moeda-field
              outlined
              label="Valor a ser cobrado do cliente"
              @input="preencherDesconto(informacoesPagamento, cotacao)"
              v-model="
                informacoesPagamento[cotacao.id].pagamentoParcelado.valorTotal
              "
              :disabledEscolhaMoeda="true"
            />
            <h5>
              Desconto Resultante:
              {{
                descontosAtuais[cotacao.id].pagamentoParcelado?.desconto?.valor
                  | dinheiroReal
              }}
            </h5>
          </div>
        </v-row>
        <v-divider class="pt-2"></v-divider>
      </div>
      <v-row>
        <v-col>
          <search-contato
            v-if="!paraEmpresa"
            v-model="responsaveisFinanceiros.responsavelFinanceiro"
            :isButtonDisabled="true"
            label="Responsável Financeiro"
          />
          <v-select
            v-if="paraEmpresa"
            label="Empresa"
            :items="empresas"
            item-text="nome"
            item-value="id"
            v-model="responsaveisFinanceiros.responsavelFinanceiroEmpresa"
            prepend-inner-icon="mdi-domain"
            outlined
          />
        </v-col>

        <div class="pt-5">
          <v-btn color="primary" @click="clickEmpresa()">
            {{ descricaoBotao }}
          </v-btn>
        </div>
      </v-row>
      <v-row> </v-row>
      <v-row>
        <v-btn color="blue darken-1" text @click="closePagamento">
          Cancelar
        </v-btn>
        <v-btn @click="save"> Fazer pagamento </v-btn>
        <v-checkbox
          dense
          label="Juntar pagamentos *"
          v-model="juntarPagamentos"
        ></v-checkbox>
        <p>
          *Obs.: Apenas pagamentos com forma de pagamento idênticas serão
          juntados (incluindo número de parcelas)
        </p>
      </v-row>
    </v-card>
  </v-dialog>
</template>

<script>
import VMoedaField from "@/components/widgets/VMoedaField";
import cotacaoMixin from "@/mixins/CotacaoMixin";
import SearchContato from "@/components/widgets/SearchContato";
import cotacao from "@/api/routes/cotacao";
import { arredondarNumero } from "@/helpers/numeros";

export default {
  mixins: [cotacaoMixin],
  components: { VMoedaField, SearchContato },
  props: {
    carrinho: {
      type: Array,
      default: () => {},
    },
    cotacoes: {
      type: Array,
      default: () => [],
    },
    pagamentoDialog: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      tipoErroFaturaCarrinho: "",
      tiposPagamento: [],
      quantidadeParcelas: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12],
      defaultinformacoesPagamento: {
        tiposPagamento: "",
        pagamentoAVistaPortalFornecedor: {
          name: "Cartão - Cliente Portal Fornecedor",
          numeroParcelas: 1,
          desconto: null,
          valorTotal: null,
        },
        pagamentoAVista: {
          name: "À vista",
          numeroParcelas: 1,
          desconto: null,
          valorTotal: null,
        },
        pagamentoParceladoEntrada: {
          name: "Parcelado com Entrada",
          valorEntrada: null,
          numeroParcelas: 12,
          desconto: null,
          valorTotal: null,
        },
        pagamentoParcelado: {
          name: "Parcelado",
          numeroParcelas: 12,
          desconto: null,
          valorTotal: null,
        },
      },
      juntarPagamentos: false,
      empresas: [],
      informacoesPagamento: null,
      responsaveisFinanceiros: {},
      paraEmpresa: false,
    };
  },

  computed: {
    descontosAtuais() {
      return this.informacoesPagamento;
    },

    descricaoBotao() {
      return this.paraEmpresa ? "Cliente" : "Empresa";
    },
  },
  async mounted() {
    this.informacoesPagamento = Object.fromEntries(
      this.cotacoes.map((cotacao) => [
        cotacao.id,
        {
          ...this.defaultinformacoesPagamento,
          pagamentoParceladoEntrada: {
            ...this.defaultinformacoesPagamento.pagamentoParceladoEntrada,
          },
          pagamentoParcelado: {
            ...this.defaultinformacoesPagamento.pagamentoParcelado,
          },
          pagamentoAVista: {
            ...this.defaultinformacoesPagamento.pagamentoAVista,
          },
          cotacao: cotacao.id,
        },
      ])
    );

    await this.$store.dispatch("global/fetchEmpresas");
    this.empresas = this.$store.state.global.empresas;
  },

  methods: {
    returnValorTotalMenosEntrada(cotacao) {
      return (
        this.cotacaoValorTotalJuros(cotacao) -
        this.converteValorFinanceiroBRL(
          this.informacoesPagamento[cotacao.id].pagamentoParceladoEntrada
            .valorEntrada
        )
      );
    },
    valorMinimoASerCobrado(cotacao, tipo = "") {
      if (tipo === "pagamentoParceladoEntrada") {
        return (
          this.cotacaoValorTotalJuros(cotacao) -
          this.descontoMaximo(cotacao) -
          this.converteValorFinanceiroBRL(
            this.informacoesPagamento[cotacao.id].pagamentoParceladoEntrada
              .valorEntrada
          )
        );
      }

      return (
        this.cotacaoValorTotalJuros(cotacao) - this.descontoMaximo(cotacao)
      );
    },

    preencherDesconto(informacoesPagamento, cotacao) {
      if (
        informacoesPagamento[cotacao.id].tiposPagamento === "pagamentoAVista" ||
        informacoesPagamento[cotacao.id].tiposPagamento ===
          "pagamentoAVistaPortalFornecedor"
      ) {
        const tipo = informacoesPagamento[cotacao.id].tiposPagamento;
        informacoesPagamento[cotacao.id][tipo].desconto = {
          moeda: informacoesPagamento[cotacao.id][tipo].valorTotal.moeda,
          valor: arredondarNumero(
            Number(this.cotacaoValorTotalJuros(cotacao)) -
              informacoesPagamento[cotacao.id][tipo].valorTotal.valor
          ),
        };
      }

      if (
        informacoesPagamento[cotacao.id].tiposPagamento === "pagamentoParcelado"
      ) {
        const tipo = informacoesPagamento[cotacao.id].tiposPagamento;
        informacoesPagamento[cotacao.id][tipo].desconto = {
          moeda: informacoesPagamento[cotacao.id][tipo].valorTotal.moeda,
          valor: arredondarNumero(
            Number(this.cotacaoValorTotalJuros(cotacao)) -
              informacoesPagamento[cotacao.id][tipo].valorTotal.valor
          ),
        };
      }
      if (
        informacoesPagamento[cotacao.id].tiposPagamento ===
        "pagamentoParceladoEntrada"
      ) {
        const tipo = informacoesPagamento[cotacao.id].tiposPagamento;
        informacoesPagamento[cotacao.id][tipo].desconto = {
          moeda: informacoesPagamento[cotacao.id][tipo].valorTotal.moeda,
          valor: arredondarNumero(
            Number(this.cotacaoValorTotalJuros(cotacao)) -
              informacoesPagamento[cotacao.id][tipo].valorTotal.valor -
              this.converteValorFinanceiroBRL(
                informacoesPagamento[cotacao.id].pagamentoParceladoEntrada
                  .valorEntrada
              )
          ),
        };
      }
    },

    valorTotalJurosEntrada(cotacao, n, entrada) {
      return this.calculoJuros(this.cotacaoValorTotal(cotacao) - entrada, n);
    },
    closePagamento() {
      this.$emit("closePagamento");
    },

    checkSave(informacoesPagamento) {
      // if true, raise exception
      const check = this.cotacoes.some((cotacao) => {
        if (informacoesPagamento[cotacao.id].tiposPagamento === "") {
          this.tipoErroFaturaCarrinho = "tipoPagamento";
        } else if (
          !this.responsaveisFinanceiros.responsavelFinanceiro &&
          !this.responsaveisFinanceiros.responsavelFinanceiroEmpresa
        ) {
          this.tipoErroFaturaCarrinho = "responsavelFinanceiro";
        } else this.tipoErroFaturaCarrinho = "descontoErrado";
        return (
          informacoesPagamento[cotacao.id].tiposPagamento === "" ||
          this.converteValorFinanceiroBRL(
            informacoesPagamento[cotacao.id][
              informacoesPagamento[cotacao.id].tiposPagamento
            ].desconto
          ) >
            this.descontoMaximo(cotacao) + 0.1 ||
          (!this.responsaveisFinanceiros.responsavelFinanceiro &&
            !this.responsaveisFinanceiros.responsavelFinanceiroEmpresa)
        );
      });
      if (check) {
        if (this.tipoErroFaturaCarrinho === "responsavelFinanceiro") {
          this.$root.vtoast.show({
            message:
              "Erro ao enviar pagamento - O responsável financeiro é obrigatório!",
            icon: "mdi-close",
            color: "error",
          });
        } else if (this.tipoErroFaturaCarrinho === "tipoPagamento") {
          this.$root.vtoast.show({
            message:
              "Erro ao enviar pagamento - O tipo de pagamento é obrigatório!",
            icon: "mdi-close",
            color: "error",
          });
        } else {
          this.$root.vtoast.show({
            message: "Erro ao enviar pagamento - Verifique o valor de desconto",
            icon: "mdi-close",
            color: "error",
          });
        }
        this.tipoErroFaturaCarrinho = "";
      }
      if (!check) {
        this.paraEmpresa
          ? (this.responsaveisFinanceiros.responsavelFinanceiroEmpresa =
              this.responsaveisFinanceiros.responsavelFinanceiroEmpresa.id)
          : (this.responsaveisFinanceiros.responsavelFinanceiro =
              this.responsaveisFinanceiros.responsavelFinanceiro.id);
      }
      return check;
    },
    descontoMaximo(cotacao) {
      if (
        this.informacoesPagamento[cotacao.id].tiposPagamento ===
        "pagamentoParceladoEntrada"
      ) {
        return (
          this.cotacaoValorTotalJuros(cotacao, 12) -
          this.converteValorFinanceiroBRL(
            this.informacoesPagamento[cotacao.id].pagamentoParceladoEntrada
              .valorEntrada
          ) -
          this.valorTotalJurosEntrada(
            cotacao,
            this.informacoesPagamento[cotacao.id].pagamentoParceladoEntrada
              .numeroParcelas,
            this.converteValorFinanceiroBRL(
              this.informacoesPagamento[cotacao.id].pagamentoParceladoEntrada
                .valorEntrada
            )
          )
        );
      }
      if (
        this.informacoesPagamento[cotacao.id].tiposPagamento ===
          "pagamentoAVista" ||
        this.informacoesPagamento[cotacao.id].tiposPagamento ===
          "pagamentoAVistaPortalFornecedor"
      ) {
        return (
          this.cotacaoValorTotalJuros(cotacao, 12) -
          this.cotacaoValorTotalJuros(cotacao, 0)
        );
      }
      if (
        this.informacoesPagamento[cotacao.id].tiposPagamento ===
        "pagamentoParcelado"
      ) {
        return (
          this.cotacaoValorTotalJuros(cotacao, 12) -
          this.cotacaoValorTotalJuros(
            cotacao,
            this.informacoesPagamento[cotacao.id].pagamentoParcelado
              .numeroParcelas
          )
        );
      }
    },
    clickEmpresa() {
      if (this.paraEmpresa) {
        this.responsaveisFinanceiros.responsavelFinanceiroEmpresa = null;
      } else {
        this.responsaveisFinanceiros.responsavelFinanceiro = null;
      }
      this.paraEmpresa = !this.paraEmpresa;
    },
    save() {
      if (this.checkSave(this.informacoesPagamento)) return;
      return this.$emit(
        "save",
        this.informacoesPagamento,
        this.responsaveisFinanceiros,
        this.juntarPagamentos
      );
    },
  },
};
</script>
