<template>
  <v-container fluid v-if="orcamento">
    <v-row class="ma-0">
      <v-card outlined width="100%" rounded elevation="2">
        <v-row class="ma-1">
          <v-col cols="12">
            <v-row>
              <v-col>
                <v-field-value label="Tipo de serviço">Voo</v-field-value>
              </v-col>
            </v-row>
            <v-row class="ma-2">
              <select-trechos-voo v-model="orcamento.orcamento_voo" @input="input"></select-trechos-voo>
            </v-row>
            <v-row class="ma-2">
              <v-text-field label="Descrição rápida" v-model="orcamento.descricao" :rules="[
              (v) =>
                (v || '').length <= 30 ||
                'Descrições devem ter menos de 30 caracteres',
            ]"></v-text-field>
            </v-row>
          </v-col>
        </v-row>
      </v-card>
    </v-row>
  </v-container>
</template>

<script>

import SelectTrechosVoo from "@/components/Orcamento/Widgets/SelectTrechosVoo";
import VFieldValue from "@/components/widgets/VFieldValue";

export default {
  components: {VFieldValue, SelectTrechosVoo},
  props: {
    value: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      orcamento: null
    }
  },
  created() {
    this.orcamento = this.value ? {...this.value} : {tipo: "orcamento_voo", orcamento_voo: {}}
  },
  methods: {
    input() {
      this.$emit('input', this.orcamento)
    }
  }

}

</script>
